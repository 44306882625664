import React, { createContext, useState, useEffect } from 'react';

// ExhibitionContextの作成
export const ExhibitionControllerContext = createContext();

export const ExhibitionControllerProvider = ({ children }) => {
  const [slideshowMode, setSlideshowMode] = useState(false);
  const [slideshowEnd, setSlideshowEnd] = useState(false);
  const [currentItemId, setCurrentItemId] = useState(0);
  const [currentItem, setCurrentItem] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isActive, setIsActive] = useState(false);
  const [zoomItemImageUrl, setZoomItemImageUrl] = useState(null);
  const [zoomItemId, setZoomItemId] = useState(null);
  const [showInfo, setShowInfo] = useState(false);
  const [showAttribution, setShowAttribution] = useState(false);
  const [inquiryItem, setInquiryItem] = useState(null);
  const [mode, setMode] = useState('HIGH');

  // コンテキストプロバイダでラップし、値を提供
  return (
    <ExhibitionControllerContext.Provider
      value={{
        slideshowMode,
        setSlideshowMode,
        slideshowEnd,
        setSlideshowEnd,
        currentItemId,
        setCurrentItemId,
        currentItem,
        setCurrentItem,
        selectedItem,
        setSelectedItem,
        isActive,
        setIsActive,
        zoomItemImageUrl,
        setZoomItemImageUrl,
        zoomItemId,
        setZoomItemId,
        showInfo,
        setShowInfo,
        showAttribution,
        setShowAttribution,
        inquiryItem,
        setInquiryItem,
        mode,
        setMode,
      }}
    >
      {children}
    </ExhibitionControllerContext.Provider>
  );
};
