import { createSlice } from '@reduxjs/toolkit';

const userSlice = createSlice({
  name: 'user',
  initialState: {
    currentUser: null,
    tokens: null,
    isFetching: false,
    error: false,
  },
  reducers: {
    loginStart: (state) => {
      state.isFetching = true;
    },
    loginSuccess: (state, action) => {
      state.isFetching = false;
      state.currentUser = action.payload.user;
      state.tokens = action.payload.tokens;
      localStorage.setItem('access_token', action.payload.tokens.access_token);
    },
    loginFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    logout: (state) => {
      localStorage.removeItem('access_token');
      state.currentUser = null;
      state.tokens = null;
    },
    signupStart: (state) => {
      state.isFetching = true;
    },
    signupSuccess: (state) => {
      state.isFetching = false;
    },
    signupFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    refreshToken: (state, action) => {
      state.tokens = action.payload;
      localStorage.setItem('access_token', action.payload.access_token);
    },
    updateProfile: (state, action) => {
      state.currentUser = action.payload;
    },
  },
});

export const {
  loginSuccess,
  loginStart,
  loginFailure,
  logout,
  signupStart,
  signupSuccess,
  signupFailure,
  refreshToken,
  updateProfile,
} = userSlice.actions;
export default userSlice.reducer;
