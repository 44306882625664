import React from 'react';
import { authRequest } from '../../requestMethods';
import { successToast } from '../../utils/toast';
import { useNavigate } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';

const InactiveRoomModal = ({ roomId }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const handleDelete = async () => {
    try {
      await authRequest.patch(`/rooms/inactive/${roomId}`);
      navigate('/dashboard');
      successToast(t('message.success.t13'));
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <input type='checkbox' id='delete-modal' className='modal-toggle' />
      <div className='modal'>
        <div className='modal-box relative'>
          <label
            htmlFor='delete-modal'
            className='btn btn-circle btn-sm absolute right-2 top-2'
          >
            ✕
          </label>
          <h2 className='text-xl font-bold'>
            <Trans i18nKey={'gallery-modal.delete-ttl'} />
          </h2>
          <p className='py-4'>
            <Trans i18nKey={'gallery-modal.delete-desc'} />
          </p>
          <div className='modal-action'>
            <label htmlFor='delete-modal' className='btn'>
              <Trans i18nKey={'btn.cancel'} />
            </label>

            <button className='btn btn-primary' onClick={handleDelete}>
              <Trans i18nKey={'btn.delete'} />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default InactiveRoomModal;
