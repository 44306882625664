import { useGLTF } from '@react-three/drei';
import { Suspense, useLayoutEffect, useState } from 'react';

const PictureFrame70 = ({ color, ...props }) => {
  const { scene, materials } = useGLTF(
    'https://data.curatedartshow.com/models/frames/koiframe2.glb'
  );

  const [geometry, setGeometry] = useState();

  if (!geometry) {
    const cloneScene = scene.clone(true);
    setGeometry(cloneScene);
  }

  useLayoutEffect(() => {
    // console.log(materials);
    materials['Red Metallic Paint_material'].color.set('#a30000');
    materials['Red Metallic Paint_material'].metalness = 1;
    materials['Red Metallic Paint_material'].roughness = 0.6;
    // materials['Red Stone_material.001'].color.set('#c1b7a3');
    materials['Red Stone_material.001'].metalness = 1;
    materials['Red Stone_material.001'].roughness = 3;

    // materials['Matte Deep Blue Carbon Fiber_material.002'].color.set('#d2c7b0');
    materials['Matte Deep Blue Carbon Fiber_material.002'].metalness = 0.8;
    materials['Matte Deep Blue Carbon Fiber_material.002'].roughness = 3;

    // materials['default_material107.003'].color.set('#7c87cd');
    // materials['default_material107.003'].metalness = 1;
    // materials['default_material107.003'].roughness = 0;
    materials['Grey Metallic Paint_material'].color.set('#626262');
    materials['Grey Metallic Paint_material'].metalness = 1;
    materials['Grey Metallic Paint_material'].roughness = 0.2;

    geometry.traverse((obj) => {
      if (obj.isMesh) obj.castShadow = obj.receiveShadow = true;
    });
  }, []);

  return (
    <Suspense fallback={null}>
      <primitive object={geometry} {...props} />
    </Suspense>
  );
};

export default PictureFrame70;
