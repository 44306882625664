import { publicRequest } from '../requestMethods';

export const getCeilings = async () => {
  try {
    const response = await publicRequest.get(`/ceilings`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getCeilingById = async (id) => {
  try {
    const response = await publicRequest.get(`/ceilings/${id}`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
