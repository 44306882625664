import React, { useEffect, useState } from 'react';
import SubscriptionPlan from '../../../../../components/SubscriptionPlan';
import { useDispatch } from 'react-redux';
import { authRequest, publicRequest } from '../../../../../requestMethods';
import CountryRegionModal from '../../../../dashboard/components/CountryRegionModal';
import { useSearchParams } from 'react-router-dom';
import { loadEnd, loadStart } from '../../../../../redux/loadRedux';
import { errorToast, successToast } from '../../../../../utils/toast';
import { useTranslation } from 'react-i18next';

const OnboardingSubscription = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const userId = searchParams.get('id');
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [plans, setPlans] = useState(null);
  const [selectedPriceIds, setSelectedPriceIds] = useState([]);
  // const user = useSelector((state) => state.user.currentUser);
  const fetchPrices = async () => {
    const res = await publicRequest.get('/stripe/prices');
    setPlans(res.data);
  };

  useEffect(() => {
    fetchPrices();
  }, []);

  const handleGetStarted = async (pricesIds, isFreeTrial = false) => {
    const latestUserData = await authRequest.get(`/auth/latest`);
    console.log('data:', latestUserData.data.user.country);

    if (latestUserData.data.user?.country) {
      const body = {
        priceIds: pricesIds,
        userId: Number(userId),
      };

      try {
        const res = await publicRequest.post('/stripe/checkout/onboard', body);
        const checkoutUrl = res.data.url;
        window.location.href = checkoutUrl;
      } catch (error) {
        console.error(error);
      }
    } else {
      setSelectedPriceIds(pricesIds);
      document.getElementById('country_region_modal').showModal();
    }
  };

  const handleSubmit = async (country, region) => {
    dispatch(loadStart());
    try {
      //countryとregionを更新
      await authRequest.patch('/users', {
        country,
        region,
      });

      //subscriptionページに遷移
      document.getElementById('country_region_modal').close();
      successToast(t('message.success.t23'));
      const body = {
        priceIds: selectedPriceIds,
        userId: Number(userId),
      };

      try {
        const res = await publicRequest.post('/stripe/checkout/onboard', body);
        const checkoutUrl = res.data.url;
        window.location.href = checkoutUrl;
      } catch (error) {
        console.error(error);
      }
    } catch (err) {
      console.error(err);
      errorToast(t('message.error.t1'));
    } finally {
      dispatch(loadEnd());
    }
  };

  useEffect(() => {
    // ページが完全に読み込まれた後に実行する
    window.onload = () => {
      const subscriptionUpdate = searchParams.get('subscription_update');
      if (subscriptionUpdate == 'failed') {
        errorToast(t('message.error.t18'));
      }

      searchParams.delete('subscription_update');
      setSearchParams(searchParams);
    };
  }, [searchParams]);

  return (
    <>
      <div className='w-full flex-auto px-4 pb-20 pt-24 md:pb-56'>
        <div className='mx-auto max-w-4xl'>
          <SubscriptionPlan
            plans={plans}
            userPlans={[]}
            getStarted={handleGetStarted}
          />
        </div>
      </div>
      <CountryRegionModal handleSubmit={handleSubmit} />
    </>
  );
};

export default OnboardingSubscription;
