import { Fragment, useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ExhibitionContext } from '../../../../context/ExhibitionContext';
import { HexColorInput, HexColorPicker } from 'react-colorful';

const LIGHTING_COLORS = [
  {
    value: 'WHITE',
    title: 'WHITE',
    colorCode: '#ffffff',
    bgColor: 'bg-white-grad',
    displayTitle: 'White',
  },
  {
    value: 'RED',
    title: 'RED',
    colorCode: '#ff0600',
    bgColor: 'bg-red-grad',
    displayTitle: 'Red',
  },
  {
    value: 'PINK',
    title: 'PINK',
    colorCode: '#ff00db',
    bgColor: 'bg-pink-grad',
    displayTitle: 'Pink',
  },
  {
    value: 'BLUE',
    title: 'BLUE',
    colorCode: '#0028ff',
    bgColor: 'bg-blue-grad',
    displayTitle: 'Blue',
  },
  {
    value: 'CUSTOM',
    title: 'CUSTOM',
    colorCode: '#ffffff',
    bgColor: '',
    displayTitle: 'Custom (pick your color)',
  },
];

export const InvisibleLight = () => {
  const { i18n } = useTranslation();

  const { exhibitionSettings, setExhibitionSettings } =
    useContext(ExhibitionContext);

  const handleChangeSpotLightIntensity = (value) => {
    //必要であればvalitationを追加する

    setExhibitionSettings((prevState) => ({
      ...prevState,
      spotLightIntensity: value,
    }));
  };

  const handleChangeDirectionalLightIntensity = (value) => {
    //必要であればvalitationを追加する

    setExhibitionSettings((prevState) => ({
      ...prevState,
      directionalLightIntensity: value,
    }));
  };

  const handleChangeDirectionalLightColorTheme = (value) => {
    //必要であればvalitationを追加する

    setExhibitionSettings((prevState) => ({
      ...prevState,
      directionalLightColorTheme: value,
    }));
  };

  const handleChangeDirectionalLightColorCode = (value) => {
    //必要であればvalitationを追加する

    setExhibitionSettings((prevState) => ({
      ...prevState,
      directionalLightColorCode: value,
    }));
  };

  const handleChangeSpotLightColorTheme = (value) => {
    //必要であればvalitationを追加する

    setExhibitionSettings((prevState) => ({
      ...prevState,
      spotLightColorTheme: value,
    }));
  };

  const handleChangeSpotLightColorCode = (value) => {
    //必要であればvalitationを追加する

    setExhibitionSettings((prevState) => ({
      ...prevState,
      spotLightColorCode: value,
    }));
  };

  return (
    <>
      <p className='pb-8 text-xl font-semibold'>
        <Trans i18nKey={'gallery.invisible-light.ttl'} />
      </p>

      <p className='font-[600]'>
        <Trans i18nKey={'gallery.invisible-light.spot'} />
      </p>
      <div className='py-4 pl-6'>
        <input
          type='range'
          min={0}
          max='10'
          step='1'
          value={exhibitionSettings.spotLightIntensity}
          onChange={(e) =>
            handleChangeSpotLightIntensity(Number(e.target.value))
          }
          className='range'
        />
        <div className='flex w-full justify-between px-2 text-xs'>
          <span>|</span>
          <span>|</span>
          <span>|</span>
          <span>|</span>
          <span>|</span>
          <span>|</span>
          <span>|</span>
          <span>|</span>
          <span>|</span>
          <span>|</span>
        </div>
        {LIGHTING_COLORS.map((el, index) => {
          return (
            <Fragment key={`spotlight-${index}`}>
              <div className='form-control'>
                <label className='label cursor-pointer justify-start gap-2'>
                  <input
                    type='radio'
                    className='radio'
                    id={el.title}
                    value={el.value}
                    onChange={(e) => {
                      handleChangeSpotLightColorTheme(e.target.value);
                      handleChangeSpotLightColorCode(el.colorCode);
                    }}
                    name='spotlight-color'
                    defaultChecked={
                      el.value === exhibitionSettings.spotLightColorTheme
                    }
                  />
                  <span className={`label-text`}>
                    {el.value === 'CUSTOM' && i18n.language === 'ja'
                      ? 'カスタム（好みの色を選択）'
                      : el.displayTitle}
                  </span>
                </label>
              </div>
              {el.title === 'CUSTOM' &&
                el.value === exhibitionSettings.spotLightColorTheme && (
                  <div className='mt-2 pl-8'>
                    <HexColorPicker
                      color={exhibitionSettings.spotLightColorCode}
                      onChange={handleChangeSpotLightColorCode}
                    />
                    <div className='mt-2 '>
                      <HexColorInput
                        color={exhibitionSettings.spotLightColorCode}
                        onChange={handleChangeSpotLightColorCode}
                        className='input input-bordered w-[200px]'
                      />
                    </div>
                  </div>
                )}
            </Fragment>
          );
        })}
      </div>
      <p className='font-[600]'>
        <Trans i18nKey={'gallery.invisible-light.direct'} />
      </p>
      <div className='py-4 pl-6'>
        <input
          type='range'
          min={0}
          max='10'
          step='1'
          value={exhibitionSettings.directionalLightIntensity}
          onChange={(e) =>
            handleChangeDirectionalLightIntensity(Number(e.target.value))
          }
          className='range'
        />
        <div className='flex w-full justify-between px-2 text-xs'>
          <span>|</span>
          <span>|</span>
          <span>|</span>
          <span>|</span>
          <span>|</span>
          <span>|</span>
          <span>|</span>
          <span>|</span>
          <span>|</span>
          <span>|</span>
        </div>
        {LIGHTING_COLORS.map((el, index) => {
          return (
            <Fragment key={`directional-light-${index}`}>
              <div className='form-control'>
                <label className='label cursor-pointer justify-start gap-2'>
                  <input
                    type='radio'
                    className='radio'
                    id={el.title}
                    value={el.value}
                    onChange={(e) => {
                      handleChangeDirectionalLightColorTheme(e.target.value);
                      handleChangeDirectionalLightColorCode(el.colorCode);
                    }}
                    name='directionallight-color'
                    defaultChecked={
                      el.value === exhibitionSettings.directionalLightColorTheme
                    }
                  />
                  <span className={`label-text`}>
                    {el.value === 'CUSTOM' && i18n.language === 'ja'
                      ? 'カスタム（好みの色を選択）'
                      : el.displayTitle}
                  </span>
                </label>
              </div>
              {el.title === 'CUSTOM' &&
                el.value === exhibitionSettings.directionalLightColorTheme && (
                  <div className='mt-2 pl-8'>
                    <HexColorPicker
                      color={exhibitionSettings.directionalLightColorCode}
                      onChange={handleChangeDirectionalLightColorCode}
                    />
                    <div className='mt-2 '>
                      <HexColorInput
                        color={exhibitionSettings.directionalLightColorCode}
                        onChange={handleChangeDirectionalLightColorCode}
                        className='input input-bordered w-[200px]'
                      />
                    </div>
                  </div>
                )}
            </Fragment>
          );
        })}
      </div>
    </>
  );
};
