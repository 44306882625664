import React, { useState, useRef } from 'react';
import { authRequest } from '../../../requestMethods';
import { successToast } from '../../../utils/toast';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import LoadingSpinner from '../../LoadingSpinner';
import { loadEnd, loadStart } from '../../../redux/loadRedux';
import { Trans } from 'react-i18next';
import {
  validateEmail,
  validateReportContent,
} from '../../../utils/validation';
import ReCAPTCHA from 'react-google-recaptcha';

const ReportItemModal = ({
  itemId,
  setShowReportModal,
  reportModalRef,
  isBackgroundLight = true,
}) => {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.isLoading);
  const [reportType, setReportType] = useState(null);
  const [reportContent, setReportContent] = useState('');
  const [reportContentError, setReportContentError] = useState('');
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [reCaptchaValue, setReCaptchaValue] = useState('');
  const reCaptchaRef = useRef(null);
  const { t } = useTranslation();

  const reportTypes = [
    { value: 'SPAM', title: t('report.spam') },
    { value: 'ADULT_CONTENT', title: t('report.adult') },
    { value: 'OFFENSIVE', title: t('report.offensive') },
    {
      value: 'INTELLECTUAL_PROPERTY_VIOLATION',
      title: t('report.property-violation'),
    },
    { value: 'OTHER', title: t('report.other') },
  ];

  const reflectContent = (content) => {
    const result = validateReportContent(content);
    result.isValid
      ? setReportContentError('')
      : setReportContentError(result.message);
    setReportContent(content);
  };

  const reflectEmail = (email) => {
    const result = validateEmail(email);
    result.isValid ? setEmailError('') : setEmailError(result.message);
    setEmail(email);
  };

  const handleSubmit = async () => {
    try {
      dispatch(loadStart());
      await authRequest.post('/item-reports', {
        type: reportType,
        content: reportContent,
        email: email,
        itemId: Number(itemId),
        token: reCaptchaValue,
      });
      if (reCaptchaRef.current) {
        reCaptchaRef.current.reset();
      }
      setEmail('');
      setReportContent('');
      setReCaptchaValue('');
      successToast(t('message.success.t8'));
      setShowReportModal(false);
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(loadEnd());
    }
  };

  const onChange = (value) => {
    setReCaptchaValue(value);
  };

  return (
    <>
      <div
        ref={reportModalRef}
        className={`fixed left-1/2 top-1/2 z-[1002] w-full max-w-sm -translate-x-1/2 -translate-y-1/2 transform overflow-scroll rounded-lg border border-[#ACACAC] p-5 shadow-lg ${
          isBackgroundLight
            ? 'bg-white'
            : 'bg-modal-on-modal before:absolute before:left-0 before:top-0 before:z-[-1] before:h-full before:w-full before:overflow-hidden before:rounded-xl before:backdrop-blur-lg before:content-[""]'
        }  md:max-w-md`}
        style={{ maxHeight: 'calc(100vh - 5em)' }}
      >
        <div className='relative'>
          <div className='modal-inner'>
            <div>
              <div className='mx-auto'>
                <p
                  className={`mb-6 text-lg font-bold ${
                    isBackgroundLight ? 'text-black' : 'text-white'
                  }`}
                >
                  <Trans i18nKey={'btn.report'} />
                </p>
                <p
                  className={`mb-6 font-medium ${
                    isBackgroundLight ? 'text-black' : 'text-white'
                  }`}
                >
                  <Trans i18nKey={'report.desc'} />
                </p>
              </div>

              <form>
                <div className=''>
                  {reportTypes.map((el, index) => (
                    <label
                      className='label cursor-pointer justify-start gap-2'
                      key={index}
                    >
                      <input
                        type='radio'
                        className={`radio ${
                          isBackgroundLight ? '!border-dark' : 'radio-none'
                        } bg-white`}
                        name='reportType'
                        value={el.value}
                        onChange={(e) => {
                          setReportContentError('');
                          setReportType(e.target.value);
                        }}
                      />
                      <span
                        className={`label-text ${
                          isBackgroundLight ? 'text-black' : 'text-white'
                        }`}
                      >
                        {el.title}
                      </span>
                    </label>
                  ))}
                  {reportType === 'OTHER' && (
                    <div className=''>
                      <label className='form-control w-full'>
                        <textarea
                          className={`textarea textarea-bordered w-full max-w-[23.125rem] self-end text-sm placeholder:text-sm ${
                            !!reportContentError && 'textarea-error'
                          }`}
                          placeholder='Please specify'
                          rows={4}
                          value={reportContent}
                          onChange={(e) => reflectContent(e.target.value)}
                        ></textarea>
                        <div className='label w-full max-w-[23.125rem] self-end'>
                          <span className='label-text-alt text-error'>
                            {reportContentError}
                          </span>
                          <span className='label-text-alt'>
                            {reportContent.length || 0} / 500
                          </span>
                        </div>
                      </label>
                    </div>
                  )}
                </div>
                <label className='form-control mt-5 w-full'>
                  <div className='label'>
                    <span className='label-text'>
                      <Trans i18nKey={'report.email'} />
                    </span>
                  </div>
                  <input
                    type='text'
                    placeholder='Email'
                    className={`input input-bordered w-full text-sm placeholder:text-sm ${
                      emailError && 'input-error'
                    }`}
                    value={email}
                    onChange={(e) => reflectEmail(e.target.value)}
                  />
                  <div className='label'>
                    <span className='label-text-alt text-error'>
                      {emailError}
                    </span>
                  </div>
                </label>
                <div className='flex justify-end'>
                  <ReCAPTCHA
                    ref={reCaptchaRef}
                    sitekey={`${process.env.REACT_APP_SITE_KEY}`}
                    onChange={onChange}
                  />
                </div>
                <div className='mt-6 flex justify-end gap-2'>
                  <button
                    onClick={() => setShowReportModal(false)}
                    className='btn'
                    type='button'
                  >
                    <Trans i18nKey={'btn.cancel'} />
                  </button>

                  <button
                    className={`btn btn-neutral `}
                    type='button'
                    onClick={handleSubmit}
                    disabled={
                      !reportType ||
                      !email ||
                      emailError ||
                      reportContentError ||
                      !reCaptchaValue
                    }
                  >
                    <Trans i18nKey={'btn.report'} />
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {isLoading && <LoadingSpinner />}
    </>
  );
};

export default ReportItemModal;
