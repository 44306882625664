import {
  CubeIcon,
  EyeSlashIcon,
  FilmIcon,
  LightBulbIcon,
  MusicalNoteIcon,
  UserIcon,
  ViewfinderCircleIcon,
  ChevronLeftIcon,
} from '@heroicons/react/24/outline';
import {
  CubeIcon as SolidCubeIcon,
  EyeSlashIcon as SolidEyeSlashIcon,
  FilmIcon as SolidFilmIcon,
  LightBulbIcon as SolidLightBulbIcon,
  MusicalNoteIcon as SolidMusicalNoteIcon,
  UserIcon as SolidUserIcon,
  ViewfinderCircleIcon as SolidViewfinderCircleIcon,
} from '@heroicons/react/24/solid';

import { ExhibitionContext } from '../../../../context/ExhibitionContext';
import { useContext } from 'react';
import { Trans } from 'react-i18next';

export const DesktopMenu = ({
  selectMenu,
  moveTo,
  currentMenu,
  SettingComponent,
}) => {
  const { exhibitionSettings, setExhibitionSettings } =
    useContext(ExhibitionContext);

  return (
    <div className='flex h-full'>
      <div className='h-full border-r border-base-300 px-2 py-4'>
        <div className='flex w-[4.5rem] flex-col items-center gap-5'>
          <button
            className='flex flex-col items-center gap-1'
            onClick={() => selectMenu('Theme')}
          >
            {currentMenu === 'Theme' ? (
              <SolidCubeIcon className='h-6 w-6 shrink-0' />
            ) : (
              <CubeIcon className='h-6 w-6 shrink-0' />
            )}
            <p className='text-xs text-base-content'>
              <Trans i18nKey={'gallery.desktop-menu.theme'} />
            </p>
          </button>
          <button
            className='flex flex-col items-center gap-1'
            onClick={() => selectMenu('Picture Frames')}
          >
            {currentMenu === 'Picture Frames' ? (
              <SolidViewfinderCircleIcon className='h-6 w-6 shrink-0' />
            ) : (
              <ViewfinderCircleIcon className='h-6 w-6 shrink-0' />
            )}
            <p className='text-xs text-base-content'>
              <Trans i18nKey={'gallery.desktop-menu.picture-frames'} />
            </p>
          </button>
          <button
            className={`flex flex-col items-center gap-1 ${
              exhibitionSettings.theme != 'CUSTOM' && 'opacity-20'
            }`}
            onClick={() => selectMenu('Functional Light')}
            disabled={exhibitionSettings.theme != 'CUSTOM'}
          >
            {currentMenu === 'Functional Light' ? (
              <SolidLightBulbIcon className='h-6 w-6 shrink-0' />
            ) : (
              <LightBulbIcon className='h-6 w-6 shrink-0' />
            )}
            <p className='text-xs text-base-content'>
              <Trans i18nKey={'gallery.desktop-menu.functional-light'} />
            </p>
          </button>
          <button
            className={`flex flex-col items-center gap-1 ${
              exhibitionSettings.theme != 'CUSTOM' && 'opacity-20'
            }`}
            onClick={() => selectMenu('Invisible Light')}
            disabled={exhibitionSettings.theme != 'CUSTOM'}
          >
            {currentMenu === 'Invisible Light' ? (
              <SolidEyeSlashIcon className='h-6 w-6 shrink-0' />
            ) : (
              <EyeSlashIcon className='h-6 w-6 shrink-0' />
            )}
            <p className='text-xs text-base-content'>
              <Trans i18nKey={'gallery.desktop-menu.invisible-light'} />
            </p>
          </button>
          <button
            className='flex flex-col items-center gap-1'
            onClick={() => selectMenu('Silhouettes')}
          >
            {currentMenu === 'Silhouettes' ? (
              <SolidUserIcon className='h-6 w-6 shrink-0' />
            ) : (
              <UserIcon className='h-6 w-6 shrink-0' />
            )}
            <p className='text-xs text-base-content'>
              <Trans i18nKey={'gallery.desktop-menu.silhouettes'} />
            </p>
          </button>
          <button
            className='flex flex-col items-center gap-1'
            onClick={() => selectMenu('Music')}
          >
            {currentMenu === 'Music' ? (
              <SolidMusicalNoteIcon className='h-6 w-6 shrink-0' />
            ) : (
              <MusicalNoteIcon className='h-6 w-6 shrink-0' />
            )}
            <p className='text-xs text-base-content'>
              <Trans i18nKey={'gallery.desktop-menu.music'} />
            </p>
          </button>
          <button
            className='flex flex-col items-center gap-1'
            onClick={() => selectMenu('Entrance Video')}
          >
            {currentMenu === 'Entrance Video' ? (
              <SolidFilmIcon className='h-6 w-6 shrink-0' />
            ) : (
              <FilmIcon className='h-6 w-6 shrink-0' />
            )}
            <p className='text-xs text-base-content'>
              <Trans i18nKey={'gallery.desktop-menu.entrance-video'} />
            </p>
          </button>
        </div>
      </div>
      {/* previewがリレンダリング中にMenuの幅が広がるのを防ぐためwidthを固定 */}
      <div
        className='w-[26.875rem] flex-grow overflow-y-scroll px-5 py-4'
        style={{ height: 'calc(100vh - 4rem - 49px)' }}
      >
        <button className='btn btn-ghost btn-sm mb-8' onClick={() => moveTo()}>
          <ChevronLeftIcon className='h-5 w-5' />
          <Trans i18nKey={'gallery.desktop-menu.arrange-artworks'} />
        </button>
        {SettingComponent}
      </div>
    </div>
  );
};
