import React from 'react';
import { Trans } from 'react-i18next';

const CookiePolicy = () => {
  return (
    <>
      <div className='mx-auto max-w-3xl px-4 pb-44 pt-10 sm:px-0'>
        <h1 className='text-3xl font-extrabold'>
          <Trans i18nKey={'cookie.ttl'} />
        </h1>

        <div className='mt-8 flex flex-col gap-8'>
          <p>
            <Trans i18nKey={'cookie.t1'} />
          </p>
          <div>
            <div className='text-xl font-bold'>
              <Trans i18nKey={'cookie.t2'} />
            </div>
            <p>
              <Trans i18nKey={'cookie.t3'} />
            </p>
          </div>
          <div>
            <div className='text-xl font-bold'>
              <Trans i18nKey={'cookie.t4'} />
            </div>
            <p>
              <Trans i18nKey={'cookie.t5'} />
            </p>
          </div>
          <div>
            <div className='text-xl font-bold'>
              <Trans i18nKey={'cookie.t6'} />
            </div>
            <p>
              <Trans i18nKey={'cookie.t7'} />
            </p>
          </div>
          <div>
            <div className='text-xl font-bold'>
              <Trans i18nKey={'cookie.t8'} />
            </div>
            <p>
              <Trans i18nKey={'cookie.t9'} />
            </p>
          </div>
          <div>
            <div className='text-xl font-bold'>
              <Trans i18nKey={'cookie.t10'} />
            </div>
            <p>
              <Trans i18nKey={'cookie.t11'} />
            </p>
          </div>
          <div>
            <div className='text-xl font-bold'>
              <Trans i18nKey={'cookie.t12'} />
            </div>
            <p>
              <Trans i18nKey={'cookie.t13'} />
            </p>
          </div>

          <div>
            <div className='text-xl font-bold'>
              <Trans i18nKey={'cookie.t14'} />
            </div>
            <p>
              <Trans i18nKey={'cookie.t15'} />
            </p>
          </div>
          <div>
            <p>
              <Trans
                i18nKey={'cookie.t16'}
                components={{
                  link1: (
                    <a
                      href='mailto:support@yuyosoft.com'
                      className='underline'
                    ></a>
                  ),
                  link2: <a href='/terms-privacy' className='underline'></a>,
                }}
              />
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default CookiePolicy;
