export const CYBERPUNK_ATTRIBUTIONS = [
  {
    title: 'Mazda RX-7 (Car)',
    url: 'https://sketchfab.com/3d-models/mazda-rx-7-car-404b2aefdf084f01bc3d75559c9c4088',
    author: 'Anokino',
    lisence: 'CC BY 4.0',
    lisenceUrl: 'https://creativecommons.org/licenses/by/4.0/',
  },
  {
    title: 'Cyberpunk 2077 - Quadra V-Tech',
    url: 'https://sketchfab.com/3d-models/cyberpunk-2077-quadra-v-tech-6c8bc8c16f2c4e739b2f182e9d060873',
    author: 'Kreanym',
    lisence: 'CC BY 4.0',
    lisenceUrl: 'https://creativecommons.org/licenses/by/4.0/',
  },
  {
    title: 'Rusty Japanese Arcade',
    url: 'https://sketchfab.com/3d-models/rusty-japanese-arcade-2938ae13a77c46e8afabb41eba18e699',
    author: 'Toni García Vilche',
    lisence: 'CC BY 4.0',
    lisenceUrl: 'https://creativecommons.org/licenses/by/4.0/',
  },
  {
    title: 'Arcade Game Machine 001',
    url: 'https://sketchfab.com/3d-models/arcade-game-machine-001-94124b539e714bd29d7889c3cb4c5325',
    author: '@sanyabeast',
    lisence: 'CC BY 4.0',
    lisenceUrl: 'https://creativecommons.org/licenses/by/4.0/',
  },
  {
    title: 'Arcade Machine Low Poly',
    url: 'https://sketchfab.com/3d-models/arcade-machine-low-poly-9ca61ace0f7a4410bf154d5ffb797f6c',
    author: 'Seven.Four',
    lisence: 'CC BY 4.0',
    lisenceUrl: 'https://creativecommons.org/licenses/by/4.0/',
  },
  {
    title: 'Old Street Building 01 Cyberpunk',
    url: 'https://sketchfab.com/3d-models/old-street-building-01-cyberpunk-2cdbba1da1574f7986c85c96c9fa8716',
    author: 'daftvid',
    lisence: 'CC BY 4.0',
    lisenceUrl: 'https://creativecommons.org/licenses/by/4.0/',
  },
  {
    title: 'Cyberpunk skyscraper',
    url: 'https://sketchfab.com/3d-models/cyberpunk-skyscraper-e7c9ef989822425e9a0e09c37b79170f',
    author: 'Artemis',
    lisence: 'CC BY 4.0',
    lisenceUrl: 'https://creativecommons.org/licenses/by/4.0/',
  },
  {
    title: 'Skyscraper Tower',
    url: 'https://sketchfab.com/3d-models/skyscraper-tower-8c141fda071f4f6a927f4d7047f48ec7',
    author: 'VertaScan',
    lisence: 'CC BY 4.0',
    lisenceUrl: 'https://creativecommons.org/licenses/by/4.0/',
  },
  {
    title: 'Cyberpunk skyscraper',
    url: 'https://sketchfab.com/3d-models/cyberpunk-skyscraper-5392c4e6daf944b3a17b43a58b55fe08',
    author: 'Artemis',
    lisence: 'CC BY 4.0',
    lisenceUrl: 'https://creativecommons.org/licenses/by/4.0/',
  },
  {
    title: 'Hosnian prime skyscraper / Star wars',
    url: 'https://sketchfab.com/3d-models/hosnian-prime-skyscraper-star-wars-a2f8139dc05e447faa5d319eb5b9038f',
    author: 'Artemis',
    lisence: 'CC BY 4.0',
    lisenceUrl: 'https://creativecommons.org/licenses/by/4.0/',
  },
  {
    title: 'Cyberpunk skyscraper',
    url: 'https://sketchfab.com/3d-models/cyberpunk-skyscraper-f0af05e128284feba38a1611edba3dff',
    author: 'Artemis',
    lisence: 'CC BY 4.0',
    lisenceUrl: 'https://creativecommons.org/licenses/by/4.0/',
  },
  {
    title: 'old buildings',
    url: 'https://sketchfab.com/3d-models/old-buildings-f3775d0c3d9844fd8b1e95f430e3c890',
    author: 'daftvid',
    lisence: 'CC BY 4.0',
    lisenceUrl: 'https://creativecommons.org/licenses/by/4.0/',
  },
  {
    title: 'Birth Of Venus In Neon',
    url: 'https://sketchfab.com/3d-models/birth-of-venus-in-neon-b771aba99eba46df860187f98631368a',
    author: 'pan.stasian',
    lisence: 'CC BY 4.0',
    lisenceUrl: 'https://creativecommons.org/licenses/by/4.0/',
  },
  {
    title: 'cassete neon',
    url: 'https://sketchfab.com/3d-models/cassete-neon-3d3a3d78a3e94f4cbdb442542a0e11fd',
    author: 'J.César Navarrete',
    lisence: 'CC BY 4.0',
    lisenceUrl: 'https://creativecommons.org/licenses/by/4.0/',
  },
  {
    title: 'Neon Signs',
    url: 'https://sketchfab.com/3d-models/neon-signs-307e887d740649f88fbc77b061f3a742',
    author: 'Shalmon',
    lisence: 'CC BY 4.0',
    lisenceUrl: 'https://creativecommons.org/licenses/by/4.0/',
  },
  {
    title: 'Тeon sign saxophone',
    url: 'https://sketchfab.com/3d-models/eon-sign-saxophone-f2c99a9355104d9db507cb74b1687c9f',
    author: 'tkkjee',
    lisence: 'CC BY 4.0',
    lisenceUrl: 'https://creativecommons.org/licenses/by/4.0/',
  },
  {
    title: 'star wars death star light panel',
    url: 'https://sketchfab.com/3d-models/star-wars-death-star-light-panel-e2a1df09ce8d4ab4895d0e6e36c6870d',
    author: 'alexeyshadrin80',
    lisence: 'CC BY 4.0',
    lisenceUrl: 'https://creativecommons.org/licenses/by/4.0/',
  },
  {
    title: 'Street Light',
    url: 'https://sketchfab.com/3d-models/street-light-2d44b333f56c4faaab1bd2007868cc37',
    author: 'KloWorks',
    lisence: 'CC BY 4.0',
    lisenceUrl: 'https://creativecommons.org/licenses/by/4.0/',
  },
  {
    title: 'Urban Street Light',
    url: 'https://sketchfab.com/3d-models/urban-street-light-530804b1064b4baf8946ff03df09e5c3',
    author: 'Full Barrel Productions',
    lisence: 'CC BY 4.0',
    lisenceUrl: 'https://creativecommons.org/licenses/by/4.0/',
  },
  {
    title: 'Bus Stop & Traffic Light',
    url: 'https://sketchfab.com/3d-models/bus-stop-traffic-light-5ab6a5f817c94cbcb178603314cb8905',
    author: 'Ricardo_PG',
    lisence: 'CC BY 4.0',
    lisenceUrl: 'https://creativecommons.org/licenses/by/4.0/',
  },
  {
    title: 'Russian City Fence',
    url: 'https://sketchfab.com/3d-models/russian-city-fence-1e7c6757213c4090975bda39ac88ffee',
    author: 'XataZHD',
    lisence: 'CC BY 4.0',
    lisenceUrl: 'https://creativecommons.org/licenses/by/4.0/',
  },
  {
    title: 'Modulat utility pole',
    url: 'https://sketchfab.com/3d-models/modulat-utility-pole-f1798d1366fe4d7392be90ab0af31143',
    author: '3d.caster',
    lisence: 'CC BY 4.0',
    lisenceUrl: 'https://creativecommons.org/licenses/by/4.0/',
  },
  {
    title: 'pole electric',
    url: 'https://sketchfab.com/3d-models/pole-electric-e76da4eef4e84e1c959260a384fc1c28',
    author: 'morff',
    lisence: 'CC BY 4.0',
    lisenceUrl: 'https://creativecommons.org/licenses/by/4.0/',
  },
  {
    title: 'Wires',
    url: 'https://sketchfab.com/3d-models/wires-fca671dcab294aff97fc422915e791ef',
    author: 'Bulat.Shakirov',
    lisence: 'CC BY 4.0',
    lisenceUrl: 'https://creativecommons.org/licenses/by/4.0/',
  },
  {
    title: 'Soda Vending Machine',
    url: 'https://sketchfab.com/3d-models/soda-vending-machine-f753a659faae499282e882d63972d4c2',
    author: 'RasenDan',
    lisence: 'CC BY 4.0',
    lisenceUrl: 'https://creativecommons.org/licenses/by/4.0/',
  },
  {
    title: 'Cyberpunk Sci-fi Door 3 - Free',
    url: 'https://sketchfab.com/3d-models/cyberpunk-sci-fi-door-3-free-df980eee6f0f42aaa52d70b668001b4d',
    author: 'Jesus Fernandez Garcia',
    lisence: 'CC BY 4.0',
    lisenceUrl: 'https://creativecommons.org/licenses/by/4.0/',
  },
  {
    title: 'Industrial Building Low poly',
    url: 'https://sketchfab.com/3d-models/industrial-building-low-poly-6ef545c00afd4b6a82f1160496acc2d7',
    author: 'Rayzn',
    lisence: 'CC BY 4.0',
    lisenceUrl: 'https://creativecommons.org/licenses/by/4.0/',
  },
  {
    title: 'Building - rectangular - sloped top',
    url: 'https://sketchfab.com/3d-models/building-rectangular-sloped-top-9d04c62d273848f6b13f166b5ad8154b',
    author: 'MozillaHubs',
    lisence: 'CC BY 4.0',
    lisenceUrl: 'https://creativecommons.org/licenses/by/4.0/',
  },
  {
    title: 'Building - beveled corners - shiny',
    url: 'https://sketchfab.com/3d-models/building-beveled-corners-shiny-c966bde825cd4254841a0e8b9252eee8',
    author: 'MozillaHubs',
    lisence: 'CC BY 4.0',
    lisenceUrl: 'https://creativecommons.org/licenses/by/4.0/',
  },
  {
    title: 'Building - square - illuminated',
    url: 'https://sketchfab.com/3d-models/building-square-illuminated-0a32caef1dfe492294d9ccf81361b5e9',
    author: 'MozillaHubs',
    lisence: 'CC BY 4.0',
    lisenceUrl: 'https://creativecommons.org/licenses/by/4.0/',
  },
  {
    title: 'Building - rectangle - 3 tier - illuminated',
    url: 'https://sketchfab.com/3d-models/building-rectangle-3-tier-illuminated-113492eac0154b14a6042cb8514c77b4',
    author: 'MozillaHubs',
    lisence: 'CC BY 4.0',
    lisenceUrl: 'https://creativecommons.org/licenses/by/4.0/',
  },
  {
    title: 'Building - stretched octagonal - tier',
    url: 'https://sketchfab.com/3d-models/building-stretched-octagonal-tier-0915a9de42a040b39a570ad091021527',
    author: 'MozillaHubs',
    lisence: 'CC BY 4.0',
    lisenceUrl: 'https://creativecommons.org/licenses/by/4.0/',
  },
  {
    title: 'NYC Bronx Buildings',
    url: 'https://sketchfab.com/3d-models/nyc-bronx-buildings-9092600d1d9b46b7881746d44d8bfc58',
    author: '99.Miles',
    lisence: 'CC BY 4.0',
    lisenceUrl: 'https://creativecommons.org/licenses/by/4.0/',
  },
  {
    title: 'Low Poly Brick Building',
    url: 'https://sketchfab.com/3d-models/low-poly-brick-building-a0cc89d6d7d94effaea4f267f02685ef',
    author: 'tl0615',
    lisence: 'CC BY 4.0',
    lisenceUrl: 'https://creativecommons.org/licenses/by/4.0/',
  },
  {
    title: 'Trash Can. Low Poly',
    url: 'https://sketchfab.com/3d-models/trash-can-low-poly-6dfba42794e445719010caf0a1ceca7c',
    author: 'katykate',
    lisence: 'CC BY 4.0',
    lisenceUrl: 'https://creativecommons.org/licenses/by/4.0/',
  },
  {
    title: 'Super Low Poly Dumpster',
    url: 'https://sketchfab.com/3d-models/super-low-poly-dumpster-3500f2cdab8a43e3a0072deb0971fa1f',
    author: 'Designed By Jonathan',
    lisence: 'CC BY 4.0',
    lisenceUrl: 'https://creativecommons.org/licenses/by/4.0/',
  },
  {
    title: 'Street Light',
    url: 'https://sketchfab.com/3d-models/street-light-b7dfecfd2dd34a5d8a67bf3e48666105',
    author: 'Algirdas Lalys',
    lisence: 'CC BY 4.0',
    lisenceUrl: 'https://creativecommons.org/licenses/by/4.0/',
  },
  {
    title: 'electric pole',
    url: 'https://sketchfab.com/3d-models/electric-pole-20645d411e0c4631b8e073263f35b08a',
    author: 'Pravda',
    lisence: 'CC BY 4.0',
    lisenceUrl: 'https://creativecommons.org/licenses/by/4.0/',
  },
  {
    title: 'Vending Machine',
    url: 'https://sketchfab.com/3d-models/vending-machine-3f9bb4ad017f4384b474f32e150430d9',
    author: 'furkankucukkisnisci',
    lisence: 'CC BY 4.0',
    lisenceUrl: 'https://creativecommons.org/licenses/by/4.0/',
  },
  {
    title: 'Fence [Low Poly]',
    url: 'https://sketchfab.com/3d-models/fence-low-poly-1b48d9745ea94233982556ecdbc0739c',
    author: 'Islide',
    lisence: 'CC BY 4.0',
    lisenceUrl: 'https://creativecommons.org/licenses/by/4.0/',
  },
  {
    title: 'Simple Sci-Fi Wall Panel',
    url: 'https://sketchfab.com/3d-models/simple-sci-fi-wall-panel-651ea9453d614cf6abfa7b0c09b57417',
    author: 'Mizuchi Sensei',
    lisence: 'CC BY 4.0',
    lisenceUrl: 'https://creativecommons.org/licenses/by/4.0/',
  },
  {
    title: 'TRON-ish low-poly drone',
    url: 'https://sketchfab.com/3d-models/tron-ish-low-poly-drone-cad1fc9ada864e06ab69a37705656392',
    author: 'Bachklamk',
    lisence: 'CC BY 4.0',
    lisenceUrl: 'https://creativecommons.org/licenses/by/4.0/',
  },
];

export const SPACE_ATTRIBUTIONS = [
  {
    title: 'Moon',
    url: 'https://sketchfab.com/3d-models/moon-fc1e78cfc65549c6a49e88ba599b7901',
    author: 'JanesBT',
    lisence: 'CC BY 4.0',
    lisenceUrl: 'https://creativecommons.org/licenses/by/4.0/',
  },
  {
    title: 'Saturn (planet)',
    url: 'https://sketchfab.com/3d-models/saturn-planet-9ab1eb3bb97f4e4a9305c0aae2d280a6',
    author: 'SebastianSosnowski',
    lisence: 'CC BY 4.0',
    lisenceUrl: 'https://creativecommons.org/licenses/by/4.0/',
  },
  {
    title: 'Jupiter',
    url: 'https://sketchfab.com/3d-models/jupiter-e00d35ab713a44dfb0a7450a79a6dd80',
    author: 'SebastianSosnowski',
    lisence: 'CC BY 4.0',
    lisenceUrl: 'https://creativecommons.org/licenses/by/4.0/',
  },
  {
    title: 'The Saturn Orbiter',
    url: 'https://sketchfab.com/3d-models/the-saturn-orbiter-f73f8788af1846f79e2d71d989a2df85',
    author: 'TWISTED Y',
    lisence: 'CC BY 4.0',
    lisenceUrl: 'https://creativecommons.org/licenses/by/4.0/',
  },
  {
    title: 'LOW POLY ASTRONAUT',
    url: 'https://sketchfab.com/3d-models/low-poly-astronaut-a0858f7876e1432b94c4c0571ee4e17a',
    author: 'Colin.Greenall',
    lisence: 'CC BY 4.0',
    lisenceUrl: 'https://creativecommons.org/licenses/by/4.0/',
  },
  // {
  //   title: 'Stylized Low Poly Sci-Fi Buildings',
  //   url: 'https://sketchfab.com/3d-models/stylized-low-poly-sci-fi-buildings-701381fef32444e79ad804315e563049',
  //   author: 'Robin Butler',
  //   lisence: 'CC BY-NC 4.0',
  //   lisenceUrl: 'https://creativecommons.org/licenses/by-nc/4.0/',
  // },
];

export const MARBLE_ATTRIBUTIONS = [
  {
    title: 'Victorian Lounge Sofa',
    url: 'https://sketchfab.com/3d-models/victorian-lounge-sofa-c7b7999b5c3d425bae23e74b669e97d6',
    author: 'Jamie McFarlane',
    lisence: 'CC BY 4.0',
    lisenceUrl: 'https://creativecommons.org/licenses/by/4.0/',
  },
  {
    title: 'Grand entrance, a pole with a sign. Gold, velour',
    url: 'https://sketchfab.com/3d-models/grand-entrance-a-pole-with-a-sign-gold-velour-7bb310edac154c5b9ee9c3d6e41f13e2',
    author: 'Rylae Shylna',
    lisence: 'CC BY 4.0',
    lisenceUrl: 'https://creativecommons.org/licenses/by/4.0/',
  },
];

export const LIGHTS_ATTRIBUTIONS = [
  {
    title: 'Ceiling Lamp',
    url: 'https://sketchfab.com/3d-models/ceiling-lamp-6d0200ce199b4c8e8645c2888731771d',
    author: 'MelaSoliToll',
    lisence: 'CC BY 4.0',
    lisenceUrl: 'https://creativecommons.org/licenses/by/4.0/',
  },
  {
    title: 'Ceiling lights',
    url: 'https://sketchfab.com/3d-models/ceiling-lights-99041d3a77794b30bbc814e14010db69',
    author: 'Speed',
    lisence: 'CC BY 4.0',
    lisenceUrl: 'https://creativecommons.org/licenses/by/4.0/',
  },
  {
    title: '1912 Electrolier Ceiling Lamps Type C',
    url: 'https://sketchfab.com/3d-models/1912-electrolier-ceiling-lamps-type-c-272c989bfcdb47ed91268bd548887d42',
    author: 'Mad_Lobster_Workshop',
    lisence: 'CC BY 4.0',
    lisenceUrl: 'https://creativecommons.org/licenses/by/4.0/',
  },
];

export const FRAME_ATTRIBUTIONS = [
  {
    frame: 'gold',
    title: 'Art Frame',
    url: 'https://sketchfab.com/3d-models/art-frame-25b86d7272e849f0967149243601bcbf',
    author: 'peterthanh84.tl',
    lisence: 'CC BY 4.0',
    lisenceUrl: 'https://creativecommons.org/licenses/by/4.0/',
  },
  {
    frame: 'frame2',
    title: 'Art Painting Frame',
    url: 'https://sketchfab.com/3d-models/art-painting-frame-117946e14b8545a69e962fce2b8a5022',
    author: 'Valter Medeiros',
    lisence: 'CC BY 4.0',
    lisenceUrl: 'https://creativecommons.org/licenses/by/4.0/',
  },
  {
    frame: 'frame3',
    title: 'Victorian Framed Painting - PBR Game Ready',
    url: 'https://sketchfab.com/3d-models/victorian-framed-painting-pbr-game-ready-b2895c1c3b42401a949deac049e0051d',
    author: 'Matthew Collings',
    lisence: 'CC BY 4.0',
    lisenceUrl: 'https://creativecommons.org/licenses/by/4.0/',
  },
  {
    frame: 'frame5',
    title: 'Picture Frame_11MB',
    url: 'https://sketchfab.com/3d-models/picture-frame-11mb-a956f84145ce4909831fab022510701a',
    author: 'Mehdi Shahsavan',
    lisence: 'CC BY 4.0',
    lisenceUrl: 'https://creativecommons.org/licenses/by/4.0/',
  },
  {
    frame: 'frame6',
    title: 'Picture Frame',
    url: 'https://sketchfab.com/3d-models/picture-frame-01006278a27e402c9ccf5a69358c3add',
    author: 'Samuel F. Johanns (Oneironauticus)',
    lisence: 'CC BY 4.0',
    lisenceUrl: 'https://creativecommons.org/licenses/by/4.0/',
  },
];
