import React from 'react';

const MuseumWall = () => {
  return (
    <>
      <group>
        <mesh position={[0, 1.8, 2]}>
          <planeGeometry args={[150, 3]} receiveShadow />
          <meshStandardMaterial
            color='#ffffff'
            roughness={0.8}
            metalness={0.1}
          />
        </mesh>
      </group>
    </>
  );
};

export default MuseumWall;
