import {
  BakeShadows,
  Cloud,
  Environment,
  OrbitControls,
  Sparkles,
  useHelper,
} from '@react-three/drei';
import { EffectComposer } from '@react-three/postprocessing';
import React, { Suspense, useContext, useEffect, useMemo, useRef } from 'react';
import Loader from '../Loader';
import Items from '../Items';

import Woman1 from '../models/Woman1';
import Kids1 from '../models/Kids1';
import Woman2 from '../models/Woman2';
import Woman3 from '../models/Woman3';
import Man1 from '../models/Man1';
import Man2 from '../models/Man2';
import Man3 from '../models/Man3';
import Kids2 from '../models/Kids2';
import Kids3 from '../models/Kids3';

import MovingSpotLight from '../lights/MovingSpotLight';
import * as THREE from 'three';
import { TextureLoader } from 'three';

import { ExhibitionControllerContext } from '../../../context/ExhibitionControllerContext';
import { ExhibitionContext } from '../../../context/ExhibitionContext';
import SpaceFloor from '../floors/SpaceFloor';
import Moon from '../models/Moon';
import SFBuilding1 from '../models/SFBuilding1';
import SFBuilding2 from '../models/SFBuilding2';
import SFBuilding3 from '../models/SFBuilding3';
import Meteorite1 from '../models/Meteorite1';
import Jupiter from '../models/Jupiter';
import Saturn from '../models/Saturn';
import SaturnOrbiter from '../models/SaturnOrbiter.glb';
import LowPolyAstronaut from '../models/LowPolyAstronaut';
import { useLoader, useThree } from '@react-three/fiber';
import ItemOnPreview from './ItemOnPreview';
import SpotLightOnPreview from '../lights/SpotLightOnPreview';

// import MovingSpotLight from '../lights/MovingSpotLight';

const SpacePreview = ({
  theme,
  frame,
  showSilhouette,
  setSnapshot,
  items,
  frameColor,
  matColor,
}) => {
  const GOLDENRATIO = 1.61803398875;
  const { camera, gl } = useThree();

  const imageMap = useLoader(
    TextureLoader,
    items.length > 0 ? items[0]?.itemUrl : '/assets/img/sample_item_image.png'
  );

  const aspectRatio =
    imageMap.source?.data?.width / imageMap.source?.data?.height;
  const scaleValues = useMemo(() => {
    const scaleY = GOLDENRATIO;
    const scaleX = scaleY * aspectRatio;
    return { scaleX, scaleY };
  }, [aspectRatio]);
  const bgColor = '#191920';

  const directLightIntensity = 0.8;
  const ambientLightIntensity = 0.6;

  useEffect(() => {
    return () => {
      gl.dispose();
      // imageMap.dispose();
      useLoader.clear(
        TextureLoader,
        items.length > 0
          ? items[0]?.itemUrl
          : '/assets/img/sample_item_image.png'
      );
    };
  }, [gl]);

  useEffect(() => {
    camera.lookAt(0, 0.8, 1.1);
  }, [camera]);

  return (
    <>
      {/* <OrbitControls /> */}
      {/* <BakeShadows /> */}
      <EffectComposer>{/* <Bloom intensity={0.1} /> */}</EffectComposer>
      <Suspense fallback={<Loader />}>
        <color attach='background' args={[`${bgColor}`]} />
        <fog attach='fog' args={[`${bgColor}`, 6, 75]} />
        <directionalLight
          // ref={dirLight}
          position={[0, 2, 20]}
          target-position={[0, 2.5, 0]}
          intensity={directLightIntensity}
          color={'#110073'}
          // color={'#fcf8cc'}
          // color={'#ffffff'}
          // castShadow
        />

        <ambientLight intensity={ambientLightIntensity} color={'#ffffff'} />
        <SpotLightOnPreview
          opacity={0.1 * 2}
          anglePower={0}
          angle={0.5}
          position={[-0.1, 3.2, 4]}
          targetPosition={[0, -2, 1]}
        />

        <LowPolyAstronaut
          scale={0.3}
          rotation={[0, Math.PI, -Math.PI / 12]}
          position={[10, 1, -5]}
        />
        {/* <LowPolyAstronaut
          scale={0.3}
          rotation={[0, Math.PI, Math.PI / 18]}
          position={[43, 1, -5]}
        /> */}
        {/* <SFBuilding1
          scale={7}
          position={[-5, -0.7, -9]}
          rotation={[0, -Math.PI / 2, 0]}
        /> */}
        {/* <SFBuilding2
          scale={1}
          position={[30, -0.5, -6]}
          rotation={[0, -Math.PI / 2, 0]}
        />
        <SFBuilding3
          scale={1}
          position={[56, -0.7, -14]}
          rotation={[0, -Math.PI, 0]}
        /> */}
        <Moon position={[-35, 18, -45]} scale={5} />
        <group>
          {/* <Meteorite1 scale={0.01} position={[8, 22, -55]} />
          <Meteorite1 scale={0.015} position={[6, 20, -55]} /> */}
          <Saturn
            position={[20, 20, -65]}
            rotation={[Math.PI / 7, 0, Math.PI / 10]}
            scale={7}
          />
          <SaturnOrbiter
            rotation={[0, 0, Math.PI / 5]}
            position={[36, 26, -65]}
            scale={1.4}
          />
        </group>
        {/* <Jupiter position={[90, 35, -55]} scale={0.8} /> */}

        <Sparkles
          position={[0, 0, -10]}
          count={300}
          scale={40}
          size={8}
          speed={0.6}
          opacity={1}
          color={'skyblue'}
        />
        <Sparkles
          position={[40, 0, -10]}
          count={200}
          scale={40}
          size={8}
          speed={0.6}
          opacity={1}
          color={'orange'}
        />

        <group position={[0, -0.5, 0]}>
          {items.length == 1 && (
            <ItemOnPreview
              position={[-0.2, 0.85, 3]}
              item={items[0]}
              frame={frame}
              frameColor={frameColor}
              matColor={matColor}
            />
          )}
          {items.length == 2 && (
            <>
              <ItemOnPreview
                position={[-1.3, 0.85, 3]}
                item={items[0]}
                frame={frame}
                frameColor={frameColor}
                matColor={matColor}
              />

              <ItemOnPreview
                position={[1.3, 0.85, 3]}
                item={items[1]}
                frame={frame}
                frameColor={frameColor}
                matColor={matColor}
              />
            </>
          )}
          {items.length >= 3 && (
            <>
              <ItemOnPreview
                position={[-3.6, 0.85, 3]}
                item={items[0]}
                frame={frame}
                frameColor={frameColor}
                matColor={matColor}
              />

              <ItemOnPreview
                position={[-0.2, 0.85, 3]}
                item={items[1]}
                frame={frame}
                frameColor={frameColor}
                matColor={matColor}
              />

              <ItemOnPreview
                position={[3.6, 0.85, 3]}
                item={items[2]}
                frame={frame}
                frameColor={frameColor}
                matColor={matColor}
              />
            </>
          )}
          {showSilhouette && (
            <Man1 position={[-0.5, 0, 3.8]} scale={0.45} rotation-y={Math.PI} />
          )}

          {/* floor */}
          <SpaceFloor />
        </group>
        <Environment
          background
          backgroundIntensity={0.1}
          backgroundBlurriness={0}
          files={
            'https://data.curatedartshow.com/hdri/NightSkyHDRI009_1K-HDR.exr'
          }
        />
      </Suspense>
    </>
  );
};

export default SpacePreview;
