import { useGLTF } from '@react-three/drei';
import { Suspense, useLayoutEffect, useState } from 'react';

const PictureFrame64 = ({ color, ...props }) => {
  const { scene, materials } = useGLTF(
    'https://data.curatedartshow.com/models/frames/jenaframe7.glb'
  );

  const [geometry, setGeometry] = useState();

  if (!geometry) {
    const cloneScene = scene.clone(true);
    setGeometry(cloneScene);
  }

  useLayoutEffect(() => {
    // console.log(materials);
    materials['Red Stone_material'].color.set('#855f06');
    materials['Red Stone_material'].metalness = 0.6;
    materials['Red Stone_material'].roughness = 2;
    materials['Blue Spray Metallic Paint_material'].color.set('#062785');
    materials['Blue Spray Metallic Paint_material'].metalness = 1;
    materials['Blue Spray Metallic Paint_material'].roughness = 0.9;
    materials['High Gloss Paper_material'].color.set('#5a5a5a');
    materials['High Gloss Paper_material'].metalness = 0.1;
    materials['High Gloss Paper_material'].roughness = 4;

    // materials['default_material107.003'].color.set('#7c87cd');
    // materials['default_material107.003'].metalness = 1;
    // materials['default_material107.003'].roughness = 0;
    // materials['Grid_material'].color.set('#7c87cd');
    materials['Grid_material'].metalness = 1;
    materials['Grid_material'].roughness = 1.5;

    geometry.traverse((obj) => {
      if (obj.isMesh) obj.castShadow = obj.receiveShadow = true;
    });
  }, []);

  return (
    <Suspense fallback={null}>
      <primitive object={geometry} {...props} />
    </Suspense>
  );
};

export default PictureFrame64;
