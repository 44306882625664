import { useDispatch, useSelector } from 'react-redux';
import LoadingSpinner from '../../../components/LoadingSpinner';
import { useEffect, useState } from 'react';
import { authRequest } from '../../../requestMethods';
import { errorToast, successToast } from '../../../utils/toast';
import { useTranslation, Trans } from 'react-i18next';
import { MinusIcon } from '@heroicons/react/24/outline';
import { loadEnd, loadStart } from '../../../redux/loadRedux';

const ChangePasswordModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.isLoading);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [currentPasswordError, setCurrentPasswordError] = useState('');
  const [newPasswordError, setNewPasswordError] = useState('');

  const [uppercaseValid, setUppercaseValid] = useState(false);
  const [lowercaseValid, setLowercaseValid] = useState(false);
  const [numericValid, setNumericValid] = useState(false);
  const [charValid, setCharValid] = useState(false);
  const [lengthValid, setLengthValid] = useState(false);
  const [passwordValid, setPasswordValid] = useState(false);
  const [typed, setTyped] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(loadStart());
    try {
      await authRequest.post(`/auth/change-password`, {
        currentPassword,
        newPassword,
        confirmPassword: newPassword,
      });
      document.getElementById('change_password_modal').close();
      successToast(t('message.success.t5-1'));
    } catch (err) {
      console.error(err);
      switch (err.response.data.message) {
        case 'Wrong Password':
          setCurrentPasswordError(t('message.error.t12'));
          break;
        default:
          errorToast(t('message.error.t1'));
          break;
      }
    } finally {
      dispatch(loadEnd());
    }
  };

  const passwordValidation = () => {
    const lowerCaseLetters = /[a-z]/g;
    const upperCaseLetters = /[A-Z]/g;

    const numbers = /[0-9]/g;
    const characters = /[-!$%^&*()_+|~=`{}\[\]:\/;<>?,.@#]/g;

    if (newPassword.match(lowerCaseLetters)) {
      setLowercaseValid(true);
    } else {
      setLowercaseValid(false);
    }

    if (newPassword.match(upperCaseLetters)) {
      setUppercaseValid(true);
    } else {
      setUppercaseValid(false);
    }
    if (newPassword.match(numbers)) {
      setNumericValid(true);
    } else {
      setNumericValid(false);
    }
    if (newPassword.match(characters)) {
      setCharValid(true);
    } else {
      setCharValid(false);
    }

    if (newPassword.length >= 8 && newPassword.length <= 20) {
      setLengthValid(true);
    } else {
      setLengthValid(false);
    }
  };

  useEffect(() => {
    if (
      uppercaseValid &&
      lowercaseValid &&
      numericValid &&
      lengthValid &&
      charValid
    ) {
      setPasswordValid(true);
    } else {
      setPasswordValid(false);
    }
  }, [uppercaseValid, lowercaseValid, numericValid, lengthValid, charValid]);

  useEffect(() => {
    const modal = document.getElementById('change_password_modal');

    // モーダルの属性を監視
    const observer = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (
          mutation.type === 'attributes' &&
          mutation.attributeName === 'open'
        ) {
          if (!modal.hasAttribute('open')) {
            setTyped(false);
            setCurrentPassword('');
            setCurrentPasswordError('');
            setNewPassword('');
            setNewPasswordError('');
            setPasswordValid(false);
            setLowercaseValid(false);
            setUppercaseValid(false);
            setNumericValid(false);
            setCharValid(false);
            setLengthValid(false);
            setShowCurrentPassword(false);
            setShowNewPassword(false);
          }
        }
      }
    });

    // 監視の開始
    observer.observe(modal, { attributes: true });

    // クリーンアップ関数で監視を停止
    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <dialog id='change_password_modal' className='modal'>
      <div className='modal-box !max-w-[32rem] !p-6'>
        <h3 className='mb-6 mt-0 text-lg font-bold'>
          <Trans i18nKey={'change-password-modal.ttl'} />
        </h3>

        <label className='form-control w-full'>
          <div className='label'>
            <span className='label-text font-medium'>
              <Trans i18nKey={'change-password-modal.current-password'} />
            </span>
          </div>
          <div className='relative'>
            <input
              value={currentPassword}
              className={`input input-bordered w-full ${
                currentPasswordError && 'input-error'
              }`}
              maxLength='20'
              type={showCurrentPassword ? 'text' : 'password'}
              onChange={(e) => {
                setCurrentPassword(e.target.value);
              }}
            />
            <span
              className='absolute right-3 top-1/2 -translate-y-2/4 cursor-pointer text-sm'
              onClick={() => setShowCurrentPassword(!showCurrentPassword)}
            >
              Show
            </span>
          </div>
          <div className='label'>
            <span className='label-text-alt text-sm font-medium text-error'>
              {currentPasswordError}
            </span>
          </div>
        </label>

        <label className='form-control w-full'>
          <div className='label'>
            <span className='label-text font-medium'>
              <Trans i18nKey={'change-password-modal.new-password'} />
            </span>
          </div>
          <div className='relative'>
            <input
              value={newPassword}
              className={`input input-bordered w-full ${
                newPasswordError && 'input-error'
              }`}
              maxLength='20'
              type={showNewPassword ? 'text' : 'password'}
              onChange={(e) => {
                setNewPassword(e.target.value);
              }}
              onKeyUp={() => {
                passwordValidation();
                setTyped(true);
              }}
            />
            <span
              className='absolute right-3 top-1/2 -translate-y-2/4 cursor-pointer text-sm'
              onClick={() => setShowNewPassword(!showNewPassword)}
            >
              Show
            </span>
          </div>
          <div className='label'>
            <span className='label-text-alt text-sm font-medium text-error'>
              {newPasswordError}
            </span>
          </div>
        </label>

        <div className='flex flex-col gap-2'>
          <div
            className={`flex items-center gap-2 text-xs font-medium ${
              !typed ? '' : lowercaseValid ? 'text-success' : 'text-error'
            }`}
          >
            <MinusIcon className={`h-4 w-4`} />
            <Trans i18nKey={'signup.top.lowercase'} />
          </div>
          <div
            className={`flex items-center gap-2 text-xs font-medium ${
              !typed ? '' : uppercaseValid ? 'text-success' : 'text-error'
            }`}
          >
            <MinusIcon className={`h-4 w-4`} />
            <Trans i18nKey={'signup.top.uppercase'} />
          </div>
          <div
            className={`flex items-center gap-2 text-xs font-medium ${
              !typed ? '' : numericValid ? 'text-success' : 'text-error'
            }`}
          >
            <MinusIcon className={`h-4 w-4`} />
            <Trans i18nKey={'signup.top.number'} />
          </div>
          <div
            className={`flex items-center gap-2 text-xs font-medium ${
              !typed ? '' : charValid ? 'text-success' : 'text-error'
            }`}
          >
            <MinusIcon className={`h-4 w-4`} />
            <Trans i18nKey={'signup.top.special-character'} />
          </div>
          <div
            className={`flex items-center gap-2 text-xs font-medium ${
              !typed ? '' : lengthValid ? 'text-success' : 'text-error'
            }`}
          >
            <MinusIcon className={`h-4 w-4`} />
            <Trans i18nKey={'signup.top.8-20-letters'} />
          </div>
        </div>

        <div className='modal-action mb-0 mt-6'>
          <form method='dialog'>
            <button className='btn btn-ghost mr-2'>
              <Trans i18nKey={'btn.cancel'} />
            </button>
            <button
              className='btn btn-primary'
              onClick={handleSubmit}
              disabled={!newPassword || !currentPassword || !passwordValid}
            >
              <Trans i18nKey={'btn.save'} />
            </button>
          </form>
        </div>
      </div>

      {isLoading && <LoadingSpinner />}
    </dialog>
  );
};

export default ChangePasswordModal;
