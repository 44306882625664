import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LoadingSpinner from '../components/LoadingSpinner';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { publicRequest } from '../requestMethods';
import {
  AdjustmentsHorizontalIcon,
  BuildingLibraryIcon,
  CubeIcon,
  DevicePhoneMobileIcon,
  LightBulbIcon,
  MinusIcon,
  PaperAirplaneIcon,
  PhotoIcon,
  ViewfinderCircleIcon,
} from '@heroicons/react/24/outline';
import { HashLink } from 'react-router-hash-link';
import { useScrollToAnchor } from '../hooks/useScrollToAnchor';
import { validateEmail } from '../utils/validation';
import { loadEnd, loadStart } from '../redux/loadRedux';
import { errorToast } from '../utils/toast';
import { signupFailure, signupStart, signupSuccess } from '../redux/userRedux';
import PriceCards from '../components/PriceCards';

const CAS_GALLERY_IMAGES = [
  {
    src: '/assets/img/slide_exhibition_1.webp',
    srcSp: '/assets/img/slide_exhibition_1_sp.webp',
    alt: 'Curated Art Show (CAS) Wide Range of Presentations',
  },
  {
    src: '/assets/img/slide_exhibition_2.webp',
    srcSp: '/assets/img/slide_exhibition_2_sp.webp',
    alt: 'Curated Art Show (CAS) Wide Range of Presentations',
  },
  {
    src: '/assets/img/slide_exhibition_3.webp',
    srcSp: '/assets/img/slide_exhibition_3_sp.webp',
    alt: 'Curated Art Show (CAS) Wide Range of Presentations',
  },
  {
    src: '/assets/img/slide_exhibition_4.webp',
    srcSp: '/assets/img/slide_exhibition_4_sp.webp',
    alt: 'Curated Art Show (CAS) Wide Range of Presentations',
  },
  {
    src: '/assets/img/slide_exhibition_5.webp',
    srcSp: '/assets/img/slide_exhibition_5_sp.webp',
    alt: 'Curated Art Show (CAS) Wide Range of Presentations',
  },
  {
    src: '/assets/img/slide_exhibition_6.webp',
    srcSp: '/assets/img/slide_exhibition_6_sp.webp',
    alt: 'Curated Art Show (CAS) Wide Range of Presentations',
  },
];

const CAS_ADMIN_IMAGES = [
  {
    src: '/assets/img/slide_admin_1.webp',
    srcSp: '/assets/img/slide_admin_1_sp.webp',
    alt: 'Curated Art Show (CAS) Use Presets or Detailed Customization',
  },
  {
    src: '/assets/img/slide_admin_2.webp',
    srcSp: '/assets/img/slide_admin_2_sp.webp',
    alt: 'Curated Art Show (CAS) Use Presets or Detailed Customization',
  },
  {
    src: '/assets/img/slide_admin_3.webp',
    srcSp: '/assets/img/slide_admin_3_sp.webp',
    alt: 'Curated Art Show (CAS) Use Presets or Detailed Customization',
  },
  {
    src: '/assets/img/slide_admin_4.webp',
    srcSp: '/assets/img/slide_admin_4_sp.webp',
    alt: 'Curated Art Show (CAS) Use Presets or Detailed Customization',
  },
  {
    src: '/assets/img/slide_admin_5.webp',
    srcSp: '/assets/img/slide_admin_5_sp.webp',
    alt: 'Curated Art Show (CAS) Use Presets or Detailed Customization',
  },
  {
    src: '/assets/img/slide_admin_6.webp',
    srcSp: '/assets/img/slide_admin_6_sp.webp',
    alt: 'Curated Art Show (CAS) Use Presets or Detailed Customization',
  },
];

const Discovery = () => {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.isLoading);
  const swiperExhibitionRef = useRef();
  const swiperAdminRef = useRef();
  const { i18n } = useTranslation();

  // Sign up
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [success, setSuccess] = useState(false);
  const [uppercaseValid, setUppercaseValid] = useState(false);
  const [lowercaseValid, setLowercaseValid] = useState(false);
  const [numericValid, setNumericValid] = useState(false);
  const [charValid, setCharValid] = useState(false);
  const [lengthValid, setLengthValid] = useState(false);
  const [passwordValid, setPasswordValid] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [hasPasswordTyped, setHasPasswordTyped] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [hasTriedSignUp, setHasTriedSignUp] = useState(false);

  const reflectEmail = (email) => {
    const result = validateEmail(email);
    result.isValid ? setEmailError('') : setEmailError(result.message);
    setEmail(email);
  };
  const passwordValidation = (password) => {
    const lowerCaseLetters = /[a-z]/g;
    const upperCaseLetters = /[A-Z]/g;

    const numbers = /[0-9]/g;
    const characters = /[-!$%^&*()_+|~=`{}\[\]:\/;<>?,.@#]/g;

    if (password.match(lowerCaseLetters)) {
      setLowercaseValid(true);
    } else {
      setLowercaseValid(false);
    }

    if (password.match(upperCaseLetters)) {
      setUppercaseValid(true);
    } else {
      setUppercaseValid(false);
    }
    if (password.match(numbers)) {
      setNumericValid(true);
    } else {
      setNumericValid(false);
    }
    if (password.match(characters)) {
      setCharValid(true);
    } else {
      setCharValid(false);
    }

    if (password.length >= 8) {
      setLengthValid(true);
    } else {
      setLengthValid(false);
    }
  };
  useEffect(() => {
    if (
      uppercaseValid &&
      lowercaseValid &&
      numericValid &&
      lengthValid &&
      charValid
    ) {
      setPasswordValid(true);
    } else {
      setPasswordValid(false);
    }
  }, [uppercaseValid, lowercaseValid, numericValid, lengthValid, charValid]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setHasTriedSignUp(true);
    if (!isConfirmed || !passwordValid || email === '') {
      return;
    }
    setEmailError('');
    dispatch(loadStart());
    dispatch(signupStart());
    try {
      await publicRequest.post(`/auth/signup`, {
        email,
        password,
        language: i18n.language,
      });
      dispatch(loadEnd());
      dispatch(signupSuccess());
      setSuccess(true);
    } catch (err) {
      console.log(err);
      const message = err.response.data.message;
      if (message.includes('email')) {
        setEmailError(message);
      } else {
        errorToast(message);
      }
      dispatch(signupFailure());
      dispatch(loadEnd());
    }
  };

  // アンカーリンク
  const { scrollToAnchor } = useScrollToAnchor({ smooth: true });
  const pricingRef = useRef(null);

  // DOMを参照できるようになったタイミングで発火
  useEffect(() => {
    scrollToAnchor();
  }, [pricingRef.current, scrollToAnchor]);

  return (
    <>
      {/* Hero */}
      <section className='bg-base-content'>
        <div className='flex w-full flex-col items-center gap-10 px-4 py-20 lg:px-[4.375rem] lg:pt-28'>
          {/* 上部 */}
          <div className='flex flex-col gap-5'>
            <h1 className='text-center !font-outfit text-[40px] font-black leading-none text-white md:text-[4.45vw]'>
              <Trans i18nKey={'top.ttl'} />
            </h1>
            <h2 className='text-center !font-outfit text-[30px] font-black leading-none text-white md:text-[3vw]'>
              <Trans i18nKey={'top.sub-ttl'} />
            </h2>
            <p className='mx-auto max-w-[630px] text-center text-xs font-medium text-white md:text-lg'>
              <Trans i18nKey={'top.desc'} />
            </p>
            <div className='hidden flex-col items-center justify-center gap-5 md:flex md:flex-row'>
              <HashLink to='#pricing' className='btn btn-primary btn-wide'>
                <Trans i18nKey={'btn.compare-plans'} />
              </HashLink>
              <Link to={'/signup'} className='btn btn-accent btn-wide'>
                <Trans i18nKey={'btn.free-to-use'} />
              </Link>
            </div>
          </div>
          {/* 下部 */}
          <div className='relative h-full w-full overflow-hidden rounded-3xl bg-primary'>
            {/* 横縦比  PC 16:9  SP 1:2 */}
            <div className='relative bg-primary pt-[200%] sm:pt-[56.25%]'>
              <video
                src='/assets/movie/hero_video.mp4'
                autoPlay
                muted
                playsInline
                loop
                disablePictureInPicture
                className='absolute inset-0 h-full w-full object-cover'
                poster='/assets/img/poster.webp'
              ></video>
            </div>
          </div>
          <div className='flex flex-col items-center justify-center gap-5 md:hidden md:flex-row'>
            <HashLink to='#pricing' className='btn btn-primary btn-wide'>
              <Trans i18nKey={'btn.compare-plans'} />
            </HashLink>
            <Link to={'/signup'} className='btn btn-accent btn-wide'>
              <Trans i18nKey={'btn.free-to-use'} />
            </Link>
          </div>
        </div>
      </section>

      {/* Feature */}
      <section className='mx-auto w-full max-w-[1064px] px-4 py-10 md:px-8'>
        <p className='mb-10 text-center !font-outfit text-[40px] font-semibold sm:mt-10 sm:text-5xl'>
          <Trans i18nKey={'top.what-is-cas-ttl'} />
        </p>
        <div className='grid grid-cols-1 gap-5 sm:grid-cols-2'>
          <div className='flex flex-col rounded-md border-[2.5px] border-primary bg-base-100 p-10 shadow'>
            <div className='flex h-20 w-20 items-center justify-center rounded-full bg-blue-100'>
              <PhotoIcon className='h-14 w-14 text-primary' />
            </div>
            <p className='mb-4 mt-6 text-2xl font-bold'>
              <Trans i18nKey={'top.what-is-cas-t1-ttl'} />
            </p>
            <p className='text-xl font-medium'>
              <Trans i18nKey={'top.what-is-cas-t1'} />
            </p>
          </div>
          <div className='flex flex-col rounded-md border-[2.5px] border-primary bg-base-100 p-10 shadow'>
            <div className='flex h-20 w-20 items-center justify-center rounded-full bg-blue-100'>
              <AdjustmentsHorizontalIcon className='h-14 w-14 text-primary' />
            </div>
            <p className='mb-4 mt-6 text-2xl font-bold'>
              <Trans i18nKey={'top.what-is-cas-t2-ttl'} />
            </p>
            <p className='text-xl font-medium'>
              <Trans i18nKey={'top.what-is-cas-t2'} />
            </p>
          </div>
          <div className='flex flex-col rounded-md border-[2.5px] border-primary bg-base-100 p-10 shadow'>
            <div className='flex h-20 w-20 items-center justify-center rounded-full bg-blue-100'>
              <PaperAirplaneIcon className='h-14 w-14 text-primary' />
            </div>
            <p className='mb-4 mt-6 text-2xl font-bold'>
              <Trans i18nKey={'top.what-is-cas-t3-ttl'} />
            </p>
            <p className='text-xl font-medium'>
              <Trans i18nKey={'top.what-is-cas-t3'} />
            </p>
          </div>
          <div className='flex flex-col rounded-md border-[2.5px] border-primary bg-base-100 p-10 shadow'>
            <div className='flex h-20 w-20 items-center justify-center rounded-full bg-blue-100'>
              <CubeIcon className='h-14 w-14 text-primary' />
            </div>
            <p className='mb-4 mt-6 text-2xl font-bold'>
              <Trans i18nKey={'top.what-is-cas-t4-ttl'} />
            </p>
            <p className='text-xl font-medium'>
              <Trans i18nKey={'top.what-is-cas-t4'} />
            </p>
          </div>
          <div className='flex flex-col rounded-md border-[2.5px] border-primary bg-base-100 p-10 shadow'>
            <div className='flex h-20 w-20 items-center justify-center rounded-full bg-blue-100'>
              <BuildingLibraryIcon className='h-14 w-14 text-primary' />
            </div>
            <p className='mb-4 mt-6 text-2xl font-bold'>
              <Trans i18nKey={'top.what-is-cas-t5-ttl'} />
            </p>
            <p className='text-xl font-medium'>
              <Trans i18nKey={'top.what-is-cas-t5'} />
            </p>
          </div>
          <div className='flex flex-col rounded-md border-[2.5px] border-primary bg-base-100 p-10 shadow'>
            <div className='flex h-20 w-20 items-center justify-center rounded-full bg-blue-100'>
              <DevicePhoneMobileIcon className='h-14 w-14 text-primary' />
            </div>
            <p className='mb-4 mt-6 text-2xl font-bold'>
              <Trans i18nKey={'top.what-is-cas-t6-ttl'} />
            </p>
            <p className='text-xl font-medium'>
              <Trans i18nKey={'top.what-is-cas-t6'} />
            </p>
          </div>
          <div className='flex flex-col rounded-md border-[2.5px] border-primary bg-base-100 p-10 shadow'>
            <div className='flex h-20 w-20 items-center justify-center rounded-full bg-blue-100'>
              <ViewfinderCircleIcon className='h-14 w-14 text-primary' />
            </div>
            <p className='mb-4 mt-6 text-2xl font-bold'>
              <Trans i18nKey={'top.what-is-cas-t7-ttl'} />
            </p>
            <p className='text-xl font-medium'>
              <Trans i18nKey={'top.what-is-cas-t7'} />
            </p>
          </div>
          <div className='flex flex-col rounded-md border-[2.5px] border-primary bg-base-100 p-10 shadow'>
            <div className='flex h-20 w-20 items-center justify-center rounded-full bg-blue-100'>
              <LightBulbIcon className='h-14 w-14 text-primary' />
            </div>
            <p className='mb-4 mt-6 text-2xl font-bold'>
              <Trans i18nKey={'top.what-is-cas-t8-ttl'} />
            </p>
            <p className='text-xl font-medium'>
              <Trans i18nKey={'top.what-is-cas-t8'} />
            </p>
          </div>
        </div>
      </section>

      {/* Promotion */}
      <section className='flex flex-col items-center gap-20 bg-[#E9F9F1] px-4 py-[120px] lg:flex-row lg:px-8 lg:py-40 xl:px-[224px]'>
        <div className='mx-auto flex max-w-[492px] flex-col items-center gap-6 rounded-2xl bg-base-100 p-10 shadow-xl'>
          <p className='text-xl font-bold'>
            <Trans i18nKey={'top.promotion-card'} />
          </p>
          <Link to={'/signup'} className='btn btn-accent self-end lg:btn-wide'>
            <Trans i18nKey={'btn.free-to-use'} />
          </Link>
        </div>
        <div className='max-w-[492px]'>
          <p className='!font-outfit text-[2.5rem] font-semibold text-[#00B5A0]'>
            <Trans i18nKey={'top.promotion-desc'} />
          </p>
        </div>
      </section>

      {/* Easy 4-Step Process */}
      <section className='flex flex-col items-center justify-center gap-20 px-4 py-[120px] lg:flex-row lg:px-8 lg:py-40 2xl:px-[224px]'>
        <div className='flex w-full max-w-[492px] items-center justify-center'>
          <h2 className='!font-outfit text-[40px] font-semibold lg:text-5xl'>
            <Trans i18nKey={'top.easy-process-ttl'} />
          </h2>
        </div>
        <div className='w-full max-w-[492px]'>
          <ul className='steps steps-vertical'>
            <li className='step step-primary'>
              <span className='text-left text-xl font-medium'>
                <Trans i18nKey={'top.easy-process-1'} />
              </span>
            </li>
            <li className='step step-primary'>
              <span className='text-left text-xl font-medium'>
                <Trans i18nKey={'top.easy-process-2'} />
              </span>
            </li>
            <li className='step step-primary'>
              <span className='text-left text-xl font-medium'>
                <Trans i18nKey={'top.easy-process-3'} />
              </span>
            </li>
            <li className='step step-primary'>
              <span className='text-left text-xl font-medium'>
                <Trans i18nKey={'top.easy-process-4'} />
              </span>
            </li>
          </ul>
        </div>
      </section>

      {/* simulator */}
      <section
        id='simulator'
        className='flex flex-col items-center gap-10 bg-base-content px-4 py-20 lg:px-0'
      >
        <div className='flex flex-col gap-8'>
          <h2 className='text-center !font-outfit text-[64px] font-semibold leading-tight text-white'>
            <Trans i18nKey={'top.simulator-ttl'} />
          </h2>
          <p className='text-center text-2xl font-bold text-white'>
            <Trans i18nKey={'top.simulator-desc'} />
          </p>
        </div>
        <img
          src='/assets/img/exhibition_simulator.webp'
          className='w-[40.5rem]'
        />
        <Link to={'/simulator'} className='btn btn-lg bg-white  font-semibold'>
          <Trans i18nKey={'top.simulator-btn'} />
        </Link>
      </section>

      {/* Wide Range of Presentations */}
      <section className='mx-auto mt-10 w-full max-w-[1064px] px-4 py-20 md:px-0'>
        <div className='flex flex-col gap-10'>
          <h2 className='text-center !font-outfit text-[40px] font-semibold lg:text-5xl'>
            <Trans i18nKey={'top.feature-5-ttl'} />
          </h2>
          <Swiper
            className='relative w-full'
            onBeforeInit={(swiper) => {
              swiperExhibitionRef.current = swiper;
            }}
            loop={true}
            centeredSlides={true}
            autoplay={{
              delay: 5000,
              disableOnInteraction: false,
            }}
            modules={[Autoplay, Pagination, Navigation]}
          >
            <button
              className='absolute left-0 top-[50%] z-10 flex h-12 w-12 -translate-y-1/2 items-center justify-center rounded-full bg-base-200'
              onClick={() => swiperExhibitionRef.current?.slidePrev()}
            >
              <ChevronLeftIcon className='h-6 w-6' />
            </button>
            {CAS_GALLERY_IMAGES.map((item) => {
              return (
                <SwiperSlide key={item.src}>
                  <picture>
                    <source srcSet={item.src} media='(min-width: 640px)' />
                    <img src={item.srcSp} alt={item.alt} className='mx-auto' />
                  </picture>
                </SwiperSlide>
              );
            })}
            <button
              className='absolute right-0 top-[50%] z-10 flex h-12 w-12 -translate-y-1/2 items-center justify-center rounded-full bg-base-200'
              onClick={() => swiperExhibitionRef.current?.slideNext()}
            >
              <ChevronRightIcon className='h-6 w-6' />
            </button>
          </Swiper>
        </div>
      </section>

      {/* Use Presets or Your Own Customizations */}
      <section className='mx-auto mt-10 w-full max-w-[1064px] px-4 py-20 md:px-0'>
        <div className='flex flex-col gap-10'>
          <h2 className='text-center !font-outfit text-[40px] font-semibold lg:text-5xl'>
            <Trans i18nKey={'top.feature-6-ttl'} />
          </h2>
          <Swiper
            className='relative w-full'
            onBeforeInit={(swiper) => {
              swiperAdminRef.current = swiper;
            }}
            loop={true}
            centeredSlides={true}
            autoplay={{
              delay: 5000,
              disableOnInteraction: false,
            }}
            modules={[Autoplay, Pagination, Navigation]}
          >
            <button
              className='absolute left-0 top-[50%] z-10 flex h-12 w-12 -translate-y-1/2 items-center justify-center rounded-full bg-base-200'
              onClick={() => swiperAdminRef.current?.slidePrev()}
            >
              <ChevronLeftIcon className='h-6 w-6' />
            </button>
            {CAS_ADMIN_IMAGES.map((item) => {
              return (
                <SwiperSlide key={item.src}>
                  <picture>
                    <source srcSet={item.src} media='(min-width: 640px)' />
                    <img src={item.srcSp} alt={item.alt} className='mx-auto' />
                  </picture>
                </SwiperSlide>
              );
            })}
            <button
              className='absolute right-0 top-[50%] z-10 flex h-12 w-12 -translate-y-1/2 items-center justify-center rounded-full bg-base-200'
              onClick={() => swiperAdminRef.current?.slideNext()}
            >
              <ChevronRightIcon className='h-6 w-6' />
            </button>
          </Swiper>
        </div>
      </section>

      {/* pricing */}
      <section id='pricing' className='px-4 py-10'>
        <h2 className='text-center !font-outfit text-5xl font-semibold'>
          <Trans i18nKey={'price-card.pricing'} />
        </h2>
        <div className='mx-auto mt-10 max-w-[66.5rem]'>
          <PriceCards />
        </div>
        <div className='mx-auto max-w-[66.5rem]'>
          <p className='mt-6 pb-5 pl-4 text-xs font-normal text-gray-600'>
            <Trans i18nKey={'price-card.warn-1'} />
            <br />
            <Trans i18nKey={'price-card.warn-2'} />
          </p>
        </div>
      </section>

      {/* Sign up */}
      <section className='bg-slate-100 px-4 pb-40 pt-20 md:px-[7.25rem]'>
        <div className='flex flex-col items-center gap-20 lg:flex-row'>
          <div className='flex flex-col gap-6'>
            <h2
              className='!font-outfit text-[3rem] font-extrabold md:text-[4rem]'
              style={{ lineHeight: 'normal' }}
            >
              <Trans i18nKey={'signup.top.ttl-2'} />
            </h2>
            <p className='text-2xl font-medium'>
              <Trans i18nKey={'signup.top.desc'} />
            </p>
          </div>
          <form
            className='min-h-[39.7rem] w-full max-w-[37.5rem] rounded-3xl bg-white px-4 py-8 shadow-xl md:p-10'
            autoComplete='off'
            onSubmit={handleSubmit}
          >
            {success ? (
              <div>
                <p className='text-3xl font-extrabold'>
                  <Trans i18nKey={'signup.top.success'} />
                </p>
                <div className='mt-8 flex justify-center'>
                  <img
                    src='/assets/img/message-sent.png'
                    className='h-60 w-60'
                    alt=''
                  />
                </div>
                <p className='text-center'>
                  <Trans i18nKey={'signup.top.message'} />
                </p>
              </div>
            ) : (
              <>
                <h2 className='px-4 pb-8 text-3xl font-extrabold lg:px-0'>
                  <Trans i18nKey={'signup.top.ttl'} />
                </h2>
                <p className='px-4 lg:px-0'>
                  <Trans i18nKey={'signup.top.sub-ttl'} />
                </p>
                <div className='mt-8 flex flex-col gap-6 px-4 lg:px-0'>
                  <label className='form-control w-full'>
                    <div className='label'>
                      <span className='label-text font-medium'>
                        <Trans i18nKey={'signup.top.email'} />
                      </span>
                    </div>
                    <input
                      className={`input input-bordered w-full ${
                        emailError && 'input-error'
                      }`}
                      maxLength='320'
                      type='email'
                      placeholder='Email'
                      onChange={(e) => reflectEmail(e.target.value)}
                      required
                    />
                    {!!emailError && (
                      <div className='label'>
                        <span className='label-text-alt text-error'>
                          {emailError}
                        </span>
                      </div>
                    )}
                  </label>

                  <label className='form-control w-full'>
                    <div className='label'>
                      <span className='label-text font-medium'>
                        <Trans i18nKey={'signup.top.password'} />
                      </span>
                    </div>
                    <div className='relative'>
                      <input
                        className={`input input-bordered w-full ${
                          !hasPasswordTyped
                            ? ''
                            : !passwordValid && hasTriedSignUp && 'input-error'
                        }`}
                        maxLength='20'
                        type={`${showPassword ? 'text' : 'password'}`}
                        placeholder='Password'
                        onChange={(e) => {
                          if (!hasPasswordTyped) {
                            setHasPasswordTyped(true);
                          }
                          setPassword(e.target.value);
                        }}
                        onKeyUp={(e) => passwordValidation(e.target.value)}
                        required
                        autoComplete='new-password'
                      />
                      <span
                        className='absolute right-3 top-1/2 -translate-y-2/4 cursor-pointer text-sm'
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        Show
                      </span>
                    </div>
                  </label>
                  {hasTriedSignUp && (
                    <div className='flex flex-col gap-2'>
                      <div
                        className={`flex items-center gap-2 text-xs ${
                          !hasPasswordTyped
                            ? ''
                            : lowercaseValid
                            ? 'text-success'
                            : 'text-error'
                        }`}
                      >
                        <MinusIcon className={`h-4 w-4`} />
                        <Trans i18nKey={'signup.top.lowercase'} />
                      </div>
                      <div
                        className={`flex items-center gap-2 text-xs ${
                          !hasPasswordTyped
                            ? ''
                            : uppercaseValid
                            ? 'text-success'
                            : 'text-error'
                        }`}
                      >
                        <MinusIcon className={`h-4 w-4`} />
                        <Trans i18nKey={'signup.top.uppercase'} />
                      </div>
                      <div
                        className={`flex items-center gap-2 text-xs ${
                          !hasPasswordTyped
                            ? ''
                            : numericValid
                            ? 'text-success'
                            : 'text-error'
                        }`}
                      >
                        <MinusIcon className={`h-4 w-4`} />
                        <Trans i18nKey={'signup.top.number'} />
                      </div>
                      <div
                        className={`flex items-center gap-2 text-xs ${
                          !hasPasswordTyped
                            ? ''
                            : charValid
                            ? 'text-success'
                            : 'text-error'
                        }`}
                      >
                        <MinusIcon className={`h-4 w-4`} />
                        <Trans i18nKey={'signup.top.special-character'} />
                      </div>
                      <div
                        className={`flex items-center gap-2 text-xs ${
                          !hasPasswordTyped
                            ? ''
                            : lengthValid
                            ? 'text-success'
                            : 'text-error'
                        }`}
                      >
                        <MinusIcon className={`h-4 w-4`} />
                        <Trans i18nKey={'signup.top.8-20-letters'} />
                      </div>
                    </div>
                  )}
                </div>
                <div className='form-control mt-8 px-4 lg:px-0'>
                  {i18n.language === 'ja' ? (
                    <label className='label flex cursor-pointer items-start gap-3'>
                      <input
                        type='checkbox'
                        className='checkbox-primary checkbox'
                        onClick={() => setIsConfirmed(!isConfirmed)}
                      />
                      <span className='label-text text-xs font-medium'>
                        私は、CASの利用およびCASでの展示が、CASの
                        <Link
                          className='underline'
                          to='/terms-privacy'
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          利用規約とプライバシーポリシー
                        </Link>
                        、ならびに
                        <Link
                          className='underline'
                          to='/cookie-policy'
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          クッキーポリシー
                        </Link>
                        に準拠しなければならないことを理解し、これに同意します。また、Yuyosoft
                        Innovations
                        Inc.が独自の裁量により、コンテンツを「成人向けコンテンツ」として指定したり、削除したりする場合があることを承知しています。さらに、規約違反が繰り返された場合、通知や返金なしにアカウントが閉鎖される可能性があることを認識し、これを受け入れます。コミュニティの基準およびこのサイトを利用する子供たちを保護するために、本規約に同意いたします。
                      </span>
                    </label>
                  ) : (
                    <label className='label flex cursor-pointer items-start gap-3'>
                      <input
                        type='checkbox'
                        className='checkbox-primary checkbox'
                        onClick={() => setIsConfirmed(!isConfirmed)}
                      />
                      <span className='label-text text-xs font-medium'>
                        I understand and agree that my use of CAS and my CAS
                        exhibitions must comply with the{' '}
                        <Link
                          className='link-hover link underline'
                          to='/terms-privacy'
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          CAS Terms of Use, Privacy Policy
                        </Link>{' '}
                        and{' '}
                        <Link
                          className='link-hover link underline'
                          to='/cookie-policy'
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          Cookie Policy
                        </Link>
                        . I understand that Yuyosoft Innovations Inc. may at
                        their sole discretion mark content as "mature content"
                        or remove it. I accept that repeated violations may
                        result in the closure of my account, without notice or
                        refund. I agree to these terms, many of which are
                        intended to protect community standards and the children
                        using this site.
                      </span>
                    </label>
                  )}
                </div>
                <div className='mt-6 flex justify-center'>
                  <button
                    type='submit'
                    className={`btn btn-primary btn-wide`}
                    disabled={!isConfirmed || !!emailError}
                  >
                    <Trans i18nKey={'signup.top.ttl'} />
                  </button>
                </div>
              </>
            )}
          </form>
        </div>
      </section>

      {/* A Random Selection of Live CAS Exhibitions */}
      <section className='mx-auto my-20 max-w-[52.875rem] px-4 lg:px-0'>
        <h2 className='text-center !font-outfit text-[2.5rem] font-semibold leading-tight lg:text-5xl'>
          <Trans i18nKey={'top.feature-7-ttl'} />
        </h2>
        <div className='mt-10'>
          <picture>
            <source
              srcSet='/assets/img/explore-live-cas-exhibitions_pc.webp'
              media='(min-width: 640px)'
            />
            <img
              src='/assets/img/explore-live-cas-exhibitions_sp.webp'
              alt='Explore Live CAS Exhibitions'
              className='w-full'
            />
          </picture>
        </div>
        <div className='mt-10 flex justify-center'>
          <Link to={'/explore'} className='btn btn-lg'>
            <Trans i18nKey={'btn.explore-live-cas-exhibition'} />
          </Link>
        </div>
      </section>
      {isLoading && <LoadingSpinner />}
    </>
  );
};

export default Discovery;
