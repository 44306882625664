import {
  LinkIcon,
  PlusCircleIcon,
  Square2StackIcon,
} from '@heroicons/react/24/outline';
import React, { useEffect, useMemo, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Rooms from './Rooms';
import Drafts from './Drafts';
import Items from './Items';
import { authRequest, publicRequest } from '../../requestMethods';
import { Trans, useTranslation } from 'react-i18next';
import ManageSubscription from './ManageSubscription';
import Analytics from './Analytics';
import { BoltIcon, CheckBadgeIcon } from '@heroicons/react/24/solid';
import { errorToast, successToast } from '../../utils/toast';
import CountryRegionModal from './components/CountryRegionModal';
import { loadEnd, loadStart } from '../../redux/loadRedux';
import { updateProfile } from '../../redux/userRedux';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import LoadingSpinner from '../../components/LoadingSpinner';

dayjs.extend(utc);

const Dashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.isLoading);

  const [isReachedMaxUpload, setIsReachedMaxUpload] = useState(false);
  const user = useSelector((state) => state.user.currentUser);
  const [searchParams, setSearchParams] = useSearchParams();
  const tab = searchParams.get('tab');
  const [selectedItem, setSelectedItem] = useState(null);
  const [plan, setPlan] = useState('');
  const [hasVipSupport, setHasVipSupport] = useState(false);
  const {
    //product ID
    REACT_APP_STRIPE_PREMIUM_CAS_MEMBER_PRODUCT_ID: PREMIUM_ID,
    REACT_APP_STRIPE_VIP_CAS_MEMBER_PRODUCT_ID: VIP_ID,

    // アートワーク上限値
    REACT_APP_MAX_UPLOAD_ARTWORK_MEMBER: MAX_UPLOAD_FREE,
    REACT_APP_MAX_UPLOAD_ARTWORK_PREMIUM: MAX_UPLOAD_PREMIUM,
    REACT_APP_MAX_UPLOAD_ARTWORK_VIP: MAX_UPLOAD_VIP,
  } = process.env;
  const { t, i18n } = useTranslation();

  // useEffect(() => {
  //   //debug用
  //   document.getElementById('country_region_modal').show();
  // }, []);

  // 契約プランと各種上限に達しているかを取得
  useEffect(() => {
    // 初期値を設定するための変数
    let foundPlan = '';
    if (user?.userPlans?.length > 0) {
      // userPlans配列の各要素をチェック
      user.userPlans.forEach((el) => {
        if (el.SubscriptionPlans.productId === PREMIUM_ID) {
          foundPlan = 'PREMIUM';
        } else if (el.SubscriptionPlans.productId === VIP_ID) {
          foundPlan = 'VIP';
        }
      });
    }
    // 設定したプラン名を反映
    if (foundPlan) {
      setPlan(foundPlan);
    } else {
      //何もサブスクしていない場合はFreeプラン
      setPlan('FREE');
    }
  }, [user]);

  const checkIsReachedMaxUpload = async () => {
    try {
      const publishedItems = await publicRequest.get(
        `/items/creator/${user.id}`
      );
      const draftItems = await authRequest.get(`/items/draft`);

      if (
        (publishedItems.data.length + draftItems.data.length >=
          Number(MAX_UPLOAD_FREE) &&
          (plan == 'FREE' || !plan)) ||
        (publishedItems.data.length + draftItems.data.length >=
          Number(MAX_UPLOAD_PREMIUM) &&
          plan == 'PREMIUM') ||
        (publishedItems.data.length + draftItems.data.length >=
          Number(MAX_UPLOAD_VIP) &&
          plan == 'VIP')
      ) {
        setIsReachedMaxUpload(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    checkIsReachedMaxUpload();
  }, [user, plan]);

  const lobbyLink = useMemo(() => {
    return user?.username
      ? `https://curatedartshow.com/lobby/${user.username}`
      : '';
  }, [user?.username]);

  const navigatePlanPage = () => {
    if (!user.country && !user.region) {
      document.getElementById('country_region_modal').show();
    } else {
      navigate('/subscription');
    }
  };

  const handleSubmit = async (country, region) => {
    dispatch(loadStart());
    try {
      //countryとregionを更新
      const res = await authRequest.patch('/users', {
        country,
        region,
      });

      //frontのuserデータを更新
      dispatch(updateProfile(res.data));
      //subscriptionページに遷移
      document.getElementById('country_region_modal').close();
      navigate('/subscription');
      successToast(t('message.success.t23'));
    } catch (err) {
      console.error(err);
      errorToast(t('message.error.t1'));
    } finally {
      dispatch(loadEnd());
    }
  };

  return (
    <>
      <div className='mx-auto max-w-7xl'>
        {isReachedMaxUpload && (
          <div className='mt-6 px-4'>
            <div role='alert' className='alert'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                className='h-6 w-6 shrink-0 stroke-info'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth='2'
                  d='M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z'
                ></path>
              </svg>
              {plan == 'FREE' && (
                <span className='text-sm text-base-content'>
                  You have reached the maximum upload limit for CAS Member (
                  {MAX_UPLOAD_FREE} artworks).{' '}
                  <button
                    className='btn-link text-base-content'
                    onClick={navigatePlanPage}
                  >
                    Upgrade to CAS Premium or CAS VIP
                  </button>{' '}
                  for more upload capacity.
                </span>
              )}

              {plan == 'PREMIUM' && (
                <span className='text-sm text-base-content'>
                  You have reached the maximum upload limit for CAS Premium (
                  {MAX_UPLOAD_PREMIUM} artworks).{' '}
                  <button
                    className='btn-link text-base-content'
                    onClick={navigatePlanPage}
                  >
                    Upgrade to CAS VIP
                  </button>{' '}
                  for even more upload capacity.
                </span>
              )}

              {plan == 'VIP' && (
                <span className='text-sm text-base-content'>
                  You have reached the maximum upload limit for CAS VIP (
                  {MAX_UPLOAD_VIP} artworks).
                </span>
              )}
            </div>
          </div>
        )}

        <div className='flex flex-col justify-between gap-3 px-4 py-10 md:flex-row'>
          <div className='flex items-center gap-3 md:items-center md:gap-6'>
            <img
              className='h-[4.625rem] w-[4.625rem] self-start rounded-full object-cover'
              src={`${
                user?.profileImageUrl
                  ? user?.profileImageUrl + '?w=300&q=60'
                  : '/assets/img/avatar/dummy_avatar.svg'
              }`}
              alt='profile image'
            />
            <div className='flex w-full flex-col gap-4'>
              <div className='flex flex-col justify-center gap-2 md:flex-row md:items-center md:gap-3'>
                <div className='text-2xl font-bold md:text-4xl'>
                  {user.displayName ? user.displayName : user.username}
                </div>

                {plan && (
                  <div className='flex flex-shrink-0 items-center gap-2'>
                    <p className='font-medium'>
                      {plan == 'FREE'
                        ? 'CAS Member'
                        : plan == 'PREMIUM CAS'
                        ? 'Premium CAS Member'
                        : plan == 'VIP'
                        ? 'VIP CAS Member'
                        : ''}
                    </p>
                    {(plan === 'FREE' || plan == 'PREMIUM') && (
                      <button
                        onClick={navigatePlanPage}
                        className='btn btn-sm gap-2 rounded-full bg-blue-100 text-primary hover:bg-blue-100'
                      >
                        <BoltIcon className='h-6 w-6' />
                        <Trans i18nKey={'dashboard.upgrade'} />
                      </button>
                    )}
                  </div>
                )}
              </div>

              <button
                className='flex items-center gap-1 text-sm text-base-content underline'
                onClick={() => {
                  navigator.clipboard.writeText(lobbyLink);
                  successToast(t('message.success.t24'));
                }}
                disabled={!user?.username}
              >
                <LinkIcon className='h-4 w-4' />

                <Trans i18nKey={'dashboard.copy-lobby-link'} />
              </button>

              <div className='flex flex-col gap-2 sm:w-fit md:hidden'>
                <Link
                  to='/create-item'
                  className='btn btn-primary w-full'
                  disabled={isReachedMaxUpload}
                >
                  <PlusCircleIcon className='h-6 w-6' />
                  <Trans i18nKey={'dashboard.upload-art'} />
                </Link>
              </div>
            </div>
          </div>
          <div className='hidden flex-col gap-2 md:flex'>
            <Link
              to='/create-item'
              className='btn btn-primary w-fit'
              disabled={isReachedMaxUpload}
            >
              <PlusCircleIcon className='h-6 w-6' />
              <Trans i18nKey={'dashboard.upload-art'} />
            </Link>
          </div>
        </div>
      </div>

      <section id='tabs-container' className='z-10 w-full'>
        <div
          role='tablist'
          className='no-scrollbar mx-auto max-w-7xl overflow-x-scroll whitespace-nowrap'
        >
          <Link
            to='/dashboard?tab=artworks'
            role='tab'
            className={`tab-bordered tab ${tab === 'artworks' && 'tab-active'}`}
          >
            <Trans i18nKey={'dashboard.artworks'} />
          </Link>

          <Link
            to='/dashboard?tab=galleries'
            role='tab'
            className={`tab-bordered tab ${
              (!tab || tab === 'galleries') && 'tab-active'
            }`}
          >
            <Trans i18nKey={'dashboard.exhibitions'} />
          </Link>

          <Link
            to='/dashboard?tab=drafts'
            role='tab'
            className={`tab-bordered tab ${tab === 'drafts' && 'tab-active'}`}
          >
            <Trans i18nKey={'dashboard.unpublished'} />
          </Link>
          <Link
            to='/dashboard?tab=analytics'
            role='tab'
            className={`tab-bordered tab ${
              tab === 'analytics' && 'tab-active'
            }`}
          >
            <Trans i18nKey={'dashboard.analytics'} />
          </Link>
          <Link
            to='/dashboard?tab=manage-subscription'
            role='tab'
            className={`tab-bordered tab ${
              tab === 'manage-subscription' && 'tab-active'
            }`}
          >
            <Trans i18nKey={'dashboard.manage-subscription'} />
          </Link>
        </div>
      </section>

      <div className='mx-auto mb-20 mt-10 max-w-7xl px-4 xl:px-0'>
        {tab === 'artworks' && <Items setSelectedItem={setSelectedItem} />}
        {(!tab || tab === 'galleries') && <Rooms plan={plan} />}
        {tab === 'drafts' && <Drafts setSelectedItem={setSelectedItem} />}
        {tab === 'analytics' && <Analytics plan={plan} />}
        {tab === 'manage-subscription' && <ManageSubscription />}
      </div>

      <CountryRegionModal handleSubmit={handleSubmit} />

      {isLoading && <LoadingSpinner />}
    </>
  );
};

export default Dashboard;
