import { Trans } from 'react-i18next';

const ResetConfirmModal = ({ resetSettings }) => {
  return (
    <dialog id='reset_confirm_modal' className='modal'>
      <div className='modal-box !max-w-[32rem] !p-6'>
        <h3 className='mb-6 mt-0 text-lg font-bold'>
          <Trans i18nKey={'reset-confirm-modal.ttl'} />
        </h3>
        <p className='my-0 pb-6'>
          <Trans i18nKey={'reset-confirm-modal.desc'} />
        </p>
        <div className='modal-action mb-0 mt-6'>
          <form method='dialog'>
            <button className='btn mr-2'>
              <Trans i18nKey={'btn.cancel'} />
            </button>
            <button
              className='btn !bg-warning hover:!bg-warning hover:opacity-70'
              onClick={resetSettings}
            >
              <Trans i18nKey={'btn.confirm'} />
            </button>
          </form>
        </div>
      </div>
    </dialog>
  );
};

export default ResetConfirmModal;
