export const escapeToHTML = (text) => {
  if (!text) {
    return '';
  }
  return text.replaceAll('\n', '<br />');
};

export const toCapitalCase = (str) => {
  return str
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join('');
};

// 作成した関数をexportに含める
export default { escapeToHTML };
