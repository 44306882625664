import React, { useEffect, useMemo, useState, useTransition } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { authRequest, publicRequest } from '../../requestMethods';
import { errorToast, successToast } from '../../utils/toast';
import { InactiveItemModal } from '../../components/item/InactiveItemModal';
import { useDispatch, useSelector } from 'react-redux';
import LoadingSpinner from '../../components/LoadingSpinner';
import { loadEnd, loadStart } from '../../redux/loadRedux';
import {
  validateArtworkTitle,
  validateArtworkDescription,
  validateArtworkUrl,
  validateArtworkUrlLabel,
  validateArtworkCreatedYear,
  validateOtherImagesTitle,
  validateArtistName,
  validateDimensions,
  validateMedium,
} from '../../utils/validation';
import {
  QuestionMarkCircleIcon,
  ArrowLeftIcon,
  PencilSquareIcon,
} from '@heroicons/react/24/outline';
import { useDropzone } from 'react-dropzone';
import { Trans, useTranslation } from 'react-i18next';
import FramePreview from './FramePreview';
import { FRAMES } from '../../data/frames';
import { HexColorInput, HexColorPicker } from 'react-colorful';
import { CUSTOM_FRAMES } from '../../data/customFrames';
import { EditPictureFrameModal } from './components/EditPictureFrameModal';
import { CURRENCY } from '../../data/currency';

const EditItem = () => {
  const { t, i18n } = useTranslation();
  const user = useSelector((state) => state.user.currentUser);
  const {
    //product ID
    REACT_APP_STRIPE_PREMIUM_CAS_MEMBER_PRODUCT_ID: PREMIUM_ID,
    REACT_APP_STRIPE_VIP_CAS_MEMBER_PRODUCT_ID: VIP_ID,
  } = process.env;

  const [item, setItem] = useState({});

  // Demo版 必須
  const [file, setFile] = useState(null);
  const [filePreview, setFilePreview] = useState(null);
  const [title, setTitle] = useState('');
  const [artistName, setArtistName] = useState('');
  const [description, setDescription] = useState('');
  const [createdYear, setCreatedYear] = useState(null);
  const [medium, setMedium] = useState('');
  const [dimensions, setDimensions] = useState('');
  const [availability, setAvailability] = useState(null);
  const [price, setPrice] = useState(null);
  const [currency, setCurrency] = useState('USD');
  const [artworkUrl, setArtworkUrl] = useState(null);
  const [artworkUrlLabel, setArtworkUrlLabel] = useState(null);
  const [itemUrl, setItemUrl] = useState('');
  const [published, setPublished] = useState(true);
  const [styleIds, setStyleIds] = useState([1]);
  const [frame, setFrame] = useState('');
  const [otherImages1, setOtherImages1] = useState({
    file: null,
    title: '',
    preview: null,
  });
  const [otherImages2, setOtherImages2] = useState({
    file: null,
    title: '',
    preview: null,
  });
  const [otherImages3, setOtherImages3] = useState({
    file: null,
    title: '',
    preview: null,
  });
  const [imageUrl, setImageUrl] = useState(null);
  const [filteredFrames, setFilteredFrames] = useState([]);
  const [filteredCustomFrames, setFilteredCustomFrames] = useState([]);
  const [plan, setPlan] = useState(null);
  const [isMatureContent, setIsMatureContent] = useState(false);
  const [frameType, setFrameType] = useState('PRESET');
  const [frameColor, setFrameColor] = useState('#525252');
  const [matColor, setMatColor] = useState('#525252');

  // モーダル
  const [isPictureFrameOpen, setIsPictureFrameOpen] = useState(false);

  // エラー
  const [fileErrorMessage, setFileErrorMessage] = useState('');
  const [titleError, setTitleError] = useState('');
  const [artistNameError, setArtistNameError] = useState('');
  const [descriptionError, setDescriptionError] = useState('');
  const [createdYearError, setCreatedYearError] = useState('');
  const [artworkUrlError, setArtworkUrlError] = useState('');
  const [artworkUrlLabelError, setArtworkUrlLabelError] = useState('');
  const [otherImages1Error, setOtherImages1Error] = useState({
    file: '',
    title: '',
  });
  const [otherImages2Error, setOtherImages2Error] = useState({
    file: '',
    title: '',
  });
  const [otherImages3Error, setOtherImages3Error] = useState({
    file: '',
    title: '',
  });
  const [mediumError, setMediumError] = useState('');
  const [dimensionsError, setDimensionsError] = useState('');
  const [availabilityError, setAvailityError] = useState('');
  const [priceError, setPriceError] = useState('');

  const [isMobile, setIsMobile] = useState(false);

  // ステータスのENUMを定義
  const STATUS = {
    AVAILABLE_FOR_SALE: 'Available for Sale',
    NOT_FOR_SALE: 'Not for Sale',
    PENDING: 'Pending',
    SOLD: 'Sold',
  };

  //drag&drop時にstateのloadに時間がかかりレンダリングの中断を防ぐため
  const [_, startTransition] = useTransition({
    timeoutMs: 3000,
  });

  const { isLoading } = useSelector((state) => state.isLoading);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const previousPagePath = location?.state?.from;
  const itemId = location.pathname.split('/')[3];

  //plan確認
  useEffect(() => {
    if (user?.userPlans?.length > 0) {
      // 初期値を設定するための変数
      let foundPlan = 'FREE';

      // userPlans配列の各要素をチェック
      user.userPlans.forEach((el) => {
        if (el.SubscriptionPlans.productId === PREMIUM_ID) {
          foundPlan = 'PREMIUM';
        } else if (el.SubscriptionPlans.productId === VIP_ID) {
          foundPlan = 'VIP';
        }
      });

      // 設定したプラン名を反映

      setPlan(foundPlan);
    }
  }, [user]);

  useEffect(() => {
    //先頭2つを取り除いた後に、新しいデータを先頭に追加
    const filteredFramesTmp =
      plan == 'PREMIUM' || plan == 'VIP'
        ? FRAMES // 全てのフレームを取得
        : FRAMES.filter((frame) => !frame.isPro); // isProがfalseのものだけをフィルタリング

    const tmpFrames = [...filteredFramesTmp];

    setFilteredFrames(tmpFrames);

    const filteredCustomFramesTmp =
      plan == 'PREMIUM' || plan == 'VIP'
        ? CUSTOM_FRAMES // 全てのフレームを取得
        : CUSTOM_FRAMES.filter((frame) => !frame.isPro); // isProがfalseのものだけをフィルタリング

    setFilteredCustomFrames(filteredCustomFramesTmp);
  }, [FRAMES, plan, CUSTOM_FRAMES]);

  useEffect(() => {
    //get item data by id
    const getItemById = async () => {
      const res = await publicRequest.get(`/items/${itemId}`);
      if (res.data.creatorId == user.id) {
        setItem(res.data);
        setPublished(res.data.published);
        setTitle(res.data.title);
        setArtistName(res.data.artistName);
        setDescription(res.data.description);
        setItemUrl(res.data.itemUrl);
        setImageUrl(res.data.itemUrl);
        setCreatedYear(res.data.createdYear);
        setArtworkUrl(res.data.artworkUrl);
        setArtworkUrlLabel(res.data.artworkUrlLabel);
        setFrame(res.data.frame);
        setOtherImages1({
          file: null,
          title: res.data.otherImage1Title,
          preview: res.data.otherImage1Url,
        });
        setOtherImages2({
          file: null,
          title: res.data.otherImage2Title,
          preview: res.data.otherImage2Url,
        });
        setOtherImages3({
          file: null,
          title: res.data.otherImage3Title,
          preview: res.data.otherImage3Url,
        });
        setIsMatureContent(res.data.isMatureContent);
        if (res.data?.frameColor) {
          setFrameColor(res.data.frameColor);
        }

        if (res.data?.matColor) {
          setMatColor(res.data.matColor);
        }
        setMedium(res.data.medium);
        setDimensions(res.data.dimensions);
        setAvailability(res.data.availability);
        setPrice(res.data.price);
        setCurrency(res.data.currency);
      } else {
        navigate('/not-found');
      }
    };
    getItemById();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (published) {
      handlePublish(e);
    } else {
      handleSaveDraft(e);
    }
  };
  const handlePublish = async (e) => {
    e.preventDefault();
    if (!title) {
      errorToast(t('message.error.t5'));
      return;
    }
    console.log('save');
    dispatch(loadStart());
    const otherImages = [
      {
        id: 1,
        file: otherImages1.file,
        url: otherImages1.preview,
      },
      {
        id: 2,
        file: otherImages2.file,
        url: otherImages2.preview,
      },
      {
        id: 3,
        file: otherImages3.file,
        url: otherImages3.preview,
      },
    ];

    try {
      let s3Response = null;
      if (file) {
        s3Response = await publicRequest.post(
          `/s3/`,
          {
            file: file,
            key: `${user.id}/artworks/`,
            fileType: file.name.split('.').pop(),
          },
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        );
      }

      for (const item of otherImages) {
        if (!item.file) {
          continue;
        }
        const res = await publicRequest.post(
          `/s3/`,
          {
            file: item.file,
            key: `${user.id}/artworks/others/`,
            fileType: item.file.name.split('.').pop(),
          },
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        );
        item.url = res.data.replace(
          'curatedartshow-data.s3.amazonaws.com',
          'data.curatedartshow.com'
        );
      }

      await authRequest.patch(`/items/${itemId}`, {
        title,
        artistName,
        description,
        type: 1,
        createdYear,
        itemUrl: s3Response
          ? s3Response.data.replace(
              'curatedartshow-data.s3.amazonaws.com',
              'data.curatedartshow.com'
            )
          : itemUrl,
        published,
        styleIds,
        published: true,
        artworkUrl,
        artworkUrlLabel,
        frame,
        otherImage1Url: otherImages[0].url,
        otherImage1Title: otherImages1.title,
        otherImage2Url: otherImages[1].url,
        otherImage2Title: otherImages2.title,
        otherImage3Url: otherImages[2].url,
        otherImage3Title: otherImages3.title,
        isMatureContent,
        frameColor,
        matColor,
        medium,
        dimensions,
        availability,
        price,
        currency,
      });
      dispatch(loadEnd());
      navigate('/dashboard?tab=artworks');
      successToast(t('message.success.t1'));
    } catch (err) {
      dispatch(loadEnd());
      console.log(err);
    }
  };

  const handleSaveDraft = async () => {
    dispatch(loadStart());
    const otherImages = [
      {
        id: 1,
        file: otherImages1.file,
        url: otherImages1.preview,
      },
      {
        id: 2,
        file: otherImages2.file,
        url: otherImages2.preview,
      },
      {
        id: 3,
        file: otherImages3.file,
        url: otherImages3.preview,
      },
    ];

    try {
      let s3Response = null;
      if (file) {
        s3Response = await publicRequest.post(
          `/s3/`,
          {
            file: file,
            key: `${user.id}/artworks/`,
            fileType: file.name.split('.').pop(),
          },
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        );
      }

      for (const item of otherImages) {
        if (!item.file) {
          continue;
        }
        const res = await publicRequest.post(
          `/s3/`,
          {
            file: item.file,
            key: `${user.id}/artworks/others/`,
            fileType: item.file.name.split('.').pop(),
          },
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        );
        item.url = res.data.replace(
          'curatedartshow-data.s3.amazonaws.com',
          'data.curatedartshow.com'
        );
      }

      await authRequest.patch(`/items/${itemId}`, {
        title,
        artistName,
        description,
        type: 1,
        createdYear,
        itemUrl: s3Response
          ? s3Response.data.replace(
              'curatedartshow-data.s3.amazonaws.com',
              'data.curatedartshow.com'
            )
          : itemUrl,
        published: false,
        styleIds: [1],
        artworkUrl,
        artworkUrlLabel,
        frame,
        otherImage1Url: otherImages[0].url,
        otherImage1Title: otherImages1.title,
        otherImage2Url: otherImages[1].url,
        otherImage2Title: otherImages2.title,
        otherImage3Url: otherImages[2].url,
        otherImage3Title: otherImages3.title,
        isMatureContent,
        frameColor,
        matColor,
        medium,
        dimensions,
        availability,
        price,
        currency,
      });
      dispatch(loadEnd());
      navigate('/dashboard?tab=artworks');
      successToast(t('message.success.t1'));
    } catch (err) {
      dispatch(loadEnd());
      console.log(err);
    }
  };

  const unpublish = async (itemId) => {
    try {
      await authRequest.post(`/items/unpublish/${itemId}`);
      setPublished(!published);
      navigate('/dashboard?tab=drafts');
      successToast(t('message.success.t11'));
    } catch (err) {
      console.log(err);
    }
  };

  const onDrop = async (draggedFile) => {
    console.log('draggedFile', draggedFile);
    if (
      checkFileType(draggedFile[0].name, 'file') &&
      checkFileSize(draggedFile[0])
    ) {
      await previewImage(draggedFile[0]);
      setFile(draggedFile[0]);
      const url = URL.createObjectURL(draggedFile[0]);

      startTransition(() => {
        setImageUrl(url);
      });
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
  });

  const checkFileType = (file, kind) => {
    const acceptableExtensions = {
      image: ['gif', 'jpg', 'jpeg', 'png', 'webp'],
      // video: ['mp4', 'mov'],
      // audio: ['mp3', 'wav'],
    };
    const fileExtension = file.split('.').pop().toLowerCase();
    if (acceptableExtensions.image.indexOf(fileExtension) !== -1) {
      assignFileErrorMessages('', kind);
      return true;
    } else {
      assignFileErrorMessages('File type not allowed', kind);
      return false;
    }
  };

  const checkFileSize = (file, kind) => {
    const maxImageSize = 10000000;
    if (file.size > maxImageSize) {
      assignFileErrorMessages('File size exceeds maximum limit 10 MB', kind);
      return false;
    } else {
      assignFileErrorMessages('', kind);
      return true;
    }
  };

  const assignFileErrorMessages = (message, kind) => {
    if (kind === 'file') {
      setFileErrorMessage(message);
    } else if (kind === 'other1') {
      setOtherImages1Error((prev) => ({
        ...prev,
        file: message,
      }));
    } else if (kind === 'other2') {
      setOtherImages2Error((prev) => ({
        ...prev,
        file: message,
      }));
    } else if (kind === 'other3') {
      setOtherImages3Error((prev) => ({
        ...prev,
        file: message,
      }));
    }
  };

  const assignFile = async (file, kind) => {
    let fileReader = new FileReader();
    fileReader.onload = function () {
      if (kind === 'file') {
        setFile(file);
        setFilePreview(fileReader.result);
      } else if (kind === 'other1') {
        setOtherImages1({
          ...otherImages1,
          file: file,
          preview: fileReader.result,
        });
      } else if (kind === 'other2') {
        setOtherImages2({
          ...otherImages2,
          file: file,
          preview: fileReader.result,
        });
      } else if (kind === 'other3') {
        setOtherImages3({
          ...otherImages3,
          file: file,
          preview: fileReader.result,
        });
      }
    };
    try {
      fileReader.readAsDataURL(file);
    } catch (err) {
      console.log(err);
    }
  };

  const handleFileInput = async (event, kind) => {
    if (!event.target.files) return;
    const file = event.target.files[0];
    if (checkFileType(file.name, kind) && checkFileSize(file, kind)) {
      await assignFile(file, kind);

      const url = URL.createObjectURL(file);

      startTransition(() => {
        setImageUrl(url);
      });
    }
  };

  const previewImage = async (obj) => {
    let fileReader = new FileReader();
    fileReader.onload = function () {
      setFilePreview(fileReader.result);
    };
    try {
      fileReader.readAsDataURL(obj);
    } catch (err) {
      console.log(err);
    }
  };

  const reflectArtworkTitle = (title) => {
    const result = validateArtworkTitle(title);
    result.isValid ? setTitleError('') : setTitleError(result.message);
    setTitle(title);
  };

  const reflectArtistName = (name) => {
    const result = validateArtistName(name);
    result.isValid
      ? setArtistNameError('')
      : setArtistNameError(result.message);
    setArtistName(name);
  };

  const reflectArtworkDescription = (description) => {
    const result = validateArtworkDescription(description);
    result.isValid
      ? setDescriptionError('')
      : setDescriptionError(result.message);
    setDescription(description);
  };

  const reflectCreatedYear = (createdYear) => {
    const result = validateArtworkCreatedYear(createdYear);
    result.isValid
      ? setCreatedYearError('')
      : setCreatedYearError(result.message);
    setCreatedYear(createdYear);
  };

  const reflectArtworkUrl = (url) => {
    const result = validateArtworkUrl(url);
    result.isValid
      ? setArtworkUrlError('')
      : setArtworkUrlError(result.message);
    url === '' ? setArtworkUrl(null) : setArtworkUrl(url);
  };

  const reflectArtworkUrlLabel = (label) => {
    const result = validateArtworkUrlLabel(label);
    result.isValid
      ? setArtworkUrlLabelError('')
      : setArtworkUrlLabelError(result.message);
    label === '' ? setArtworkUrlLabel(null) : setArtworkUrlLabel(label);
  };

  const reflectOtherImagesTitle = (title, kind) => {
    const result = validateOtherImagesTitle(title);
    if (kind === 'other1') {
      result.isValid
        ? setOtherImages1Error({ ...otherImages1Error, title: '' })
        : setOtherImages1Error({ ...otherImages1Error, title: result.message });
      setOtherImages1({ ...otherImages1, title: title });
    } else if (kind === 'other2') {
      result.isValid
        ? setOtherImages2Error({ ...otherImages2Error, title: '' })
        : setOtherImages2Error({ ...otherImages2Error, title: result.message });
      setOtherImages2({ ...otherImages2, title: title });
    } else if (kind === 'other3') {
      result.isValid
        ? setOtherImages3Error({ ...otherImages3Error, title: '' })
        : setOtherImages3Error({ ...otherImages3Error, title: result.message });
      setOtherImages3({ ...otherImages3, title: title });
    }
  };

  const reflectMedium = (text) => {
    const result = validateMedium(text);
    result.isValid ? setMediumError('') : setMediumError(result.message);
    setMedium(text);
  };

  const reflectDimensions = (text) => {
    const result = validateDimensions(text);
    result.isValid
      ? setDimensionsError('')
      : setDimensionsError(result.message);
    setDimensions(text);
  };

  const hasError = useMemo(() => {
    const isFilled = title && (itemUrl || file);
    const hasErrorMessage =
      !!fileErrorMessage ||
      !!titleError ||
      !!artistNameError ||
      !!descriptionError ||
      !!createdYearError ||
      !!artworkUrlError ||
      !!artworkUrlLabelError ||
      !!otherImages1Error.file ||
      !!otherImages1Error.title ||
      !!otherImages2Error.file ||
      !!otherImages2Error.title ||
      !!otherImages3Error.file ||
      !!otherImages3Error.title;

    return hasErrorMessage || !isFilled ? true : false;
  }, [
    title,
    artistName,
    description,
    createdYear,
    file,
    artworkUrl,
    artworkUrlLabel,
    otherImages1,
    otherImages2,
    otherImages3,
    otherImages1Error,
    otherImages2Error,
    otherImages3Error,
  ]);

  useEffect(() => {
    if (frame?.includes('custom')) {
      setFrameType('CUSTOM');
    } else {
      setFrameType('PRESET');
    }
  }, [frame]);

  useEffect(() => {
    // 画面幅が640px未満の場合はモバイルとみなす
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth < 640);
    };
    setIsMobile(window.innerWidth < 640);
    // 画面サイズが変更されたときにモバイル判定を再実行
    window.addEventListener('resize', checkIfMobile);
    return () => {
      window.removeEventListener('resize', checkIfMobile);
    };
  }, []);

  return (
    <>
      <div className='mx-auto max-w-[62.5rem] px-4 pb-44 pt-12 lg:pt-20'>
        <button
          className='btn btn-ghost'
          onClick={() => {
            previousPagePath
              ? navigate(previousPagePath)
              : navigate('/dashboard?tab=artworks');
          }}
        >
          <ArrowLeftIcon className='h-5 w-5' />
          <Trans i18nKey={'btn.back'} />
        </button>
        <h2 className='mb-3 mt-6 text-3xl font-extrabold'>
          <Trans i18nKey={'artwork.edit-ttl'} />
        </h2>
        <p className='pb-10 text-xs text-gray-500 '>
          <Trans i18nKey={'artwork.note'} />
        </p>
        <div className=''>
          <div {...getRootProps()} className='mx-auto h-[22.5rem] max-w-xl'>
            {itemUrl && !file && (
              <img
                className='h-full w-full cursor-pointer object-contain'
                src={itemUrl}
                alt=''
              />
            )}
            {filePreview && (
              <img
                className='h-full w-full cursor-pointer object-contain'
                src={filePreview}
                alt=''
              />
            )}
          </div>
          {!!fileErrorMessage && (
            <p className='mt-4 text-error'>{fileErrorMessage}</p>
          )}

          <div className='flex flex-col gap-10 py-10'>
            <div className='flex max-w-[22.5rem] flex-col gap-10'>
              {/* Title */}
              <label className='form-control w-full'>
                <div className='label'>
                  <span className='label-text font-medium'>
                    <Trans i18nKey={'label.ttl'} />
                  </span>
                </div>
                <input
                  type='text'
                  className={`input input-bordered w-full text-sm placeholder:text-sm ${
                    titleError ? 'input-error' : ''
                  }`}
                  maxLength='46'
                  placeholder={t('placeholder.artwork-ttl')}
                  value={title}
                  onChange={(e) => reflectArtworkTitle(e.target.value)}
                />
                <div className='label'>
                  <span className='label-text-alt text-error'>
                    {titleError}
                  </span>
                  <span className='label-text-alt'>
                    {title?.length ?? 0} / 46
                  </span>
                </div>
              </label>

              {/* Artist Name */}
              <label className='form-control w-full'>
                <div className='label'>
                  <span className='label-text font-medium'>
                    <Trans i18nKey={'label.artist-name'} />
                  </span>
                  <span className='label-text'>
                    <Trans i18nKey={'label.option'} />
                  </span>
                </div>
                <input
                  type='text'
                  className={`input input-bordered w-full text-sm placeholder:text-sm ${
                    artistNameError ? 'input-error' : ''
                  }`}
                  maxLength='46'
                  placeholder={t('placeholder.artwork-artist-name')}
                  onChange={(e) => reflectArtistName(e.target.value)}
                  value={artistName}
                />
                <div className='label'>
                  <span className='label-text-alt text-error'>
                    {artistNameError}
                  </span>
                  <span className='label-text-alt'>
                    {artistName?.length ?? 0} / 46
                  </span>
                </div>
              </label>

              {/* Description */}
              <label className='form-control'>
                <div className='label'>
                  <span className='label-text font-medium'>
                    <Trans i18nKey={'label.desc'} />
                  </span>
                  <span className='label-text'>
                    <Trans i18nKey={'label.option'} />
                  </span>
                </div>
                <textarea
                  className={`textarea textarea-bordered h-28 text-sm placeholder:text-sm ${
                    descriptionError ? 'textarea-error' : ''
                  }`}
                  maxLength='30000'
                  placeholder={t('placeholder.artwork-desc')}
                  value={description}
                  onChange={(e) => reflectArtworkDescription(e.target.value)}
                ></textarea>
                <div className='label'>
                  <span className='label-text-alt text-error'>
                    {descriptionError}
                  </span>
                  <span className='label-text-alt'>
                    {description?.length ?? 0}/ 30000
                  </span>
                </div>
              </label>

              {/* Year */}
              <label className='form-control w-full'>
                <div className='label'>
                  <span className='label-text font-medium'>
                    <Trans i18nKey={'label.year'} />
                  </span>
                  <span className='label-text'>
                    <Trans i18nKey={'label.option'} />
                  </span>
                </div>
                <input
                  type='text'
                  maxLength='50'
                  className={`input input-bordered w-full text-sm placeholder:text-sm ${
                    createdYearError ? 'input-error' : ''
                  }`}
                  value={createdYear}
                  onChange={(e) => reflectCreatedYear(e.target.value)}
                />
                <div className='label'>
                  <span className='label-text-alt text-error'>
                    {createdYearError}
                  </span>
                </div>
              </label>

              {/* Medium */}
              <label className='form-control w-full'>
                <div className='label'>
                  <span className='label-text font-medium'>
                    <Trans i18nKey={'label.medium'} />
                  </span>
                  <span className='label-text'>
                    <Trans i18nKey={'label.option'} />
                  </span>
                </div>
                <input
                  type='text'
                  className={`input input-bordered w-full text-sm placeholder:text-sm ${
                    mediumError ? 'input-error' : ''
                  }`}
                  // placeholder='https://'
                  value={medium}
                  maxLength='100'
                  onChange={(e) => reflectMedium(e.target.value)}
                />
                <div className='label'>
                  <span className='label-text-alt text-error'>
                    {mediumError}
                  </span>
                </div>
              </label>

              {/* Dimensions */}
              <label className='form-control w-full'>
                <div className='label'>
                  <span className='label-text font-medium'>
                    <Trans i18nKey={'label.dimensions'} />
                  </span>
                  <span className='label-text'>
                    <Trans i18nKey={'label.option'} />
                  </span>
                </div>
                <input
                  type='text'
                  className={`input input-bordered w-full text-sm placeholder:text-sm ${
                    dimensionsError ? 'input-error' : ''
                  }`}
                  // placeholder='https://'
                  value={dimensions}
                  maxLength='40'
                  onChange={(e) => reflectDimensions(e.target.value)}
                />
                <div className='label'>
                  <span className='label-text-alt text-error'>
                    {dimensionsError}
                  </span>
                </div>
              </label>

              {/* Availability */}
              <label className='form-control w-full'>
                <div className='label'>
                  <span className='label-text font-medium'>
                    <Trans i18nKey={'label.availability'} />
                  </span>
                  <span className='label-text'>
                    <Trans i18nKey={'label.option'} />
                  </span>
                </div>

                <select
                  className='select select-bordered'
                  onChange={(e) => {
                    setAvailability(e.target.value);
                  }}
                  value={availability}
                >
                  <option disabled selected value={''}>
                    Select
                  </option>

                  {Object.keys(STATUS).map((key) => (
                    <option key={key} value={key}>
                      {STATUS[key]}
                    </option>
                  ))}
                </select>
                <div className='label'>
                  <span className='label-text-alt text-error'>
                    {availabilityError}
                  </span>
                </div>
              </label>

              {/* Price & Currency */}
              <label className='form-control w-full'>
                <div className='label'>
                  <span className='label-text font-medium'>
                    <Trans i18nKey={'label.price-currency'} />
                  </span>
                  <span className='label-text'>
                    <Trans i18nKey={'label.option'} />
                  </span>
                </div>

                <div className='join'>
                  <div>
                    <div>
                      <input
                        className='input join-item input-bordered text-right'
                        type='number'
                        value={price}
                        min={0}
                        max={9999999999}
                        placeholder='0'
                        onChange={(e) => setPrice(Number(e.target.value))}
                      />
                    </div>
                  </div>
                  <select
                    className='join-item select select-bordered'
                    value={currency}
                    onChange={(e) => setCurrency(e.target.value)}
                  >
                    <option disabled selected>
                      Filter
                    </option>

                    {Object.keys(CURRENCY).map((key) => (
                      <option key={key} value={key}>
                        {CURRENCY[key].code}
                      </option>
                    ))}
                  </select>
                </div>

                <div className='label'>
                  <span className='label-text-alt text-error'>
                    {priceError}
                  </span>
                </div>
              </label>

              {/* Artwork URL */}
              <label className='form-control w-full'>
                <div className='label'>
                  <span className='label-text font-medium'>
                    <Trans i18nKey={'label.url'} />
                  </span>
                  <span className='label-text'>
                    <Trans i18nKey={'label.option'} />
                  </span>
                </div>
                <input
                  type='text'
                  className={`input input-bordered w-full text-sm placeholder:text-sm ${
                    artworkUrlError ? 'input-error' : ''
                  }`}
                  placeholder='https://'
                  maxLength='256'
                  value={artworkUrl}
                  onChange={(e) => reflectArtworkUrl(e.target.value)}
                />
                <div className='label'>
                  <span className='label-text-alt text-error'>
                    {artworkUrlError}
                  </span>
                </div>
              </label>

              {/* Artwork URL Button Label */}
              <label className='form-control w-full'>
                <div className='label'>
                  <span className='label-text font-medium'>
                    <Trans i18nKey={'label.btn-label'} />
                  </span>
                  <span className='label-text'>
                    <Trans i18nKey={'label.option'} />
                  </span>
                </div>
                <input
                  type='text'
                  className={`input input-bordered w-full text-sm placeholder:text-sm ${
                    artworkUrlLabelError ? 'input-error' : ''
                  }`}
                  value={artworkUrlLabel}
                  maxLength='40'
                  onChange={(e) => reflectArtworkUrlLabel(e.target.value)}
                />
                <div className='label'>
                  <span className='label-text-alt text-error'>
                    {artworkUrlLabelError}
                  </span>
                </div>
              </label>
            </div>

            {/* Picture Frame */}
            <div>
              <p className='font-bold'>
                <Trans i18nKey={'label.frame'} />
                <span className='ml-3 text-sm font-normal text-gray-400'>
                  <Trans i18nKey={'label.option'} />
                </span>
              </p>
              <p className='mb-3 text-sm text-gray-400'>
                <Trans i18nKey={'artwork.frame-note'} />
              </p>
              {isMobile ? (
                <div className='mt-5 flex justify-center'>
                  <button
                    className='btn'
                    onClick={() => {
                      document
                        .getElementById('edit_picture_frame_modal')
                        .showModal();
                      setIsPictureFrameOpen(true);
                    }}
                  >
                    <PencilSquareIcon className='h-6 w-6' />
                    <Trans i18nKey={'artwork.edit-frame'} />
                  </button>
                </div>
              ) : (
                <div className='flex flex-col gap-6 md:flex-row md:justify-between md:gap-4'>
                  <div className='flex w-full flex-col md:max-w-[50%]'>
                    <div className='form-control'>
                      <label className='label cursor-pointer justify-start gap-2 py-0'>
                        <input
                          type='radio'
                          className='radio'
                          // value={item.kind}
                          onChange={(e) => setFrame('')}
                          name='frame_type'
                          checked={frameType === 'PRESET'}
                        />
                        <span className='label-text'>
                          <Trans i18nKey={'label.select-from-presets'} />
                        </span>
                      </label>
                    </div>
                    <div
                      className={`my-5 grid max-h-[34rem] w-full grid-cols-3 gap-x-2 gap-y-[0.69rem] overflow-y-scroll bg-white pl-8 ${
                        frameType == 'PRESET'
                          ? 'pointer-events-auto opacity-100'
                          : 'pointer-events-none opacity-20'
                      }`}
                    >
                      {filteredFrames.map((item, index) => (
                        <div
                          key={`frame-${index}`}
                          className={`relative cursor-pointer`}
                          onClick={() => setFrame(item.value)}
                        >
                          <img
                            src={item.imgUrl + '?q=60'}
                            alt={item.title}
                            className={`h-auto w-full rounded ${
                              item.value == frame &&
                              'border-[1.5px] border-black'
                            }`}
                          />
                          {item.value === '' && i18n.language === 'ja' ? (
                            <p className='mt-4 text-left text-sm'>
                              展示会のデフォルトを使用
                            </p>
                          ) : item.value === 'unframed' &&
                            i18n.language === 'ja' ? (
                            <p className='mt-4 text-left text-sm'>額縁なし</p>
                          ) : (
                            <p className='mt-4 text-left text-sm'>
                              {item.title}
                            </p>
                          )}

                          {item.value == frame && (
                            <img
                              src='/assets/img/check_icon.png'
                              className='absolute right-3 top-3 h-[0.875rem] w-[0.875rem]'
                              alt='frame'
                            />
                          )}
                        </div>
                      ))}
                    </div>
                    {(plan == 'PREMIUM' || plan == 'VIP') && (
                      <>
                        <div className='form-control'>
                          <label className='label my-0 cursor-pointer justify-start gap-2 py-0'>
                            <input
                              type='radio'
                              className='radio'
                              // value={item.kind}
                              onChange={(e) => setFrame('custom1')}
                              name='frame_type'
                              checked={frameType === 'CUSTOM'}
                            />
                            <span className='label-text'>
                              <Trans i18nKey={'label.create-custom-frame'} />
                            </span>
                          </label>
                        </div>
                        <div
                          className={`${
                            frameType == 'CUSTOM'
                              ? 'pointer-events-auto opacity-100'
                              : 'pointer-events-none opacity-20'
                          }`}
                        >
                          <p className={`pb-2 pl-8 pt-3 font-[600]`}>
                            <Trans i18nKey={'label.select-frame-model'} />
                          </p>
                          <div className={`pl-8 pr-0`}>
                            <div className='grid max-h-[29rem] grid-cols-3 gap-x-2 gap-y-[0.69rem] overflow-y-scroll rounded-lg border border-base-300 bg-white/70 px-[1.62rem] py-[1.19rem]'>
                              {filteredCustomFrames.map((item, index) => (
                                <div
                                  key={`custom-frame-${index}`}
                                  className={`relative cursor-pointer`}
                                  onClick={() => setFrame(item.value)}
                                >
                                  <img
                                    src={item.imgUrl + '?q=60'}
                                    alt={item.title}
                                    className={`h-auto w-full rounded ${
                                      item.value == frame &&
                                      'border-[1.5px] border-black'
                                    }`}
                                  />
                                  <p className='mt-1 text-left text-xs'>
                                    {item.title}
                                  </p>
                                  {item.value == frame && (
                                    <img
                                      src='/assets/img/check_icon.png'
                                      className='absolute right-3 top-3 h-[0.875rem] w-[0.875rem]'
                                    />
                                  )}
                                </div>
                              ))}
                            </div>
                          </div>

                          <p className='pb-2 pl-8 pt-6 font-[600]'>
                            <Trans i18nKey={'label.select-frame-color'} />
                          </p>
                          <div className='mt-2 pl-8'>
                            <HexColorPicker
                              color={frameColor}
                              onChange={setFrameColor}
                            />
                            <div className='mt-2 '>
                              <HexColorInput
                                color={frameColor}
                                onChange={setFrameColor}
                                className='input input-bordered w-[200px]'
                              />
                            </div>
                          </div>
                          <p className='pb-2 pl-8 pt-6 font-[600]'>
                            <Trans i18nKey={'label.select-matte-color'} />
                          </p>
                          <div className='mt-2 pl-8'>
                            <HexColorPicker
                              color={matColor}
                              onChange={setMatColor}
                            />
                            <div className='mt-2 '>
                              <HexColorInput
                                color={matColor}
                                onChange={setMatColor}
                                className='input input-bordered w-[200px]'
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>

                  <div className='flex w-full flex-1 flex-col gap-4 rounded-2xl bg-zinc-100 p-6 md:max-w-[50%] md:px-5'>
                    <p className='font-bold'>
                      <Trans i18nKey={'artwork.frame-preview'} />
                    </p>
                    <div className='sticky top-[5rem] aspect-square w-full'>
                      {imageUrl ? (
                        <FramePreview
                          frame={frame}
                          itemUrl={imageUrl}
                          frameColor={frameColor}
                          matColor={matColor}
                          removeQuery
                        />
                      ) : (
                        <div className='flex aspect-square w-full items-center justify-center bg-zinc-500 text-center text-lg text-white'>
                          <Trans i18nKey={'artwork.frame-preview-artwork'} />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className='flex max-w-[22.5rem] flex-col gap-10'>
              {/* Other Image 1 */}
              <div>
                <p className='px-1 py-2 text-sm font-semibold'>
                  <Trans i18nKey={'artwork.other-image'} /> 1/3 (
                  <Trans i18nKey={'label.option'} />)
                </p>
                {otherImages1.preview ? (
                  <>
                    <img
                      src={otherImages1.preview}
                      className='h-[200px] w-[200px] object-contain'
                      alt='other1'
                    />
                    <button
                      className='btn mt-4'
                      onClick={() =>
                        setOtherImages1({
                          ...otherImages1,
                          file: null,
                          preview: null,
                        })
                      }
                    >
                      <Trans i18nKey={'btn.delete-image'} />
                    </button>
                  </>
                ) : (
                  <input
                    type='file'
                    className={`file-input file-input-bordered w-full max-w-sm ${
                      otherImages1Error.file && 'file-input-error'
                    }`}
                    onChange={(e) => handleFileInput(e, 'other1')}
                  />
                )}
                <p className='text-error'>{otherImages1Error.file}</p>
                <label className='form-control mt-4 w-full'>
                  <div className='label'>
                    <span className='label-text font-medium'>
                      <Trans i18nKey={'label.ttl'} />
                    </span>
                  </div>
                  <input
                    type='text'
                    maxLength='100'
                    placeholder={t('placeholder.type-here')}
                    className={`input input-bordered w-full placeholder:text-sm ${
                      otherImages1Error.title ? 'error' : ''
                    }`}
                    value={otherImages1.title}
                    onChange={(e) =>
                      reflectOtherImagesTitle(e.target.value, 'other1')
                    }
                  />
                  <div className='label'>
                    <span className='label-text-alt text-error'>
                      {otherImages1Error.title}
                    </span>
                    <span className='label-text-alt'>
                      {otherImages1.title?.length ?? 0} / 100
                    </span>
                  </div>
                </label>
              </div>
              {/* Other Image 2 */}
              <div>
                <p className='px-1 py-2 text-sm font-semibold'>
                  <Trans i18nKey={'artwork.other-image'} /> 2/3 (
                  <Trans i18nKey={'label.option'} />)
                </p>
                {otherImages2.preview ? (
                  <>
                    <img
                      src={otherImages2.preview}
                      className='h-[200px] w-[200px] object-contain'
                      alt='other2'
                    />
                    <button
                      className='btn mt-4'
                      onClick={() =>
                        setOtherImages2({
                          ...otherImages2,
                          file: null,
                          preview: null,
                        })
                      }
                    >
                      <Trans i18nKey={'btn.delete-image'} />
                    </button>
                  </>
                ) : (
                  <input
                    type='file'
                    className={`file-input file-input-bordered w-full max-w-sm ${
                      otherImages2Error.file && 'file-input-error'
                    }`}
                    onChange={(e) => handleFileInput(e, 'other2')}
                  />
                )}
                <p className='text-error'>{otherImages2Error.file}</p>
                <label className='form-control mt-4 w-full'>
                  <div className='label'>
                    <span className='label-text font-medium'>
                      <Trans i18nKey={'label.ttl'} />
                    </span>
                  </div>
                  <input
                    type='text'
                    maxLength='100'
                    placeholder={t('placeholder.type-here')}
                    className={`input input-bordered w-full placeholder:text-sm ${
                      otherImages2Error.title ? 'input-error' : ''
                    }`}
                    value={otherImages2.title}
                    onChange={(e) =>
                      reflectOtherImagesTitle(e.target.value, 'other2')
                    }
                  />
                  <div className='label'>
                    <span className='label-text-alt text-error'>
                      {otherImages2Error.title}
                    </span>
                    <span className='label-text-alt'>
                      {otherImages2.title?.length ?? 0} / 100
                    </span>
                  </div>
                </label>
              </div>
              {/* Other Image 3 */}
              <div>
                <p className='px-1 py-2 text-sm font-semibold'>
                  <Trans i18nKey={'artwork.other-image'} /> 3/3 (
                  <Trans i18nKey={'label.option'} />)
                </p>
                {otherImages3.preview ? (
                  <>
                    <img
                      src={otherImages3.preview}
                      className='h-[200px] w-[200px] object-contain'
                      alt='other3'
                    />
                    <button
                      className='btn mt-4'
                      onClick={() =>
                        setOtherImages3({
                          ...otherImages3,
                          file: null,
                          preview: null,
                        })
                      }
                    >
                      <Trans i18nKey={'btn.delete-image'} />
                    </button>
                  </>
                ) : (
                  <input
                    type='file'
                    className={`file-input file-input-bordered w-full max-w-sm ${
                      otherImages3Error.file && 'file-input-error'
                    }`}
                    onChange={(e) => handleFileInput(e, 'other3')}
                  />
                )}
                <p className='form-error'>{otherImages3Error.file}</p>
                <label className='form-control mt-4 w-full'>
                  <div className='label'>
                    <span className='label-text font-medium'>
                      <Trans i18nKey={'label.ttl'} />
                    </span>
                  </div>
                  <input
                    type='text'
                    maxLength='100'
                    placeholder={t('placeholder.type-here')}
                    className={`input input-bordered w-full placeholder:text-sm ${
                      otherImages3Error.title ? 'input-error' : ''
                    }`}
                    value={otherImages3.title}
                    onChange={(e) =>
                      reflectOtherImagesTitle(e.target.value, 'other3')
                    }
                  />
                  <div className='label'>
                    <span className='label-text-alt text-error'>
                      {otherImages3Error.title}
                    </span>
                    <span className='label-text-alt'>
                      {otherImages3.title?.length ?? 0} / 100
                    </span>
                  </div>
                </label>
              </div>
            </div>
          </div>

          <div className='form-control mt-5'>
            <label className='label flex cursor-pointer items-start justify-start gap-3'>
              <input
                checked={isMatureContent}
                onChange={() => {
                  setIsMatureContent(!isMatureContent);
                }}
                type='checkbox'
                className='checkbox-primary checkbox'
              />
              <div className='font-normal'>
                <div>
                  <Trans i18nKey={'artwork.mature-content-ttl'} />
                </div>
                <div>
                  <Trans i18nKey={'artwork.mature-content-desc'} />
                </div>
                <div
                  className='mt-2 flex gap-1 underline'
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    document.getElementById('mature_content_modal').showModal();
                  }}
                >
                  <QuestionMarkCircleIcon className='h-6 w-6 shrink-0' />
                  <Trans i18nKey={'artwork.mature-content-desc-2'} />
                </div>
              </div>
            </label>
          </div>
          <div className='divider my-5'></div>

          <div className='my-5 flex justify-between gap-4'>
            <button
              className='btn btn-ghost'
              onClick={() =>
                document.getElementById('inactive_item_modal').showModal()
              }
            >
              <Trans i18nKey={'btn.delete'} />
            </button>
            <div className='flex gap-4'>
              {/* 保存 */}
              <button
                onClick={handleSubmit}
                className={`btn btn-primary`}
                disabled={hasError}
              >
                <Trans i18nKey={'btn.save'} />
              </button>
            </div>
          </div>
        </div>
      </div>
      <EditPictureFrameModal
        pictureFrames={filteredFrames}
        customPictureFrames={filteredCustomFrames}
        frame={frame}
        setFrame={setFrame}
        frameType={frameType}
        frameColor={frameColor}
        setFrameColor={setFrameColor}
        matColor={matColor}
        setMatColor={setMatColor}
        imageUrl={imageUrl}
        isOpen={isPictureFrameOpen}
        onClose={() => setIsPictureFrameOpen(false)}
      />
      <dialog id='mature_content_modal' className='modal'>
        <div className='modal-box flex max-w-xl flex-col gap-4'>
          <div className='text-lg font-bold'>
            <Trans i18nKey={'mature-content-explanation-modal.ttl'} />
          </div>
          <div className='mt-5 inline-flex items-center justify-start'>
            <div className='text-base font-bold leading-normal'>
              <Trans i18nKey={'mature-content-explanation-modal.heading-1'} />
            </div>
          </div>
          <div className='flex flex-col gap-5'>
            <p>
              <Trans i18nKey={'mature-content-explanation-modal.desc-1'} />
            </p>
          </div>

          <div className='inline-flex items-center justify-start'>
            <div className='text-base font-bold leading-normal'>
              <Trans i18nKey={'mature-content-explanation-modal.heading-2'} />
            </div>
          </div>
          <div className='flex flex-col gap-5'>
            <p>
              <Trans i18nKey={'mature-content-explanation-modal.desc-2'} />
            </p>
          </div>

          <div className='inline-flex items-center justify-start'>
            <div className='text-base font-bold leading-normal'>
              <Trans i18nKey={'mature-content-explanation-modal.heading-3'} />
            </div>
          </div>
          <div className='flex flex-col gap-5'>
            <p>
              <Trans i18nKey={'mature-content-explanation-modal.desc-3'} />
            </p>
          </div>
          <div className='modal-action'>
            <form method='dialog'>
              {/* if there is a button in form, it will close the modal */}
              <button className='btn'>
                <Trans i18nKey={'btn.close'} />
              </button>
            </form>
          </div>
        </div>
      </dialog>
      <InactiveItemModal itemId={itemId} />
      {isLoading && <LoadingSpinner />}
    </>
  );
};

export default EditItem;
