import { useMutation } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import parse from 'html-react-parser';
import LoadingSpinner from '../../../components/LoadingSpinner';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import { errorToast, successToast } from '../../../utils/toast';
import { createFaq } from '../../../services/Admin';
import { Tiptap } from '../../../components/Tiptap';
import { CreateFaqSchema } from '../../../utils/validation';
import { escapeToHTML } from '../../../utils/StringUtils';

export const AdminFaqCreate = () => {
  const navigate = useNavigate();
  const [question, setQuestion] = useState('');
  const [questionJa, setQuestionJa] = useState('');
  const [answer, setAnswer] = useState('');
  const [answerJa, setAnswerJa] = useState('');
  const [order, setOrder] = useState(0);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
  } = useForm({
    mode: 'onSubmit',
    resolver: zodResolver(CreateFaqSchema),
    defaultValues: {
      question,
      questionJa,
      answer,
      answerJa,
      categoryId: 1,
      isPublished: true,
      isActive: true,
      order: 0,
    },
  });

  useEffect(() => {
    setValue('answer', answer);
  }, [answer]);

  useEffect(() => {
    setValue('answerJa', answerJa);
  }, [answerJa]);

  // create処理
  const createFaqMutation = useMutation({
    mutationFn: async (data) => {
      return await createFaq(data);
    },
    onSuccess: () => {
      navigate('/admin/faqs');
      successToast('Faq successfully created');
    },
    onError: (err) => {
      if (err.response.data.statusCode == 403) {
        errorToast(err?.response?.data.message);
      } else {
        errorToast(
          'Something went wrong. We are aware of the problem and will try to fix it as soon as possible. Sorry for the inconvenience.'
        );
      }
    },
  });

  const onSubmit = async (data) => {
    createFaqMutation.mutate(data);
  };

  return (
    <main className='container m-auto flex-auto pb-10'>
      <div className='mx-auto h-full max-w-5xl pt-6'>
        <button className='btn btn-ghost' onClick={() => navigate(-1)}>
          <ArrowLeftIcon className='h-6 w-6 shrink-0' />
          Back
        </button>
        <div className='flex w-full flex-col'>
          <h1 className='my-6 text-4xl font-bold'>Create a FAQ</h1>
          <div className='flex w-full flex-col gap-5 lg:flex-row'>
            {/* English start */}
            <div className='flex-1 bg-neutral-50 p-6'>
              <p className='pb-8 text-2xl font-bold'>English</p>
              <label className='form-control w-full'>
                <div className='label'>
                  <span className='label-text font-medium'>Question</span>
                  <span className='label-text-alt'></span>
                </div>
                <textarea
                  {...register('question', { required: false })}
                  value={question}
                  className={`textarea textarea-bordered w-full ${
                    errors.question?.message && 'textarea-error'
                  }`}
                  placeholder='Type here'
                  onChange={(e) => setQuestion(e.target.value)}
                />
                <p className='mt-2 text-sm text-error'>
                  {errors.question?.message}
                </p>
              </label>

              <div className='mt-6'>
                <label className='form-control w-full'>
                  <div className='label'>
                    <span className='label-text text-sm font-medium'>
                      Answer
                    </span>
                    <span className='label-text-alt'></span>
                  </div>
                </label>
                <Tiptap bodyText={answer} onChange={(v) => setAnswer(v)} />
              </div>

              <p className='label-text my-6 font-medium'>Answer preview</p>
              <div
                style={{ whiteSpace: 'pre-wrap' }}
                className='min-h-20 rounded-lg bg-base-200 p-4'
              >
                {/* faq-answerクラスはul,olにpaddingをつけるためのもの（index.cssで定義） */}
                <div className='faq-answer text-sm'>
                  {parse(escapeToHTML(answer))}
                </div>
              </div>
            </div>
            {/* English end */}

            {/* Japanese start */}
            <div className='flex-1 bg-neutral-50 p-6'>
              <p className='pb-8 text-2xl font-bold'>Japanese</p>
              <label className='form-control w-full'>
                <div className='label'>
                  <span className='label-text font-medium'>質問</span>
                  <span className='label-text-alt'></span>
                </div>
                <textarea
                  {...register('questionJa', { required: false })}
                  value={questionJa}
                  className={`textarea textarea-bordered w-full ${
                    errors.questionJa?.message && 'textarea-error'
                  }`}
                  placeholder='Type here'
                  onChange={(e) => setQuestionJa(e.target.value)}
                />
                <p className='mt-2 text-sm text-error'>
                  {errors.questionJa?.message}
                </p>
              </label>

              <div className='mt-6'>
                <label className='form-control w-full'>
                  <div className='label'>
                    <span className='label-text text-sm font-semibold'>
                      回答
                    </span>
                    <span className='label-text-alt'></span>
                  </div>
                </label>
                <Tiptap bodyText={answerJa} onChange={(v) => setAnswerJa(v)} />
              </div>

              <p className='label-text my-6 font-medium'>回答プレビュー</p>
              <div
                style={{ whiteSpace: 'pre-wrap' }}
                className='min-h-20 rounded-lg bg-base-200 p-4'
              >
                {/* faq-answerクラスはul,olにpaddingをつけるためのもの（index.cssで定義） */}
                <div className='faq-answer text-sm'>
                  {parse(escapeToHTML(answerJa))}
                </div>
              </div>
            </div>
            {/* Japanese end */}
          </div>

          <label className='form-control mt-6 w-full'>
            <div className='label'>
              <span className='label-text'>Order</span>
              <span className='label-text-alt'>Optional</span>
            </div>
            <input
              value={order}
              type='number'
              placeholder='Type here'
              className={`input input-bordered ${
                errors.order?.message && 'border-error'
              }`}
              onChange={(e) => {
                setOrder(Number(e.target.value));
                setValue('order', Number(e.target.value));
              }}
            />
            <p className='mt-2 text-sm text-error'>{errors.order?.message}</p>
          </label>
          <div className='divider my-10 py-0' />
          <div className='flex justify-between'>
            <Link to={'/admin/faqs'} className='b btn'>
              Cancel
            </Link>
            <button
              className='btn btn-primary'
              onClick={handleSubmit(onSubmit)}
            >
              Create
            </button>
          </div>
        </div>
      </div>
      {createFaqMutation.isPending && <LoadingSpinner />}
    </main>
  );
};
