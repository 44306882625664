import { Clone, useAnimations, useGLTF } from '@react-three/drei';
import { useEffect, useLayoutEffect, useRef } from 'react';

const Woman1 = ({ ...props }) => {
  const { scene, materials, animations } = useGLTF(
    'https://data.curatedartshow.com/models/woman1_non_animation.glb'
  );
  const cloneRef = useRef();
  useEffect(() => {
    return () => {
      //リソースのクリーンアップ処理
      useGLTF.clear(
        'https://data.curatedartshow.com/models/woman1_non_animation.glb'
      );
    };
  }, []);

  useLayoutEffect(() => {
    // console.log(materials);

    materials['Autumn.011'].roughness = 1;
    materials['Autumn.011'].metalness = 1;
    materials['Autumn.011'].opacity = 1;
    materials['Autumn.011'].transparent = true;
    materials['Autumn.011'].color.set('#000');

    scene.traverse((obj) => {
      if (obj.isMesh) obj.castShadow = obj.receiveShadow = true;
    });

    return () => {
      // マテリアルの解放
      Object.values(materials).forEach((material) => {
        material.dispose();
      });

      // シーンからオブジェクトの削除
      scene.traverse((obj) => {
        if (obj.isMesh) {
          obj.geometry.dispose();
          obj.material.dispose();
          scene.remove(obj);
        }
      });
    };
  }, []);
  return <Clone object={scene} ref={cloneRef} {...props} />;
};

export default Woman1;
