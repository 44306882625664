import { useGLTF } from '@react-three/drei';
import { Suspense, useLayoutEffect, useState } from 'react';

const PictureFrame42 = ({ color, ...props }) => {
  const { scene, materials } = useGLTF(
    'https://data.curatedartshow.com/models/frames/purple-edge.glb'
  );

  const [geometry, setGeometry] = useState();

  if (!geometry) {
    const cloneScene = scene.clone(true);
    setGeometry(cloneScene);
  }

  useLayoutEffect(() => {
    // console.log(materials);
    // materials['Bleached Cast Concrete_material3'].color.set('#ebeaea');
    materials['Bleached Cast Concrete_material3'].metalness = 1;
    materials['Bleached Cast Concrete_material3'].roughness = 2;
    // materials['Soft Purple Satin Ceramic_material2'].color.set('#191919');
    materials['Soft Purple Satin Ceramic_material2'].metalness = 0.5;
    materials['Soft Purple Satin Ceramic_material2'].roughness = 1;
    materials['frame.001'].color.set('#b4b4b4');
    materials['frame.001'].metalness = 1;
    materials['frame.001'].roughness = 2;

    geometry.traverse((obj) => {
      if (obj.isMesh) obj.castShadow = obj.receiveShadow = true;
    });
  }, []);

  return (
    <Suspense fallback={null}>
      <primitive object={geometry} {...props} />
    </Suspense>
  );
};

export default PictureFrame42;
