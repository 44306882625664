import { useLoader } from '@react-three/fiber';
import { Image } from '@react-three/drei';
import { TextureLoader } from 'three/src/loaders/TextureLoader';
import PictureFrameGroup from '../frames/PictureFrameGroup';

const ItemOnPreview = ({ position, item, frame, frameColor, matColor }) => {
  const GOLDENRATIO = 1.61803398875;
  const scaleAdjustment = 0.95;
  const imageMap = useLoader(TextureLoader, item?.itemUrl);

  const aspectRatio =
    imageMap.source?.data?.width / imageMap.source?.data?.height;

  return (
    <group position={position}>
      <mesh scale={[1, 1, 0.05]} position={[0, GOLDENRATIO / 2.5, 0]}>
        <PictureFrameGroup
          item={item}
          frame={frame}
          GOLDENRATIO={GOLDENRATIO}
          aspectRatio={aspectRatio}
          frameColor={frameColor}
          matColor={matColor}
        />

        <Image
          raycast={() => null}
          position={[0, 0, 0.7]}
          url={item?.itemUrl + '?q=60'}
          scale={[
            aspectRatio < 1 ? scaleAdjustment : 1.2,
            aspectRatio < 1 ? scaleAdjustment / aspectRatio : 1.2 / aspectRatio,
            1,
          ]}
        />
      </mesh>
    </group>
  );
};

export default ItemOnPreview;
