import { useState, useEffect } from 'react';
import { authRequest } from '../../../../requestMethods';
import { TrashIcon } from '@heroicons/react/24/outline';

import DeleteModal from '../../components/DeleteModal';
import { errorToast, successToast } from '../../../../utils/toast';
import { useDispatch } from 'react-redux';
import { loadEnd, loadStart } from '../../../../redux/loadRedux';
import { Link, useNavigate } from 'react-router-dom';
import { deleteCeiling, getCeilingTextures } from '../../../../services/Admin';
import { useMutation, useQuery } from '@tanstack/react-query';
import LoadingSpinner from '../../../../components/LoadingSpinner';

const AdminCeilingTextureList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [deleteId, setDeleteId] = useState(null);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [filteredArtworks, setFilteredArtworks] = useState([]);

  const {
    data: ceilingTextures,
    isLoading: isCeilingTexturesLoading,
    refetch: refetchCeilingTextures,
  } = useQuery({
    queryKey: ['admin-ceiling-textures'],
    queryFn: () => getCeilingTextures(),
    retry: (failureCount, error) => {
      if (error.response?.status === 401 || error.response?.status === 403) {
        return false; // 再認証が必要な場合はリトライしない
      }
    },
    meta: { errCode: 'ADMIN_GET_CEILING_TEXTURES' },
  });

  const handleDelete = async () => {
    deleteCeilingMutation.mutate();
  };

  // delete処理
  const deleteCeilingMutation = useMutation({
    mutationFn: async () => {
      return await deleteCeiling(deleteId);
    },
    onSuccess: () => {
      successToast('Texture deleted successfully!');
      document.getElementById('delete_modal').close();
      refetchCeilingTextures();
    },
    onError: (err) => {
      console.error(err);

      if (err.response.data.statusCode == 403) {
        navigate('/admin/login');
      } else {
        errorToast(
          'Something went wrong. We are aware of the problem and will try to fix it as soon as possible. Sorry for the inconvenience.'
        );
      }
    },
  });

  useEffect(() => {
    if (searchKeyword) {
      const filtered = ceilingTextures.filter((artwork) =>
        artwork.title.toLowerCase().includes(searchKeyword)
      );
      setFilteredArtworks(filtered);
    } else {
      setFilteredArtworks(ceilingTextures);
    }
  }, [ceilingTextures, searchKeyword]);

  return (
    <>
      <h2 className='pb-8 text-3xl font-extrabold'>Ceiling Texture</h2>
      <Link to={'/admin/textures/ceiling/create'} className='btn btn-primary'>
        Add ceiling texture
      </Link>

      <div className='mt-10 overflow-x-auto'>
        {isCeilingTexturesLoading ? (
          <span className='loading loading-spinner text-primary'></span>
        ) : (
          <table className='table table-auto'>
            {/* head */}
            <thead>
              <tr>
                <th>Id</th>
                <th>Thumbnail</th>
                <th>Name</th>
                <th>Availability</th>
                <th>Status</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {filteredArtworks?.map((item) => (
                <tr key={`ceiling-${item.id}`}>
                  <th>{item.id}</th>
                  <td>
                    <img
                      className='h-24 w-24 object-cover'
                      src={item.thumbnail + '?q=60'}
                      alt=''
                    />
                  </td>
                  <td className='max-w-48'>{item.title}</td>
                  <td className='max-w-56'>
                    <div className='badge badge-outline'>
                      {item.isPro ? 'CAS-5 Only' : 'All Plans'}
                    </div>
                  </td>
                  <td>{item.published ? 'Published' : 'Unpublished'}</td>
                  <td>
                    <Link
                      to={`/admin/textures/ceiling/edit/${item.id}`}
                      className='btn btn-sm'
                    >
                      Edit
                    </Link>
                  </td>
                  <td className='min-w-36'>
                    <button
                      className='btn btn-outline btn-error btn-sm'
                      onClick={() => {
                        setDeleteId(item.id);
                        document.getElementById('delete_modal').showModal();
                      }}
                    >
                      <TrashIcon className='h-5 w-5' />
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>

      <DeleteModal
        itemName='texture'
        confirmDelete={handleDelete}
        loading={deleteCeilingMutation.isPending}
      />
    </>
  );
};

export default AdminCeilingTextureList;
