import { useGLTF } from '@react-three/drei';
import { Suspense, useLayoutEffect, useState } from 'react';
import * as THREE from 'three';

const PictureFrame75 = ({ color, ...props }) => {
  const { scene, materials } = useGLTF(
    'https://data.curatedartshow.com/models/frames/koiframe7.glb'
  );

  const [geometry, setGeometry] = useState();

  if (!geometry) {
    const cloneScene = scene.clone(true);
    setGeometry(cloneScene);
  }

  useLayoutEffect(() => {
    // console.log(materials);
    // materials['Soft Purple Satin Ceramic_material'].color.set('#856f06');
    materials['Soft Purple Satin Ceramic_material'].metalness = 1;
    materials['Soft Purple Satin Ceramic_material'].roughness = 2;
    // materials['Dark Blue Ceramic_material'].color.set('#6a3e01');
    materials['Dark Blue Ceramic_material'].metalness = 0.2;
    materials['Dark Blue Ceramic_material'].roughness = 3;
    materials['Dark Blue Ceramic_material'].normalScale = new THREE.Vector2(
      0,
      0
    );

    // materials['Dark Brown Ceramic_material].color.set('#1d1d1d');
    materials['Dark Brown Ceramic_material'].metalness = 0.5;
    materials['Dark Brown Ceramic_material'].roughness = 1;
    materials['Dark Brown Ceramic_material'].normalScale = new THREE.Vector2(
      0,
      0
    );

    // materials['default_material107.003'].color.set('#7c87cd');
    // materials['default_material107.003'].metalness = 1;
    // materials['default_material107.003'].roughness = 0;
    // materials['Corroded Rust_material2'].color.set('#868686');
    materials['Corroded Rust_material2'].metalness = 0.5;
    materials['Corroded Rust_material2'].roughness = 2;

    geometry.traverse((obj) => {
      if (obj.isMesh) obj.castShadow = obj.receiveShadow = true;
    });
  }, []);

  return (
    <Suspense fallback={null}>
      <primitive object={geometry} {...props} />
    </Suspense>
  );
};

export default PictureFrame75;
