import { useGLTF } from '@react-three/drei';
import { Suspense, useLayoutEffect, useState } from 'react';

const PictureFrame65 = ({ color, ...props }) => {
  const { scene, materials } = useGLTF(
    'https://data.curatedartshow.com/models/frames/jenaframe8.glb'
  );

  const [geometry, setGeometry] = useState();

  if (!geometry) {
    const cloneScene = scene.clone(true);
    setGeometry(cloneScene);
  }

  useLayoutEffect(() => {
    // console.log(materials);
    // materials['fdm_plastic_infill_material'].color.set('#686868');
    materials['fdm_plastic_infill_material'].metalness = 0.7;
    materials['fdm_plastic_infill_material'].roughness = 1;
    materials['Red Marble_material'].color.set('#de8f8f');
    materials['Red Marble_material'].metalness = 0.5;
    materials['Red Marble_material'].roughness = 0.9;
    materials['Clean Metal Galvanized_material'].color.set('#2b2b2b');
    materials['Clean Metal Galvanized_material'].metalness = 0.8;
    materials['Clean Metal Galvanized_material'].roughness = 2;

    // materials['default_material107.003'].color.set('#7c87cd');
    // materials['default_material107.003'].metalness = 1;
    // materials['default_material107.003'].roughness = 0;
    // materials['Dark Green Fabric_material'].color.set('#7c87cd');
    materials['Dark Green Fabric_material'].metalness = 0.5;
    materials['Dark Green Fabric_material'].roughness = 0.5;

    geometry.traverse((obj) => {
      if (obj.isMesh) obj.castShadow = obj.receiveShadow = true;
    });
  }, []);

  return (
    <Suspense fallback={null}>
      <primitive object={geometry} {...props} />
    </Suspense>
  );
};

export default PictureFrame65;
