import * as THREE from 'three';
import { useEffect, useState } from 'react';

function TubeGeometry({ path, radius, segments }) {
  const tubeGeometry = new THREE.TubeGeometry(path, segments, radius, segments);
  const material = new THREE.MeshBasicMaterial({ color: 'black' });

  useEffect(() => {
    return () => {
      tubeGeometry.dispose();
      material.dispose();
    };
  }, []);

  return <mesh geometry={tubeGeometry} material={material} />;
}

function Cable({ startPoint, endPoint, radius, segments }) {
  const [path, setPath] = useState();

  useEffect(() => {
    const curve = new THREE.CatmullRomCurve3([
      startPoint,
      new THREE.Vector3(
        (startPoint.x + endPoint.x) / 2,
        startPoint.y - 1,
        startPoint.z
      ),
      endPoint,
    ]);
    setPath(curve);
  }, [startPoint, endPoint]);

  return path ? (
    <TubeGeometry path={path} radius={radius} segments={segments} />
  ) : null;
}

export default Cable;
