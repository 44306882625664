import { Trans } from 'react-i18next';
import { ExhibitionContext } from '../../../../context/ExhibitionContext';
import {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';
import { PlayPauseIcon, PlayIcon } from '@heroicons/react/24/outline';

export const Music = () => {
  const { exhibitionSettings, setExhibitionSettings } =
    useContext(ExhibitionContext);

  const [music1Playing, setMusic1Playing] = useState(false);
  const [music2Playing, setMusic2Playing] = useState(false);
  const [music3Playing, setMusic3Playing] = useState(false);

  const [toggleBackgroundMusic, setToggleBackgroundMusic] = useState(false);

  const [music1] = useState(new Audio('/assets/music/sample1.mp3'));
  const [music2] = useState(new Audio('/assets/music/sample2.mp3'));
  const [music3] = useState(new Audio('/assets/music/sample3.mp3'));

  const location = useLocation();

  useLayoutEffect(() => {
    return () => {
      // ページから離れるときのクリーンアップ処理
      music1.pause();
      music2.pause();
      music3.pause();
    };
  }, [location]);

  const handleChangeMusic = (value) => {
    //必要であればvalitationを追加する

    setExhibitionSettings((prevState) => ({
      ...prevState,
      music: value,
    }));
  };

  const handleChangeRandomMusic = (value) => {
    //必要であればvalitationを追加する

    setExhibitionSettings((prevState) => ({
      ...prevState,
      randomMusic: value,
    }));
  };

  const playMusic = (trackNumber) => {
    switch (trackNumber) {
      case 1:
        music2.pause();
        music3.pause();
        music1.play();
        setMusic2Playing(false);
        setMusic3Playing(false);
        setMusic1Playing(true);
        break;
      case 2:
        music1.pause();
        music3.pause();
        music2.play();
        setMusic1Playing(false);
        setMusic3Playing(false);
        setMusic2Playing(true);
        break;
      case 3:
        music1.pause();
        music2.pause();
        music3.play();
        setMusic1Playing(false);
        setMusic2Playing(false);
        setMusic3Playing(true);
        break;
    }
  };

  const pauseMusic = (trackNumber) => {
    switch (trackNumber) {
      case 1:
        music1.pause();
        setMusic1Playing(false);
        break;
      case 2:
        music2.pause();
        setMusic2Playing(false);
        break;
      case 3:
        music3.pause();
        setMusic3Playing(false);
        break;
    }
  };

  const music1ProgressBarRef = useRef(null);
  const music2ProgressBarRef = useRef(null);
  const music3ProgressBarRef = useRef(null);

  useEffect(() => {
    const music1ProgressBar = music1ProgressBarRef.current;
    const music2ProgressBar = music2ProgressBarRef.current;
    const music3ProgressBar = music3ProgressBarRef.current;
    const updateProgress = () => {
      if (music1Playing) {
        const currentTime = music1.currentTime;
        const duration = music1.duration;
        const progress = (currentTime / duration) * 100;
        if (progress == 100) {
          music1ProgressBar.style.width = `0%`;
          setMusic1Playing(false);
        } else {
          music1ProgressBar.style.width = `${progress}%`;
        }
      }
      if (music2Playing) {
        const currentTime = music2.currentTime;
        const duration = music2.duration;
        const progress = (currentTime / duration) * 100;
        if (progress == 100) {
          music2ProgressBar.style.width = `0%`;
          setMusic2Playing(false);
        } else {
          music2ProgressBar.style.width = `${progress}%`;
        }
      }
      if (music3Playing) {
        const currentTime = music3.currentTime;
        const duration = music3.duration;
        const progress = (currentTime / duration) * 100;
        if (progress == 100) {
          music3ProgressBar.style.width = `0%`;
          setMusic3Playing(false);
        } else {
          music3ProgressBar.style.width = `${progress}%`;
        }
      }
    };

    const progressInterval = setInterval(updateProgress, 100); // 100ミリ秒ごとに更新する

    return () => {
      clearInterval(progressInterval); // コンポーネントがアンマウントされたらインターバルをクリアする
    };
  }, [music1Playing, music2Playing, music3Playing]);

  return (
    <div className=''>
      <p className='pb-8 text-xl font-semibold'>
        <Trans i18nKey={'gallery.music.ttl'} />
      </p>

      <p className='mb-5'>
        <Trans i18nKey={'gallery.music.desc'} />
      </p>
      <div className='label justify-start gap-2'>
        <span className='label-text text-base'>
          <Trans i18nKey={'gallery.music.background'} />
        </span>
        <input
          type='checkbox'
          className='toggle'
          checked={exhibitionSettings.music}
          onChange={() => {
            if (exhibitionSettings.music) {
              handleChangeMusic(null);
              setToggleBackgroundMusic(false);
              if (exhibitionSettings.randomMusic) {
                handleChangeRandomMusic(false);
              }
            } else {
              setToggleBackgroundMusic(true);
              handleChangeMusic(1);
            }
          }}
        />
      </div>
      <div className='label justify-start gap-2'>
        <span className='label-text text-base'>
          <Trans i18nKey={'gallery.music.random'} />
        </span>
        <input
          type='checkbox'
          className='toggle'
          checked={exhibitionSettings.randomMusic}
          onChange={() => {
            if (!exhibitionSettings.randomMusic) {
              setToggleBackgroundMusic(true);
              handleChangeMusic(1);
            }
            handleChangeRandomMusic(!exhibitionSettings.randomMusic);
          }}
        />
      </div>

      <div
        className={`mt-5 flex flex-col gap-2 ${
          (exhibitionSettings.music && !exhibitionSettings.randomMusic) ||
          (toggleBackgroundMusic && !exhibitionSettings.randomMusic)
            ? 'pointer-events-auto cursor-auto opacity-100'
            : 'pointer-events-none cursor-not-allowed opacity-30'
        }`}
      >
        <div className='flex justify-between'>
          <div className='flex gap-2'>
            <input
              type='radio'
              className='radio'
              value={1}
              onChange={(e) => handleChangeMusic(parseInt(e.target.value))}
              checked={exhibitionSettings.music === 1}
            />
            <p className='text-md font-bold'>
              <Trans i18nKey={'gallery.music.sample'} /> 1
            </p>
          </div>
          <div className='flex max-w-[248px] flex-grow items-center '>
            {music1Playing ? (
              <button className='ml-3 mr-2' onClick={() => pauseMusic(1)}>
                <PlayPauseIcon className='h-5 w-5' />
              </button>
            ) : (
              <button className='ml-3 mr-2' onClick={() => playMusic(1)}>
                <PlayIcon className='h-5 w-5' />
              </button>
            )}
            <div className='h-2.5 w-full rounded-full bg-[#808080]'>
              <div
                ref={music1ProgressBarRef}
                className={`h-2.5 w-0 rounded-full bg-gray-700`}
              ></div>
            </div>
          </div>
        </div>

        <div className='flex justify-between'>
          <div className='flex gap-2'>
            <input
              type='radio'
              className='radio'
              value={2}
              onChange={(e) => handleChangeMusic(parseInt(e.target.value))}
              checked={exhibitionSettings.music === 2}
            />
            <p className='text-md font-bold'>
              <Trans i18nKey={'gallery.music.sample'} /> 2
            </p>
          </div>
          <div className='flex max-w-[248px] flex-grow items-center'>
            {music2Playing ? (
              <button className='ml-3 mr-2' onClick={() => pauseMusic(2)}>
                <PlayPauseIcon className='h-5 w-5' />
              </button>
            ) : (
              <button className='ml-3 mr-2' onClick={() => playMusic(2)}>
                <PlayIcon className='h-5 w-5' />
              </button>
            )}
            <div className='h-2.5 w-full rounded-full bg-[#808080]'>
              <div
                ref={music2ProgressBarRef}
                className='h-2.5 w-0 rounded-full bg-gray-700'
              ></div>
            </div>
          </div>
        </div>

        <div className='flex justify-between'>
          <div className='flex gap-2'>
            <input
              type='radio'
              className='radio'
              value={3}
              onChange={(e) => handleChangeMusic(parseInt(e.target.value))}
              checked={exhibitionSettings.music === 3}
            />
            <p className='text-md font-bold'>
              <Trans i18nKey={'gallery.music.sample'} /> 3
            </p>
          </div>
          <div className='flex max-w-[248px] flex-grow items-center'>
            {music3Playing ? (
              <button className='ml-3 mr-2' onClick={() => pauseMusic(3)}>
                <PlayPauseIcon className='h-5 w-5' />
              </button>
            ) : (
              <button className='ml-3 mr-2' onClick={() => playMusic(3)}>
                <PlayIcon className='h-5 w-5' />
              </button>
            )}
            <div className='h-2.5 w-full rounded-full bg-[#808080]'>
              <div
                ref={music3ProgressBarRef}
                className='h-2.5 w-0 rounded-full bg-gray-700'
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
