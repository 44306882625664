import { CheckIcon } from 'lucide-react';
import React, { useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import PromotionCardSmall from './PromotionCardSmall';
import { useSelector } from 'react-redux';
import { publicRequest } from '../requestMethods';

const PriceCards = ({ handleSubscribe, currentPlan }) => {
  const { t } = useTranslation();
  const user = useSelector((state) => state.user.currentUser);
  const [premiumPlan, setPremiumPlan] = useState(null);
  const [vipPlan, setVipPlan] = useState(null);

  const fetchPrices = async () => {
    const res = await publicRequest.get('/stripe/prices');

    if (res.data) {
      const premium = res.data.find(
        (item) =>
          item.product ==
          process.env.REACT_APP_STRIPE_PREMIUM_CAS_MEMBER_PRODUCT_ID
      );
      setPremiumPlan(premium);

      const vip = res.data.find(
        (item) =>
          item.product == process.env.REACT_APP_STRIPE_VIP_CAS_MEMBER_PRODUCT_ID
      );
      setVipPlan(vip);
    }
  };

  useEffect(() => {
    fetchPrices();
  }, []);

  const {
    // 展示会上限値
    REACT_APP_MAX_EXHIBITION_MEMBER: MAX_EXHIBITION_MEMBER,
    REACT_APP_MAX_EXHIBITION_PREMIUM: MAX_EXHIBITION_PREMIUM,
    REACT_APP_MAX_EXHIBITION_VIP: MAX_EXHIBITION_VIP,
    REACT_APP_MAX_PUBLISHED_EXHIBITION_MEMBER: MAX_PUBLISHED_EXHIBITION_MEMBER,
    REACT_APP_MAX_PUBLISHED_EXHIBITION_PREMIUM:
      MAX_PUBLISHED_EXHIBITION_PREMIUM,
    REACT_APP_MAX_PUBLISHED_EXHIBITION_VIP: MAX_PUBLISHED_EXHIBITION_VIP,

    // アートワーク上限値
    REACT_APP_MAX_UPLOAD_ARTWORK_MEMBER: MAX_UPLOAD_MEMBER,
    REACT_APP_MAX_UPLOAD_ARTWORK_PREMIUM: MAX_UPLOAD_PREMIUM,
    REACT_APP_MAX_UPLOAD_ARTWORK_VIP: MAX_UPLOAD_VIP,

    // 各種素材上限値
    REACT_APP_MAX_FRAME_MEMBER: MAX_FRAME_MEMBER,
    REACT_APP_MAX_FRAME_PREMIUM: MAX_FRAME_PREMIUM,
    REACT_APP_MAX_FRAME_VIP: MAX_FRAME_VIP,

    REACT_APP_MAX_WALL_MEMBER: MAX_WALL_MEMBER,
    REACT_APP_MAX_WALL_PREMIUM: MAX_WALL_PREMIUM,
    REACT_APP_MAX_WALL_VIP: MAX_WALL_VIP,

    REACT_APP_MAX_FLOOR_MEMBER: MAX_FLOOR_MEMBER,
    REACT_APP_MAX_FLOOR_PREMIUM: MAX_FLOOR_PREMIUM,
    REACT_APP_MAX_FLOOR_VIP: MAX_FLOOR_VIP,

    REACT_APP_MAX_CEILING_MEMBER: MAX_CEILING_MEMBER,
    REACT_APP_MAX_CEILING_PREMIUM: MAX_CEILING_PREMIUM,
    REACT_APP_MAX_CEILING_VIP: MAX_CEILING_VIP,

    REACT_APP_MAX_MUSIC_MEMBER: MAX_MUSIC_MEMBER,
    REACT_APP_MAX_MUSIC_PREMIUM: MAX_MUSIC_PREMIUM,
    REACT_APP_MAX_MUSIC_VIP: MAX_MUSIC_VIP,

    REACT_APP_MAX_SILHOUETTE_MEMBER: MAX_SILHOUETTE_MEMBER,
    REACT_APP_MAX_SILHOUETTE_PREMIUM: MAX_SILHOUETTE_PREMIUM,
    REACT_APP_MAX_SILHOUETTE_VIP: MAX_SILHOUETTE_VIP,

    REACT_APP_MAX_VIDEO_MEMBER: MAX_VIDEO_MEMBER,
    REACT_APP_MAX_VIDEO_PREMIUM: MAX_VIDEO_PREMIUM,
    REACT_APP_MAX_VIDEO_VIP: MAX_VIDEO_VIP,
  } = process.env;

  return (
    <div className='flex w-full flex-col gap-10 lg:flex-row'>
      {/* CAS Member */}
      <div className='relative flex-1 rounded-2xl border-[1.5px] border-accent bg-[#ECFDF5] p-8'>
        <div className='badge absolute -top-3 left-1/2 -translate-x-1/2 !border-[#00D8BF] !bg-[#00D8BF] text-sm'>
          <Trans i18nKey={'price-card.most-popular'} />
        </div>
        <div className='flex flex-col justify-between gap-4'>
          <div>
            <p className='font-semibold'>
              <Trans i18nKey={'price-card.member'} />
            </p>
            <p className='text-xs font-semibold text-gray-500'>
              <Trans i18nKey={'price-card.for-artists'} />
            </p>
          </div>
          <div>
            <p className='text-3xl font-[900]'>
              <Trans i18nKey={'price-card.free'} />
            </p>
          </div>
          {currentPlan && (
            <div className='min-h-[4.5rem]'>
              <button className='btn w-full' disabled>
                {currentPlan == 'FREE'
                  ? 'Current Plan'
                  : currentPlan == 'VIP' || currentPlan == 'PREMIUM'
                  ? 'Downgrade to CAS Member'
                  : 'Unavailable'}
              </button>
            </div>
          )}
        </div>
        <div className='mt-6 flex flex-col gap-1'>
          <div className='flex items-start gap-2 text-base-content'>
            <CheckIcon className='h-5 w-5 shrink-0' />
            <p className='text-sm'>
              {t('price-card.published-variable', {
                max: MAX_PUBLISHED_EXHIBITION_MEMBER,
              })}
            </p>
          </div>
          <div className='flex items-start gap-2 text-base-content'>
            <CheckIcon className='h-5 w-5 shrink-0' />
            <p className='text-sm'>
              {t('price-card.create-save-variable', {
                max: MAX_EXHIBITION_MEMBER,
              })}
            </p>
          </div>
          <div className='flex items-start gap-2 text-base-content'>
            <CheckIcon className='h-5 w-5 shrink-0' />
            <p className='text-sm'>
              {t('price-card.upload-variable', {
                max: MAX_UPLOAD_MEMBER,
              })}
            </p>
          </div>
          <div className='text-base-content'>
            <div className='flex items-start gap-2 '>
              <CheckIcon className='h-5 w-5 shrink-0' />
              <p className='text-sm'>
                <Trans i18nKey={'price-card.choose-from'} />
              </p>
            </div>
            <ul className='list-inside list-disc pl-7 text-sm'>
              <li>
                {t('price-card.frame-variable', {
                  max: MAX_FRAME_MEMBER,
                })}
              </li>
              <li>
                {t('price-card.wall-variable', {
                  max: MAX_WALL_MEMBER,
                })}
              </li>
              <li>
                {t('price-card.floor-variable', {
                  max: MAX_FLOOR_MEMBER,
                })}
              </li>
              <li>
                {t('price-card.ceiling-variable', {
                  max: MAX_CEILING_MEMBER,
                })}
              </li>
              <li>
                {t('price-card.music-variable', {
                  max: MAX_MUSIC_MEMBER,
                })}
              </li>
              <li>
                {t('price-card.silhouette-variable', {
                  max: MAX_SILHOUETTE_MEMBER,
                })}
              </li>
              <li>
                {t('price-card.video-variable', {
                  max: MAX_VIDEO_MEMBER,
                })}
              </li>
            </ul>
          </div>
          <div className='flex items-start gap-2 text-base-content'>
            <CheckIcon className='h-5 w-5 shrink-0' />
            <p className='text-sm'>
              <Trans i18nKey={'price-card.basic-analytics'} />
            </p>
          </div>
        </div>
        {!user && (
          <div className='mt-6'>
            <PromotionCardSmall />
          </div>
        )}
      </div>

      {/* Premium CAS Member */}
      <div className='relative flex-1 rounded-2xl bg-gray-50 p-8'>
        <div className='flex flex-col justify-between gap-4'>
          <div>
            <p className='font-semibold'>
              <Trans i18nKey={'price-card.premium-member'} />
            </p>
            <p className='text-xs font-semibold text-gray-500'>
              <Trans i18nKey={'price-card.for-artists-curators'} />
            </p>
          </div>
          <div>
            <p className='text-3xl font-[900]'>
              {t('price-card.cas-price-variable', {
                price: 9,
              })}
            </p>
          </div>
          {currentPlan && (
            <div className='flex min-h-[4.5rem] flex-col gap-2'>
              <button
                className='btn btn-primary w-full'
                onClick={() => {
                  handleSubscribe([premiumPlan.priceId]);
                }}
                disabled={
                  currentPlan == 'PREMIUM' ||
                  currentPlan == 'VIP' ||
                  !currentPlan
                }
              >
                {premiumPlan ? (
                  <>
                    {currentPlan == 'FREE'
                      ? 'Upgrade to Premium'
                      : currentPlan == 'PREMIUM'
                      ? 'Current Plan'
                      : currentPlan == 'VIP'
                      ? 'Downgrade to Premium'
                      : 'Unavailable'}
                  </>
                ) : (
                  <>
                    <span className='loading loading-spinner'></span>
                    Loading
                  </>
                )}
              </button>
              <p className='text-xs text-gray-500'>
                Opens on secure checkout page (Stripe)
              </p>
            </div>
          )}
        </div>
        <div className='mt-6 flex flex-col gap-1'>
          <div className='flex items-start gap-2 text-base-content'>
            <CheckIcon className='h-5 w-5 shrink-0' />
            <p className='text-sm'>
              {t('price-card.published-variable', {
                max: MAX_PUBLISHED_EXHIBITION_PREMIUM,
              })}
            </p>
          </div>
          <div className='flex items-start gap-2 text-base-content'>
            <CheckIcon className='h-5 w-5 shrink-0' />
            <p className='text-sm'>
              <Trans i18nKey={'price-card.password-protect'} />
            </p>
          </div>
          <div className='flex items-start gap-2 text-base-content'>
            <CheckIcon className='h-5 w-5 shrink-0' />
            <p className='text-sm'>
              {t('price-card.create-save-variable', {
                max: MAX_EXHIBITION_PREMIUM,
              })}
            </p>
          </div>
          <div className='flex items-start gap-2 text-base-content'>
            <CheckIcon className='h-5 w-5 shrink-0' />
            <p className='text-sm'>
              {t('price-card.upload-variable', {
                max: MAX_UPLOAD_PREMIUM,
              })}
            </p>
          </div>
          <div className='text-base-content'>
            <div className='flex items-start gap-2 '>
              <CheckIcon className='h-5 w-5 shrink-0' />
              <p className='text-sm'>
                <Trans i18nKey={'price-card.choose-from'} />
              </p>
            </div>
            <ul className='list-inside list-disc pl-7 text-sm'>
              <li>
                {t('price-card.frame-with-custom-variable', {
                  max: MAX_FRAME_PREMIUM,
                })}
              </li>
              <li>
                {t('price-card.wall-variable', {
                  max: MAX_WALL_PREMIUM,
                })}
              </li>
              <li>
                {t('price-card.floor-variable', {
                  max: MAX_FLOOR_PREMIUM,
                })}
              </li>
              <li>
                {t('price-card.ceiling-variable', {
                  max: MAX_CEILING_PREMIUM,
                })}
              </li>
              <li>
                <Trans i18nKey={'price-card.unlimited-assets'} />
              </li>
              <li>
                {t('price-card.music-variable', {
                  max: MAX_MUSIC_PREMIUM,
                })}
              </li>
              <li>
                {t('price-card.silhouette-variable', {
                  max: MAX_SILHOUETTE_PREMIUM,
                })}
              </li>
              <li>
                {t('price-card.video-variable', {
                  max: MAX_VIDEO_PREMIUM,
                })}
              </li>
            </ul>
          </div>
          <div className='flex items-start gap-2 text-base-content'>
            <CheckIcon className='h-5 w-5 shrink-0' />
            <p className='text-sm'>
              <Trans i18nKey={'price-card.advanced-analytics'} />
            </p>
          </div>
          {!user && (
            <div className='mt-6 min-h-[4.5rem] rounded-xl bg-white p-4'>
              <p className='text-xs text-base-content'>
                <Trans i18nKey={'price-card.note-for-premium'} />
              </p>
            </div>
          )}
        </div>
      </div>

      {/* VIP CAS Member */}
      <div className='relative flex-1 rounded-2xl  bg-gray-50 p-8'>
        <div className='flex flex-col justify-between gap-4'>
          <div>
            <p className='font-semibold'>
              <Trans i18nKey={'price-card.vip-member'} />
            </p>
            <p className='text-xs font-semibold text-gray-500'>
              <Trans i18nKey={'price-card.for-anyone'} />
            </p>
          </div>
          <div>
            <p className='text-3xl font-[900]'>
              {t('price-card.cas-price-variable', {
                price: 19,
              })}
            </p>
          </div>

          {currentPlan && (
            <div className='flex min-h-[4.5rem] flex-col gap-2'>
              <button
                className='btn btn-primary w-full'
                onClick={() => {
                  handleSubscribe([vipPlan.priceId]);
                }}
                disabled={currentPlan == 'VIP' || !currentPlan}
              >
                {vipPlan ? (
                  <>
                    {currentPlan == 'FREE' || currentPlan == 'PREMIUM'
                      ? 'Upgrade to VIP'
                      : currentPlan == 'VIP'
                      ? 'Current Plan'
                      : 'Unavailable'}
                  </>
                ) : (
                  <>
                    <span className='loading loading-spinner'></span>
                    Loading
                  </>
                )}
              </button>
              <p className='text-xs text-gray-500'>
                Opens on secure checkout page (Stripe)
              </p>
            </div>
          )}
        </div>
        <div className='mt-6 flex flex-col gap-1'>
          <div className='flex items-start gap-2 text-base-content'>
            <CheckIcon className='h-5 w-5 shrink-0' />
            <p className='text-sm'>
              {t('price-card.published-variable', {
                max: MAX_PUBLISHED_EXHIBITION_VIP,
              })}
            </p>
          </div>
          <div className='flex items-start gap-2 text-base-content'>
            <CheckIcon className='h-5 w-5 shrink-0' />
            <p className='text-sm'>
              <Trans i18nKey={'price-card.password-protect'} />
            </p>
          </div>
          <div className='flex items-start gap-2 text-base-content'>
            <CheckIcon className='h-5 w-5 shrink-0' />
            <p className='text-sm'>
              {t('price-card.create-save-variable', {
                max: MAX_EXHIBITION_VIP,
              })}
            </p>
          </div>
          <div className='flex items-start gap-2 text-base-content'>
            <CheckIcon className='h-5 w-5 shrink-0' />
            <p className='text-sm'>
              {t('price-card.upload-variable', {
                max: MAX_UPLOAD_VIP,
              })}
            </p>
          </div>

          <div className='text-base-content'>
            <div className='flex items-start gap-2 '>
              <CheckIcon className='h-5 w-5 shrink-0' />
              <p className='text-sm'>
                <Trans i18nKey={'price-card.choose-from'} />
              </p>
            </div>
            <ul className='list-inside list-disc pl-7 text-sm'>
              <li>
                {t('price-card.frame-with-custom-variable', {
                  max: MAX_FRAME_VIP,
                })}
              </li>
              <li>
                {t('price-card.wall-variable', {
                  max: MAX_WALL_VIP,
                })}
              </li>
              <li>
                {t('price-card.floor-variable', {
                  max: MAX_FLOOR_VIP,
                })}
              </li>
              <li>
                {t('price-card.ceiling-variable', {
                  max: MAX_CEILING_VIP,
                })}
              </li>
              <li>{t('price-card.unlimited-assets')}</li>
              <li>
                {t('price-card.music-variable', {
                  max: MAX_MUSIC_VIP,
                })}
              </li>
              <li>
                {t('price-card.silhouette-variable', {
                  max: MAX_SILHOUETTE_VIP,
                })}
              </li>
              <li>
                {t('price-card.video-variable', {
                  max: MAX_VIDEO_VIP,
                })}
              </li>
            </ul>
          </div>
          <div className='flex items-start gap-2 text-base-content'>
            <CheckIcon className='h-5 w-5 shrink-0' />
            <p className='text-sm'>
              <Trans i18nKey={'price-card.vip-analytics'} />
            </p>
          </div>
          {!user && (
            <div className='mt-6 min-h-[4.5rem] rounded-xl bg-white p-4'>
              <p className='text-xs text-base-content'>
                <Trans i18nKey={'price-card.note-for-premium'} />
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PriceCards;
