import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useTexture } from '@react-three/drei';
import * as THREE from 'three';
import { WALLS } from '../../../data/walls';
import { invalidate } from '@react-three/fiber';
import { publicRequest } from '../../../requestMethods';

const CustomWall = ({ id }) => {
  const [texturePaths, setTexturePaths] = useState({});
  const [wall, setWall] = useState(null);

  const getWallData = async (id) => {
    const { data } = await publicRequest.get(`/walls/${id}`);
    setWall(data);

    if (data) {
      const newTexturePaths = {};

      if (data.colorMap) newTexturePaths.map = data.colorMap;
      if (data.normalMap) newTexturePaths.normalMap = data.normalMap;
      if (data.roughnessMap) newTexturePaths.roughnessMap = data.roughnessMap;
      if (data.aoMap) newTexturePaths.aoMap = data.aoMap;
      if (data.displacementMap)
        newTexturePaths.displacementMap = data.displacementMap;
      if (data.metalnessMap) newTexturePaths.metalnessMap = data.metalnessMap;
      if (data.alphaMap) newTexturePaths.alphaMap = data.alphaMap;

      setTexturePaths(newTexturePaths);
    }
  };

  useEffect(() => {
    getWallData(id);
  }, [id]);

  const textureProps = useTexture(texturePaths);

  useLayoutEffect(() => {
    Object.values(textureProps).forEach((texture) => {
      texture.wrapS = THREE.RepeatWrapping;
      texture.wrapT = THREE.RepeatWrapping;
      texture.repeat.set(120, 120);
    });
  }, [textureProps]);

  const mesh = useRef();
  useEffect(() => {
    if (mesh.current) {
      mesh.current.geometry.setAttribute(
        'uv2',
        new THREE.BufferAttribute(mesh.current.geometry.attributes.uv.array, 2)
      );
    }
  }, [mesh.current, wall]);

  useEffect(() => {
    invalidate(); // idが変わるたびに再レンダリングを強制する
  }, [id]);

  if (!wall) {
    return null; // wallがまだロードされていないときは何も表示しない
  }

  return (
    <mesh
      key={`wall-${wall.title}`}
      receiveShadow
      ref={mesh}
      position={[0, 0, -0.5]}
    >
      <planeGeometry args={[200, 200]} />
      <meshStandardMaterial
        displacementScale={wall?.displacementScale ? wall.displacementScale : 0}
        aoMapIntensity={wall?.aoMapIntensity ? wall.aoMapIntensity : 0.5}
        roughness={wall?.roughness ? wall.roughness : 0.5}
        normalScale={
          wall?.normalScaleX && wall?.normalScaleY
            ? new THREE.Vector2(wall.normalScaleX, wall.normalScaleY)
            : new THREE.Vector2(1, 1)
        }
        metalness={wall?.settings?.metalness ? wall?.settings.metalness : 0.0}
        {...textureProps}
      />
    </mesh>
  );
};

export default CustomWall;
