import { useGLTF } from '@react-three/drei';
import { Suspense, useLayoutEffect, useState } from 'react';

const PictureFrame69 = ({ color, ...props }) => {
  const { scene, materials } = useGLTF(
    'https://data.curatedartshow.com/models/frames/koiframe1.glb'
  );

  const [geometry, setGeometry] = useState();

  if (!geometry) {
    const cloneScene = scene.clone(true);
    setGeometry(cloneScene);
  }

  useLayoutEffect(() => {
    // console.log(materials);
    materials['Crocodile Leather Satin_material.001'].color.set('#a30000');
    materials['Crocodile Leather Satin_material.001'].metalness = 0.5;
    materials['Crocodile Leather Satin_material.001'].roughness = 2;
    materials['Used Wheat Silk_material.001'].color.set('#c1b7a3');
    materials['Used Wheat Silk_material.001'].metalness = 1;
    materials['Used Wheat Silk_material.001'].roughness = 3;

    // materials['Matte Deep Blue Carbon Fiber_material.001'].color.set('#d2c7b0');
    materials['Matte Deep Blue Carbon Fiber_material.001'].metalness = 0.8;
    materials['Matte Deep Blue Carbon Fiber_material.001'].roughness = 3;

    // materials['default_material107.003'].color.set('#7c87cd');
    // materials['default_material107.003'].metalness = 1;
    // materials['default_material107.003'].roughness = 0;
    // materials['car_paint_material.001'].color.set('#7c87cd');
    materials['car_paint_material.001'].metalness = 1;
    materials['car_paint_material.001'].roughness = 0.1;

    geometry.traverse((obj) => {
      if (obj.isMesh) obj.castShadow = obj.receiveShadow = true;
    });
  }, []);

  return (
    <Suspense fallback={null}>
      <primitive object={geometry} {...props} />
    </Suspense>
  );
};

export default PictureFrame69;
