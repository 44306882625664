import { Clone, useGLTF } from '@react-three/drei';
import { useEffect, useLayoutEffect } from 'react';

const ClassicPillar2 = ({ ...props }) => {
  const { scene, materials, animations } = useGLTF(
    'https://data.curatedartshow.com/models/classicPillar2.glb'
  );

  useEffect(() => {
    return () => {
      //リソースのクリーンアップ処理
      useGLTF.clear(
        'https://data.curatedartshow.com/models/classicPillar2.glb'
      );
    };
  }, []);

  useLayoutEffect(() => {
    // console.log('pole:', materials);
    // materials['Street_Light_Mat'].color.set('#555');
    // materials['Glass'].color.set('#fff');
    //
    materials['Material.006'].roughness = 1;
    materials['Material.006'].metalness = 0;
    // materials['Wet Coarse Concrete_material'].metalness = 0.5;

    scene.traverse((obj) => {
      if (obj.isMesh) obj.castShadow = obj.receiveShadow = true;
    });
    return () => {
      // マテリアルの解放
      Object.values(materials).forEach((material) => {
        material.dispose();
      });

      // シーンからオブジェクトの削除
      scene.traverse((obj) => {
        if (obj.isMesh) {
          obj.geometry.dispose();
          obj.material.dispose();
          scene.remove(obj);
        }
      });
    };
  }, []);

  return (
    <>
      <Clone object={scene} {...props} castShadow />
    </>
  );
};

export default ClassicPillar2;
