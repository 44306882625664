import * as THREE from 'three';

export const FLOORS = [
  {
    id: 1,
    title: 'GRASS',
    thumbnail: `https://data.curatedartshow.com/texture/floors/floor1/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/floor1/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/floor1/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/floor1/roughness.jpg`,
      aoMap: `https://data.curatedartshow.com/texture/floors/floor1/ao.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/floor1/height.jpg`,
    },
    settings: {
      displacementScale: 0.5,
      aoMapIntensity: 2,
      roughness: 1,
    },
    isPro: true,
  },
  {
    id: 2,
    title: 'COBBLESTONE',
    thumbnail: `https://data.curatedartshow.com/texture/floors/floor2/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/floor2/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/floor2/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/floor2/roughness.jpg`,
      aoMap: `https://data.curatedartshow.com/texture/floors/floor2/ao.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/floor2/height.jpg`,
    },
    settings: {
      displacementScale: 0.5,
      aoMapIntensity: 2,
      roughness: 1,
    },
    isPro: true,
  },
  {
    id: 3,
    title: 'CRACKED MUD',
    thumbnail: `https://data.curatedartshow.com/texture/floors/floor3/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/floor3/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/floor3/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/floor3/roughness.jpg`,
      aoMap: `https://data.curatedartshow.com/texture/floors/floor3/ao.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/floor3/height.jpg`,
    },
    settings: {
      displacementScale: 0.5,
      aoMapIntensity: 2,
      roughness: 1,
    },
    isPro: true,
  },
  {
    id: 4,
    title: 'FAUX COBBLESTONE',
    thumbnail: `https://data.curatedartshow.com/texture/floors/floor4/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/floor4/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/floor4/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/floor4/roughness.jpg`,
      aoMap: `https://data.curatedartshow.com/texture/floors/floor4/ao.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/floor4/height.jpg`,
    },
    settings: {
      displacementScale: 0.5,
      aoMapIntensity: 2,
      roughness: 1,
      metalness: 1,
    },
    isPro: true,
  },
  {
    id: 5,
    title: 'FINE TEMPLE PLANKS',
    thumbnail: `https://data.curatedartshow.com/texture/floors/floor5/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/floor5/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/floor5/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/floor5/roughness.jpg`,
      aoMap: `https://data.curatedartshow.com/texture/floors/floor5/ao.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/floor5/height.jpg`,
    },
    settings: {
      displacementScale: 0.5,
      aoMapIntensity: 2,
      roughness: 1,
    },
    isPro: true,
  },
  {
    id: 6,
    title: 'FLOOR TILES',
    thumbnail: `https://data.curatedartshow.com/texture/floors/floor6/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/floor6/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/floor6/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/floor6/roughness.jpg`,
      aoMap: `https://data.curatedartshow.com/texture/floors/floor6/ao.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/floor6/height.jpg`,
    },
    settings: {
      displacementScale: 0.5,
      aoMapIntensity: 1.2,
      roughness: 1,
      metalness: 1,
    },
    isPro: true,
  },
  {
    id: 7,
    title: 'GROUND FOREST',
    thumbnail: `https://data.curatedartshow.com/texture/floors/floor7/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/floor7/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/floor7/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/floor7/roughness.jpg`,
      aoMap: `https://data.curatedartshow.com/texture/floors/floor7/ao.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/floor7/height.jpg`,
    },
    settings: {
      displacementScale: 0.5,
      aoMapIntensity: 2,
      roughness: 1,
    },
    isPro: true,
  },
  {
    id: 8,
    title: 'HERRINGBONE PAVEMENT',
    thumbnail: `https://data.curatedartshow.com/texture/floors/floor8/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/floor8/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/floor8/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/floor8/roughness.jpg`,
      aoMap: `https://data.curatedartshow.com/texture/floors/floor8/ao.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/floor8/height.jpg`,
    },
    settings: {
      displacementScale: 0.5,
      aoMapIntensity: 2,
      roughness: 1,
    },
    isPro: true,
  },
  {
    id: 9,
    title: 'MIXED COBBLESTONE PAVEMENT',
    thumbnail: `https://data.curatedartshow.com/texture/floors/floor9/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/floor9/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/floor9/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/floor9/roughness.jpg`,
      aoMap: `https://data.curatedartshow.com/texture/floors/floor9/ao.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/floor9/height.jpg`,
    },
    settings: {
      displacementScale: 0.5,
      aoMapIntensity: 2,
      roughness: 1,
    },
    isPro: true,
  },
  {
    id: 10,
    title: 'OAK FLOOR PANELS',
    thumbnail: `https://data.curatedartshow.com/texture/floors/floor10/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/floor10/basecolor.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/floor10/roughness.jpg`,
      aoMap: `https://data.curatedartshow.com/texture/floors/floor10/ao.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/floor10/height.jpg`,
    },
    settings: {
      displacementScale: 0.5,
      aoMapIntensity: 0,
      roughness: 0.4,
      normalScale: new THREE.Vector2(0, 0),
    },
    isPro: false,
  },
  {
    id: 11,
    title: 'SNOW WITH FOOTSTEPS',
    thumbnail: `https://data.curatedartshow.com/texture/floors/floor11/thumbnail.png`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/floor11/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/floor11/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/floor11/roughness.jpg`,
      aoMap: `https://data.curatedartshow.com/texture/floors/floor11/ao.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/floor11/height.jpg`,
    },
    settings: {
      displacementScale: 0.5,
      aoMapIntensity: 2,
      roughness: 0.8,
      metalness: 0.5,
      normalScale: new THREE.Vector2(7, 1),
    },
    isPro: true,
  },
  {
    id: 12,
    title: 'WEATHERED CONCRETE FLOOR',
    thumbnail: `https://data.curatedartshow.com/texture/floors/floor12/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/floor12/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/floor12/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/floor12/roughness.jpg`,
      aoMap: `https://data.curatedartshow.com/texture/floors/floor12/ao.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/floor12/height.jpg`,
    },
    settings: {
      displacementScale: 0.5,
      aoMapIntensity: 2,
      roughness: 1,
    },
    isPro: true,
  },
  {
    id: 13,
    title: 'DRY DESERT SAND',
    thumbnail: `https://data.curatedartshow.com/texture/floors/DryDesertSand/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/DryDesertSand/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/DryDesertSand/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/DryDesertSand/roughness.jpg`,
      aoMap: `https://data.curatedartshow.com/texture/floors/DryDesertSand/ao.jpg`,
    },
    settings: {
      displacementScale: 0.5,
      aoMapIntensity: 2,
      roughness: 1,
      normalScale: new THREE.Vector2(3, 1),
    },
    isPro: true,
  },
  {
    id: 14,
    title: 'FOREST CLOVERS',
    thumbnail: `https://data.curatedartshow.com/texture/walls/ForestClovers/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/walls/ForestClovers/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/walls/ForestClovers/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/walls/ForestClovers/roughness.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/walls/ForestClovers/height.jpg`,
    },
    settings: {
      displacementScale: 0.5,
      aoMapIntensity: 2,
      roughness: 1,
    },
    isPro: true,
  },
  {
    id: 15,
    title: 'VINYL CHECKBOARD FLOOR',
    thumbnail: `https://data.curatedartshow.com/texture/floors/VinylCheckerboardFloor/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/VinylCheckerboardFloor/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/VinylCheckerboardFloor/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/VinylCheckerboardFloor/roughness.jpg`,
      aoMap: `https://data.curatedartshow.com/texture/floors/VinylCheckerboardFloor/ao.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/VinylCheckerboardFloor/height.jpg`,
      metalnessMap: `https://data.curatedartshow.com/texture/floors/VinylCheckerboardFloor/metallic.jpg`,
    },
    settings: {
      displacementScale: 0.5,
      aoMapIntensity: 2,
      roughness: 0.6,
    },
    isPro: true,
  },
  {
    id: 16,
    title: 'WHITE MARBLE SQUARE TILES',
    thumbnail: `https://data.curatedartshow.com/texture/floors/WhiteMarbleSquareTiles/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/WhiteMarbleSquareTiles/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/WhiteMarbleSquareTiles/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/WhiteMarbleSquareTiles/roughness.jpg`,
    },
    settings: {
      displacementScale: 0.5,
      aoMapIntensity: 2,
      roughness: 0.6,
      normalScale: new THREE.Vector2(5, 1),
    },
    isPro: false,
  },
  {
    id: 17,
    title: 'PORTUGUESE TILES',
    thumbnail: `https://data.curatedartshow.com/texture/ceilings/Portuguese_Tiles/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/ceilings/Portuguese_Tiles/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/ceilings/Portuguese_Tiles/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/ceilings/Portuguese_Tiles/roughness.jpg`,
      aoMap: `https://data.curatedartshow.com/texture/ceilings/Portuguese_Tiles/ao.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/ceilings/Portuguese_Tiles/height.jpg`,
    },
    settings: {
      displacementScale: 0.5,
      aoMapIntensity: 2,
      roughness: 0.6,
      normalScale: new THREE.Vector2(5, 1),
    },
    isPro: true,
  },
  {
    id: 18,
    title: 'PATTERNED TILES 4',
    thumbnail: `https://data.curatedartshow.com/texture/walls/PatternedTiles4/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/walls/PatternedTiles4/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/walls/PatternedTiles4/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/walls/PatternedTiles4/roughness.jpg`,
      aoMap: `https://data.curatedartshow.com/texture/walls/PatternedTiles4/ao.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/walls/PatternedTiles4/height.jpg`,
      metalnessMap: `https://data.curatedartshow.com/texture/walls/PatternedTiles4/metallic.jpg`,
    },
    settings: {
      displacementScale: 0.5,
      aoMapIntensity: 2,
      roughness: 0.6,
      normalScale: new THREE.Vector2(5, 1),
    },
    isPro: true,
  },
  {
    id: 19,
    title: 'PATTERNED TILES 19',
    thumbnail: `https://data.curatedartshow.com/texture/walls/PatternedTiles19/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/walls/PatternedTiles19/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/walls/PatternedTiles19/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/walls/PatternedTiles19/roughness.jpg`,
      aoMap: `https://data.curatedartshow.com/texture/walls/PatternedTiles19/ao.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/walls/PatternedTiles19/height.jpg`,
      metalnessMap: `https://data.curatedartshow.com/texture/walls/PatternedTiles19/metallic.jpg`,
    },
    settings: {
      displacementScale: 0.5,
      aoMapIntensity: 2,
      roughness: 0.6,
      normalScale: new THREE.Vector2(5, 1),
    },
    isPro: true,
  },
  {
    id: 20,
    title: 'Travertine',
    thumbnail: `https://data.curatedartshow.com/texture/floors/Travertine005/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/Travertine005/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/Travertine005/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/Travertine005/roughness.jpg`,
      aoMap: `https://data.curatedartshow.com/texture/floors/Travertine005/ao.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/Travertine005/height.jpg`,
    },
    settings: {
      displacementScale: 0.5,
      aoMapIntensity: 2,
      roughness: 0.6,
    },
    isPro: false,
  },
  {
    id: 21,
    title: 'Moss',
    thumbnail: `https://data.curatedartshow.com/texture/floors/Moss002/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/Moss002/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/Moss002/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/Moss002/roughness.jpg`,
      aoMap: `https://data.curatedartshow.com/texture/floors/Moss002/ao.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/Moss002/height.jpg`,
    },
    settings: {
      displacementScale: 0.5,
      aoMapIntensity: 2.5,
      roughness: 0.6,
    },
    isPro: true,
  },
  {
    id: 22,
    title: 'Wood 1',
    thumbnail: `https://data.curatedartshow.com/texture/floors/Wood007/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/Wood007/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/Wood007/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/Wood007/roughness.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/Wood007/height.jpg`,
    },
    settings: {
      displacementScale: 0.5,
      aoMapIntensity: 2,
      roughness: 0.6,
    },
    isPro: false,
  },
  {
    id: 23,
    title: 'Wood 2',
    thumbnail: `https://data.curatedartshow.com/texture/floors/Wood008/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/Wood008/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/Wood008/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/Wood008/roughness.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/Wood008/height.jpg`,
    },
    settings: {
      displacementScale: 0.5,
      aoMapIntensity: 2,
      roughness: 0.6,
    },
    isPro: false,
  },
  {
    id: 24,
    title: 'Wood 3',
    thumbnail: `https://data.curatedartshow.com/texture/floors/Wood016/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/Wood016/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/Wood016/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/Wood016/roughness.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/Wood016/height.jpg`,
    },
    settings: {
      displacementScale: 0,
      aoMapIntensity: 2,
      roughness: 0.6,
    },
    isPro: false,
  },
  {
    id: 25,
    title: 'Wood 4',
    thumbnail: `https://data.curatedartshow.com/texture/floors/Wood017/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/Wood017/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/Wood017/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/Wood017/roughness.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/Wood017/height.jpg`,
    },
    settings: {
      displacementScale: 0,
      aoMapIntensity: 2,
      roughness: 0.6,
    },
    isPro: false,
  },
  {
    id: 26,
    title: 'Wood 5',
    thumbnail: `https://data.curatedartshow.com/texture/floors/Wood023/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/Wood023/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/Wood023/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/Wood023/roughness.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/Wood023/height.jpg`,
    },
    settings: {
      displacementScale: 0.5,
      aoMapIntensity: 2,
      roughness: 0.6,
    },
    isPro: false,
  },
  {
    id: 27,
    title: 'Wood 6',
    thumbnail: `https://data.curatedartshow.com/texture/floors/Wood025/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/Wood025/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/Wood025/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/Wood025/roughness.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/Wood025/height.jpg`,
    },
    settings: {
      displacementScale: 0.5,
      aoMapIntensity: 2,
      roughness: 0.6,
    },
    isPro: false,
  },
  {
    id: 28,
    title: 'Wood 7',
    thumbnail: `https://data.curatedartshow.com/texture/floors/Wood035/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/Wood035/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/Wood035/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/Wood035/roughness.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/Wood035/height.jpg`,
    },
    settings: {
      displacementScale: 0.5,
      aoMapIntensity: 2,
      roughness: 0.6,
    },
    isPro: false,
  },
  {
    id: 29,
    title: 'Wood 8',
    thumbnail: `https://data.curatedartshow.com/texture/floors/Wood037/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/Wood037/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/Wood037/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/Wood037/roughness.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/Wood037/height.jpg`,
    },
    settings: {
      displacementScale: 0.5,
      aoMapIntensity: 2,
      roughness: 0.6,
    },
    isPro: false,
  },
  {
    id: 30,
    title: 'Wood 9',
    thumbnail: `https://data.curatedartshow.com/texture/floors/Wood038/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/Wood038/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/Wood038/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/Wood038/roughness.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/Wood038/height.jpg`,
    },
    settings: {
      displacementScale: 0.5,
      aoMapIntensity: 2,
      roughness: 0.6,
    },
    isPro: false,
  },
  {
    id: 31,
    title: 'Wood 10',
    thumbnail: `https://data.curatedartshow.com/texture/floors/Wood042/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/Wood042/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/Wood042/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/Wood042/roughness.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/Wood042/height.jpg`,
    },
    settings: {
      displacementScale: 0.5,
      aoMapIntensity: 2,
      roughness: 0.6,
    },
    isPro: false,
  },
  {
    id: 32,
    title: 'Wood 11',
    thumbnail: `https://data.curatedartshow.com/texture/floors/Wood043/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/Wood043/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/Wood043/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/Wood043/roughness.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/Wood043/height.jpg`,
    },
    settings: {
      displacementScale: 0.5,
      aoMapIntensity: 2,
      roughness: 0.6,
    },
    isPro: false,
  },
  {
    id: 33,
    title: 'Wood 12',
    thumbnail: `https://data.curatedartshow.com/texture/floors/Wood058/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/Wood058/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/Wood058/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/Wood058/roughness.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/Wood058/height.jpg`,
    },
    settings: {
      displacementScale: 0.5,
      aoMapIntensity: 2,
      roughness: 0.6,
    },
    isPro: false,
  },
  {
    id: 34,
    title: 'Wood 13',
    thumbnail: `https://data.curatedartshow.com/texture/floors/Wood084A/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/Wood084A/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/Wood084A/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/Wood084A/roughness.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/Wood084A/height.jpg`,
    },
    settings: {
      displacementScale: 0,
      aoMapIntensity: 2,
      roughness: 0.6,
    },
    isPro: false,
  },
  {
    id: 35,
    title: 'Leather 1',
    thumbnail: `https://data.curatedartshow.com/texture/floors/Leather016/thumbnail.png`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/Leather016/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/Leather016/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/Leather016/roughness.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/Leather016/height.jpg`,
      aoMap: `https://data.curatedartshow.com/texture/floors/Leather016/ao.jpg`,
      alphaMap: `https://data.curatedartshow.com/texture/floors/Leather016/alpha.jpg`,
    },
    settings: {
      displacementScale: 0,
      aoMapIntensity: 2.5,

      roughness: 1,
      metalness: 0.4,
      normalScale: new THREE.Vector2(1, 0.5),
    },
    isPro: false,
  },
  {
    id: 36,
    title: 'Leather 2',
    thumbnail: `https://data.curatedartshow.com/texture/floors/Leather020/thumbnail.png`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/Leather020/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/Leather020/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/Leather020/roughness.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/Leather020/height.jpg`,
      aoMap: `https://data.curatedartshow.com/texture/floors/Leather020/ao.jpg`,
      alphaMap: `https://data.curatedartshow.com/texture/floors/Leather020/alpha.jpg`,
    },
    settings: {
      displacementScale: 0,
      aoMapIntensity: 2.5,

      roughness: 1,
      metalness: 0.4,
      normalScale: new THREE.Vector2(1, 0.5),
    },
    isPro: false,
  },
  {
    id: 37,
    title: 'Leather 3',
    thumbnail: `https://data.curatedartshow.com/texture/floors/Leather021/thumbnail.png`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/Leather021/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/Leather021/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/Leather021/roughness.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/Leather021/height.jpg`,
    },
    settings: {
      displacementScale: 0,
      aoMapIntensity: 2,
      roughness: 1,
      metalness: 0.4,
      normalScale: new THREE.Vector2(1, 0.5),
    },
    isPro: true,
  },
  {
    id: 38,
    title: 'Leather 4',
    thumbnail: `https://data.curatedartshow.com/texture/floors/Leather024/thumbnail.png`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/Leather024/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/Leather024/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/Leather024/roughness.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/Leather024/height.jpg`,
    },
    settings: {
      displacementScale: 0,
      aoMapIntensity: 2,
      roughness: 1,
      metalness: 0.4,
      normalScale: new THREE.Vector2(1, 0.5),
    },
    isPro: true,
  },
  {
    id: 39,
    title: 'Leather 5',
    thumbnail: `https://data.curatedartshow.com/texture/floors/Leather026/thumbnail.png`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/Leather026/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/Leather026/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/Leather026/roughness.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/Leather026/height.jpg`,
    },
    settings: {
      displacementScale: 0,
      aoMapIntensity: 2,
      roughness: 1,
      metalness: 0.4,
      normalScale: new THREE.Vector2(1, 0.5),
    },
    isPro: true,
  },
  {
    id: 40,
    title: 'Fabric 1',
    thumbnail: `https://data.curatedartshow.com/texture/floors/Fabric020/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/Fabric020/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/Fabric020/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/Fabric020/roughness.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/Fabric020/height.jpg`,
      alphaMap: `https://data.curatedartshow.com/texture/floors/Fabric020/alpha.jpg`,
    },
    settings: {
      displacementScale: 0.5,
      aoMapIntensity: 2,
      roughness: 1,
    },
    isPro: false,
  },
  {
    id: 41,
    title: 'Fabric 2',
    thumbnail: `https://data.curatedartshow.com/texture/floors/Fabric022/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/Fabric022/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/Fabric022/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/Fabric022/roughness.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/Fabric022/height.jpg`,
    },
    settings: {
      displacementScale: 0.5,
      aoMapIntensity: 2,
      roughness: 1,
    },
    isPro: false,
  },
  {
    id: 42,
    title: 'Fabric 3',
    thumbnail: `https://data.curatedartshow.com/texture/floors/Fabric026/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/Fabric026/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/Fabric026/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/Fabric026/roughness.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/Fabric026/height.jpg`,
    },
    settings: {
      displacementScale: 0.5,
      aoMapIntensity: 2,
      roughness: 1,
    },
    isPro: false,
  },
  {
    id: 43,
    title: 'Fabric 4',
    thumbnail: `https://data.curatedartshow.com/texture/floors/Fabric060/thumbnail.png`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/Fabric060/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/Fabric060/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/Fabric060/roughness.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/Fabric060/height.jpg`,
      aoMap: `https://data.curatedartshow.com/texture/floors/Fabric060/ao.jpg`,
    },
    settings: {
      displacementScale: 0.5,
      aoMapIntensity: 3,
      roughness: 0.6,
      normalScale: new THREE.Vector2(2, 1),
      metalness: 0.9,
    },
    isPro: false,
  },
  {
    id: 44,
    title: 'Marble 1',
    thumbnail: `https://data.curatedartshow.com/texture/floors/Marble006/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/Marble006/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/Marble006/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/Marble006/roughness.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/Marble006/height.jpg`,
    },
    settings: {
      displacementScale: 0,
      aoMapIntensity: 2,
      roughness: 0.5,
      metalness: 0.1,
    },
    isPro: false,
  },
  {
    id: 45,
    title: 'Marble 2',
    thumbnail: `https://data.curatedartshow.com/texture/floors/Marble008/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/Marble008/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/Marble008/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/Marble008/roughness.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/Marble008/height.jpg`,
    },
    settings: {
      displacementScale: 0.5,
      aoMapIntensity: 2,
      roughness: 0.5,
      metalness: 0.1,
    },
    isPro: false,
  },
  {
    id: 46,
    title: 'Marble 3',
    thumbnail: `https://data.curatedartshow.com/texture/floors/Marble009/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/Marble009/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/Marble009/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/Marble009/roughness.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/Marble009/height.jpg`,
    },
    settings: {
      displacementScale: 0,
      aoMapIntensity: 2,
      roughness: 0.5,
      metalness: 0.1,
    },
    isPro: true,
  },
  {
    id: 47,
    title: 'Marble 4',
    thumbnail: `https://data.curatedartshow.com/texture/floors/Marble010/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/Marble010/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/Marble010/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/Marble010/roughness.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/Marble010/height.jpg`,
    },
    settings: {
      displacementScale: 0,
      aoMapIntensity: 2,
      roughness: 0.5,
      metalness: 0.1,
      normalScale: new THREE.Vector2(3, 1),
    },
    isPro: true,
  },
  {
    id: 48,
    title: 'Marble 5',
    thumbnail: `https://data.curatedartshow.com/texture/floors/Marble022/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/Marble022/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/Marble022/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/Marble022/roughness.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/Marble022/height.jpg`,
    },
    settings: {
      displacementScale: 0,
      aoMapIntensity: 2,
      roughness: 0.5,
      metalness: 0.1,
    },
    isPro: true,
  },
  {
    id: 49,
    title: 'Metal 1',
    thumbnail: `https://data.curatedartshow.com/texture/floors/Metal011/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/Metal011/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/Metal011/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/Metal011/roughness.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/Metal011/height.jpg`,
      metalnessMap: `https://data.curatedartshow.com/texture/floors/Metal011/metalness.jpg`,
    },
    settings: {
      displacementScale: 0.5,
      aoMapIntensity: 2,
      roughness: 1,
      metalness: 0.6,
      normalScale: new THREE.Vector2(1, 3),
    },
    isPro: false,
  },
  {
    id: 50,
    title: 'Metal 2',
    thumbnail: `https://data.curatedartshow.com/texture/floors/Metal015/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/Metal015/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/Metal015/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/Metal015/roughness.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/Metal015/height.jpg`,
      metalnessMap: `https://data.curatedartshow.com/texture/floors/Metal015/metalness.jpg`,
    },
    settings: {
      displacementScale: 0.5,
      aoMapIntensity: 2,
      roughness: 1,
      metalness: 0.7,
      normalScale: new THREE.Vector2(1, 3),
    },
    isPro: false,
  },
  {
    id: 51,
    title: 'Metal 3',
    thumbnail: `https://data.curatedartshow.com/texture/floors/Metal028/thumbnail.png`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/Metal028/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/Metal028/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/Metal028/roughness.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/Metal028/height.jpg`,
      metalnessMap: `https://data.curatedartshow.com/texture/floors/Metal028/metalness.jpg`,
    },
    settings: {
      displacementScale: 0.5,
      aoMapIntensity: 2,
      roughness: 1,
      metalness: 0.5,
      normalScale: new THREE.Vector2(1, 3),
    },
    isPro: true,
  },
  {
    id: 52,
    title: 'Metal 4',
    thumbnail: `https://data.curatedartshow.com/texture/floors/Metal032/thumbnail.png`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/Metal032/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/Metal032/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/Metal032/roughness.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/Metal032/height.jpg`,
      metalnessMap: `https://data.curatedartshow.com/texture/floors/Metal032/metalness.jpg`,
    },
    settings: {
      displacementScale: 0,
      aoMapIntensity: 2,
      roughness: 1,
      metalness: 0.5,
      normalScale: new THREE.Vector2(1, 3),
    },
    isPro: true,
  },
  {
    id: 53,
    title: 'Metal 5',
    thumbnail: `https://data.curatedartshow.com/texture/floors/Metal035/thumbnail.png`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/Metal035/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/Metal035/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/Metal035/roughness.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/Metal035/height.jpg`,
      metalnessMap: `https://data.curatedartshow.com/texture/floors/Metal035/metalness.jpg`,
    },
    settings: {
      displacementScale: 0,
      aoMapIntensity: 2,
      roughness: 0.5,
      metalness: 0.5,
      normalScale: new THREE.Vector2(1, 3),
    },
    isPro: true,
  },
  {
    id: 54,
    title: 'Metal 6',
    thumbnail: `https://data.curatedartshow.com/texture/floors/Metal036/thumbnail.png`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/Metal036/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/Metal036/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/Metal036/roughness.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/Metal036/height.jpg`,
      metalnessMap: `https://data.curatedartshow.com/texture/floors/Metal036/metalness.jpg`,
    },
    settings: {
      displacementScale: 0,
      aoMapIntensity: 2,
      roughness: 0.5,
      metalness: 0.7,
      normalScale: new THREE.Vector2(1, 3),
    },
    isPro: true,
  },
  {
    id: 55,
    title: 'Metal 7',
    thumbnail: `https://data.curatedartshow.com/texture/floors/Metal039/thumbnail.png`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/Metal039/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/Metal039/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/Metal039/roughness.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/Metal039/height.jpg`,
      metalnessMap: `https://data.curatedartshow.com/texture/floors/Metal039/metalness.jpg`,
    },
    settings: {
      displacementScale: 0.5,
      aoMapIntensity: 2,
      roughness: 0.6,
      metalness: 0.7,
      normalScale: new THREE.Vector2(1, 3),
    },
    isPro: true,
  },
  {
    id: 56,
    title: 'Wood 14',
    thumbnail: `https://data.curatedartshow.com/texture/floors/WoodFloor005/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/WoodFloor005/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/WoodFloor005/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/WoodFloor005/roughness.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/WoodFloor005/height.jpg`,
    },
    settings: {
      displacementScale: 0.5,
      aoMapIntensity: 2,
      roughness: 1,
    },
    isPro: false,
  },
  {
    id: 57,
    title: 'Wood 15',
    thumbnail: `https://data.curatedartshow.com/texture/floors/WoodFloor013/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/WoodFloor013/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/WoodFloor013/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/WoodFloor013/roughness.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/WoodFloor013/height.jpg`,
      aoMap: `https://data.curatedartshow.com/texture/floors/WoodFloor013/ao.jpg`,
    },
    settings: {
      displacementScale: 0.5,
      aoMapIntensity: 2,
      roughness: 1,
    },
    isPro: false,
  },
  {
    id: 58,
    title: 'Wood 16',
    thumbnail: `https://data.curatedartshow.com/texture/floors/WoodFloor014/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/WoodFloor014/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/WoodFloor014/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/WoodFloor014/roughness.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/WoodFloor014/height.jpg`,
      aoMap: `https://data.curatedartshow.com/texture/floors/WoodFloor014/ao.jpg`,
    },
    settings: {
      displacementScale: 0.5,
      aoMapIntensity: 2,
      roughness: 1,
    },
    isPro: false,
  },
  {
    id: 59,
    title: 'Wood 17',
    thumbnail: `https://data.curatedartshow.com/texture/floors/WoodFloor019/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/WoodFloor019/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/WoodFloor019/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/WoodFloor019/roughness.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/WoodFloor019/height.jpg`,
      aoMap: `https://data.curatedartshow.com/texture/floors/WoodFloor019/ao.jpg`,
    },
    settings: {
      displacementScale: 0.5,
      aoMapIntensity: 2,
      roughness: 1,
    },
    isPro: false,
  },
  {
    id: 60,
    title: 'Wood 18',
    thumbnail: `https://data.curatedartshow.com/texture/floors/WoodFloor020/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/WoodFloor020/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/WoodFloor020/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/WoodFloor020/roughness.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/WoodFloor020/height.jpg`,
      aoMap: `https://data.curatedartshow.com/texture/floors/WoodFloor020/ao.jpg`,
    },
    settings: {
      displacementScale: 0.5,
      aoMapIntensity: 2,
      roughness: 1,
      normalScale: new THREE.Vector2(2, 1),
      metalness: 0.5,
    },
    isPro: true,
  },
  {
    id: 61,
    title: 'Wood 19',
    thumbnail: `https://data.curatedartshow.com/texture/floors/WoodFloor028/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/WoodFloor028/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/WoodFloor028/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/WoodFloor028/roughness.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/WoodFloor028/height.jpg`,
      aoMap: `https://data.curatedartshow.com/texture/floors/WoodFloor028/ao.jpg`,
    },
    settings: {
      displacementScale: 0.5,
      aoMapIntensity: 2,
      roughness: 1,
      normalScale: new THREE.Vector2(2, 1),
      metalness: 0.5,
    },
    isPro: true,
  },
  {
    id: 62,
    title: 'Wood 20',
    thumbnail: `https://data.curatedartshow.com/texture/floors/WoodFloor034/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/WoodFloor034/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/WoodFloor034/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/WoodFloor034/roughness.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/WoodFloor034/height.jpg`,
      aoMap: `https://data.curatedartshow.com/texture/floors/WoodFloor034/ao.jpg`,
    },
    settings: {
      displacementScale: 0.5,
      aoMapIntensity: 2,
      roughness: 1,
    },
    isPro: true,
  },
  {
    id: 63,
    title: 'Wood 21',
    thumbnail: `https://data.curatedartshow.com/texture/floors/WoodFloor036/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/WoodFloor036/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/WoodFloor036/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/WoodFloor036/roughness.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/WoodFloor036/height.jpg`,
      aoMap: `https://data.curatedartshow.com/texture/floors/WoodFloor036/ao.jpg`,
    },
    settings: {
      displacementScale: 0.5,
      aoMapIntensity: 3,
      roughness: 0.5,
      normalScale: new THREE.Vector2(5, 1),
      metalness: 0.5,
    },
    isPro: true,
  },
  {
    id: 64,
    title: 'Bricks 1',
    thumbnail: `https://data.curatedartshow.com/texture/floors/Bricks004/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/Bricks004/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/Bricks004/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/Bricks004/roughness.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/Bricks004/height.jpg`,
      aoMap: `https://data.curatedartshow.com/texture/floors/Bricks004/ao.jpg`,
    },
    settings: {
      displacementScale: 0.5,
      aoMapIntensity: 2.5,
      roughness: 1,
      normalScale: new THREE.Vector2(2, 1),
      metalness: 0.5,
    },
    isPro: true,
  },
  {
    id: 65,
    title: 'Bricks 2',
    thumbnail: `https://data.curatedartshow.com/texture/floors/Bricks005/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/Bricks005/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/Bricks005/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/Bricks005/roughness.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/Bricks005/height.jpg`,
    },
    settings: {
      displacementScale: 0.5,
      aoMapIntensity: 2.5,
      roughness: 0.5,
      normalScale: new THREE.Vector2(2, 1),
    },
    isPro: true,
  },
  {
    id: 66,
    title: 'Concrete 1',
    thumbnail: `https://data.curatedartshow.com/texture/floors/Concrete004/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/Concrete004/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/Concrete004/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/Concrete004/roughness.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/Concrete004/height.jpg`,
      aoMap: `https://data.curatedartshow.com/texture/floors/Concrete004/ao.jpg`,
    },
    settings: {
      displacementScale: 0.5,
      aoMapIntensity: 1.2,
      roughness: 0.7,
      normalScale: new THREE.Vector2(6, 1),
    },
    isPro: true,
  },
  {
    id: 67,
    title: 'Concrete 2',
    thumbnail: `https://data.curatedartshow.com/texture/floors/Concrete020/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/Concrete020/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/Concrete020/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/Concrete020/roughness.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/Concrete020/height.jpg`,
    },
    settings: {
      displacementScale: 0.5,
      aoMapIntensity: 1.5,
      roughness: 0.5,
      normalScale: new THREE.Vector2(2, 1),
    },
    isPro: true,
  },
];
