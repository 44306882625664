import { Image } from '@react-three/drei';
import { useLoader } from '@react-three/fiber';
import React, { useEffect, useState } from 'react';
import { TextureLoader } from 'three';
import { BACKGROUNDS } from '../../../data/backgrounds';
import { getBackgroundById } from '../../../services/Background';

const CustomBackground = ({ id }) => {
  const [background, setBackground] = useState(null);
  const [imageMap, setImageMap] = useState(null);

  const getBackgroundData = async (id) => {
    const data = await getBackgroundById(id);

    //local debug用
    // const data = BACKGROUNDS.find((background) => background.id === id) || null;

    setBackground(data);
  };

  useEffect(() => {
    getBackgroundData(id);
  }, [id]);

  useEffect(() => {
    if (background?.image) {
      const loader = new TextureLoader();
      loader.load(background.image, (texture) => {
        setImageMap(texture);
      });
    }
  }, [background]);

  // 読み込み中のチェック
  if (!background || !imageMap) return null;

  const aspectRatio =
    imageMap.source?.data?.width / imageMap.source?.data?.height || 1;

  return (
    <Image
      position={[background.positionX, background.positionY, -20]}
      url={background?.image}
      scale={[background.scaleX, background.scaleY / aspectRatio, 1]}
      toneMapped={false}
      color={background?.color}
      // grayscale={0}
    />
  );
};

export default CustomBackground;
