import React from 'react';

const SkeletonItemCard = () => {
  return (
    <div className='animate-pulse'>
      <div className='mb-4 mr-10 aspect-[3/2] w-full flex-shrink-0 rounded-lg bg-slate-200' />
      <div className='flex justify-between'>
        <div>
          <div className='mb-2  h-2.5 w-32  rounded-full bg-slate-200' />
          <div className='mb-2.5 h-2 w-16 rounded-full  bg-slate-200' />
        </div>
        <div className='flex flex-col items-end'>
          <div className='mb-2  h-2.5 w-20  rounded-full bg-slate-200' />
          <div className='mb-2.5 h-2 w-16 rounded-full  bg-slate-200' />
        </div>
      </div>
    </div>
  );
};

export default SkeletonItemCard;
