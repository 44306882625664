import { TrashIcon } from '@heroicons/react/24/solid';
import { useEffect, useState } from 'react';
import DeletePresetModal from './DeletePresetModal';
import { authRequest, publicRequest } from '../requestMethods';
import { useDispatch, useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import { successToast } from '../utils/toast';
import { loadEnd, loadStart } from '../redux/loadRedux';

const MyPresetsModal = ({ loadPreset }) => {
  const [selectedPreset, setSelectedPreset] = useState(null);
  const [presets, setPresets] = useState([]);
  const [deletePreset, setDeletePreset] = useState(null);
  const user = useSelector((state) => state.user.currentUser);
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();

  const getPresets = async () => {
    try {
      const res = await publicRequest.get(`/presets/users/${user?.id}`);
      setPresets(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  const handleDeletePreset = async (id) => {
    dispatch(loadStart());
    try {
      await authRequest.patch(`/presets/inactive/${Number(id)}`);
      getPresets();
      successToast(t('message.success.t19'));
      document.getElementById('delete_preset_modal').close();
      // Preset deleted successfully!
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(loadEnd());
    }
  };

  useEffect(() => {
    const modal = document.getElementById('my_presets_modal');

    // モーダルの属性を監視
    const observer = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (
          mutation.type === 'attributes' &&
          mutation.attributeName === 'open'
        ) {
          if (modal.hasAttribute('open')) {
            getPresets();
          }
        }
      }
    });

    // 監視の開始
    observer.observe(modal, { attributes: true });

    // クリーンアップ関数で監視を停止
    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <dialog id='my_presets_modal' className='modal'>
      <div className='modal-box max-h-[70vh] !max-w-[38rem] !p-6'>
        <h3 className='mb-6 mt-0 text-lg font-bold'>
          <Trans i18nKey={'my-presets-modal.ttl'} />
        </h3>
        <p className='my-0 pb-6'>
          <Trans i18nKey={'my-presets-modal.desc'} />
        </p>

        <div className='overflow-x-auto'>
          <table className='table'>
            <tbody>
              {presets?.map((preset, index) => (
                <tr
                  key={`private-preset-${index}`}
                  className={`cursor-pointer ${
                    presets.length != index + 1 && '!border-b-2'
                  }`}
                  onClick={() => setSelectedPreset(preset)}
                >
                  <th>
                    <label>
                      <input
                        type='radio'
                        name={`preset-radio`}
                        className='radio'
                        onChange={() => setSelectedPreset(preset)}
                        id={preset?.id}
                        value={preset?.id}
                        checked={preset?.id === selectedPreset?.id}
                      />
                    </label>
                  </th>
                  <td>
                    <img
                      src={preset?.imageUrl + '?q=60'}
                      alt='Avatar Tailwind CSS Component'
                      className='h-20 w-28 object-cover'
                    />
                  </td>
                  <td className='text-sm font-bold'>{preset?.name}</td>
                  <th>
                    <button
                      className='flex h-8 items-center gap-2 rounded-lg bg-base-200 px-3 font-[600] hover:opacity-70'
                      onClick={(e) => {
                        e.stopPropagation();
                        setDeletePreset(preset);
                        document
                          .getElementById('delete_preset_modal')
                          .showModal();
                      }}
                    >
                      <TrashIcon className='h-5 w-5' />
                      <Trans i18nKey={'btn.delete'} />
                    </button>
                  </th>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className='modal-action mb-0 mt-6'>
          <form method='dialog'>
            <button className='btn mr-2'>
              <Trans i18nKey={'btn.cancel'} />
            </button>
            <button
              className='btn btn-primary'
              disabled={!selectedPreset}
              onClick={() => loadPreset(selectedPreset)}
            >
              <Trans i18nKey={'btn.save'} />
            </button>
          </form>
        </div>
      </div>
      <DeletePresetModal
        handleDeletePreset={() => handleDeletePreset(deletePreset?.id)}
      />
    </dialog>
  );
};

export default MyPresetsModal;
