import { useSelector } from 'react-redux';
import LoadingSpinner from './LoadingSpinner';
import { Trans } from 'react-i18next';

const DeletePresetModal = ({ handleDeletePreset }) => {
  const { isLoading } = useSelector((state) => state.isLoading);

  return (
    <dialog id='delete_preset_modal' className='modal'>
      <div className='modal-box !max-w-[32rem] !p-6'>
        <h3 className='mb-6 mt-0 text-lg font-bold'>
          <Trans i18nKey={'delete-preset-modal.ttl'} />
        </h3>
        <p className='my-0 pb-6'>
          <Trans i18nKey={'delete-preset-modal.desc'} />
        </p>
        <div className='modal-action mb-0 mt-6'>
          <form method='dialog'>
            <button className='btn mr-2'>
              <Trans i18nKey={'btn.cancel'} />
            </button>
            <button
              className='btn !bg-[#FF5663] hover:!bg-[#FF5663] hover:opacity-70'
              onClick={handleDeletePreset}
            >
              <Trans i18nKey={'btn.delete'} />
            </button>
          </form>
        </div>
      </div>
      {isLoading && <LoadingSpinner />}
    </dialog>
  );
};

export default DeletePresetModal;
