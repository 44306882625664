import {
  EyeSlashIcon,
  PencilSquareIcon,
  ArrowRightIcon,
  EyeIcon,
} from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';

const RoomCardWithMenu = ({ room, togglePublish, isPublished }) => {
  return (
    <>
      <div className='drag-item card w-full bg-base-100 shadow-xl'>
        <figure
          className='group relative aspect-[1/0.645] cursor-pointer'
          onClick={() => {
            window.open(
              `/galleries/${room.roomId}`,
              '_blank',
              'noopener, noreferrer'
            );
          }}
        >
          <img
            src={`${
              room?.previewImg
                ? room?.previewImg + '?q=60'
                : '/assets/img/gallery_dummy.png'
            }`}
            alt={room.title}
            className='h-full w-full object-cover'
          />
          <div className='sort-handle backdrop-blur/base absolute right-0 top-0 flex cursor-grab items-center rounded-bl-xl bg-white/50 p-2'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
            >
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M13.5 6C13.5 5.17157 14.1716 4.5 15 4.5C15.8284 4.5 16.5 5.17157 16.5 6C16.5 6.82843 15.8284 7.5 15 7.5C14.1716 7.5 13.5 6.82843 13.5 6ZM13.5 12C13.5 11.1716 14.1716 10.5 15 10.5C15.8284 10.5 16.5 11.1716 16.5 12C16.5 12.8284 15.8284 13.5 15 13.5C14.1716 13.5 13.5 12.8284 13.5 12ZM13.5 18C13.5 17.1716 14.1716 16.5 15 16.5C15.8284 16.5 16.5 17.1716 16.5 18C16.5 18.8284 15.8284 19.5 15 19.5C14.1716 19.5 13.5 18.8284 13.5 18Z'
                fill='#1F2A37'
              />
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M7 6C7 5.17157 7.67157 4.5 8.5 4.5C9.32843 4.5 10 5.17157 10 6C10 6.82843 9.32843 7.5 8.5 7.5C7.67157 7.5 7 6.82843 7 6ZM7 12C7 11.1716 7.67157 10.5 8.5 10.5C9.32843 10.5 10 11.1716 10 12C10 12.8284 9.32843 13.5 8.5 13.5C7.67157 13.5 7 12.8284 7 12ZM7 18C7 17.1716 7.67157 16.5 8.5 16.5C9.32843 16.5 10 17.1716 10 18C10 18.8284 9.32843 19.5 8.5 19.5C7.67157 19.5 7 18.8284 7 18Z'
                fill='#1F2A37'
              />
            </svg>
          </div>
          <button className='absolute left-1/2 top-1/2  hidden -translate-x-1/2 -translate-y-1/2 transform items-center rounded-full bg-primary px-4 py-1 text-sm text-white group-hover:flex'>
            <Trans i18nKey={'btn.enter'} />
            <ArrowRightIcon className='ml-1 h-4 w-4' />
          </button>
        </figure>
        <div className='card-body p-4'>
          <div className='flex justify-start'>
            <h2 className='m-0 cursor-pointer break-all text-lg font-medium hover:underline'>
              {room?.title ? room.title : 'Untitled Gallery'}
            </h2>
          </div>
          <div className='flex items-center gap-2'>
            <img
              className='h-6 w-6 flex-shrink-0 rounded-full object-cover'
              src={
                room?.user?.profileImageUrl
                  ? room?.user?.profileImageUrl + '?w=300&q=60'
                  : '/assets/img/avatar/dummy_avatar.svg'
              }
            />
            <p className='text-xs'>{room?.user?.displayName}</p>
          </div>
          <div className='flex flex-col items-end gap-2'>
            <div className='flex gap-2'>
              {isPublished ? (
                <button
                  className='btn btn-outline btn-error btn-sm'
                  onClick={() => togglePublish(room.id)}
                >
                  <EyeSlashIcon className='h-5 w-5' />
                  <Trans i18nKey={'btn.unpublish'} />
                </button>
              ) : (
                <button
                  className='btn btn-sm !bg-accent'
                  onClick={() => togglePublish(room.id)}
                >
                  <EyeIcon className='h-5 w-5' />
                  <Trans i18nKey={'btn.publish'} />
                </button>
              )}
              <Link
                to={`/edit-exhibition/${room.roomId}`}
                className='btn btn-outline btn-sm'
              >
                <PencilSquareIcon className='h-5 w-5' />
                <Trans i18nKey={'btn.edit'} />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RoomCardWithMenu;
