import { EyeSlashIcon, XMarkIcon } from '@heroicons/react/24/outline';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { ExhibitionContext } from '../../context/ExhibitionContext';
import { useSelector } from 'react-redux';
import { useMutation } from '@tanstack/react-query';
import {
  createDraftExhibition,
  getExhibitionByExhibitionId,
  saveDraftExhibition,
  savePublishExhibition,
} from '../../services/Exhibition';
import { errorToast, successToast } from '../../utils/toast';
import { validateRoomId } from '../../utils/validation';
import Preview from '../r3f/Preview';

export const DiscardExhibitionModal = ({
  title,
  desc,
  editMode,
  plan,
  hasReachedMaxPublish,
  hasReachedMaxCreation,
}) => {
  const {
    REACT_APP_MAX_PUBLISHED_EXHIBITION_MEMBER: MAX_PUBLISHED_EXHIBITION_FREE,
    REACT_APP_MAX_PUBLISHED_EXHIBITION_PREMIUM:
      MAX_PUBLISHED_EXHIBITION_PREMIUM,
    REACT_APP_MAX_PUBLISHED_EXHIBITION_VIP: MAX_PUBLISHED_EXHIBITION_VIP,
    REACT_APP_MAX_EXHIBITION_MEMBER: MAX_EXHIBITION_FREE,
    REACT_APP_MAX_EXHIBITION_PREMIUM: MAX_EXHIBITION_PREMIUM,
    REACT_APP_MAX_EXHIBITION_VIP: MAX_EXHIBITION_VIP,
  } = process.env;

  const {
    exhibitionInfo,
    setExhibitionInfo,
    exhibitionSettings,
    exhibitionSnapshot,
    setExhibitionSnapshot,
    exhibitionItems,
    isAgreedTerms,
    setIsAgreedTerms,
  } = useContext(ExhibitionContext);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.currentUser);
  const [exhibition, setExhibition] = useState(null);
  const [exhibitionId, setExhibitionId] = useState(null);

  const location = useLocation();

  const getExhibition = async (roomId) => {
    const room = await getExhibitionByExhibitionId(roomId);
    setExhibitionId(room.id);
    setExhibition(room);
  };

  const handleChangeShowOnHome = (value) => {
    //必要であればvalitationを追加する

    setExhibitionInfo((prevState) => ({
      ...prevState,
      showOnHome: value,
    }));
  };

  //save&draft処理
  const saveDraftMutation = useMutation({
    mutationFn: async () => {
      const items = exhibitionItems.map((item) => item.id);

      return await saveDraftExhibition(
        Number(exhibitionId),
        user.id,
        exhibitionSnapshot,
        items,
        exhibitionInfo,
        exhibitionSettings
      );
    },
    onSuccess: () => {
      navigate('/dashboard?tab=drafts');
      successToast(t('message.success.t12'));
    },
    onError: (err) => {
      if (err.response.data.statusCode == 400) {
        errorToast(err?.response?.data.message);
      } else {
        errorToast(
          'Something went wrong. We are aware of the problem and will try to fix it as soon as possible. Sorry for the inconvenience.'
        );
      }
    },
  });

  //create&draft処理
  const createDraftMutation = useMutation({
    mutationFn: async () => {
      const items = exhibitionItems.map((item) => item.id);

      return await createDraftExhibition(
        user.id,
        exhibitionSnapshot,
        items,
        exhibitionInfo,
        exhibitionSettings
      );
    },
    onSuccess: () => {
      navigate('/dashboard?tab=drafts');
      successToast(t('message.success.t12'));
    },
    onError: (err) => {
      if (err.response.data.statusCode == 400) {
        errorToast(err?.response?.data.message);
      } else {
        errorToast(
          'Something went wrong. We are aware of the problem and will try to fix it as soon as possible. Sorry for the inconvenience.'
        );
      }
    },
  });

  const handleChangeSnapshot = (value) => {
    setExhibitionSnapshot(value);
  };

  // save&publish処理
  const savePublishMutation = useMutation({
    mutationFn: async () => {
      const items = exhibitionItems.map((item) => item.id);

      return await savePublishExhibition(
        Number(exhibition.id),
        user.id,
        exhibitionSnapshot,
        items,
        exhibitionInfo,
        exhibitionSettings
      );
    },
    onSuccess: () => {
      navigate('/dashboard?tab=galleries');
      successToast(t('message.success.t12'));
    },
    onError: (err) => {
      if (err.response.data.statusCode == 400) {
        errorToast(err?.response?.data.message);
      } else {
        errorToast(
          'Something went wrong. We are aware of the problem and will try to fix it as soon as possible. Sorry for the inconvenience.'
        );
      }
    },
  });

  //update処理
  const handleUpdateExhibition = async (e) => {
    e.preventDefault();

    if (savePublishMutation.isPending) {
      return; // isPendingの場合は何もしない
    }

    if (
      exhibitionInfo.title &&
      exhibitionInfo.description &&
      exhibitionInfo.roomId
    ) {
      savePublishMutation.mutate();
    } else {
      errorToast(t('message.error.t5'));
    }
  };

  const handleSaveDraft = () => {
    if (saveDraftMutation.isPending) {
      return; // isPendingの場合は何もしない
    }

    if (
      exhibitionInfo.title &&
      exhibitionInfo.description &&
      exhibitionInfo.roomId
    ) {
      saveDraftMutation.mutate();
    } else {
      errorToast(t('message.error.t5'));
    }
  };

  const handleCreateDraft = () => {
    if (createDraftMutation.isPending) {
      return; // isPendingの場合は何もしない
    }

    if (
      exhibitionInfo.title &&
      exhibitionInfo.description &&
      exhibitionInfo.roomId
    ) {
      createDraftMutation.mutate();
    } else {
      errorToast(t('message.error.t5'));
    }
  };

  const hasError = useMemo(() => {
    // requiredを満たしているか
    const isFilled =
      !!exhibitionInfo.title &&
      !!exhibitionInfo.description &&
      !!exhibitionInfo.roomId &&
      ((exhibitionInfo.isPrivate && !!exhibitionInfo.password) ||
        !exhibitionInfo.isPrivate);
    return !isFilled ? true : false;
  }, [
    exhibitionInfo.title,
    exhibitionInfo.description,
    exhibitionInfo.roomId,
    exhibitionInfo.password,
    exhibitionInfo.isPrivate,
  ]);

  useEffect(() => {
    if (editMode) {
      const roomId = location.pathname.split('/')[2];
      getExhibition(roomId);
    }
  }, [editMode, location]);

  return (
    <>
      <dialog id='discard_exhibition_modal' className='modal'>
        <div className='modal-box flex flex-col gap-6'>
          <button
            className='btn btn-circle btn-ghost btn-sm absolute right-2 top-2'
            onClick={() =>
              document.getElementById('discard_exhibition_modal').close()
            }
          >
            <XMarkIcon className='h-5 w-5' />
          </button>
          <h3 className='text-lg font-bold'>{title}</h3>
          <div className='flex justify-center'>
            {exhibitionSnapshot ? (
              <img
                src={exhibitionSnapshot}
                className='h-[180px]  w-full max-w-60 object-cover'
              />
            ) : (
              <div className='flex h-[180px] w-60 items-center justify-center bg-[#d9d9d9]'>
                <p className='text-sm'>Loading</p>
              </div>
            )}
          </div>

          <p>{desc}</p>
          {editMode ? (
            <div>
              {!exhibitionInfo.published && hasReachedMaxPublish && (
                <div role='alert' className='alert mb-6'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                    className='h-6 w-6 shrink-0 stroke-info'
                  >
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      strokeWidth='2'
                      d='M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z'
                    ></path>
                  </svg>
                  {i18n.language == 'ja' ? (
                    <span>
                      {plan == 'VIP'
                        ? `現在のプラン（VIP CAS Member）で同時に公開できる展示会の上限に達しました。さらに展示会を公開したい場合、公開する展示会を１つ選んで他の展示会を非公開にすることを検討してください。VIP CAS Memberでは${MAX_PUBLISHED_EXHIBITION_VIP}個の展示会の同時公開をサポートしていますが、さらに多くの展示会を公開したい場合は、お問い合わせフォームよりお問い合わせください。`
                        : plan == 'PREMIUM'
                        ? `現在のプラン（PREMIUM CAS Member）での同時公開展示の上限に達しました。さらに展示会を公開したい場合、公開する展示会を１つ選んで他の展示会を非公開にするか、${MAX_PUBLISHED_EXHIBITION_VIP}個の展示会を同時に公開できるVIP CAS Memberにアップグレードしましょう。`
                        : `現在のプラン（CAS Member）での同時公開展示の上限に達しました。さらに展示会を公開したい場合、公開する展示会を１つ選んで他の展示会を非公開にするか、${MAX_PUBLISHED_EXHIBITION_PREMIUM}個の展示会を同時に公開できるVIP CAS Memberにアップグレードしましょう。`}
                    </span>
                  ) : (
                    <span>
                      {plan == 'VIP'
                        ? `You have reached the limit for the number of concurrent published exhibitions under your plan: VIP CAS Member.You may wish to unpublish this exhibition and publish one of your unpublished exhibitions. VIP CAS Member supports ${MAX_PUBLISHED_EXHIBITION_VIP} concurrent published exhibitions, but if you need even more, please contact us.`
                        : plan == 'PREMIUM'
                        ? `You have reached the limit for the number of concurrent published exhibitions under your plan: Premium CAS Member.
You may wish to unpublish this exhibition and publish one of your unpublished exhibitions, or upgrade to VIP CAS Member, which supports ${MAX_PUBLISHED_EXHIBITION_VIP} concurrent published exhibitions.`
                        : `You have reached the limit for the number of concurrent published exhibitions under your plan: CAS Member.
You may wish to unpublish this exhibition and publish one of your unpublished exhibitions, or upgrade to VIP CAS Member, which supports ${MAX_PUBLISHED_EXHIBITION_PREMIUM} concurrent published exhibitions.`}
                    </span>
                  )}
                </div>
              )}

              <div className='form-control'>
                <label className='label flex cursor-pointer items-start justify-start gap-3'>
                  <input
                    checked={exhibitionInfo.showOnHome}
                    type='checkbox'
                    onChange={() =>
                      handleChangeShowOnHome(!exhibitionInfo.showOnHome)
                    }
                    className='checkbox-primary checkbox !h-[1.375rem] !w-[1.375rem] !border-base-content/20'
                  />
                  <p className='text-xs font-normal'>
                    {plan == 'FREE' && (
                      <Trans i18nKey={'exhibition-agreement-free'} />
                    )}

                    {(plan == 'PREMIUM' || plan == 'VIP') && (
                      <Trans
                        i18nKey={'exhibition-agreement-premium'}
                        components={{
                          link1: (
                            <a
                              href='/explore?tab=premium'
                              target='_blank'
                              className='underline'
                            ></a>
                          ),
                        }}
                      />
                    )}
                  </p>
                </label>
              </div>
              <div className='form-control'>
                <label
                  className={`label flex cursor-pointer items-start gap-3 ${
                    editMode ? 'cursor-not-allowed' : 'cursor-pointer'
                  }`}
                >
                  <input
                    checked={isAgreedTerms}
                    type='checkbox'
                    onChange={() => setIsAgreedTerms(!isAgreedTerms)}
                    className={`checkbox-primary checkbox !h-[1.375rem] !w-[1.375rem] !border-base-content/20`}
                    disabled={editMode}
                  />
                  <p className='text-xs font-normal'>
                    <Trans
                      i18nKey={'exhibition-term'}
                      components={{
                        link1: (
                          <a
                            href='/terms-privacy'
                            target='_blank'
                            className='underline'
                          ></a>
                        ),
                      }}
                    />
                  </p>
                </label>
              </div>

              <div className='mt-6 flex flex-col items-center'>
                <button
                  className='btn btn-primary  mb-2 w-[256px]'
                  onClick={handleUpdateExhibition}
                  disabled={hasError || !isAgreedTerms || !exhibitionSnapshot}
                >
                  {savePublishMutation.isPending && (
                    <span className='loading loading-spinner'></span>
                  )}
                  <Trans i18nKey={'btn.save-publish'} />
                </button>

                <button
                  className='btn btn-outline btn-primary  w-[256px]'
                  onClick={handleSaveDraft}
                  disabled={!exhibitionSnapshot || !isAgreedTerms}
                >
                  {saveDraftMutation.isPending && (
                    <span className='loading loading-spinner'></span>
                  )}
                  <EyeSlashIcon className='h-6 w-6 shrink-0' />
                  <Trans i18nKey={'btn.save-unpublished'} />
                </button>

                <Link to={'/dashboard'} className='btn btn-ghost mt-2'>
                  <Trans i18nKey={'btn.discard'} />
                </Link>
              </div>
            </div>
          ) : (
            <>
              <div className='form-control'>
                <label className='label flex cursor-pointer items-start justify-start gap-3'>
                  <input
                    checked={exhibitionInfo.showOnHome}
                    type='checkbox'
                    onChange={() =>
                      handleChangeShowOnHome(!exhibitionInfo.showOnHome)
                    }
                    className='checkbox-primary checkbox !h-[1.375rem] !w-[1.375rem] !border-base-content/20'
                  />
                  <p className='text-xs font-normal'>
                    <Trans
                      i18nKey={'exhibition-agreement'}
                      components={{
                        link1: (
                          <a href='/' target='_blank' className='underline'></a>
                        ),
                      }}
                    />
                  </p>
                </label>
              </div>
              <div className='form-control'>
                <label
                  className={`label flex cursor-pointer items-start gap-3 ${
                    editMode ? 'cursor-not-allowed' : 'cursor-pointer'
                  }`}
                >
                  <input
                    checked={isAgreedTerms}
                    type='checkbox'
                    onChange={() => setIsAgreedTerms(!isAgreedTerms)}
                    className={`checkbox-primary checkbox !h-[1.375rem] !w-[1.375rem] !border-base-content/20`}
                    disabled={editMode}
                  />
                  <p className='text-xs font-normal'>
                    <Trans
                      i18nKey={'exhibition-term'}
                      components={{
                        link1: (
                          <a
                            href='/terms-privacy'
                            target='_blank'
                            className='underline'
                          ></a>
                        ),
                      }}
                    />
                  </p>
                </label>
              </div>

              <div className='flex flex-col justify-end lg:flex-row'>
                <button
                  className='btn btn-outline btn-primary lg:order-2'
                  onClick={handleCreateDraft}
                  disabled={!exhibitionSnapshot || !isAgreedTerms}
                >
                  {createDraftMutation.isPending && (
                    <span className='loading loading-spinner'></span>
                  )}
                  <Trans i18nKey={'btn.save-unpublished'} />
                </button>
                <Link to={'/dashboard'} className='btn btn-ghost mr-3'>
                  <Trans i18nKey={'btn.discard'} />
                </Link>
              </div>
            </>
          )}
        </div>

        <div className='pointer-events-none absolute top-0 z-[-1] opacity-0'>
          <Preview
            theme={exhibitionSettings.theme}
            frame={exhibitionSettings.frame}
            showSilhouette={exhibitionSettings.silhouette}
            setSnapshot={handleChangeSnapshot}
            light={exhibitionSettings.light}
            items={exhibitionItems}
            lightColor={exhibitionSettings.lightColor}
            ceiling={exhibitionSettings.ceiling}
            wall={exhibitionSettings.wall}
            floor={exhibitionSettings.floor}
            background={exhibitionSettings.background}
            objectLight={exhibitionSettings.objectLight}
            colorCode={exhibitionSettings.colorCode}
            directionalLightIntensity={
              exhibitionSettings.directionalLightIntensity
            }
            directionalLightColorCode={
              exhibitionSettings.directionalLightColorCode
            }
            spotLightIntensity={exhibitionSettings.spotLightIntensity}
            spotLightColorCode={exhibitionSettings.spotLightColorCode}
            frameColor={exhibitionSettings.frameColor}
            matColor={exhibitionSettings.matColor}
            fogColor={exhibitionSettings.fogColor}
          />
        </div>
      </dialog>
    </>
  );
};
