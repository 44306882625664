import { Trans } from 'react-i18next';

export const About = () => {
  return (
    <article className='mx-auto flex max-w-[39.0625rem] flex-col gap-10 px-4 pb-40 pt-10 sm:px-0'>
      <div className='mx-auto h-[12.5rem] w-[12.5rem]'>
        <img
          src='/assets/img/cas-logo-large.webp'
          alt='CAS (Curated Art Show)'
        />
      </div>
      <h1 className='text-center !font-outfit text-5xl font-semibold'>
        <Trans i18nKey={'about.ttl'} />
      </h1>
      <div className='h-[28.75rem] w-full'>
        <img
          src='/assets/img/about-hero.webp'
          alt=''
          className='h-full w-full object-cover'
        />
      </div>
      <section className='flex flex-col gap-5'>
        <p>
          <Trans i18nKey={'about.t1'} />
        </p>
        <p>
          <Trans i18nKey={'about.t2'} />
        </p>
        <p>
          <Trans i18nKey={'about.t3'} />
        </p>
        <p>
          <Trans i18nKey={'about.t4'} />
        </p>
        <p>
          <Trans i18nKey={'about.t5'} />
        </p>
      </section>
    </article>
  );
};
