import React, { useEffect, useState, useMemo } from 'react';
import ItemCardWithMenu from '../../components/item/ItemCardWithMenu';
import { publicRequest, authRequest } from '../../requestMethods';
import { useSelector } from 'react-redux';
import AddToGalleryModal from '../../components/AddToGalleryModal';
import SkeletonItemCard from '../../components/item/SkeletonItemCard';
import { errorToast, successToast } from '../../utils/toast';
import { Trans, useTranslation } from 'react-i18next';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import DraftItemConfirmationModal from '../../components/item/DraftItemConfirmationModal';

const Items = ({ setSelectedItem }) => {
  const { t, i18n } = useTranslation();
  const [publishedItems, setPublishedItems] = useState([]);
  const [addToGalleryItem, setAddToGalleryItem] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [draftItem, setDraftItem] = useState(null);

  const user = useSelector((state) => state.user.currentUser);

  const getData = async () => {
    const res = await publicRequest.get(`/items/creator/${user.id}`);
    setPublishedItems(res.data);
    setLoading(false);
  };
  useEffect(() => {
    getData();
  }, [loading]);

  const unpublish = async (itemId) => {
    try {
      setLoading(true);
      await authRequest.post(`/items/unpublish/${itemId}`);
      getData();
      successToast(t('message.success.t11'));
    } catch (err) {
      console.log(err);
      errorToast(t('message.error.t1'));
    } finally {
      setLoading(false);
    }
  };

  const filteredPublishedItems = useMemo(() => {
    if (searchTerm == '') {
      return publishedItems;
    }
    return publishedItems.filter((item) =>
      item.title.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [searchTerm, publishedItems]);

  return (
    <>
      <div className='min-h-44'>
        {publishedItems?.length === 0 ? (
          <div className='w-full font-bold'>
            <Trans i18nKey={'dashboard.no-artworks'} />
          </div>
        ) : (
          <>
            {!loading && (
              <label className='input input-bordered mb-12 flex items-center gap-2 md:max-w-96'>
                {!searchTerm && <MagnifyingGlassIcon className={`h-6 w-6`} />}
                <input
                  value={searchTerm}
                  type='text'
                  className='grow bg-transparent'
                  placeholder={
                    i18n.language === 'ja'
                      ? '作品名を入力'
                      : 'Type artwork title'
                  }
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                {searchTerm && (
                  <span
                    onClick={() => setSearchTerm('')}
                    className='badge cursor-pointer bg-base-200 px-[0.4375rem] py-0 text-xs'
                  >
                    Clear
                  </span>
                )}
              </label>
            )}
            <div className='grid gap-[1.5rem] md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4'>
              {loading ? (
                <>
                  {Array(8)
                    .fill()
                    .map((_, index) => (
                      <SkeletonItemCard key={index} />
                    ))}
                </>
              ) : (
                <>
                  {filteredPublishedItems.map((item, index) => (
                    <ItemCardWithMenu
                      key={index}
                      item={item}
                      setSelectedItem={setSelectedItem}
                      setAddToGalleryItem={setAddToGalleryItem}
                      setDraftItem={setDraftItem}
                      togglePublish={unpublish}
                    />
                  ))}
                </>
              )}
            </div>
          </>
        )}
      </div>
      <AddToGalleryModal selectedItem={addToGalleryItem} />
      <DraftItemConfirmationModal item={draftItem} unpublish={unpublish} />
    </>
  );
};

export default Items;
