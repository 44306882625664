import React, { useLayoutEffect, useMemo, useRef } from 'react';
import { useGLTF, useTexture } from '@react-three/drei';
import * as THREE from 'three';

export default function CustomFrame4(props) {
  const { nodes, materials } = useGLTF(
    'https://data.curatedartshow.com/models/frames/custom_jena_frame.glb'
  );

  const bottomTextureProps = useTexture({
    map: `https://data.curatedartshow.com/texture/jenaFrame/MidnightPlastic/baseColor.png`,
    normalMap: `https://data.curatedartshow.com/texture/jenaFrame/MidnightPlastic/normal.png`,
    roughnessMap: `https://data.curatedartshow.com/texture/jenaFrame/MidnightPlastic/roughness.png`,
    aoMap: `https://data.curatedartshow.com/texture/jenaFrame/MidnightPlastic/ao.png`,
    metalnessMap: `https://data.curatedartshow.com/texture/jenaFrame/MidnightPlastic/metallic.png`,
    displacementMap: `https://data.curatedartshow.com/texture/jenaFrame/MidnightPlastic/height.png`,
  });

  const topTextureProps = useTexture({
    map: `https://data.curatedartshow.com/texture/jenaFrame/BronzeCubeSandstone/basecolor.png`,
    normalMap: `https://data.curatedartshow.com/texture/jenaFrame/BronzeCubeSandstone/normal.png`,
    roughnessMap: `https://data.curatedartshow.com/texture/jenaFrame/BronzeCubeSandstone/roughness.png`,
    metalnessMap: `https://data.curatedartshow.com/texture/jenaFrame/BronzeCubeSandstone/metallic.png`,
    displacementMap: `https://data.curatedartshow.com/texture/jenaFrame/BronzeCubeSandstone/height.png`,
  });

  const { frameColor, matColor } = props;

  // useMemo でマテリアルをキャッシュし、frameColor, matColor が変わったときに再生成
  const frameMaterial = useMemo(() => {
    const material = materials['default_material386'].clone();
    material.color.set(frameColor);
    material.metalness = 1;
    material.roughness = 3;
    material.needsUpdate = true;
    return material;
  }, [frameColor, materials]);

  const matMaterial = useMemo(() => {
    const material = materials['default_material386'].clone();
    material.color.set(matColor);
    material.metalness = 1;
    material.roughness = 3;
    material.needsUpdate = true;
    return material;
  }, [matColor, materials]);

  const topMaterial = useMemo(() => {
    const material = new THREE.MeshStandardMaterial({
      color: frameColor,
      // color: '#4d2c01',
      metalness: 0.5,
      roughness: 1,
      displacementScale: 0,
      map: topTextureProps.map,
      roughnessMap: topTextureProps.roughnessMap,
      metalnessMap: topTextureProps.metalnessMap,
      normalMap: topTextureProps.normalMap,
      displacementMap: topTextureProps.displacementMap,
    });

    material.needsUpdate = true;
    return material;
  }, [frameColor, topTextureProps, materials]);

  const bottomMaterial = useMemo(() => {
    const material = new THREE.MeshStandardMaterial({
      color: frameColor,
      // color: '#6c6c6c',
      metalness: 0.5,
      roughness: 0.7,
      aoMapIntensity: 2,
      displacementScale: 0,
      map: bottomTextureProps.map,
      aoMap: bottomTextureProps.aoMap,
      roughnessMap: bottomTextureProps.roughnessMap,
      metalnessMap: bottomTextureProps.metalnessMap,
      normalMap: bottomTextureProps.normalMap,
      displacementMap: bottomTextureProps.displacementMap,
    });

    material.needsUpdate = true;
    return material;
  }, [frameColor, bottomTextureProps, materials]);

  useLayoutEffect(() => {
    Object.values(topTextureProps).forEach((texture) => {
      texture.wrapS = THREE.RepeatWrapping;
      texture.wrapT = THREE.RepeatWrapping;
      texture.repeat.set(1, 1);
    });
  }, [topTextureProps]);

  useLayoutEffect(() => {
    Object.values(bottomTextureProps).forEach((texture) => {
      texture.wrapS = THREE.RepeatWrapping;
      texture.wrapT = THREE.RepeatWrapping;
      texture.repeat.set(2, 1);
    });
  }, [bottomTextureProps]);

  return (
    <group {...props} dispose={null}>
      <group
        position={[0.009, -0.184, 0.004]}
        rotation={[-0.024, 0, 0]}
        scale={1.193}
      >
        <group
          position={[-0.022, 0.012, -0.019]}
          rotation={[0.098, -1.189, 0.008]}
          scale={0.838}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.default_procedural_mesh_cube_n3d2.geometry}
            material={topMaterial}
            position={[-0.028, 0.764, -0.044]}
            rotation={[0.188, 1.191, -0.177]}
            scale={[2.718, 0.085, 0.003]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.default_procedural_mesh_cube_n3d3.geometry}
            material={bottomMaterial}
            position={[0.059, 0, -0.004]}
            rotation={[-1.596, -0.051, 1.187]}
            scale={[2.53, 0.435, 0.494]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Frame001.geometry}
            material={frameMaterial}
            position={[0.014, 0.037, -0.023]}
            rotation={[-0.186, 1.183, 0.17]}
            scale={[2.982, 3.645, 0.119]}
          />
        </group>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mat.geometry}
          material={matMaterial}
          position={[0, 0.35, 0.011]}
          rotation={[-3.109, 0.016, -3.14]}
          scale={[0.787, 0.842, 0.775]}
        />
      </group>
    </group>
  );
}

useGLTF.preload(
  'https://data.curatedartshow.com/models/frames/custom_jena_frame.glb'
);
