import React, { useRef } from 'react';
import { SpotLight } from '@react-three/drei';

const SpotLightOnPreview = ({
  opacity = 0.7,
  anglePower = 3,
  position = [0.2, 2.8, 4],
  targetPosition = [0.4, -2, 1],
  angle = 0.6,
  color = '#ffffff',
}) => {
  const spotLightRef = useRef();

  return (
    <SpotLight
      target-position={targetPosition}
      castShadow
      distance={6.5}
      attenuation={2.9}
      anglePower={anglePower}
      color={color}
      opacity={opacity}
      ref={spotLightRef}
      position={position}
      angle={angle}
      penumbra={0.5}
      intensity={3}
    />
  );
};

export default SpotLightOnPreview;
