import { useState, useMemo, useRef, useContext, useEffect } from 'react';
import CustomHeaderDesktop from '../CustomHeaderDesktop';
import CustomHeaderMobile from '../CustomHeaderMobile';
import { FooterSimple } from '../../../../components/FooterSimple';
import MobileMenu from './MobileMenu';
import { DesktopMenu } from './DesktopMenu';
import { Theme } from './Theme';
import { PictureFrame } from './PictureFrame';
import { FunctionalLight } from './FunctionalLight';
import { InvisibleLight } from './InvisibleLight';
import { Silhouette } from './Silhouette';
import { EntranceVideo } from './EntranceVideo';
import { Sheet } from 'react-modal-sheet';
import { useMutation, useQuery } from '@tanstack/react-query';
import { getCeilings } from '../../../../services/Ceiling';
import { getWalls } from '../../../../services/Wall';
import { getFloors } from '../../../../services/Floor';
import Preview from '../../../../components/r3f/Preview';
import { ExhibitionContext } from '../../../../context/ExhibitionContext';
import { Trans, useTranslation } from 'react-i18next';
import { Music } from './Music';
import MyPresetsModal from '../../../../components/MyPresetsModal';
import SuggestedPresetsModal from '../../../../components/SuggestedPresetsModal';
import ResetConfirmModal from '../../../../components/ResetConfirmModal';
import SavePresetModal from '../../../../components/SavePresetModal';
import { createPreset } from '../../../../services/Exhibition';
import { errorToast, successToast } from '../../../../utils/toast';
import { useSelector } from 'react-redux';
import { getBackgrounds } from '../../../../services/Background';
import { XMarkIcon } from '@heroicons/react/24/outline';

export const ExhibitionSettings = ({
  moveTo,
  pcHeaderTitle,
  plan,
  editMode,
  hasReachedMaxPublish,
  hasReachedMaxCreation,
}) => {
  const ref = useRef();
  const { t } = useTranslation();

  const user = useSelector((state) => state.user.currentUser);
  const snapPoints = [0.9, 0.7, 0.4];
  const [snapIndex, setSnapIndex] = useState(snapPoints.length - 1);
  const [currentMenu, setCurrentMenu] = useState('Appearance');
  const [isMobile, setIsMobile] = useState(false);

  const [presetName, setPresetName] = useState('');

  const {
    exhibitionSettings,
    setExhibitionSettings,
    exhibitionItems,
    exhibitionInfo,
    exhibitionSnapshot,
    setExhibitionSnapshot,
    isAgreedTerms,
    setIsAgreedTerms,
  } = useContext(ExhibitionContext);

  const {
    data: ceilings,
    isLoading: isCeilingsLoading,
    refetch: refetchCeiling,
  } = useQuery({
    queryKey: ['get-ceilings'],
    queryFn: () => getCeilings(),
  });

  const {
    data: walls,
    isLoading: isWallsLoading,
    refetch: refetchWall,
  } = useQuery({
    queryKey: ['get-walls'],
    queryFn: () => getWalls(),
  });

  const {
    data: floors,
    isLoading: isFloorsLoading,
    refetch: refetchFloor,
  } = useQuery({
    queryKey: ['get-floors'],
    queryFn: () => getFloors(),
  });

  const {
    data: backgrounds,
    isLoading: isBackgroundsLoading,
    refetch: refetchBackground,
  } = useQuery({
    queryKey: ['get-background'],
    queryFn: () => getBackgrounds(),
  });

  const handleSelectMenu = (title) => {
    setCurrentMenu(title);
  };

  useEffect(() => {
    // 画面幅が1024px未満の場合はモバイルとみなす
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth < 1024);
    };
    checkIfMobile();
    // 画面サイズが変更されたときにモバイル判定を再実行
    window.addEventListener('resize', checkIfMobile);
    return () => {
      window.removeEventListener('resize', checkIfMobile);
    };
  }, []);

  const renderSettingComponent = useMemo(() => {
    if (
      !isCeilingsLoading &&
      !isWallsLoading &&
      !isFloorsLoading &&
      !isBackgroundsLoading &&
      currentMenu === 'Theme'
    ) {
      return (
        <Theme
          ceilings={ceilings}
          walls={walls}
          floors={floors}
          backgrounds={backgrounds}
          plan={plan}
        />
      );
    }
    if (currentMenu === 'Picture Frames') {
      return <PictureFrame plan={plan} />;
    }
    if (currentMenu === 'Functional Light') {
      return <FunctionalLight />;
    }
    if (currentMenu === 'Invisible Light') {
      return <InvisibleLight />;
    }
    if (currentMenu === 'Silhouettes') {
      return <Silhouette />;
    }
    if (currentMenu === 'Music') {
      return <Music />;
    }
    if (currentMenu === 'Entrance Video') {
      return <EntranceVideo />;
    }
    if (currentMenu === 'Appearance') {
      return null;
    }
    return <span className='loading loading-spinner text-primary'></span>;
  }, [currentMenu]);

  const loadPreset = (preset) => {
    const {
      frame,
      theme,
      silhouette,
      light,
      lightColor,
      music,
      colorCode,
      ceiling,
      wall,
      floor,
      objectLight,
      directionalLightIntensity,
      directionalLightColorTheme,
      directionalLightColorCode,
      spotLightIntensity,
      spotLightColorTheme,
      spotLightColorCode,
      frameColor,
      matColor,
      background,
      fogColor,
    } = preset;

    //silhouettesデータを同期
    const indices = preset.PresetSilhouettes?.map(
      (silhouette) => silhouette.index
    );

    setExhibitionSettings((prevState) => ({
      ...prevState,
      frame,
      theme,
      silhouette,
      music,
      light,
      lightColor,
      silhouettes: indices,
      colorCode,
      ceiling,
      wall,
      floor,
      objectLight,
      directionalLightIntensity,
      directionalLightColorTheme,
      directionalLightColorCode,
      spotLightIntensity,
      spotLightColorTheme,
      spotLightColorCode,
      frameColor,
      matColor,
      randomMusic: false,
      background,
      fogColor,
    }));
  };

  const resetSettings = () => {
    setExhibitionSettings({
      frame: '',
      theme: 'CYBERPUNK',
      silhouette: false,
      music: '',
      light: null,
      lightColor: 'WHITE',
      silhouettes: [],
      colorCode: '#191920',
      ceiling: null,
      wall: null,
      floor: null,
      objectLight: null,
      directionalLightIntensity: 1,
      directionalLightColorTheme: 'WHITE',
      directionalLightColorCode: '#ffffff',
      spotLightIntensity: 1,
      spotLightColorTheme: 'WHITE',
      spotLightColorCode: '#ffffff',
      frameColor: '#525252',
      matColor: '#525252',
      background: null,
      fogColor: null,
    });
  };

  // create preset処理
  const createPresetMutation = useMutation({
    mutationFn: async (data) => {
      return await createPreset(data);
    },
    onSuccess: () => {
      successToast(t('message.success.t18'));
      setPresetName('');
      document.getElementById('save_preset_modal').close();
    },
    onError: (err) => {
      console.error(err);
      const data = err.response.data;
      if (data.statusCode === 400) {
        errorToast(data.message);
      } else {
        errorToast(
          'Something went wrong. We are aware of the problem and will try to fix it as soon as possible. Sorry for the inconvenience.'
        );
      }
    },
  });

  const handleCreatePreset = async (e) => {
    e.preventDefault();

    const data = {
      ...exhibitionSettings,
      userId: user.id,
      name: presetName,
      status: 'PRIVATE',
      music: exhibitionSettings.randomMusic
        ? 100
        : exhibitionSettings.music
        ? exhibitionSettings.music
        : null,
      snapshot: exhibitionSnapshot,
    };
    createPresetMutation.mutate(data);
  };

  const handleChangeSnapshot = (value) => {
    setExhibitionSnapshot(value);
  };

  // 編集の場合は同意済みで固定
  useEffect(() => {
    if (editMode) {
      setIsAgreedTerms(true);
    }
  }, [editMode]);

  return (
    <div className='flex h-full w-full flex-col'>
      <CustomHeaderDesktop
        displayText={pcHeaderTitle}
        moveTo={moveTo}
        hasReachedMaxPublish={hasReachedMaxPublish}
        hasReachedMaxCreation={hasReachedMaxCreation}
        isAgreedTerms={isAgreedTerms}
      />
      <CustomHeaderMobile
        selectMenu={handleSelectMenu}
        currentMenu={currentMenu}
        moveTo={moveTo}
      />
      <div className='flex-auto lg:flex'>
        {/* SP,PC共通プレビュー */}
        <section className='order-2 flex-grow'>
          <div className='h-[55vh] w-full flex-col justify-center bg-base-300 lg:flex lg:h-full lg:justify-start lg:px-10 lg:py-12'>
            <div className='mb-4 hidden text-center text-xl font-bold text-gray-500 lg:mb-6 lg:block lg:text-2xl'>
              <Trans i18nKey={'gallery.preview'} />
            </div>
            <div className='h-full w-full lg:aspect-video lg:h-auto'>
              {currentMenu === 'Entrance Video' ? (
                <>
                  {exhibitionInfo.animationType == 0 ? (
                    <div className='flex h-full items-center justify-center bg-black text-center lg:aspect-video'>
                      <p className='font-bold text-white'>
                        Loading<span className='animate-dot-1'>.</span>
                        <span className='animate-dot-2'>.</span>
                        <span className='animate-dot-3'>.</span>
                      </p>
                    </div>
                  ) : (
                    <video
                      src={`/assets/transition_animation/transition${exhibitionInfo.animationType}.mp4`}
                      className='h-full w-full object-cover lg:aspect-video'
                      autoPlay
                      loop
                      playsInline
                      controls={false}
                      preload='auto'
                      muted
                    >
                      not support
                    </video>
                  )}
                </>
              ) : (
                <Preview
                  theme={exhibitionSettings.theme}
                  frame={exhibitionSettings.frame}
                  showSilhouette={exhibitionSettings.silhouette}
                  setSnapshot={() => {}}
                  light={exhibitionSettings.light}
                  items={exhibitionItems}
                  lightColor={exhibitionSettings.lightColor}
                  ceiling={exhibitionSettings.ceiling}
                  wall={exhibitionSettings.wall}
                  floor={exhibitionSettings.floor}
                  background={exhibitionSettings.background}
                  objectLight={exhibitionSettings.objectLight}
                  colorCode={exhibitionSettings.colorCode}
                  directionalLightIntensity={
                    exhibitionSettings.directionalLightIntensity
                  }
                  directionalLightColorCode={
                    exhibitionSettings.directionalLightColorCode
                  }
                  spotLightIntensity={exhibitionSettings.spotLightIntensity}
                  spotLightColorCode={exhibitionSettings.spotLightColorCode}
                  frameColor={exhibitionSettings.frameColor}
                  matColor={exhibitionSettings.matColor}
                  fogColor={exhibitionSettings.fogColor}
                />
              )}
            </div>
            <p className='mt-6 hidden text-center text-xs text-gray-500 lg:block'>
              <Trans i18nKey={'gallery.mobile-menu.tip'} />
            </p>
          </div>
        </section>
        {/* PC左カラム (SP非表示) */}
        <section className='order-1 hidden lg:block'>
          <DesktopMenu
            selectMenu={handleSelectMenu}
            moveTo={() => moveTo('ArrangeArtworks')}
            currentMenu={currentMenu}
            SettingComponent={renderSettingComponent}
          />
        </section>
      </div>
      {/* SPプレビュー下 (PC非表示) */}
      {isMobile && (
        <section className='h-full'>
          <MobileMenu
            selectMenu={handleSelectMenu}
            moveTo={() => moveTo('ArrangeArtworks')}
          />
          {/* PCサイズでマウントするとhtml要素にoverflow:hiddenが適用されスクロール不能になる */}
          <Sheet
            ref={ref}
            isOpen={currentMenu !== 'Appearance'}
            onClose={() => setCurrentMenu('Appearance')}
            snapPoints={snapPoints}
            initialSnap={snapPoints.length - 1}
            onSnap={(snapIndex) => setSnapIndex(snapIndex)}
          >
            <Sheet.Container style={{ paddingBottom: ref.current?.y }}>
              <div className='relative'>
                <Sheet.Header />
                <div
                  className='absolute right-2 top-[10px]'
                  onClick={() => setCurrentMenu('Appearance')}
                >
                  <XMarkIcon className='h-5 w-5' />
                </div>
              </div>
              <Sheet.Content>
                <Sheet.Scroller draggableAt='both'>
                  <div className='px-4'>{renderSettingComponent}</div>
                </Sheet.Scroller>
              </Sheet.Content>
            </Sheet.Container>
          </Sheet>
        </section>
      )}
      <FooterSimple />
      <SuggestedPresetsModal loadPreset={loadPreset} />
      <MyPresetsModal loadPreset={loadPreset} />
      <ResetConfirmModal resetSettings={resetSettings} />
      <SavePresetModal
        presetName={presetName}
        setPresetName={setPresetName}
        handleCreatePreset={handleCreatePreset}
        isLoading={createPresetMutation.isPending}
      />

      {/* サムネイル生成用 */}
      <div className='pointer-events-none absolute top-0 z-[-1] opacity-0'>
        <Preview
          theme={exhibitionSettings.theme}
          frame={exhibitionSettings.frame}
          showSilhouette={exhibitionSettings.silhouette}
          setSnapshot={handleChangeSnapshot}
          light={exhibitionSettings.light}
          items={exhibitionItems}
          lightColor={exhibitionSettings.lightColor}
          ceiling={exhibitionSettings.ceiling}
          wall={exhibitionSettings.wall}
          floor={exhibitionSettings.floor}
          background={exhibitionSettings.background}
          objectLight={exhibitionSettings.objectLight}
          colorCode={exhibitionSettings.colorCode}
          directionalLightIntensity={
            exhibitionSettings.directionalLightIntensity
          }
          directionalLightColorCode={
            exhibitionSettings.directionalLightColorCode
          }
          spotLightIntensity={exhibitionSettings.spotLightIntensity}
          spotLightColorCode={exhibitionSettings.spotLightColorCode}
          frameColor={exhibitionSettings.frameColor}
          matColor={exhibitionSettings.matColor}
          fogColor={exhibitionSettings.fogColor}
        />
      </div>
    </div>
  );
};
