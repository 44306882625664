import { useContext, useEffect, useState } from 'react';
import { ExhibitionContext } from '../../../../context/ExhibitionContext';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const ANIMATION = [
  {
    value: 0,
    isFree: true,
    imgUrl: '/assets/img/transition.png',
    name: 'Default',
  },
  {
    value: 1,
    isFree: true,
    imgUrl: '/assets/img/transition1.png',
    name: 'Neon Tunnel',
  },
  {
    value: 2,
    isFree: false,
    imgUrl: '/assets/img/transition2.png',
    name: 'Neon Corridor',
  },
  {
    value: 3,
    isFree: false,
    imgUrl: '/assets/img/transition3.png',
    name: 'Abstract Tunnel',
  },
  {
    value: 4,
    isFree: false,
    imgUrl: '/assets/img/transition4.png',
    name: 'Sci-Fi Neon',
  },
  {
    value: 5,
    isFree: false,
    imgUrl: '/assets/img/transition5.png',
    name: 'Art Brush',
  },
  {
    value: 6,
    isFree: false,
    imgUrl: '/assets/img/transition6.png',
    name: 'Diving',
  },
  {
    value: 7,
    isFree: false,
    imgUrl: '/assets/img/transition7.png',
    name: 'Elevator',
  },
  {
    value: 8,
    isFree: false,
    imgUrl: '/assets/img/transition8.png',
    name: 'Hallway',
  },
  {
    value: 9,
    isFree: false,
    imgUrl: '/assets/img/transition9.png',
    name: 'Music Score',
  },
  {
    value: 10,
    isFree: false,
    imgUrl: '/assets/img/transition10.png',
    name: 'Time Machine',
  },
  {
    value: 11,
    isFree: false,
    imgUrl: '/assets/img/transition11.png',
    name: 'Driving',
  },
  {
    value: 12,
    isFree: false,
    imgUrl: '/assets/img/transition12.png',
    name: 'Pencil',
  },
  {
    value: 13,
    isFree: false,
    imgUrl: '/assets/img/transition13.png',
    name: 'Redcircle',
  },
  {
    value: 14,
    isFree: false,
    imgUrl: '/assets/img/transition14.png',
    name: 'Stairs',
  },
];

export const EntranceVideo = () => {
  const { i18n } = useTranslation();

  const { exhibitionInfo, setExhibitionInfo } = useContext(ExhibitionContext);
  const user = useSelector((state) => state.user.currentUser);

  const {
    //product ID
    REACT_APP_STRIPE_PREMIUM_CAS_MEMBER_PRODUCT_ID: PREMIUM_ID,
    REACT_APP_STRIPE_VIP_CAS_MEMBER_PRODUCT_ID: VIP_ID,
  } = process.env;

  const [plan, setPlan] = useState(null);

  const [filteredAnimations, setFilteredAnimations] = useState([]);

  useEffect(() => {
    if (user?.userPlans?.length > 0) {
      // 初期値を設定するための変数
      let foundPlan = 'FREE';

      // userPlans配列の各要素をチェック
      user.userPlans.forEach((el) => {
        if (el.SubscriptionPlans.productId === PREMIUM_ID) {
          foundPlan = 'PREMIUM';
        } else if (el.SubscriptionPlans.productId === VIP_ID) {
          foundPlan = 'VIP';
        }
      });

      setPlan(foundPlan);
    } else {
      setPlan('FREE');
    }
  }, [user]);

  useEffect(() => {
    //FREE: 0,1,2,3,4
    //PREMIUM: 0-9
    //VIP: all
    const filterAnimationsByPlan = (plan, animations) => {
      switch (plan) {
        case 'FREE':
          return animations.filter(({ value }) =>
            [0, 1, 2, 3, 4].includes(value)
          );
        case 'PREMIUM':
          return animations.filter(({ value }) =>
            [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].includes(value)
          );
        case 'VIP':
          return animations; //全て
        default:
          return [];
      }
    };

    const filteredAnimationsTmp = filterAnimationsByPlan(plan, ANIMATION);
    setFilteredAnimations(filteredAnimationsTmp);
  }, [ANIMATION, plan]);

  const handleChangeAnimationType = (value) => {
    //必要であればvalitationを追加する
    setExhibitionInfo((prevState) => ({
      ...prevState,
      animationType: value,
    }));
  };

  return (
    <div className=''>
      <p className='pb-8 text-xl font-semibold'>
        <Trans i18nKey={'gallery.entrance-video.ttl'} />
      </p>
      <div className='flex max-h-[29rem] flex-wrap gap-3'>
        {filteredAnimations?.map((item, index) => (
          <div
            key={`video-${index}`}
            className={`relative w-fit cursor-pointer`}
            onClick={(e) => {
              handleChangeAnimationType(Number(item.value));
            }}
          >
            <img
              src={item.imgUrl}
              alt={item.title}
              className={`aspect-square w-full max-w-[6.9375rem] rounded object-cover ${
                item.value == exhibitionInfo.animationType &&
                'border-[1.5px] border-black'
              }`}
            />
            {item.value == 'default' && i18n.language === 'ja' ? (
              <p className='mt-1 text-left text-xs'>デフォルト</p>
            ) : (
              <p className='mt-1 text-left text-xs'>{item.name}</p>
            )}
            {item.value == exhibitionInfo.animationType && (
              <img
                src='/assets/img/check_icon.png'
                className='absolute right-3 top-3 h-[0.875rem] w-[0.875rem]'
              />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};
