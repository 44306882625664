import { useGLTF } from '@react-three/drei';
import { Suspense, useLayoutEffect, useState } from 'react';
import * as THREE from 'three';

const PictureFrame74 = ({ color, ...props }) => {
  const { scene, materials } = useGLTF(
    'https://data.curatedartshow.com/models/frames/koiframe6.glb'
  );

  const [geometry, setGeometry] = useState();

  if (!geometry) {
    const cloneScene = scene.clone(true);
    setGeometry(cloneScene);
  }

  useLayoutEffect(() => {
    // console.log(materials);
    // materials['Corroded Rust_material'].color.set('#856f06');
    materials['Corroded Rust_material'].metalness = 1;
    materials['Corroded Rust_material'].roughness = 0.8;
    materials['Scratch Bronze_material'].color.set('#6a3e01');
    materials['Scratch Bronze_material'].metalness = 1;
    materials['Scratch Bronze_material'].roughness = 0.6;

    // materials['Cracked Cast Concrete_material].color.set('#1d1d1d');
    materials['Cracked Cast Concrete_material'].metalness = 0.9;
    materials['Cracked Cast Concrete_material'].roughness = 4;
    materials['Cracked Cast Concrete_material'].normalScale = new THREE.Vector2(
      0,
      0
    );

    // materials['default_material107.003'].color.set('#7c87cd');
    // materials['default_material107.003'].metalness = 1;
    // materials['default_material107.003'].roughness = 0;
    materials['Black Marble_material'].color.set('#868686');
    materials['Black Marble_material'].metalness = 0.5;
    materials['Black Marble_material'].roughness = 2;

    geometry.traverse((obj) => {
      if (obj.isMesh) obj.castShadow = obj.receiveShadow = true;
    });
  }, []);

  return (
    <Suspense fallback={null}>
      <primitive object={geometry} {...props} />
    </Suspense>
  );
};

export default PictureFrame74;
