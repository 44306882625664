import { createSlice } from '@reduxjs/toolkit';

const loadSlice = createSlice({
  name: 'load',
  initialState: {
    isLoading: false,
  },
  reducers: {
    loadStart: (state) => {
      state.isLoading = true;
    },
    loadEnd: (state) => {
      state.isLoading = false;
    },
  },
});

export const { loadStart, loadEnd } = loadSlice.actions;
export default loadSlice.reducer;
