import ReactGA from 'react-ga4';

const pluginConfig = {
  current_lang: localStorage.i18nextLng,
  autoclear_cookies: true, // default: false
  page_scripts: true, // default: false
  test: 'ciao',
  // mode: 'opt-in'                          // default: 'opt-in'; value: 'opt-in' or 'opt-out'
  // delay: 0,                               // default: 0
  // auto_language: null                     // default: null; could also be 'browser' or 'document'
  // autorun: true,                          // default: true
  // force_consent: false,                   // default: false
  // hide_from_bots: false,                  // default: false
  // remove_cookie_tables: false             // default: false
  // cookie_name: 'cc_cookie',               // default: 'cc_cookie'
  // cookie_expiration: 182,                 // default: 182 (days)
  // cookie_necessary_only_expiration: 182   // default: disabled
  // cookie_domain: location.hostname,       // default: current domain
  // cookie_path: '/',                       // default: root
  // cookie_same_site: 'Lax',                // default: 'Lax'
  // use_rfc_cookie: false,                  // default: false
  // revision: 0,                            // default: 0

  onFirstAction: function (user_preferences, cookie) {
    // callback triggered only once
  },

  onAccept: function (cookie) {
    // ...
    // console.log('onAccept:', cookie.categories);
    if (
      cookie.categories.includes('analytics') &&
      process.env.REACT_APP_ENV != 'development' &&
      process.env.NODE_ENV == 'production'
    ) {
      console.log('ga init');
      ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);
    }
  },

  onChange: function (cookie, changed_preferences) {
    // ...
    console.log('onChange cookie:', cookie);
    console.log('onChange preferences:', changed_preferences);
  },

  languages: {
    en: {
      consent_modal: {
        // title: 'We use cookies!',
        description:
          // 'Hi, this website uses essential cookies to ensure its proper operation and tracking cookies to understand how you interact with it. The latter will be set only after consent. <button type="button" data-cc="c-settings" class="cc-link">Let me choose</button>',
          'We use cookies for better browsing. By continuing to use this site, you agree to our <a href="/terms-privacy">Terms of Use & Privacy Policy</a>.\n<button type="button" data-cc="c-settings" class="cc-link">Manage privacy</button>',
        primary_btn: {
          text: 'Accept all',
          role: 'accept_all', // 'accept_selected' or 'accept_all'
        },
        secondary_btn: {
          text: 'Reject',
          role: 'accept_necessary', // 'settings' or 'accept_necessary'
        },
      },
      settings_modal: {
        title: 'Cookie Settings',
        save_settings_btn: 'Save settings',
        accept_all_btn: 'Accept all',
        reject_all_btn: 'Reject all',
        close_btn_label: 'Close',
        cookie_table_headers: [
          { col1: 'Name' },
          { col2: 'Domain' },
          { col3: 'Expiration' },
          { col4: 'Description' },
        ],
        blocks: [
          {
            title: 'Cookie usage 📢',
            description:
              'I use cookies to ensure the basic functionalities of the website and to enhance your online experience. You can choose for each category to opt-in/out whenever you want. For more details relative to cookies and other sensitive data, please read the full <a href="/terms-privacy" class="cc-link">Terms of Use & Privacy Policy</a>.',
          },
          {
            title: 'Strictly necessary cookies',
            description:
              'These cookies are essential for the proper functioning of my website. Without these cookies, the website would not work properly',
            toggle: {
              value: 'necessary',
              enabled: true,
              readonly: true, // cookie categories with readonly=true are all treated as "necessary cookies"
            },
          },
          {
            title: 'Performance and Analytics cookies',
            description:
              'These cookies allow the website to remember the choices you have made in the past',
            toggle: {
              value: 'analytics', // your cookie category
              enabled: false,
              readonly: false,
            },
            cookie_table: [
              // list of all expected cookies
              {
                col1: '^_ga', // match all cookies starting with "_ga"
                col2: 'google.com',
                col3: '2 years',
                col4: 'description ...',
                is_regex: true,
              },
              {
                col1: '_gid',
                col2: 'google.com',
                col3: '1 day',
                col4: 'description ...',
              },
            ],
          },
          {
            title: 'Advertisement and Targeting cookies',
            description:
              'These cookies collect information about how you use the website, which pages you visited and which links you clicked on. All of the data is anonymized and cannot be used to identify you',
            toggle: {
              value: 'targeting',
              enabled: false,
              readonly: false,
            },
          },
          {
            title: 'More information',
            description:
              'For any queries in relation to our policy on cookies and your choices, please <a class="cc-link" href="/contact">contact us</a>.',
          },
        ],
      },
    },
    ja: {
      consent_modal: {
        // title: 'We use cookies!',
        description:
          // 'Hi, this website uses essential cookies to ensure its proper operation and tracking cookies to understand how you interact with it. The latter will be set only after consent. <button type="button" data-cc="c-settings" class="cc-link">Let me choose</button>',
          '当ウェブサイトでは、より良いブラウジングのために、クッキーを利用しています。当ウェブサイトの利用を継続することで、<a href="/terms-privacy">利用規約及びプライバシーポリシー</a>に同意したものとみなされます。\n<button type="button" data-cc="c-settings" class="cc-link">詳細を見る</button>',
        primary_btn: {
          text: 'すべて同意する',
          role: 'accept_all', // 'accept_selected' or 'accept_all'
        },
        secondary_btn: {
          text: 'すべて却下する',
          role: 'accept_necessary', // 'settings' or 'accept_necessary'
        },
      },
      settings_modal: {
        title: 'クッキーの設定',
        save_settings_btn: '設定を保存する',
        accept_all_btn: 'すべて同意する',
        reject_all_btn: 'すべて却下する',
        close_btn_label: '閉じる',
        cookie_table_headers: [
          { col1: '項目名' },
          { col2: 'ドメイン' },
          { col3: '有効期限' },
          { col4: '説明' },
        ],
        blocks: [
          {
            title: 'クッキーの使用について 📢',
            description:
              'このウェブサイトでは、クッキーを使用して基本的な機能を確保し、オンライン体験を向上させます。 オプトイン/オプトアウトは、各カテゴリーごとにいつでも選択することができます。クッキーやその他の機密データに関する詳細については、<a href="/terms-privacy" class="cc-link">利用規約とプライバシーポリシー</a>の全文をお読みください。',
          },
          {
            title: '動作のために必要なクッキー',
            description:
              'これらのクッキーは、私のウェブサイトを適切に機能させるために不可欠です。これらのクッキーがなければ、ウェブサイトは正常に機能しません。',
            toggle: {
              value: 'necessary',
              enabled: true,
              readonly: true, // cookie categories with readonly=true are all treated as "necessary cookies"
            },
          },
          {
            title: 'パフォーマンスと分析のクッキー',
            description:
              'これらのクッキーにより、ウェブサイトは過去の行動を記憶することができます。',
            toggle: {
              value: 'analytics', // your cookie category
              enabled: false,
              readonly: false,
            },
            cookie_table: [
              // list of all expected cookies
              {
                col1: '^_ga', // match all cookies starting with "_ga"
                col2: 'google.com',
                col3: '2 years',
                col4: 'description ...',
                is_regex: true,
              },
              {
                col1: '_gid',
                col2: 'google.com',
                col3: '1 day',
                col4: 'description ...',
              },
            ],
          },
          {
            title: '広告とターゲティングのクッキー',
            description:
              'これらのクッキーは、お客様がウェブサイトをどのように利用したか、どのページを訪れたか、どのリンクをクリックしたかなどの情報を収集します。データはすべて匿名化され、お客様を特定するために使用されることはありません。',
            toggle: {
              value: 'targeting',
              enabled: false,
              readonly: false,
            },
          },
          {
            title: '詳細について',
            description:
              'クッキーに関する当社の方針及びお客様のご要望に関するご質問は、<a class="cc-link" href="/contact">お問い合わせ</a>ください。',
          },
        ],
      },
    },
  },
};

export default pluginConfig;
