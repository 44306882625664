import { useDispatch, useSelector } from 'react-redux';
import LoadingSpinner from '../../components/LoadingSpinner';
import { useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  validateContactMessage,
  validateContactName,
  validateEmail,
} from '../../utils/validation';
import { loadEnd, loadStart } from '../../redux/loadRedux';
import { publicRequest } from '../../requestMethods';
import { successToast } from '../../utils/toast';
import ReCAPTCHA from 'react-google-recaptcha';

const Contact = () => {
  const { t } = useTranslation();
  const { isLoading } = useSelector((state) => state.isLoading);
  const dispatch = useDispatch();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [nameError, setNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [messageError, setMessageError] = useState('');
  const [reCaptchaValue, setReCaptchaValue] = useState('');
  const reCaptchaRef = useRef(null);

  const reflectName = (name) => {
    const result = validateContactName(name);
    result.isValid ? setNameError('') : setNameError(result.message);
    setName(name);
  };
  const reflectEmail = (email) => {
    const result = validateEmail(email);
    result.isValid ? setEmailError('') : setEmailError(result.message);
    setEmail(email);
  };
  const reflectMessage = (message) => {
    const result = validateContactMessage(message);
    result.isValid ? setMessageError('') : setMessageError(result.message);
    setMessage(message);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(loadStart());
    try {
      await publicRequest.post('/enquiries', {
        name,
        email,
        message,
        token: reCaptchaValue,
      });
      setName('');
      setEmail('');
      setMessage('');
      setReCaptchaValue('');
      if (reCaptchaRef.current) {
        reCaptchaRef.current.reset();
      }
      successToast(t('message.success.t20'));
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(loadEnd());
    }
  };

  const onChange = (value) => {
    setReCaptchaValue(value);
  };

  return (
    <>
      <div className='mx-auto max-w-[25.875rem] px-4 pb-44 pt-10 sm:px-0'>
        <h1 className='text-3xl font-extrabold'>
          <Trans i18nKey={'contact.ttl'} />
        </h1>
        <div className='mt-8 flex flex-col items-start gap-6'>
          {/* Name */}
          <label className='form-control w-full'>
            <div className='label'>
              <span className='label-text'>
                <Trans i18nKey={'contact.name'} />*
              </span>
            </div>
            <input
              type='text'
              value={name}
              placeholder={t('placeholder.contact-name')}
              maxLength='300'
              className={`input input-bordered w-full text-sm placeholder:text-sm ${
                nameError ? 'input-error' : ''
              }`}
              onChange={(e) => reflectName(e.target.value)}
            />
            <div className='label'>
              <span className='label-text-alt text-error'>{nameError}</span>
            </div>
          </label>
          {/* Email */}
          <label className='form-control w-full'>
            <div className='label'>
              <span className='label-text'>
                <Trans i18nKey={'contact.email'} />*
              </span>
            </div>
            <input
              type='email'
              maxLength='255'
              value={email}
              placeholder={'name@mail.com'}
              className={`input input-bordered w-full text-sm placeholder:text-sm ${
                emailError ? 'input-error' : ''
              }`}
              onChange={(e) => reflectEmail(e.target.value)}
            />
            <div className='label'>
              <span className='label-text-alt text-error'>{emailError}</span>
            </div>
          </label>
          {/* Message */}
          <label className='form-control w-full'>
            <div className='label'>
              <span className='label-text'>
                <Trans i18nKey={'contact.message'} />*
              </span>
            </div>
            <textarea
              className={`textarea textarea-bordered text-sm placeholder:text-sm ${
                messageError ? 'textarea-error' : ''
              }`}
              placeholder={t('placeholder.type-here')}
              onChange={(e) => {
                reflectMessage(e.target.value);
              }}
              value={message}
              rows={6}
            ></textarea>
            <div className='label'>
              <span className='label-text-alt text-error'>{messageError}</span>
            </div>
          </label>
          <ReCAPTCHA
            ref={reCaptchaRef}
            sitekey={`${process.env.REACT_APP_SITE_KEY}`}
            onChange={onChange}
          />
          <button
            className='btn btn-primary'
            onClick={handleSubmit}
            disabled={
              !name ||
              !email ||
              !message ||
              nameError ||
              emailError ||
              messageError ||
              !reCaptchaValue
            }
          >
            <Trans i18nKey={'btn.submit'} />
          </button>
        </div>
      </div>
      {isLoading && <LoadingSpinner />}
    </>
  );
};

export default Contact;
