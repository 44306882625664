import React, { useContext, useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { ExhibitionContext } from '../../../context/ExhibitionContext';
import {
  CYBERPUNK_ATTRIBUTIONS,
  FRAME_ATTRIBUTIONS,
  LIGHTS_ATTRIBUTIONS,
  SPACE_ATTRIBUTIONS,
} from '../../../data/attributions';
import { ExhibitionControllerContext } from '../../../context/ExhibitionControllerContext';

const AttributionModal = ({ attributions }) => {
  const { setShowAttribution } = useContext(ExhibitionControllerContext);

  useEffect(() => {
    const modal = document.getElementById('attribution_modal');

    // モーダルの属性を監視
    const observer = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (
          mutation.type === 'attributes' &&
          mutation.attributeName === 'open'
        ) {
          if (modal.hasAttribute('open')) {
            setShowAttribution(true);
          } else {
            setShowAttribution(false);
          }
        }
      }
    });

    // 監視の開始
    observer.observe(modal, { attributes: true });

    // クリーンアップ関数で監視を停止
    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <dialog id='attribution_modal' className='modal'>
      <div className='modal-box'>
        <h3 className='text-lg font-bold'>Attribution</h3>
        <ul className='list-outside list-disc py-4 pl-4'>
          {attributions?.map((item, index) => (
            <li key={`attribution-${index}`}>
              "
              <a href={item.url} target='_blank' className='underline'>
                {item.title}
              </a>
              " © {item.author} (Licensed under{' '}
              <a href={item.lisenceUrl} target='_blank' className='underline'>
                CC BY 4.0
              </a>
              )
            </li>
          ))}
        </ul>
        <div className='modal-action'>
          <form method='dialog'>
            {/* if there is a button in form, it will close the modal */}
            <button className='btn'>
              <Trans i18nKey={'btn.close'} />
            </button>
          </form>
        </div>
      </div>
    </dialog>
  );
};

export default AttributionModal;
