import { Link } from 'react-router-dom';
import { useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';

import { errorToast, successToast } from '../../../utils/toast';
import LoadingSpinner from '../../../components/LoadingSpinner';
import { deleteFaqById, getAllPublishedFaqs } from '../../../services/Admin';
import { formatDateMMMDYYYYTIME } from '../../../utils/DateUtils';
import { TrashIcon } from '@heroicons/react/24/outline';
import DeleteFaqModal from './components/DeleteFaqModal';

export const AdminFaqList = () => {
  const {
    data: faqs,
    isLoading: isFaqsLoading,
    refetch,
  } = useQuery({
    queryFn: () => getAllPublishedFaqs(),
    queryKey: ['faqs'],
  });
  const [selectedId, setSelectedId] = useState();

  const openDeleteModal = (id) => {
    setSelectedId(id);
    document.getElementById('delete_faq_modal').showModal();
  };

  // delete処理
  const deleteUserMutation = useMutation({
    mutationFn: async () => {
      return await deleteFaqById(Number(selectedId));
    },
    onSuccess: (data) => {
      refetch();

      document.getElementById('delete_faq_modal').close();

      successToast('FAQ successfully deleted.');
    },
    onError: (err) => {
      console.error(err);
      if (err.response.data.statusCode == 403) {
        errorToast(err?.response?.data.message);
      } else {
        errorToast(
          'Something went wrong. We are aware of the problem and will try to fix it as soon as possible. Sorry for the inconvenience.'
        );
      }
    },
  });

  const handleDelete = () => {
    deleteUserMutation.mutate();
  };

  return (
    <main className='container m-auto flex-auto'>
      <div className='mb-10 h-full w-full py-10'>
        <h1 className='mb-2 text-4xl font-bold'>FAQs</h1>
        <Link to={'/admin/faqs-create'} className='btn btn-primary my-6'>
          Create an FAQ
        </Link>
        <div className='overflow-x-auto'>
          <table className='table'>
            {/* head */}
            <thead>
              <tr>
                <th>Id</th>
                <th>Order</th>
                <th>Question</th>
                <th>Last Updated</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            {/* body */}
            <tbody>
              {faqs?.map((faq) => {
                return (
                  <tr key={faq.id}>
                    <th>{faq.id}</th>
                    <th>{faq.order}</th>
                    <th className='font-normal'>
                      {faq.question ? faq.question : faq.questionJa}
                    </th>
                    <th className='font-normal'>
                      {formatDateMMMDYYYYTIME(faq.updatedAt)}
                    </th>

                    <th>
                      <Link to={`/admin/faqs/${faq.id}`} className='btn btn-sm'>
                        View details
                      </Link>
                    </th>
                    <th>
                      <button
                        onClick={() => openDeleteModal(faq.id)}
                        className='btn btn-outline btn-error btn-sm flex items-center gap-1'
                      >
                        <TrashIcon className='h-5 w-5 shrink-0' />
                        delete
                      </button>
                    </th>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      {isFaqsLoading && <LoadingSpinner />}

      <DeleteFaqModal handleDelete={handleDelete} />
    </main>
  );
};
