import React, { useEffect, useState } from 'react';
import { TrashIcon } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';
import { authRequest } from '../../../requestMethods';
import dayjs from 'dayjs';
import DeleteModal from '../components/DeleteModal';
import { loadEnd, loadStart } from '../../../redux/loadRedux';
import { successToast } from '../../../utils/toast';
import { useDispatch } from 'react-redux';

const CouponCodeList = () => {
  const dispatch = useDispatch();

  const [coupons, setCoupons] = useState([]);
  const [selectedCoupon, setSelectedCoupon] = useState(null);

  const getCoupons = async () => {
    try {
      const res = await authRequest.get('/admin/coupons');
      setCoupons(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getCoupons();
  }, []);

  const handleDeleteCoupon = async (coupon) => {
    dispatch(loadStart());
    try {
      await authRequest.patch(`/admin/coupons/${Number(coupon.id)}`, {
        ...coupon,
        isActive: false,
      });
      getCoupons();
      successToast('Coupon deleted successfully!');
      document.getElementById('delete_modal').close();
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(loadEnd());
    }
  };

  return (
    <>
      <h2 className='text-3xl font-extrabold'>Coupon Codes</h2>
      <Link to={'/admin/coupon-code-create'} className='btn btn-primary mt-8'>
        Create coupon code
      </Link>
      {/* table */}
      <div className='mt-10 overflow-x-auto'>
        <table className='table'>
          {/* head */}
          <thead>
            <tr>
              <th>Id</th>
              <th>Title</th>
              <th>Code</th>
              <th>Expiration Date</th>
              <th>Duration</th>
              <th>Usage Status</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {coupons.map((coupon, index) => (
              <tr key={`coupon-${coupon.id}`}>
                <th>{coupon.id}</th>
                <td>{coupon.title}</td>
                <td>{coupon.code}</td>
                <td>{dayjs(coupon.expiredAt).format('MMM DD, YYYY')}</td>
                <td>{coupon.duration} days</td>
                <td>{coupon.isUsed ? 'Used' : 'Not Used'}</td>
                <td>
                  <Link
                    to={`/admin/coupon-code-edit/${coupon.id}`}
                    disabled={coupon.isUsed}
                    className={`btn btn-sm`}
                  >
                    Edit
                  </Link>
                </td>
                <td>
                  <button
                    disabled={coupon.isUsed}
                    className={`btn btn-sm ${
                      coupon.isUsed ? '' : 'btn-outline btn-error'
                    }`}
                    onClick={() => {
                      setSelectedCoupon(coupon);
                      document.getElementById('delete_modal').showModal();
                    }}
                  >
                    <TrashIcon className='h-5 w-5' />
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <DeleteModal
        itemName='coupon'
        confirmDelete={() => {
          handleDeleteCoupon(selectedCoupon);
        }}
      />
    </>
  );
};

export default CouponCodeList;
