import { useThree } from '@react-three/fiber';
import React, { useEffect } from 'react';

const SimulatorCamera = () => {
  const { camera } = useThree();

  // Log camera position every frame
  // useFrame(() => {
  //   console.log(camera);
  //   console.log(
  //     `Camera position: [${camera.position.x}, ${camera.position.y}, ${camera.position.z}]`
  //   );

  //   const direction = new THREE.Vector3();
  //   camera.getWorldDirection(direction);

  //   // Log the lookAt direction
  //   console.log(
  //     `Camera lookAt direction: [${direction.x}, ${direction.y}, ${direction.z}]`
  //   );
  // }, []);

  useEffect(() => {
    camera.lookAt(0, 1.4, 1.1);
    // camera.lookAt(0, 0.8, 1.1);
  }, [camera]);
  return null;
};

export default SimulatorCamera;
