import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  signupFailure,
  signupStart,
  signupSuccess,
} from '../../../redux/userRedux';
import { publicRequest } from '../../../requestMethods';
import { MinusIcon, CheckIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';
import LoadingSpinner from '../../../components/LoadingSpinner';
import { loadEnd, loadStart } from '../../../redux/loadRedux';
import { validateEmail } from '../../../utils/validation';
import { errorToast } from '../../../utils/toast';
import { useTranslation, Trans } from 'react-i18next';

const Signup = () => {
  const {
    // 展示会上限値
    REACT_APP_MAX_EXHIBITION_MEMBER: MAX_EXHIBITION_MEMBER,
    REACT_APP_MAX_EXHIBITION_PREMIUM: MAX_EXHIBITION_PREMIUM,
    REACT_APP_MAX_EXHIBITION_VIP: MAX_EXHIBITION_VIP,
    REACT_APP_MAX_PUBLISHED_EXHIBITION_MEMBER: MAX_PUBLISHED_EXHIBITION_MEMBER,
    REACT_APP_MAX_PUBLISHED_EXHIBITION_PREMIUM:
      MAX_PUBLISHED_EXHIBITION_PREMIUM,
    REACT_APP_MAX_PUBLISHED_EXHIBITION_VIP: MAX_PUBLISHED_EXHIBITION_VIP,

    // アートワーク上限値
    REACT_APP_MAX_UPLOAD_ARTWORK_MEMBER: MAX_UPLOAD_MEMBER,
    REACT_APP_MAX_UPLOAD_ARTWORK_PREMIUM: MAX_UPLOAD_PREMIUM,
    REACT_APP_MAX_UPLOAD_ARTWORK_VIP: MAX_UPLOAD_VIP,

    // 各種素材上限値
    REACT_APP_MAX_FRAME_MEMBER: MAX_FRAME_MEMBER,
    REACT_APP_MAX_FRAME_PREMIUM: MAX_FRAME_PREMIUM,
    REACT_APP_MAX_FRAME_VIP: MAX_FRAME_VIP,

    REACT_APP_MAX_WALL_MEMBER: MAX_WALL_MEMBER,
    REACT_APP_MAX_WALL_PREMIUM: MAX_WALL_PREMIUM,
    REACT_APP_MAX_WALL_VIP: MAX_WALL_VIP,

    REACT_APP_MAX_FLOOR_MEMBER: MAX_FLOOR_MEMBER,
    REACT_APP_MAX_FLOOR_PREMIUM: MAX_FLOOR_PREMIUM,
    REACT_APP_MAX_FLOOR_VIP: MAX_FLOOR_VIP,

    REACT_APP_MAX_CEILING_MEMBER: MAX_CEILING_MEMBER,
    REACT_APP_MAX_CEILING_PREMIUM: MAX_CEILING_PREMIUM,
    REACT_APP_MAX_CEILING_VIP: MAX_CEILING_VIP,

    REACT_APP_MAX_MUSIC_MEMBER: MAX_MUSIC_MEMBER,
    REACT_APP_MAX_MUSIC_PREMIUM: MAX_MUSIC_PREMIUM,
    REACT_APP_MAX_MUSIC_VIP: MAX_MUSIC_VIP,

    REACT_APP_MAX_SILHOUETTE_MEMBER: MAX_SILHOUETTE_MEMBER,
    REACT_APP_MAX_SILHOUETTE_PREMIUM: MAX_SILHOUETTE_PREMIUM,
    REACT_APP_MAX_SILHOUETTE_VIP: MAX_SILHOUETTE_VIP,

    REACT_APP_MAX_VIDEO_MEMBER: MAX_VIDEO_MEMBER,
    REACT_APP_MAX_VIDEO_PREMIUM: MAX_VIDEO_PREMIUM,
    REACT_APP_MAX_VIDEO_VIP: MAX_VIDEO_VIP,
  } = process.env;
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [success, setSuccess] = useState(false);
  const [hasPasswordTyped, setHasPasswordTyped] = useState(false);
  const [uppercaseValid, setUppercaseValid] = useState(false);
  const [lowercaseValid, setLowercaseValid] = useState(false);
  const [numericValid, setNumericValid] = useState(false);
  const [charValid, setCharValid] = useState(false);
  const [lengthValid, setLengthValid] = useState(false);
  const [passwordValid, setPasswordValid] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [hasTriedSignUp, setHasTriedSignUp] = useState(false);

  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.isLoading);
  const { t, i18n } = useTranslation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setHasTriedSignUp(true);
    if (!isConfirmed || !passwordValid || email === '') {
      return;
    }
    setEmailError('');
    dispatch(loadStart());
    dispatch(signupStart());
    try {
      await publicRequest.post(`/auth/signup`, {
        email,
        password,
        language: i18n.language,
      });
      dispatch(loadEnd());
      dispatch(signupSuccess());
      setSuccess(true);
    } catch (err) {
      console.log(err);
      const message = err.response.data.message;
      if (message.includes('email')) {
        setEmailError(message);
      } else {
        errorToast(message);
      }
      dispatch(signupFailure());
      dispatch(loadEnd());
    }
  };

  const reflectEmail = (email) => {
    const result = validateEmail(email);
    result.isValid ? setEmailError('') : setEmailError(result.message);
    setEmail(email);
  };

  const passwordValidation = (password) => {
    const lowerCaseLetters = /[a-z]/g;
    const upperCaseLetters = /[A-Z]/g;

    const numbers = /[0-9]/g;
    const characters = /[-!$%^&*()_+|~=`{}\[\]:\/;<>?,.@#]/g;

    if (password.match(lowerCaseLetters)) {
      setLowercaseValid(true);
    } else {
      setLowercaseValid(false);
    }

    if (password.match(upperCaseLetters)) {
      setUppercaseValid(true);
    } else {
      setUppercaseValid(false);
    }
    if (password.match(numbers)) {
      setNumericValid(true);
    } else {
      setNumericValid(false);
    }
    if (password.match(characters)) {
      setCharValid(true);
    } else {
      setCharValid(false);
    }

    if (password.length >= 8 && password.length <= 20) {
      setLengthValid(true);
    } else {
      setLengthValid(false);
    }
  };

  useEffect(() => {
    if (
      uppercaseValid &&
      lowercaseValid &&
      numericValid &&
      lengthValid &&
      charValid
    ) {
      setPasswordValid(true);
    } else {
      setPasswordValid(false);
    }
  }, [uppercaseValid, lowercaseValid, numericValid, lengthValid, charValid]);

  return (
    <div className='relative mx-auto h-full pb-20 pt-10 md:flex md:p-0'>
      <div className='flex w-full flex-col items-start md:w-1/2 md:pb-20 md:pt-16'>
        <div className='mx-auto w-full max-w-[30rem]'>
          {success ? (
            <div className='mt-8'>
              <p className='text-3xl font-extrabold'>
                <Trans i18nKey={'signup.top.success'} />
              </p>
              <div className='mt-8 flex justify-center'>
                <img
                  src='/assets/img/message-sent.png'
                  className='h-60 w-60'
                  alt=''
                />
              </div>
              <p className='text-center'>
                <Trans i18nKey={'signup.top.message'} />
              </p>
            </div>
          ) : (
            <>
              <form autoComplete='off' onSubmit={handleSubmit}>
                <p className='px-4 pb-6 text-left text-sm lg:px-0'>
                  <Trans
                    i18nKey={'signup.top.has-account'}
                    components={{
                      link1: <Link to='/signin' className='underline'></Link>,
                    }}
                  />
                </p>
                <h1 className='px-4 pb-8 text-3xl font-extrabold lg:px-0'>
                  <Trans i18nKey={'signup.top.ttl'} />
                </h1>
                <p className='px-4 lg:px-0'>
                  <Trans i18nKey={'signup.top.sub-ttl'} />
                </p>
                <div className='bg-custom-gradient-purple mt-8 flex w-full flex-col gap-[0.625rem] px-4 py-6 md:hidden'>
                  <p className='text-xl font-black text-purple-700'>
                    <Trans i18nKey={'signup.top.ttl-2'} />
                  </p>
                  <p className='text-sm text-purple-700'>
                    <Trans i18nKey={'signup.top.desc'} />
                  </p>
                  <div
                    tabIndex={0}
                    className='collapse collapse-arrow border border-accent bg-emerald-50'
                  >
                    <div className='collapse-title flex items-center text-sm font-bold'>
                      <Trans i18nKey={'signup.top.what-you-get'} />
                    </div>
                    <div className='collapse-content'>
                      <div className='flex flex-col justify-between gap-4'>
                        <div>
                          <p className='text-xl font-bold'>
                            <Trans i18nKey={'price-card.member'} />
                          </p>
                          <p className='text-xs font-bold text-zinc-600'>
                            <Trans i18nKey={'price-card.for-artists'} />
                          </p>
                        </div>
                        <div>
                          <p className='text-3xl font-[900]'>
                            <Trans i18nKey={'price-card.free'} />
                          </p>
                        </div>
                      </div>
                      <div className='mt-6 flex flex-col gap-1'>
                        <div className='flex items-start gap-2 text-base-content'>
                          <CheckIcon className='h-5 w-5 shrink-0' />
                          <p className='text-sm'>
                            {t('price-card.published-variable', {
                              max: MAX_PUBLISHED_EXHIBITION_MEMBER,
                            })}
                          </p>
                        </div>
                        <div className='flex items-start gap-2 text-base-content'>
                          <CheckIcon className='h-5 w-5 shrink-0' />
                          <p className='text-sm'>
                            {t('price-card.create-save-variable', {
                              max: MAX_EXHIBITION_MEMBER,
                            })}
                          </p>
                        </div>
                        <div className='flex items-start gap-2 text-base-content'>
                          <CheckIcon className='h-5 w-5 shrink-0' />
                          <p className='text-sm'>
                            {t('price-card.upload-variable', {
                              max: MAX_UPLOAD_MEMBER,
                            })}
                          </p>
                        </div>
                        <div className='text-base-content'>
                          <div className='flex items-start gap-2 '>
                            <CheckIcon className='h-5 w-5 shrink-0' />
                            <p className='text-sm'>
                              <Trans i18nKey={'price-card.choose-from'} />
                            </p>
                          </div>
                          <ul className='list-inside list-disc pl-7 text-sm'>
                            <li>
                              {t('price-card.frame-variable', {
                                max: MAX_FRAME_MEMBER,
                              })}
                            </li>
                            <li>
                              {t('price-card.wall-variable', {
                                max: MAX_WALL_MEMBER,
                              })}
                            </li>
                            <li>
                              {t('price-card.floor-variable', {
                                max: MAX_FLOOR_MEMBER,
                              })}
                            </li>
                            <li>
                              {t('price-card.ceiling-variable', {
                                max: MAX_CEILING_MEMBER,
                              })}
                            </li>
                            <li>
                              {t('price-card.music-variable', {
                                max: MAX_MUSIC_MEMBER,
                              })}
                            </li>
                            <li>
                              {t('price-card.silhouette-variable', {
                                max: MAX_SILHOUETTE_MEMBER,
                              })}
                            </li>
                            <li>
                              {t('price-card.video-variable', {
                                max: MAX_VIDEO_MEMBER,
                              })}
                            </li>
                          </ul>
                        </div>
                        <div className='flex items-start gap-2 text-base-content'>
                          <CheckIcon className='h-5 w-5 shrink-0' />
                          <p className='text-sm'>
                            <Trans i18nKey={'price-card.basic-analytics'} />
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='mt-8 flex flex-col gap-6 px-4 lg:px-0'>
                  <label className='form-control w-full'>
                    <div className='label'>
                      <span className='label-text font-medium'>
                        <Trans i18nKey={'signup.top.email'} />
                      </span>
                    </div>
                    <input
                      className={`input input-bordered w-full ${
                        emailError && 'input-error'
                      }`}
                      maxLength='320'
                      type='email'
                      placeholder='Email'
                      onChange={(e) => reflectEmail(e.target.value)}
                      required
                    />
                    {!!emailError && (
                      <div className='label'>
                        <span className='label-text-alt text-error'>
                          {emailError}
                        </span>
                      </div>
                    )}
                  </label>

                  <label className='form-control w-full'>
                    <div className='label'>
                      <span className='label-text font-medium'>
                        <Trans i18nKey={'signup.top.password'} />
                      </span>
                    </div>
                    <div className='relative'>
                      <input
                        className={`input input-bordered w-full ${
                          !hasPasswordTyped
                            ? ''
                            : !passwordValid && hasTriedSignUp && 'input-error'
                        }`}
                        maxLength='20'
                        type={`${showPassword ? 'text' : 'password'}`}
                        placeholder='Password'
                        onChange={(e) => {
                          if (!hasPasswordTyped) {
                            setHasPasswordTyped(true);
                          }
                          setPassword(e.target.value);
                        }}
                        onKeyUp={(e) => passwordValidation(e.target.value)}
                        required
                        autoComplete='new-password'
                      />
                      <span
                        className='absolute right-3 top-1/2 -translate-y-2/4 cursor-pointer text-sm'
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        Show
                      </span>
                    </div>
                  </label>
                  {hasTriedSignUp && (
                    <div className='flex flex-col gap-2'>
                      <div
                        className={`flex items-center gap-2 text-xs ${
                          !hasPasswordTyped
                            ? ''
                            : lowercaseValid
                            ? 'text-success'
                            : 'text-error'
                        }`}
                      >
                        <MinusIcon className={`h-4 w-4`} />
                        <Trans i18nKey={'signup.top.lowercase'} />
                      </div>
                      <div
                        className={`flex items-center gap-2 text-xs ${
                          !hasPasswordTyped
                            ? ''
                            : uppercaseValid
                            ? 'text-success'
                            : 'text-error'
                        }`}
                      >
                        <MinusIcon className={`h-4 w-4`} />
                        <Trans i18nKey={'signup.top.uppercase'} />
                      </div>
                      <div
                        className={`flex items-center gap-2 text-xs ${
                          !hasPasswordTyped
                            ? ''
                            : numericValid
                            ? 'text-success'
                            : 'text-error'
                        }`}
                      >
                        <MinusIcon className={`h-4 w-4`} />
                        <Trans i18nKey={'signup.top.number'} />
                      </div>
                      <div
                        className={`flex items-center gap-2 text-xs ${
                          !hasPasswordTyped
                            ? ''
                            : charValid
                            ? 'text-success'
                            : 'text-error'
                        }`}
                      >
                        <MinusIcon className={`h-4 w-4`} />
                        <Trans i18nKey={'signup.top.special-character'} />
                      </div>
                      <div
                        className={`flex items-center gap-2 text-xs ${
                          !hasPasswordTyped
                            ? ''
                            : lengthValid
                            ? 'text-success'
                            : 'text-error'
                        }`}
                      >
                        <MinusIcon className={`h-4 w-4`} />
                        <Trans i18nKey={'signup.top.8-20-letters'} />
                      </div>
                    </div>
                  )}
                </div>
                <div className='form-control mt-8 px-4 lg:px-0'>
                  <label className='label flex cursor-pointer items-start gap-3'>
                    <input
                      type='checkbox'
                      className='checkbox-primary checkbox'
                      onClick={() => setIsConfirmed(!isConfirmed)}
                    />
                    <span className='label-text text-xs font-medium'>
                      <Trans
                        i18nKey={'signup.top.term'}
                        components={{
                          link1: (
                            <Link
                              className='link-hover link underline'
                              to='/terms-privacy'
                              target='_blank'
                              rel='noopener noreferrer'
                            ></Link>
                          ),
                          link2: (
                            <Link
                              className='link-hover link underline'
                              to='/cookie-policy'
                              target='_blank'
                              rel='noopener noreferrer'
                            ></Link>
                          ),
                        }}
                      />
                    </span>
                  </label>
                </div>

                <div className='mt-6 flex justify-center'>
                  <button
                    type='submit'
                    className={`btn btn-primary btn-wide`}
                    disabled={!isConfirmed || !!emailError}
                  >
                    <Trans i18nKey={'signup.top.ttl'} />
                  </button>
                </div>
              </form>
            </>
          )}
        </div>

        {isLoading && (
          <>
            <div className='md:invisible'>
              <LoadingSpinner isHalf={false} />
            </div>
            <div className='hidden md:block'>
              <LoadingSpinner isHalf={true} />
            </div>
          </>
        )}
      </div>
      <div
        className='hidden h-full w-1/2 items-center justify-center bg-cover bg-no-repeat md:flex'
        style={{
          backgroundImage: 'url("/assets/img/signin_image.png")',
        }}
      >
        <div className='flex w-[30rem] flex-col gap-6 px-4 lg:px-0'>
          <h2 className='text-[3.75rem] font-black leading-[3.75rem] text-white'>
            <Trans i18nKey={'signup.top.ttl-2'} />
          </h2>
          <p className='text-2xl font-medium text-white'>
            <Trans i18nKey={'signup.top.desc'} />
          </p>
          {/* CAS Member */}
          <div className='relative flex-1 rounded-2xl border-[1.5px] border-accent bg-[#ECFDF5] p-8'>
            <div className='badge badge-accent absolute -top-3 left-1/2 -translate-x-1/2 p-3 text-base font-black'>
              <Trans i18nKey={'signup.top.what-you-get'} />
            </div>
            <div className='flex flex-col justify-between gap-4'>
              <div>
                <p className='text-xl font-bold'>
                  <Trans i18nKey={'price-card.member'} />
                </p>
                <p className='text-xs font-bold text-zinc-600'>
                  <Trans i18nKey={'price-card.for-artists'} />
                </p>
              </div>
              <div>
                <p className='text-3xl font-[900]'>
                  <Trans i18nKey={'price-card.free'} />
                </p>
              </div>
            </div>
            <div className='mt-6 flex flex-col gap-1'>
              <div className='flex items-start gap-2 text-base-content'>
                <CheckIcon className='h-5 w-5 shrink-0' />
                <p className='text-sm'>
                  {t('price-card.published-variable', {
                    max: MAX_PUBLISHED_EXHIBITION_MEMBER,
                  })}
                </p>
              </div>
              <div className='flex items-start gap-2 text-base-content'>
                <CheckIcon className='h-5 w-5 shrink-0' />
                <p className='text-sm'>
                  {t('price-card.create-save-variable', {
                    max: MAX_EXHIBITION_MEMBER,
                  })}
                </p>
              </div>
              <div className='flex items-start gap-2 text-base-content'>
                <CheckIcon className='h-5 w-5 shrink-0' />
                <p className='text-sm'>
                  {t('price-card.upload-variable', {
                    max: MAX_UPLOAD_MEMBER,
                  })}
                </p>
              </div>

              <div className='text-base-content'>
                <div className='mb-1 flex items-start gap-2'>
                  <CheckIcon className='h-5 w-5 shrink-0' />
                  <p className='text-sm'>
                    <Trans i18nKey={'price-card.choose-from'} />
                  </p>
                </div>
                <ul className='list-inside list-disc pl-7 text-sm'>
                  <li>
                    {t('price-card.frame-variable', {
                      max: MAX_FRAME_MEMBER,
                    })}
                  </li>
                  <li>
                    {t('price-card.wall-variable', {
                      max: MAX_WALL_MEMBER,
                    })}
                  </li>
                  <li>
                    {t('price-card.floor-variable', {
                      max: MAX_FLOOR_MEMBER,
                    })}
                  </li>
                  <li>
                    {t('price-card.ceiling-variable', {
                      max: MAX_CEILING_MEMBER,
                    })}
                  </li>
                  <li>
                    {t('price-card.music-variable', {
                      max: MAX_MUSIC_MEMBER,
                    })}
                  </li>
                  <li>
                    {t('price-card.silhouette-variable', {
                      max: MAX_SILHOUETTE_MEMBER,
                    })}
                  </li>
                  <li>
                    {t('price-card.video-variable', {
                      max: MAX_VIDEO_MEMBER,
                    })}
                  </li>
                </ul>
              </div>
              <div className='flex items-start gap-2 text-base-content'>
                <CheckIcon className='h-5 w-5 shrink-0' />
                <p className='text-sm'>
                  <Trans i18nKey={'price-card.basic-analytics'} />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
