import React, { useEffect, useRef, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

const ExhibitionPasswordModal = ({ isShow, onSubmit, returnUrl }) => {
  const { t } = useTranslation();
  const [password, setPassword] = useState('');
  const btnRef = useRef(null);
  const hasClickedRef = useRef(false);

  useEffect(() => {
    if (btnRef.current && isShow && !hasClickedRef.current) {
      btnRef.current?.click();
      hasClickedRef.current = true;
    }
  }, [isShow]);

  return (
    <>
      <label
        htmlFor='password-modal'
        className='hidden'
        id='model-btn'
        ref={btnRef}
      ></label>
      <input type='checkbox' id='password-modal' className='modal-toggle' />
      <div className='modal' role='dialog'>
        <div className='modal-box max-w-lg'>
          <p className='mb-6 text-lg font-bold'>
            <Trans i18nKey={'exhibition-password-modal.ttl'} />
          </p>

          <label className='form-control w-full'>
            <div className='label'>
              <span className='label-text text-sm font-medium'>
                <Trans i18nKey={'exhibition-password-modal.label'} />
              </span>
            </div>
            <input
              type='password'
              placeholder={t('placeholder.type-here')}
              className={`input input-bordered w-full`}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </label>
          <div className='mt-6 flex justify-end gap-2'>
            <Link className='btn' to={returnUrl}>
              <Trans i18nKey={'btn.cancel'} />
            </Link>
            <button
              className='btn btn-primary'
              onClick={() => onSubmit(password)}
              disabled={!password}
            >
              <Trans i18nKey={'btn.enter'} />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ExhibitionPasswordModal;
