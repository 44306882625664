import React, { useEffect, useState } from 'react';
import { authRequest } from '../../../requestMethods';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/en'; // ロケールを必要に応じてインポート
import UserSuspendConfirmModal from '../../../components/UserSuspendConfirmModal';
import { useDispatch } from 'react-redux';
import { loadEnd, loadStart } from '../../../redux/loadRedux';
import { errorToast, successToast } from '../../../utils/toast';
import { AdjustmentsHorizontalIcon } from '@heroicons/react/24/outline';
import DeleteModal from '../components/DeleteModal';
import { useNavigate } from 'react-router-dom';
import UserUnArchiveConfirmModal from '../../../components/UserUnarchiveConfirmModal';
import { fetchActiveUsers } from '../../../services/Admin';
import { useQuery } from '@tanstack/react-query';
import {
  ChevronDownIcon,
  ChevronUpIcon,
  ChevronUpDownIcon,
} from '@heroicons/react/24/outline';

dayjs.extend(relativeTime);

const UserList = () => {
  const {
    //product ID
    REACT_APP_STRIPE_PREMIUM_CAS_MEMBER_PRODUCT_ID: PREMIUM_ID,
    REACT_APP_STRIPE_VIP_CAS_MEMBER_PRODUCT_ID: VIP_ID,
  } = process.env;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [deleteId, setDeleteId] = useState(null);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [filteredUsers, setFilteredUsers] = useState();
  const [lobbyTitleSort, setLobbyTitleSort] = useState('');
  const [registrationDateSort, setRegistrationDateSort] = useState('');
  const statusSelectList = [
    {
      label: 'Subscription Status',
      value: '',
    },
    {
      label: 'FREE',
      value: 'FREE',
    },
    {
      label: 'PREMIUM',
      value: 'PREMIUM',
    },
    {
      label: 'VIP',
      value: 'VIP',
    },
  ];

  const {
    data: activeUsers,
    isLoading: isActiveUsersLoading,
    refetch: refetchActiveUsers,
  } = useQuery({
    queryKey: ['fetch-active-users', selectedStatus],
    queryFn: () => fetchActiveUsers(selectedStatus),
    retry: (failureCount, error) => {
      if (error.response?.status === 401 || error.response?.status === 403) {
        return false; // 再認証が必要な場合はリトライしない
      }
    },
    meta: { errCode: 'ADMIN_FETCH_ACTIVE_USERS' },
  });

  const handleSuspend = async () => {
    dispatch(loadStart());
    try {
      await authRequest.post(`/admin/suspend/${selectedUser?.id}`);
      document.getElementById('suspend_confirm_modal').close();
      successToast('User archived successfully!');
      refetchActiveUsers();
    } catch (err) {
      console.log(err);
      if (
        err.response.data.statusCode == 401 ||
        err.response.data.statusCode == 403
      ) {
        navigate('/admin/login');
      }
    } finally {
      dispatch(loadEnd());
    }
  };

  const handleUnarchive = async () => {
    dispatch(loadStart());
    try {
      await authRequest.post(`/admin/unsuspend/${selectedUser?.id}`);
      document.getElementById('unarchive_confirm_modal').close();
      successToast('User unarchived successfully!');
      refetchActiveUsers();
    } catch (err) {
      console.log(err);
      if (
        err.response.data.statusCode == 401 ||
        err.response.data.statusCode == 403
      ) {
        navigate('/admin/login');
      } else if (
        err.response.data.message ==
        'An active user with the same email address already exists.'
      ) {
        errorToast(
          'An active user with the same email address already exists.'
        );
      }
    } finally {
      dispatch(loadEnd());
    }
  };

  const getNextSort = (sort) => {
    if (sort === '') {
      return 'asc';
    }
    if (sort === 'asc') {
      return 'desc';
    }
    return '';
  };

  const handleSortByLobbyTitle = () => {
    const nextSort = getNextSort(lobbyTitleSort);
    setLobbyTitleSort(nextSort);
    if (nextSort === '') {
      return setFilteredUsers(activeUsers);
    }
    const sorted =
      nextSort === 'desc'
        ? filteredUsers.toSorted((a, b) =>
            a.displayName.toLowerCase() < b.displayName.toLowerCase() ? -1 : 1
          )
        : filteredUsers.toSorted((a, b) =>
            a.displayName.toLowerCase() < b.displayName.toLowerCase() ? 1 : -1
          );
    setFilteredUsers(sorted);
  };

  const handleSortByRegistrationDate = () => {
    const nextSort = getNextSort(registrationDateSort);
    setRegistrationDateSort(nextSort);
    if (nextSort === '') {
      return setFilteredUsers(activeUsers);
    }
    const sorted =
      nextSort === 'desc'
        ? filteredUsers.toSorted((a, b) => (a.createdAt < b.createdAt ? -1 : 1))
        : filteredUsers.toSorted((a, b) =>
            a.createdAt < b.createdAt ? 1 : -1
          );
    setFilteredUsers(sorted);
  };

  // TODO: User削除処理 サブスクの兼ね合いがあり保留
  // const handleDelete = async () => {
  //   console.log(deleteId);

  // };

  const getSubscriptionPlan = (userPlans) => {
    const subscriptionPlans = userPlans.map((item) => item.SubscriptionPlans);
    const plan = subscriptionPlans.find(
      (plan) => plan.productId === PREMIUM_ID || plan.productId === VIP_ID
    );

    return plan ? `${plan?.name}` : `CAS Member`;
  };

  useEffect(() => {
    if (searchKeyword) {
      const filtered = activeUsers.filter((user) =>
        user.email.toLowerCase().includes(searchKeyword)
      );
      setFilteredUsers(filtered);
    } else {
      setFilteredUsers(activeUsers);
    }
  }, [activeUsers, searchKeyword]);

  return (
    <>
      <h2 className='text-3xl font-extrabold'>Manage Users</h2>

      <label className='input input-bordered mt-8 flex w-[22.5rem] items-center gap-2'>
        <input
          type='search'
          className='grow'
          placeholder='Type email address'
          value={searchKeyword}
          onChange={(e) => setSearchKeyword(e.target.value.toLowerCase())}
        />
      </label>

      <div className='my-10 flex gap-2'>
        <div className='flex items-center gap-1'>
          <AdjustmentsHorizontalIcon className='h-6 w-6' />
          <span className='text-sm'>Filter by</span>
        </div>
        <select
          className='select select-bordered select-primary select-sm w-full max-w-xs'
          value={selectedStatus}
          onChange={(e) => setSelectedStatus(e.target.value)}
        >
          {statusSelectList.map((item) => {
            return (
              <option value={item.value} key={item.label}>
                {item.label}
              </option>
            );
          })}
        </select>
      </div>

      {/* table */}
      <div className='mt-10 overflow-x-auto'>
        {isActiveUsersLoading ? (
          <span className='loading loading-spinner text-primary'></span>
        ) : (
          <table className='table'>
            {/* head */}
            <thead>
              <tr>
                <th>Id</th>
                <th>Profile photo</th>
                <th>
                  <div
                    className='flex cursor-pointer items-center gap-[0.625rem]'
                    onClick={handleSortByLobbyTitle}
                  >
                    <span>Lobby title</span>
                    <span>
                      {lobbyTitleSort === '' ? (
                        <ChevronUpDownIcon className='h-4 w-4' />
                      ) : lobbyTitleSort === 'desc' ? (
                        <ChevronDownIcon className='h-4 w-4' />
                      ) : (
                        <ChevronUpIcon className='h-4 w-4' />
                      )}
                    </span>
                  </div>
                </th>
                <th>Email</th>
                <th>
                  <div
                    className='flex cursor-pointer items-center gap-[0.625rem]'
                    onClick={handleSortByRegistrationDate}
                  >
                    <span>Registration Date</span>
                    <span>
                      {registrationDateSort === '' ? (
                        <ChevronUpDownIcon className='h-4 w-4' />
                      ) : registrationDateSort === 'desc' ? (
                        <ChevronDownIcon className='h-4 w-4' />
                      ) : (
                        <ChevronUpIcon className='h-4 w-4' />
                      )}
                    </span>
                  </div>
                </th>
                <th>Subscription status</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {filteredUsers?.map((user) => (
                <tr key={`user-${user.id}`}>
                  <th>{user.id}</th>
                  <td>
                    <img
                      className='border-dark mr-4 h-10 w-10 flex-shrink-0 rounded-full border-2 object-cover md:h-8 md:w-8'
                      src={`${
                        user?.profileImageUrl
                          ? user?.profileImageUrl + '?w=300&q=60'
                          : '/assets/img/avatar/dummy_avatar.svg'
                      }`}
                      alt=''
                    />
                  </td>
                  <td>{user.displayName}</td>
                  <td>{user.email}</td>
                  <td>{dayjs(user.createdAt).format('MMM D, YYYY')}</td>

                  <td className=''>
                    <div className='flex gap-1'>
                      {0 < user.userPlans.length && (
                        <span>{getSubscriptionPlan(user.userPlans)}</span>
                      )}
                      {user.isActive ? (
                        <span className='badge !badge-success'>active</span>
                      ) : (
                        <span className='badge !badge-error'>inactive</span>
                      )}
                      {user.isSuspended && (
                        <span className='badge !badge-error'>archived</span>
                      )}
                    </div>
                  </td>
                  <td>
                    {user?.isSuspended ? (
                      <button
                        className={`btn btn-sm !bg-[#FFBE00] hover:opacity-70`}
                        onClick={() => {
                          setSelectedUser(user);
                          document
                            .getElementById('unarchive_confirm_modal')
                            .showModal();
                        }}
                      >
                        Unarchive
                      </button>
                    ) : (
                      <button
                        className={`btn btn-sm !bg-[#FFBE00] hover:opacity-70`}
                        onClick={() => {
                          setSelectedUser(user);
                          document
                            .getElementById('suspend_confirm_modal')
                            .showModal();
                        }}
                      >
                        Archive
                      </button>
                    )}
                  </td>
                  {/* <td>
                  <button
                    className='btn btn-error btn-outline btn-sm'
                    onClick={() => {
                      setDeleteId(user.id);
                      document.getElementById('delete_modal').showModal();
                    }}
                  >
                    <TrashIcon className='h-5 w-5' />
                    Delete
                  </button>
                </td> */}
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
      {/* <DeleteModal itemName='user' confirmDelete={handleDelete} /> */}
      <UserSuspendConfirmModal handleSuspend={handleSuspend} />
      <UserUnArchiveConfirmModal handleUnarchive={handleUnarchive} />
    </>
  );
};

export default UserList;
