import { useState, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ChevronUpDownIcon } from '@heroicons/react/24/outline';

export const ExhibitionViewsByLocation = ({
  GA4ExhibitionData,
  isLoading,
  isVIP,
}) => {
  const { t } = useTranslation();
  const [countryOptions, setCountryOptions] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [rankedData, setRankedData] = useState([]);
  const [filteredRankedData, setFilteredRankedData] = useState([]);
  const [displayLimit, setDisplayLimit] = useState(25);
  const [showRowsOptions, setShowsRowOptions] = useState([]);
  const [sort, setSort] = useState({
    exhibition: 'desc',
    country: 'desc',
    cityRegion: 'desc',
    view: 'desc',
    uniqueUser: 'desc',
  });

  useEffect(() => {
    if (GA4ExhibitionData) {
      // console.log('Exhibition Locations: ', GA4ExhibitionData);
      // 国選択を設定
      const countries = GA4ExhibitionData.map((item) => item.country);
      setCountryOptions([t('analytics.countries'), ...countries]);
      // 表示データを設定
      const flatData = [];
      GA4ExhibitionData.forEach((item) => {
        item.exhibitions.forEach((exhibition) => {
          // 都市データがない場合はRegionのみを表示
          if (exhibition.cities.length < 1 && 0 < exhibition.totalViews) {
            flatData.push({
              country: item.country,
              exhibitionTitle: exhibition.title,
              cityName: '',
              regionName: exhibition.region,
              totalViews: exhibition.totalViews,
              totalUsers: exhibition.totalUsers,
            });
          } else {
            exhibition.cities.forEach((city) => {
              // 必要に応じてフィルタリング
              if (city.totalViews > 0) {
                flatData.push({
                  country: item.country,
                  exhibitionTitle: exhibition.title,
                  cityName: city.city,
                  regionName: exhibition.region,
                  totalViews: city.totalViews,
                  totalUsers: city.totalUsers,
                });
              }
            });
          }
        });
      });
      // ビュー数でソート（降順）
      const sortedData = flatData.sort((a, b) => b.totalViews - a.totalViews);
      // ランクを割り当て
      const ranked = sortedData.map((item, index) => ({
        rank: index + 1,
        ...item,
      }));
      setRankedData(ranked);
      setFilteredRankedData(ranked);
      makeShowRowsOptions(ranked);
    }
  }, [GA4ExhibitionData]);

  // 表示数オプションを作成
  const makeShowRowsOptions = (data) => {
    const options = [
      {
        label: '25',
        value: '25',
      },
    ];
    if (25 < data.length) {
      options.push({
        label: '50',
        value: '50',
      });
    }
    if (100 < data.length) {
      options.push({
        label: '100',
        value: '100',
      });
    }
    options.push({
      label: 'All',
      value: data.length,
    });
    setShowsRowOptions(options);
  };

  const filterByCountry = () => {
    const newData =
      selectedCountry === ''
        ? rankedData
        : rankedData.filter((item) => item.country === selectedCountry);
    setFilteredRankedData(newData);
  };

  // 絞り込み
  useEffect(() => {
    filterByCountry();
  }, [selectedCountry, rankedData, t]);

  // 表示数変更時にソートをリセット
  const changeDisplayLimit = (limit) => {
    filterByCountry();
    setDisplayLimit(limit);
  };

  // Exhibitionでソート
  const sortByExhibition = () => {
    const copy = [...filteredRankedData.slice(0, displayLimit)];
    copy.sort((a, b) => {
      const exhibitionComparison =
        sort.exhibition === 'asc'
          ? a.exhibitionTitle.localeCompare(b.exhibitionTitle)
          : b.exhibitionTitle.localeCompare(a.exhibitionTitle);
      // Exhibitionが同じ場合rankで昇順ソート
      if (exhibitionComparison === 0) {
        return a.rank - b.rank;
      }
      return exhibitionComparison;
    });
    setFilteredRankedData(copy);
    setSort((prev) => ({
      ...prev,
      exhibition: prev.exhibition === 'asc' ? 'desc' : 'asc',
    }));
  };

  // Countryでソート
  const sortByCountry = () => {
    const copy = [...filteredRankedData.slice(0, displayLimit)];
    copy.sort((a, b) => {
      const countryComparison =
        sort.country === 'asc'
          ? a.country.localeCompare(b.country)
          : b.country.localeCompare(a.country);
      // countryが同じ場合rankで昇順ソート
      if (countryComparison === 0) {
        return a.rank - b.rank;
      }
      return countryComparison;
    });
    setFilteredRankedData(copy);
    setSort((prev) => ({
      ...prev,
      country: prev.country === 'asc' ? 'desc' : 'asc',
    }));
  };

  // City/Regionでソート
  const sortByCityRegion = () => {
    const copy = [...filteredRankedData.slice(0, displayLimit)];
    copy.sort((a, b) => {
      const cityComparison =
        sort.cityRegion === 'asc'
          ? a.cityName.localeCompare(b.cityName)
          : b.cityName.localeCompare(a.cityName);

      // cityNameが同じ場合にregionNameで昇順・降順ソート
      if (cityComparison === 0) {
        sort.cityRegion === 'asc'
          ? a.regionName.localeCompare(b.regionName)
          : b.regionName.localeCompare(a.regionName);
        return a.rank - b.rank;
      }
      return cityComparison;
    });
    setFilteredRankedData(copy);
    setSort((prev) => ({
      ...prev,
      cityRegion: prev.cityRegion === 'asc' ? 'desc' : 'asc',
    }));
  };

  // Viewsでソート
  const sortByViews = () => {
    const copy = [...filteredRankedData.slice(0, displayLimit)];
    copy.sort((a, b) => {
      const viewComparison =
        sort.view === 'asc'
          ? b.totalViews - a.totalViews
          : a.totalViews - b.totalViews;
      // totalViewsが同じ場合rankで昇順ソート
      if (viewComparison === 0) {
        return a.rank - b.rank;
      }
      return viewComparison;
    });
    setFilteredRankedData(copy);
    setSort((prev) => ({
      ...prev,
      view: prev.view === 'asc' ? 'desc' : 'asc',
    }));
  };

  // Unique Usersでソート
  const sortByUniqueUsers = () => {
    const copy = [...filteredRankedData.slice(0, displayLimit)];
    copy.sort((a, b) => {
      const uuComparison =
        sort.uniqueUser === 'asc'
          ? b.totalUsers - a.totalUsers
          : a.totalUsers - b.totalUsers;
      // totalUsersが同じ場合rankで昇順ソート
      if (uuComparison === 0) {
        return a.rank - b.rank;
      }
      return uuComparison;
    });
    setFilteredRankedData(copy);
    setSort((prev) => ({
      ...prev,
      uniqueUser: prev.uniqueUser === 'asc' ? 'desc' : 'asc',
    }));
  };

  return (
    <div>
      <div className='flex items-center gap-2'>
        <select
          className='select select-bordered w-full max-w-xs'
          onChange={(e) => setSelectedCountry(e.target.value)}
        >
          {countryOptions.map((item) => {
            return (
              <option
                value={item === t('analytics.countries') ? '' : item}
                key={item}
              >
                {item}
              </option>
            );
          })}
        </select>
      </div>
      <div className='mt-6 flex items-center gap-4'>
        <span className='label-text'>Show Rows:</span>
        {0 < showRowsOptions.length && (
          <select
            className='select select-bordered select-sm w-full max-w-32'
            onChange={(e) => changeDisplayLimit(Number(e.target.value))}
          >
            {showRowsOptions.map((item) => {
              return (
                <option
                  value={item.value}
                  key={`ExhibitionViewsByLocation-${item.value}`}
                >
                  {item.label}
                </option>
              );
            })}
          </select>
        )}
      </div>
      <div className='mt-6 overflow-x-auto'>
        <table className='table'>
          {/* head */}
          <thead>
            <tr>
              <th>
                <Trans i18nKey={'analytics.rank'} />
              </th>
              <th className='min-w-[180px] '>
                <div className='flex gap-[0.625rem]'>
                  <Trans i18nKey={'analytics.exhibition'} />
                  <ChevronUpDownIcon
                    className='h-4 w-4 shrink-0 cursor-pointer'
                    onClick={sortByExhibition}
                  />
                </div>
              </th>
              <th>
                <div className='flex gap-[0.625rem]'>
                  <Trans i18nKey={'analytics.country'} />
                  <ChevronUpDownIcon
                    className='h-4 w-4 shrink-0 cursor-pointer'
                    onClick={sortByCountry}
                  />
                </div>
              </th>
              <th>
                <div className='flex gap-[0.625rem]'>
                  <Trans i18nKey={'analytics.city-region'} />
                  <ChevronUpDownIcon
                    className='h-4 w-4 shrink-0 cursor-pointer'
                    onClick={sortByCityRegion}
                  />
                </div>
              </th>
              <th>
                <div className='flex gap-[0.625rem]'>
                  <Trans i18nKey={'analytics.views'} />
                  <ChevronUpDownIcon
                    className='h-4 w-4 shrink-0 cursor-pointer'
                    onClick={sortByViews}
                  />
                </div>
              </th>
              {isVIP && (
                <th>
                  <div className='flex gap-[0.625rem]'>
                    <Trans i18nKey={'analytics.uu'} />
                    <ChevronUpDownIcon
                      className='h-4 w-4 shrink-0 cursor-pointer'
                      onClick={sortByUniqueUsers}
                    />
                  </div>
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {!isLoading ? (
              filteredRankedData.length > 0 ? (
                filteredRankedData.slice(0, displayLimit).map((row, index) => {
                  return (
                    <tr
                      key={`ExhibitionViewsByLocation-${row.exhibitionTitle}-${row.cityName}_${index}`}
                    >
                      <td className='font-bold'>{row.rank}</td>
                      <td className='font-bold'>{row.exhibitionTitle}</td>
                      <td>{row.country}</td>
                      <td>
                        {row.cityName === ''
                          ? `${row.regionName}`
                          : `${row.cityName}, ${row.regionName}`}
                      </td>
                      <td>{row.totalViews.toLocaleString()}</td>
                      {isVIP && <td>{row.totalUsers.toLocaleString()}</td>}
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={3} className='text-center'>
                    <Trans i18nKey={'analytics.no-data'} />
                  </td>
                </tr>
              )
            ) : (
              <tr>
                <td className='loading loading-spinner loading-xs text-primary'></td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};
