import { useSelector } from 'react-redux';
import LoadingSpinner from '../../../components/LoadingSpinner';

const MatureContentModal = ({ confirmMark }) => {
  const { isLoading } = useSelector((state) => state.isLoading);
  return (
    <dialog id='mature_content_modal' className='modal'>
      <div className='modal-box max-w-lg'>
        <h3 className='m-0 font-bold text-lg'>
          Confirm Mark as Mature Content
        </h3>
        <p className='py-4'>
          This artwork will be marked as mature content. Once marked, it will
          only be visible to users who choose to view mature content. If you
          proceed, this artwork may be hidden from some viewers based on their
          preferences. A notification email will be sent to the user when
          marked.
        </p>
        <div className='modal-action'>
          <form method='dialog'>
            <button className='btn'>Close</button>
          </form>
          <button className='btn !btn-error' onClick={confirmMark}>
            Confirm
          </button>
        </div>
      </div>
      {isLoading && <LoadingSpinner />}
    </dialog>
  );
};

export default MatureContentModal;
