import { useContext, useEffect, useState } from 'react';
import { ExhibitionContext } from '../../../../context/ExhibitionContext';
import { Trans } from 'react-i18next';

const LIGHTS = [
  {
    value: 1,
    title: 'Ceiling Square 1',
    isFree: true,
  },
  {
    value: 2,
    title: 'Ceiling Square 2',
    isFree: false,
  },
  {
    value: 3,
    title: 'Ceiling Circle',
    isFree: false,
  },
  {
    value: 4,
    title: 'Wall Square',
    isFree: false,
  },
  {
    value: 5,
    title: 'Wall Circle 1',
    isFree: false,
  },
  {
    value: 7,
    title: 'Wall Circle 2',
    isFree: false,
  },
  {
    value: 6,
    title: 'Wall Triangle',
    isFree: false,
  },
  {
    value: 8,
    title: 'Wall Bar 1',
    isFree: false,
  },
  {
    value: 9,
    title: 'Wall Bar 2',
    isFree: false,
  },
];

const LIGHT_COLORS = [
  {
    value: 'WHITE',
    title: 'WHITE',
    bgColor: 'bg-gray-200',
    checkColor: 'text-gray',
  },
  {
    value: 'YELLOW',
    title: 'YELLOW',
    bgColor: 'bg-yellow-400',
    checkColor: 'text-white',
  },
  {
    value: 'RED',
    title: 'RED',
    bgColor: 'bg-red-400',
    checkColor: 'text-white',
  },
  {
    value: 'BLUE',
    title: 'BLUE',
    bgColor: 'bg-blue-400',
    checkColor: 'text-white',
  },
  {
    value: 'PINK',
    title: 'PINK',
    bgColor: 'bg-pink-400',
    checkColor: 'text-white',
  },
  {
    value: 'GREEN',
    title: 'GREEN',
    bgColor: 'bg-green-400',
    checkColor: 'text-white',
  },
];

export const FunctionalLight = () => {
  const { exhibitionSettings, setExhibitionSettings } =
    useContext(ExhibitionContext);

  const [toggleLighting, setToggleLighting] = useState(false);

  useEffect(() => {
    if (exhibitionSettings.ceiling) {
      if (
        exhibitionSettings.light == 1 ||
        exhibitionSettings.light == 2 ||
        exhibitionSettings.light == 3
      ) {
        handleChangeLight(null);
        setToggleLighting(false);
      }
    }
  }, [exhibitionSettings.ceiling]);

  useEffect(() => {
    if (exhibitionSettings.light) {
      setToggleLighting(true);
    }
  }, [exhibitionSettings.light]);

  const handleChangeLight = (value) => {
    //必要であればvalitationを追加する

    setExhibitionSettings((prevState) => ({
      ...prevState,
      light: value,
    }));
  };

  const handleChangeLightColor = (value) => {
    //必要であればvalitationを追加する

    setExhibitionSettings((prevState) => ({
      ...prevState,
      lightColor: value,
    }));
  };

  return (
    <div className='overflow-scroll'>
      <p className='pb-8 text-xl font-semibold'>
        <Trans i18nKey={'gallery.functional-light.ttl'} />
      </p>

      <div className='label justify-start gap-2'>
        <input
          type='checkbox'
          className='toggle'
          checked={exhibitionSettings.light != null}
          onChange={() => {
            if (exhibitionSettings.light && toggleLighting) {
              setToggleLighting(false);
              handleChangeLight(null);
            } else {
              setToggleLighting(true);
              if (exhibitionSettings.ceiling != null) {
                handleChangeLight(4);
              } else {
                handleChangeLight(1);
              }
            }
          }}
        />
      </div>
      <div
        className={`${
          toggleLighting || exhibitionSettings.light
            ? 'pointer-events-auto cursor-auto opacity-100'
            : 'pointer-events-none cursor-not-allowed opacity-30'
        }`}
      >
        <div className='py-4 pl-6'>
          {LIGHTS.map((item) => (
            <div className='form-control' key={`light-${item.title}`}>
              <label className='label cursor-pointer justify-start gap-2'>
                <input
                  type='radio'
                  className='radio'
                  id={item.title}
                  value={item.value}
                  onChange={(e) => handleChangeLight(Number(e.target.value))}
                  // name='light'
                  checked={item.value == exhibitionSettings.light}
                  disabled={
                    (item.value == 1 || item.value == 2 || item.value == 3) &&
                    exhibitionSettings.ceiling != null
                  }
                />
                <span
                  className={`label-text ${
                    (item.value == 1 || item.value == 2 || item.value == 3) &&
                    exhibitionSettings.ceiling != null
                      ? 'text-opacity-30'
                      : ''
                  }`}
                >
                  {item.title}
                </span>
              </label>
            </div>
          ))}
        </div>
        <p>
          <Trans i18nKey={'gallery.functional-light.light-color'} />
        </p>
        <div className='py-4 pl-6'>
          {LIGHT_COLORS.map((item) => (
            <div className='form-control' key={`light-color-${item.title}`}>
              <label className='label cursor-pointer justify-start gap-2'>
                <input
                  type='radio'
                  className='radio'
                  id={item.title}
                  value={item.value}
                  onChange={(e) => handleChangeLightColor(e.target.value)}
                  // name='light-color'
                  checked={item.value == exhibitionSettings.lightColor}
                />
                <span className='label-text'>
                  {item.title.substring(0, 1).toUpperCase() +
                    item.title.substring(1).toLowerCase()}
                </span>
              </label>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
