import { useState } from 'react';
import { validatePresetName } from '../utils/validation';
import LoadingSpinner from './LoadingSpinner';
import { useTranslation, Trans } from 'react-i18next';

const SavePresetModal = ({
  presetName,
  setPresetName,
  handleCreatePreset,
  isLoading,
}) => {
  const [presetNameError, setPresetNameError] = useState('');
  const { i18n } = useTranslation();

  const reflectPresetName = (name) => {
    const result = validatePresetName(name);
    result.isValid
      ? setPresetNameError('')
      : setPresetNameError(result.message);
    setPresetName(name);
  };

  return (
    <dialog id='save_preset_modal' className='modal'>
      <div className='modal-box max-w-[32rem] p-6'>
        <h3 className='mb-6 text-lg font-bold'>
          <Trans i18nKey={'save-preset-modal.ttl'} />
        </h3>
        <p className='pb-6'>
          <Trans i18nKey={'save-preset-modal.desc'} />
        </p>
        <label className='form-control w-full'>
          <div className='label'>
            <span className='label-text'>
              <Trans i18nKey={'save-preset-modal.label'} />
            </span>
          </div>
          <input
            className={`input input-bordered w-full text-sm placeholder:text-sm ${
              presetNameError && 'input-error'
            }`}
            maxLength='100'
            type='text'
            placeholder={
              i18n.language === 'ja'
                ? 'プリセットの名前を入力'
                : 'Enter preset name...'
            }
            value={presetName}
            onChange={(e) => reflectPresetName(e.target.value)}
            required
          />
          <div className='label'>
            <span className='label-text text-error'>{presetNameError}</span>
            <span className='label-text-alt'>
              {presetName?.length || 0}/100
            </span>
          </div>
        </label>
        <div className='modal-action mt-6'>
          <form method='dialog'>
            <button className='btn mr-2'>
              <Trans i18nKey={'btn.cancel'} />
            </button>
            <button
              className='btn btn-primary'
              onClick={handleCreatePreset}
              disabled={!presetName || presetNameError}
            >
              <Trans i18nKey={'btn.save'} />
            </button>
          </form>
        </div>
      </div>
      {isLoading && <LoadingSpinner />}
    </dialog>
  );
};

export default SavePresetModal;
