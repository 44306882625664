import React, { useEffect, useRef } from 'react';
import { SpotLight } from '@react-three/drei';

const MovingSpotLight = ({
  currentItemId,
  opacity = 0.7,
  anglePower = 3,
  targetPosition = [1, -2, 1],
  angle = 0.7,
  color = '#ffffff',
}) => {
  const spotLightRef = useRef();

  useEffect(() => {
    if (spotLightRef.current) {
      const spotLight = spotLightRef.current;
      spotLight.position.x = currentItemId * 2.6 + 0.2; // 右に移動
      spotLight.target.position.x = currentItemId * 2.6 + 0.2; // 右に移動
    }
  }, [currentItemId]);

  return (
    <SpotLight
      target-position={targetPosition}
      castShadow
      distance={6.5}
      attenuation={2.9}
      anglePower={anglePower}
      color={color}
      opacity={opacity}
      ref={spotLightRef}
      position={[0.2, 3.1, 4]}
      angle={angle}
      penumbra={0.5}
      intensity={4}
    />
  );
};

export default MovingSpotLight;
