import React from 'react';
import { GlobeAltIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';

export const Footer = () => {
  const { i18n } = useTranslation();

  const changeLanguage = async (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <>
      <footer>
        <div
          className={`footer justify-between bg-[#EBEBEB] p-10 px-10 pb-[1.5625rem] text-base-content md:px-16 md:pt-24`}
        >
          <aside>
            <Link to={'/'} className='flex items-center gap-3 lg:gap-6'>
              <img
                src='/assets/img/cas-logo.png'
                alt='site-logo'
                className='h-8 w-8 lg:h-20 lg:w-20'
              />
              <span className='text-dark text-left !font-outfit text-2xl font-semibold md:text-4xl'>
                <Trans i18nKey={'logo.cas'} />
              </span>
            </Link>
          </aside>
          <nav>
            <Link to={'/faqs'} className='link-hover link'>
              <Trans i18nKey={'footer.nav4'} />
            </Link>
            <Link to={'/contact'} className='link-hover link'>
              <Trans i18nKey={'footer.nav3'} />
            </Link>
            <Link to={'/terms-privacy'} className='link-hover link'>
              <Trans i18nKey={'footer.nav1'} />
            </Link>
            <Link to={'/cookie-policy'} className='link-hover link'>
              <Trans i18nKey={'footer.nav2'} />
            </Link>
          </nav>
        </div>
        <div className='bg-[#EBEBEB] px-10 py-10 md:px-16'>
          <div className='h-[1px] w-full bg-base-content bg-opacity-20' />
        </div>
        <div className='flex items-center  justify-between bg-[#EBEBEB] px-10 pb-24 md:px-16'>
          <div className='flex items-center  gap-2 md:px-6'>
            <GlobeAltIcon className='h-5 w-5' />
            <select
              className='select select-xs w-auto bg-[#EBEBEB]'
              onChange={(e) => changeLanguage(e.target.value)}
              value={i18n.language}
            >
              <option value='en'>English</option>
              <option value='ja'>日本語</option>
            </select>
          </div>
          <div className='self-start md:place-self-center md:justify-self-end'>
            <div className='flex gap-6'>
              <a
                href='https://www.facebook.com/profile.php?id=61566525053523'
                target='_blank'
                rel='noreferrer'
              >
                <img
                  src='/assets/img/facebook-logo.png'
                  className='h-[1.875rem] w-[1.875rem] shrink-0'
                />
              </a>
              <a
                href='https://www.instagram.com/curated_art_show'
                target='_blank'
                rel='noreferrer'
              >
                <img
                  src='/assets/img/instagram-logo.png'
                  className='h-[1.875rem] w-[1.875rem] shrink-0'
                />
              </a>
              <a href='https://x.com/vau_cas' target='_blank' rel='noreferrer'>
                <img
                  src='/assets/img/x-logo.png'
                  className='h-[1.875rem] w-[1.875rem] shrink-0'
                />
              </a>
            </div>
          </div>
        </div>

        <div className='font-paragraph text-dark-50 mx-4 flex justify-center py-4 text-xs md:py-4 md:text-sm'>
          <p className='text-[#74759C]'>
            <Trans i18nKey={'footer.copyright'} />
          </p>
        </div>
      </footer>
    </>
  );
};
