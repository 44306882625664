import { SpotLight, useHelper } from '@react-three/drei';
import React, { useRef } from 'react';
import { SpotLightHelper } from 'three';

const Light6 = ({ color, length = 5 }) => {
  let meshColor = [5, 2, 0.5];

  switch (color) {
    case 'RED':
      meshColor = [8, 1, 1];
      break;
    case 'BLUE':
      meshColor = [1, 1, 7];
      break;
    case 'WHITE':
      meshColor = [6, 6, 6];
      break;
    case 'YELLOW':
      meshColor = [6, 6, 2];
      break;
    case 'GREEN':
      meshColor = [1, 9, 1];
      break;
    case 'PINK':
      meshColor = [7, 1, 7];
      break;
    default:
      meshColor = [6, 6, 6];
      break;
  }
  const ref = useRef();
  useHelper(ref, SpotLightHelper);
  return (
    <>
      {Array(5 * length)
        .fill()
        .map((_, index) => (
          <mesh position={[index * 2 - 10, 0, 0]} key={index}>
            <cylinderGeometry args={[0, 0.5, 10, 30]} />
            <meshStandardMaterial toneMapped={false} color={meshColor} />
          </mesh>
        ))}
    </>
  );
};

export default Light6;
