export const CUSTOM_FRAMES = [
  {
    value: 'custom1',
    title: 'Thick Frame',
    isPro: false,
    imgUrl:
      'https://data.curatedartshow.com/frame_thumbnails/customThickFrame.png',
  },
  {
    value: 'custom2',
    title: 'Wide Frame',
    isPro: false,
    imgUrl:
      'https://data.curatedartshow.com/frame_thumbnails/customWideFrame.png',
  },
  {
    value: 'custom3',
    title: 'Slim Frame',
    isPro: false,
    imgUrl:
      'https://data.curatedartshow.com/frame_thumbnails/customSlimFrame.png',
  },
  {
    value: 'custom4',
    title: 'Jena Frame',
    isPro: false,
    imgUrl:
      'https://data.curatedartshow.com/frame_thumbnails/customJenaFrame.png',
  },
  {
    value: 'custom5',
    title: 'Koi Frame',
    isPro: false,
    imgUrl:
      'https://data.curatedartshow.com/frame_thumbnails/customKoiFrame.png',
  },
];
