import { publicRequest } from '../requestMethods';

export const getFloors = async () => {
  try {
    const response = await publicRequest.get(`/floors`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getFloorById = async (id) => {
  try {
    const response = await publicRequest.get(`/floors/${id}`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
