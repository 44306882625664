import React from 'react';

const Light7 = ({ color, length = 5 }) => {
  let meshColor = [5, 2, 0.5];

  switch (color) {
    case 'RED':
      meshColor = [20, 2, 2];
      break;
    case 'BLUE':
      meshColor = [2, 2, 20];
      break;
    case 'WHITE':
      meshColor = [6, 6, 6];
      break;
    case 'YELLOW':
      meshColor = [6, 6, 2];
      break;
    case 'GREEN':
      meshColor = [1.2, 20, 1.2];
      break;
    case 'PINK':
      meshColor = [20, 1.2, 20];
      break;
    default:
      meshColor = [6, 6, 6];
      break;
  }

  return (
    <group>
      {Array(2 * length)
        .fill()
        .map((_, index) => (
          <mesh position={[index * 5 - 10, 2, 0]} key={index}>
            <torusGeometry args={[1.8, 0.035, 50, 50]} />
            <meshStandardMaterial toneMapped={false} color={meshColor} />
          </mesh>
        ))}
    </group>
  );
};

export default Light7;
