import { useGLTF } from '@react-three/drei';
import { Suspense, useLayoutEffect, useState } from 'react';

const PictureFrame6 = ({ color, ...props }) => {
  const { scene, materials } = useGLTF(
    'https://data.curatedartshow.com/models/frames/pictureFrame6.glb'
  );

  const [geometry, setGeometry] = useState();

  if (!geometry) {
    const cloneScene = scene.clone(true);
    setGeometry(cloneScene);
  }

  useLayoutEffect(() => {
    materials['Frame-Material.001'].color.set(color);
    materials['Frame-Material.001'].metalness = 1;
    materials['Frame-Material.001'].roughness = 1;

    geometry.traverse((obj) => {
      if (obj.isMesh) obj.castShadow = obj.receiveShadow = true;
    });
  }, []);
  return (
    <Suspense fallback={null}>
      <primitive object={geometry} {...props} />
    </Suspense>
  );
};

export default PictureFrame6;
