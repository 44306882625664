import { useSelector } from 'react-redux';
import LoadingSpinner from './LoadingSpinner';

const UserUnArchiveConfirmModal = ({ handleUnarchive }) => {
  const { isLoading } = useSelector((state) => state.isLoading);

  return (
    <dialog id='unarchive_confirm_modal' className='modal'>
      <div className='modal-box !max-w-[32rem] !p-6'>
        <h3 className='mb-6 mt-0 text-lg font-bold'>Unarchive confirmation</h3>
        <p className='my-0 pb-6'>
          Are you sure you want to unarchive this user?
        </p>

        <div className='modal-action mb-0 mt-6'>
          <form method='dialog'>
            <button className='btn mr-2'>Cancel</button>
            <button
              className='btn !bg-warning !text-error hover:!bg-warning hover:opacity-70'
              onClick={handleUnarchive}
            >
              Unarchive
            </button>
          </form>
        </div>
      </div>
      {isLoading && <LoadingSpinner />}
    </dialog>
  );
};

export default UserUnArchiveConfirmModal;
