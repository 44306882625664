import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';

export const OnBoardingVerified = () => {
  return (
    <div className='mx-auto flex max-w-[21.25rem] flex-col items-center gap-5 pt-10'>
      <h2 className='text-center text-3xl font-extrabold'>
        <Trans i18nKey={'email-confirmation.used.ttl'} />
      </h2>
      <div className='mt-3 text-center text-sm'>
        <Trans i18nKey={'email-confirmation.used.desc'} />
      </div>
      <Link to={'/signin'} className={`btn btn-primary btn-wide`}>
        <Trans i18nKey={'btn.signin'} />
      </Link>
    </div>
  );
};
