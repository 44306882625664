import React, { useMemo } from 'react';
import { useGLTF } from '@react-three/drei';

export default function CustomFrame5(props) {
  const { nodes, materials } = useGLTF(
    'https://data.curatedartshow.com/models/frames/custom_koi_frame.glb'
  );

  const { frameColor, matColor } = props;

  // useMemo でマテリアルをキャッシュし、frameColor, matColor が変わったときに再生成
  const frameMaterial = useMemo(() => {
    const material = materials['default_material4'].clone();
    material.color.set(frameColor);
    material.metalness = 1;
    material.roughness = 3;
    material.needsUpdate = true;
    return material;
  }, [frameColor, materials]);

  const matMaterial = useMemo(() => {
    const material = materials['default_material4'].clone();

    material.color.set(matColor);
    material.metalness = 1;
    material.roughness = 3;
    material.needsUpdate = true;
    return material;
  }, [matColor, materials]);

  return (
    <group {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.bottom_strip.geometry}
        material={frameMaterial}
        position={[-0.002, -0.195, -0.007]}
        rotation={[Math.PI, -0.002, 3.138]}
        scale={[0.801, 0.222, 0.574]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.top_strip.geometry}
        material={frameMaterial}
        position={[0, 0.475, -0.007]}
        rotation={[Math.PI, -0.002, 3.138]}
        scale={[0.801, 0.222, 0.574]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.outer.geometry}
        material={frameMaterial}
        position={[0, -0.218, -0.008]}
        rotation={[0.979, 1.566, -0.979]}
        scale={[0.307, 3.71, 2.777]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Mat.geometry}
        material={matMaterial}
        position={[0.001, 0.16, 0.047]}
        rotation={[-1.542, -1.566, 0.037]}
        scale={[2.828, 0.155, 2.354]}
      />
    </group>
  );
}

useGLTF.preload(
  'https://data.curatedartshow.com/models/frames/custom_koi_frame.glb'
);
