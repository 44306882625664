import { useGLTF } from '@react-three/drei';
import { Suspense, useLayoutEffect, useState } from 'react';
import * as THREE from 'three';

const PictureFrame49 = ({ color, ...props }) => {
  const { scene, materials } = useGLTF(
    'https://data.curatedartshow.com/models/frames/jenaframe5.glb'
  );

  const [geometry, setGeometry] = useState();

  if (!geometry) {
    const cloneScene = scene.clone(true);
    setGeometry(cloneScene);
  }

  useLayoutEffect(() => {
    // console.log(materials);
    materials['Midnight Plastic_material2'].color.set('#686868');
    materials['Midnight Plastic_material2'].metalness = 0;
    materials['Midnight Plastic_material2'].roughness = 2;
    materials['Gold Spray Paint_material'].color.set('#856f06');
    materials['Gold Spray Paint_material'].metalness = 1;
    materials['Gold Spray Paint_material'].roughness = 0.9;
    // materials['Cream Paint Rolled_material'].color.set('#c9bca0');
    materials['Cream Paint Rolled_material'].color.set('#d2c7b0');
    materials['Cream Paint Rolled_material'].metalness = 0.8;
    materials['Cream Paint Rolled_material'].roughness = 3;
    // materials['Cream Paint Rolled_material'].normalScale = new THREE.Vector2(
    //   1,
    //   1
    // );
    // materials['default_material191.001'].color.set('#7c87cd');
    materials['default_material191.001'].metalness = 1;
    materials['default_material191.001'].roughness = 0;

    geometry.traverse((obj) => {
      if (obj.isMesh) obj.castShadow = obj.receiveShadow = true;
    });
  }, []);

  return (
    <Suspense fallback={null}>
      <primitive object={geometry} {...props} />
    </Suspense>
  );
};

export default PictureFrame49;
