import React, { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { updateProfile } from '../../redux/userRedux';
import { loadEnd, loadStart } from '../../redux/loadRedux';
import LoadingSpinner from '../../components/LoadingSpinner';
import { publicRequest } from '../../requestMethods';
import { errorToast, successToast } from '../../utils/toast';
import { Trans, useTranslation } from 'react-i18next';

const NewEmailConfirmation = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.isLoading);

  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const id = searchParams.get('id');
  const accountId = searchParams.get('account_id');

  const [status, setStatus] = useState('expired');

  const confirmEmail = async () => {
    try {
      dispatch(loadStart());
      const res = await axios.post(
        `${process.env.REACT_APP_API_BASEURL}/auth/new-email-confirm`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'x-api-key': process.env.REACT_APP_API_KEY,
          },
        }
      );

      dispatch(updateProfile(res.data));
      setStatus('success');
    } catch (err) {
      //tokenが失効しているかサーバー側のエラー
      if (err.response.status == 401) {
        setStatus('expired');
      } else if (
        err.response.data.message == 'This token has already been used.'
      ) {
        setStatus('used');
      } else {
        setStatus('other');
      }
      console.error(err);
    } finally {
      dispatch(loadEnd());
    }
  };

  const resendEmail = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(loadStart());
    try {
      await publicRequest.post(`/auth/resend-new-email`, {
        userId: Number(id),
        accountId,
      });
      successToast(t('message.success.t22'));
    } catch (err) {
      console.error(err);
      errorToast(t('message.error.t1'));
    } finally {
      dispatch(loadEnd());
    }
  };

  useEffect(() => {
    if (token) {
      confirmEmail();
    } else {
      setStatus('other');
    }
  }, [token]);

  return (
    <>
      <div className='mx-auto h-[95vh] !max-w-[25.8rem] pt-[6.5rem]'>
        {/* success */}
        {status == 'success' && (
          <div className='flex flex-col items-center'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='120'
              height='120'
              viewBox='0 0 120 120'
              fill='none'
              className='h-[7.5rem] w-[7.5rem]'
            >
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M60 108C72.7304 108 84.9394 102.943 93.9411 93.9411C102.943 84.9394 108 72.7304 108 60C108 47.2696 102.943 35.0606 93.9411 26.0589C84.9394 17.0571 72.7304 12 60 12C47.2696 12 35.0606 17.0571 26.0589 26.0589C17.0571 35.0606 12 47.2696 12 60C12 72.7304 17.0571 84.9394 26.0589 93.9411C35.0606 102.943 47.2696 108 60 108ZM82.242 52.242C83.335 51.1104 83.9397 49.5948 83.926 48.0216C83.9124 46.4484 83.2814 44.9435 82.1689 43.8311C81.0565 42.7186 79.5516 42.0876 77.9784 42.074C76.4052 42.0603 74.8896 42.6651 73.758 43.758L54 63.516L46.242 55.758C45.1104 54.6651 43.5948 54.0603 42.0216 54.074C40.4484 54.0876 38.9435 54.7186 37.8311 55.8311C36.7186 56.9435 36.0876 58.4484 36.074 60.0216C36.0603 61.5948 36.6651 63.1104 37.758 64.242L49.758 76.242C50.8832 77.3668 52.409 77.9987 54 77.9987C55.591 77.9987 57.1168 77.3668 58.242 76.242L82.242 52.242Z'
                fill='#00A96D'
              />
            </svg>

            <p className='mt-2 text-3xl font-[900]'>
              <Trans i18nKey={'email-confirmation.success.ttl'} />
            </p>
            <p className='my-10 text-sm font-medium'>
              <Trans i18nKey={'email-confirmation.success.desc'} />
            </p>
            <Link to={'/dashboard'} className='btn btn-primary'>
              <Trans i18nKey={'btn.go-to-dashboard'} />
            </Link>
          </div>
        )}

        {/* expired */}
        {status == 'expired' && (
          <div className='flex flex-col items-center text-center'>
            <p className='mt-2 text-3xl font-[900]'>
              <Trans i18nKey={'email-confirmation.expired.ttl'} />
            </p>
            <p className='my-10 text-sm font-medium'>
              <Trans i18nKey={'email-confirmation.expired.desc'} />
            </p>
            <button onClick={(e) => resendEmail(e)} className='btn btn-primary'>
              <Trans i18nKey={'btn.resend-verification'} />
            </button>
          </div>
        )}

        {/* used */}
        {status == 'used' && (
          <div className='flex flex-col items-center text-center'>
            <p className='mt-2 text-3xl font-[900]'>
              <Trans i18nKey={'email-confirmation.used.ttl'} />
            </p>
            <p className='my-10 text-sm font-medium'>
              <Trans i18nKey={'email-confirmation.used.desc'} />
            </p>
            <Link to={'/dashboard'} className='btn btn-primary'>
              <Trans i18nKey={'btn.go-to-dashboard'} />
            </Link>
          </div>
        )}

        {/* other */}

        {status == 'other' && (
          <div className='flex flex-col items-center text-center'>
            <p className='mt-2 text-3xl font-[900]'>
              <Trans i18nKey={'email-confirmation.other.ttl'} />
            </p>
            <p className='my-10 text-sm font-medium'>
              <Trans i18nKey={'email-confirmation.other.desc'} />
            </p>
            <Link to={'/dashboard'} className='btn btn-primary'>
              <Trans i18nKey={'btn.go-to-dashboard'} />
            </Link>
            <Link to={'/'} className='mt-6 cursor-pointer text-sm underline'>
              <Trans i18nKey={'btn.go-to-home'} />
            </Link>
          </div>
        )}
      </div>

      {isLoading && <LoadingSpinner />}
    </>
  );
};

export default NewEmailConfirmation;
