import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useTexture } from '@react-three/drei';
import * as THREE from 'three';

const Classic2Wall = () => {
  const textureProps = useTexture({
    // map: `https://data.curatedartshow.com/texture/walls/MetalPaintRusted6/basecolor.jpg`,
    normalMap: `https://data.curatedartshow.com/texture/walls/MetalPaintRusted6/normal.jpg`,
    roughnessMap: `https://data.curatedartshow.com/texture/walls/MetalPaintRusted6/roughness.jpg`,
    displacementMap: `https://data.curatedartshow.com/texture/walls/MetalPaintRusted6/height.jpg`,
    aoMap: `https://data.curatedartshow.com/texture/walls/MetalPaintRusted6/ao.jpg`,
  });
  // const textureProps = useTexture({
  //   map: `https://data.curatedartshow.com/texture/walls/RoughPaint/basecolor.jpg`,
  //   normalMap: `https://data.curatedartshow.com/texture/walls/RoughPaint/normal.jpg`,
  //   roughnessMap: `https://data.curatedartshow.com/texture/walls/RoughPaint/roughness.jpg`,
  //   displacementMap: `https://data.curatedartshow.com/texture/walls/RoughPaint/height.jpg`,
  // });

  useLayoutEffect(() => {
    Object.values(textureProps).forEach((texture) => {
      texture.wrapS = THREE.RepeatWrapping;
      texture.wrapT = THREE.RepeatWrapping;
      texture.repeat.set(120, 120);
    });
  }, [textureProps]);

  const mesh = useRef();
  useEffect(() => {
    if (mesh.current) {
      mesh.current.geometry.setAttribute(
        'uv2',
        new THREE.BufferAttribute(mesh.current.geometry.attributes.uv.array, 2)
      );
    }
  }, [mesh.current]);

  return (
    <mesh receiveShadow ref={mesh} position={[0, 0, -0.95]}>
      <planeGeometry args={[200, 200]} />
      <meshStandardMaterial
        displacementScale={0.5}
        aoMapIntensity={0.5}
        roughness={1}
        normalScale={new THREE.Vector2(0, 1)}
        metalness={0.0}
        // color={'#355862'}
        // color={'#9b0101'}
        // color={'#d84040'}
        // color={'#f95959'}//最適
        // color={'#67978b'}
        color={'#e1e1e1'}
        {...textureProps}
      />
    </mesh>
  );
};

export default Classic2Wall;
