import {
  BakeShadows,
  Cloud,
  Environment,
  OrbitControls,
  useHelper,
} from '@react-three/drei';
import { Canvas } from '@react-three/fiber';
import { Bloom, EffectComposer } from '@react-three/postprocessing';
import React, { Suspense, useContext, useRef } from 'react';
import Loader from '../Loader';
import Items from '../Items';

import BasicFloor from '../floors/BasicFloor';
import Light1 from '../lights/Light1';
import Light2 from '../lights/Light2';
import Light3 from '../lights/Light3';
import Light4 from '../lights/Light4';
import Light5 from '../lights/Light5';
import Light6 from '../lights/Light6';
import Light7 from '../lights/Light7';
import Light8 from '../lights/Light8';
import Light9 from '../lights/Light9';
import ObjectLight1 from '../models/objectLights/ObjectLight1';
import ObjectLight2 from '../models/objectLights/ObjectLight2';
import ObjectLight3 from '../models/objectLights/ObjectLight3';
import CustomFloor from '../floors/CustomFloor';
import CustomWall from '../walls/CustomWall';
import CustomCeiling from '../ceilings/CustomCeiling';
import MovingSpotLight from '../lights/MovingSpotLight';
import * as THREE from 'three';
import CyberPunkFloor from '../floors/CyberPunkFloor';
import ClassicFloor from '../floors/ClassicFloor';
import { ExhibitionControllerContext } from '../../../context/ExhibitionControllerContext';
import { ExhibitionContext } from '../../../context/ExhibitionContext';
import CustomBackground from '../backgrounds/CustomBackground';
import SilhouetteGroup from '../SilhouetteGroup';

// import MovingSpotLight from '../lights/MovingSpotLight';

const CustomColorRoom = ({ bgColor }) => {
  const { exhibitionItems, exhibitionSettings } = useContext(ExhibitionContext);
  const { currentItemId, mode } = useContext(ExhibitionControllerContext);
  const floorColor = '#333333';
  const directLightIntensity =
    exhibitionSettings?.floor == 4 ||
    exhibitionSettings?.floor == 6 ||
    exhibitionSettings?.floor == 11
      ? 1
      : 3.5;
  const ambientLightIntensity = 1;

  //lightのdebug用
  // const dirLight = useRef(null);
  // useHelper(dirLight, THREE.DirectionalLightHelper, '#fff');

  return (
    <>
      {/* <OrbitControls /> */}
      {/* <BakeShadows /> */}
      <EffectComposer>
        <Bloom intensity={0.1} />
      </EffectComposer>
      <Suspense fallback={<Loader />}>
        <color attach='background' args={[`${bgColor}`]} />
        {!exhibitionSettings?.background && (
          <fog attach='fog' args={[`${bgColor}`, 6, 15]} />
        )}

        {exhibitionSettings?.background && exhibitionSettings?.fogColor && (
          <fog attach='fog' args={[`${exhibitionSettings.fogColor}`, 6, 22]} />
        )}
        <directionalLight
          // ref={dirLight}
          position={[0, 2, 20]}
          target-position={[0, 2.5, 0]}
          intensity={
            exhibitionSettings?.directionalLightIntensity != null
              ? exhibitionSettings?.directionalLightIntensity * 0.5
              : directLightIntensity
          }
          color={
            exhibitionSettings?.directionalLightColorCode &&
            exhibitionSettings?.directionalLightIntensity > 0
              ? exhibitionSettings.directionalLightColorCode
              : '#ffffff'
          }
          castShadow
        />
        <ambientLight
          intensity={
            exhibitionSettings?.directionalLightIntensity != null
              ? exhibitionSettings?.directionalLightIntensity * 0.1
              : ambientLightIntensity
          }
          color={
            exhibitionSettings?.directionalLightColorCode &&
            exhibitionSettings?.directionalLightIntensity > 0
              ? exhibitionSettings.directionalLightColorCode
              : '#ffffff'
          }
        />
        {exhibitionSettings?.light === 1 && (
          <Light1
            color={exhibitionSettings.lightColor}
            length={exhibitionItems?.length}
          />
        )}
        {exhibitionSettings?.light === 2 && (
          <Light2
            color={exhibitionSettings.lightColor}
            length={exhibitionItems?.length}
          />
        )}
        {exhibitionSettings?.light === 3 && (
          <Light3
            color={exhibitionSettings.lightColor}
            length={exhibitionItems?.length}
          />
        )}
        {exhibitionSettings?.light === 4 && (
          <Light4
            color={exhibitionSettings.lightColor}
            length={exhibitionItems?.length}
          />
        )}
        {exhibitionSettings?.light === 5 && (
          <Light5
            color={exhibitionSettings.lightColor}
            length={exhibitionItems?.length}
          />
        )}
        {exhibitionSettings?.light === 6 && (
          <Light6
            color={exhibitionSettings.lightColor}
            length={exhibitionItems?.length}
          />
        )}
        {exhibitionSettings?.light === 7 && (
          <Light7
            color={exhibitionSettings.lightColor}
            length={exhibitionItems?.length}
          />
        )}
        {exhibitionSettings?.light === 8 && (
          <Light8
            color={exhibitionSettings.lightColor}
            length={exhibitionItems?.length}
          />
        )}
        {exhibitionSettings?.light === 9 && (
          <Light9
            color={exhibitionSettings.lightColor}
            length={exhibitionItems?.length}
          />
        )}
        {exhibitionSettings?.objectLight == 1 &&
          Array.from({ length: 10 }, (_, index) => (
            <ObjectLight1
              key={index}
              position={[-1.5 + index * 8, 1.7, 0.6]}
              scale={1}
            />
          ))}
        {exhibitionSettings?.objectLight == 2 &&
          Array.from({ length: 10 }, (_, index) => (
            <ObjectLight2
              key={index}
              position={[-1.5 + index * 8, 4, 0.2]}
              scale={1.2}
            />
          ))}
        {exhibitionSettings?.objectLight == 3 &&
          Array.from({ length: 10 }, (_, index) => (
            <ObjectLight3
              key={index}
              position={[-1.5 + index * 8, 4.4, 0.12]}
              scale={1}
            />
          ))}
        {mode == 'HIGH' && currentItemId != null && (
          <MovingSpotLight
            currentItemId={currentItemId}
            opacity={
              exhibitionSettings?.spotLightIntensity != null
                ? 0.1 * exhibitionSettings.spotLightIntensity
                : 0.1 * 2
            }
            anglePower={1}
            angle={0.35}
            color={exhibitionSettings?.spotLightColorCode}
          />
        )}
        <group position={[0.2, -0.5, 0]}>
          <Items items={exhibitionItems} />
          <SilhouetteGroup />

          {/* ceiling */}
          {exhibitionSettings?.ceiling && (
            <CustomCeiling id={exhibitionSettings?.ceiling} />
          )}

          {/* background */}
          {exhibitionSettings?.background && (
            <CustomBackground id={exhibitionSettings?.background} />
          )}

          {/* wall */}
          {exhibitionSettings?.wall && (
            <CustomWall id={exhibitionSettings?.wall} />
          )}
          {/* floor */}
          {exhibitionSettings?.floor !== undefined &&
            (exhibitionSettings?.floor ? (
              <CustomFloor id={exhibitionSettings?.floor} />
            ) : (
              <BasicFloor floorColor={floorColor} />
            ))}
        </group>
        <Environment
          files={'https://data.curatedartshow.com/hdri/studio_small_07_1k.hdr'}
        />
      </Suspense>
    </>
  );
};

export default CustomColorRoom;
