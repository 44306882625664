import { Clone, useGLTF } from '@react-three/drei';
import { useEffect, useLayoutEffect } from 'react';

const MetalFence = ({ ...props }) => {
  const { scene, materials, animations } = useGLTF(
    'https://data.curatedartshow.com/models/lowPolyFence.glb'
  );

  useEffect(() => {
    return () => {
      //リソースのクリーンアップ処理
      useGLTF.clear('https://data.curatedartshow.com/models/lowPolyFence.glb');
    };
  }, []);

  useLayoutEffect(() => {
    scene.traverse((obj) => {
      if (obj.isMesh) obj.castShadow = obj.receiveShadow = true;
    });
    return () => {
      // マテリアルの解放
      Object.values(materials).forEach((material) => {
        material.dispose();
      });

      // シーンからオブジェクトの削除
      scene.traverse((obj) => {
        if (obj.isMesh) {
          obj.geometry.dispose();
          obj.material.dispose();
          scene.remove(obj);
        }
      });
    };
  }, []);

  return (
    <>
      <Clone object={scene} {...props} castShadow />
    </>
  );
};

export default MetalFence;
