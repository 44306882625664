import React, { createContext, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// ExhibitionContextの作成
export const ExhibitionContext = createContext();

export const ExhibitionProvider = ({ children }) => {
  const location = useLocation();

  // 初期状態
  const initialExhibitionInfo = {
    title: '',
    description: '',
    previewImg: '',
    animationType: 0,
    roomId: '',
    isPrivate: false,
    password: '',
    newPassword: '',
    showOnHome: true,
    published: false,
    slideshowAutoStart: false,
  };

  const initialExhibitionSettings = {
    frame: '',
    theme: 'MUSEUM',
    silhouette: false,
    music: '',
    randomMusic: false,
    light: null,
    lightColor: 'WHITE',
    silhouettes: [],
    colorCode: '#191920',
    ceiling: null,
    wall: null,
    floor: null,
    background: null,
    objectLight: null,
    directionalLightIntensity: 1,
    directionalLightColorTheme: 'WHITE',
    directionalLightColorCode: '#ffffff',
    spotLightIntensity: 1,
    spotLightColorTheme: 'WHITE',
    spotLightColorCode: '#ffffff',
    frameColor: '#525252',
    matColor: '#525252',
    fogColor: null,
  };

  const initialExhibitionItems = [];

  const initialExhibitionController = {
    slideshowMode: false,
    slideshowEnd: false,
  };

  // Exhibition Info の状態管理
  const [exhibitionInfo, setExhibitionInfo] = useState(initialExhibitionInfo);

  // Exhibition Settings の状態管理
  const [exhibitionSettings, setExhibitionSettings] = useState(
    initialExhibitionSettings
  );

  // Exhibition Items の配列管理
  const [exhibitionItems, setExhibitionItems] = useState(
    initialExhibitionItems
  );

  const [exhibitionSnapshot, setExhibitionSnapshot] = useState('');

  // 同意分のチェック状態
  const [isAgreedTerms, setIsAgreedTerms] = useState(false);

  //exhibitionの閲覧画面のコントローラー
  const [exhibitionController, setExhibitionController] = useState(
    initialExhibitionController
  );

  // ページのパスが変わるたびにチェックして、特定のパス以外ではstateを初期化
  useEffect(() => {
    const isExhibitionPath =
      location.pathname.startsWith('/create-exhibition') ||
      location.pathname.startsWith('/edit-exhibition');

    if (!isExhibitionPath) {
      // ページが指定のパス以外になったらstateをリセット
      setExhibitionInfo(initialExhibitionInfo);
      setExhibitionSettings(initialExhibitionSettings);
      setExhibitionItems(initialExhibitionItems);
      setExhibitionSnapshot('');
      setIsAgreedTerms(false);
    }
  }, [location.pathname]); // location.pathnameが変わるたびに実行

  // コンテキストプロバイダでラップし、値を提供
  return (
    <ExhibitionContext.Provider
      value={{
        exhibitionInfo,
        setExhibitionInfo,
        exhibitionSettings,
        setExhibitionSettings,
        exhibitionItems,
        setExhibitionItems,
        exhibitionSnapshot,
        setExhibitionSnapshot,
        isAgreedTerms,
        setIsAgreedTerms,
        exhibitionController,
        setExhibitionController,
      }}
    >
      {children}
    </ExhibitionContext.Provider>
  );
};
