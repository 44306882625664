import {
  ArrowsPointingOutIcon,
  BuildingStorefrontIcon,
  XMarkIcon,
  EnvelopeIcon,
  Square2StackIcon,
} from '@heroicons/react/24/outline';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { publicRequest } from '../../requestMethods';
import ReportItemModal from './modal/ReportItemModal';
import FullScreenImg from './FullScreenImg';
import { useOnClickOutside } from '../../utils/useOnClickOutside';
import parse from 'html-react-parser';
import { Trans, useTranslation } from 'react-i18next';
import ReactGA from 'react-ga4';
import { checkInquiryEmail } from '../../services/User';
import { successToast } from '../../utils/toast';

const ItemModalInGallery = ({
  item,
  setSelectedItem,
  isBackgroundLight,
  setInquiryItem,
  roomId,
}) => {
  const [showReport, setShowReport] = useState(false);
  const [showReportModal, setShowReportModal] = useState(false);
  const [showRentingUsers, setShowRentingUsers] = useState(false);
  const [showFullScreenImg, setShowFullScreenImg] = useState(null);
  const [hasInquiryEmail, setHasInquiryEmail] = useState(false);
  const [copiedMessage, setCopiedMessage] = useState('');

  // ステータスのENUMを定義
  const STATUS = {
    AVAILABLE_FOR_SALE: 'Available for Sale',
    NOT_FOR_SALE: 'Not for Sale',
    PENDING: 'Pending',
    SOLD: 'Sold',
  };

  const user = useSelector((state) => state.user.currentUser);
  const { t } = useTranslation();
  const itemModalRef = useRef();
  const rentingUsersRef = useRef();
  const rentingUsersButtonRef = useRef();
  const reportRef = useRef();
  const reportButtonRef = useRef();
  const reportModalRef = useRef();

  const sendEvent = (actionType) => {
    if (ReactGA.isInitialized) {
      // Page　Viewイベントを送信
      ReactGA.send({
        hitType: 'pageview',
        page: `/galleries/${roomId}`,
        title: `${item.title}`,
      });
      // カスタムイベントを送信
      ReactGA.event({
        category: 'Artwork',
        action: actionType,
        label: `${item.title}`,
        value: item.id,
      });
      console.log('send');
    } else {
      console.log('GA Not Initialized');
    }
  };

  useOnClickOutside(itemModalRef, () => setSelectedItem(null));

  const handleClickOutsideReport = (event) => {
    if (showReport && !reportButtonRef.current.contains(event.target)) {
      setShowReport(false);
    }
  };
  const handleClickOutsideRenting = (event) => {
    if (
      showRentingUsers &&
      !rentingUsersButtonRef.current.contains(event.target)
    ) {
      setShowRentingUsers(false);
    }
  };
  useOnClickOutside(rentingUsersRef, (e) => handleClickOutsideRenting(e));
  useOnClickOutside(reportRef, (e) => handleClickOutsideReport(e));
  useOnClickOutside(reportModalRef, () => setShowReportModal(false));

  const navigate = useNavigate();

  const getLatestItemData = async () => {
    const res = await publicRequest.get(`/items/${item?.id}`);

    setSelectedItem(res.data);
  };

  const checkUserInquiryEmail = async () => {
    const res = await checkInquiryEmail(item.ownerId);
    setHasInquiryEmail(res.hasInquiryEmail);
  };

  const handleCopyLink = (isInModal = false) => {
    const currentUrl = window.location.href; // 現在のページURLを取得
    navigator.clipboard
      .writeText(currentUrl)
      .then(() => {
        if (isInModal) {
          //backdropの上に表示できないのでカスタム
          setCopiedMessage(t('message.success.t24'));
          setTimeout(() => {
            setCopiedMessage('');
          }, 3000);
        } else {
          successToast(t('message.success.t24'));
        }
      })
      .catch((err) => {
        console.error('Failed to copy: ', err);
      });
  };

  useEffect(() => {
    getLatestItemData();
    checkUserInquiryEmail();
  }, [item.id]);

  return (
    <div ref={itemModalRef}>
      <div
        className='fixed left-0 top-0 z-[1001] flex h-full w-full -translate-x-1/2 transform animate-fade-up flex-col items-center overscroll-contain rounded-xl border border-gray-500 bg-[#1A1F26]/75 bg-opacity-60 p-1 text-white animate-normal animate-duration-[400ms] animate-ease-out before:absolute before:z-[-1] before:h-full before:w-full before:overflow-hidden before:rounded-xl before:backdrop-blur-xl before:content-[""] md:!left-[5%] md:!top-[5%] md:!h-[90vh] md:!w-[90vw] md:-translate-y-1/2 md:p-5 md:before:top-0'
        onClick={() => setSelectedItem(null)}
      >
        {/* header */}
        <div className='flex w-full justify-end'>
          <button onClick={() => setSelectedItem(null)}>
            <XMarkIcon className='m-2 h-8 w-8 cursor-pointer' />
          </button>
        </div>

        <div className='flex w-full flex-col overflow-hidden md:flex-row'>
          {/* left */}
          <div className='cas-scrollbar hidden w-full flex-col items-center overflow-y-scroll px-4 py-2 md:flex md:w-1/3'>
            <div
              className='relative mb-5 max-h-[90%]'
              onClick={(event) => {
                sendEvent('enter_fullscreen');
                event.stopPropagation();
                item?.itemUrl
                  ? setShowFullScreenImg(item.itemUrl)
                  : setShowFullScreenImg('/assets/img/artwork_dummy.png');
              }}
            >
              <img
                className='m-full w-full max-w-full cursor-pointer object-contain lg:max-h-full'
                src={
                  item?.itemUrl
                    ? item.itemUrl + '?q=60'
                    : '/assets/img/artwork_dummy.png'
                }
              />
              {item?.itemUrl && (
                <ArrowsPointingOutIcon
                  className='absolute bottom-0 right-0 z-10 h-7 w-7 cursor-pointer bg-primary p-1'
                  onClick={(event) => {
                    sendEvent('enter_fullscreen');
                    event.stopPropagation();
                    item?.itemUrl
                      ? setShowFullScreenImg(item.itemUrl)
                      : setShowFullScreenImg('/assets/img/artwork_dummy.png');
                  }}
                />
              )}
            </div>
            <div className='grid w-full grid-cols-3 gap-4'>
              {item.otherImage1Url && (
                <div className='flex flex-col gap-2'>
                  <div
                    className='aspect-square cursor-pointer overflow-hidden'
                    onClick={(event) => {
                      event.stopPropagation();
                      setShowFullScreenImg(item.otherImage1Url);
                    }}
                  >
                    <img
                      src={item.otherImage1Url + '?q=60'}
                      className='h-full w-full object-cover'
                    />
                  </div>
                  <p className='line-clamp-1 text-xs text-white'>
                    {item.otherImage1Title}
                  </p>
                </div>
              )}
              {item.otherImage2Url && (
                <div className='flex flex-col gap-2'>
                  <div
                    className='aspect-square cursor-pointer overflow-hidden'
                    onClick={(event) => {
                      event.stopPropagation();
                      setShowFullScreenImg(item.otherImage2Url);
                    }}
                  >
                    <img
                      src={item.otherImage2Url + '?q=60'}
                      className='h-full w-full object-cover'
                    />
                  </div>
                  <p className='line-clamp-1 text-xs text-white'>
                    {item.otherImage2Title}
                  </p>
                </div>
              )}
              {item.otherImage3Url && (
                <div className='flex flex-col gap-2'>
                  <div
                    className='aspect-square cursor-pointer overflow-hidden'
                    onClick={(event) => {
                      event.stopPropagation();
                      setShowFullScreenImg(item.otherImage3Url);
                    }}
                  >
                    <img
                      src={item.otherImage3Url + '?q=60'}
                      className='h-full w-full object-cover'
                    />
                  </div>
                  <p className='line-clamp-1 text-xs text-white'>
                    {item.otherImage3Title}
                  </p>
                </div>
              )}
            </div>
          </div>

          {/* right */}
          <div className='col-span-2 flex h-full w-full flex-col px-4 py-2 md:w-2/3'>
            <div className='flex flex-col justify-between md:flex-row'>
              <div
                className='hidden p-2 text-3xl font-bold text-white md:block'
                onClick={(event) => {
                  event.stopPropagation();
                }}
              >
                {item?.title ? item.title : 'Untitled Artwork'}
              </div>
              <div className='mb-3 flex flex-col items-center md:flex-row md:space-x-1 md:self-end'>
                <div className='mr-5 flex w-full items-center justify-end space-x-[24px] md:space-x-3'></div>
              </div>
            </div>
            <div className='cas-scrollbar overflow-y-auto overflow-x-hidden overscroll-contain p-3'>
              {/* mobile only start */}
              <div className='mb-5 flex justify-center md:hidden'>
                <div
                  className='relative'
                  onClick={(event) => {
                    event.stopPropagation();
                    item?.itemUrl
                      ? setShowFullScreenImg(item.itemUrl)
                      : setShowFullScreenImg('/assets/img/artwork_dummy.png');
                  }}
                >
                  <img
                    className='w-full cursor-pointer object-contain'
                    src={item?.itemUrl + '?q=60'}
                  />
                  <ArrowsPointingOutIcon
                    className='absolute bottom-0 right-0 h-7 w-7 cursor-pointer bg-primary p-1'
                    onClick={(event) => {
                      event.stopPropagation();
                      item?.itemUrl
                        ? setShowFullScreenImg(item.itemUrl)
                        : setShowFullScreenImg('/assets/img/artwork_dummy.png');
                    }}
                  />
                </div>
              </div>
              <div className='mb-4 grid w-full grid-cols-3 gap-4 md:hidden'>
                {item.otherImage1Url && (
                  <div className='flex flex-col gap-2'>
                    <div
                      className='aspect-square cursor-pointer overflow-hidden'
                      onClick={(event) => {
                        event.stopPropagation();
                        console.log(item.otherImage3Url);
                        setShowFullScreenImg(item.otherImage1Url);
                      }}
                    >
                      <img
                        src={item.otherImage1Url + '?q=60'}
                        className='h-full w-full object-cover'
                      />
                    </div>
                    <p className='line-clamp-1 text-xs text-white'>
                      {item.otherImage1Title}
                    </p>
                  </div>
                )}
                {item.otherImage2Url && (
                  <div className='flex flex-col gap-2'>
                    <div
                      className='aspect-square cursor-pointer overflow-hidden'
                      onClick={(event) => {
                        event.stopPropagation();
                        setShowFullScreenImg(item.otherImage2Url);
                      }}
                    >
                      <img
                        src={item.otherImage2Url + '?q=60'}
                        className='h-full w-full object-cover'
                      />
                    </div>
                    <p className='line-clamp-1 text-xs text-white'>
                      {item.otherImage2Title}
                    </p>
                  </div>
                )}
                {item.otherImage3Url && (
                  <div className='flex flex-col gap-2'>
                    <div
                      className='aspect-square cursor-pointer overflow-hidden'
                      onClick={(event) => {
                        event.stopPropagation();
                        setShowFullScreenImg(item.otherImage3Url);
                      }}
                    >
                      <img
                        src={item.otherImage3Url + '?q=60'}
                        className='h-full w-full object-cover'
                      />
                    </div>
                    <p className='line-clamp-1 text-xs text-white'>
                      {item.otherImage3Title}
                    </p>
                  </div>
                )}
              </div>
              <div
                className='block p-2 text-3xl font-bold text-white md:hidden'
                onClick={(event) => {
                  event.stopPropagation();
                }}
              >
                {item?.title ? item.title : 'Untitled Artwork'}
              </div>
              <div className='md:hidden'>
                {item?.ownerId !== user?.id && item?.published && (
                  <button
                    onClick={() =>
                      navigate(`/users/${item?.owner.username}?tab=forSale`, {
                        state: { item: item, showBackButton: true },
                      })
                    }
                    className={`primary-filled regular-btn flex w-full items-center justify-center space-x-2 text-sm ${
                      !item?.isActive && 'non-active-filled'
                    }`}
                    disabled={!item?.isActive}
                    style={{ display: 'none' }}
                  >
                    <BuildingStorefrontIcon
                      className={`h-5 w-5 stroke-white`}
                    />
                    <span>Get this artwork</span>
                  </button>
                )}
              </div>
              {/* mobile only end */}
              {item.artworkUrl && (
                <a
                  target='_blank'
                  href={item.artworkUrl}
                  className='btn btn-ghost normal-case '
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                    strokeWidth='1.5'
                    stroke='currentColor'
                    className='h-6 w-6'
                  >
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      d='M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25'
                    />
                  </svg>
                  {item.artworkUrlLabel}
                </a>
              )}
              {hasInquiryEmail && (
                <button
                  className='btn btn-ghost mt-3 flex w-fit items-center gap-2 rounded-lg !capitalize'
                  onClick={(event) => {
                    event.stopPropagation();
                    setInquiryItem(item);
                    document.getElementById('inquiry_item_modal').showModal();
                    setSelectedItem(null);
                  }}
                >
                  <EnvelopeIcon className='h-6 w-6' />
                  <Trans i18nKey={'artwork-modal.inquire'} />
                </button>
              )}
              <p
                className='py-10 text-sm text-white'
                onClick={(event) => {
                  event.stopPropagation();
                }}
              >
                {parse(item?.description.replaceAll('\n', '<br />'))}
              </p>
              {/* Artist Name */}
              {item.artistName && (
                <div className='mb-6'>
                  <p className='font-bold text-white'>Artist Name</p>
                  <p>{item.artistName}</p>
                </div>
              )}
              {item.createdYear && (
                <div className='mb-6'>
                  <p className='font-bold text-white'>
                    <Trans i18nKey={'artwork-modal.created'} />
                  </p>
                  <p className='text-white'>{item.createdYear}</p>
                </div>
              )}
              <div className='mb-6'>
                <p className='font-bold text-white'>
                  <Trans i18nKey={'artwork-modal.location'} />
                </p>
                <p className='text-white'>{item?.owner?.displayName}</p>
              </div>

              {item?.medium && (
                <div className='mb-6'>
                  <p className='font-bold text-white'>
                    <Trans i18nKey={'artwork-modal.medium'} />
                  </p>
                  <p className='text-white'>{item?.medium}</p>
                </div>
              )}

              {item?.dimensions && (
                <div className='mb-6'>
                  <p className='font-bold text-white'>
                    <Trans i18nKey={'artwork-modal.dimensions'} />
                  </p>
                  <p className='text-white'>{item?.dimensions}</p>
                </div>
              )}

              {item?.availability && (
                <div className='mb-6'>
                  <p className='font-bold text-white'>
                    <Trans i18nKey={'artwork-modal.availability'} />
                  </p>
                  <p className='text-white'>{STATUS[item?.availability]}</p>
                </div>
              )}

              {item?.price != null && (
                <div className='mb-6'>
                  <p className='font-bold text-white'>
                    <Trans i18nKey={'artwork-modal.price-currency'} />
                  </p>
                  <p className='text-white'>
                    {item?.price} {item?.currency}
                  </p>
                </div>
              )}
              <button
                className='btn btn-outline font-normal text-white hover:border-white'
                onClick={(event) => {
                  event.stopPropagation();
                  handleCopyLink(false);
                }}
              >
                <Square2StackIcon className='h-5 w-5' />
                Copy Exhibition Link
              </button>
              <div className='my-10 h-[0.125rem] bg-gray-600' />
              <button
                className='btn btn-ghost btn-sm text-xs font-normal text-gray-300'
                onClick={(e) => {
                  e.stopPropagation();
                  if (user) {
                    setShowReportModal(true);
                  } else {
                    navigate('/signin');
                  }
                }}
              >
                <Trans i18nKey={'btn.report'} />
              </button>
            </div>
          </div>
        </div>
      </div>
      {showReportModal && (
        <ReportItemModal
          itemId={item?.id}
          setShowReportModal={setShowReportModal}
          reportModalRef={reportModalRef}
          isBackgroundLight={true}
        />
      )}
      {!!showFullScreenImg && (
        <FullScreenImg
          onClose={() => setShowFullScreenImg(null)}
          imgUrl={showFullScreenImg}
        />
      )}
    </div>
  );
};

export default ItemModalInGallery;
