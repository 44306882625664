import { useMutation, useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router';

import parse from 'html-react-parser';
import { errorToast, successToast } from '../../../utils/toast';
import { ArrowLeftIcon, TrashIcon } from '@heroicons/react/24/outline';
import { deleteFaqById, getFaqById } from '../../../services/Admin';
import LoadingSpinner from '../../../components/LoadingSpinner';
import { escapeToHTML } from '../../../utils/StringUtils';
import DeleteFaqModal from './components/DeleteFaqModal';

export const AdminFaqDetails = () => {
  const navigate = useNavigate();

  const { id } = useParams();

  useEffect(() => {
    // IDの指定がない場合は NotFound
    if (!id) {
      return <Navigate replace to='/404' />;
    }
  }, [id]);

  const {
    data: faq,
    isLoading: isFaqLoading,
    refetch,
  } = useQuery({
    queryFn: () => getFaqById(Number(id)),
    queryKey: [`faq_${id}`],
  });

  const openDeleteModal = () => {
    document.getElementById('delete_faq_modal').showModal();
  };

  // delete処理
  const deleteUserMutation = useMutation({
    mutationFn: async () => {
      return await deleteFaqById(Number(id));
    },
    onSuccess: (data) => {
      document.getElementById('delete_faq_modal').close();
      navigate('/admin/faqs');
      successToast('FAQ successfully deleted.');
    },
    onError: (err) => {
      console.error(err);
      if (err.response.data.statusCode == 403) {
        errorToast(err?.response?.data.message);
      } else {
        errorToast(
          'Something went wrong. We are aware of the problem and will try to fix it as soon as possible. Sorry for the inconvenience.'
        );
      }
    },
  });

  const handleDelete = () => {
    deleteUserMutation.mutate();
  };

  return (
    <main className='container m-auto flex-auto'>
      <div className='mx-auto h-full max-w-4xl pt-6'>
        <button className='btn btn-ghost' onClick={() => navigate(-1)}>
          <ArrowLeftIcon className='h-6 w-6 shrink-0' />
          Back
        </button>
        <div className='flex  flex-col'>
          <h1 className='mb-8 mt-4 text-4xl font-bold'>FAQ Details</h1>
          <div className='flex flex-col gap-10 lg:flex-row'>
            {/* English */}
            <div className='flex-1'>
              <p className='pb-6 text-2xl font-bold'>English</p>
              <p className='mb-3 text-xl font-semibold text-base-content'>
                Question
              </p>
              <p className='font-medium text-base-content'>{faq?.question} </p>
              <p className='mb-2 mt-6 text-xl font-semibold text-base-content'>
                Answer
              </p>

              <div style={{ whiteSpace: 'pre-wrap' }}>
                {/* faq-answerクラスはul,olにpaddingをつけるためのもの（index.cssで定義） */}
                <div className='faq-answer'>
                  {parse(escapeToHTML(faq?.answer))}
                </div>
              </div>
            </div>
            {/* Japanese */}
            <div className='flex-1'>
              <p className='pb-6 text-2xl font-bold'>Japanese</p>
              <p className='mb-3 text-xl font-semibold text-base-content'>
                質問
              </p>
              <p className='font-medium text-base-content'>
                {faq?.questionJa}{' '}
              </p>
              <p className='mb-2 mt-6 text-xl font-semibold text-base-content'>
                回答
              </p>

              <div style={{ whiteSpace: 'pre-wrap' }}>
                {/* faq-answerクラスはul,olにpaddingをつけるためのもの（index.cssで定義） */}
                <div className='faq-answer'>
                  {parse(escapeToHTML(faq?.answerJa))}
                </div>
              </div>
            </div>
          </div>

          <p className='mb-6 mt-5 text-2xl font-bold text-base-content'>
            Order
          </p>
          <p className='mb-5'>{faq?.order}</p>
          <div className='divider my-10 py-0' />

          <div className='flex w-full justify-between'>
            <button
              onClick={openDeleteModal}
              className='btn btn-outline btn-error'
            >
              <TrashIcon className='h-6 w-6 shrink-0' />
              Delete
            </button>
            <button
              className='btn mb-6'
              onClick={() => navigate(`/admin/faqs-edit/${id}`)}
            >
              Edit
            </button>
          </div>
        </div>
      </div>
      {isFaqLoading && <LoadingSpinner />}

      <DeleteFaqModal handleDelete={handleDelete} />
    </main>
  );
};
