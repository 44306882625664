import { HexColorInput, HexColorPicker } from 'react-colorful';
import { Trans, useTranslation } from 'react-i18next';
import { ExhibitionContext } from '../../../../context/ExhibitionContext';
import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FRAMES } from '../../../../data/frames';
import { CUSTOM_FRAMES } from '../../../../data/customFrames';

export const PictureFrame = ({ plan }) => {
  const { i18n } = useTranslation();
  const { exhibitionSettings, setExhibitionSettings } =
    useContext(ExhibitionContext);
  const [frameType, setFrameType] = useState('PRESET');
  const [filteredFrames, setFilteredFrames] = useState([]);
  const [filteredCustomFrames, setFilteredCustomFrames] = useState([]);

  useEffect(() => {
    if (exhibitionSettings.frame?.includes('custom')) {
      setFrameType('CUSTOM');
    } else {
      setFrameType('PRESET');
    }
  }, [exhibitionSettings.frame]);

  useEffect(() => {
    //先頭2つを取り除いた後に、新しいデータを先頭に追加
    const filteredFramesTmp =
      plan == 'PREMIUM' || plan == 'VIP'
        ? FRAMES.slice(2) // 全てのフレームを取得
        : FRAMES.slice(2).filter((frame) => !frame.isPro); // isProがfalseのものだけをフィルタリング

    const tmpFrames = [
      {
        value: '',
        title: 'Unframed',
        isFree: true,
        imgUrl: '/assets/img/ban.png',
      },
      ...filteredFramesTmp,
    ];

    setFilteredFrames(tmpFrames);

    const filteredCustomFramesTmp =
      plan == 'PREMIUM' || plan == 'VIP'
        ? CUSTOM_FRAMES // 全てのフレームを取得
        : CUSTOM_FRAMES.filter((frame) => !frame.isPro); // isProがfalseのものだけをフィルタリング

    setFilteredCustomFrames(filteredCustomFramesTmp);
  }, [FRAMES, plan, CUSTOM_FRAMES]);

  const handleChangeFrame = (value) => {
    //必要であればvalitationを追加する

    setExhibitionSettings((prevState) => ({
      ...prevState,
      frame: value,
    }));
  };

  const handleChangeFrameColor = (value) => {
    //必要であればvalitationを追加する

    setExhibitionSettings((prevState) => ({
      ...prevState,
      frameColor: value,
    }));
  };

  const handleChangeMatColor = (value) => {
    //必要であればvalitationを追加する

    setExhibitionSettings((prevState) => ({
      ...prevState,
      matColor: value,
    }));
  };

  return (
    <div className='mb-10 overflow-scroll'>
      <p className='pb-8 text-xl font-semibold'>
        <Trans i18nKey={'gallery.picture-frames.ttl'} />
      </p>

      <p className='pb-5'>
        <Trans i18nKey={'gallery.picture-frames.desc'} />
      </p>
      <div className='form-control'>
        <label className='label my-0 cursor-pointer justify-start gap-2 py-0'>
          <input
            type='radio'
            className='radio'
            // value={item.kind}
            onChange={(e) => handleChangeFrame('')}
            checked={frameType === 'PRESET'}
          />
          <span className='label-text'>
            <Trans i18nKey={'label.select-from-presets'} />
          </span>
        </label>
      </div>
      <div
        className={`mb-10 py-5 pl-8 pr-0 ${
          frameType == 'PRESET'
            ? 'pointer-events-auto opacity-100'
            : 'pointer-events-none opacity-20'
        }`}
      >
        <div className='grid max-h-[29rem] grid-cols-3 gap-x-2 gap-y-[0.69rem] overflow-y-scroll rounded-lg border border-base-300 bg-white/70 px-[1.62rem] py-[1.19rem]'>
          {filteredFrames.map((item, index) => (
            <div
              key={`frame-${index}`}
              className={`relative cursor-pointer`}
              onClick={() => handleChangeFrame(item.value)}
            >
              <img
                src={item.imgUrl + '?q=60'}
                alt={item.title}
                className={`h-auto w-full rounded ${
                  item.value == exhibitionSettings.frame &&
                  'border-[1.5px] border-black'
                }`}
              />
              {item.value == '' && i18n.language === 'ja' ? (
                <p className='mt-1 text-left text-sm'>額縁なし</p>
              ) : (
                <p className='mt-1 text-left text-sm'>{item.title}</p>
              )}
              {item.value == exhibitionSettings.frame && (
                <img
                  src='/assets/img/check_icon.png'
                  className='absolute right-3 top-3 h-[0.875rem] w-[0.875rem]'
                />
              )}
            </div>
          ))}
        </div>
      </div>
      {(plan == 'PREMIUM' || plan == 'VIP') && (
        <>
          <div className='form-control'>
            <label className='label my-0 cursor-pointer justify-start gap-2 py-0'>
              <input
                type='radio'
                className='radio'
                // value={item.kind}
                onChange={(e) => handleChangeFrame('custom1')}
                checked={frameType === 'CUSTOM'}
              />
              <span className='label-text'>
                <Trans i18nKey={'label.create-custom-frame'} />
              </span>
            </label>
          </div>
          <div
            className={`${
              frameType == 'CUSTOM'
                ? 'pointer-events-auto opacity-100'
                : 'pointer-events-none opacity-20'
            }`}
          >
            <p className={`pb-2 pl-8 pt-3 font-[600]`}>
              <Trans i18nKey={'label.select-frame-model'} />
            </p>
            <div className={`pl-8 pr-0`}>
              <div className='grid max-h-[29rem] grid-cols-3 gap-x-2 gap-y-[0.69rem] overflow-y-scroll rounded-lg border border-base-300 bg-white/70 px-[1.62rem] py-[1.19rem]'>
                {filteredCustomFrames.map((item, index) => (
                  <div
                    key={`custom-frame-${index}`}
                    className={`relative cursor-pointer`}
                    onClick={() => handleChangeFrame(item.value)}
                  >
                    <img
                      src={item.imgUrl + '?q=60'}
                      alt={item.title}
                      className={`h-auto w-full rounded ${
                        item.value == exhibitionSettings.frame &&
                        'border-[1.5px] border-black'
                      }`}
                    />
                    <p className='mt-1 text-left text-xs'>{item.title}</p>
                    {item.value == exhibitionSettings.frame && (
                      <img
                        src='/assets/img/check_icon.png'
                        className='absolute right-3 top-3 h-[0.875rem] w-[0.875rem]'
                      />
                    )}
                  </div>
                ))}
              </div>
            </div>

            <p className='pb-2 pl-8 pt-6 font-[600]'>
              <Trans i18nKey={'label.select-frame-color'} />
            </p>
            <div className='mt-2 pl-8'>
              <HexColorPicker
                color={exhibitionSettings.frameColor}
                onChange={handleChangeFrameColor}
              />
              <div className='mt-2 '>
                <HexColorInput
                  color={exhibitionSettings.frameColor}
                  onChange={handleChangeFrameColor}
                  className='input input-bordered w-[200px]'
                />
              </div>
            </div>
            <p className='pb-2 pl-8 pt-6 font-[600]'>
              <Trans i18nKey={'label.select-matte-color'} />
            </p>
            <div className='mt-2 pl-8'>
              <HexColorPicker
                color={exhibitionSettings.matColor}
                onChange={handleChangeMatColor}
              />
              <div className='mt-2 '>
                <HexColorInput
                  color={exhibitionSettings.matColor}
                  onChange={handleChangeMatColor}
                  className='input input-bordered w-[200px]'
                />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
