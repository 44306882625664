import React from 'react';

const DeleteFaqModal = ({ handleDelete }) => {
  return (
    <dialog id='delete_faq_modal' className='modal'>
      <div className='modal-box'>
        <h3 className='text-lg font-bold'>Delete confirmation</h3>
        <p className='py-4'>Are you sure you want to delete this FAQ?</p>
        <div className='modal-action'>
          <form method='dialog'>
            <button className='btn mr-2'>Cancel</button>
            <button className='btn btn-error' onClick={handleDelete}>
              Delete
            </button>
          </form>
        </div>
      </div>
    </dialog>
  );
};

export default DeleteFaqModal;
