import { BakeShadows, Environment } from '@react-three/drei';
import { Canvas } from '@react-three/fiber';
import { Bloom, EffectComposer } from '@react-three/postprocessing';
import React, { Suspense } from 'react';

import Loader from '../../../../components/r3f/Loader';
import ItemOnPreview from '../../../../components/r3f/preview/ItemOnPreview';
import SimulatorCamera from '../../../simulator/components/SimulatorCamera';
import BasicFloorPreview from '../../../../components/r3f/floors/BasicFloorPreview';
import EditableCeiling from '../../../../components/r3f/ceilings/EditableCeiling';
import EditableWall from '../../../../components/r3f/walls/EditableWall';
import EditableFloor from '../../../../components/r3f/floors/EditableFloor';
import Man1 from '../../../../components/r3f/models/Man1';

const TextureEditPreview = ({ formData, textureData, type }) => {
  const bgColor = '#333333';
  const floorColor = '#050505';
  const directLightIntensity = 1;
  const ambientLightIntensity = 1;

  const sampleItems = [
    {
      title: 'Sample Artwork',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing',
      type: 1,
      itemUrl:
        'https://images.unsplash.com/photo-1531489956451-20957fab52f2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MjR8fHBhaW50aW5nfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60',
      coverImageUrl: '',
      createdYear: '2024',
      creatorId: 3,
      ownerId: 3,
      artworkUrl: 'https://www.google.com/',
      artworkUrlLabel: 'View artworks on site',
    },
    {
      title: 'Sample Artwork',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing',
      type: 1,
      itemUrl:
        'https://images.unsplash.com/photo-1541961017774-22349e4a1262?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NXx8cGFpbnRpbmd8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60',
      coverImageUrl: '',
      createdYear: '2024',
      creatorId: 3,
      ownerId: 3,
      artworkUrl: 'https://www.google.com/',
      artworkUrlLabel: 'View artworks on site',
    },
    {
      title: 'Sample Artwork',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing',
      type: 1,
      itemUrl:
        'https://images.unsplash.com/photo-1578059457703-850565b053f6?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=60',
      coverImageUrl: '',
      createdYear: '2024',
      creatorId: 3,
      ownerId: 3,
      artworkUrl: 'https://www.google.com/',
      artworkUrlLabel: 'View artworks on site',
    },
  ];
  return (
    <Canvas
      shadows
      dpr={1}
      frameloop='demand'
      camera={{
        fov: 70,

        position: [0, 1.3893446629166666, 6.4],
      }}
      gl={{ preserveDrawingBuffer: true }}
    >
      <SimulatorCamera />
      {/* <OrbitControls /> */}
      <BakeShadows />
      <EffectComposer>
        <Bloom intensity={0.1} />
      </EffectComposer>
      <Suspense fallback={<Loader />}>
        <color attach='background' args={[`${bgColor}`]} />
        <fog attach='fog' args={['#191920', 4, 15]} />

        <directionalLight
          position={[0, 2, 20]}
          target-position={[0, 2, 0]}
          intensity={directLightIntensity}
          castShadow
        />
        <ambientLight intensity={ambientLightIntensity} color={'#ffffff'} />

        {/* ceiling */}
        {type == 'ceiling' &&
          (textureData.colorMap ||
            textureData.aoMap ||
            textureData.displacementMap ||
            textureData.normalMap ||
            textureData.roughnessMap ||
            textureData.metalnessMap ||
            textureData.alphaMap) && (
            <EditableCeiling formData={formData} textureData={textureData} />
          )}

        {/* wall */}
        {type == 'wall' &&
          (textureData.colorMap ||
            textureData.aoMap ||
            textureData.displacementMap ||
            textureData.normalMap ||
            textureData.roughnessMap ||
            textureData.metalnessMap ||
            textureData.alphaMap) && (
            <EditableWall formData={formData} textureData={textureData} />
          )}

        {/* floor */}
        {type == 'floor' &&
        (textureData.colorMap ||
          textureData.aoMap ||
          textureData.displacementMap ||
          textureData.normalMap ||
          textureData.roughnessMap ||
          textureData.metalnessMap ||
          textureData.alphaMap) ? (
          <EditableFloor formData={formData} textureData={textureData} />
        ) : (
          <BasicFloorPreview floorColor={floorColor} />
        )}

        {sampleItems.map((item, index) => (
          <ItemOnPreview
            key={`item-${index}`}
            position={[-3 + 3 * index, 1.2, 3]}
            item={item}
            frame={null}
            frameColor={'#ffffff'}
            matColor={'#ffffff'}
          />
        ))}

        <group position={[0.2, 0, 0]}>
          <Man1 position={[1, 0, 3.4]} scale={0.45} rotation-y={Math.PI} />
        </group>
        <Environment
          files={'https://data.curatedartshow.com/hdri/studio_small_07_1k.hdr'}
        />
      </Suspense>
    </Canvas>
  );
};

export default TextureEditPreview;
