import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useTexture } from '@react-three/drei';
import * as THREE from 'three';
import { FLOORS } from '../../../data/floors';
import { invalidate } from '@react-three/fiber';

const EditableFloor = ({ formData, textureData }) => {
  const [texturePaths, setTexturePaths] = useState({});
  useEffect(() => {
    const newTexturePaths = {};
    if (textureData) {
      if (textureData.colorMap) newTexturePaths.map = textureData.colorMap;
      if (textureData.normalMap)
        newTexturePaths.normalMap = textureData.normalMap;
      if (textureData.roughnessMap)
        newTexturePaths.roughnessMap = textureData.roughnessMap;
      if (textureData.aoMap) newTexturePaths.aoMap = textureData.aoMap;
      if (textureData.displacementMap)
        newTexturePaths.displacementMap = textureData.displacementMap;
      if (textureData.metalnessMap)
        newTexturePaths.metalnessMap = textureData.metalnessMap;
      if (textureData.alphaMap) newTexturePaths.alphaMap = textureData.alphaMap;
    }

    setTexturePaths(newTexturePaths);
  }, [textureData]);

  const textureProps = useTexture(texturePaths);

  useLayoutEffect(() => {
    Object.values(textureProps).forEach((texture) => {
      texture.wrapS = THREE.RepeatWrapping;
      texture.wrapT = THREE.RepeatWrapping;
      texture.repeat.set(120, 120);
    });
  }, [textureProps]);

  const mesh = useRef();
  useEffect(() => {
    if (mesh.current) {
      mesh.current.geometry.setAttribute(
        'uv2',
        new THREE.BufferAttribute(mesh.current.geometry.attributes.uv.array, 2)
      );
    }
  }, [mesh.current, textureProps]);

  useEffect(() => {
    invalidate(); //再レンダリングを強制する
  }, [textureData, textureProps]);

  if (
    !texturePaths.colorMap &&
    !texturePaths.normalMap &&
    !texturePaths.roughnessMap &&
    !texturePaths.aoMap &&
    !texturePaths.displacementMap &&
    !texturePaths.metalnessMap &&
    !texturePaths.alphaMap
  ) {
    return null; // floorがまだロードされていないときは何も表示しない
  } else {
    return (
      <mesh
        receiveShadow
        rotation={[-Math.PI / 2, 0, 0]}
        ref={mesh}
        position={[0, -0.2, 0]}
      >
        <planeGeometry args={[200, 200]} />
        <meshStandardMaterial
          displacementScale={formData.displacementScale}
          aoMapIntensity={formData.aoMapIntensity}
          roughness={formData.roughness}
          normalScale={
            formData.normalScaleX && formData.normalScaleY
              ? new THREE.Vector2(formData.normalScaleX, formData.normalScaleY)
              : new THREE.Vector2(1, 1)
          }
          metalness={formData?.metalness ? formData.metalness : 0.0}
          {...textureProps}
        />
      </mesh>
    );
  }
};

export default EditableFloor;
