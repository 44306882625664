import React, { useEffect, useMemo, useState } from 'react';
import { errorToast, successToast } from '../../../../utils/toast';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { toCapitalCase } from '../../../../utils/StringUtils';
import { deleteWall, updateWall, uploadToS3 } from '../../../../services/Admin';
import LoadingSpinner from '../../../../components/LoadingSpinner';

import TextureForm from '../components/TextureForm';
import DeleteModal from '../../components/DeleteModal';
import { getWallById } from '../../../../services/Wall';

const AdminEditWallTexture = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const id = location.pathname.split('/')[5];

  const {
    data: wall,
    isLoading: isWallLoading,
    refetch,
  } = useQuery({
    queryFn: () => getWallById(Number(id)),
    queryKey: [`wall-${id}`],
    enabled: !!id,
  });

  const [formData, setFormData] = useState({
    name: '',
    displacementScale: 1,
    aoMapIntensity: 1,
    roughness: 1,
    metalness: 0,
    normalScaleX: 1,
    normalScaleY: 1,
    isFree: true,
    published: true,
    //以下のMapにはs3のkey（画像path）が入る
    colorMap: null,
    normalMap: null,
    roughnessMap: null,
    aoMap: null,
    displacementMap: null,
    metalnessMap: null,
    alphaMap: null,
  });

  //localの一時保存file url
  const [textureData, setTextureData] = useState({
    colorMap: null,
    normalMap: null,
    roughnessMap: null,
    aoMap: null,
    displacementMap: null,
    metalnessMap: null,
    alphaMap: null,
  });

  //localの一時保存file
  const [textureFiles, setTextureFiles] = useState({
    colorMap: null,
    normalMap: null,
    roughnessMap: null,
    aoMap: null,
    displacementMap: null,
    metalnessMap: null,
    alphaMap: null,
  });

  useEffect(() => {
    if (wall) {
      setFormData({
        name: wall.title,
        displacementScale: wall.displacementScale ? wall.displacementScale : 1,
        aoMapIntensity: wall.aoMapIntensity ? wall.aoMapIntensity : 1,
        roughness: wall.roughness ? wall.roughness : 1,
        metalness: wall.metalness ? wall.metalness : 0,
        normalScaleX: wall.normalScaleX ? wall.normalScaleX : 1,
        normalScaleY: wall.normalScaleY ? wall.normalScaleY : 1,
        isFree: !wall.isPro,
        published: wall.published,
        //以下のMapにはs3のkey（画像path）が入る
        colorMap: wall.colorMap,
        normalMap: wall.normalMap,
        roughnessMap: wall.roughnessMap,
        aoMap: wall.aoMap,
        displacementMap: wall.displacementMap,
        metalnessMap: wall.metalnessMap,
        alphaMap: wall.alphaMap,
      });

      setTextureData({
        colorMap: wall.colorMap,
        normalMap: wall.normalMap,
        roughnessMap: wall.roughnessMap,
        aoMap: wall.aoMap,
        displacementMap: wall.displacementMap,
        metalnessMap: wall.metalnessMap,
        alphaMap: wall.alphaMap,
      });
    }
  }, [wall]);

  const handleNameChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSettingChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: parseFloat(value),
    }));
  };

  const handleSettingToggle = (e) => {
    const { name, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: checked,
    }));
  };

  const s3Keys = useMemo(() => {
    const capitalCaseName = toCapitalCase(formData.name); // Convert to CapitalCase
    const basePath = `texture/walls/${capitalCaseName}`;

    return {
      colorMap: `${basePath}/basecolor`,
      normalMap: `${basePath}/normal`,
      roughnessMap: `${basePath}/roughness`,
      aoMap: `${basePath}/ao`,
      displacementMap: `${basePath}/height`,
      metalnessMap: `${basePath}/metalness`,
      alphaMap: `${basePath}/alpha`,
    };
  }, [formData.name]);

  // create処理
  const updateWallMutation = useMutation({
    mutationFn: async (data) => {
      const updatedFormData = { ...formData };
      // すべてのアップロード処理をPromise.allで待機
      await Promise.all(
        Object.entries(textureFiles).map(async ([key, file]) => {
          if (file) {
            // 生成したkeyを取得
            const s3Key = s3Keys[key];
            // s3にtexture画像をアップロード
            const uploadedKey = await uploadToS3(file, s3Key);
            // formDataのコピーを更新
            updatedFormData[key] = uploadedKey.replace(
              'curatedartshow-data.s3.amazonaws.com',
              'data.curatedartshow.com'
            );
          }
        })
      );

      const { isFree, name, ...filteredFormData } = updatedFormData;

      const requestData = {
        ...filteredFormData,
        isPro: !formData.isFree,
        title: formData.name,
      };

      return await updateWall(Number(id), requestData);
    },
    onSuccess: () => {
      navigate('/admin/textures/wall');
      successToast('Wall texture successfully saved');
    },
    onError: (err) => {
      console.log(err);
      if (err.response.data.statusCode == 403) {
        errorToast(err?.response?.data.message);
      } else {
        errorToast(
          'Something went wrong. We are aware of the problem and will try to fix it as soon as possible. Sorry for the inconvenience.'
        );
      }
    },
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    updateWallMutation.mutate();
  };

  const saveTexture = (tmpTextureData) => {
    setTextureData({ ...tmpTextureData });
  };

  const handleDelete = async () => {
    deleteWallMutation.mutate();
  };

  // delete処理
  const deleteWallMutation = useMutation({
    mutationFn: async () => {
      return await deleteWall(Number(id));
    },
    onSuccess: () => {
      document.getElementById('delete_modal').close();
      navigate('/admin/textures/wall');
      successToast('Texture deleted successfully!');
    },
    onError: (err) => {
      console.error(err);

      if (err.response.data.statusCode == 403) {
        navigate('/admin/login');
      } else {
        errorToast(
          'Something went wrong. We are aware of the problem and will try to fix it as soon as possible. Sorry for the inconvenience.'
        );
      }
    },
  });

  return (
    <div>
      <p className='pb-8 text-3xl font-extrabold'>Edit Wall Texture</p>
      <TextureForm
        formData={formData}
        textureData={textureData}
        saveTexture={saveTexture}
        handleNameChange={handleNameChange}
        handleSettingChange={handleSettingChange}
        handleSettingToggle={handleSettingToggle}
        handleSubmit={handleSubmit}
        setTextureFiles={setTextureFiles}
      />

      <DeleteModal
        itemName='texture'
        confirmDelete={handleDelete}
        loading={deleteWallMutation.isPending}
      />
      {(updateWallMutation.isPending || isWallLoading.isPending) && (
        <LoadingSpinner />
      )}
    </div>
  );
};

export default AdminEditWallTexture;
