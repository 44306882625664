import React, { useState } from 'react';
import { TrashIcon } from '@heroicons/react/24/outline';
import { Link, useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { CalendarDaysIcon } from '@heroicons/react/24/solid';
import {
  validateCouponCode,
  validateCouponDuration,
  validateCouponNote,
  validateCouponTitle,
} from '../../../utils/validation';
import { useDispatch, useSelector } from 'react-redux';
import { loadEnd, loadStart } from '../../../redux/loadRedux';
import { successToast, errorToast } from '../../../utils/toast';
import { authRequest } from '../../../requestMethods';
import LoadingSpinner from '../../../components/LoadingSpinner';

const CouponCodeCreate = () => {
  const { isLoading } = useSelector((state) => state.isLoading);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [type, setType] = useState('FREE_TRIAL');
  const [title, setTitle] = useState('');
  const [code, setCode] = useState('');
  const [duration, setDuration] = useState(null);
  const [note, setNote] = useState('');

  // 1ヶ月先の日付を計算
  const getOneMonthLater = () => {
    const today = new Date();
    return new Date(today.setMonth(today.getMonth() + 1));
  };

  const [expiredAt, setExpiredAt] = useState(getOneMonthLater());

  //error
  const [titleError, setTitleError] = useState('');
  const [codeError, setCodeError] = useState('');
  const [durationError, setDurationError] = useState('');
  const [noteError, setNoteError] = useState('');
  const [expiredAtError, setExpiredAtError] = useState('');

  const reflectTitle = (title) => {
    const result = validateCouponTitle(title);
    result.isValid ? setTitleError('') : setTitleError(result.message);
    setTitle(title);
  };
  const reflectCode = (code) => {
    const result = validateCouponCode(code.toUpperCase());
    result.isValid ? setCodeError('') : setCodeError(result.message);
    setCode(code.toUpperCase());
  };

  const reflectDuration = (duration) => {
    const result = validateCouponDuration(Number(duration));
    result.isValid ? setDurationError('') : setDurationError(result.message);
    setDuration(Number(duration));
  };
  const reflectNote = (note) => {
    const result = validateCouponNote(note);
    result.isValid ? setNoteError('') : setNoteError(result.message);
    setNote(note);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(loadStart());
    try {
      await authRequest.post('/admin/coupons', {
        type,
        title,
        code,
        duration,
        expiredAt,
        note,
      });
      setTitle('');
      setCode('');
      setDuration('');
      setExpiredAt(getOneMonthLater());
      setNote('');
      navigate('/admin/coupon-codes');
      successToast('The coupon has been successfully created.');
    } catch (err) {
      errorToast('Coupon creation failed. The same Coupon Code may exist.');
      console.log(err);
    } finally {
      dispatch(loadEnd());
    }
  };

  return (
    <div className='w-[500px]'>
      <h2 className='text-3xl font-extrabold'>Create New Coupon Code</h2>
      <form className='mt-8 flex flex-col gap-6'>
        <label className='form-control w-full'>
          <div className='label'>
            <span className='label-text'>Coupon Type</span>
          </div>
          <select className='select select-bordered' value={type ? type : ''}>
            <option value={'FREE_TRIAL'}>Free Trial</option>
          </select>
        </label>

        <label className='form-control w-full'>
          <div className='label'>
            <span className='label-text'>Coupon Title</span>
          </div>
          <input
            type='text'
            maxLength='46'
            value={title}
            placeholder={
              'Enter a descriptive title to easily identify this coupon'
            }
            className={`input input-bordered w-full ${
              titleError ? 'input-error' : ''
            }`}
            onChange={(e) => reflectTitle(e.target.value)}
          />
          {titleError && (
            <div className='label'>
              <span className='label-text-alt text-error'>{titleError}</span>
            </div>
          )}
        </label>

        <label className='form-control w-full'>
          <div className='label'>
            <span className='label-text'>Coupon Code</span>
          </div>
          <input
            type='text'
            maxLength='8'
            value={code}
            placeholder={'Type coupon code'}
            className={`input input-bordered w-full ${
              codeError ? 'input-error' : ''
            }`}
            onChange={(e) => reflectCode(e.target.value)}
          />
          <div className='label'>
            <span className='label-text-alt'>
              Coupon codes are not case-sensitive.
            </span>
            <span className='label-text-alt text-error'>{codeError}</span>
          </div>
        </label>

        <label className='form-control w-full'>
          <div className='label'>
            <span className='label-text'>Coupon Expiration Date</span>
          </div>
          <p className='px-1 pb-4 pt-2 text-xs'>
            The last date this coupon can be used for a discount.
          </p>
          <div className='flex w-fit cursor-pointer items-center rounded-lg border px-4 pb-3 pt-3.5'>
            {/* カレンダーアイコン */}
            <CalendarDaysIcon className='mr-2 h-4 w-4' />
            {/* DatePickerコンポーネント */}
            <DatePicker
              selected={expiredAt}
              minDate={new Date()}
              onChange={(date) => setExpiredAt(date)}
              dateFormat='MMM dd, yyyy'
              className='w-[6.5rem] cursor-pointer border-none outline-none' // DatePicker自体の境界線を無効に
            />
          </div>
          <p className='form-error' id='artwork-name-error'>
            {expiredAtError}
          </p>
        </label>

        <label className='form-control w-full'>
          <div className='label'>
            <span className='label-text'>Discount Duration</span>
          </div>
          <p className='px-1 pb-4 pt-2 text-xs'>
            A coupon’s duration indicates how long the redeemed discount is
            valid for.
          </p>
          <div className='flex items-center gap-2'>
            <input
              className={`input input-bordered w-[7.5rem] ${
                durationError && 'input-error'
              }`}
              maxLength='255'
              max={730}
              min={1}
              type='number'
              value={duration}
              placeholder={1}
              onChange={(e) => reflectDuration(e.target.value)}
            />
            <span>days</span>
          </div>
          <div className='label'>
            <span className='label-text-alt text-error'>{durationError}</span>
          </div>
        </label>

        <label className='form-control'>
          <div className='label'>
            <span className='label-text'>Usage Notes</span>
          </div>
          <textarea
            className={`textarea textarea-bordered ${
              noteError ? 'textarea-error' : ''
            }`}
            maxLength={3000}
            placeholder='Please provide any notes about the purpose or use of this coupon.'
            rows={4}
            onChange={(e) => {
              reflectNote(e.target.value);
            }}
            value={note}
          ></textarea>
          <div className='label'>
            <span className='label-text-alt'>{noteError}</span>
          </div>
        </label>

        <div className='flex justify-between'>
          <Link to={'/admin/coupon-codes'} className='btn'>
            Cancel
          </Link>
          <button
            className='btn btn-primary'
            onClick={handleSubmit}
            disabled={
              !type ||
              !title ||
              !expiredAt ||
              !code ||
              !duration ||
              expiredAtError ||
              codeError ||
              titleError ||
              durationError
            }
          >
            Submit
          </button>
        </div>
      </form>
      {isLoading && <LoadingSpinner />}
    </div>
  );
};

export default CouponCodeCreate;
