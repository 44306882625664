import React, { useEffect, useState } from 'react';
import { authRequest, publicRequest } from '../../requestMethods';
import { useSelector } from 'react-redux';
import { PlusIcon } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';
import RoomCardWithMenu from '../../components/room/RoomCardWithMenu';
import SkeletonRoomCard from '../../components/room/SkeletonRoomCard';
import dayjs from 'dayjs';
import { Trans, useTranslation } from 'react-i18next';
import { errorToast, successToast } from '../../utils/toast';
import { ReactSortable } from 'react-sortablejs';

const Rooms = ({ plan }) => {
  const { t } = useTranslation();
  const [rooms, setRooms] = useState([]);
  const [draftRooms, setDraftRooms] = useState([]);
  const [disabledRooms, setDisabledRooms] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sorded, setSorted] = useState(false);
  const [hasReachedMax, setHasReachedMax] = useState(false);

  const user = useSelector((state) => state.user.currentUser);
  const {
    REACT_APP_MAX_EXHIBITION_MEMBER: MAX_EXHIBITION_FREE,
    REACT_APP_MAX_EXHIBITION_PREMIUM: MAX_EXHIBITION_PREMIUM,
    REACT_APP_MAX_EXHIBITION_VIP: MAX_EXHIBITION_VIP,
  } = process.env;

  const getData = async () => {
    try {
      const res = await publicRequest.get(`/rooms/users/${user.id}`);
      const draftRoomData = await authRequest.get(`/rooms/drafts/${user?.id}`);
      const disabledRoomData = await authRequest.get(
        `/rooms/disabled/${user?.id}`
      );
      setRooms([{ id: 0 }, ...res.data]);
      setDraftRooms(draftRoomData.data);
      setDisabledRooms(disabledRoomData.data);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    getData();
  }, [loading]);

  const handleUnpublish = async (id) => {
    try {
      setLoading(true);
      await authRequest.post(`/rooms/unpublish/${id}`);
      getData();
      successToast(t('message.success.t16'));
    } catch (err) {
      console.log(err);
      errorToast(t('message.error.t1'));
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateOrder = async () => {
    try {
      const roomIds = rooms.slice(1).map((room) => Number(room.id));
      await authRequest.post('/rooms/change-sort', { roomIds });
      setSorted(false);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (rooms.length > 0 && sorded) {
      handleUpdateOrder();
    }
  }, [rooms]);

  const checkMaxCreation = async () => {
    const galleryData = await publicRequest.get(`/rooms/users/${user.id}`);
    const draftRoomData = await authRequest.get(`/rooms/drafts/${user?.id}`);
    // 作成数の上限 (公開済 + 下書き数)

    if (
      (galleryData.data.length + draftRoomData.data.length >=
        Number(MAX_EXHIBITION_FREE) &&
        (plan == 'FREE' || !plan)) ||
      (galleryData.data.length + draftRoomData.data.length >=
        Number(MAX_EXHIBITION_PREMIUM) &&
        plan == 'PREMIUM') ||
      (galleryData.data.length + draftRoomData.data.length >=
        Number(MAX_EXHIBITION_VIP) &&
        plan == 'VIP')
    ) {
      setHasReachedMax(true);
    }
  };

  useEffect(() => {
    checkMaxCreation();
  }, [user, plan]);

  return (
    <div>
      {loading ? (
        <div className='grid gap-[1.5rem] md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4'>
          {hasReachedMax ? (
            <div className='border-gray-light flex aspect-square w-full cursor-not-allowed flex-col items-center justify-center rounded-2xl border-2 border-dashed'>
              <div className='flex max-w-60 flex-col items-center gap-2'>
                <div className='flex h-16 w-16 items-center justify-center rounded-full bg-gray-100 text-gray-400'>
                  <PlusIcon className='h-10 w-10 stroke-2' />
                </div>
                <div className='small-btn border-gray-light bg-gray-light btn-disabled !cursor-not-allowed'>
                  <Trans i18nKey={'dashboard.create-exhib'} />
                </div>

                <p className={`text-center text-xs text-[#6A6A6A]`}>
                  You’ve reached the maximum limit of{' '}
                  {plan == 'VIP'
                    ? MAX_EXHIBITION_VIP
                    : plan == 'PREMIUM'
                    ? MAX_EXHIBITION_PREMIUM
                    : MAX_EXHIBITION_FREE}{' '}
                  exhibitions creations. You have reached the maximum content
                  limit for your current plan,{' '}
                  {plan == 'VIP'
                    ? 'VIP CAS Member'
                    : plan == 'PREMIUM'
                    ? 'Premium CAS Member'
                    : 'CAS Member'}
                  . Please note that no further content can be created under
                  this plan.
                </p>
              </div>
            </div>
          ) : (
            <Link to='/create-exhibition'>
              <div className='flex aspect-square h-full w-full flex-col items-center justify-center gap-8 rounded-2xl border-2 border-dashed'>
                <div className='flex h-16 w-16 items-center justify-center rounded-full bg-purple-50 text-primary'>
                  <PlusIcon className='h-10 w-10 stroke-2' />
                </div>
                <div className='btn btn-primary btn-sm'>
                  <Trans i18nKey={'dashboard.create-exhib'} />
                </div>
              </div>
            </Link>
          )}
          {Array(8)
            .fill()
            .map((_, index) => (
              <SkeletonRoomCard key={`SkeletonRoomCard_${index}`} />
            ))}
        </div>
      ) : (
        <div>
          <ReactSortable
            list={rooms}
            setList={setRooms}
            handle='.sort-handle'
            className='grid gap-[1.5rem] md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4'
            draggable={'.drag-item'}
            onUpdate={() => setSorted(true)}
          >
            {rooms.map((room, index) => {
              if (room.id == 0) {
                return (
                  <>
                    {hasReachedMax ? (
                      <div
                        key={`hasReachedMax_${index}`}
                        className='border-gray-light flex h-[14rem] w-full flex-shrink-0 cursor-not-allowed flex-col items-center justify-center gap-[0.625rem] rounded-2xl border-2 border-dashed bg-white p-5 md:mr-10 md:h-full'
                      >
                        <div className='flex h-16 w-16 items-center justify-center rounded-full bg-gray-100 text-gray-400'>
                          <PlusIcon className='h-10 w-10' />
                        </div>
                        <div className='border-gray-light bg-gray-light btn btn-disabled btn-sm !cursor-not-allowed'>
                          <Trans i18nKey={'dashboard.create-exhib'} />
                        </div>

                        <p className={`text-center text-sm text-[#6A6A6A]`}>
                          You’ve reached the maximum limit of{' '}
                          {plan == 'VIP'
                            ? MAX_EXHIBITION_VIP
                            : plan == 'PREMIUM'
                            ? MAX_EXHIBITION_PREMIUM
                            : MAX_EXHIBITION_FREE}{' '}
                          exhibitions creations. You have reached the maximum
                          content limit for your current plan,{' '}
                          {plan == 'VIP'
                            ? 'VIP CAS Member'
                            : plan == 'PREMIUM'
                            ? 'Premium CAS Member'
                            : 'CAS Member'}
                          . Please note that no further content can be created
                          under this plan.
                        </p>
                      </div>
                    ) : (
                      <Link
                        key={`hasReachedMax_${index}`}
                        to='/create-exhibition'
                      >
                        <div className='flex aspect-square h-[12rem] w-full flex-col items-center justify-center gap-4 rounded-2xl border-2 border-dashed  bg-white md:h-full'>
                          <div className='flex h-16 w-16 items-center justify-center rounded-full bg-purple-50 text-primary'>
                            <PlusIcon className='h-10 w-10 stroke-2' />
                          </div>
                          <div className='btn btn-primary btn-sm'>
                            <Trans i18nKey={'dashboard.create-exhib'} />
                          </div>
                        </div>
                      </Link>
                    )}
                  </>
                );
              } else {
                return (
                  <RoomCardWithMenu
                    key={`RoomCardWithMenu_${index}`}
                    room={room}
                    togglePublish={handleUnpublish}
                    isPublished
                  />
                );
              }
            })}
          </ReactSortable>
        </div>
      )}

      {!loading && (
        <>
          {disabledRooms?.length > 0 && (
            <div className='mb-28 mt-12 flex flex-col gap-6'>
              <h2 className='text-2xl font-bold text-error'>
                Resubscribe before it’s gone
              </h2>
              <p className='font-bold'>
                Your subscription will be cancelled and this exhibition will be
                deleted on{' '}
                {dayjs(disabledRooms[0].expiryDate)
                  .locale('en')
                  .format('MMM D, YYYY')}
                .
                <br />
                If you do not wish to be removed, please re-subscribe!
              </p>
              <Link
                to='/settings/upgrade-membership'
                className='btn btn-error btn-block md:btn-wide'
              >
                Resubscribe
              </Link>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Rooms;
