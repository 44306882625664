import { useGLTF } from '@react-three/drei';
import { Suspense, useLayoutEffect, useState } from 'react';

const PictureFrame46 = ({ color, ...props }) => {
  const { scene, materials } = useGLTF(
    'https://data.curatedartshow.com/models/frames/yellowblue.glb'
  );

  const [geometry, setGeometry] = useState();

  if (!geometry) {
    const cloneScene = scene.clone(true);
    setGeometry(cloneScene);
  }

  useLayoutEffect(() => {
    // console.log(materials);
    // materials['fabric_tarpaulin_material'].color.set('#ebeaea');
    materials['fabric_tarpaulin_material'].metalness = 1;
    materials['fabric_tarpaulin_material'].roughness = 2;
    materials['Used Wheat Silk_material'].color.set('#d8d595');
    materials['Used Wheat Silk_material'].metalness = 0.8;
    materials['Used Wheat Silk_material'].roughness = 1;
    materials['Clean Blue Tarp_material'].color.set('#7c87cd');
    materials['Clean Blue Tarp_material'].metalness = 0.5;
    materials['Clean Blue Tarp_material'].roughness = 2;

    geometry.traverse((obj) => {
      if (obj.isMesh) obj.castShadow = obj.receiveShadow = true;
    });
  }, []);

  return (
    <Suspense fallback={null}>
      <primitive object={geometry} {...props} />
    </Suspense>
  );
};

export default PictureFrame46;
