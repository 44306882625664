import { BakeShadows, Environment, Image, useGLTF } from '@react-three/drei';
import { useLoader, useThree } from '@react-three/fiber';
import { Bloom, EffectComposer } from '@react-three/postprocessing';
import React, { Suspense, useEffect, useLayoutEffect, useMemo } from 'react';

import Man1 from '../models/Man1.jsx';
import ClassicWall from '../walls/ClassicWall.jsx';
import ClassicFloor from '../floors/ClassicFloor.jsx';
import { TextureLoader } from 'three';
import SpotLightOnPreview from '../lights/SpotLightOnPreview.js';

import ObjectLight1 from '../models/objectLights/ObjectLight1.jsx';
import ObjectLight2 from '../models/objectLights/ObjectLight2.jsx';
import ObjectLight3 from '../models/objectLights/ObjectLight3.jsx';
import CustomCeiling from '../ceilings/CustomCeiling.jsx';
import CustomWall from '../walls/CustomWall.jsx';
import CustomFloor from '../floors/CustomFloor.jsx';

import ItemOnPreview from './ItemOnPreview.jsx';

const ClassicRoomPreview = ({
  theme,
  frame,
  showSilhouette,
  setSnapshot,
  items,
  ceiling,
  wall,
  floor,
  objectLight,
  frameColor,
  matColor,
}) => {
  const GOLDENRATIO = 1.61803398875;
  const { camera, gl } = useThree();

  const imageMap = useLoader(
    TextureLoader,
    items.length > 0 ? items[0]?.itemUrl : '/assets/img/sample_item_image.png'
  );

  const aspectRatio =
    imageMap.source?.data?.width / imageMap.source?.data?.height;
  const scaleValues = useMemo(() => {
    const scaleY = GOLDENRATIO;
    const scaleX = scaleY * aspectRatio;
    return { scaleX, scaleY };
  }, [aspectRatio]);
  const { scaleX, scaleY } = scaleValues;

  const directLightIntensity = floor == 4 || floor == 6 || floor == 11 ? 1 : 5;
  const ambientLightIntensity = 0.1;

  useEffect(() => {
    return () => {
      gl.dispose();
      // imageMap.dispose();
      useLoader.clear(
        TextureLoader,
        items.length > 0
          ? items[0]?.itemUrl
          : '/assets/img/sample_item_image.png'
      );
    };
  }, [gl]);

  useEffect(() => {
    camera.lookAt(0, 0.8, 1.1);
  }, [camera]);

  return (
    <>
      <BakeShadows />
      <EffectComposer>
        <Bloom intensity={0} />
      </EffectComposer>
      {/* lights */}

      <color attach='background' args={[`#191920`]} />
      {/* 他のテーマのfogが残ることがあるので擬似fogを設定 */}
      <fog attach='fog' args={['#000000', 20, 20]} />

      <directionalLight intensity={directLightIntensity} />
      <ambientLight intensity={ambientLightIntensity} />

      <SpotLightOnPreview opacity={0} anglePower={0} />

      {objectLight == 1 &&
        Array.from({ length: 2 }, (_, index) => (
          <ObjectLight1
            key={index}
            position={[-4 + index * 8, 1.7, 0.6]}
            scale={1}
          />
        ))}
      {objectLight == 2 &&
        Array.from({ length: 2 }, (_, index) => (
          <ObjectLight2
            key={index}
            position={[-4 + index * 8, 4, 0.2]}
            scale={1.2}
          />
        ))}
      {objectLight == 3 &&
        Array.from({ length: 2 }, (_, index) => (
          <ObjectLight3
            key={index}
            position={[-4 + index * 8, 4.4, 0.12]}
            scale={1}
          />
        ))}

      <group position={[0, -0.5, 0]}>
        {items.length == 1 && (
          <ItemOnPreview
            position={[-0.2, 0.85, 3]}
            item={items[0]}
            frame={frame}
            frameColor={frameColor}
            matColor={matColor}
          />
        )}
        {items.length == 2 && (
          <>
            <ItemOnPreview
              position={[-1.3, 0.85, 3]}
              item={items[0]}
              frame={frame}
              frameColor={frameColor}
              matColor={matColor}
            />

            <ItemOnPreview
              position={[1.3, 0.85, 3]}
              item={items[1]}
              frame={frame}
              frameColor={frameColor}
              matColor={matColor}
            />
          </>
        )}
        {items.length >= 3 && (
          <>
            <ItemOnPreview
              position={[-3.6, 0.85, 3]}
              item={items[0]}
              frame={frame}
              frameColor={frameColor}
              matColor={matColor}
            />

            <ItemOnPreview
              position={[-0.2, 0.85, 3]}
              item={items[1]}
              frame={frame}
              frameColor={frameColor}
              matColor={matColor}
            />

            <ItemOnPreview
              position={[3.6, 0.85, 3]}
              item={items[2]}
              frame={frame}
              frameColor={frameColor}
              matColor={matColor}
            />
          </>
        )}
        {showSilhouette && (
          <Man1 position={[-0.5, 0, 3.8]} scale={0.45} rotation-y={Math.PI} />
        )}

        {/* wall */}
        <ClassicWall />
        {/* floor */}
        <ClassicFloor />
      </group>

      <Environment
        files={'https://data.curatedartshow.com/hdri/potsdamer_platz_1k.hdr'}
      />
    </>
  );
};

export default ClassicRoomPreview;
