import { useContext, useEffect, useMemo, useState } from 'react';
import { useRef } from 'react';
import { useLoader } from '@react-three/fiber';
import { useCursor, Image, Html } from '@react-three/drei';
import { TextureLoader } from 'three/src/loaders/TextureLoader';
import ReactGA from 'react-ga4';
import PictureFrameGroup from './frames/PictureFrameGroup';
import { ExhibitionContext } from '../../context/ExhibitionContext';
import { ExhibitionControllerContext } from '../../context/ExhibitionControllerContext';

const Item = ({ item, index }) => {
  const image = useRef();
  const { exhibitionSettings } = useContext(ExhibitionContext);

  const {
    slideshowMode,
    setSlideshowMode,
    setCurrentItem,
    setSelectedItem,
    setZoomItemImageUrl,
    zoomItemId,
    setZoomItemId,
    setInquiryItem,
  } = useContext(ExhibitionControllerContext);

  const [hovered, hover] = useState(false);
  const [htmlPosition, setHtmlPosition] = useState([0, 0, 0]);

  const name = index.toString();

  const GOLDENRATIO = 1.61803398875;
  const scaleAdjustment = 0.95;

  useCursor(hovered);

  const imageMap = useLoader(TextureLoader, item?.itemUrl + '?q=60');
  const w = imageMap.source.data.width;
  const h = imageMap.source.data.height;
  // const colorMap = useLoader(TextureLoader, '/assets/img/geometry_orange.png');

  const aspectRatio =
    imageMap.source?.data?.width / imageMap.source?.data?.height;
  const scaleValues = useMemo(() => {
    const scaleY = GOLDENRATIO;
    const scaleX = scaleY * aspectRatio;
    return { scaleX, scaleY };
  }, [aspectRatio]);
  const { scaleX, scaleY } = scaleValues;

  const sendEvent = (actionType) => {
    if (ReactGA.isInitialized) {
      // Page　Viewイベントを送信
      ReactGA.send({
        hitType: 'pageview',
        page: `/galleries/${exhibitionSettings.roomId}`,
        title: `${item.title}`,
      });
      // カスタムイベントを送信
      ReactGA.event({
        category: 'Artwork',
        action: actionType,
        label: `${item.title}`,
        value: item.id,
      });
      console.log('send');
    } else {
      console.log('GA Not Initialized');
    }
  };

  useEffect(() => {
    if (h / w >= 1.7) {
      // 9:16(1.77)
      setHtmlPosition([0.8 * 0.6, ((-(h / w) * 2) / 10) * 0.8, 0]);
    } else if (h / w >= 1.4) {
      // 2:3(1.5)
      setHtmlPosition([0.8 * 0.6, ((-(h / w) * 2) / 10) * 0.4, 0]);
    } else if (h / w >= 1.3) {
      // 3:4(1.33)
      setHtmlPosition([0.8 * 0.6, ((-(h / w) * 2) / 10) * 0.01, 0]);
    } else if (h / w >= 1.2) {
      setHtmlPosition([0.8 * 0.6, 0.01, 0]);
    } else if (h / w >= 1) {
      setHtmlPosition([0.8 * 0.6, 0.18, 0]);
    } else if (h / w >= 0.7) {
      // 4:3(0.75)
      setHtmlPosition([0.8 * 0.6, 0.32, 0]);
    } else if (h / w >= 0.6) {
      // 3:2(0.66)
      setHtmlPosition([0.8 * 0.6, 0.4, 0]);
    } else if (h / w >= 0.5) {
      // 16:9(0.56)
      setHtmlPosition([0.8 * 0.6, 0.46, 0]);
    }
  }, []);
  const [clickDisabled, setClickDisabled] = useState(false);

  const handleChangeSlideshowMode = (value) => {
    setSlideshowMode(value);
  };

  return (
    <group
      onPointerEnter={() => hover(true)}
      onPointerOut={() => hover(false)}
      position={[2 * index * 1.3, 0.85, 3]}
      onClick={(e) => {
        if (clickDisabled) return; // Exit if click is disabled
        if (slideshowMode) {
          handleChangeSlideshowMode(false);
          return;
        }
        if (zoomItemId !== item.id) {
          // stopPropagation前に送信
          sendEvent('enter_fullscreen');
        }
        setClickDisabled(true); // Disable click
        setTimeout(() => setClickDisabled(false), 3000);
        e.stopPropagation();

        // setSelectedItem(item);
        if (zoomItemId == item.id) {
          setZoomItemId(null);
          setZoomItemImageUrl(null);
          setCurrentItem(null);
        } else {
          setZoomItemId(item.id);
          setCurrentItem(item);
          setZoomItemImageUrl(item.itemUrl);
          // setTimeout(() => {
          // }, 3000);
        }
      }}
    >
      <mesh
        name={name}
        scale={[1, 1, 0.05]}
        position={[0, GOLDENRATIO / 2.5, 0]}
      >
        <PictureFrameGroup
          item={item}
          frame={exhibitionSettings?.frame}
          GOLDENRATIO={GOLDENRATIO}
          aspectRatio={aspectRatio}
          frameColor={exhibitionSettings?.frameColor}
          matColor={exhibitionSettings?.matColor}
        />

        {/* need one geometry to be able to Clicked */}
        <mesh
          scale={[
            aspectRatio < 1 ? scaleAdjustment : 1.2,
            aspectRatio < 1 ? scaleAdjustment / aspectRatio : 1.2 / aspectRatio,
            1,
          ]}
        >
          <boxGeometry />
        </mesh>
        <mesh>
          <Image
            raycast={() => null}
            ref={image}
            position={[0, 0, 0.7]}
            url={item?.itemUrl + '?q=60'}
            scale={[
              aspectRatio < 1 ? scaleAdjustment : 1.2,
              aspectRatio < 1
                ? scaleAdjustment / aspectRatio
                : 1.2 / aspectRatio,
              1,
            ]}
            toneMapped={false}
          />
        </mesh>
      </mesh>
      {!zoomItemId && (
        <Html wrapperClass='!fixed !z-0 w-[100px]' position={[0.9, 0.2, 0]}>
          <div
            className={`hidden h-[5.625rem] w-[9.375rem] cursor-pointer rounded border-[1.5px] border-[#868686] bg-white/60 p-4 md:flex md:flex-col md:gap-2`}
            onClick={(e) => {
              sendEvent('open_modal');
              e.stopPropagation();
              setSelectedItem(item);
            }}
          >
            <p className={`line-clamp-2 text-xs font-medium text-base-content`}>
              {item?.title}
            </p>
            {item?.artistName && (
              <p
                className={`line-clamp-1 text-xs font-medium text-base-content`}
              >
                {item.artistName}
              </p>
            )}
          </div>
        </Html>
      )}
    </group>
  );
};

export default Item;
