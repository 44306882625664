import { useThree } from '@react-three/fiber';
import React, { useEffect, useRef } from 'react';

const BasicFloorPreview = ({ floorColor, mixStrength = 1 }) => {
  const meshRef = useRef();
  const { gl } = useThree();

  useEffect(() => {
    return () => {
      gl.dispose();

      // コンポーネントがアンマウントされるときにリソースをクリーンアップ
      if (meshRef.current) {
        meshRef.current.material.dispose();
        if (Array.isArray(meshRef.current.material)) {
          meshRef.current.material.forEach((material) => {
            material.dispose();
            if (material.map) material.map.dispose();
            if (material.normalMap) material.normalMap.dispose();
            if (material.roughnessMap) material.roughnessMap.dispose();
            if (material.metalnessMap) material.metalnessMap.dispose();
            // その他のテクスチャがあれば同様にクリーンアップ
          });
        } else {
          if (meshRef.current.material.map)
            meshRef.current.material.map.dispose();
          if (meshRef.current.material.normalMap)
            meshRef.current.material.normalMap.dispose();
          if (meshRef.current.material.roughnessMap)
            meshRef.current.material.roughnessMap.dispose();
          if (meshRef.current.material.metalnessMap)
            meshRef.current.material.metalnessMap.dispose();
          // その他のテクスチャがあれば同様にクリーンアップ
        }
      }
    };
  }, []);

  return (
    <>
      <mesh ref={meshRef} rotation={[-Math.PI / 2, 0, 0]} receiveShadow>
        <planeGeometry args={[150, 50]} />
        <meshStandardMaterial
          metalness={0.4}
          roughness={1}
          color={`${floorColor}`}
        />
      </mesh>
    </>
  );
};

export default BasicFloorPreview;
