import { useSelector } from 'react-redux';
import LoadingSpinner from '../../components/LoadingSpinner';
import { Trans } from 'react-i18next';

const DraftItemConfirmationModal = ({ item, unpublish }) => {
  const { isLoading } = useSelector((state) => state.isLoading);

  return (
    <dialog id='draft_confirmation_modal' className='modal'>
      <div className='modal-box max-w-lg'>
        <h3 className='m-0 font-bold text-lg'>
          <Trans i18nKey={'artwork.unpublish-ttl'} />
        </h3>
        <p className='py-4'>
          <Trans i18nKey={'artwork.unpublish-t1'} />
        </p>
        <p className='py-4'>
          <Trans i18nKey={'artwork.unpublish-t2'} />
        </p>
        <div className='modal-action'>
          <form method='dialog'>
            <button className='btn'>
              <Trans i18nKey={'btn.close'} />
            </button>
          </form>
          <button
            className='btn btn-primary'
            data-dialog-close='true'
            onClick={() => {
              unpublish(item.id);
              document.getElementById('draft_confirmation_modal').close();
            }}
          >
            <Trans i18nKey={'btn.unpublish'} />
          </button>
        </div>
      </div>
      {isLoading && <LoadingSpinner />}
    </dialog>
  );
};

export default DraftItemConfirmationModal;
