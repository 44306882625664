import React from 'react';

const Light4 = ({ color, length = 5 }) => {
  let meshColor = [5, 2, 0.5];

  switch (color) {
    case 'RED':
      meshColor = [20, 2, 2];
      break;
    case 'BLUE':
      meshColor = [2, 2, 20];
      break;
    case 'WHITE':
      meshColor = [4, 4, 4];
      break;
    case 'YELLOW':
      meshColor = [6, 6, 2];
      break;
    case 'GREEN':
      meshColor = [1.2, 20, 1.2];
      break;
    case 'PINK':
      meshColor = [5, 0.3, 5];
      break;
    default:
      meshColor = [4, 4, 4];
      break;
  }
  return (
    <>
      {Array(3 * length)
        .fill()
        .map((_, index) => (
          <mesh
            scale={1}
            position={[index * 3 - 10, 1.8, 0]}
            // rotation-z={Math.sin(index) * 0.3}
            // rotation={[-Math.PI / 2, 0, Math.PI / 2.5]}
            key={index}
          >
            <boxGeometry args={[2, 2, 0.08]} />
            {/* <meshStandardMaterial color={[3, 3, 3]} toneMapped={false} /> */}
            <meshStandardMaterial toneMapped={false} color={meshColor} />
          </mesh>
        ))}
    </>
  );
};

export default Light4;
