import { useEffect, useMemo, useState } from 'react';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { authRequest, publicRequest } from '../../requestMethods';
import dayjs from 'dayjs';
import { errorToast, successToast } from '../../utils/toast';
import { loginSuccess, logout } from '../../redux/userRedux';
import { loadEnd, loadStart } from '../../redux/loadRedux';
import LoadingSpinner from '../../components/LoadingSpinner';
import { Trans, useTranslation } from 'react-i18next';
import CancelSubscriptionModal from './components/CancelSubscriptionModal';
import { BoltIcon } from '@heroicons/react/24/solid';

const ManageSubscription = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    //product ID
    REACT_APP_STRIPE_PREMIUM_CAS_MEMBER_PRODUCT_ID: PREMIUM_ID,
    REACT_APP_STRIPE_VIP_CAS_MEMBER_PRODUCT_ID: VIP_ID,

    REACT_APP_MAX_PUBLISHED_EXHIBITION_MEMBER: MAX_PUBLISHED_EXHIBITION_CAS1,
    REACT_APP_MAX_PUBLISHED_EXHIBITION_PREMIUM: MAX_PUBLISHED_EXHIBITION_CAS5,
    REACT_APP_MAX_EXHIBITION_MEMBER: MAX_EXHIBITION_CAS1,
    REACT_APP_MAX_EXHIBITION_PREMIUM: MAX_EXHIBITION_CAS5,
  } = process.env;

  const user = useSelector((state) => state.user.currentUser);

  const [userSubscription, setUserSubscription] = useState(null);

  const [plan, setPlan] = useState(null);

  const [isFreeTrial, setIsFreeTrial] = useState(false);
  const { i18n } = useTranslation();

  const fetchUserSubscription = async (subscriptionId) => {
    const res = await publicRequest.get(`/stripe/${subscriptionId}`);
    setUserSubscription(res.data);
  };

  useEffect(() => {
    // 初期値を設定するための変数
    let foundPlan = '';
    if (user?.userPlans?.length > 0) {
      // userPlans配列の各要素をチェック
      user.userPlans.forEach((el) => {
        if (el.SubscriptionPlans.productId === PREMIUM_ID) {
          foundPlan = 'PREMIUM';
        } else if (el.SubscriptionPlans.productId === VIP_ID) {
          foundPlan = 'VIP';
        }
      });
    }
    // 設定したプラン名を反映
    if (foundPlan) {
      setPlan(foundPlan);
    } else {
      //何もサブスクしていない場合はFreeプラン
      setPlan('FREE');
    }
  }, [user]);

  // 加入プラン名、オプション名、サブスク期限を取得
  // FIXME: user.userPlansにVIPが含まれていないためVIP Supportありなしの判定を行えない
  useEffect(() => {
    const subscriptionPlans = user.userPlans.map(
      (item) => item.SubscriptionPlans
    );
    const plan = subscriptionPlans.find(
      (plan) => plan.productId === PREMIUM_ID || plan.productId === VIP_ID
    );

    if (!plan) {
      setIsFreeTrial(true);
    }

    if (user) {
      fetchUserSubscription(user.userSubscriptionId);
    }
  }, [user]);

  // 加入中プランの価格
  const currentPlanPrice = useMemo(() => {
    return userSubscription ? userSubscription.amountTotal / 100 : 'NOT FOUND';
  }, [userSubscription]);

  // 次回支払日
  const expireDate = useMemo(() => {
    if (userSubscription) {
      const unix = dayjs.unix(userSubscription.currentPeriodEnd);
      return i18n.language === 'ja'
        ? unix.format('YYYY年M月D日')
        : unix.format('MMM D, YYYY');
    }
    return 'NOT FOUND';
  }, [userSubscription, i18n.language]);

  const handleUpdatePayment = async () => {
    try {
      const res = await authRequest.post('/stripe/setup-payment');
      const checkoutUrl = res.data.url;
      window.location.href = checkoutUrl;
    } catch (error) {
      console.error(error);
    }
  };

  const loadLatestUserData = async () => {
    try {
      const res = await authRequest.get(`/auth/latest`);
      // ログイン状態にする
      dispatch(loginSuccess(res.data));
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(loadEnd());
    }
  };

  useEffect(() => {
    // ページが完全に読み込まれた後に実行する
    window.onload = () => {
      const paymentUpdate = searchParams.get('payment_update');

      const subscriptionUpdate = searchParams.get('subscription_update');
      if (subscriptionUpdate == 'success') {
        successToast(t('message.success.t25'));

        //webhookよりも早く実行しないように遅らせる
        dispatch(loadStart());
        setTimeout(() => {
          loadLatestUserData();
        }, 3000);
      }

      if (paymentUpdate === 'success') {
        successToast(t('message.success.t26'));
      }
      searchParams.delete('payment_update');
      searchParams.delete('subscription_update');
      setSearchParams(searchParams);
    };
  }, [searchParams]);

  const handleAddVipSupport = async (pricesIds) => {
    const body = {
      priceIds: pricesIds,
      userId: user.id,
    };

    try {
      const res = await publicRequest.post('/stripe/checkout/upgrade', body);
      const checkoutUrl = res.data.url;
      window.location.href = checkoutUrl;
    } catch (error) {
      console.error(error);
    }
  };

  const handleRemoveVipSupport = async () => {
    try {
      dispatch(loadStart());
      await authRequest.post('/stripe/delete-vip-support');

      loadLatestUserData();
      successToast(t('message.success.t25'));
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(loadEnd());
    }
  };

  const handleCancelSubscription = async () => {
    try {
      dispatch(loadStart());
      await authRequest.post('/stripe/subscriptions/cancel');

      document.getElementById('cancel_subscription_modal').close();

      dispatch(logout());
      navigate('/');
      //logoutする
      successToast(t('message.success.t27'), 'Infinity');
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(loadEnd());
    }
  };

  const navigatePlanPage = () => {
    if (!user.country && !user.region) {
      document.getElementById('country_region_modal').show();
    } else {
      navigate('/subscription');
    }
  };

  return (
    <>
      <h2 className='mb-6 text-2xl font-bold'>
        <Trans i18nKey={'manage-subscription.ttl'} />
      </h2>
      <div className='flex min-h-44 flex-col gap-10'>
        <div>
          <p>
            <Trans i18nKey={'manage-subscription.desc-1'} />
          </p>
          {/* Freeかnullの場合は非表示 */}
          {(plan == 'PREMIUM' || plan == 'VIP') && (
            <button className='btn mt-5' onClick={handleUpdatePayment}>
              <Trans i18nKey={'manage-subscription.update-payment-btn'} />
            </button>
          )}
        </div>

        {/* Your current plan */}
        <section>
          <h3 className='text-xl font-semibold'>
            <Trans i18nKey={'manage-subscription.plan'} />
          </h3>
          {plan == 'FREE' ? (
            <div className='mt-3 flex gap-3'>
              <span className='font-medium'>CAS Member</span>
              <span className='text-neutral-500'>Free</span>
            </div>
          ) : plan == 'PREMIUM' ? (
            <div className='mt-3 flex gap-3'>
              <span className='font-medium'>Premium CAS Member</span>
              <span className='text-neutral-500'>
                ${currentPlanPrice}/month
              </span>
            </div>
          ) : plan == 'VIP' ? (
            <div className='mt-3 flex gap-3'>
              <span className='font-medium'>VIP CAS Member</span>
              <span className='text-neutral-500'>
                ${currentPlanPrice}/month
              </span>
            </div>
          ) : (
            <></>
          )}

          <button
            onClick={navigatePlanPage}
            className='btn mt-4 w-full rounded-full bg-blue-100 text-primary hover:bg-blue-100 sm:w-auto'
          >
            {/* Freeと有料の場合で出しわけ */}
            {plan == 'FREE' ? (
              <>
                <BoltIcon className='h-6 w-6' />
                <Trans i18nKey={'btn.upgrade'} />
              </>
            ) : (
              'Change Plan'
            )}
          </button>
        </section>

        {/* Next payment */}
        {/* Freeかnullの場合は非表示 */}
        {(plan == 'PREMIUM' || plan == 'VIP') && (
          <section>
            <h3 className='text-xl font-semibold'>
              <Trans i18nKey={'manage-subscription.next-payment'} />
            </h3>
            <p className='mt-3'>{expireDate}</p>
            <div role='alert' className='alert mt-5 max-w-[602px]'>
              <InformationCircleIcon className='h-6 w-6 text-info' />
              <span>
                <Trans i18nKey={'manage-subscription.notice'} />
              </span>
            </div>
          </section>
        )}

        <div className='divider my-0'></div>
        {/* Cancel Subscription & Deactivate Account */}
        <div>
          <button
            className='btn btn-outline btn-error w-full sm:w-auto'
            onClick={() =>
              document.getElementById('cancel_subscription_modal').showModal()
            }
          >
            <Trans i18nKey={'manage-subscription.cancel-btn'} />
          </button>
        </div>
        {/* ) : (
          <span className='loading loading-dots loading-md text-primary'></span>
        )} */}
        <CancelSubscriptionModal isFreeTrial={isFreeTrial} />
      </div>
    </>
  );
};

export default ManageSubscription;
