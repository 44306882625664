import React from 'react';
import { Helmet } from 'react-helmet-async';

const OGP = () => {
  return (
    <Helmet>
      {/* Standard metadata tags */}
      <title>CAS (Curated Art Show)</title>
      <meta
        name='description'
        content='A 3D virtual art experience that art galleries can add to their web sites.'
      />
      {/* End standard metadata tags */}
      {/* Facebook tags */}
      <meta property='og:type' content='website' />
      <meta property='og:title' content='CAS' />
      <meta
        property='og:description'
        content='A 3D virtual art experience that art galleries can add to their web sites.'
      />
      {/* End Facebook tags */}
      {/* Twitter tags */}
      <meta name='twitter:creator' content='yuyosoft' />
      <meta name='twitter:card' content='website' />
      <meta name='twitter:title' content='CAS' />
      <meta
        name='twitter:description'
        content='A 3D virtual art experience that art galleries can add to their web sites.'
      />
      {/* End Twitter tags */}
    </Helmet>
  );
};

export default OGP;
