import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { loginFailure, loginStart, loginSuccess } from '../../redux/userRedux';
import { Link } from 'react-router-dom';
import { publicRequest } from '../../requestMethods';
import { ErrorToastBlock } from '../../utils/toast';
import { Trans, useTranslation } from 'react-i18next';
import SuspendAlert from './components/SuspendAlert';
import toast from 'react-hot-toast';

const Signin = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [suspendType, setSuspendType] = useState(null);

  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(loginStart());
    try {
      const res = await publicRequest.post(`/auth/signin`, { email, password });
      dispatch(loginSuccess(res.data));
    } catch (err) {
      console.log(err.response.data.message);
      if (
        err.response.data.statusCode === 403 &&
        err.response.data.message === 'Your account is archived.'
      ) {
        setSuspendType('ADMIN');
      } else if (err.response.data.statusCode === 403) {
        setErrorMessage(t('message.error.t15'));
      } else if (err.response.data.message === 'Email has not confirmed yet') {
        setErrorMessage(t('message.error.t16'));
      } else {
        setErrorMessage(t('message.error.t1'));
      }
      dispatch(loginFailure());
    }
  };

  useEffect(() => {
    return () => {
      toast.dismiss();
    };
  }, []);

  return (
    <div className='relative mx-auto h-full'>
      <div className='mx-auto flex max-w-[30rem] flex-col gap-4 px-4 pb-20 pt-[4.5rem] sm:px-0'>
        <h1 className='text-3xl font-extrabold'>
          <Trans i18nKey={'signin.signin'} />
        </h1>
        {errorMessage && <ErrorToastBlock message={errorMessage} />}
        {suspendType && <SuspendAlert type={suspendType} />}
        <form onSubmit={handleSubmit}>
          <div className='flex flex-col gap-6'>
            <label className='form-control w-full'>
              <div className='label'>
                <span className='label-text font-medium'>
                  <Trans i18nKey={'signin.email'} />
                </span>
              </div>
              <input
                maxLength='320'
                type='email'
                placeholder={
                  i18n.language === 'ja' ? 'メールアドレス' : 'Email'
                }
                onChange={(e) => setEmail(e.target.value)}
                className='input input-bordered w-full'
              />
            </label>
            <label className='form-control w-full'>
              <div className='label'>
                <span className='label-text font-medium'>
                  <Trans i18nKey={'signin.password'} />
                </span>
              </div>
              <div className='relative'>
                <input
                  className='undefined input input-bordered w-full pr-10'
                  type={`${showPassword ? 'text' : 'password'}`}
                  placeholder={
                    i18n.language === 'ja' ? 'パスワード' : 'Password'
                  }
                  onChange={(e) => setPassword(e.target.value)}
                />
                <span
                  className='absolute right-3 top-1/2 -translate-y-2/4 cursor-pointer text-sm'
                  onClick={() => setShowPassword(!showPassword)}
                >
                  Show
                </span>
              </div>
            </label>
          </div>
          <div className='mt-8 flex justify-end'>
            <a href='/forgot-password' className='text-sm underline'>
              <Trans i18nKey={'signin.forgot-password'} />
            </a>
          </div>
          <div className='mt-10 flex flex-col items-center gap-4'>
            <button
              type='submit'
              className='btn btn-primary btn-wide'
              disabled={email == '' || password == ''}
            >
              <Trans i18nKey={'signin.signin'} />
            </button>

            <Link className='btn-neurtal btn btn-wide' to='/'>
              <Trans i18nKey={'signin.home'} />
            </Link>

            {i18n.language === 'ja' ? (
              <p className='text-sm'>
                まだアカウントをお持ちではありませんか？
                <Link className='underline' to='/signup'>
                  サインアップ
                </Link>
              </p>
            ) : (
              <p className='text-sm'>
                New to CAS?{' '}
                <Link className='underline' to='/signup'>
                  Sign up
                </Link>
              </p>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default Signin;
