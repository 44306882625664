import { Link, useSearchParams } from 'react-router-dom';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import {
  getCuratorsSelectionExhibitions,
  getPremiumVipCASExhibitions,
} from '../../services/Exhibition';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import ExhibitionCard from '../../components/ExhibitionCard';

export const Explore = () => {
  const { i18n } = useTranslation();
  const [searchParams] = useSearchParams();
  const tab = searchParams.get('tab');

  // CAS Recommendations の展示会を取得
  const { data: selectionExhibitions, isLoading: isSelectionLoading } =
    useQuery({
      queryFn: () => getCuratorsSelectionExhibitions(),
      queryKey: ['get-curators-selection-exhibitions'],
      enabled: tab !== 'premium',
      staleTime: Infinity, // リロード時以外は発火しないように設定
      cacheTime: Infinity, // リロード時以外は発火しないように設定
    });

  // Premium CAS Member の展示会を取得
  const { data: premiumExhibitions, isLoading: isPremiumLoading } = useQuery({
    queryFn: () => getPremiumVipCASExhibitions(),
    queryKey: ['get-premium-vip-exhibitions'],
    enabled: tab === 'premium',
    staleTime: Infinity, // リロード時以外は発火しないように設定
    cacheTime: Infinity, // リロード時以外は発火しないように設定
  });

  return (
    <div className='mx-auto w-full max-w-[56.75rem] px-4 pb-64 pt-10 lg:px-0'>
      <Link to={'/'} className='btn btn-ghost'>
        <ArrowLeftIcon className='h-4 w-4' />
        Home
      </Link>
      <div className='mt-6 flex flex-col items-baseline gap-8'>
        <h1 className='text-3xl font-extrabold'>
          Explore Live CAS Exhibitions
        </h1>
        <div role='tablist' className='tabs-boxed tabs'>
          <a
            href='/explore'
            role='tab'
            className={`tab ${tab === 'premium' ? '' : 'tab-active'}`}
          >
            CAS Recommendations
          </a>
          <a
            href='/explore?tab=premium'
            role='tab'
            className={`tab ${tab === 'premium' ? 'tab-active' : ''}`}
          >
            Premium/VIP Member Galleries
          </a>
        </div>
      </div>
      <div className='mb-20 mt-8 grid gap-6 sm:grid-cols-2 sm:!gap-5 lg:grid-cols-3'>
        {tab !== 'premium' ? (
          isSelectionLoading ? (
            <span className='loading loading-spinner text-primary'></span>
          ) : selectionExhibitions.length === 0 ? (
            <p>
              {i18n.language === 'ja'
                ? 'まだ公開されている展覧会はありません'
                : 'No exhibition published yet.'}
            </p>
          ) : (
            selectionExhibitions.map((item) => (
              <ExhibitionCard key={item.id} item={item} note={item?.note} />
            ))
          )
        ) : isPremiumLoading ? (
          <span className='loading loading-spinner text-primary'></span>
        ) : premiumExhibitions.length === 0 ? (
          <p>
            {i18n.language === 'ja'
              ? 'まだ公開されている展覧会はありません'
              : 'No exhibition published yet.'}
          </p>
        ) : (
          premiumExhibitions.map((item) => (
            <ExhibitionCard key={item.id} item={item} />
          ))
        )}
      </div>
    </div>
  );
};
