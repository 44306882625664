import { useEditor, EditorContent } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import {
  Bold,
  Italic,
  ListIcon,
  ListOrderedIcon,
  Underline as UnderlineIcon,
} from 'lucide-react';
import Underline from '@tiptap/extension-underline';
import Placeholder from '@tiptap/extension-placeholder';
import BulletList from '@tiptap/extension-bullet-list';
import ListItem from '@tiptap/extension-list-item';
import OrderedList from '@tiptap/extension-ordered-list';
import ListKeymap from '@tiptap/extension-list-keymap';

import '../css/style.css';
import { useEffect, useRef } from 'react';

const Toolbar = ({ editor, disabled }) => {
  if (!editor) {
    return null;
  }

  return (
    <div
      className={`border-input mb-2 flex items-center gap-2 rounded-md border bg-white px-2 py-1 ${
        disabled && 'pointer-events-none cursor-not-allowed'
      }`}
    >
      <button
        onClick={(e) => {
          e.preventDefault();
          editor.chain().focus().toggleBold().run();
        }}
        className={`rounded-md p-3 ${
          editor.isActive('bold') ? 'bg-gray-100' : ''
        }`}
      >
        <Bold className='h-4 w-4' />
      </button>
      <button
        onClick={(e) => {
          e.preventDefault();
          editor.chain().focus().toggleItalic().run();
        }}
        className={`rounded-md p-3 ${
          editor.isActive('italic') ? 'bg-gray-100' : ''
        }`}
      >
        <Italic className='h-4 w-4' />
      </button>
      <button
        onClick={(e) => {
          e.preventDefault();
          editor.chain().focus().toggleUnderline().run();
        }}
        className={`rounded-md p-3 ${
          editor.isActive('underline') ? 'bg-gray-100' : ''
        }`}
      >
        <UnderlineIcon className='h-4 w-4' />
      </button>
      <button
        onClick={(e) => {
          e.preventDefault();
          editor.chain().focus().toggleBulletList().run();
        }}
        className={`rounded-md p-3 ${
          editor.isActive('bulletList') ? 'bg-gray-100' : ''
        }`}
      >
        <ListIcon className='h-4 w-4' />
      </button>
      <button
        onClick={(e) => {
          e.preventDefault();
          editor.chain().focus().toggleOrderedList().run();
        }}
        className={`rounded-md p-3 ${
          editor.isActive('orderedList') ? 'bg-gray-100' : ''
        }`}
      >
        <ListOrderedIcon className='h-4 w-4' />
      </button>
    </div>
  );
};

export const Tiptap = ({
  bodyText,
  onChange,
  onBlur,
  customHeightClass,
  error,
  disabled,
  classNames,
}) => {
  const editor = useEditor({
    extensions: [
      StarterKit.configure(),
      Underline,
      Placeholder.configure({
        placeholder: 'Type here',
      }),
      BulletList.configure({
        HTMLAttributes: {
          class: 'list-disc',
        },
      }),
      OrderedList.configure({
        HTMLAttributes: {
          class: 'list-decimal',
        },
      }),

      ListItem,
      ListKeymap,
    ],
    content: bodyText,
    editorProps: {
      attributes: {
        class: `textarea  text-sm story-text ${classNames} ${
          customHeightClass ? customHeightClass : 'min-h-96'
        } ${error && 'border-error'} ${
          disabled
            ? 'bg-base-200 cursor-not-allowed pointer-events-none'
            : 'bg-white textarea-bordered'
        }`,
      },
    },
    onUpdate({ editor }) {
      const htmlContent = editor
        .getHTML()
        .replace(/<p><\/p>/g, '<br>')
        .replace(/<p>&nbsp;/g, '<p> ');
      onChange(htmlContent);
    },
    onBlur({ editor }) {
      if (onBlur) {
        const htmlContent = editor
          .getHTML()
          .replace(/<p><\/p>/g, '<br>')
          .replace(/<p>&nbsp;/g, '<p> ');
        onBlur(htmlContent);
      }
    },
  });
  const updateTriggered = useRef(false);

  useEffect(() => {
    if (editor && bodyText && !updateTriggered.current) {
      const formattedContent = bodyText
        .replace(/<br>/g, '<p></p>')
        .replace(/<p> /g, '<p>&nbsp;');
      editor.commands.setContent(formattedContent, false);
      updateTriggered.current = true;
    } else if (editor && !bodyText) {
      editor.commands.setContent('', false);
    }
  }, [bodyText, editor]);

  useEffect(() => {
    if (editor) {
      const className = `textarea text-sm story-text ${classNames} ${
        customHeightClass ? customHeightClass : 'min-h-96'
      } ${error ? 'border-error' : ''} ${
        disabled
          ? 'bg-base-200 cursor-not-allowed pointer-events-none'
          : 'bg-transparent textarea-bordered'
      }`;
      editor.setOptions({
        editorProps: {
          attributes: {
            class: className,
          },
        },
      });
    }
  }, [error, customHeightClass, editor, disabled]);

  return (
    <div className={`flex flex-col justify-stretch`}>
      <Toolbar editor={editor} disabled={disabled} />
      <EditorContent editor={editor} />
    </div>
  );
};
