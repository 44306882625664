import React from 'react';
import { Trans } from 'react-i18next';

export const FooterSimple = () => {
  return (
    <footer className={`footer border-t border-base-300 p-4`}>
      <p className='text-xs font-medium text-base-content'>
        <Trans i18nKey={'footer.copyright'} />
      </p>
    </footer>
  );
};
