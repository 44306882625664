import { useGLTF } from '@react-three/drei';
import { Suspense, useLayoutEffect, useState } from 'react';

const PictureFrame31 = ({ color, ...props }) => {
  const { scene, materials } = useGLTF(
    'https://data.curatedartshow.com/models/frames/beigewhite.glb'
  );

  const [geometry, setGeometry] = useState();

  if (!geometry) {
    const cloneScene = scene.clone(true);
    setGeometry(cloneScene);
  }

  useLayoutEffect(() => {
    // console.log(materials);
    materials['Gold Marble_material'].color.set('#9a9a9a');
    materials['Gold Marble_material'].metalness = 0.8;
    materials['Gold Marble_material'].roughness = 0.5;
    materials['Metal Coated Paper_material2'].color.set('#727272');
    materials['Metal Coated Paper_material2'].metalness = 1;
    materials['Metal Coated Paper_material2'].roughness = 0.8;
    materials['Satin Light Brown Paper_material'].color.set('#d78f13');
    materials['Satin Light Brown Paper_material'].metalness = 0.5;
    materials['Satin Light Brown Paper_material'].roughness = 1.5;

    geometry.traverse((obj) => {
      if (obj.isMesh) obj.castShadow = obj.receiveShadow = true;
    });
  }, []);
  return (
    <Suspense fallback={null}>
      <primitive object={geometry} {...props} />
    </Suspense>
  );
};

export default PictureFrame31;
