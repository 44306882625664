import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Plane, useTexture } from '@react-three/drei';
import * as THREE from 'three';

const Floor = ({ theme }) => {
  const floorNumber = 3;
  const [floorPosition, setFloorPosition] = useState([0, 0, 0]);
  useEffect(() => {
    switch (theme) {
      case 'NATURE':
        setFloorPosition([0, 0, 0]);
        break;
      case 'SF':
        setFloorPosition([0, -0.1, 0]);
        break;
      case 'CLASSIC':
        setFloorPosition([0, -0.2, 0]);
        break;
      default:
        setFloorPosition([0, 0, 0]);
        break;
    }
  }, [theme]);
  const textureProps = useTexture({
    map: `https://data.curatedartshow.com/texture/floors/${theme}/basecolor.jpg`,
    normalMap: `https://data.curatedartshow.com/texture/floors/${theme}/normal.jpg`,
    roughnessMap: `https://data.curatedartshow.com/texture/floors/${theme}/roughness.jpg`,
    aoMap: `https://data.curatedartshow.com/texture/floors/${theme}/ambientOcclusion.jpg`,
    displacementMap: `https://data.curatedartshow.com/texture/floors/${theme}/height.png`,
  });
  useLayoutEffect(() => {
    Object.values(textureProps).forEach((texture) => {
      texture.wrapS = THREE.RepeatWrapping;
      texture.wrapT = THREE.RepeatWrapping;
      texture.repeat.set(72, 72);
    });
  }, [textureProps]);

  const mesh = useRef();
  useEffect(() => {
    mesh.current.geometry.setAttribute(
      'uv2',
      new THREE.BufferAttribute(mesh.current.geometry.attributes.uv.array, 2)
    );
  }, []);
  return (
    <mesh
      receiveShadow
      rotation={[-Math.PI / 2, 0, 0]}
      ref={mesh}
      position={floorPosition}
    >
      <planeGeometry args={[200, 200]} />
      <meshStandardMaterial
        displacementScale={0.5}
        aoMapIntensity={2}
        roughness={1}
        normalScale={new THREE.Vector2(1, 1)}
        {...textureProps}
        // color='#111'
      />
    </mesh>
  );
};

export default Floor;
