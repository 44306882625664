import { PhotoIcon, TrashIcon } from '@heroicons/react/24/outline';
import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import TextureEditPreview from './TextureEditPreview';
import TextureImageModal from './TextureImageModal';
import { validateTextureName } from '../../../../utils/validation';

const TextureForm = ({
  formData,
  textureData,
  saveTexture,
  handleNameChange,
  handleSettingChange,
  handleSettingToggle,
  handleSubmit,
  setTextureFiles,
}) => {
  const location = useLocation();
  const mode = location.pathname.split('/')[4]; //createかedit
  const type = location.pathname.split('/')[3]; //ceilingかwallかfloor

  const [nameError, setNameError] = useState('');

  const validateName = (name) => {
    const result = validateTextureName(name);
    result.isValid ? setNameError('') : setNameError(result.message);
  };

  return (
    <div>
      <label className='form-control w-full max-w-sm'>
        <div className='label'>
          <span className='label-text'>Name</span>
        </div>
        <input
          type='text'
          placeholder='Must be a unique name'
          className={`input input-bordered w-full max-w-sm ${
            nameError && 'input-error'
          }`}
          name='name'
          value={formData.name}
          onChange={(e) => {
            validateName(e.target.value);
            handleNameChange(e);
          }}
        />
        <div className='label items-start'>
          <span className='label-text-alt text-error'>{nameError}</span>
          <span className='label-text-alt'>{formData.name?.length}/25</span>
        </div>
      </label>

      <p className='pb-6 pt-12 text-2xl font-bold'>Images</p>

      <button
        className='btn mb-4'
        onClick={() => document.getElementById('my_modal_1').showModal()}
      >
        Upload Images
      </button>
      <TextureImageModal
        saveTexture={saveTexture}
        setTextureFiles={setTextureFiles}
      />
      <div className='flex flex-wrap gap-5'>
        <div>
          <p className='px-1 py-2 text-sm font-medium'>basecolor</p>
          {textureData.colorMap ? (
            <img
              src={textureData.colorMap}
              className='h-40 w-40 rounded-lg object-cover'
            />
          ) : (
            <div className='flex h-40 w-40 items-center justify-center rounded-lg border-[1.5px] border-dashed border-gray-400 p-5'>
              <PhotoIcon className='h-10 w-10 shrink-0 text-gray-400' />
            </div>
          )}
        </div>
        <div>
          <p className='px-1 py-2 text-sm font-medium'>ao (optional)</p>
          {textureData.aoMap ? (
            <img
              src={textureData.aoMap}
              className='h-40 w-40 rounded-lg object-cover'
            />
          ) : (
            <div className='flex h-40 w-40 items-center justify-center rounded-lg border-[1.5px] border-dashed border-gray-400 p-5'>
              <PhotoIcon className='h-10 w-10 shrink-0 text-gray-400' />
            </div>
          )}
        </div>

        <div>
          <p className='px-1 py-2 text-sm font-medium'>height (optional)</p>
          {textureData.displacementMap ? (
            <img
              src={textureData.displacementMap}
              className='h-40 w-40 rounded-lg object-cover'
            />
          ) : (
            <div className='flex h-40 w-40 items-center justify-center rounded-lg border-[1.5px] border-dashed border-gray-400 p-5'>
              <PhotoIcon className='h-10 w-10 shrink-0 text-gray-400' />
            </div>
          )}
        </div>

        <div>
          <p className='px-1 py-2 text-sm font-medium'>normal(optional)</p>
          {textureData.normalMap ? (
            <img
              src={textureData.normalMap}
              className='h-40 w-40 rounded-lg object-cover'
            />
          ) : (
            <div className='flex h-40 w-40 items-center justify-center rounded-lg border-[1.5px] border-dashed border-gray-400 p-5'>
              <PhotoIcon className='h-10 w-10 shrink-0 text-gray-400' />
            </div>
          )}
        </div>
        <div>
          <p className='px-1 py-2 text-sm font-medium'>roughness(optional)</p>
          {textureData.roughnessMap ? (
            <img
              src={textureData.roughnessMap}
              className='h-40 w-40 rounded-lg object-cover'
            />
          ) : (
            <div className='flex h-40 w-40 items-center justify-center rounded-lg border-[1.5px] border-dashed border-gray-400 p-5'>
              <PhotoIcon className='h-10 w-10 shrink-0 text-gray-400' />
            </div>
          )}
        </div>
        <div>
          <p className='px-1 py-2 text-sm font-medium'>metalness(optional)</p>
          {textureData.metalnessMap ? (
            <img
              src={textureData.metalnessMap}
              className='h-40 w-40 rounded-lg object-cover'
            />
          ) : (
            <div className='flex h-40 w-40 items-center justify-center rounded-lg border-[1.5px] border-dashed border-gray-400 p-5'>
              <PhotoIcon className='h-10 w-10 shrink-0 text-gray-400' />
            </div>
          )}
        </div>
        <div>
          <p className='px-1 py-2 text-sm font-medium'>alpha(optional)</p>
          {textureData.alphaMap ? (
            <img
              src={textureData.alphaMap}
              className='h-40 w-40 rounded-lg object-cover'
            />
          ) : (
            <div className='flex h-40 w-40 items-center justify-center rounded-lg border-[1.5px] border-dashed border-gray-400 p-5'>
              <PhotoIcon className='h-10 w-10 shrink-0 text-gray-400' />
            </div>
          )}
        </div>
      </div>

      <div className='flex flex-col-reverse lg:flex-row lg:gap-5'>
        <div className='flex flex-1 flex-col gap-4'>
          <p className='pb-6 pt-12 text-2xl font-bold'>Values</p>
          <div className='flex items-center justify-between'>
            <p className='font-medium'>displacementScale</p>
            <input
              type='range'
              min={0}
              max='2'
              step={0.1}
              className='range range-sm w-80'
              name='displacementScale'
              value={formData.displacementScale}
              onChange={handleSettingChange}
            />
          </div>
          <div className='flex items-center justify-between'>
            <p className='font-medium'>aoMapIntensity</p>
            <input
              type='range'
              min={0}
              max='10'
              step={0.1}
              className='range range-sm w-80'
              name='aoMapIntensity'
              value={formData.aoMapIntensity}
              onChange={handleSettingChange}
            />
          </div>
          <div className='flex items-center justify-between'>
            <p className='font-medium'>roughness</p>
            <input
              type='range'
              min={0}
              max='10'
              step={0.1}
              className='range range-sm w-80'
              name='roughness'
              value={formData.roughness}
              onChange={handleSettingChange}
            />
          </div>
          <div className='flex items-center justify-between'>
            <p className='font-medium'>metalness</p>
            <input
              type='range'
              min={0}
              max='2'
              step={0.1}
              className='range range-sm w-80'
              name='metalness'
              value={formData.metalness}
              onChange={handleSettingChange}
            />
          </div>
          <div className='flex items-center justify-between'>
            <p className='font-medium'>normalScale</p>
            <div className='flex items-center gap-4'>
              <input
                type='number'
                placeholder='1st argument'
                className='input input-sm input-bordered w-full max-w-[8.3125rem]'
                name='normalScaleX'
                value={formData.normalScaleX}
                onChange={handleSettingChange}
              />
              <input
                type='number'
                placeholder='2nd argument'
                className='input input-sm input-bordered w-full max-w-[8.3125rem]'
                name='normalScaleY'
                value={formData.normalScaleY}
                onChange={handleSettingChange}
              />
            </div>
          </div>
        </div>
        <div className='w-full flex-1'>
          <p className='pb-6 pt-12 text-2xl font-bold'>Preview</p>
          <div className='h-[24.4375rem] w-full bg-[#D9D9D9]'>
            <TextureEditPreview
              formData={formData}
              textureData={textureData}
              type={type}
            />
          </div>
        </div>
      </div>

      <p className='pb-6 pt-12 text-2xl font-bold'>Visibility & Availability</p>
      <div className='flex max-w-[15rem] flex-col pb-10'>
        <div className='form-control'>
          <label className='label cursor-pointer'>
            <span className='label-text font-medium'>Available for CAS-1</span>
            <input
              type='checkbox'
              className='toggle'
              name='isFree'
              checked={formData.isFree}
              onChange={handleSettingToggle}
            />
          </label>
        </div>
        <div className='form-control'>
          <label className='label cursor-pointer'>
            <span className='label-text font-medium'>Publish</span>
            <input
              type='checkbox'
              className='toggle'
              name='published'
              checked={formData.published}
              onChange={handleSettingToggle}
            />
          </label>
        </div>
      </div>

      <div className='divider m-0 p-0' />
      {mode == 'create' ? (
        <div className='flex justify-end gap-6 pt-10'>
          <Link to={'/admin/textures/ceiling'} className='btn'>
            Cancel
          </Link>
          <button className='btn btn-primary' onClick={handleSubmit}>
            Create
          </button>
        </div>
      ) : (
        <div className='flex justify-between pt-10'>
          <button
            className='btn btn-outline btn-error'
            onClick={() => {
              document.getElementById('delete_modal').showModal();
            }}
          >
            <TrashIcon className='h-6 w-6 shrink-0' />
            Delete
          </button>
          <div className='flex gap-6'>
            <Link to={'/admin/textures/ceiling'} className='btn'>
              Cancel
            </Link>
            <button className='btn btn-primary' onClick={handleSubmit}>
              Save
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default TextureForm;
