import React, { useEffect, useMemo, useState } from 'react';
import { errorToast, successToast } from '../../../../utils/toast';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { toCapitalCase } from '../../../../utils/StringUtils';
import { authRequest } from '../../../../requestMethods';
import {
  createCeiling,
  deleteCeiling,
  updateCeiling,
  uploadToS3,
} from '../../../../services/Admin';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import { getCeilingById } from '../../../../services/Ceiling';
import TextureForm from '../components/TextureForm';
import DeleteModal from '../../components/DeleteModal';

const AdminEditCeilingTexture = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const id = location.pathname.split('/')[5];

  const {
    data: ceiling,
    isLoading: isCeilingLoading,
    refetch,
  } = useQuery({
    queryFn: () => getCeilingById(Number(id)),
    queryKey: [`ceiling-${id}`],
    enabled: !!id,
  });

  const [formData, setFormData] = useState({
    name: '',
    displacementScale: 1,
    aoMapIntensity: 1,
    roughness: 1,
    metalness: 0,
    normalScaleX: 1,
    normalScaleY: 1,
    isFree: true,
    published: true,
    //以下のMapにはs3のkey（画像path）が入る
    colorMap: null,
    normalMap: null,
    roughnessMap: null,
    aoMap: null,
    displacementMap: null,
    metalnessMap: null,
    alphaMap: null,
  });

  //localの一時保存file url
  const [textureData, setTextureData] = useState({
    colorMap: null,
    normalMap: null,
    roughnessMap: null,
    aoMap: null,
    displacementMap: null,
    metalnessMap: null,
    alphaMap: null,
  });

  //localの一時保存file
  const [textureFiles, setTextureFiles] = useState({
    colorMap: null,
    normalMap: null,
    roughnessMap: null,
    aoMap: null,
    displacementMap: null,
    metalnessMap: null,
    alphaMap: null,
  });

  useEffect(() => {
    if (ceiling) {
      setFormData({
        name: ceiling.title,
        displacementScale: ceiling.displacementScale
          ? ceiling.displacementScale
          : 1,
        aoMapIntensity: ceiling.aoMapIntensity ? ceiling.aoMapIntensity : 1,
        roughness: ceiling.roughness ? ceiling.roughness : 1,
        metalness: ceiling.metalness ? ceiling.metalness : 0,
        normalScaleX: ceiling.normalScaleX ? ceiling.normalScaleX : 1,
        normalScaleY: ceiling.normalScaleY ? ceiling.normalScaleY : 1,
        isFree: !ceiling.isPro,
        published: ceiling.published,
        //以下のMapにはs3のkey（画像path）が入る
        colorMap: ceiling.colorMap,
        normalMap: ceiling.normalMap,
        roughnessMap: ceiling.roughnessMap,
        aoMap: ceiling.aoMap,
        displacementMap: ceiling.displacementMap,
        metalnessMap: ceiling.metalnessMap,
        alphaMap: ceiling.alphaMap,
      });

      setTextureData({
        colorMap: ceiling.colorMap,
        normalMap: ceiling.normalMap,
        roughnessMap: ceiling.roughnessMap,
        aoMap: ceiling.aoMap,
        displacementMap: ceiling.displacementMap,
        metalnessMap: ceiling.metalnessMap,
        alphaMap: ceiling.alphaMap,
      });
    }
  }, [ceiling]);

  const handleNameChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSettingChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: parseFloat(value),
    }));
  };

  const handleSettingToggle = (e) => {
    const { name, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: checked,
    }));
  };

  const s3Keys = useMemo(() => {
    const capitalCaseName = toCapitalCase(formData.name); // Convert to CapitalCase
    const basePath = `texture/ceilings/${capitalCaseName}`;

    return {
      colorMap: `${basePath}/basecolor`,
      normalMap: `${basePath}/normal`,
      roughnessMap: `${basePath}/roughness`,
      aoMap: `${basePath}/ao`,
      displacementMap: `${basePath}/height`,
      metalnessMap: `${basePath}/metalness`,
      alphaMap: `${basePath}/alpha`,
    };
  }, [formData.name]);

  // create処理
  const updateCeilingMutation = useMutation({
    mutationFn: async (data) => {
      const updatedFormData = { ...formData };
      // すべてのアップロード処理をPromise.allで待機
      await Promise.all(
        Object.entries(textureFiles).map(async ([key, file]) => {
          if (file) {
            // 生成したkeyを取得
            const s3Key = s3Keys[key];
            // s3にtexture画像をアップロード
            const uploadedKey = await uploadToS3(file, s3Key);
            // formDataのコピーを更新
            updatedFormData[key] = uploadedKey.replace(
              'curatedartshow-data.s3.amazonaws.com',
              'data.curatedartshow.com'
            );
          }
        })
      );

      const { isFree, name, ...filteredFormData } = updatedFormData;

      const requestData = {
        ...filteredFormData,
        isPro: !formData.isFree,
        title: formData.name,
      };

      return await updateCeiling(Number(id), requestData);
    },
    onSuccess: () => {
      navigate('/admin/textures/ceiling');
      successToast('Ceiling texture successfully saved');
    },
    onError: (err) => {
      console.log(err);
      if (err.response.data.statusCode == 403) {
        errorToast(err?.response?.data.message);
      } else {
        errorToast(
          'Something went wrong. We are aware of the problem and will try to fix it as soon as possible. Sorry for the inconvenience.'
        );
      }
    },
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    updateCeilingMutation.mutate();
  };

  const saveTexture = (tmpTextureData) => {
    setTextureData({ ...tmpTextureData });
  };

  const handleDelete = async () => {
    deleteCeilingMutation.mutate();
  };

  // delete処理
  const deleteCeilingMutation = useMutation({
    mutationFn: async () => {
      return await deleteCeiling(Number(id));
    },
    onSuccess: () => {
      document.getElementById('delete_modal').close();
      navigate('/admin/textures/ceiling');
      successToast('Texture deleted successfully!');
    },
    onError: (err) => {
      console.error(err);

      if (err.response.data.statusCode == 403) {
        navigate('/admin/login');
      } else {
        errorToast(
          'Something went wrong. We are aware of the problem and will try to fix it as soon as possible. Sorry for the inconvenience.'
        );
      }
    },
  });

  return (
    <div>
      <p className='pb-8 text-3xl font-extrabold'>Edit Ceiling Texture</p>
      <TextureForm
        formData={formData}
        textureData={textureData}
        saveTexture={saveTexture}
        handleNameChange={handleNameChange}
        handleSettingChange={handleSettingChange}
        handleSettingToggle={handleSettingToggle}
        handleSubmit={handleSubmit}
        setTextureFiles={setTextureFiles}
      />

      <DeleteModal
        itemName='texture'
        confirmDelete={handleDelete}
        loading={deleteCeilingMutation.isPending}
      />
      {(updateCeilingMutation.isPending || isCeilingLoading.isPending) && (
        <LoadingSpinner />
      )}
    </div>
  );
};

export default AdminEditCeilingTexture;
