import React from 'react';

const LightGroup = ({ position, color }) => {
  return (
    <group position={position}>
      {Array(16)
        .fill()
        .map((_, index) => (
          <mesh
            scale={1}
            position={[
              (Math.sin(index) + 1) * 3.3,
              (Math.cos(index - 0.5) + 1) * 1.2,
              0,
            ]}
            rotation-z={Math.sin(index - 0.8) * 2.5}
            key={index}
          >
            <boxGeometry args={[0.08, 1.6, 0.08]} />
            <meshStandardMaterial toneMapped={false} color={color} />
          </mesh>
        ))}
    </group>
  );
};

const Light8 = ({ color, length = 5 }) => {
  let meshColor = [5, 2, 0.5];

  switch (color) {
    case 'RED':
      meshColor = [10, 1, 1];
      break;
    case 'BLUE':
      meshColor = [1, 1, 10];
      break;
    case 'WHITE':
      meshColor = [6, 6, 6];
      break;
    case 'YELLOW':
      meshColor = [6, 6, 2];
      break;
    case 'GREEN':
      meshColor = [0.6, 8, 0.6];
      break;
    case 'PINK':
      meshColor = [8, 0.6, 8];
      break;
    default:
      meshColor = [6, 6, 6];
      break;
  }
  return (
    <>
      {Array(5 * length)
        .fill()
        .map((_, index) => (
          <LightGroup
            key={index}
            position={[index * 4.4 - 10, 0.4, 0]}
            color={meshColor}
          />
        ))}
    </>
  );
};

export default Light8;
