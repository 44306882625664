import { useGLTF } from '@react-three/drei';
import { Suspense, useLayoutEffect, useState } from 'react';

const PictureFrame32 = ({ color, ...props }) => {
  const { scene, materials } = useGLTF(
    'https://data.curatedartshow.com/models/frames/blue-silver.glb'
  );

  const [geometry, setGeometry] = useState();

  if (!geometry) {
    const cloneScene = scene.clone(true);
    setGeometry(cloneScene);
  }

  useLayoutEffect(() => {
    // console.log(materials);
    materials['Light Grey Paint Brushed_material'].color.set('#ebeaea');
    materials['Light Grey Paint Brushed_material'].metalness = 0.8;
    materials['Light Grey Paint Brushed_material'].roughness = 2.5;
    materials['Silver Blue Paint_material'].color.set('#a1a1a1');
    materials['Silver Blue Paint_material'].metalness = 0.7;
    materials['Silver Blue Paint_material'].roughness = 0.8;
    // materials['car_paint_material'].color.set('#d78f13');
    materials['car_paint_material'].metalness = 1;
    materials['car_paint_material'].roughness = 0;

    geometry.traverse((obj) => {
      if (obj.isMesh) obj.castShadow = obj.receiveShadow = true;
    });
  }, []);
  return (
    <Suspense fallback={null}>
      <primitive object={geometry} {...props} />
    </Suspense>
  );
};

export default PictureFrame32;
