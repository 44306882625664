import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

const CustomHeaderMobile = ({ selectMenu, currentMenu, moveTo, hideNext }) => {
  const { i18n } = useTranslation();
  return (
    <>
      <div className='flex min-h-[3.875rem] items-center justify-between px-4 lg:hidden'>
        {currentMenu === 'Information' ? (
          <span
            className='px-4 py-2 text-sm font-semibold'
            onClick={() => moveTo('ExhibitionSettings')}
          >
            <Trans i18nKey={'btn.back'} />
          </span>
        ) : (
          <span
            onClick={() =>
              document.getElementById('discard_exhibition_modal').showModal()
            }
            className='p-4 text-sm font-semibold focus-visible:outline-none'
          >
            <Trans i18nKey={'btn.exit-2'} />
          </span>
        )}

        <h2 className='font-semibold'>
          {currentMenu === 'Appearance' ? (
            <Trans i18nKey={'gallery.mobile-menu.appearance'} />
          ) : currentMenu === 'Theme' ? (
            <Trans i18nKey={'gallery.mobile-menu.theme'} />
          ) : currentMenu === 'Picture Frames' ? (
            <Trans i18nKey={'gallery.mobile-menu.picture-frames'} />
          ) : currentMenu === 'Functional Light' ? (
            <Trans i18nKey={'gallery.mobile-menu.functional-light'} />
          ) : currentMenu === 'Invisible Light' ? (
            <Trans i18nKey={'gallery.mobile-menu.invisible-light'} />
          ) : currentMenu === 'Silhouettes' ? (
            <Trans i18nKey={'gallery.mobile-menu.silhouettes'} />
          ) : currentMenu === 'Music' ? (
            <Trans i18nKey={'gallery.mobile-menu.music'} />
          ) : currentMenu === 'Entrance Video' ? (
            <Trans i18nKey={'gallery.mobile-menu.entrance-video'} />
          ) : currentMenu === 'Arrange Artworks' ? (
            <Trans i18nKey={'gallery.mobile-menu.arrange-artworks'} />
          ) : currentMenu === 'Information' ? (
            <Trans i18nKey={'gallery.mobile-menu.info'} />
          ) : (
            currentMenu
          )}
        </h2>
        {currentMenu === 'Appearance' ? (
          <span
            className={`px-4 py-2 text-sm font-semibold text-primary ${
              hideNext ? 'invisible opacity-0' : 'visible opacity-100'
            }`}
            onClick={() => moveTo('BasicInfo')}
          >
            <Trans i18nKey={'btn.next'} />
          </span>
        ) : (
          <span
            className={`px-4 py-2 text-sm font-semibold text-primary ${
              hideNext ? 'invisible opacity-0' : 'visible opacity-100'
            }`}
            onClick={() => selectMenu('Appearance')}
          >
            <Trans i18nKey={'btn.done'} />
          </span>
        )}
      </div>
    </>
  );
};

export default CustomHeaderMobile;
