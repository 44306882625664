import * as THREE from 'three';

export const WALLS = [
  {
    id: 1,
    title: 'CONCRETE 1',
    thumbnail: `https://data.curatedartshow.com/texture/walls/wall1/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/walls/wall1/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/walls/wall1/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/walls/wall1/roughness.jpg`,
      aoMap: `https://data.curatedartshow.com/texture/walls/wall1/ao.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/walls/wall1/height.jpg`,
    },
    settings: {
      displacementScale: 0.2,
      aoMapIntensity: 2,
      roughness: 1,
      normalScale: new THREE.Vector2(1, 3),
    },
    isPro: false,
  },
  {
    id: 2,
    title: 'CANVAS',
    thumbnail: `https://data.curatedartshow.com/texture/walls/wall2/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/walls/wall2/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/walls/wall2/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/walls/wall2/roughness.jpg`,
      aoMap: `https://data.curatedartshow.com/texture/walls/wall2/ao.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/walls/wall2/height.jpg`,
    },
    settings: {
      displacementScale: 0.2,
      aoMapIntensity: 2,
      roughness: 1,
      normalScale: new THREE.Vector2(1, 4),
    },
    isPro: false,
  },
  {
    id: 3,
    title: 'CLINKER WALL',
    thumbnail: `https://data.curatedartshow.com/texture/walls/wall3/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/walls/wall3/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/walls/wall3/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/walls/wall3/roughness.jpg`,
      aoMap: `https://data.curatedartshow.com/texture/walls/wall3/ao.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/walls/wall3/height.jpg`,
    },
    settings: {
      displacementScale: 0.2,
      aoMapIntensity: 2,
      roughness: 1,
    },
    isPro: true,
  },
  {
    id: 4,
    title: 'CONCRETE 2',
    thumbnail: `https://data.curatedartshow.com/texture/walls/wall4/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/walls/wall4/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/walls/wall4/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/walls/wall4/roughness.jpg`,
      aoMap: `https://data.curatedartshow.com/texture/walls/wall4/ao.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/walls/wall4/height.jpg`,
    },
    settings: {
      displacementScale: 0.2,
      aoMapIntensity: 2,
      roughness: 1,
    },
    isPro: false,
  },
  {
    id: 5,
    title: 'CONCRETE 3',
    thumbnail: `https://data.curatedartshow.com/texture/walls/wall5/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/walls/wall5/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/walls/wall5/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/walls/wall5/roughness.jpg`,
      aoMap: `https://data.curatedartshow.com/texture/walls/wall5/ao.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/walls/wall5/height.jpg`,
    },
    settings: {
      displacementScale: 0.2,
      aoMapIntensity: 2,
      roughness: 1,
    },
    isPro: false,
  },
  {
    id: 6,
    title: 'WOODEN PLANK WALL',
    thumbnail: `https://data.curatedartshow.com/texture/walls/wall6/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/walls/wall6/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/walls/wall6/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/walls/wall6/roughness.jpg`,
      aoMap: `https://data.curatedartshow.com/texture/walls/wall6/ao.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/walls/wall6/height.jpg`,
    },
    settings: {
      displacementScale: 0.2,
      aoMapIntensity: 1.2,
      roughness: 1,
    },
    isPro: false,
  },
  {
    id: 7,
    title: 'LOG WALL',
    thumbnail: `https://data.curatedartshow.com/texture/walls/wall7/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/walls/wall7/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/walls/wall7/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/walls/wall7/roughness.jpg`,
      aoMap: `https://data.curatedartshow.com/texture/walls/wall7/ao.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/walls/wall7/height.jpg`,
    },
    settings: {
      displacementScale: 0.2,
      aoMapIntensity: 0.7,
      roughness: 2,
      // normalScale: new THREE.Vector2(2, 1),
    },
    isPro: true,
  },
  {
    id: 8,
    title: 'MAROON WALL TILES',
    thumbnail: `https://data.curatedartshow.com/texture/walls/wall8/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/walls/wall8/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/walls/wall8/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/walls/wall8/roughness.jpg`,
      aoMap: `https://data.curatedartshow.com/texture/walls/wall8/ao.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/walls/wall8/height.jpg`,
    },
    settings: {
      displacementScale: 0.2,
      aoMapIntensity: 2,
      roughness: 1,
    },
    isPro: true,
  },
  {
    id: 9,
    title: 'ORNATE WALL',
    thumbnail: `https://data.curatedartshow.com/texture/walls/wall9/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/walls/wall9/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/walls/wall9/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/walls/wall9/roughness.jpg`,
      aoMap: `https://data.curatedartshow.com/texture/walls/wall9/ao.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/walls/wall9/height.jpg`,
    },
    settings: {
      displacementScale: 0.2,
      aoMapIntensity: 2,
      roughness: 1,
    },
    isPro: true,
  },
  {
    id: 10,
    title: 'BRICKS 1',
    thumbnail: `https://data.curatedartshow.com/texture/walls/wall10/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/walls/wall10/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/walls/wall10/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/walls/wall10/roughness.jpg`,
      aoMap: `https://data.curatedartshow.com/texture/walls/wall10/ao.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/walls/wall10/height.jpg`,
    },
    settings: {
      displacementScale: 0.2,
      aoMapIntensity: 2,
      roughness: 1,
    },
    isPro: false,
  },
  {
    id: 11,
    title: 'PLASTER WALL',
    thumbnail: `https://data.curatedartshow.com/texture/walls/wall11/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/walls/wall11/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/walls/wall11/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/walls/wall11/roughness.jpg`,
      aoMap: `https://data.curatedartshow.com/texture/walls/wall11/ao.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/walls/wall11/height.jpg`,
    },
    settings: {
      displacementScale: 0.2,
      aoMapIntensity: 2,
      roughness: 1,
      normalScale: new THREE.Vector2(1, 3),
    },
    isPro: false,
  },
  {
    id: 12,
    title: 'TRIPPY VINTAGE TILES',
    thumbnail: `https://data.curatedartshow.com/texture/walls/wall12/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/walls/wall12/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/walls/wall12/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/walls/wall12/roughness.jpg`,
      aoMap: `https://data.curatedartshow.com/texture/walls/wall12/ao.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/walls/wall12/height.jpg`,
    },
    settings: {
      displacementScale: 0.2,
      aoMapIntensity: 0.8,
      roughness: 4,
    },
    isPro: true,
  },
  {
    id: 13,
    title: 'WOOD ORNATE PANEL',
    thumbnail: `https://data.curatedartshow.com/texture/ceilings/ceiling10/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/ceilings/ceiling10/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/ceilings/ceiling10/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/ceilings/ceiling10/roughness.jpg`,
      aoMap: `https://data.curatedartshow.com/texture/ceilings/ceiling10/ao.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/ceilings/ceiling10/height.jpg`,
    },
    settings: {
      displacementScale: 0.5,
      aoMapIntensity: 1,
      roughness: 1,
    },
    isPro: true,
  },
  {
    id: 14,
    title: 'STUCCO',
    thumbnail: `https://data.curatedartshow.com/texture/ceilings/Stucco34/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/ceilings/Stucco34/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/ceilings/Stucco34/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/ceilings/Stucco34/roughness.jpg`,
      aoMap: `https://data.curatedartshow.com/texture/ceilings/Stucco34/ao.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/ceilings/Stucco34/height.jpg`,
    },
    settings: {
      displacementScale: 0.5,
      aoMapIntensity: 0,
      roughness: 0.1,
      normalScale: new THREE.Vector2(1, 2),
    },
    isPro: false,
  },
  {
    id: 15,
    title: 'PORTUGUESE TILES',
    thumbnail: `https://data.curatedartshow.com/texture/ceilings/Portuguese_Tiles/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/ceilings/Portuguese_Tiles/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/ceilings/Portuguese_Tiles/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/ceilings/Portuguese_Tiles/roughness.jpg`,
      aoMap: `https://data.curatedartshow.com/texture/ceilings/Portuguese_Tiles/ao.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/ceilings/Portuguese_Tiles/height.jpg`,
    },
    settings: {
      displacementScale: 0.5,
      aoMapIntensity: 2,
      roughness: 10,
    },
    isPro: true,
  },
  {
    id: 16,
    title: 'STONE WALL',
    thumbnail: `https://data.curatedartshow.com/texture/walls/StoneWall13/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/walls/StoneWall13/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/walls/StoneWall13/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/walls/StoneWall13/roughness.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/walls/StoneWall13/height.jpg`,
    },
    settings: {
      displacementScale: 0.5,
      aoMapIntensity: 2,
      roughness: 0.6,
    },
    isPro: true,
  },
  {
    id: 17,
    title: 'FOREST CLOVERS',
    thumbnail: `https://data.curatedartshow.com/texture/walls/ForestClovers/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/walls/ForestClovers/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/walls/ForestClovers/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/walls/ForestClovers/roughness.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/walls/ForestClovers/height.jpg`,
    },
    settings: {
      displacementScale: 0.5,
      aoMapIntensity: 2,
      roughness: 0.6,
      normalScale: new THREE.Vector2(1, 1),
    },
    isPro: true,
  },
  {
    id: 18,
    title: 'PATTERNED TILES 4',
    thumbnail: `https://data.curatedartshow.com/texture/walls/PatternedTiles4/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/walls/PatternedTiles4/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/walls/PatternedTiles4/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/walls/PatternedTiles4/roughness.jpg`,
      aoMap: `https://data.curatedartshow.com/texture/walls/PatternedTiles4/ao.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/walls/PatternedTiles4/height.jpg`,
      metalnessMap: `https://data.curatedartshow.com/texture/walls/PatternedTiles4/metallic.jpg`,
    },
    settings: {
      displacementScale: 0.5,
      aoMapIntensity: 2,
      roughness: 0.6,
      normalScale: new THREE.Vector2(1, 4),
    },
    isPro: true,
  },
  {
    id: 19,
    title: 'PATTERNED TILES 19',
    thumbnail: `https://data.curatedartshow.com/texture/walls/PatternedTiles19/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/walls/PatternedTiles19/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/walls/PatternedTiles19/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/walls/PatternedTiles19/roughness.jpg`,
      aoMap: `https://data.curatedartshow.com/texture/walls/PatternedTiles19/ao.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/walls/PatternedTiles19/height.jpg`,
      metalnessMap: `https://data.curatedartshow.com/texture/walls/PatternedTiles19/metallic.jpg`,
    },
    settings: {
      displacementScale: 0.5,
      aoMapIntensity: 2,
      roughness: 0.6,
      normalScale: new THREE.Vector2(1, 4),
    },
    isPro: true,
  },
  {
    id: 20,
    title: 'Travertine',
    thumbnail: `https://data.curatedartshow.com/texture/floors/Travertine005/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/Travertine005/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/Travertine005/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/Travertine005/roughness.jpg`,
      aoMap: `https://data.curatedartshow.com/texture/floors/Travertine005/ao.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/Travertine005/height.jpg`,
    },
    settings: {
      displacementScale: 0.5,
      aoMapIntensity: 2,
      roughness: 0.6,
    },
    isPro: false,
  },
  // {
  //   id: 21,
  //   title: 'Moss',
  //   thumbnail: `https://data.curatedartshow.com/texture/floors/Moss002/basecolor.jpg`,
  //   images: {
  //     map: `https://data.curatedartshow.com/texture/floors/Moss002/basecolor.jpg`,
  //     normalMap: `https://data.curatedartshow.com/texture/floors/Moss002/normal.jpg`,
  //     roughnessMap: `https://data.curatedartshow.com/texture/floors/Moss002/roughness.jpg`,
  //     aoMap: `https://data.curatedartshow.com/texture/floors/Moss002/ao.jpg`,
  //     displacementMap: `https://data.curatedartshow.com/texture/floors/Moss002/height.jpg`,
  //   },
  //   settings: {
  //     displacementScale: 0.5,
  //     aoMapIntensity: 2.5,
  //     roughness: 0.6,
  //   },
  // },
  {
    id: 22,
    title: 'Wood 1',
    thumbnail: `https://data.curatedartshow.com/texture/floors/Wood007/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/Wood007/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/Wood007/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/Wood007/roughness.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/Wood007/height.jpg`,
    },
    settings: {
      displacementScale: 0.5,
      aoMapIntensity: 2,
      roughness: 0.6,
    },
    isPro: false,
  },
  {
    id: 23,
    title: 'Wood 2',
    thumbnail: `https://data.curatedartshow.com/texture/floors/Wood008/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/Wood008/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/Wood008/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/Wood008/roughness.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/Wood008/height.jpg`,
    },
    settings: {
      displacementScale: 0.5,
      aoMapIntensity: 2,
      roughness: 0.6,
    },
    isPro: false,
  },
  {
    id: 24,
    title: 'Wood 3',
    thumbnail: `https://data.curatedartshow.com/texture/floors/Wood016/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/Wood016/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/Wood016/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/Wood016/roughness.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/Wood016/height.jpg`,
    },
    settings: {
      displacementScale: 0.5,
      aoMapIntensity: 2,
      roughness: 0.6,
    },
    isPro: false,
  },
  {
    id: 25,
    title: 'Wood 4',
    thumbnail: `https://data.curatedartshow.com/texture/floors/Wood017/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/Wood017/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/Wood017/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/Wood017/roughness.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/Wood017/height.jpg`,
    },
    settings: {
      displacementScale: 0,
      aoMapIntensity: 2,
      roughness: 0.6,
      normalScale: new THREE.Vector2(1, 1),
      metalness: 0.5,
    },
    isPro: false,
  },
  {
    id: 26,
    title: 'Wood 5',
    thumbnail: `https://data.curatedartshow.com/texture/floors/Wood023/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/Wood023/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/Wood023/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/Wood023/roughness.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/Wood023/height.jpg`,
    },
    settings: {
      displacementScale: 0.5,
      aoMapIntensity: 2,
      roughness: 0.6,
    },
    isPro: false,
  },
  {
    id: 27,
    title: 'Wood 6',
    thumbnail: `https://data.curatedartshow.com/texture/floors/Wood025/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/Wood025/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/Wood025/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/Wood025/roughness.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/Wood025/height.jpg`,
    },
    settings: {
      displacementScale: 0.5,
      aoMapIntensity: 2,
      roughness: 0.6,
    },
    isPro: false,
  },
  {
    id: 28,
    title: 'Wood 7',
    thumbnail: `https://data.curatedartshow.com/texture/floors/Wood038/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/Wood038/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/Wood038/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/Wood038/roughness.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/Wood038/height.jpg`,
    },
    settings: {
      displacementScale: 0.5,
      aoMapIntensity: 2,
      roughness: 2.8,
    },
    isPro: false,
  },
  {
    id: 29,
    title: 'Wood 8',
    thumbnail: `https://data.curatedartshow.com/texture/floors/Wood043/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/Wood043/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/Wood043/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/Wood043/roughness.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/Wood043/height.jpg`,
    },
    settings: {
      displacementScale: 0.5,
      aoMapIntensity: 2,
      roughness: 0.6,
    },
    isPro: false,
  },
  {
    id: 30,
    title: 'Wood 9',
    thumbnail: `https://data.curatedartshow.com/texture/floors/Wood058/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/Wood058/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/Wood058/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/Wood058/roughness.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/Wood058/height.jpg`,
    },
    settings: {
      displacementScale: 0.5,
      aoMapIntensity: 2,
      roughness: 0.6,
      normalScale: new THREE.Vector2(1, 3),
    },
    isPro: false,
  },
  {
    id: 31,
    title: 'Wood 10',
    thumbnail: `https://data.curatedartshow.com/texture/floors/Wood084A/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/Wood084A/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/Wood084A/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/Wood084A/roughness.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/Wood084A/height.jpg`,
    },
    settings: {
      displacementScale: 0,
      aoMapIntensity: 2,
      roughness: 0.6,
    },
    isPro: false,
  },
  {
    id: 32,
    title: 'Leather 1',
    thumbnail: `https://data.curatedartshow.com/texture/floors/Leather016/thumbnail.png`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/Leather016/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/Leather016/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/Leather016/roughness.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/Leather016/height.jpg`,
      aoMap: `https://data.curatedartshow.com/texture/floors/Leather016/ao.jpg`,
      alphaMap: `https://data.curatedartshow.com/texture/floors/Leather016/alpha.jpg`,
    },
    settings: {
      displacementScale: 0,
      aoMapIntensity: 2.5,
      roughness: 0.5,
      metalness: 0.4,
      normalScale: new THREE.Vector2(1, 1),
    },
    isPro: false,
  },
  {
    id: 33,
    title: 'Leather 2',
    thumbnail: `https://data.curatedartshow.com/texture/floors/Leather020/thumbnail.png`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/Leather020/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/Leather020/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/Leather020/roughness.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/Leather020/height.jpg`,
      aoMap: `https://data.curatedartshow.com/texture/floors/Leather020/ao.jpg`,
      alphaMap: `https://data.curatedartshow.com/texture/floors/Leather020/alpha.jpg`,
    },
    settings: {
      displacementScale: 0,
      aoMapIntensity: 2.5,
      roughness: 0.5,
      metalness: 0.4,
      normalScale: new THREE.Vector2(1, 1),
    },
    isPro: false,
  },
  {
    id: 34,
    title: 'Leather 3',
    thumbnail: `https://data.curatedartshow.com/texture/floors/Leather021/thumbnail.png`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/Leather021/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/Leather021/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/Leather021/roughness.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/Leather021/height.jpg`,
    },
    settings: {
      displacementScale: 0,
      aoMapIntensity: 2,

      roughness: 0.5,
      metalness: 0.4,
      normalScale: new THREE.Vector2(1, 1),
    },
    isPro: false,
  },
  {
    id: 35,
    title: 'Leather 4',
    thumbnail: `https://data.curatedartshow.com/texture/floors/Leather024/thumbnail.png`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/Leather024/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/Leather024/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/Leather024/roughness.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/Leather024/height.jpg`,
    },
    settings: {
      displacementScale: 0,
      aoMapIntensity: 2,
      roughness: 0.5,
      metalness: 0.4,
      normalScale: new THREE.Vector2(1, 1),
    },
    isPro: true,
  },
  {
    id: 36,
    title: 'Leather 5',
    thumbnail: `https://data.curatedartshow.com/texture/floors/Leather026/thumbnail.png`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/Leather026/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/Leather026/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/Leather026/roughness.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/Leather026/height.jpg`,
    },
    settings: {
      displacementScale: 0,
      aoMapIntensity: 2,
      roughness: 0.5,
      metalness: 0.4,
      normalScale: new THREE.Vector2(1, 1),
    },
    isPro: true,
  },
  {
    id: 37,
    title: 'Fabric 1',
    thumbnail: `https://data.curatedartshow.com/texture/floors/Fabric020/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/Fabric020/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/Fabric020/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/Fabric020/roughness.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/Fabric020/height.jpg`,
      alphaMap: `https://data.curatedartshow.com/texture/floors/Fabric020/alpha.jpg`,
    },
    settings: {
      displacementScale: 0.5,
      aoMapIntensity: 2,
      roughness: 1,
    },
    isPro: false,
  },
  {
    id: 38,
    title: 'Fabric 2',
    thumbnail: `https://data.curatedartshow.com/texture/floors/Fabric022/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/Fabric022/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/Fabric022/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/Fabric022/roughness.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/Fabric022/height.jpg`,
    },
    settings: {
      displacementScale: 0.5,
      aoMapIntensity: 2,
      roughness: 1,
    },
    isPro: false,
  },
  {
    id: 39,
    title: 'Fabric 3',
    thumbnail: `https://data.curatedartshow.com/texture/floors/Fabric026/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/Fabric026/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/Fabric026/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/Fabric026/roughness.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/Fabric026/height.jpg`,
    },
    settings: {
      displacementScale: 0.5,
      aoMapIntensity: 2,
      roughness: 1,
    },
    isPro: false,
  },
  {
    id: 40,
    title: 'Fabric 4',
    thumbnail: `https://data.curatedartshow.com/texture/floors/Fabric060/thumbnail.png`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/Fabric060/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/Fabric060/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/Fabric060/roughness.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/Fabric060/height.jpg`,
      aoMap: `https://data.curatedartshow.com/texture/floors/Fabric060/ao.jpg`,
    },
    settings: {
      displacementScale: 0.5,
      aoMapIntensity: 3,
      roughness: 0.6,
      normalScale: new THREE.Vector2(2, 1),
      metalness: 0.9,
    },
    isPro: false,
  },
  {
    id: 41,
    title: 'Marble 1',
    thumbnail: `https://data.curatedartshow.com/texture/floors/Marble006/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/Marble006/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/Marble006/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/Marble006/roughness.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/Marble006/height.jpg`,
    },
    settings: {
      displacementScale: 0,
      aoMapIntensity: 2,
      roughness: 0.5,
      metalness: 0.1,
    },
    isPro: false,
  },
  {
    id: 42,
    title: 'Marble 2',
    thumbnail: `https://data.curatedartshow.com/texture/floors/Marble008/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/Marble008/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/Marble008/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/Marble008/roughness.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/Marble008/height.jpg`,
    },
    settings: {
      displacementScale: 0.5,
      aoMapIntensity: 2,
      roughness: 0.5,
      metalness: 0.1,
    },
    isPro: false,
  },
  {
    id: 43,
    title: 'Marble 3',
    thumbnail: `https://data.curatedartshow.com/texture/floors/Marble009/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/Marble009/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/Marble009/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/Marble009/roughness.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/Marble009/height.jpg`,
    },
    settings: {
      displacementScale: 0,
      aoMapIntensity: 2,
      roughness: 0.5,
      metalness: 0.1,
    },
    isPro: true,
  },
  {
    id: 44,
    title: 'Marble 4',
    thumbnail: `https://data.curatedartshow.com/texture/floors/Marble010/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/Marble010/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/Marble010/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/Marble010/roughness.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/Marble010/height.jpg`,
    },
    settings: {
      displacementScale: 0,
      aoMapIntensity: 2,
      roughness: 0.5,
      metalness: 0.1,
      normalScale: new THREE.Vector2(1, 4),
    },
    isPro: true,
  },
  {
    id: 45,
    title: 'Metal 1',
    thumbnail: `https://data.curatedartshow.com/texture/floors/Metal011/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/Metal011/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/Metal011/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/Metal011/roughness.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/Metal011/height.jpg`,
      metalnessMap: `https://data.curatedartshow.com/texture/floors/Metal011/metalness.jpg`,
    },
    settings: {
      displacementScale: 0.5,
      aoMapIntensity: 2,
      roughness: 1,
      metalness: 0.5,
      normalScale: new THREE.Vector2(1, 2),
    },
    isPro: false,
  },
  {
    id: 46,
    title: 'Metal 2',
    thumbnail: `https://data.curatedartshow.com/texture/floors/Metal015/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/Metal015/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/Metal015/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/Metal015/roughness.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/Metal015/height.jpg`,
      metalnessMap: `https://data.curatedartshow.com/texture/floors/Metal015/metalness.jpg`,
    },
    settings: {
      displacementScale: 0.5,
      aoMapIntensity: 2,
      roughness: 1,
      metalness: 0.7,
      normalScale: new THREE.Vector2(1, 3),
    },
    isPro: false,
  },
  {
    id: 47,
    title: 'Metal 3',
    thumbnail: `https://data.curatedartshow.com/texture/floors/Metal028/thumbnail.png`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/Metal028/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/Metal028/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/Metal028/roughness.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/Metal028/height.jpg`,
      metalnessMap: `https://data.curatedartshow.com/texture/floors/Metal028/metalness.jpg`,
    },
    settings: {
      displacementScale: 0.5,
      aoMapIntensity: 2,
      roughness: 2,
      metalness: 0.5,
      normalScale: new THREE.Vector2(1, 3),
    },
    isPro: true,
  },
  {
    id: 48,
    title: 'Metal 4',
    thumbnail: `https://data.curatedartshow.com/texture/floors/Metal032/thumbnail.png`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/Metal032/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/Metal032/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/Metal032/roughness.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/Metal032/height.jpg`,
      metalnessMap: `https://data.curatedartshow.com/texture/floors/Metal032/metalness.jpg`,
    },
    settings: {
      displacementScale: 0,
      aoMapIntensity: 2,
      roughness: 1,
      metalness: 0.5,
      normalScale: new THREE.Vector2(1, 3),
    },
    isPro: true,
  },
  {
    id: 49,
    title: 'Metal 5',
    thumbnail: `https://data.curatedartshow.com/texture/floors/Metal035/thumbnail.png`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/Metal035/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/Metal035/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/Metal035/roughness.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/Metal035/height.jpg`,
      metalnessMap: `https://data.curatedartshow.com/texture/floors/Metal035/metalness.jpg`,
    },
    settings: {
      displacementScale: 0,
      aoMapIntensity: 2,
      roughness: 0.5,
      metalness: 0.5,
      normalScale: new THREE.Vector2(1, 3),
    },
    isPro: true,
  },
  {
    id: 50,
    title: 'Metal 6',
    thumbnail: `https://data.curatedartshow.com/texture/floors/Metal036/thumbnail.png`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/Metal036/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/Metal036/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/Metal036/roughness.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/Metal036/height.jpg`,
      metalnessMap: `https://data.curatedartshow.com/texture/floors/Metal036/metalness.jpg`,
    },
    settings: {
      displacementScale: 0,
      aoMapIntensity: 2,
      roughness: 0.5,
      metalness: 0.7,
      normalScale: new THREE.Vector2(1, 3),
    },
    isPro: true,
  },
  {
    id: 51,
    title: 'Metal 7',
    thumbnail: `https://data.curatedartshow.com/texture/floors/Metal039/thumbnail.png`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/Metal039/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/Metal039/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/Metal039/roughness.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/Metal039/height.jpg`,
      metalnessMap: `https://data.curatedartshow.com/texture/floors/Metal039/metalness.jpg`,
    },
    settings: {
      displacementScale: 0.5,
      aoMapIntensity: 2,
      roughness: 0.6,
      metalness: 0.7,
      normalScale: new THREE.Vector2(1, 3),
    },
    isPro: true,
  },
  {
    id: 52,
    title: 'Wood 11',
    thumbnail: `https://data.curatedartshow.com/texture/floors/WoodFloor005/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/WoodFloor005/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/WoodFloor005/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/WoodFloor005/roughness.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/WoodFloor005/height.jpg`,
    },
    settings: {
      displacementScale: 0.5,
      aoMapIntensity: 2,
      roughness: 1,
    },
    isPro: true,
  },
  {
    id: 53,
    title: 'Bricks 2',
    thumbnail: `https://data.curatedartshow.com/texture/floors/Bricks004/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/Bricks004/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/Bricks004/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/Bricks004/roughness.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/Bricks004/height.jpg`,
      aoMap: `https://data.curatedartshow.com/texture/floors/Bricks004/ao.jpg`,
    },
    settings: {
      displacementScale: 0.5,
      aoMapIntensity: 2.5,
      roughness: 3,
      normalScale: new THREE.Vector2(2, 1),
      metalness: 0.5,
    },
    isPro: true,
  },
  {
    id: 54,
    title: 'Bricks 3',
    thumbnail: `https://data.curatedartshow.com/texture/floors/Bricks005/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/Bricks005/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/Bricks005/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/Bricks005/roughness.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/Bricks005/height.jpg`,
    },
    settings: {
      displacementScale: 0.5,
      aoMapIntensity: 2.5,
      roughness: 0.5,
      normalScale: new THREE.Vector2(2, 1),
    },
    isPro: true,
  },
  {
    id: 55,
    title: 'Bricks 4',
    thumbnail: `https://data.curatedartshow.com/texture/floors/Bricks059/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/Bricks059/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/Bricks059/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/Bricks059/roughness.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/Bricks059/height.jpg`,
    },
    settings: {
      displacementScale: 0.5,
      aoMapIntensity: 2.5,
      roughness: 3,
      normalScale: new THREE.Vector2(1, 3),
    },
    isPro: true,
  },
  {
    id: 56,
    title: 'Concrete 4',
    thumbnail: `https://data.curatedartshow.com/texture/floors/Concrete004/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/Concrete004/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/Concrete004/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/Concrete004/roughness.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/Concrete004/height.jpg`,
      aoMap: `https://data.curatedartshow.com/texture/floors/Concrete004/ao.jpg`,
    },
    settings: {
      displacementScale: 0.5,
      aoMapIntensity: 2.2,
      roughness: 0.7,
      normalScale: new THREE.Vector2(1, 3),
    },
    isPro: true,
  },
  {
    id: 57,
    title: 'Concrete 5',
    thumbnail: `https://data.curatedartshow.com/texture/floors/Concrete020/basecolor.jpg`,
    images: {
      map: `https://data.curatedartshow.com/texture/floors/Concrete020/basecolor.jpg`,
      normalMap: `https://data.curatedartshow.com/texture/floors/Concrete020/normal.jpg`,
      roughnessMap: `https://data.curatedartshow.com/texture/floors/Concrete020/roughness.jpg`,
      displacementMap: `https://data.curatedartshow.com/texture/floors/Concrete020/height.jpg`,
    },
    settings: {
      displacementScale: 0.5,
      aoMapIntensity: 1.2,
      roughness: 1,
      normalScale: new THREE.Vector2(1, 3),
    },
    isPro: true,
  },
  {
    id: 58,
    title: 'White Paint',
    thumbnail: `https://data.curatedartshow.com/texture/walls/whitepaint/basecolor.png`,
    images: {
      map: `https://data.curatedartshow.com/texture/walls/whitepaint/basecolor.png`,
      normalMap: `https://data.curatedartshow.com/texture/walls/whitepaint/normal.png`,
      roughnessMap: `https://data.curatedartshow.com/texture/walls/whitepaint/roughness.png`,
      metalnessMap: `https://data.curatedartshow.com/texture/walls/whitepaint/metallic.png`,
      displacementMap: `https://data.curatedartshow.com/texture/walls/whitepaint/height.png`,
    },
    settings: {
      displacementScale: 0.5,
      aoMapIntensity: 1,
      roughness: 1,
      normalScale: new THREE.Vector2(1, 1),
      metalness: 0,
    },
    isPro: true,
  },
];
