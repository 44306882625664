import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import LoadingSpinner from '../../../components/LoadingSpinner';
import { useDispatch, useSelector } from 'react-redux';
import { loadEnd, loadStart } from '../../../redux/loadRedux';
import { logout } from '../../../redux/userRedux';
import { authRequest } from '../../../requestMethods';
import { errorToast, successToast } from '../../../utils/toast';
import { useNavigate } from 'react-router-dom';

const CancelSubscriptionModal = ({ isFreeTrial }) => {
  const FEEDBACK_OPTIONS = [
    {
      value: 1,
      label: 'Difficult to use',
      labelJa: '使いにくい',
    },
    {
      value: 2,
      label: 'Exhibition setup unsatisfactory',
      labelJa: '展示会のセットアップ機能に不満',
    },
    {
      value: 3,
      label: 'Exhibition viewing experience unsatisfactory',
      labelJa: '展示会の鑑賞体験に不満',
    },
    {
      value: 4,
      label: 'Dissatisfied with customer support',
      labelJa: 'カスタマーサポートに不満',
    },
    {
      value: 5,
      label: 'Too expensive',
      labelJa: '料金が高すぎる',
    },
    {
      value: 6,
      label: 'Lacks desired features (please give examples)',
      labelJa: '必要な機能が不足している（具体的に教えてください）',
    },
    {
      value: 7,
      label: 'Other',
      labelJa: 'その他',
    },
  ];
  const { isLoading } = useSelector((state) => state.isLoading);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isChecked, setIsChecked] = useState(false);
  const [isAnswered, setIsAnswered] = useState(false);
  const [reason, setReason] = useState(null);
  const [freeComment, setFreeComment] = useState('');
  const { i18n, t } = useTranslation();

  const handleCancelSubscription = async () => {
    try {
      dispatch(loadStart());
      await authRequest.post('/stripe/subscriptions/cancel', {
        reason,
        freeComment,
      });

      document.getElementById('cancel_subscription_modal').close();

      dispatch(logout());
      navigate('/');
      //logoutする
      successToast(t('message.success.t27'), 'Infinity');
    } catch (error) {
      errorToast(t('message.error.t1'));
      console.error(error);
    } finally {
      dispatch(loadEnd());
    }
  };

  const handleCancelFreeTrial = async () => {
    try {
      dispatch(loadStart());
      await authRequest.post('/stripe/free-trial/cancel', {
        reason,
        freeComment,
      });

      document.getElementById('cancel_subscription_modal').close();

      dispatch(logout());
      navigate('/');
      //logoutする
      successToast(t('message.success.t28'), 'Infinity');
    } catch (error) {
      errorToast(t('message.error.t1'));
      console.error(error);
    } finally {
      dispatch(loadEnd());
    }
  };

  useEffect(() => {
    const modal = document.getElementById('cancel_subscription_modal');

    // モーダルの属性を監視
    const observer = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (
          mutation.type === 'attributes' &&
          mutation.attributeName === 'open'
        ) {
          if (!modal.hasAttribute('open')) {
            setFreeComment('');
            setReason(null);
            setIsAnswered(false);
            setIsChecked(false);
          }
        }
      }
    });

    // 監視の開始
    observer.observe(modal, { attributes: true });

    // クリーンアップ関数で監視を停止
    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <dialog id='cancel_subscription_modal' className='modal'>
      {isAnswered ? (
        <>
          {isFreeTrial ? (
            <div className='modal-box flex max-w-lg flex-col gap-6'>
              <h3 className='m-0 text-lg font-bold'>
                <Trans i18nKey={'free-trial-cancel-modal.ttl'} />
              </h3>
              <p className='text-error'>
                <Trans i18nKey={'free-trial-cancel-modal.heading'} />
              </p>
              <div className='flex flex-col gap-5'>
                <p className=''>
                  <Trans i18nKey={'free-trial-cancel-modal.desc'} />
                </p>

                <div className='form-control'>
                  <label className='label flex cursor-pointer justify-start'>
                    <input
                      type='checkbox'
                      className='!checkbox-error checkbox'
                      value={isChecked}
                      onChange={(e) => setIsChecked(e.target.checked)}
                    />
                    <span className='label-text ml-3'>
                      <Trans i18nKey={'free-trial-cancel-modal.agree'} />
                    </span>
                  </label>
                </div>
              </div>
              <div className='modal-action'>
                <form method='dialog'>
                  {/* if there is a button in form, it will close the modal */}
                  <button className='btn'>
                    <Trans i18nKey={'btn.cancel'} />
                  </button>
                </form>
                <button
                  className={`btn ${
                    isChecked ? '!btn-error' : '!non-active-filled'
                  }`}
                  disabled={!isChecked}
                  onClick={handleCancelFreeTrial}
                >
                  <Trans
                    i18nKey={'subscription-cancellation-modal.cancel-btn'}
                  />
                </button>
              </div>
            </div>
          ) : (
            <div className='modal-box flex max-w-lg flex-col gap-6'>
              <h3 className='m-0 text-lg font-bold'>
                <Trans i18nKey={'subscription-cancellation-modal.ttl'} />
              </h3>
              <p className='text-error'>
                <Trans i18nKey={'subscription-cancellation-modal.desc-1'} />
              </p>
              <div className='flex flex-col gap-5'>
                <p className=''>
                  <Trans i18nKey={'subscription-cancellation-modal.desc-2'} />
                </p>
                <p>
                  <Trans i18nKey={'subscription-cancellation-modal.desc-3'} />
                </p>
                <p>
                  <Trans i18nKey={'subscription-cancellation-modal.desc-4'} />
                </p>
                <div className='form-control'>
                  <label className='label flex cursor-pointer justify-start'>
                    <input
                      type='checkbox'
                      className='!checkbox-error checkbox'
                      value={isChecked}
                      onChange={(e) => setIsChecked(e.target.checked)}
                    />
                    <span className='label-text ml-3'>
                      <Trans
                        i18nKey={'subscription-cancellation-modal.checkbox'}
                        a
                      />
                    </span>
                  </label>
                </div>
              </div>
              <div className='modal-action'>
                <form method='dialog'>
                  {/* if there is a button in form, it will close the modal */}
                  <button className='btn'>
                    <Trans i18nKey={'btn.cancel'} />
                  </button>
                </form>
                <button
                  className={`btn ${
                    isChecked ? '!btn-error' : '!non-active-filled'
                  }`}
                  disabled={!isChecked}
                  onClick={handleCancelSubscription}
                >
                  <Trans
                    i18nKey={'subscription-cancellation-modal.cancel-btn'}
                  />
                </button>
              </div>
            </div>
          )}
        </>
      ) : (
        <div className='modal-box flex max-w-lg flex-col'>
          <h3 className='m-0 text-lg font-bold'>
            <Trans i18nKey={'feedback-modal.ttl'} />
          </h3>
          <p className='my-6 text-[#FF5663]'>
            <Trans i18nKey={'feedback-modal.heading'} />
          </p>
          <p className='pb-5'>
            <Trans i18nKey={'feedback-modal.desc'} />
          </p>
          <div className='flex flex-col gap-1'>
            {FEEDBACK_OPTIONS.map((item) => (
              <div key={`feedback-option-${item.label}`}>
                <div className='form-control'>
                  <label className='label cursor-pointer justify-start gap-2 py-0'>
                    <input
                      type='radio'
                      className='radio !border-base-content '
                      id={item.label}
                      value={item.value}
                      onChange={(e) => {
                        setFreeComment('');
                        setReason(Number(e.target.value));
                      }}
                      name='feedback'
                      checked={item.value == reason}
                    />
                    <span className='label-text'>
                      {i18n.language === 'ja' ? item.labelJa : item.label}
                    </span>
                  </label>
                </div>
                {((item.value == 6 && reason == 6) ||
                  (item.value == 7 && reason == 7)) && (
                  <label className='form-control pl-8'>
                    <textarea
                      className='textarea textarea-bordered h-20'
                      placeholder={
                        item.value == 6
                          ? i18n.language === 'ja'
                            ? '具体例をご記入ください'
                            : 'please give examples'
                          : i18n.language === 'ja'
                          ? '詳細をご記入ください'
                          : 'please specify'
                      }
                      onChange={(e) => {
                        setFreeComment(e.target.value);
                      }}
                      value={freeComment}
                    ></textarea>
                    <div className='label'>
                      <span className='label-text-alt'></span>
                      <span className='label-text-alt'>
                        {freeComment.length} / 500
                      </span>
                    </div>
                  </label>
                )}
              </div>
            ))}
          </div>
          <div className='modal-action pt-6'>
            <form method='dialog'>
              {/* if there is a button in form, it will close the modal */}
              <button className='btn'>
                <Trans i18nKey={'btn.close'} />
              </button>
            </form>
            <button
              className={`btn ${
                !reason ||
                (reason == 6 && !freeComment) ||
                (reason == 7 && !freeComment)
                  ? '!non-active-filled'
                  : '!btn-error !bg-[#FF5663]'
              }`}
              disabled={
                !reason ||
                (reason == 6 && !freeComment) ||
                (reason == 7 && !freeComment)
              }
              onClick={() => {
                setIsAnswered(true);
              }}
            >
              <Trans i18nKey={'btn.continue'} />
            </button>
          </div>
        </div>
      )}

      {isLoading && <LoadingSpinner />}
    </dialog>
  );
};

export default CancelSubscriptionModal;
