import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  XMarkIcon,
  ArrowTopRightOnSquareIcon,
  MagnifyingGlassIcon,
} from '@heroicons/react/24/outline';
import dayjs from 'dayjs';

export const ChooseArtworks = ({
  availableItems,
  isLoading,
  moveTo,
  preSelectedArtworks,
  openUploadPage,
}) => {
  const MAX_SELECT_ARTWORKS = 20;
  const [filteredItems, setFilteredItems] = useState([]);
  const [selectedArtworks, setSelectedArtworks] = useState([]);
  const [keyword, setKeyword] = useState('');
  const { t, i18n } = useTranslation();

  // 作品追加・削除処理
  const handleChooseArtwork = (artwork) => {
    const hasSelected = selectedArtworks.some(
      (selectedItem) => selectedItem.id == artwork.id
    );
    if (hasSelected) {
      const removed = selectedArtworks.filter(
        (selectedItem) => selectedItem.id !== artwork.id
      );
      setSelectedArtworks(removed);
    } else {
      setSelectedArtworks((selectedArtworks) => [...selectedArtworks, artwork]);
    }
  };

  // 作品追加日
  const addedDate = (date) => {
    if (date) {
      const dayjsLocal = dayjs(date);
      return i18n.language === 'ja'
        ? dayjsLocal.format('YYYY年M月D日')
        : dayjsLocal.format('MMM D, YYYY');
    }
    return 'NOT FOUND';
  };

  // Arrange Artworksから戻ってきた際の処理
  useEffect(() => {
    if (0 < preSelectedArtworks.length) {
      setSelectedArtworks((selectedArtworks) => [
        ...selectedArtworks,
        ...preSelectedArtworks,
      ]);
    }
  }, [preSelectedArtworks]);

  const handleInputChange = (event) => {
    setKeyword(event.target.value);
  };

  // 検索
  useEffect(() => {
    if (availableItems && availableItems.length > 0) {
      const items = availableItems
        .filter((item) => {
          return (
            item?.title.toLowerCase().includes(keyword.toLowerCase()) ||
            item?.creator?.displayName
              .toLowerCase()
              .includes(keyword.toLowerCase())
          );
        })
        .sort((a, b) => a.id - b.id);
      setFilteredItems(items);
    }
  }, [availableItems, keyword]);

  return (
    <>
      <dialog id='choose_artworks' className='modal'>
        <div className='modal-box h-full max-h-none w-full max-w-none rounded-none px-4 py-2 md:max-h-[48rem] md:max-w-[50.375rem] md:rounded-2xl md:p-6'>
          <div className='flex h-full flex-col gap-6'>
            <div className='flex items-center justify-between md:hidden'>
              <Link
                to={'/dashboard'}
                className='p-4 focus-visible:outline-none'
              >
                <XMarkIcon className='h-6 w-6' />
              </Link>
              <h1 className='font-semibold'>
                <Trans i18nKey={'gallery.choose-artworks.new-exhibition'} />
              </h1>
              <span
                className={`px-4 py-2 text-sm font-semibold ${
                  selectedArtworks.length < 1 ||
                  MAX_SELECT_ARTWORKS < selectedArtworks.length
                    ? 'text-gray-400'
                    : 'text-primary'
                }`}
                onClick={() => {
                  if (MAX_SELECT_ARTWORKS < selectedArtworks.length) {
                    return;
                  }
                  if (0 < selectedArtworks.length) {
                    moveTo('ArrangeArtworks', selectedArtworks);
                  }
                }}
              >
                <Trans i18nKey={'btn.next'} />
              </span>
            </div>
            <h2 className='text-lg font-bold'>
              <Trans i18nKey={'gallery.choose-artworks.ttl'} />
            </h2>
            {!isLoading && 0 < availableItems.length && (
              <div className='flex justify-end'>
                <button
                  onClick={openUploadPage}
                  className='btn btn-ghost btn-sm focus-visible:outline-none'
                >
                  <Trans i18nKey={'gallery.choose-artworks.upload-artworks'} />{' '}
                  <ArrowTopRightOnSquareIcon className='h-5 w-5' />
                </button>
              </div>
            )}
            <label className='input input-bordered flex w-full flex-shrink-0 items-center gap-2 lg:max-w-none'>
              <MagnifyingGlassIcon className='h-4 w-4 text-[#9CA3AF]' />
              <input
                type='search'
                className='grow text-sm placeholder:text-sm'
                placeholder={t('available-items-modal.placeholder')}
                onChange={handleInputChange}
                value={keyword}
              />
            </label>
            {!isLoading && availableItems.length < 1 ? (
              <div>
                <p>
                  <Trans i18nKey={'gallery.choose-artworks.no-artwork'} />
                </p>
                <div className='flex justify-center'>
                  <a
                    href='/create-item'
                    target='_blank'
                    className='btn btn-primary btn-block mt-6 focus-visible:outline-none'
                  >
                    <Trans i18nKey={'gallery.choose-artworks.add-artworks'} />{' '}
                    <ArrowTopRightOnSquareIcon className='h-5 w-5' />
                  </a>
                </div>
              </div>
            ) : (
              <>
                <div className='no-scrollbar h-full overflow-scroll'>
                  {isLoading ? (
                    <span className='loading loading-spinner text-primary'></span>
                  ) : (
                    filteredItems?.map((item, index) => {
                      return (
                        <div key={`choose-artworks_${item.id}`}>
                          <label className='label flex cursor-pointer justify-start'>
                            <div className='px-2'>
                              <input
                                type='checkbox'
                                className='checkbox-primary checkbox'
                                value={item}
                                onChange={() => handleChooseArtwork(item)}
                                checked={selectedArtworks.some(
                                  (artwork) => artwork.id === item.id
                                )}
                              />
                            </div>
                            <div className='px-2'>
                              <div
                                className='h-20 w-20 rounded-lg border border-base-300 bg-contain bg-center bg-no-repeat'
                                style={{
                                  backgroundImage: `url(${item.itemUrl})`,
                                }}
                              ></div>
                            </div>
                            <div className='flex-grow px-2 text-xs md:grid md:grid-cols-4 md:px-0 md:text-sm'>
                              <div className='line-clamp-1 font-bold md:px-4'>
                                {item.title}
                              </div>
                              <div className='line-clamp-1 md:px-4'>
                                {item.creator.displayName}
                              </div>
                              <div className='line-clamp-1 md:px-4'>
                                {item.createdYear}
                              </div>
                              <div className='line-clamp-1 md:px-4'>
                                <span className='mr-1 font-semibold text-[#1F2A3799]/60 md:hidden'>
                                  <Trans
                                    i18nKey={'available-items-modal.added'}
                                  />
                                </span>
                                {addedDate(item.createdAt)}
                              </div>
                            </div>
                          </label>
                          {filteredItems.length - 1 !== index && (
                            <div className='divider m-0'></div>
                          )}
                        </div>
                      );
                    })
                  )}
                </div>
                <div className='hidden justify-end gap-2 md:flex'>
                  <Link to={'/dashboard'} className='btn'>
                    <Trans i18nKey={'btn.cancel'} />
                  </Link>
                  <button
                    className='btn btn-primary'
                    onClick={() => {
                      if (MAX_SELECT_ARTWORKS < selectedArtworks.length) {
                        return;
                      }
                      moveTo('ArrangeArtworks', [...selectedArtworks]);
                    }}
                    disabled={
                      selectedArtworks.length < 1 ||
                      MAX_SELECT_ARTWORKS < selectedArtworks.length
                    }
                  >
                    <Trans i18nKey={'btn.next'} />
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </dialog>
    </>
  );
};
