import {
  BakeShadows,
  Cloud,
  Environment,
  OrbitControls,
  useHelper,
} from '@react-three/drei';
import { EffectComposer } from '@react-three/postprocessing';
import React, { Suspense, useContext, useEffect, useMemo, useRef } from 'react';
import Loader from '../Loader';
import Items from '../Items';

import Woman1 from '../models/Woman1';
import Kids1 from '../models/Kids1';
import Woman2 from '../models/Woman2';
import Woman3 from '../models/Woman3';
import Man1 from '../models/Man1';
import Man2 from '../models/Man2';
import Man3 from '../models/Man3';
import Kids2 from '../models/Kids2';
import Kids3 from '../models/Kids3';

import { ExhibitionControllerContext } from '../../../context/ExhibitionControllerContext';
import { ExhibitionContext } from '../../../context/ExhibitionContext';
import { useLoader, useThree } from '@react-three/fiber';
import { TextureLoader } from 'three';
import ItemOnPreview from './ItemOnPreview';

const SkyPreview = ({
  theme,
  frame,
  showSilhouette,
  setSnapshot,
  items,
  frameColor,
  matColor,
}) => {
  const GOLDENRATIO = 1.61803398875;
  const { camera, gl } = useThree();
  const bgColor = '#191920';

  const directLightIntensity = 1;
  const ambientLightIntensity = 1;

  const imageMap = useLoader(
    TextureLoader,
    items.length > 0 ? items[0]?.itemUrl : '/assets/img/sample_item_image.png'
  );

  // const aspectRatio =
  //   imageMap.source?.data?.width / imageMap.source?.data?.height;
  // const scaleValues = useMemo(() => {
  //   const scaleY = GOLDENRATIO;
  //   const scaleX = scaleY * aspectRatio;
  //   return { scaleX, scaleY };
  // }, [aspectRatio]);

  useEffect(() => {
    return () => {
      gl.dispose();
      // imageMap.dispose();
      useLoader.clear(
        TextureLoader,
        items.length > 0
          ? items[0]?.itemUrl
          : '/assets/img/sample_item_image.png'
      );
    };
  }, [gl]);

  useEffect(() => {
    camera.lookAt(0, 0.8, 1.1);
  }, [camera]);

  return (
    <>
      {/* <OrbitControls /> */}
      {/* <BakeShadows /> */}
      <EffectComposer>{/* <Bloom intensity={0.1} /> */}</EffectComposer>
      <Suspense fallback={<Loader />}>
        <color attach='background' args={[`${bgColor}`]} />
        <fog attach='fog' args={[`${bgColor}`, 6, 75]} />
        <directionalLight
          // ref={dirLight}
          position={[0, 2, 20]}
          target-position={[0, 2.5, 0]}
          intensity={directLightIntensity}
          color={'#f8f5e1'}
          castShadow
        />

        <ambientLight intensity={ambientLightIntensity} color={'#ffffff'} />
        <Cloud
          seed={1}
          scale={2}
          volume={5}
          color='white'
          position={[-1, -4, 0]}
          fade={20}
        />
        {/* <Cloud
          seed={2}
          scale={2}
          volume={5}
          color='white'
          position={[20, -4, -2]}
          fade={20}
        /> */}
        {/* <Cloud
          seed={3}
          scale={2}
          volume={5}
          color='white'
          position={[40, -4, 0]}
          fade={25}
        />
        <Cloud
          seed={4}
          scale={2}
          volume={5}
          color='white'
          position={[60, -4, 0]}
        /> */}

        <group position={[0, -0.5, 0]}>
          {items.length == 1 && (
            <ItemOnPreview
              position={[-0.2, 0.85, 3]}
              item={items[0]}
              frame={frame}
              frameColor={frameColor}
              matColor={matColor}
            />
          )}
          {items.length == 2 && (
            <>
              <ItemOnPreview
                position={[-1.3, 0.85, 3]}
                item={items[0]}
                frame={frame}
                frameColor={frameColor}
                matColor={matColor}
              />

              <ItemOnPreview
                position={[1.3, 0.85, 3]}
                item={items[1]}
                frame={frame}
                frameColor={frameColor}
                matColor={matColor}
              />
            </>
          )}
          {items.length >= 3 && (
            <>
              <ItemOnPreview
                position={[-3.6, 0.85, 3]}
                item={items[0]}
                frame={frame}
                frameColor={frameColor}
                matColor={matColor}
              />

              <ItemOnPreview
                position={[-0.2, 0.85, 3]}
                item={items[1]}
                frame={frame}
                frameColor={frameColor}
                matColor={matColor}
              />

              <ItemOnPreview
                position={[3.6, 0.85, 3]}
                item={items[2]}
                frame={frame}
                frameColor={frameColor}
                matColor={matColor}
              />
            </>
          )}
          {showSilhouette && (
            <Man1 position={[-0.5, 0, 3.8]} scale={0.45} rotation-y={Math.PI} />
          )}
        </group>
        <Environment
          background
          backgroundIntensity={1}
          backgroundBlurriness={0}
          files={
            'https://data.curatedartshow.com/hdri/industrial_sunset_02_puresky_1k.hdr'
          }
        />
      </Suspense>
    </>
  );
};

export default SkyPreview;
