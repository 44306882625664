import React, { useContext, useMemo } from 'react';
import Man1 from './models/Man1';
import Man2 from './models/Man2';
import Man3 from './models/Man3';
import Woman1 from './models/Woman1';
import Woman2 from './models/Woman2';
import Woman3 from './models/Woman3';
import Kids1 from './models/Kids1';
import Kids2 from './models/Kids2';
import Kids3 from './models/Kids3';
import { ExhibitionContext } from '../../context/ExhibitionContext';

const SilhouetteGroup = () => {
  const { exhibitionItems, exhibitionSettings } = useContext(ExhibitionContext);

  //作品を表示している座標（x軸座標）を取得し配列で保持
  const itemPositionArr = useMemo(() => {
    if (exhibitionItems && exhibitionItems?.length > 0) {
      return exhibitionItems.map((item, index) => 2 * index * 1.3);
    } else {
      return [];
    }
  }, [exhibitionItems]);

  //シルエットを表示する数を計算。作品数に依存
  const numberOfSilhouettes = useMemo(() => {
    if (exhibitionItems.length == 0) {
      return 0;
    } else if (exhibitionItems.length < 3) {
      return 1;
    } else {
      return Math.round(exhibitionItems.length * 0.6);
    }
  }, [exhibitionItems]);

  // シャッフルする関数
  const shuffleArray = (array) => {
    return array.sort(() => Math.random() - 0.5);
  };

  //表示するシエルエットの種類を決める。
  //settingsで指定されたシルエットは必ず1回は表示する
  const modelTypes = useMemo(() => {
    //settingsで指定されたシルエットをシャッフルして格納
    const resultArray = shuffleArray([...exhibitionSettings.silhouettes]);

    if (numberOfSilhouettes > resultArray.length) {
      // numberOfSilhouettesの数になるようにランダムに種類を追加
      while (resultArray.length < numberOfSilhouettes) {
        const randomItem =
          exhibitionSettings.silhouettes[
            Math.floor(Math.random() * exhibitionSettings.silhouettes.length)
          ];
        resultArray.push(randomItem);
      }
      return resultArray;
    } else if (numberOfSilhouettes == resultArray.length) {
      //そのまま返す
      return resultArray;
    } else {
      //numberOfSilhouttesの数になるように配列を減らす
      return resultArray.slice(0, numberOfSilhouettes);
    }
  }, [exhibitionSettings, numberOfSilhouettes]);

  //シルエットを表示するポジションを計算（配列を返す）
  const renderPositionArr = useMemo(() => {
    const chosenPositions = [];

    while (chosenPositions.length < numberOfSilhouettes) {
      // itemPositionArrからランダムに位置を取得
      let randomPos =
        itemPositionArr[Math.floor(Math.random() * itemPositionArr.length)];

      // 重複チェック
      while (chosenPositions.includes(randomPos)) {
        randomPos += 0.5; // 重複している場合は+0.5して位置をずらす
      }

      chosenPositions.push(randomPos);
    }

    return chosenPositions;
  }, [itemPositionArr, numberOfSilhouettes]);

  return (
    <>
      {exhibitionSettings?.silhouette &&
        modelTypes?.map((item, index) => {
          switch (item) {
            case 1:
              return (
                <Man1
                  key={`man1-${index}`}
                  position={[renderPositionArr[index], 0, 3.4]}
                  scale={0.45}
                  rotation-y={Math.PI}
                />
              );
            case 2:
              return (
                <Man2
                  key={`man2-${index}`}
                  position={[renderPositionArr[index], 0, 3.4]}
                  scale={0.45}
                  rotation-y={Math.PI}
                />
              );
            case 3:
              return (
                <Man3
                  key={`man3-${index}`}
                  position={[renderPositionArr[index], 0, 3.4]}
                  scale={0.45}
                  rotation-y={Math.PI}
                />
              );
            case 4:
              return (
                <Woman1
                  key={`woman1-${index}`}
                  position={[renderPositionArr[index], 0, 3.4]}
                  scale={0.45}
                  rotation-y={Math.PI}
                />
              );
            case 5:
              return (
                <Woman2
                  key={`woman2-${index}`}
                  position={[renderPositionArr[index], 0, 3.4]}
                  scale={0.45}
                  rotation-y={Math.PI}
                />
              );
            case 6:
              return (
                <Woman3
                  key={`woman3-${index}`}
                  position={[renderPositionArr[index], 0, 3.4]}
                  scale={0.45}
                  rotation-y={Math.PI}
                />
              );
            case 7:
              return (
                <Kids1
                  key={`kids1-${index}`}
                  position={[renderPositionArr[index], 0, 3.4]}
                  scale={0.45}
                  rotation-y={Math.PI}
                />
              );
            case 8:
              return (
                <Kids2
                  key={`kids2-${index}`}
                  position={[renderPositionArr[index], 0, 3.4]}
                  scale={0.45}
                  rotation-y={Math.PI}
                />
              );
            case 9:
              return (
                <Kids3
                  key={`kids3-${index}`}
                  position={[renderPositionArr[index], 0, 3.4]}
                  scale={0.45}
                  rotation-y={Math.PI}
                />
              );
          }
        })}
    </>
  );
};

export default SilhouetteGroup;
