import { useEffect, useMemo, useState } from 'react';
import { successToast } from '../../utils/toast';
import { publicRequest } from '../../requestMethods';
import {
  validateEmail,
  validateInquiryMessage,
  validateInquiryName,
} from '../../utils/validation';
import { useDispatch, useSelector } from 'react-redux';
import LoadingSpinner from '../LoadingSpinner';
import { loadEnd, loadStart } from '../../redux/loadRedux';
import { Trans, useTranslation } from 'react-i18next';

const InquiryItemModal = ({ user, item, setInquiryItem }) => {
  const { t, i18n } = useTranslation();
  const { isLoading } = useSelector((state) => state.isLoading);
  const dispatch = useDispatch();

  //form state
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [artworkTitle, setArtworkTitle] = useState('');
  const [message, setMessage] = useState('');

  // エラー
  const [emailError, setEmailError] = useState('');
  const [nameError, setNameError] = useState('');
  const [messageError, setMessageError] = useState('');

  const reflectEmail = (email) => {
    const result = validateEmail(email);
    result.isValid ? setEmailError('') : setEmailError(result.message);
    setEmail(email);
  };

  const reflectName = (name) => {
    const result = validateInquiryName(name);
    result.isValid ? setNameError('') : setNameError(result.message);
    setName(name);
  };

  const reflectMessage = (message) => {
    const result = validateInquiryMessage(message);
    result.isValid ? setMessageError('') : setMessageError(result.message);
    setMessage(message);
  };

  const hasError = useMemo(() => {
    // requiredを満たしているか
    const isFilled = !!email && !!name && !!message && !!item;
    const hasErrorMessage = !!emailError || !!nameError || !!messageError;
    return hasErrorMessage || !isFilled ? true : false;
  }, [email, name, item, message]);

  const resetInput = () => {
    setEmail('');
    setName('');
    setMessage('');
    setEmailError('');
    setNameError('');
    setMessageError('');
    setArtworkTitle('');
    setInquiryItem(null);
  };

  const HandleSubmit = async (e) => {
    e.preventDefault();
    dispatch(loadStart());
    try {
      await publicRequest.post('/item-inquiries', {
        userId: Number(user.id),
        itemId: Number(item.id),
        email,
        name,
        message,
      });

      successToast(t('message.success.t2'));
      document.getElementById('inquiry_item_modal').close();
      resetInput();
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(loadEnd());
    }
  };

  useEffect(() => {
    setArtworkTitle(item?.title);
  }, [item]);

  return (
    <>
      <dialog id='inquiry_item_modal' className='modal'>
        <div className='modal-box max-w-lg'>
          <h3 className='text-lg font-bold'>
            <Trans i18nKey={'inquiry-modal.inquire'} />
          </h3>
          <p className='py-6'>
            {i18n.language === 'ja'
              ? `作品に関する問い合わせを${user?.displayName}に送信する。`
              : `Send an enquiry regarding this artwork to ${user?.displayName}.`}
          </p>
          <form className=''>
            {/* Name */}
            <label className='form-control w-full'>
              <div className='label'>
                <span className='label-text font-medium'>
                  <Trans i18nKey={'inquiry-modal.name'} />
                </span>
              </div>
              <input
                type='text'
                maxLength='46'
                placeholder={t('placeholder.type-here')}
                value={name}
                onChange={(e) => reflectName(e.target.value)}
                className={`input input-bordered w-full text-sm placeholder:text-sm ${
                  nameError && 'input-error'
                }`}
              />
              <div className='label'>
                <span className='label-text-alt text-error'>{nameError}</span>
              </div>
            </label>

            {/* Email */}
            <label className='form-control w-full'>
              <div className='label'>
                <span className='label-text font-medium'>
                  <Trans i18nKey={'inquiry-modal.email'} />
                </span>
              </div>
              <input
                type='email'
                maxLength='320'
                className={`input input-bordered w-full text-sm placeholder:text-sm ${
                  emailError && 'input-error'
                }`}
                placeholder={t('placeholder.type-here')}
                value={email}
                onChange={(e) => reflectEmail(e.target.value)}
              />
              <div className='label'>
                <span className='label-text-alt'>
                  <span className='label-text-alt text-error'>
                    {emailError}
                  </span>
                </span>
              </div>
            </label>

            {/* Title */}
            <label className='form-control w-full'>
              <div className='label'>
                <span className='label-text font-medium'>
                  <Trans i18nKey={'inquiry-modal.artwork-title'} />
                </span>
              </div>
              <input
                disabled
                className='input input-bordered w-full text-sm placeholder:text-sm'
                type='text'
                placeholder={t('placeholder.type-here')}
                value={artworkTitle}
                onChange={(e) => setArtworkTitle(e.target.value)}
              />
              <div className='label'>
                <span className='label-text-alt'>
                  <span className='label-text-alt text-error'></span>
                </span>
              </div>
            </label>

            {/* Message */}
            <label className='form-control'>
              <div className='label'>
                <span className='label-text font-medium'>
                  <Trans i18nKey={'inquiry-modal.message'} />
                </span>
              </div>
              <textarea
                className={`textarea textarea-bordered text-sm placeholder:text-sm ${
                  messageError && 'textarea-error'
                }`}
                placeholder={t('placeholder.type-here')}
                maxLength='500'
                rows='10'
                value={message}
                onChange={(e) => reflectMessage(e.target.value)}
              ></textarea>
              <div className='label'>
                <span className='label-text-alt text-error'>
                  {messageError}
                </span>
              </div>
            </label>
          </form>
          <div className='modal-action'>
            <form method='dialog'>
              {/* if there is a button in form, it will close the modal */}
              <button
                className='btn !capitalize'
                onClick={() => {
                  resetInput();
                }}
              >
                <Trans i18nKey={'btn.cancel'} />
              </button>
            </form>
            <button
              type='button'
              className='btn btn-primary !capitalize'
              disabled={hasError}
              onClick={HandleSubmit}
            >
              <Trans i18nKey={'btn.send'} />
            </button>
          </div>
        </div>
        <form method='dialog' className='modal-backdrop'>
          <button onClick={resetInput}>close</button>
        </form>
        {isLoading && <LoadingSpinner />}
      </dialog>
    </>
  );
};

export default InquiryItemModal;
