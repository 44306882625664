import SubscriptionPlan from '../../components/SubscriptionPlan';
import { useState, useEffect } from 'react';
import { publicRequest } from '../../requestMethods';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import { Link, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Trans } from 'react-i18next';

const Subscription = () => {
  const [plans, setPlans] = useState(null);
  const user = useSelector((state) => state.user.currentUser);
  const [searchParams, setSearchParams] = useSearchParams();
  const isTrial = searchParams.get('free_trial'); //debug用

  const {
    //product ID
    REACT_APP_STRIPE_PREMIUM_CAS_MEMBER_PRODUCT_ID: PREMIUM_ID,
    REACT_APP_STRIPE_VIP_CAS_MEMBER_PRODUCT_ID: VIP_ID,
  } = process.env;

  const fetchPrices = async () => {
    const res = await publicRequest.get('/stripe/prices');
    setPlans(res.data);
  };

  useEffect(() => {
    fetchPrices();
  }, []);

  const handleGetStarted = async (pricesIds, isFreeTrial = false) => {
    const body = {
      priceIds: pricesIds,
      userId: user.id,
    };

    try {
      // 初期値を設定するための変数
      let foundPlan = '';
      if (user?.userPlans?.length > 0) {
        // userPlans配列の各要素をチェック
        user.userPlans.forEach((el) => {
          if (el.SubscriptionPlans.productId === PREMIUM_ID) {
            foundPlan = 'PREMIUM';
          } else if (el.SubscriptionPlans.productId === VIP_ID) {
            foundPlan = 'VIP';
          }
        });
      }

      if (isTrial) {
        const debugBody = {
          priceIds: pricesIds,
          userId: user.id,
          duration: 90,
          isPaymentRequired: false,
        };
        //debug用
        const res = await publicRequest.post(
          '/stripe/checkout/trial',
          debugBody
        );
        const checkoutUrl = res.data.url;
        window.location.href = checkoutUrl;
      }

      if (foundPlan == 'PREMIUM') {
        //すでにpremiumMemberの場合はupgrade処理
        const res = await publicRequest.post('/stripe/checkout/upgrade', body);
        const checkoutUrl = res.data.url;
        window.location.href = checkoutUrl;
      } else {
        //Freeからの加入の場合は通常のサブスクリプション加入
        const res = await publicRequest.post('/stripe/checkout', body);
        const checkoutUrl = res.data.url;
        window.location.href = checkoutUrl;
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <div className='w-full flex-auto px-4 pb-20 pt-10 md:pb-56'>
        <div className='mx-auto max-w-screen-xl'>
          <Link
            to={'/dashboard?tab=manage-subscription'}
            className='btn btn-ghost'
          >
            <ArrowLeftIcon className='h-6 w-6' />
            <Trans i18nKey={'btn.back'} />
          </Link>
          <div className='mt-6'>
            <SubscriptionPlan
              plans={plans}
              userPlans={user.userPlans}
              getStarted={handleGetStarted}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Subscription;
