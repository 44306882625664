import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';

export const successToast = (message, duration = 2000) =>
  toast.success(message, {
    position: 'bottom-center',
    duration: duration === 'Infinity' ? Infinity : duration,
  });

export const successToastWithButton = (message) =>
  toast.custom(
    (t) => (
      <div
        className='alert alert-success !bg-white shadow-lg md:w-1/2'
        style={{ display: 'flex' }}
      >
        <div className='flex items-center'>
          <CheckCircleIcon className='h-6 w-6 text-success' />
          <span>{message}</span>
        </div>
        <button onClick={() => toast.dismiss(t.id)} className='btn btn-sm '>
          Ok
        </button>
      </div>
    ),
    { position: 'bottom-center' }
  );

export const successToastWithButtonWithLink = (message, rooms) =>
  toast.custom(
    (t) => (
      <div className='alert alert-success flex flex-row gap-2 !bg-white px-2 shadow-lg md:w-1/2 md:gap-4 md:px-4'>
        <div className='flex flex-grow items-center gap-4'>
          <CheckCircleIcon className='h-8 w-8 text-success' />
          <div className='text-left md:text-center'>
            <span className='font-bold text-[13px] md:text-base'>
              {message}
            </span>
            <div className='mt-[7px] text-left text-xs'>
              <span className='mr-1'>See</span>
              {rooms.map((room) => (
                <Link
                  key={room?.id}
                  to={`/galleries/${room?.roomId}`}
                  className='mr-1 underline'
                >
                  {room?.title}
                </Link>
              ))}
            </div>
          </div>
        </div>
        <button onClick={() => toast.dismiss(t.id)} className='btn btn-sm '>
          Ok
        </button>
      </div>
    ),
    { position: 'bottom-center' }
  );

export const errorToast = (message) =>
  toast.custom(
    (t) => (
      <div
        className='alert alert-error gap-2 p-3 shadow-lg md:w-1/2 md:gap-4 md:p-4'
        style={{ display: 'flex' }}
      >
        <InformationCircleIcon className='h-6 w-6' />
        <span>{message}</span>
      </div>
    ),
    { position: 'bottom-center' }
  );

export const ErrorToastBlock = ({ message }) => {
  return (
    <div className='alert alert-error w-full'>
      <div className='flex flex-col sm:flex-row gap-4 items-center'>
        <InformationCircleIcon className='h-6 w-6' />
        <span
          className='md:text-md w-full text-sm'
          dangerouslySetInnerHTML={{ __html: message }}
        ></span>
      </div>
    </div>
  );
};
