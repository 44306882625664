import { useEffect, useState } from 'react';
import {
  ArrowTopRightOnSquareIcon,
  CheckIcon,
  PlusIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { useSelector } from 'react-redux';
import { publicRequest } from '../requestMethods';
import { Trans, useTranslation } from 'react-i18next';
import PriceCards from './PriceCards';
import { Link } from 'react-router-dom';

const SubscriptionPlan = ({
  plans,
  getStarted,
  codeError,
  applyCoupon,
  userPlans,
  disabled = false,
}) => {
  const { t, i18n } = useTranslation();
  const user = useSelector((state) => state.user.currentUser);
  const [isFreeTrial, setIsFreeTrial] = useState(false);

  const {
    //product ID
    REACT_APP_STRIPE_PREMIUM_CAS_MEMBER_PRODUCT_ID: PREMIUM_ID,
    REACT_APP_STRIPE_VIP_CAS_MEMBER_PRODUCT_ID: VIP_ID,

    REACT_APP_MAX_PUBLISHED_EXHIBITION_MEMBER: MAX_PUBLISHED_EXHIBITION_CAS1,
    REACT_APP_MAX_PUBLISHED_EXHIBITION_PREMIUM: MAX_PUBLISHED_EXHIBITION_CAS5,
    REACT_APP_MAX_EXHIBITION_MEMBER: MAX_EXHIBITION_CAS1,
    REACT_APP_MAX_EXHIBITION_PREMIUM: MAX_EXHIBITION_CAS5,
  } = process.env;

  const fetchUserSubscription = async (subscriptionId) => {
    const res = await publicRequest.get(`/stripe/${subscriptionId}`);

    if (res.data.status == 'trialing') {
      setIsFreeTrial(true);
    } else {
      setIsFreeTrial(false);
    }
  };

  // useEffect(() => {
  //   if (user?.userSubscriptionId) {
  //     fetchUserSubscription(user.userSubscriptionId);
  //   }
  // }, [user]);

  const [plan, setPlan] = useState(null);

  useEffect(() => {
    // 初期値を設定するための変数
    let foundPlan = '';
    if (user?.userPlans?.length > 0) {
      // userPlans配列の各要素をチェック
      user.userPlans.forEach((el) => {
        if (el.SubscriptionPlans.productId === PREMIUM_ID) {
          foundPlan = 'PREMIUM';
        } else if (el.SubscriptionPlans.productId === VIP_ID) {
          foundPlan = 'VIP';
        }
      });
    }
    // 設定したプラン名を反映
    if (foundPlan) {
      setPlan(foundPlan);
    } else {
      //何もサブスクしていない場合はFreeプラン
      setPlan('FREE');
    }
  }, [user]);

  return (
    <>
      <p className='mb-8 text-3xl font-extrabold'>
        <Trans i18nKey={'signup.choose-plan.ttl'} />
      </p>
      <p className='pb-5'>
        <Trans i18nKey={'signup.choose-plan.desc'} />
      </p>
      {plan != 'FREE' && (
        <>
          {i18n.language === 'ja' ? (
            <p className='pb-5 text-gray-500'>
              <strong>Moving to a lower plan:</strong>
              <br />
              You can easily downgrade your plan, but you will lose your content
              and customizations. However, with CAS, you can easily and quickly 
              build your exhibitions from scratch. A Downgrade Plan button will
              be avaiable shortly, but until then, please feel free to{' '}
              <Link to={'/contact'} target='_blank' className='underline'>
                contact us
              </Link>
              , and we will take care of it for you.
            </p>
          ) : (
            <p className='pb-5 text-gray-500'>
              <strong>Moving to a lower plan:</strong>
              <br />
              You can easily downgrade your plan, but you will lose your content
              and customizations. However, with CAS, you can easily and quickly 
              build your exhibitions from scratch. A Downgrade Plan button will
              be avaiable shortly, but until then, please feel free to{' '}
              <Link to={'/contact'} target='_blank' className='underline'>
                contact us
              </Link>
              , and we will take care of it for you.
            </p>
          )}
        </>
      )}

      <div className='mx-auto mt-5'>
        <PriceCards handleSubscribe={getStarted} currentPlan={plan} />
        <div className='mx-auto'>
          <p className='mt-6 pb-5 pl-4 text-xs font-normal text-gray-600'>
            <Trans i18nKey={'price-card.warn-1'} />
            <br />
            <Trans i18nKey={'price-card.warn-2'} />
          </p>
        </div>
      </div>
    </>
  );
};

export default SubscriptionPlan;
