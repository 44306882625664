import { useState, useEffect } from 'react';
import { Trans } from 'react-i18next';
import { ChevronUpDownIcon } from '@heroicons/react/24/outline';

export const ArtworkViewsFromAllCountries = ({
  GA4ArtworkData,
  isLoading,
  isVIP,
}) => {
  const [rankedData, setRankedData] = useState([]);
  const [displayLimit, setDisplayLimit] = useState(25);
  const [showRowsOptions, setShowsRowOptions] = useState([]);
  const [sort, setSort] = useState({
    artwork: 'desc',
    country: 'desc',
    cityRegion: 'desc',
    view: 'desc',
    artworkDetails: 'desc',
    fullscreen: 'desc',
    uniqueUser: 'desc',
  });

  useEffect(() => {
    if (GA4ArtworkData) {
      //console.log('Artwork Countries: ', GA4ArtworkData);
      // 表示データを設定
      const flatData = [];
      GA4ArtworkData.forEach((item) => {
        item.artworks.forEach((artwork) => {
          const fullScreenEvent = artwork.events.find(
            (item) => item.name.toLowerCase() === 'enter_fullscreen'
          );
          const openModalEvent = artwork.events.find(
            (item) => item.name.toLowerCase() === 'open_modal'
          );
          if (!fullScreenEvent || !openModalEvent) {
            return;
          }
          // 必要に応じてフィルタリング
          if (artwork.totalViews > 0 || artwork.totalUsers > 0) {
            flatData.push({
              country: item.country,
              artworkTitle: artwork.title,
              totalViews: artwork.totalViews,
              fullScreenEventCount: fullScreenEvent.views,
              openModalEventCount: openModalEvent.views,
              totalUsers: artwork.totalUsers,
            });
          }
        });
      });
      // ビュー数でソート（降順）
      const sortedData = flatData.sort((a, b) => b.totalViews - a.totalViews);
      // ランクを割り当て
      const ranked = sortedData.map((item, index) => ({
        rank: index + 1,
        ...item,
      }));
      setRankedData(ranked);
      makeShowRowsOptions(ranked);
    }
  }, [GA4ArtworkData]);

  // 表示数オプションを作成
  const makeShowRowsOptions = (data) => {
    const options = [
      {
        label: '25',
        value: '25',
      },
    ];
    if (25 < data.length) {
      options.push({
        label: '50',
        value: '50',
      });
    }
    if (100 < data.length) {
      options.push({
        label: '100',
        value: '100',
      });
    }
    options.push({
      label: 'All',
      value: data.length,
    });
    setShowsRowOptions(options);
  };

  // 表示数変更時にソートをリセット
  const changeDisplayLimit = (limit) => {
    setDisplayLimit(limit);
  };

  // Artworkでソート
  const sortByArtwork = () => {
    const copy = [...rankedData.slice(0, displayLimit)];
    copy.sort((a, b) => {
      const artworkComparison =
        sort.artwork === 'asc'
          ? a.artworkTitle.localeCompare(b.artworkTitle)
          : b.artworkTitle.localeCompare(a.artworkTitle);
      return artworkComparison;
    });
    setRankedData(copy);
    setSort((prev) => ({
      ...prev,
      artwork: prev.artwork === 'asc' ? 'desc' : 'asc',
    }));
  };

  // Countryでソート
  const sortByCountry = () => {
    const copy = [...rankedData.slice(0, displayLimit)];
    copy.sort((a, b) => {
      const countryComparison =
        sort.country === 'asc'
          ? a.country.localeCompare(b.country)
          : b.country.localeCompare(a.country);
      return countryComparison;
    });
    setRankedData(copy);
    setSort((prev) => ({
      ...prev,
      country: prev.country === 'asc' ? 'desc' : 'asc',
    }));
  };

  // Artwork Detailsでソート
  const sortByArtworkDetails = () => {
    const copy = [...rankedData.slice(0, displayLimit)];
    copy.sort((a, b) => {
      const detailsComparison =
        sort.artworkDetails === 'asc'
          ? b.openModalEventCount - a.openModalEventCount
          : a.openModalEventCount - b.openModalEventCount;
      // artworkDetailsが同じ場合、rankで昇順ソート
      if (detailsComparison === 0) {
        return a.rank - b.rank;
      }
      return detailsComparison;
    });
    setRankedData(copy);
    setSort((prev) => ({
      ...prev,
      artworkDetails: prev.artworkDetails === 'asc' ? 'desc' : 'asc',
    }));
  };

  // Fullscreenでソート
  const sortByFullscreen = () => {
    const copy = [...rankedData.slice(0, displayLimit)];
    copy.sort((a, b) => {
      const fullscreenComparison =
        sort.fullscreen === 'asc'
          ? b.fullScreenEventCount - a.fullScreenEventCount
          : a.fullScreenEventCount - b.fullScreenEventCount;
      // fullscreenが同じ場合、rankで昇順ソート
      if (fullscreenComparison === 0) {
        return a.rank - b.rank;
      }
      return fullscreenComparison;
    });
    setRankedData(copy);
    setSort((prev) => ({
      ...prev,
      fullscreen: prev.fullscreen === 'asc' ? 'desc' : 'asc',
    }));
  };

  // Unique Usersでソート
  const sortByUniqueUsers = () => {
    const copy = [...rankedData.slice(0, displayLimit)];
    copy.sort((a, b) => {
      const uuComparison =
        sort.uniqueUser === 'asc'
          ? b.totalUsers - a.totalUsers
          : a.totalUsers - b.totalUsers;
      // uniqueUsersが同じ場合、rankで昇順ソート
      if (uuComparison === 0) {
        return a.rank - b.rank;
      }
      return uuComparison;
    });
    setRankedData(copy);
    setSort((prev) => ({
      ...prev,
      uniqueUser: prev.uniqueUser === 'asc' ? 'desc' : 'asc',
    }));
  };

  return (
    <>
      <div className='mt-6 flex items-center gap-4'>
        <span className='label-text'>Show Rows:</span>
        {0 < showRowsOptions.length && (
          <select
            className='select select-bordered select-sm w-full max-w-32'
            onChange={(e) => changeDisplayLimit(Number(e.target.value))}
          >
            {showRowsOptions.map((item) => {
              return (
                <option
                  value={item.value}
                  key={`ArtworkViewsFromAllCountries-${item.value}`}
                >
                  {item.label}
                </option>
              );
            })}
          </select>
        )}
      </div>

      <div className='mt-6 overflow-x-auto'>
        <table className='table'>
          {/* head */}
          <thead>
            <tr>
              <th>
                <Trans i18nKey={'analytics.rank'} />
              </th>
              <th className='min-w-[180px]'>
                <div className='flex gap-[0.625rem]'>
                  <Trans i18nKey={'analytics.artwork'} />
                  <ChevronUpDownIcon
                    className='h-4 w-4 shrink-0 cursor-pointer'
                    onClick={sortByArtwork}
                  />
                </div>
              </th>
              <th>
                <div className='flex gap-[0.625rem]'>
                  <Trans i18nKey={'analytics.country'} />
                  <ChevronUpDownIcon
                    className='h-4 w-4 shrink-0 cursor-pointer'
                    onClick={sortByCountry}
                  />
                </div>
              </th>
              <th>
                <div className='flex gap-[0.625rem]'>
                  <Trans i18nKey={'analytics.artwork-details'} />
                  <ChevronUpDownIcon
                    className='h-4 w-4 shrink-0 cursor-pointer'
                    onClick={sortByArtworkDetails}
                  />
                </div>
              </th>
              <th>
                <div className='flex gap-[0.625rem]'>
                  <Trans i18nKey={'analytics.fullscreen'} />
                  <ChevronUpDownIcon
                    className='h-4 w-4 shrink-0 cursor-pointer'
                    onClick={sortByFullscreen}
                  />
                </div>
              </th>
              {isVIP && (
                <th>
                  <div className='flex gap-[0.625rem]'>
                    <Trans i18nKey={'analytics.uu'} />
                    <ChevronUpDownIcon
                      className='h-4 w-4 shrink-0 cursor-pointer'
                      onClick={sortByUniqueUsers}
                    />
                  </div>
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {!isLoading ? (
              rankedData.length > 0 ? (
                rankedData.slice(0, displayLimit).map((row, index) => {
                  return (
                    <tr
                      key={`ArtworkViewsFromAllCountries-${row.country}-${row.artworkTitle}_${index}`}
                    >
                      <td>{row.rank}</td>
                      <td>{row.artworkTitle}</td>
                      <td>{row.country}</td>
                      <td>{row.openModalEventCount.toLocaleString()}</td>
                      <td>{row.fullScreenEventCount.toLocaleString()}</td>
                      {isVIP && <td>{row.totalUsers.toLocaleString()}</td>}
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={3} className='text-center'>
                    <Trans i18nKey={'analytics.no-data'} />
                  </td>
                </tr>
              )
            ) : (
              <tr>
                <td className='loading loading-spinner loading-xs text-primary'></td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};
