export const BACKGROUNDS = [
  {
    id: 1,
    title: 'Desert',
    image:
      'https://data.curatedartshow.com/backgrounds/freepik__expand__12258.png',
    thumbnail: 'https://data.curatedartshow.com/backgrounds/thumbnails/01.png',
    color: '#c0b1a7',
    positionX: 30,
    positionY: 10,
    scaleX: 140,
    scaleY: 200,
  },
  {
    id: 2,
    title: 'Urban Sprawl',
    image:
      'https://data.curatedartshow.com/backgrounds/freepik__expand__12472.png',
    thumbnail: 'https://data.curatedartshow.com/backgrounds/thumbnails/02.png',
    color: '',
    positionX: 30,
    positionY: 11,
    scaleX: 140,
    scaleY: 200,
  },
  {
    id: 3,
    title: 'Sunset City',
    image:
      'https://data.curatedartshow.com/backgrounds/freepik__expand__16166.png',
    thumbnail: 'https://data.curatedartshow.com/backgrounds/thumbnails/03.png',
    color: '#c0b1a7',
    positionX: 30,
    positionY: 10,
    scaleX: 140,
    scaleY: 200,
  },
  {
    id: 4,
    title: 'Rainbow',
    image:
      'https://data.curatedartshow.com/backgrounds/freepik__expand__19087.png',
    thumbnail: 'https://data.curatedartshow.com/backgrounds/thumbnails/04.png',
    color: '#c4d5e1',
    positionX: 30,
    positionY: 11,
    scaleX: 140,
    scaleY: 140,
  },
  {
    id: 5,
    title: 'Misty Forest',
    image:
      'https://data.curatedartshow.com/backgrounds/freepik__expand__30426.png',
    thumbnail: 'https://data.curatedartshow.com/backgrounds/thumbnails/05.png',
    color: '#adc7ae',
    positionX: 30,
    positionY: 10,
    scaleX: 140,
    scaleY: 200,
  },
  {
    id: 6,
    title: 'Rock Island',
    image:
      'https://data.curatedartshow.com/backgrounds/freepik__expand__39485.png',
    thumbnail: 'https://data.curatedartshow.com/backgrounds/thumbnails/06.png',
    color: '#c4d5e1',
    positionX: 30,
    positionY: 10,
    scaleX: 140,
    scaleY: 290,
  },
  {
    id: 7,
    title: 'Ocean Bay',
    image:
      'https://data.curatedartshow.com/backgrounds/freepik__expand__40900.png',
    thumbnail: 'https://data.curatedartshow.com/backgrounds/thumbnails/07.png',
    color: '#d2d2d2',
    positionX: 30,
    positionY: 10,
    scaleX: 140,
    scaleY: 200,
  },
  {
    id: 8,
    title: 'Night Skyline',
    image:
      'https://data.curatedartshow.com/backgrounds/freepik__expand__47906.png',
    thumbnail: 'https://data.curatedartshow.com/backgrounds/thumbnails/08.png',
    color: '#a3a3a3',
    positionX: 30,
    positionY: 10,
    scaleX: 140,
    scaleY: 140,
  },
  {
    id: 9,
    title: 'Rolling Plains',
    image:
      'https://data.curatedartshow.com/backgrounds/freepik__expand__47938.png',
    thumbnail: 'https://data.curatedartshow.com/backgrounds/thumbnails/09.png',
    color: '#dbf2f4',
    positionX: 30,
    positionY: 10.5,
    scaleX: 140,
    scaleY: 180,
  },
  {
    id: 10,
    title: 'Craggy Rock',
    image:
      'https://data.curatedartshow.com/backgrounds/freepik__expand__50539.png',
    thumbnail: 'https://data.curatedartshow.com/backgrounds/thumbnails/10.png',
    color: '#d7d7d7',
    positionX: 30,
    positionY: 10,
    scaleX: 140,
    scaleY: 180,
  },
  {
    id: 11,
    title: 'Heavy Machinery',
    image:
      'https://data.curatedartshow.com/backgrounds/freepik__expand__67757.png',
    thumbnail: 'https://data.curatedartshow.com/backgrounds/thumbnails/11.png',
    color: '#a4a4a4',
    positionX: 30,
    positionY: 9,
    scaleX: 140,
    scaleY: 220,
  },
  {
    id: 12,
    title: 'Forest Sunrise',
    image:
      'https://data.curatedartshow.com/backgrounds/freepik__expand__74484.png',
    thumbnail: 'https://data.curatedartshow.com/backgrounds/thumbnails/12.png',
    color: '#d5efe8',
    positionX: 30,
    positionY: 10,
    scaleX: 140,
    scaleY: 200,
  },
  {
    id: 13,
    title: 'Dark Street',
    image:
      'https://data.curatedartshow.com/backgrounds/freepik__expand__77504.png',
    thumbnail: 'https://data.curatedartshow.com/backgrounds/thumbnails/13.png',
    color: '#b4b4b4',
    positionX: 30,
    positionY: 9,
    scaleX: 140,
    scaleY: 200,
  },
  {
    id: 14,
    title: 'Neon City',
    image:
      'https://data.curatedartshow.com/backgrounds/freepik__expand__80207.png',
    thumbnail: 'https://data.curatedartshow.com/backgrounds/thumbnails/14.png',
    color: '#b194be',
    positionX: 30,
    positionY: 9,
    scaleX: 140,
    scaleY: 180,
  },
  {
    id: 15,
    title: 'Daytime Skyline',
    image:
      'https://data.curatedartshow.com/backgrounds/freepik__expand__82082.png',
    thumbnail: 'https://data.curatedartshow.com/backgrounds/thumbnails/15.png',
    color: '#f6f1dd',
    positionX: 30,
    positionY: 9.2,
    scaleX: 140,
    scaleY: 140,
  },
  {
    id: 16,
    title: 'Ruins',
    image:
      'https://data.curatedartshow.com/backgrounds/freepik__expand__11524.png',
    thumbnail: 'https://data.curatedartshow.com/backgrounds/thumbnails/16.png',
    color: '#b4b4b4',
    positionX: 30,
    positionY: 12.5,
    scaleX: 140,
    scaleY: 140,
  },
];
