import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';

export const useScrollToAnchor = ({ smooth }) => {
  const location = useLocation();

  const scrollToAnchor = useCallback(() => {
    const hash = location.hash.slice(1);
    const target = document.getElementById(hash);

    if (target) {
      setTimeout(() => {
        target.scrollIntoView({
          behavior: smooth ? 'smooth' : 'auto',
          block: 'start',
        });
      }, 500);
    }
  }, [location, smooth]);

  return { scrollToAnchor };
};
