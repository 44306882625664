import { useGLTF } from '@react-three/drei';
import { Suspense, useLayoutEffect, useState } from 'react';

const PictureFrame47 = ({ color, ...props }) => {
  const { scene, materials } = useGLTF(
    'https://data.curatedartshow.com/models/frames/jenaframe1.glb'
  );

  const [geometry, setGeometry] = useState();

  if (!geometry) {
    const cloneScene = scene.clone(true);
    setGeometry(cloneScene);
  }

  useLayoutEffect(() => {
    // console.log(materials);
    // materials['Basalt Rough_material'].color.set('#ebeaea');
    materials['Basalt Rough_material'].metalness = 0.5;
    materials['Basalt Rough_material'].roughness = 1;
    // materials['Rough Wood_material'].color.set('#d8d595');
    materials['Rough Wood_material'].metalness = 0.3;
    materials['Rough Wood_material'].roughness = 0.7;
    // materials['cloudy_plastic_material'].color.set('#7c87cd');
    materials['cloudy_plastic_material'].metalness = 0.1;
    materials['cloudy_plastic_material'].roughness = 1;
    // materials['default_material191'].color.set('#7c87cd');
    // materials['default_material191'].metalness = 1;
    // materials['default_material191'].roughness = 0;

    geometry.traverse((obj) => {
      if (obj.isMesh) obj.castShadow = obj.receiveShadow = true;
    });
  }, []);

  return (
    <Suspense fallback={null}>
      <primitive object={geometry} {...props} />
    </Suspense>
  );
};

export default PictureFrame47;
