import {
  Outlet,
  Navigate,
  Link,
  useNavigate,
  useLocation,
} from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useDispatch } from 'react-redux';
import { authRequest } from '../../requestMethods';
import { logout } from '../../redux/userRedux';

const AdminLayout = ({ user }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const path = useLocation().pathname;
  if (!user?.isAdmin) {
    return <Navigate to='/admin/login' />;
  }

  const logoutHandler = async (e) => {
    e.preventDefault();
    try {
      await authRequest.post('/auth/logout');
      dispatch(logout());
      console.log('logout');
      navigate('/');
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Helmet>
        <meta name='robots' content='noindex, nofollow' />
      </Helmet>
      <div id='layout'>
        <div className='w-full flex-auto'>
          <div className='drawer h-full w-full md:drawer-open'>
            <input id='drawer' type='checkbox' className='drawer-toggle' />
            <div className='drawer-content relative h-full w-full px-4 py-8 md:px-10 md:py-10'>
              <Outlet />
            </div>
            {path !== '/admin/login' && (
              <div className='drawer-side'>
                <label
                  htmlFor='drawer'
                  aria-label='close sidebar'
                  className='drawer-overlay'
                ></label>
                <aside className='min-h-full w-80 bg-base-200 p-4 text-base-content'>
                  <Link to={'/'} className='flex items-end gap-2'>
                    <img
                      src='/assets/img/cas-logo.png'
                      alt='site-logo'
                      className='h-10 w-10'
                    />
                    <span className='cursor-pointer !font-outfit text-2xl font-semibold'>
                      Curated Art Show
                    </span>
                  </Link>
                  <ul className='menu mt-6 rounded-box bg-base-200'>
                    <li>
                      <Link
                        to='/admin/users'
                        className={`${path === '/admin/users' ? 'active' : ''}`}
                      >
                        Users
                      </Link>
                    </li>
                    <li>
                      <Link
                        to='/admin/artworks'
                        className={`${
                          path === '/admin/artworks' ? 'active' : ''
                        }`}
                      >
                        Artworks
                      </Link>
                      <ul>
                        <li>
                          <Link
                            to='/admin/deleted-artworks'
                            className={`${
                              path === '/admin/deleted-artworks' ? 'active' : ''
                            }`}
                          >
                            Deleted Artworks
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Link
                        to='/admin/exhibitions'
                        className={`${
                          path === '/admin/exhibitions' ? 'active' : ''
                        }`}
                      >
                        Exhibitions
                      </Link>
                    </li>
                    <li className='bg-base-content-20'></li>

                    <li>
                      <h2 className='menu-title'>Textures</h2>
                      <ul>
                        <li>
                          <Link
                            to='/admin/textures/ceiling'
                            className={`${
                              path === '/admin/textures/ceiling' ? 'active' : ''
                            }`}
                          >
                            Ceiling
                          </Link>
                        </li>

                        <li>
                          <Link
                            to='/admin/textures/wall'
                            className={`${
                              path === '/admin/textures/wall' ? 'active' : ''
                            }`}
                          >
                            Wall
                          </Link>
                        </li>
                        <li>
                          <Link
                            to='/admin/textures/floor'
                            className={`${
                              path === '/admin/textures/floor' ? 'active' : ''
                            }`}
                          >
                            Floor
                          </Link>
                        </li>
                      </ul>
                    </li>

                    <li className='bg-base-content-20'></li>
                    <li>
                      <Link
                        to='/admin/coupon-codes'
                        className={`${
                          path.includes('coupon-code') ? 'active' : ''
                        }`}
                      >
                        Coupon Codes
                      </Link>
                    </li>
                    <li className='bg-base-content-20'></li>
                    <li>
                      <Link
                        to='/admin/artwork-reports'
                        className={`${
                          path.includes('artwork-reports') ? 'active' : ''
                        }`}
                      >
                        Reports
                      </Link>
                    </li>
                    <li className='bg-base-content-20'></li>
                    <li>
                      <Link
                        to='/admin/faqs'
                        className={`${path.includes('faqs') ? 'active' : ''}`}
                      >
                        FAQS
                      </Link>
                    </li>
                    <li className='bg-base-content-20'></li>
                    {/* <li>
                    <a
                      className={`${path === '/admin/reports' ? 'active' : ''}`}
                    >
                      Reports
                    </a>
                  </li>
                  <li className='bg-base-content-20'></li> */}
                    <li>
                      <span onClick={logoutHandler} className='font-bold'>
                        Logout
                      </span>
                    </li>
                  </ul>
                </aside>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminLayout;
