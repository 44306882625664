import { XMarkIcon } from '@heroicons/react/24/outline';
import React, { useEffect, useState, useCallback, useRef } from 'react';
import QuickPinchZoom, { make3dTransformValue } from 'react-quick-pinch-zoom';
import { Trans } from 'react-i18next';

const FullScreenImg = ({ onClose, imgUrl, delay = false }) => {
  const [imageSize, setImageSize] = useState({ width: 0, height: 0 });
  const [isZoomed, setIsZoomed] = useState(false);
  const [pointerEventsEnabled, setPointerEventsEnabled] = useState(false);

  const handleImageLoad = (event) => {
    const { naturalWidth, naturalHeight } = event.target;
    setImageSize({ width: naturalWidth, height: naturalHeight });
  };
  const isLandscape = imageSize.width >= imageSize.height;

  useEffect(() => {
    const handleTouchMove = (e) => {
      if (e.scale !== 1) {
        e.preventDefault();
      }
    };
    document.addEventListener('touchmove', handleTouchMove, { passive: false });
    return () => {
      document.removeEventListener('touchmove', handleTouchMove);
    };
  }, []);

  const imgRef = useRef();
  const zoomRef = useRef();
  const onUpdate = useCallback(({ x, y, scale }) => {
    const { current: img } = imgRef;

    if (Math.ceil(scale) == 1) {
      setIsZoomed(false);
    } else {
      setIsZoomed(true);
    }

    if (img) {
      const value = make3dTransformValue({ x, y, scale });
      img.style.setProperty('transform', value);
    }
  }, []);

  const handleClose = () => {
    onClose();
  };

  //アニメーション中はpointer eventを無効にする
  useEffect(() => {
    const timer = setTimeout(() => {
      setPointerEventsEnabled(true);
    }, 2500);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div
      className={`fixed left-0 top-0 z-[1002] flex h-screen w-screen animate-fade flex-col items-center justify-center bg-black  animate-normal animate-duration-[2500ms] animate-ease-in ${
        delay ? 'animate-delay-[2500ms]' : ''
      }`}
      style={{
        touchAction: 'none',
        pointerEvents: pointerEventsEnabled ? 'auto' : 'none',
      }} // touch-actionプロパティを追加
    >
      {isZoomed ? (
        <p
          className={`fixed left-5 top-5 z-[1004] items-center justify-center gap-[0.625rem] rounded-full border border-[rgba(155,155,155,0.4)] bg-[rgba(84,87,116,0.4)] px-4 py-1 text-xs font-medium text-white/80 sm:text-sm`}
        >
          <span className='hidden md:block'>
            <Trans i18nKey={'gallery.fullscreen.zoom-out-click'} />
            <br />
            <Trans i18nKey={'gallery.fullscreen.exit-click'} />
          </span>
          <span className='block md:hidden'>
            <Trans i18nKey={'gallery.fullscreen.zoom-out-tap'} />
            <br />
            <Trans i18nKey={'gallery.fullscreen.exit-tap'} />
          </span>
        </p>
      ) : (
        <p
          className={`fixed left-5 top-5 z-[1004] items-center justify-center gap-[0.625rem] rounded-full border border-[rgba(155,155,155,0.4)] bg-[rgba(84,87,116,0.4)] px-4 py-1 text-xs font-medium text-white/80 sm:text-sm`}
        >
          <span className='hidden md:block'>
            <Trans i18nKey={'gallery.fullscreen.zoom-click'} />
            <br />
            <Trans i18nKey={'gallery.fullscreen.exit-click'} />
          </span>
          <span className='block md:hidden'>
            <Trans i18nKey={'gallery.fullscreen.zoom-tap'} />
            <br />
            <Trans i18nKey={'gallery.fullscreen.exit-tap'} />
          </span>
        </p>
      )}

      <div
        className='absolute inset-0 z-[1003] h-full w-full'
        onClick={handleClose}
      ></div>
      <XMarkIcon
        className='fixed right-5 top-5 z-[1004] h-8 w-8 cursor-pointer self-end text-white md:!right-10 md:!top-10 md:h-12 md:w-12'
        onClick={(e) => {
          e.stopPropagation(); // アイコンのクリックイベントを親に伝播させない
          onClose();
        }}
      />

      <QuickPinchZoom
        ref={zoomRef}
        centerContained
        onUpdate={onUpdate}
        doubleTapZoomOutOnMaxScale={true}
        maxZoom={2}
      >
        <img
          ref={imgRef}
          src={imgUrl + '?q=60'}
          className={`relative z-[1003] cursor-pointer object-contain ${
            isLandscape ? 'w-screen' : 'md:!h-screen'
          }`}
          onLoad={handleImageLoad}
          alt=''
        />
      </QuickPinchZoom>
    </div>
  );
};

export default FullScreenImg;
