import { XMarkIcon } from '@heroicons/react/24/outline';
import { useTranslation, Trans } from 'react-i18next';
import { HexColorInput, HexColorPicker } from 'react-colorful';
import FramePreview from '../FramePreview';

export const EditPictureFrameModal = ({
  pictureFrames,
  customPictureFrames,
  frame,
  setFrame,
  frameType,
  frameColor,
  setFrameColor,
  matColor,
  setMatColor,
  imageUrl,
  isOpen,
  onClose,
}) => {
  const { i18n } = useTranslation();
  return (
    <dialog
      id='edit_picture_frame_modal'
      className={`modal ${isOpen ? '' : '-z-10'}`}
    >
      <div className='modal-box h-full max-h-none w-full max-w-none rounded-none p-0'>
        <div className='sticky top-0 z-10 bg-white py-6'>
          <form method='dialog'>
            {/* if there is a button in form, it will close the modal */}
            <button
              className='btn btn-circle btn-ghost btn-sm absolute right-5 top-5'
              onClick={onClose}
            >
              <XMarkIcon className='h-6 w-6' />
            </button>
          </form>
          <div className='text-center font-bold'>
            <Trans i18nKey={'artwork.edit-frame'} />
          </div>
          <div className='mt-4 flex w-full flex-col items-center gap-4 bg-zinc-100 p-4'>
            <span className='font-xs text-center font-bold text-gray-500'>
              {imageUrl ? (
                <Trans i18nKey={'artwork.frame-preview'} />
              ) : (
                <Trans i18nKey={'artwork.frame-preview-artwork'} />
              )}
            </span>
            {imageUrl ? (
              <div className='h-[12.5rem] w-[12.5rem] bg-[#D9D9D9]'>
                <FramePreview
                  frame={frame}
                  itemUrl={imageUrl}
                  frameColor={frameColor}
                  matColor={matColor}
                  removeQuery
                />
              </div>
            ) : (
              <div className='h-[12.5rem] w-[12.5rem] bg-[#D9D9D9]'></div>
            )}
          </div>
        </div>

        <div className='flex flex-col gap-6 px-4 py-6'>
          <div className='flex w-full flex-col md:max-w-[50%]'>
            <div className='form-control'>
              <label className='label cursor-pointer justify-start gap-2 py-0'>
                <input
                  type='radio'
                  className='radio'
                  // value={item.kind}
                  onChange={(e) => setFrame('')}
                  name='frame_type'
                  checked={frameType === 'PRESET'}
                />
                <span className='label-text'>
                  <Trans i18nKey={'label.select-from-presets'} />
                </span>
              </label>
            </div>
            <div
              className={`my-5 w-full pl-8 ${
                frameType == 'PRESET'
                  ? 'pointer-events-auto opacity-100'
                  : 'pointer-events-none opacity-20'
              }`}
            >
              <div className='grid max-h-[30rem] grid-cols-3 gap-5 overflow-y-scroll bg-zinc-50 p-4'>
                {pictureFrames.map((item, index) => (
                  <div
                    key={`frame-${index}`}
                    className={`relative cursor-pointer`}
                    onClick={() => setFrame(item.value)}
                  >
                    <img
                      src={item.imgUrl + '?q=60'}
                      alt={item.title}
                      className={`h-auto w-full rounded ${
                        item.value == frame && 'border-[1.5px] border-black'
                      }`}
                    />

                    {item.value === '' && i18n.language === 'ja' ? (
                      <p className='mt-2 text-left text-xs'>
                        展示会のデフォルトを使用
                      </p>
                    ) : item.value === 'unframed' && i18n.language === 'ja' ? (
                      <p className='mt-2 text-left text-xs'>額縁なし</p>
                    ) : (
                      <p className='mt-2 text-left text-xs'>{item.title}</p>
                    )}

                    {item.value == frame && (
                      <img
                        src='/assets/img/check_icon.png'
                        className='absolute right-3 top-3 h-[0.875rem] w-[0.875rem]'
                        alt='frame'
                      />
                    )}
                  </div>
                ))}
              </div>
            </div>

            <div className='form-control'>
              <label className='label my-0 cursor-pointer justify-start gap-2 py-0'>
                <input
                  type='radio'
                  className='radio'
                  // value={item.kind}
                  onChange={(e) => setFrame('custom1')}
                  name='frame_type'
                  checked={frameType === 'CUSTOM'}
                />
                <span className='label-text'>
                  <Trans i18nKey={'label.create-custom-frame'} />
                </span>
              </label>
            </div>
            <div
              className={`${
                frameType == 'CUSTOM'
                  ? 'pointer-events-auto opacity-100'
                  : 'pointer-events-none opacity-20'
              }`}
            >
              <p className={`pb-2 pl-8 pt-3 font-[600]`}>
                <Trans i18nKey={'label.select-frame-model'} />
              </p>
              <div className='pl-8 '>
                <div className='grid max-h-[29rem] grid-cols-3 gap-5 overflow-y-scroll bg-zinc-50 p-4'>
                  {customPictureFrames.map((item, index) => (
                    <div
                      key={`custom-frame-${index}`}
                      className={`relative cursor-pointer`}
                      onClick={() => setFrame(item.value)}
                    >
                      <img
                        src={item.imgUrl + '?q=60'}
                        alt={item.title}
                        className={`h-auto w-full rounded ${
                          item.value == frame && 'border-[1.5px] border-black'
                        }`}
                      />
                      <p className='mt-2 text-left text-xs'>{item.title}</p>
                      {item.value == frame && (
                        <img
                          src='/assets/img/check_icon.png'
                          className='absolute right-3 top-3 h-[0.875rem] w-[0.875rem]'
                        />
                      )}
                    </div>
                  ))}
                </div>
              </div>

              <p className='pb-2 pl-8 pt-6 font-[600]'>
                <Trans i18nKey={'label.select-frame-color'} />
              </p>
              <div className='mt-2 pl-8'>
                <HexColorPicker color={frameColor} onChange={setFrameColor} />
                <div className='mt-2 '>
                  <HexColorInput
                    color={frameColor}
                    onChange={setFrameColor}
                    className='input input-bordered w-[200px]'
                  />
                </div>
              </div>
              <p className='pb-2 pl-8 pt-6 font-[600]'>
                <Trans i18nKey={'label.select-matte-color'} />
              </p>
              <div className='mt-2 pl-8'>
                <HexColorPicker color={matColor} onChange={setMatColor} />
                <div className='mt-2 '>
                  <HexColorInput
                    color={matColor}
                    onChange={setMatColor}
                    className='input input-bordered w-[200px]'
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </dialog>
  );
};
