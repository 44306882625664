import { Environment } from '@react-three/drei';

import { EffectComposer } from '@react-three/postprocessing';
import React, { Suspense, useContext } from 'react';
import Loader from '../Loader';
import Items from '../Items';

import MovingSpotLight from '../lights/MovingSpotLight';
import ObjectLight3 from '../models/objectLights/ObjectLight3';
import { ExhibitionControllerContext } from '../../../context/ExhibitionControllerContext';
import { ExhibitionContext } from '../../../context/ExhibitionContext';
import Classic2Floor from '../floors/Classic2Floor';
import Classic2Ceiling from '../ceilings/Classic2Ceiling';
import Classic2Wall from '../walls/Classic2Wall';
import Sofa from '../models/Sofa';
import Pole from '../models/Pole';
import ClassicPillar2 from '../models/ClassicPillar2';
import MarbleObject from '../models/MarbleObject';
import SilhouetteGroup from '../SilhouetteGroup';

const Marble = () => {
  const { currentItemId, mode } = useContext(ExhibitionControllerContext);
  const { exhibitionItems, exhibitionSettings } = useContext(ExhibitionContext);

  const directLightIntensity = 0.4;
  const ambientLightIntensity = 0.2;

  return (
    <>
      {/* <OrbitControls /> */}
      {/* <BakeShadows /> */}
      <EffectComposer></EffectComposer>
      <Suspense fallback={<Loader />}>
        {/* lights */}
        <color attach='background' args={[`#191920`]} />

        <directionalLight intensity={directLightIntensity} castShadow />

        <ambientLight intensity={ambientLightIntensity} />

        {mode == 'HIGH' && currentItemId != null && (
          <MovingSpotLight
            currentItemId={currentItemId}
            opacity={0.08}
            anglePower={0}
            angle={0.3}
          />
        )}

        {Array.from({ length: 10 }, (_, index) => (
          <ObjectLight3
            key={index}
            position={[-1.5 + index * 8, 4.4, 0.12]}
            scale={1}
          />
        ))}

        <Sofa
          scale={1.2}
          rotation={[0, -Math.PI / 2, 0]}
          position={[-1, -0.05, 1.4]}
        />
        <Sofa
          scale={1.2}
          rotation={[0, -Math.PI / 2, 0]}
          position={[15, -0.05, 1.4]}
        />
        <Sofa
          scale={1.2}
          rotation={[0, -Math.PI / 2, 0]}
          position={[30, -0.05, 1.4]}
        />
        {/* <ClassicPillar1 scale={[0.7, 1, 0.7]} position={[1, -0.6, 0]} /> */}

        {Array.from({ length: 12 }, (_, index) => (
          <Pole
            key={`pole-${index}`}
            scale={1}
            position={[-2.5 + 5 * index, -0.6, 0]}
          />
        ))}

        {Array.from({ length: 10 }, (_, index) => (
          <ClassicPillar2
            key={`pillar-${index}`}
            scale={[0.9, 0.93, 0.9]}
            position={[-3 + 6 * index, -0.6, 0.5]}
          />
        ))}

        {/* <WoodBench scale={1.2} position={[0, -0.7, 2.5]} /> */}
        <group position={[0.2, -0.5, 0]}>
          <Items items={exhibitionItems} />
          <SilhouetteGroup />

          {/* ceiling */}
          <Classic2Ceiling />

          {/* wall */}
          <Classic2Wall />
          <MarbleObject />

          {/* floor */}
          <Classic2Floor />
        </group>

        <Environment
          files={'https://data.curatedartshow.com/hdri/potsdamer_platz_1k.hdr'}
        />
      </Suspense>
    </>
  );
};

export default Marble;
