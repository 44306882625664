import { useGLTF } from '@react-three/drei';
import { Suspense, useLayoutEffect, useState } from 'react';

const PictureFrame55 = ({ color, ...props }) => {
  const { scene, materials } = useGLTF(
    'https://data.curatedartshow.com/models/frames/slimframe7.glb'
  );

  const [geometry, setGeometry] = useState();

  if (!geometry) {
    const cloneScene = scene.clone(true);
    setGeometry(cloneScene);
  }

  useLayoutEffect(() => {
    // console.log(materials);
    // materials['Midnight Plastic_material2'].color.set('#686868');
    // materials['Midnight Plastic_material2'].metalness = 0;
    // materials['Midnight Plastic_material2'].roughness = 2;
    // materials['Rough Paint_material'].color.set('#a77200');
    materials['Rough Paint_material'].metalness = 1;
    materials['Rough Paint_material'].roughness = 3;
    // materials['Black Paint Brushed_material'].color.set('#c9bca0');
    // materials['Black Paint Brushed_material'].color.set('#bcbcbc');
    materials['Black Paint Brushed_material'].metalness = 1;
    materials['Black Paint Brushed_material'].roughness = 2;
    // materials['default_material107'].color.set('#7c87cd');
    // materials['default_material107'].metalness = 1;
    // materials['default_material107'].roughness = 0;
    // materials['Red Spotted Paint_material'].color.set('#7c87cd');
    // materials['Red Spotted Paint_material'].metalness = 0.4;
    // materials['Red Spotted Paint_material'].roughness = 2;

    geometry.traverse((obj) => {
      if (obj.isMesh) obj.castShadow = obj.receiveShadow = true;
    });
  }, []);

  return (
    <Suspense fallback={null}>
      <primitive object={geometry} {...props} />
    </Suspense>
  );
};

export default PictureFrame55;
