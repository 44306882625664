import { BakeShadows, Environment } from '@react-three/drei';

import { Bloom, EffectComposer } from '@react-three/postprocessing';
import React, { Suspense, useContext, useEffect, useMemo } from 'react';
import Loader from '../Loader';
import Items from '../Items';
// import FluorescentLamp from '../lights/FluorescentLamp';
// import CeilingLight from '../lights/CeilingLight';
import Man2 from '../models/Man2';
import Man3 from '../models/Man3';
import Man4 from '../models/Man4';

import Woman1 from '../models/Woman1';
import Man1 from '../models/Man1';
import Floor from '../floors/Floor';
import ClassicWall from '../walls/ClassicWall';
import ClassicFloor from '../floors/ClassicFloor';
import MovingSpotLight from '../lights/MovingSpotLight';
import Woman2 from '../models/Woman2';
import Woman3 from '../models/Woman3';
import Kids3 from '../models/Kids3';
import Kids2 from '../models/Kids2';
import Kids1 from '../models/Kids1';
import CustomCeiling from '../ceilings/CustomCeiling';
import CustomWall from '../walls/CustomWall';
import CustomFloor from '../floors/CustomFloor';
import ObjectLight1 from '../models/objectLights/ObjectLight1';
import ObjectLight2 from '../models/objectLights/ObjectLight2';
import ObjectLight3 from '../models/objectLights/ObjectLight3';
import { ExhibitionControllerContext } from '../../../context/ExhibitionControllerContext';
import { ExhibitionContext } from '../../../context/ExhibitionContext';
import MuseumWall from '../walls/MuseumWall';
import MuseumFloor from '../floors/MuseumFloor';
import Classic2Floor from '../floors/Classic2Floor';
import Classic2Ceiling from '../ceilings/Classic2Ceiling';
import Classic2Wall from '../walls/Classic2Wall';
import Sofa from '../models/Sofa';
import Pole from '../models/Pole';
import ClassicPillar1 from '../models/ClassicPillar1';
import ClassicPillar2 from '../models/ClassicPillar2';
import WoodBench from '../models/WoodBench';
import MarbleObject from '../models/MarbleObject';
import ItemOnPreview from './ItemOnPreview';
import SpotLightOnPreview from '../lights/SpotLightOnPreview';
import { useLoader, useThree } from '@react-three/fiber';
import { TextureLoader } from 'three';

const MarblePreview = ({
  theme,
  frame,
  showSilhouette,
  setSnapshot,
  items,
  ceiling,
  wall,
  floor,
  objectLight,
  frameColor,
  matColor,
}) => {
  const GOLDENRATIO = 1.61803398875;
  const { camera, gl } = useThree();

  const imageMap = useLoader(
    TextureLoader,
    items.length > 0 ? items[0]?.itemUrl : '/assets/img/sample_item_image.png'
  );

  const aspectRatio =
    imageMap.source?.data?.width / imageMap.source?.data?.height;
  const scaleValues = useMemo(() => {
    const scaleY = GOLDENRATIO;
    const scaleX = scaleY * aspectRatio;
    return { scaleX, scaleY };
  }, [aspectRatio]);
  const { scaleX, scaleY } = scaleValues;

  const directLightIntensity = 0.4;
  const ambientLightIntensity = 0.2;

  useEffect(() => {
    return () => {
      gl.dispose();
      // imageMap.dispose();
      useLoader.clear(
        TextureLoader,
        items.length > 0
          ? items[0]?.itemUrl
          : '/assets/img/sample_item_image.png'
      );
    };
  }, [gl]);

  useEffect(() => {
    camera.lookAt(0, 0.8, 1.1);
  }, [camera]);

  return (
    <>
      {/* <BakeShadows /> */}
      <EffectComposer>
        <Bloom intensity={0} />
      </EffectComposer>
      {/* lights */}
      <color attach='background' args={[`#191920`]} />

      <directionalLight intensity={directLightIntensity} />

      <ambientLight intensity={ambientLightIntensity} />

      <SpotLightOnPreview opacity={0} anglePower={0} />

      {Array.from({ length: 3 }, (_, index) => (
        <ObjectLight3
          key={index}
          position={[-1.5 + index * 8, 4.4, 0.12]}
          scale={1}
        />
      ))}

      <Sofa
        scale={1.2}
        rotation={[0, -Math.PI / 2, 0]}
        position={[-1, -0.05, 1.4]}
      />

      {Array.from({ length: 3 }, (_, index) => (
        <Pole
          key={`pole-${index}`}
          scale={1}
          position={[-2.5 + 5 * index, -0.6, 0]}
        />
      ))}

      {Array.from({ length: 3 }, (_, index) => (
        <ClassicPillar2
          key={`pillar-${index}`}
          scale={[0.9, 0.93, 0.9]}
          position={[-3 + 6 * index, -0.6, 0.5]}
        />
      ))}

      {/* <WoodBench scale={1.2} position={[0, -0.7, 2.5]} /> */}
      <group position={[0, -0.5, 0]}>
        {items.length == 1 && (
          <ItemOnPreview
            position={[-0.2, 0.85, 3]}
            item={items[0]}
            frame={frame}
            frameColor={frameColor}
            matColor={matColor}
          />
        )}
        {items.length == 2 && (
          <>
            <ItemOnPreview
              position={[-1.3, 0.85, 3]}
              item={items[0]}
              frame={frame}
              frameColor={frameColor}
              matColor={matColor}
            />

            <ItemOnPreview
              position={[1.3, 0.85, 3]}
              item={items[1]}
              frame={frame}
              frameColor={frameColor}
              matColor={matColor}
            />
          </>
        )}
        {items.length >= 3 && (
          <>
            <ItemOnPreview
              position={[-3.6, 0.85, 3]}
              item={items[0]}
              frame={frame}
              frameColor={frameColor}
              matColor={matColor}
            />

            <ItemOnPreview
              position={[-0.2, 0.85, 3]}
              item={items[1]}
              frame={frame}
              frameColor={frameColor}
              matColor={matColor}
            />

            <ItemOnPreview
              position={[3.6, 0.85, 3]}
              item={items[2]}
              frame={frame}
              frameColor={frameColor}
              matColor={matColor}
            />
          </>
        )}
        {showSilhouette && (
          <Man1 position={[-0.5, 0, 3.8]} scale={0.45} rotation-y={Math.PI} />
        )}

        {/* ceiling */}
        <Classic2Ceiling />

        {/* wall */}
        <Classic2Wall />
        <MarbleObject />

        {/* floor */}
        <Classic2Floor />
      </group>

      <Environment
        files={'https://data.curatedartshow.com/hdri/potsdamer_platz_1k.hdr'}
      />
    </>
  );
};

export default MarblePreview;
