import { publicRequest } from '../requestMethods';

export const getWalls = async () => {
  try {
    const response = await publicRequest.get(`/walls`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getWallById = async (id) => {
  try {
    const response = await publicRequest.get(`/walls/${id}`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
