import React from 'react';
import { authRequest } from '../../requestMethods';
import { useNavigate } from 'react-router-dom';
import { successToast } from '../../utils/toast';
import { Trans, useTranslation } from 'react-i18next';
import { XMarkIcon } from '@heroicons/react/24/outline';

export const InactiveItemModal = ({ itemId }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const handleDelete = async () => {
    try {
      await authRequest.patch(`/items/inactive/${itemId}`);
      navigate('/dashboard?tab=artworks');
      successToast(t('message.success.t9'));
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <dialog id='inactive_item_modal' className='modal'>
        <div className='modal-box'>
          <h3 className='text-lg font-bold'>
            <Trans i18nKey={'inactive-item-modal.ttl'} />
          </h3>
          <p className='py-4'>
            <Trans i18nKey={'inactive-item-modal.desc'} />
          </p>
          <div className='modal-action'>
            <form method='dialog'>
              {/* if there is a button in form, it will close the modal */}
              <button className='btn btn-circle btn-ghost btn-sm absolute right-2 top-2'>
                <XMarkIcon className='h-5 w-5' />
              </button>
              <button className='btn btn-outline btn-primary'>
                <Trans i18nKey={'btn.cancel'} />
              </button>
            </form>
            <button className='btn btn-primary' onClick={handleDelete}>
              <Trans i18nKey={'btn.delete'} />
            </button>
          </div>
        </div>
      </dialog>
    </>
  );
};
