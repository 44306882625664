import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useTexture } from '@react-three/drei';
import * as THREE from 'three';
import { invalidate } from '@react-three/fiber';
import { publicRequest } from '../../../requestMethods';

const Classic2Ceiling = () => {
  const textureProps = useTexture({
    // map: `https://data.curatedartshow.com/texture/ceilings/ceiling10/basecolor.jpg`,
    normalMap: `https://data.curatedartshow.com/texture/ceilings/ceiling10/normal.jpg`,
    roughnessMap: `https://data.curatedartshow.com/texture/ceilings/ceiling10/roughness.jpg`,
    aoMap: `https://data.curatedartshow.com/texture/ceilings/ceiling10/ao.jpg`,
    displacementMap: `https://data.curatedartshow.com/texture/ceilings/ceiling10/height.jpg`,
  });

  useLayoutEffect(() => {
    Object.values(textureProps).forEach((texture) => {
      texture.wrapS = THREE.RepeatWrapping;
      texture.wrapT = THREE.RepeatWrapping;
      texture.repeat.set(120, 120);
    });
  }, [textureProps]);

  const mesh = useRef();
  useEffect(() => {
    if (mesh.current) {
      mesh.current.geometry.setAttribute(
        'uv2',
        new THREE.BufferAttribute(mesh.current.geometry.attributes.uv.array, 2)
      );
    }
  }, [mesh.current]);

  return (
    <mesh
      receiveShadow
      rotation={[Math.PI / 2, 0, Math.PI]}
      ref={mesh}
      position={[0, 5, 0]}
    >
      <planeGeometry args={[200, 200]} />

      <meshStandardMaterial
        displacementScale={0.5}
        aoMapIntensity={1.2}
        roughness={0.5}
        normalScale={new THREE.Vector2(0, 0)}
        metalness={0.0}
        color={'#cccccc'}
        {...textureProps}
      />
    </mesh>
  );
};

export default Classic2Ceiling;
