import { useState, useEffect } from 'react';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQuery, useMutation } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { getExhibitionByRoomId } from '../../../services/Exhibition';
import { updateCuratorsSelection } from '../../../services/Admin';
import { errorToast, successToast } from '../../../utils/toast';

const CuratorsSelectionEdit = () => {
  const { roomId } = useParams();
  const [isCurated, setIsCurated] = useState(false);
  const [note, setNote] = useState('');
  const [noteJa, setNoteJa] = useState('');
  const { t } = useTranslation();

  const { data: exhibition, isLoading: isExhibitionLoading } = useQuery({
    queryKey: ['get-exhibition-by-room-id'],
    queryFn: () => getExhibitionByRoomId(roomId),
    retry: (failureCount, error) => {
      if (error.response?.status === 401 || error.response?.status === 403) {
        return false; // 再認証が必要な場合はリトライしない
      }
    },
    enabled: !!roomId,
  });

  const saveMutation = useMutation({
    mutationFn: async (info) => {
      return await updateCuratorsSelection(Number(exhibition.id), info);
    },
    onSuccess: () => {
      successToast(t('message.success.t12'));
    },
    onError: (err) => {
      if (err.response.data.statusCode == 400) {
        errorToast(err?.response?.data.message);
      } else {
        errorToast(
          'Something went wrong. We are aware of the problem and will try to fix it as soon as possible. Sorry for the inconvenience.'
        );
      }
    },
  });

  const handleUpdate = async () => {
    const info = {
      isCurated: isCurated,
      note: note,
      noteJa: noteJa,
    };
    saveMutation.mutate(info);
  };

  useEffect(() => {
    if (exhibition && !isExhibitionLoading) {
      setIsCurated(exhibition.isCurated);
      const note = exhibition.note ? exhibition.note : '';
      const noteJa = exhibition.noteJa ? exhibition.noteJa : '';
      setNote(note);
      setNoteJa(noteJa);
    }
  }, [exhibition]);

  return (
    <div className='w-full max-w-[500px]'>
      <h2 className='text-3xl font-extrabold'>Edit Curation Settings</h2>
      {exhibition ? (
        <div className='flex flex-col gap-6'>
          <div className='mt-8 flex gap-6'>
            <div className='w-80'>
              <img src={exhibition.previewImg} alt='' />
            </div>
            <div className='flex flex-col gap-3'>
              <div className='text-lg font-medium'>{exhibition.title}</div>
              <div>{exhibition.user.username}</div>
              <div>
                <a
                  href={`/galleries/${roomId}`}
                  target='_blank'
                  className='btn btn-sm'
                >
                  View Exhibition
                  <ArrowTopRightOnSquareIcon className='h-4 w-4' />
                </a>
              </div>
            </div>
          </div>
          <div className='flex items-center gap-4'>
            Show on Curator’s Selection
            <input
              type='checkbox'
              className='toggle'
              checked={isCurated}
              onChange={() => setIsCurated(!isCurated)}
            />
          </div>

          {/* Curator’s Note (English) */}
          <label className='form-control'>
            <div className='label'>
              <span className='label-text'>Curator’s Note (English)</span>
            </div>
            <textarea
              className='textarea textarea-bordered h-24'
              placeholder='Type here'
              value={note}
              onChange={(e) => setNote(e.target.value)}
              maxLength={150}
            ></textarea>
            <div className='label'>
              <span></span>
              <span className='label-text-alt'>{note?.length ?? 0} / 150</span>
            </div>
          </label>

          {/* Curator’s Note (日本語) */}
          <label className='form-control'>
            <div className='label'>
              <span className='label-text'>Curator’s Note (日本語)</span>
            </div>
            <textarea
              className='textarea textarea-bordered h-24'
              placeholder='Type here'
              value={noteJa}
              onChange={(e) => setNoteJa(e.target.value)}
              maxLength={150}
            ></textarea>
            <div className='label'>
              <span></span>
              <span className='label-text-alt'>
                {noteJa?.length ?? 0} / 150
              </span>
            </div>
          </label>

          <div className='flex justify-between'>
            <Link to='/admin/exhibitions' className='btn'>
              Cancel
            </Link>
            <button
              className='btn btn-primary'
              disabled={150 < note.length || 150 < noteJa.length}
              onClick={handleUpdate}
            >
              Save
            </button>
          </div>
        </div>
      ) : (
        <span className='loading loading-spinner text-primary'></span>
      )}
    </div>
  );
};

export default CuratorsSelectionEdit;
