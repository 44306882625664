import React from 'react';

const Light2 = ({ color, length = 5 }) => {
  let meshColor = [5, 2, 0.5];

  switch (color) {
    case 'RED':
      meshColor = [40, 1.2, 1.2];
      break;
    case 'BLUE':
      meshColor = [2.4, 2.4, 80];
      break;
    case 'WHITE':
      meshColor = [12, 12, 12];
      break;
    case 'YELLOW':
      meshColor = [12, 12, 2];
      break;
    case 'GREEN':
      meshColor = [1.2, 20, 1.2];
      break;
    case 'PINK':
      meshColor = [40, 1.2, 40];
      break;
    default:
      meshColor = [12, 12, 12];
      break;
  }
  return (
    <>
      {Array(2 * length)
        .fill()
        .map((_, index) => (
          <group position={[index * 5 - 15, 2.9, 1.6]} key={index}>
            <mesh
              scale={1.95}
              position={[0, 0, 0]}
              rotation={[Math.PI / 2, 0, Math.PI / 1.35]}
            >
              <ringGeometry args={[0.9, 1, 4, 1]} />
              <meshStandardMaterial
                color={meshColor}
                roughness={0.75}
                toneMapped={false}
              />
            </mesh>
            <mesh
              scale={1.5}
              position={[0, 0, 0]}
              rotation={[Math.PI / 2, 0, Math.PI / 1.35]}
            >
              <ringGeometry args={[0.7, 0.9, 4, 1]} />
              <meshStandardMaterial
                color={meshColor}
                roughness={0.75}
                toneMapped={false}
              />
            </mesh>
          </group>
        ))}
    </>
  );
};

export default Light2;
