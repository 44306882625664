import React from 'react';
import { Trans } from 'react-i18next';

const TermsPrivacy = () => {
  return (
    <>
      <div className='mx-auto max-w-3xl px-4 pb-44 pt-10 sm:px-0'>
        <h1 className='text-3xl font-extrabold'>
          <Trans i18nKey={'terms-privacy.ttl'} />
        </h1>
        <div className='mt-8 flex flex-col gap-8'>
          <div>
            <div className='text-xl font-bold'>
              <Trans i18nKey={'terms-privacy.t1'} />
            </div>
            <p>
              <Trans
                i18nKey={'terms-privacy.t2'}
                components={{
                  link1: (
                    <a
                      href='https://yuyosoft.com'
                      target='_blank'
                      rel='noopener noreferrer'
                      className='cursor-pointer underline'
                    ></a>
                  ),
                }}
              />
            </p>
          </div>
          <div>
            <div className='text-xl font-bold'>
              <Trans i18nKey={'terms-privacy.t3'} />
            </div>
            <p>
              <Trans i18nKey={'terms-privacy.t4'} />
            </p>
          </div>
          <div>
            <div className='text-xl font-bold'>
              <Trans i18nKey={'terms-privacy.t5'} />
            </div>
            <p>
              <Trans i18nKey={'terms-privacy.t6'} />
            </p>
          </div>
          <div>
            <div className='text-xl font-bold'>
              <Trans i18nKey={'terms-privacy.t7'} />
            </div>
            <div>
              <div className='mb-1 mt-8 text-lg'>
                <Trans i18nKey={'terms-privacy.t8'} />
              </div>
              <Trans i18nKey={'terms-privacy.t9'} />
            </div>
            <div>
              <div className='mb-1 mt-8 text-lg'>
                <Trans i18nKey={'terms-privacy.t10'} />
              </div>
              <Trans i18nKey={'terms-privacy.t11'} />
            </div>
            <div>
              <div className='mb-1 mt-8 text-lg'>
                <Trans i18nKey={'terms-privacy.t12'} />
              </div>
              <Trans i18nKey={'terms-privacy.t13'} />
            </div>
            <div>
              <div className='mb-1 mt-8 text-lg'>
                <Trans i18nKey={'terms-privacy.t14'} />
              </div>
              <Trans i18nKey={'terms-privacy.t15'} />
            </div>
            <div>
              <div className='mb-1 mt-8 text-lg'>
                <Trans i18nKey={'terms-privacy.t16'} />
              </div>
              <Trans i18nKey={'terms-privacy.t17'} />
            </div>
          </div>
          <div>
            <div className='text-xl font-bold'>
              <Trans i18nKey={'terms-privacy.t18'} />
            </div>
            <p>
              <Trans i18nKey={'terms-privacy.t19'} />
            </p>
          </div>
          <div>
            <div className='text-xl font-bold'>
              <Trans i18nKey={'terms-privacy.t20'} />
            </div>
            <p>
              <Trans i18nKey={'terms-privacy.t21'} />
              <br />
              <Trans i18nKey={'terms-privacy.t22'} />
            </p>
          </div>
          <div>
            <div className='text-xl font-bold'>
              <Trans i18nKey={'terms-privacy.t23'} />
            </div>
            <p>
              <Trans i18nKey={'terms-privacy.t24'} />
            </p>
          </div>
          <div>
            <div className='text-xl font-bold'>
              <Trans i18nKey={'terms-privacy.t25'} />
            </div>
            <p>
              <Trans i18nKey={'terms-privacy.t26'} />
            </p>
          </div>
          <div>
            <div className='text-xl font-bold'>
              <Trans i18nKey={'terms-privacy.t27'} />
            </div>
            <p>
              <Trans i18nKey={'terms-privacy.t28'} />
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default TermsPrivacy;
