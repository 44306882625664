import { useGLTF } from '@react-three/drei';
import { Suspense, useLayoutEffect, useState } from 'react';

const PictureFrame39 = ({ color, ...props }) => {
  const { scene, materials } = useGLTF(
    'https://data.curatedartshow.com/models/frames/midnight.glb'
  );

  const [geometry, setGeometry] = useState();

  if (!geometry) {
    const cloneScene = scene.clone(true);
    setGeometry(cloneScene);
  }

  useLayoutEffect(() => {
    // console.log(materials);
    // materials['hard_plastic_material'].color.set('#f8c1d2');
    materials['hard_plastic_material'].metalness = 1;
    materials['hard_plastic_material'].roughness = 0.5;
    // materials['cloudy_plastic_material'].color.set('#191919');
    materials['cloudy_plastic_material'].metalness = 0.2;
    materials['cloudy_plastic_material'].roughness = 0.9;
    // materials['Midnight Plastic_material'].color.set('#0f0f0f');
    materials['Midnight Plastic_material'].metalness = 0.8;
    materials['Midnight Plastic_material'].roughness = 2.5;

    geometry.traverse((obj) => {
      if (obj.isMesh) obj.castShadow = obj.receiveShadow = true;
    });
  }, []);
  return (
    <Suspense fallback={null}>
      <primitive object={geometry} {...props} />
    </Suspense>
  );
};

export default PictureFrame39;
