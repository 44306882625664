import { useEffect, useState } from 'react';
import { publicRequest } from '../requestMethods';
import { Trans } from 'react-i18next';

const SuggestedPresetsModal = ({ loadPreset }) => {
  const [selectedPreset, setSelectedPreset] = useState(null);
  const [presets, setPresets] = useState([]);

  const getPresets = async () => {
    try {
      const res = await publicRequest.get(`/presets/public`);
      setPresets(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const modal = document.getElementById('suggested_presets_modal');

    // モーダルの属性を監視
    const observer = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (
          mutation.type === 'attributes' &&
          mutation.attributeName === 'open'
        ) {
          if (modal.hasAttribute('open')) {
            getPresets();
          }
        }
      }
    });

    // 監視の開始
    observer.observe(modal, { attributes: true });

    // クリーンアップ関数で監視を停止
    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <dialog id='suggested_presets_modal' className='modal'>
      <div className='modal-box max-h-[70vh] !max-w-[38rem] !p-6'>
        <h3 className='mb-6 mt-0 text-lg font-bold'>
          <Trans i18nKey={'suggested-presets-modal.ttl'} />
        </h3>
        <p className='my-0 pb-6'>
          <Trans i18nKey={'suggested-presets-modal.desc'} />
        </p>

        <div className='max-h-[30rem] overflow-x-auto overflow-y-scroll'>
          <table className='table'>
            <tbody>
              {presets?.map((preset, index) => (
                <tr
                  key={`public-preset-${index}`}
                  className={`cursor-pointer ${
                    presets.length != index + 1 && '!border-b-2'
                  }`}
                  onClick={() => setSelectedPreset(preset)}
                >
                  <th>
                    <label>
                      <input
                        type='radio'
                        name={`preset-radio`}
                        className='radio'
                        onChange={() => setSelectedPreset(preset)}
                        id={preset?.id}
                        value={preset?.id}
                        checked={preset?.id === selectedPreset?.id}
                      />
                    </label>
                  </th>
                  <td>
                    <img
                      src={preset?.imageUrl + '?q=60'}
                      alt='Avatar Tailwind CSS Component'
                      className='h-20 w-28 object-cover'
                    />
                  </td>
                  <td className='text-sm font-bold'>{preset?.name}</td>
                  <th></th>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className='modal-action mb-0 mt-6'>
          <form method='dialog'>
            <button className='btn mr-2'>
              <Trans i18nKey={'btn.cancel'} />
            </button>
            <button
              className='btn btn-primary'
              disabled={!selectedPreset}
              onClick={() => loadPreset(selectedPreset)}
            >
              <Trans i18nKey={'btn.save'} />
            </button>
          </form>
        </div>
      </div>
    </dialog>
  );
};

export default SuggestedPresetsModal;
