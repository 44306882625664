import { useGLTF } from '@react-three/drei';
import { Suspense, useLayoutEffect, useState } from 'react';

const PictureFrame66 = ({ color, ...props }) => {
  const { scene, materials } = useGLTF(
    'https://data.curatedartshow.com/models/frames/jenaframe9.glb'
  );

  const [geometry, setGeometry] = useState();

  if (!geometry) {
    const cloneScene = scene.clone(true);
    setGeometry(cloneScene);
  }

  useLayoutEffect(() => {
    // console.log(materials);
    // materials['Rough Slate_material'].color.set('#686868');
    materials['Rough Slate_material'].metalness = 0.5;
    materials['Rough Slate_material'].roughness = 1;
    materials['Wet Blue Clay_material'].color.set('#94a29f');
    materials['Wet Blue Clay_material'].metalness = 0.6;
    materials['Wet Blue Clay_material'].roughness = 4;
    // materials['Red Clay Rough_material'].color.set('#2b2b2b');
    materials['Red Clay Rough_material'].metalness = 0.8;
    materials['Red Clay Rough_material'].roughness = 3;

    // materials['default_material107.003'].color.set('#7c87cd');
    // materials['default_material107.003'].metalness = 1;
    // materials['default_material107.003'].roughness = 0;
    // materials['Dark Green Fabric_material.001'].color.set('#7c87cd');
    materials['Dark Green Fabric_material.001'].metalness = 0.5;
    materials['Dark Green Fabric_material.001'].roughness = 0.5;

    geometry.traverse((obj) => {
      if (obj.isMesh) obj.castShadow = obj.receiveShadow = true;
    });
  }, []);

  return (
    <Suspense fallback={null}>
      <primitive object={geometry} {...props} />
    </Suspense>
  );
};

export default PictureFrame66;
