import { PhotoIcon } from '@heroicons/react/24/outline';
import React, { useState } from 'react';

const TextureImageModal = ({ saveTexture, setTextureFiles }) => {
  const [tmpTextureData, setTmpTextureData] = useState({
    colorMap: null,
    normalMap: null,
    roughnessMap: null,
    aoMap: null,
    displacementMap: null,
    metalnessMap: null,
    alphaMap: null,
  });

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setTmpTextureData((prev) => ({
      ...prev,
      [name]: URL.createObjectURL(files[0]), // or handle file uploading
    }));

    setTextureFiles((prev) => ({
      ...prev,
      [name]: files[0],
    }));
  };

  return (
    <dialog id='my_modal_1' className='modal'>
      <div className='modal-box max-w-4xl'>
        <h3 className='text-lg font-bold'>Upload Images</h3>
        <p className='text-sm text-error'>
          Only PNG and JPG files are allowed for upload.
        </p>
        <div className='flex flex-wrap gap-5'>
          <div className='flex flex-col gap-4'>
            <div>
              <p className='px-1 py-2 text-sm font-medium'>Basecolor</p>
              {tmpTextureData?.colorMap ? (
                <img
                  src={tmpTextureData.colorMap}
                  className='h-24 w-24 rounded-lg object-cover'
                />
              ) : (
                <div className='flex h-24 w-24 items-center justify-center rounded-lg border-[1.5px] border-dashed border-gray-400 p-5'>
                  <PhotoIcon className='h-10 w-10 shrink-0 text-gray-400' />
                </div>
              )}
            </div>
            <input
              type='file'
              className='file-input file-input-bordered w-full max-w-xs'
              name='colorMap'
              onChange={handleFileChange}
            />
          </div>
          <div className='flex flex-col gap-4'>
            <div>
              <p className='px-1 py-2 text-sm font-medium'>ao (optional)</p>
              {tmpTextureData?.aoMap ? (
                <img
                  src={tmpTextureData.aoMap}
                  className='h-24 w-24 rounded-lg object-cover'
                />
              ) : (
                <div className='flex h-24 w-24 items-center justify-center rounded-lg border-[1.5px] border-dashed border-gray-400 p-5'>
                  <PhotoIcon className='h-10 w-10 shrink-0 text-gray-400' />
                </div>
              )}
            </div>
            <input
              type='file'
              className='file-input file-input-bordered w-full max-w-xs'
              name='aoMap'
              onChange={handleFileChange}
            />
          </div>
          <div className='flex flex-col gap-4'>
            <div>
              <p className='px-1 py-2 text-sm font-medium'>height (optional)</p>
              {tmpTextureData?.displacementMap ? (
                <img
                  src={tmpTextureData.displacementMap}
                  className='h-24 w-24 rounded-lg object-cover'
                />
              ) : (
                <div className='flex h-24 w-24 items-center justify-center rounded-lg border-[1.5px] border-dashed border-gray-400 p-5'>
                  <PhotoIcon className='h-10 w-10 shrink-0 text-gray-400' />
                </div>
              )}
            </div>
            <input
              type='file'
              className='file-input file-input-bordered w-full max-w-xs'
              name='displacementMap'
              onChange={handleFileChange}
            />
          </div>
          <div className='flex flex-col gap-4'>
            <div>
              <p className='px-1 py-2 text-sm font-medium'>normal(optional)</p>
              {tmpTextureData?.normalMap ? (
                <img
                  src={tmpTextureData.normalMap}
                  className='h-24 w-24 rounded-lg object-cover'
                />
              ) : (
                <div className='flex h-24 w-24 items-center justify-center rounded-lg border-[1.5px] border-dashed border-gray-400 p-5'>
                  <PhotoIcon className='h-10 w-10 shrink-0 text-gray-400' />
                </div>
              )}
            </div>
            <input
              type='file'
              className='file-input file-input-bordered w-full max-w-xs'
              name='normalMap'
              onChange={handleFileChange}
            />
          </div>
          <div className='flex flex-col gap-4'>
            <div>
              <p className='px-1 py-2 text-sm font-medium'>
                roughness(optional)
              </p>
              {tmpTextureData?.roughnessMap ? (
                <img
                  src={tmpTextureData.roughnessMap}
                  className='h-24 w-24 rounded-lg object-cover'
                />
              ) : (
                <div className='flex h-24 w-24 items-center justify-center rounded-lg border-[1.5px] border-dashed border-gray-400 p-5'>
                  <PhotoIcon className='h-10 w-10 shrink-0 text-gray-400' />
                </div>
              )}
            </div>
            <input
              type='file'
              className='file-input file-input-bordered w-full max-w-xs'
              name='roughnessMap'
              onChange={handleFileChange}
            />
          </div>
          <div className='flex flex-col gap-4'>
            <div>
              <p className='px-1 py-2 text-sm font-medium'>
                metalness(optional)
              </p>
              {tmpTextureData?.metalnessMap ? (
                <img
                  src={tmpTextureData.metalnessMap}
                  className='h-24 w-24 rounded-lg object-cover'
                />
              ) : (
                <div className='flex h-24 w-24 items-center justify-center rounded-lg border-[1.5px] border-dashed border-gray-400 p-5'>
                  <PhotoIcon className='h-10 w-10 shrink-0 text-gray-400' />
                </div>
              )}
            </div>
            <input
              type='file'
              className='file-input file-input-bordered w-full max-w-xs'
              name='metalnessMap'
              onChange={handleFileChange}
            />
          </div>
          <div className='flex flex-col gap-4'>
            <div>
              <p className='px-1 py-2 text-sm font-medium'>alpha(optional)</p>
              {tmpTextureData?.alphaMap ? (
                <img
                  src={tmpTextureData.alphaMap}
                  className='h-24 w-24 rounded-lg object-cover'
                />
              ) : (
                <div className='flex h-24 w-24 items-center justify-center rounded-lg border-[1.5px] border-dashed border-gray-400 p-5'>
                  <PhotoIcon className='h-10 w-10 shrink-0 text-gray-400' />
                </div>
              )}
            </div>
            <input
              type='file'
              className='file-input file-input-bordered w-full max-w-xs'
              name='alphaMap'
              onChange={handleFileChange}
            />
          </div>
        </div>
        <div className='modal-action'>
          <form method='dialog'>
            {/* if there is a button in form, it will close the modal */}
            <button className='btn mr-4'>Close</button>
            <button
              className='btn btn-primary'
              onClick={() => saveTexture(tmpTextureData)}
            >
              Save
            </button>
          </form>
        </div>
      </div>
    </dialog>
  );
};

export default TextureImageModal;
