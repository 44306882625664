import { useGLTF } from '@react-three/drei';
import { Suspense, useLayoutEffect, useState } from 'react';

const PictureFrame26 = ({ color, ...props }) => {
  const { scene, materials } = useGLTF(
    'https://data.curatedartshow.com/models/frames/pink.glb'
  );

  const [geometry, setGeometry] = useState();

  if (!geometry) {
    const cloneScene = scene.clone(true);
    setGeometry(cloneScene);
  }

  useLayoutEffect(() => {
    // console.log(materials);
    materials['Rough Paint_material'].color.set('#dec0da');
    materials['Rough Paint_material'].metalness = 1;
    materials['Rough Paint_material'].roughness = 2.5;
    // materials['Black Paint Brushed_material.001'].color.set('#727272');
    materials['Black Paint Brushed_material.001'].metalness = 1;
    materials['Black Paint Brushed_material.001'].roughness = 2;

    geometry.traverse((obj) => {
      if (obj.isMesh) obj.castShadow = obj.receiveShadow = true;
    });
  }, []);
  return (
    <Suspense fallback={null}>
      <primitive object={geometry} {...props} />
    </Suspense>
  );
};

export default PictureFrame26;
