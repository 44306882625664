import { Clone, useGLTF } from '@react-three/drei';
import { useEffect, useLayoutEffect, useRef } from 'react';

const Man1 = ({ ...props }) => {
  const { scene, materials } = useGLTF(
    'https://data.curatedartshow.com/models/man1_non_animation.glb'
  );
  const cloneRef = useRef();

  useLayoutEffect(() => {
    // console.log(materials);
    materials['Autumn.002'].roughness = 1;
    materials['Autumn.002'].metalness = 1;
    materials['Autumn.002'].opacity = 1;
    materials['Autumn.002'].transparent = true;
    materials['Autumn.002'].color.set('#000');

    scene.traverse((obj) => {
      if (obj.isMesh) obj.castShadow = obj.receiveShadow = true;
    });
  }, [materials, scene]);

  useEffect(() => {
    return () => {
      // マテリアルの解放
      Object.values(materials).forEach((material) => {
        material.dispose();
      });
      // シーンからオブジェクトの削除
      scene.traverse((obj) => {
        if (obj.isMesh) {
          obj.geometry.dispose();
          obj.material.dispose();
          scene.remove(obj);
        }
      });
      // GLTFキャッシュのクリア
      useGLTF.clear(
        'https://data.curatedartshow.com/models/man1_non_animation.glb'
      );
    };
  }, [materials, scene]);

  return <Clone object={scene} ref={cloneRef} {...props} />;
};

export default Man1;
