import React, { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { errorToast, successToast } from '../../../utils/toast';
import { ExhibitionContext } from '../../../context/ExhibitionContext';
import {
  createDraftExhibition,
  getExhibitionByExhibitionId,
  publishExhibition,
  saveDraftExhibition,
  savePublishExhibition,
  unpublishExhibition,
} from '../../../services/Exhibition';
import { useMutation } from '@tanstack/react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { validateRoomId } from '../../../utils/validation';
import { EyeSlashIcon } from '@heroicons/react/24/outline';

const CustomHeaderDesktop = ({
  displayText,
  moveTo,
  hasReachedMaxPublish,
  hasReachedMaxCreation,
  isAgreedTerms,
  hideNext,
}) => {
  const {
    exhibitionInfo,
    exhibitionSettings,
    exhibitionItems,
    exhibitionSnapshot,
  } = useContext(ExhibitionContext);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.currentUser);
  const [isEditMode, setIsEditMode] = useState(false);
  const [exhibitionId, setExhibitionId] = useState(null);

  const location = useLocation();

  const getExhibition = async (roomId) => {
    const room = await getExhibitionByExhibitionId(roomId);
    setExhibitionId(room.id);
  };

  useEffect(() => {
    if (location.pathname.split('/')[1] == 'create-exhibition') {
      setIsEditMode(false);
    } else {
      setIsEditMode(true);
      const roomId = location.pathname.split('/')[2];
      getExhibition(roomId);
    }
  }, [location]);

  //save & publish
  const savePublishMutation = useMutation({
    mutationFn: async () => {
      const items = exhibitionItems.map((item) => item.id);

      return await savePublishExhibition(
        Number(exhibitionId),
        user.id,
        exhibitionSnapshot,
        items,
        exhibitionInfo,
        exhibitionSettings
      );
    },
    onSuccess: () => {
      navigate('/dashboard');
      successToast(t('message.success.t15'));
    },
    onError: (err) => {
      if (err.response.data.statusCode == 400) {
        errorToast(err?.response?.data.message);
      } else {
        errorToast(
          'Something went wrong. We are aware of the problem and will try to fix it as soon as possible. Sorry for the inconvenience.'
        );
      }
    },
  });

  // create&draft処理
  const createDraftMutation = useMutation({
    mutationFn: async () => {
      const items = exhibitionItems.map((item) => item.id);

      return await createDraftExhibition(
        user.id,
        exhibitionSnapshot,
        items,
        exhibitionInfo,
        exhibitionSettings
      );
    },
    onSuccess: () => {
      navigate('/dashboard?tab=drafts');
      successToast(t('message.success.t12'));
    },
    onError: (err) => {
      if (err.response.data.statusCode == 400) {
        errorToast(err?.response?.data.message);
      } else {
        errorToast(
          'Something went wrong. We are aware of the problem and will try to fix it as soon as possible. Sorry for the inconvenience.'
        );
      }
    },
  });

  // save&draft処理
  const saveDraftMutation = useMutation({
    mutationFn: async () => {
      const items = exhibitionItems.map((item) => item.id);

      return await saveDraftExhibition(
        Number(exhibitionId),
        user.id,
        exhibitionSnapshot,
        items,
        exhibitionInfo,
        exhibitionSettings
      );
    },
    onSuccess: () => {
      navigate('/dashboard?tab=drafts');
      successToast(t('message.success.t12'));
    },
    onError: (err) => {
      if (err.response.data.statusCode == 400) {
        errorToast(err?.response?.data.message);
      } else {
        errorToast(
          'Something went wrong. We are aware of the problem and will try to fix it as soon as possible. Sorry for the inconvenience.'
        );
      }
    },
  });

  const handleSavePublish = () => {
    if (savePublishMutation.isPending) {
      return; // isPendingの場合は何もしない
    }

    if (
      exhibitionInfo.title &&
      exhibitionInfo.description &&
      exhibitionInfo.roomId
    ) {
      savePublishMutation.mutate();
    } else {
      errorToast(t('message.error.t5'));
    }
  };

  const handleSaveDraft = () => {
    if (saveDraftMutation.isPending) {
      return; // isPendingの場合は何もしない
    }

    if (
      exhibitionInfo.title &&
      exhibitionInfo.description &&
      exhibitionInfo.roomId
    ) {
      saveDraftMutation.mutate();
    } else {
      errorToast(t('message.error.t5'));
    }
  };

  const handleCreateDraft = () => {
    if (createDraftMutation.isPending) {
      return; // isPendingの場合は何もしない
    }

    if (
      exhibitionInfo.title &&
      exhibitionInfo.description &&
      exhibitionInfo.roomId
    ) {
      createDraftMutation.mutate();
    } else {
      errorToast(t('message.error.t5'));
    }
  };

  return (
    <div className='navbar sticky top-0 z-50 hidden min-h-[3.5rem] w-full border-b border-[#DADADA] bg-white/60 pl-4 backdrop-blur-[20px] md:min-h-[4rem] lg:block'>
      <div className='flex justify-between'>
        <div
          className='flex cursor-pointer items-center gap-4'
          onClick={() =>
            document.getElementById('discard_exhibition_modal').showModal()
          }
        >
          <img
            src='/assets/img/cas-logo.png'
            alt='site-logo'
            className='h-10 w-10'
          />
          <span className='!font-outfit text-xl font-semibold md:text-[1.75rem]'>
            CAS
          </span>
          {displayText && (
            <p className='font-outfit text-xl font-bold text-primary md:text-2xl'>
              {displayText === 'Create Exhibition' ? (
                <Trans i18nKey={'gallery.create-ttl'} />
              ) : displayText === 'Edit Exhibition' ? (
                <Trans i18nKey={'gallery.edit-ttl'} />
              ) : (
                displayText
              )}

              {/* {displayText} */}
            </p>
          )}
        </div>
        <div className='flex gap-4'>
          <button
            className='btn btn-ghost'
            onClick={() => {
              document.getElementById('discard_exhibition_modal').showModal();
            }}
          >
            <Trans i18nKey={'btn.cancel'} />
          </button>
          {isEditMode ? (
            <>
              <button
                className='btn btn-outline btn-primary'
                onClick={handleSaveDraft}
                disabled={!isAgreedTerms}
              >
                {saveDraftMutation.isPending && (
                  <span className='loading loading-spinner'></span>
                )}
                <EyeSlashIcon className='h-6 w-6 shrink-0' />
                <Trans i18nKey={'btn.save-unpublish'} />
              </button>
              <button
                className='btn btn-outline btn-primary'
                onClick={handleSavePublish}
                disabled={!isAgreedTerms}
              >
                {savePublishMutation.isPending && (
                  <span className='loading loading-spinner'></span>
                )}
                <Trans i18nKey={'btn.save-publish'} />
              </button>
            </>
          ) : (
            <button
              className='btn btn-outline btn-primary'
              onClick={handleCreateDraft}
              disabled={!isAgreedTerms}
            >
              {createDraftMutation.isPending && (
                <span className='loading loading-spinner'></span>
              )}
              <Trans i18nKey={'btn.save-unpublished'} />
            </button>
          )}

          {!hideNext && (
            <button
              className='btn btn-primary'
              onClick={() => moveTo('BasicInfo')}
            >
              <Trans i18nKey={'btn.next'} />
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default CustomHeaderDesktop;
