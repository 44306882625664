import { Clone, useAnimations, useGLTF } from '@react-three/drei';
import { useEffect, useLayoutEffect } from 'react';

const ObjectLight2 = ({ ...props }) => {
  const { scene, materials, animations } = useGLTF(
    'https://data.curatedartshow.com/models/objectLights/objectLight2.glb'
  );

  useEffect(() => {
    return () => {
      //リソースのクリーンアップ処理
      useGLTF.clear(
        'https://data.curatedartshow.com/models/objectLights/objectLight2.glb'
      );
    };
  }, []);

  useLayoutEffect(() => {
    // materials['Material.014'].color.set('silver');
    // materials['Material.016'].color.set('silver');

    // materials['Glass'].color.set('red');
    // materials['material'].color.set('red');
    // materials['set1'].color.set('#555');
    // materials['Wall'].opacity = 0;
    // materials['Wall'].transparent = true;

    scene.traverse((obj) => {
      if (obj.isMesh) obj.castShadow = obj.receiveShadow = true;
    });

    return () => {
      // マテリアルの解放
      Object.values(materials).forEach((material) => {
        material.dispose();
      });

      // シーンからオブジェクトの削除
      scene.traverse((obj) => {
        if (obj.isMesh) {
          obj.geometry.dispose();
          obj.material.dispose();
          scene.remove(obj);
        }
      });
    };
  }, []);

  return <Clone object={scene} {...props} castShadow />;
};

export default ObjectLight2;
