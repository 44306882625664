import { useContext, useEffect, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import {
  fetchAvailableItems,
  savePublishExhibition,
} from '../../../services/Exhibition';
import { useSelector, useDispatch } from 'react-redux';
import { useQuery, useMutation } from '@tanstack/react-query';
import { ChooseArtworks } from '../components/ChooseArtworks';
import { ArrangeArtworks } from '../components/ArrangeArtworks';
import { ExhibitionSettings } from '../components/exhibition-settings';
import { ExhibitionContext } from '../../../context/ExhibitionContext';
import { BasicInfo } from '../components/BasicInfo';
import { errorToast, successToast } from '../../../utils/toast';
import { loadEnd, loadStart } from '../../../redux/loadRedux';
import LoadingSpinner from '../../../components/LoadingSpinner';
import { useLocation, useNavigate } from 'react-router-dom';
import { usePreventLeave } from '../../../hooks/usePreventLeave';
import { useBlockNavigation } from '../../../hooks/useBlockNavigation';
import { authRequest, publicRequest } from '../../../requestMethods';
import { DiscardExhibitionModal } from '../../../components/item/DiscardExhibitionModal';
import CreateItem from '../../item/CreateItem';

export const EditExhibition = () => {
  usePreventLeave();
  useBlockNavigation();
  const {
    //product ID
    REACT_APP_STRIPE_PREMIUM_CAS_MEMBER_PRODUCT_ID: PREMIUM_ID,
    REACT_APP_STRIPE_VIP_CAS_MEMBER_PRODUCT_ID: VIP_ID,

    REACT_APP_MAX_PUBLISHED_EXHIBITION_MEMBER: MAX_PUBLISHED_EXHIBITION_FREE,
    REACT_APP_MAX_PUBLISHED_EXHIBITION_PREMIUM:
      MAX_PUBLISHED_EXHIBITION_PREMIUM,
    REACT_APP_MAX_PUBLISHED_EXHIBITION_VIP: MAX_PUBLISHED_EXHIBITION_VIP,
    REACT_APP_MAX_EXHIBITION_MEMBER: MAX_EXHIBITION_FREE,
    REACT_APP_MAX_EXHIBITION_PREMIUM: MAX_EXHIBITION_PREMIUM,
    REACT_APP_MAX_EXHIBITION_VIP: MAX_EXHIBITION_VIP,
  } = process.env;
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState('ArrangeArtworks');
  const [plan, setPlan] = useState(null);
  const [hasReachedMaxPublish, setHasReachedMaxPublish] = useState(false);
  const [hasReachedMaxCreation, setHasReachedMaxCreation] = useState(false);
  const [exhibition, setExhibition] = useState(null);
  const { isLoading } = useSelector((state) => state.isLoading);
  const user = useSelector((state) => state.user.currentUser);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    exhibitionItems,
    setExhibitionItems,
    exhibitionInfo,
    setExhibitionInfo,
    exhibitionSettings,
    setExhibitionSettings,
    exhibitionSnapshot,
  } = useContext(ExhibitionContext);

  const location = useLocation();
  const roomId = location.pathname.split('/')[2];

  useEffect(() => {
    //get room data by id
    const getRoomById = async () => {
      const roomData = await authRequest.get(
        `/rooms/roomId/${roomId}/password`
      );
      setExhibition(roomData.data);
      if (
        roomData.data.isActive &&
        !roomData.data.disabled &&
        roomData.data.userId == user.id
      ) {
        const {
          title,
          description,
          previewImg,
          animationType,
          roomId,
          isPrivate,
          password,
          newPassword,
          showOnHome,
          published,
          slideshowAutoStart,
        } = roomData.data;
        const {
          frame,
          theme,
          silhouette,
          music,

          light,
          lightColor,

          colorCode,
          ceiling,
          wall,
          floor,
          background,
          objectLight,
          directionalLightIntensity,
          directionalLightColorTheme,
          directionalLightColorCode,
          spotLightIntensity,
          spotLightColorTheme,
          spotLightColorCode,
          frameColor,
          matColor,
          fogColor,
        } = roomData.data.roomSettings;

        //silhouettesデータを同期
        const indices = roomData.data.roomSettings.silhouettes?.map(
          (silhouette) => silhouette.index
        );
        setExhibitionItems(
          roomData.data.items.sort((a, b) => a.displayOrder - b.displayOrder)
        );

        setExhibitionInfo({
          title,
          description,
          previewImg,
          animationType,
          roomId,
          isPrivate,
          password,
          newPassword,
          showOnHome,
          published,
          slideshowAutoStart,
        });
        setExhibitionSettings({
          frame,
          theme,
          silhouette,
          music,
          randomMusic: roomData.data.roomSettings?.music === 100,
          light,
          lightColor,
          silhouettes: indices,
          colorCode: colorCode ? colorCode : '#191920',
          ceiling,
          wall,
          floor,
          background,
          objectLight,
          directionalLightIntensity,
          directionalLightColorTheme,
          directionalLightColorCode,
          spotLightIntensity,
          spotLightColorTheme,
          spotLightColorCode,
          frameColor: frameColor ? frameColor : '#525252',
          matColor: matColor ? matColor : '#525252',
          fogColor,
        });
      } else {
        navigate('/not-found');
      }
    };

    getRoomById();
  }, []);

  // 作品取得
  const {
    data: availableItems,
    isLoading: isAvailableItemsLoading,
    refetch: refetchAvailableItems,
  } = useQuery({
    queryFn: () => fetchAvailableItems(user.id),
    queryKey: ['fetch-available-items'],
    enabled: !!user,
  });

  // Save処理
  const handleUpdateExhibition = async (e) => {
    e.preventDefault();
    savePublishMutation.mutate();
  };

  // save&publish処理
  const savePublishMutation = useMutation({
    mutationFn: async () => {
      const items = exhibitionItems.map((item) => item.id);

      return await savePublishExhibition(
        Number(exhibition.id),
        user.id,
        exhibitionSnapshot,
        items,
        exhibitionInfo,
        exhibitionSettings
      );
    },
    onSuccess: () => {
      navigate('/dashboard?tab=galleries');
      successToast(t('message.success.t12'));
    },
    onError: (err) => {
      if (err.response.data.statusCode == 400) {
        errorToast(err?.response?.data.message);
      } else {
        errorToast(
          'Something went wrong. We are aware of the problem and will try to fix it as soon as possible. Sorry for the inconvenience.'
        );
      }
    },
  });

  // 上限に達しているか確認
  const checkIsReachedLimit = async (foundPlan) => {
    const galleryData = await publicRequest.get(`/rooms/users/${user.id}`);
    const draftRoomData = await authRequest.get(`/rooms/drafts/${user?.id}`);

    // 作成上限値に達しているか確認
    if (
      galleryData.data.length + draftRoomData.data.length >=
        Number(MAX_EXHIBITION_FREE) &&
      (foundPlan == 'FREE' || !foundPlan)
    ) {
      setHasReachedMaxCreation(true);
    }

    if (
      galleryData.data.length + draftRoomData.data.length >=
        Number(MAX_EXHIBITION_PREMIUM) &&
      foundPlan == 'PREMIUM'
    ) {
      setHasReachedMaxCreation(true);
    }

    if (
      galleryData.data.length + draftRoomData.data.length >=
        Number(MAX_EXHIBITION_VIP) &&
      foundPlan == 'VIP'
    ) {
      setHasReachedMaxCreation(true);
    }

    // 公開上限値に達しているか確認
    if (
      ((foundPlan == 'FREE' || !foundPlan) &&
        galleryData.data.length >= Number(MAX_PUBLISHED_EXHIBITION_FREE)) ||
      (foundPlan == 'PREMIUM' &&
        galleryData.data.length >= Number(MAX_PUBLISHED_EXHIBITION_PREMIUM)) ||
      (foundPlan == 'VIP' &&
        galleryData.data.length >= Number(MAX_PUBLISHED_EXHIBITION_VIP))
    ) {
      setHasReachedMaxPublish(true);
    }
  };

  // ページ移動
  const handleMoveTo = (pageName, selectedArtworks) => {
    setCurrentPage(pageName);
    if (selectedArtworks) {
      setExhibitionItems(selectedArtworks);
    }
  };

  // 未ログインの場合はリダイレクト
  useEffect(() => {
    if (!user) {
      navigate('/signin');
    }
  }, [user]);

  useEffect(() => {
    const chooseArtworks = document.getElementById('choose_artworks');
    const arrangeArtworks = document.getElementById('arrange_artworks');
    if (chooseArtworks) {
      chooseArtworks.showModal();
    }
    if (arrangeArtworks) {
      arrangeArtworks.showModal();
    }
  }, [currentPage]);

  // 契約プランと各種上限に達しているかを取得
  useEffect(() => {
    let foundPlan = 'FREE';

    if (user?.userPlans?.length > 0) {
      // userPlans配列の各要素をチェック
      user.userPlans.forEach((el) => {
        if (el.SubscriptionPlans.productId === PREMIUM_ID) {
          foundPlan = 'PREMIUM';
        } else if (el.SubscriptionPlans.productId === VIP_ID) {
          foundPlan = 'VIP';
        }
      });
    }
    setPlan(foundPlan);
    checkIsReachedLimit(foundPlan);
  }, [user, PREMIUM_ID, VIP_ID]);

  const renderPage = () => {
    if (currentPage === '') {
      return (
        <ChooseArtworks
          availableItems={availableItems}
          isLoading={isAvailableItemsLoading}
          moveTo={handleMoveTo}
          preSelectedArtworks={exhibitionItems}
          openUploadPage={() => setCurrentPage('uploadArtworks')}
        />
      );
    }
    if (currentPage === 'uploadArtworks') {
      return (
        <CreateItem
          isModal={true}
          closeHandler={() => {
            setCurrentPage('');
            refetchAvailableItems();
          }}
        />
      );
    }
    if (currentPage === 'ArrangeArtworks') {
      return (
        <ArrangeArtworks
          selectedArtworks={exhibitionItems}
          moveTo={handleMoveTo}
        />
      );
    }
    if (currentPage === 'ExhibitionSettings') {
      return (
        <ExhibitionSettings
          moveTo={handleMoveTo}
          pcHeaderTitle='Edit Exhibition'
          plan={plan}
          editMode={true}
          hasReachedMaxPublish={hasReachedMaxPublish}
          hasReachedMaxCreation={hasReachedMaxCreation}
        />
      );
    }
    // ExhibitionSettings と　 BasicInfo を読みこむ
    if (currentPage === 'BasicInfo') {
      return (
        <BasicInfo
          pcHeaderTitle='Edit Exhibition'
          currentMenu='Information'
          moveTo={handleMoveTo}
          editMode={true}
          handleSubmit={handleUpdateExhibition}
          plan={plan}
          hasReachedMaxPublish={hasReachedMaxPublish}
          hasReachedMaxCreation={hasReachedMaxCreation}
        />
      );
    }
    return null; // それ以外のページの場合はnullを返す
  };

  return (
    <>
      {renderPage()}

      <DiscardExhibitionModal
        title={
          <Trans i18nKey={'gallery-modal.discard-create-exhibition-ttl'} />
        }
        desc={<Trans i18nKey={'gallery-modal.discard-desc'} />}
        editMode={true}
        plan={plan}
        hasReachedMaxPublish={hasReachedMaxPublish}
        hasReachedMaxCreation={hasReachedMaxCreation}
      />
      {(savePublishMutation.isPending || isLoading) && <LoadingSpinner />}
    </>
  );
};
