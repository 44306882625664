import { useTranslation } from 'react-i18next';
import { escapeToHTML } from '../../../utils/StringUtils';
import parse from 'html-react-parser';

export const Accordion = ({ faq }) => {
  const { i18n } = useTranslation();

  return (
    <div className='collapse collapse-arrow bg-base-200'>
      <input type='radio' name='my-accordion-2' />
      <div className='collapse-title text-xl font-medium'>
        {i18n.language == 'ja' ? faq.questionJa : faq?.question}
      </div>
      <div className='collapse-content'>
        <div style={{ whiteSpace: 'pre-wrap' }}>
          {/* faq-answerクラスはul,olにpaddingをつけるためのもの（index.cssで定義） */}
          <div className='faq-answer p-2'>
            {parse(
              escapeToHTML(i18n.language == 'ja' ? faq.answerJa : faq?.answer)
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
