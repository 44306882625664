import {
  SparklesIcon,
  ArrowPathIcon,
  FolderArrowDownIcon,
  BookmarkIcon,
  PlusCircleIcon,
  EyeSlashIcon,
} from '@heroicons/react/24/outline';
import React, { Fragment, useEffect, useState, useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ExhibitionContext } from '../../../../context/ExhibitionContext';
import { HexColorInput, HexColorPicker } from 'react-colorful';

const COLOR_CODES = [
  '#F5F5F4',
  '#E5E6E6',
  '#E2E8F0',
  '#D1D5DB',
  '#9CA3AF',
  '#1F2937',
  '#C2410C',
  '#DC2626',
  '#F472B6',
  '#C026D3',
  '#9333EA',
  '#4F46E5',
  '#0284C7',
  '#0891B2',
  '#0D9488',
  '#16A34A',
  '#65A30D',
  '#CA8A04',
  '#D97706',
  '#854D0E',
  '#78350F',
];

export const Theme = ({ ceilings, walls, floors, backgrounds, plan }) => {
  const { i18n } = useTranslation();

  const [themeKind, setThemeKind] = useState('Preset');
  const [filteredCeilings, setFilteredCeilings] = useState([]);
  const [filteredWalls, setFilteredWalls] = useState([]);
  const [filteredFloors, setFilteredFloors] = useState([]);
  const [filteredBackgrounds, setFilteredBackgrounds] = useState([]);
  const [wallType, setWallType] = useState('texture');
  const [useCustomColor, setUseCustomColor] = useState(false);
  const FOG_COLOR = '#404040';
  const { exhibitionSettings, setExhibitionSettings, exhibitionSnapshot } =
    useContext(ExhibitionContext);
  const THEMES = [
    {
      kind: 'Preset',
      label: 'Special (non-editable)',
      themes: [
        {
          value: 'MUSEUM',
          title: 'MUSEUM',
          bgColor: 'bg-[#8d3047]',
          displayTitle: 'Museum',
        },
        {
          value: 'CYBERPUNK',
          title: 'CYBERPUNK',
          bgColor: 'bg-cyberpunk-grad',
          displayTitle: 'Cyberpunk',
        },
        {
          value: 'CLASSIC',
          title: 'CLASSIC',
          bgColor: 'bg-[#8d3047]',
          displayTitle: 'Classic',
        },

        {
          value: 'SPACE',
          title: 'SPACE',
          bgColor: 'bg-[#8d3047]',
          displayTitle: 'Space',
        },
        {
          value: 'SKY',
          title: 'SKY',
          bgColor: 'bg-[#8d3047]',
          displayTitle: 'Sky',
        },
        {
          value: 'MARBLE',
          title: 'MARBLE',
          bgColor: 'bg-[#8d3047]',
          displayTitle: 'Marble Hall',
        },
      ],
    },
    {
      kind: 'Custom',
      label: 'Custom Room Theme',
      themes: [],
    },
  ];

  const OBJECT_LIGHTS = [
    {
      value: 1,
      title: 'CEILING LAMP',
    },
    {
      value: 2,
      title: 'CEILING LIGHTS',
    },
    {
      value: 3,
      title: '1912 ELECTROLIER CEILING LAMPS',
    },
  ];

  const handleChangeTheme = (value) => {
    setExhibitionSettings((prevState) => ({
      ...prevState,
      theme: value,
    }));
  };

  const handleChangeColorCode = (value) => {
    setExhibitionSettings((prevState) => ({
      ...prevState,
      colorCode: value,
      wall: null,
    }));
  };

  const handleChangeCeiling = (value) => {
    setExhibitionSettings((prevState) => ({
      ...prevState,
      ceiling: value,
      background: null,
      theme: 'CUSTOM',
    }));
  };

  const handleChangeWall = (value) => {
    setExhibitionSettings((prevState) => ({
      ...prevState,
      wall: value,
      background: null,
      theme: 'CUSTOM',
    }));
  };

  const handleChangeBackground = (value) => {
    setExhibitionSettings((prevState) => ({
      ...prevState,
      background: value,
      wall: null,
      ceiling: null,
      theme: 'CUSTOM',
    }));
  };

  const handleChangeFloor = (value) => {
    setExhibitionSettings((prevState) => ({
      ...prevState,
      floor: value,
      theme: 'CUSTOM',
    }));
  };

  const handleChangeObjectLight = (value) => {
    setExhibitionSettings((prevState) => ({
      ...prevState,
      objectLight: value ? Number(value) : value,
    }));
  };

  const handleChangeFogColor = (value) => {
    setExhibitionSettings((prevState) => ({
      ...prevState,
      fogColor: value,
    }));
  };

  useEffect(() => {
    if (themeKind == 'Custom') {
      handleChangeTheme('CUSTOM');
    }
  }, [themeKind]);

  useEffect(() => {
    if (exhibitionSettings.theme == 'CUSTOM') {
      setThemeKind('Custom');
    } else {
      setThemeKind('Preset');
    }
  }, [exhibitionSettings.theme]);

  useEffect(() => {
    const colorCodeExists = COLOR_CODES.includes(exhibitionSettings.colorCode);

    if (colorCodeExists) {
      setUseCustomColor(false);
    } else {
      setUseCustomColor(true);
    }
  }, [exhibitionSettings.colorCode]);

  // 天井をフィルタリング (データ取得時にバックエンドでフィルタリングしたい)
  useEffect(() => {
    if (ceilings) {
      const filteredCeilingsTmp =
        plan == 'PREMIUM' || plan == 'VIP'
          ? ceilings
          : ceilings.filter((ceiling) => !ceiling.isPro); // isProがfalseのものだけをフィルタリング
      setFilteredCeilings(filteredCeilingsTmp);
    }
  }, [ceilings, plan]);

  // 壁をフィルタリング (データ取得時にバックエンドでフィルタリングしたい)
  useEffect(() => {
    const filteredWallsTmp =
      plan == 'PREMIUM' || plan == 'VIP'
        ? walls
        : walls?.filter((wall) => !wall.isPro); // isProがfalseのものだけをフィルタリング
    setFilteredWalls(filteredWallsTmp);
  }, [walls, plan]);

  // 床をフィルタリング (データ取得時にバックエンドでフィルタリングしたい)
  useEffect(() => {
    const filteredFloorsTmp =
      plan == 'PREMIUM' || plan == 'VIP'
        ? floors
        : floors?.filter((floor) => !floor.isPro); // isProがfalseのものだけをフィルタリング
    setFilteredFloors(filteredFloorsTmp);
  }, [floors, plan]);

  // backgroundをフィルタリング (データ取得時にバックエンドでフィルタリングしたい)
  useEffect(() => {
    const filteredBackgroundsTmp =
      plan == 'PREMIUM' || plan == 'VIP'
        ? backgrounds
        : backgrounds?.filter((background) => !background.isPro); // isProがfalseのものだけをフィルタリング
    setFilteredBackgrounds(filteredBackgroundsTmp);
  }, [backgrounds, plan]);

  const [toggleTheme, setToggleTheme] = useState({
    objectLight: false,
  });

  useEffect(() => {
    if (exhibitionSettings.objectLight) {
      setToggleTheme({ objectLight: true });
    }
  }, [exhibitionSettings.objectLight]);

  return (
    <div className='relative lg:overflow-scroll'>
      {THEMES.map((item, index) => (
        <Fragment key={`theme-${index}`}>
          <div className='form-control'>
            <label className='label cursor-pointer justify-start gap-2'>
              <input
                type='radio'
                className='radio'
                value={item.kind}
                onChange={(e) => {
                  setThemeKind(e.target.value);
                }}
                checked={item.kind === themeKind}
              />
              <span className='label-text'>
                {item.kind === 'Preset' && i18n.language === 'ja'
                  ? 'プリセット'
                  : item.kind === 'Custom' && i18n.language === 'ja'
                  ? 'カスタム'
                  : item.label}
              </span>
            </label>
          </div>
          {item.kind === 'Custom' && (
            <div className='ml-8 flex flex-col items-start gap-2 rounded-xl bg-zinc-50 p-5 shadow-md'>
              <button
                className='btn btn-neutral btn-sm'
                onClick={() =>
                  document.getElementById('suggested_presets_modal').showModal()
                }
              >
                <SparklesIcon className='h-5 w-5' />
                <Trans i18nKey={'gallery.theme.preset-library'} />
              </button>
              <button
                className='btn btn-outline btn-sm'
                onClick={() =>
                  document.getElementById('my_presets_modal').showModal()
                }
              >
                <FolderArrowDownIcon className='h-5 w-5' />
                <Trans i18nKey={'gallery.theme.load-my-preset'} />
              </button>
              <button
                className='btn btn-ghost btn-sm'
                onClick={() =>
                  document.getElementById('reset_confirm_modal').showModal()
                }
              >
                <ArrowPathIcon className='h-5 w-5' />
                <Trans i18nKey={'gallery.theme.reset-setting'} />
              </button>
            </div>
          )}
          <div className='pl-8'>
            {item.themes.map((el, index) => {
              return (
                <Fragment key={`theme-sub-${index}`}>
                  <div className='form-control'>
                    <label className='label cursor-pointer justify-start gap-2'>
                      <input
                        type='radio'
                        className='radio'
                        value={el.value}
                        onChange={(e) => handleChangeTheme(e.target.value)}
                        checked={el.value === exhibitionSettings.theme}
                        disabled={themeKind !== item.kind}
                      />
                      <span
                        className={`label-text ${
                          themeKind !== item.kind ? 'text-opacity-30' : ''
                        }`}
                      >
                        {el.displayTitle}
                      </span>
                    </label>
                  </div>
                </Fragment>
              );
            })}
          </div>
        </Fragment>
      ))}

      <div className='mb-10 mt-8 pl-8'>
        <div className='text-sm'>
          <Trans i18nKey={'gallery.theme.choose-texture'} />
        </div>
        <div
          role='tablist'
          className={`tabs tabs-lifted mt-5 ${
            themeKind === 'Custom'
              ? 'pointer-events-auto cursor-auto opacity-100'
              : 'pointer-events-none opacity-40'
          }`}
        >
          <input
            type='radio'
            name='texture_tab'
            role='tab'
            className='tab break-keep font-semibold'
            aria-label={i18n.language === 'ja' ? '天井' : 'Ceiling'}
            defaultChecked
          />
          <div
            role='tabpanel'
            className='tab-content rounded-lg border-base-300 bg-white p-6 shadow-lg'
          >
            <div className='grid max-h-[34rem] grid-cols-3 gap-x-2 gap-y-[0.69rem] overflow-y-scroll'>
              <div
                className={`relative mb-6 cursor-pointer`}
                onClick={() => {
                  handleChangeCeiling(null);
                }}
              >
                <div
                  className={`flex aspect-square items-center justify-center rounded bg-base-200 ${
                    !exhibitionSettings.ceiling &&
                    'border-[1.5px] border-base-content'
                  }`}
                >
                  <EyeSlashIcon className='h-8 w-8 text-gray-400' />
                </div>
                <p className='mt-1 text-left text-xs capitalize'>No Ceiling</p>
                {!exhibitionSettings.ceiling && (
                  <img
                    src='/assets/img/check_icon.png'
                    className='absolute right-3 top-3 h-[0.875rem] w-[0.875rem]'
                  />
                )}
              </div>
              <div />
              <div />
              {filteredCeilings.map((item, index) => (
                <div
                  key={`ceiling-${index}`}
                  className={`relative cursor-pointer`}
                  onClick={() => handleChangeCeiling(Number(item.id))}
                >
                  <img
                    src={item.thumbnail + '?q=60'}
                    alt={item.title}
                    className={`aspect-square w-full rounded ${
                      item.id == exhibitionSettings.ceiling &&
                      'border-[1.5px] border-base-content'
                    }`}
                  />
                  <p className='mt-1 text-left text-xs capitalize'>
                    {item.title.toLowerCase()}
                  </p>
                  {item.id == exhibitionSettings.ceiling && (
                    <img
                      src='/assets/img/check_icon.png'
                      className='absolute right-3 top-3 h-[0.875rem] w-[0.875rem]'
                    />
                  )}
                </div>
              ))}
            </div>
          </div>
          <input
            type='radio'
            name='texture_tab'
            role='tab'
            className='tab font-semibold'
            aria-label={i18n.language === 'ja' ? '壁' : 'Wall'}
          />
          <div
            role='tabpanel'
            className='tab-content rounded-lg border-base-300 bg-white p-6 shadow-lg'
          >
            <div className='mb-6 flex items-center gap-3'>
              <button
                className={`btn btn-sm ${
                  wallType == 'texture' && 'bg-base-content text-white'
                }`}
                onClick={() => {
                  setWallType('texture');
                }}
              >
                <Trans i18nKey={'gallery.theme.texture'} />
              </button>
              <button
                className={`btn btn-sm ${
                  wallType == 'color' && 'bg-base-content text-white'
                }`}
                onClick={() => {
                  setWallType('color');
                }}
              >
                <Trans i18nKey={'gallery.theme.solid-color'} />
              </button>
            </div>
            {wallType == 'texture' ? (
              <div>
                <div className='label my-6 justify-start gap-2'>
                  <span
                    className={`label-text text-sm text-base-content ${
                      exhibitionSettings.background ? '' : 'text-opacity-30'
                    }`}
                  >
                    <Trans i18nKey={'gallery.theme.wall-shadow'} /> on/off
                  </span>
                  <input
                    type='checkbox'
                    className='toggle'
                    onChange={(e) => {
                      if (exhibitionSettings.fogColor) {
                        handleChangeFogColor(null);
                      } else {
                        handleChangeFogColor(FOG_COLOR);
                      }
                    }}
                    checked={exhibitionSettings.fogColor}
                    disabled={!exhibitionSettings.background}
                  />
                </div>
                <div className='grid max-h-[34rem] grid-cols-3 gap-x-2 gap-y-[0.69rem] overflow-y-scroll'>
                  <div
                    className={`relative mb-6 cursor-pointer`}
                    onClick={() => {
                      handleChangeWall(null);
                      handleChangeBackground(null);
                    }}
                  >
                    <div
                      className={`flex aspect-square items-center justify-center rounded bg-base-200 ${
                        !exhibitionSettings.wall &&
                        !exhibitionSettings.background &&
                        'border-[1.5px] border-base-content'
                      }`}
                    >
                      <EyeSlashIcon className='h-8 w-8 text-gray-400' />
                    </div>
                    <p className='mt-1 text-left text-xs capitalize'>No Wall</p>
                    {!exhibitionSettings.wall &&
                      !exhibitionSettings.background && (
                        <img
                          src='/assets/img/check_icon.png'
                          className='absolute right-3 top-3 h-[0.875rem] w-[0.875rem]'
                        />
                      )}
                  </div>
                  <div />
                  <div />
                  {filteredBackgrounds.map((item, index) => (
                    <div
                      key={`background-${index}`}
                      className={`relative cursor-pointer`}
                      onClick={() => {
                        handleChangeBackground(Number(item.id));
                        handleChangeFogColor(FOG_COLOR);
                      }}
                    >
                      <img
                        src={item.thumbnail + '?q=60'}
                        alt={item.title}
                        className={`aspect-square w-full rounded ${
                          item.id == exhibitionSettings.background &&
                          'border-[1.5px] border-base-content'
                        }`}
                      />
                      <p className='mt-1 text-left text-xs capitalize'>
                        {item.title.toLowerCase()}
                      </p>
                      {item.id == exhibitionSettings.background && (
                        <img
                          src='/assets/img/check_icon.png'
                          className='absolute right-3 top-3 h-[0.875rem] w-[0.875rem]'
                        />
                      )}
                    </div>
                  ))}
                  {filteredWalls.map((item, index) => (
                    <div
                      key={`wall-${index}`}
                      className={`relative cursor-pointer`}
                      onClick={() => {
                        handleChangeFogColor(null);
                        handleChangeWall(Number(item.id));
                      }}
                    >
                      <img
                        src={item.thumbnail + '?q=60'}
                        alt={item.title}
                        className={`aspect-square w-full rounded ${
                          item.id == exhibitionSettings.wall &&
                          'border-[1.5px] border-base-content'
                        }`}
                      />
                      <p className='mt-1 text-left text-xs capitalize'>
                        {item.title.toLowerCase()}
                      </p>
                      {item.id == exhibitionSettings.wall && (
                        <img
                          src='/assets/img/check_icon.png'
                          className='absolute right-3 top-3 h-[0.875rem] w-[0.875rem]'
                        />
                      )}
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <div className='overflow-y-scroll'>
                <div className='grid max-h-[34rem] grid-cols-3 gap-x-2 gap-y-[0.69rem]'>
                  <div
                    className={`bg-rainbow relative mb-6 flex aspect-square min-h-[5rem] min-w-[5rem]  cursor-pointer items-center justify-center  rounded-md border-[1.5px] border-[#1F2A37]`}
                    onClick={() => {
                      setUseCustomColor(true);
                      handleChangeColorCode('#191920');
                    }}
                  >
                    <PlusCircleIcon className=' h-8 w-8 shrink-0' />
                    {useCustomColor && (
                      <img
                        src='/assets/img/check_icon.png'
                        className='absolute right-2 top-2 h-[0.875rem] w-[0.875rem]'
                      />
                    )}
                  </div>
                </div>

                {useCustomColor && (
                  <div className='mb-6'>
                    <HexColorPicker
                      color={exhibitionSettings?.colorCode}
                      onChange={handleChangeColorCode}
                    />
                    <div className='mt-2 '>
                      <HexColorInput
                        color={exhibitionSettings?.colorCode}
                        onChange={handleChangeColorCode}
                        className='input input-bordered w-[200px]'
                      />
                    </div>
                  </div>
                )}
                <div className='grid max-h-[34rem] grid-cols-3 gap-x-2 gap-y-[0.69rem]'>
                  {COLOR_CODES.map((code, index) => (
                    <div
                      key={`suggest-code-${index}`}
                      className={`relative cursor-pointer rounded-md`}
                      onClick={() => {
                        setUseCustomColor(false);
                        handleChangeColorCode(code);
                      }}
                    >
                      <div
                        style={{ backgroundColor: code }}
                        className={`aspect-square min-h-[5rem] min-w-[5rem]  rounded-md  ${
                          code == exhibitionSettings.colorCode &&
                          'border-[1.5px] border-[#1F2A37]'
                        }`}
                      />

                      {code == exhibitionSettings.colorCode && (
                        <img
                          src='/assets/img/check_icon.png'
                          className='absolute right-2 top-2 h-[0.875rem] w-[0.875rem]'
                        />
                      )}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>

          <input
            type='radio'
            name='texture_tab'
            role='tab'
            className='tab font-semibold'
            aria-label={i18n.language === 'ja' ? '床' : 'Floor'}
          />
          <div
            role='tabpanel'
            className='tab-content rounded-lg border-base-300 bg-white p-6 shadow-lg'
          >
            <div className='grid max-h-[34rem] grid-cols-3 gap-x-2 gap-y-[0.69rem] overflow-y-scroll'>
              <div
                className={`relative mb-6 cursor-pointer`}
                onClick={() => {
                  handleChangeFloor(null);
                }}
              >
                <div
                  className={`flex aspect-square items-center justify-center rounded bg-base-200 ${
                    !exhibitionSettings.floor &&
                    'border-[1.5px] border-base-content'
                  }`}
                >
                  <EyeSlashIcon className='h-8 w-8 text-gray-400' />
                </div>
                <p className='mt-1 text-left text-xs capitalize'>
                  Default Floor
                </p>
                {!exhibitionSettings.floor && (
                  <img
                    src='/assets/img/check_icon.png'
                    className='absolute right-3 top-3 h-[0.875rem] w-[0.875rem]'
                  />
                )}
              </div>
              <div />
              <div />

              {filteredFloors.map((item, index) => (
                <div
                  key={`floor-${index}`}
                  className={`relative cursor-pointer`}
                  onClick={() => {
                    handleChangeFloor(Number(item.id));
                  }}
                >
                  <img
                    src={item.thumbnail + '?q=60'}
                    alt={item.title}
                    className={`aspect-square w-full rounded ${
                      item.id == exhibitionSettings.floor &&
                      'border-[1.5px] border-base-content'
                    }`}
                  />
                  <p className='mt-1 text-left text-xs capitalize'>
                    {item.title.toLowerCase()}
                  </p>
                  {item.id == exhibitionSettings.floor && (
                    <img
                      src='/assets/img/check_icon.png'
                      className='absolute right-3 top-3 h-[0.875rem] w-[0.875rem]'
                    />
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>

        {themeKind === 'Custom' && exhibitionSettings.ceiling && (
          <label className='form-control mt-2 w-full max-w-[200px] sm:max-w-xs'>
            <div className='label justify-start gap-2'>
              <span
                className={`label-text text-base font-bold ${
                  themeKind === 'Preset' ? 'text-opacity-30' : ''
                }`}
              >
                <Trans i18nKey={'gallery.theme.deco-light'} />
              </span>
              <input
                type='checkbox'
                className='toggle'
                onChange={() => {
                  setToggleTheme({
                    ...toggleTheme,
                    objectLight: !toggleTheme.objectLight,
                  });
                  if (exhibitionSettings.objectLight) {
                    handleChangeObjectLight(null);
                  }
                }}
                checked={toggleTheme.objectLight}
                disabled={themeKind === 'Preset'}
              />
            </div>
            {toggleTheme.objectLight && (
              <select
                className='select select-bordered mb-2'
                onChange={(e) => handleChangeObjectLight(e.target.value)}
                value={
                  exhibitionSettings.objectLight
                    ? exhibitionSettings.objectLight
                    : ''
                }
                disabled={themeKind === 'Preset'}
              >
                <option disabled selected value={''}>
                  <Trans i18nKey={'gallery.pick'} />
                </option>
                {OBJECT_LIGHTS.map((el, index) => (
                  <option key={`object-light-${index}`} value={el.value}>
                    {el.title}
                  </option>
                ))}
              </select>
            )}
          </label>
        )}
        <button
          className='btn btn-info btn-block mt-5'
          onClick={() =>
            document.getElementById('save_preset_modal').showModal()
          }
        >
          <BookmarkIcon className='h-5 w-5' />
          <Trans i18nKey={'gallery.theme.save-preset'} />
        </button>
      </div>
    </div>
  );
};
