// import React from 'react';
import RotateLoader from 'react-spinners/RotateLoader';

const LoadingSpinner = ({ isHalf = false }) => {
  return (
    <div
      className={`bg-white/35 fixed top-0 z-[1003] h-full backdrop-blur ${
        isHalf ? 'w-1/2' : 'w-full'
      }`}
    >
      <div className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'>
        <RotateLoader color='#1F5EFF' size={15} />
      </div>
    </div>
  );
};

export default LoadingSpinner;
