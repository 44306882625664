import { useGLTF } from '@react-three/drei';
import { Suspense, useLayoutEffect, useState } from 'react';

const PictureFrame45 = ({ color, ...props }) => {
  const { scene, materials } = useGLTF(
    'https://data.curatedartshow.com/models/frames/wetconcrete.glb'
  );

  const [geometry, setGeometry] = useState();

  if (!geometry) {
    const cloneScene = scene.clone(true);
    setGeometry(cloneScene);
  }

  useLayoutEffect(() => {
    // console.log(materials);
    // materials['Matte Dark Blue Denim Fabric_material'].color.set('#ebeaea');
    materials['Matte Dark Blue Denim Fabric_material'].metalness = 0.5;
    materials['Matte Dark Blue Denim Fabric_material'].roughness = 1;
    // materials['Heavily Brushed Iron_material'].color.set('#191919');
    materials['Heavily Brushed Iron_material'].metalness = 1.5;
    materials['Heavily Brushed Iron_material'].roughness = 2;
    // materials['Wet Coarse Concrete_material'].color.set('#aca51b');
    materials['Wet Coarse Concrete_material'].metalness = 0.5;
    materials['Wet Coarse Concrete_material'].roughness = 2;

    geometry.traverse((obj) => {
      if (obj.isMesh) obj.castShadow = obj.receiveShadow = true;
    });
  }, []);

  return (
    <Suspense fallback={null}>
      <primitive object={geometry} {...props} />
    </Suspense>
  );
};

export default PictureFrame45;
