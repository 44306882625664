import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export const formatYYYYMMDD = (date) => {
  return dayjs(date).format('YYYYMMDD');
};

export const formatMMMDYYYY = (date) => {
  return dayjs(date).format('MMM D, YYYY');
};

export const formatDateMMMDYYYYTIME = (date) => {
  return dayjs.utc(date).local().format('MMM D, YYYY hh:mm A');
};
