import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

const SignupSuccessModal = () => {
  return (
    <dialog id='signup_success_modal' className='modal'>
      <div className='modal-box !max-w-[32rem] !p-6'>
        <h3 className='mb-6 mt-0 text-lg font-bold'>
          <Trans i18nKey={'signup.success-modal.ttl'} />
        </h3>
        <img
          src='/assets/img/signup_success.png'
          className='mx-auto w-[15rem]'
        />
        <p className='my-6 font-medium'>
          <Trans i18nKey={'signup.success-modal.desc'} />
        </p>
        <div className='modal-action flex flex-col gap-4 sm:flex-row'>
          <Link to={'/dashboard'} className='btn btn-outline btn-primary'>
            <Trans i18nKey={'btn.go-to-dashboard'} />
          </Link>
          <Link to={'/create-item'} className='btn btn-primary !m-0'>
            <Trans i18nKey={'btn.upload-artwork'} />
          </Link>
        </div>
      </div>
    </dialog>
  );
};

export default SignupSuccessModal;
