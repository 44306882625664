import React, { Suspense, useEffect, useRef } from 'react';
import { Canvas } from '@react-three/fiber';
import CyberPunkRoomPreview from './preview/CyberPunkRoomPreview';
import BasicRoomPreview from './preview/BasicRoomPreview';
import ClassicRoomPreview from './preview/ClassicRoomPreview';
import { Perf } from 'r3f-perf';
import Snapshot from './Snapshot';
import SpacePreview from './preview/SpacePreview';
import SkyPreview from './preview/SkyPreview';
import MuseumPreview from './preview/MuseumPreview';
import MarblePreview from './preview/MarblePreview';

const Preview = ({
  theme,
  frame,
  showSilhouette,
  setSnapshot,
  light,
  spotlight,
  items,
  lightColor,
  ceiling,
  wall,
  floor,
  background,
  objectLight,
  colorCode,
  directionalLightIntensity,
  directionalLightColorCode,
  spotLightIntensity,
  spotLightColorCode,
  frameColor,
  matColor,
  fogColor,
}) => {
  const canvasRef = useRef();

  useEffect(() => {
    // Canvasコンポーネントがアンマウントされたときにリソースを解放
    return () => {
      if (canvasRef.current) {
        const { gl } = canvasRef.current;
        if (gl) {
          gl.forceContextLoss();
          gl.dispose();
          canvasRef.current = null;
        }
      }
    };
  }, []);

  return (
    <>
      <Suspense fallback={null}>
        <Canvas
          ref={canvasRef}
          dpr={[1, 1.5]}
          camera={{
            fov: 70,
            position: [-0.05030961195855422, 1.02, 5.9],
            // position: [
            //   -0.05030961195855422, 1.0203096119585538, 6.528130264682561,
            // ],
          }}
          gl={{
            preserveDrawingBuffer: true,
            pixelRatio: window.devicePixelRatio,
          }}
          onCreated={({ gl, events }) => {
            setTimeout(() => {
              // // スクリーンショットを取得
              // gl.domElement.toDataURL('image/png');
              // // 解像度を元に戻す

              const screenshot = gl.domElement.toDataURL('image/jpeg');
              setSnapshot(screenshot);
            }, 3000);
          }}
          frameloop='always'
        >
          <Snapshot
            theme={theme}
            frame={frame}
            showSilhouette={showSilhouette}
            setSnapshot={setSnapshot}
            light={light}
            spotlight={spotlight}
            items={items}
            lightColor={lightColor}
            ceiling={ceiling}
            wall={wall}
            floor={floor}
            objectLight={objectLight}
            colorCode={colorCode}
            directionalLightIntensity={directionalLightIntensity}
            directionalLightColorCode={directionalLightColorCode}
            spotLightIntensity={spotLightIntensity}
            spotLightColorCode={spotLightColorCode}
            frameColor={frameColor}
            matColor={matColor}
            fogColor={fogColor}
          />
          {process.env.REACT_APP_ENV === 'development' && (
            <Perf position='top-center' />
          )}
          {theme === 'CYBERPUNK' ? (
            <CyberPunkRoomPreview
              theme={theme}
              frame={frame}
              showSilhouette={showSilhouette}
              setSnapshot={setSnapshot}
              items={items}
              frameColor={frameColor}
              matColor={matColor}
            />
          ) : theme === 'CLASSIC' ? (
            <ClassicRoomPreview
              theme={theme}
              frame={frame}
              showSilhouette={showSilhouette}
              setSnapshot={setSnapshot}
              items={items}
              ceiling={ceiling}
              wall={wall}
              floor={floor}
              objectLight={objectLight}
              frameColor={frameColor}
              matColor={matColor}
            />
          ) : theme === 'SPACE' ? (
            <SpacePreview
              theme={theme}
              frame={frame}
              showSilhouette={showSilhouette}
              setSnapshot={setSnapshot}
              items={items}
              frameColor={frameColor}
              matColor={matColor}
            />
          ) : theme === 'SKY' ? (
            <SkyPreview
              theme={theme}
              frame={frame}
              showSilhouette={showSilhouette}
              setSnapshot={setSnapshot}
              items={items}
              frameColor={frameColor}
              matColor={matColor}
            />
          ) : theme === 'MUSEUM' ? (
            <MuseumPreview
              theme={theme}
              frame={frame}
              showSilhouette={showSilhouette}
              setSnapshot={setSnapshot}
              items={items}
              frameColor={frameColor}
              matColor={matColor}
            />
          ) : theme === 'MARBLE' ? (
            <MarblePreview
              theme={theme}
              frame={frame}
              showSilhouette={showSilhouette}
              setSnapshot={setSnapshot}
              items={items}
              frameColor={frameColor}
              matColor={matColor}
            />
          ) : (
            <BasicRoomPreview
              theme={theme}
              frame={frame}
              showSilhouette={showSilhouette}
              setSnapshot={setSnapshot}
              light={light}
              spotlight={spotlight}
              items={items}
              lightColor={lightColor}
              ceiling={ceiling}
              wall={wall}
              floor={floor}
              background={background}
              objectLight={objectLight}
              colorCode={colorCode}
              directionalLightIntensity={directionalLightIntensity}
              directionalLightColorCode={directionalLightColorCode}
              spotLightIntensity={spotLightIntensity}
              spotLightColorCode={spotLightColorCode}
              frameColor={frameColor}
              matColor={matColor}
              fogColor={fogColor}
            />
          )}
        </Canvas>
      </Suspense>
    </>
  );
};

export default Preview;
