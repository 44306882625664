import React, { useContext, useRef } from 'react';
import { useEffect, useState } from 'react';

import Overlay from './Overlay';
import { publicRequest } from '../../requestMethods';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import ItemModalInGallery from '../../components/item/ItemModalnGallery';
import { useSelector } from 'react-redux';
import FullScreenImg from '../../components/item/FullScreenImg';
import ClassicRoom from '../../components/r3f/theme/ClassicRoom';
import { Canvas, invalidate } from '@react-three/fiber';
import { Perf } from 'r3f-perf';
import {
  AdaptiveDpr,
  KeyboardControls,
  PerformanceMonitor,
  useProgress,
} from '@react-three/drei';
import CustomColorRoom from '../../components/r3f/theme/CustomColorRoom';
import { useSwipeable } from 'react-swipeable';
import InquiryItemModal from '../../components/item/InquiryItemModal';
import { errorToast } from '../../utils/toast';
import ReactGA from 'react-ga4';

import ExhibitionPasswordModal from '../../components/ExhibitionPasswordModal';
import { Trans } from 'react-i18next';
import { ExhibitionControllerContext } from '../../context/ExhibitionControllerContext';
import { ExhibitionContext } from '../../context/ExhibitionContext';
import Space from '../../components/r3f/theme/Space';
import Sky from '../../components/r3f/theme/Sky';
import AttributionModal from './components/AttributionModal';
import Museum from '../../components/r3f/theme/Museum';
import Marble from '../../components/r3f/theme/Marble';

const CyberPunkRoom = React.lazy(() =>
  import('../../components/r3f/theme/CyberPunk')
);

const Room = () => {
  const PREMIUM_MEMBER_ID =
    process.env.REACT_APP_STRIPE_PREMIUM_CAS_MEMBER_PRODUCT_ID;
  const VIP_MEMBER_ID = process.env.REACT_APP_STRIPE_VIP_CAS_MEMBER_PRODUCT_ID;
  const [isLocked, setIsLocked] = useState(false);
  const [room, setRoom] = useState([]);
  const location = useLocation();
  const [showExitModal, setShowExitModal] = useState(false);
  const [skipped, setSkipped] = useState(false);
  const [videoLoaded, setVideoLoaded] = useState(false);
  const [hasMatureContent, setHasMatureContent] = useState(false);
  const [showMatureContent, setShowMatureContent] = useState(true);
  const [answered, setAnswered] = useState(false);
  const [preference, setPreference] = useState('hide');
  const [dpr, setDpr] = useState(window.devicePixelRatio || 1);
  const [isFreeMember, setIsFreeMember] = useState(false);
  const roomId = location.pathname.split('/')[2];
  const user = useSelector((state) => state.user.currentUser);
  const navigate = useNavigate();
  const matureContentModalRef = useRef(null);

  const {
    exhibitionItems,
    setExhibitionItems,

    setExhibitionInfo,
    exhibitionSettings,
    setExhibitionSettings,
  } = useContext(ExhibitionContext);

  const {
    currentItemId,
    setCurrentItemId,

    selectedItem,
    setSelectedItem,

    setIsActive,
    zoomItemImageUrl,
    setZoomItemImageUrl,

    inquiryItem,
    setInquiryItem,

    setMode,
  } = useContext(ExhibitionControllerContext);

  useEffect(() => {
    // イベント送信のため初期化
    ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);
    const modal = document.getElementById('mature_content_warning_modal');

    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        event.preventDefault(); // ESCキーのデフォルト動作を無効化
      }
    };

    // モーダルが開いている間だけESCキーを無効化する
    modal.addEventListener('keydown', handleKeyDown);

    // クリーンアップでリスナーを削除
    return () => {
      modal.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const getRoomData = async () => {
    const res = await publicRequest.get(`/rooms/roomId/${roomId}`);

    if (
      (res.data.userId == user?.id && !res.data.disabled) ||
      (res.data.isActive && !res.data.disabled && res.data.published)
    ) {
      if (res.data.isSuspended) {
        navigate('/404');
        return;
      }

      const isMyGallery = res.data.userId === user?.id;
      const casUnlocked = JSON.parse(
        localStorage.getItem('cas-exhibition-unlocked')
      );
      const hasUnLocked = casUnlocked && casUnlocked[`${res.data.id}`] === 1;
      //lockedモーダル表示条件
      //- isPrivate:trueの場合
      //- localstorageにunlocked履歴がない場合
      //- 自分自身のexhibitiionではない場合
      if (!res.data.isPrivate || isMyGallery || hasUnLocked) {
        setIsLocked(false);
      } else {
        setIsLocked(true);
      }

      setRoom(res.data);

      const foundMatureContent = res.data.items.find(
        (item) => item.isMatureContent == true
      );

      if (foundMatureContent) {
        setHasMatureContent(true);
      } else {
        setHasMatureContent(false);
        setAnswered(true);
      }

      // // published: true のアイテムのみをセット
      // const publishedItems = res.data.items.filter(
      //   (item) => item.published === true
      // );
      // setItems(publishedItems);
      // const indices = res.data.roomSettings.silhouettes?.map(
      //   (silhouette) => silhouette.index
      // );
      // res.data.roomSettings.silhouettes = indices;
      // setRoomSettings(res.data.roomSettings);

      const {
        title,
        description,
        previewImg,
        animationType,
        roomId,
        isPrivate,
        password,
        newPassword,
        showOnHome,
        published,
        slideshowAutoStart,
      } = res.data;
      const {
        frame,
        theme,
        silhouette,
        music,
        light,
        lightColor,
        colorCode,
        ceiling,
        wall,
        floor,
        background,
        objectLight,
        directionalLightIntensity,
        directionalLightColorTheme,
        directionalLightColorCode,
        spotLightIntensity,
        spotLightColorTheme,
        spotLightColorCode,
        frameColor,
        matColor,
        fogColor,
      } = res.data.roomSettings;

      //silhouettesデータを同期
      const indices = res.data.roomSettings.silhouettes?.map(
        (silhouette) => silhouette.index
      );

      const publishedItems = res.data.items.filter(
        (item) => item.published === true
      );

      setExhibitionItems(publishedItems);

      setExhibitionInfo({
        title,
        description,
        previewImg,
        animationType,
        roomId,
        isPrivate,
        password,
        newPassword,
        showOnHome,
        published,
        slideshowAutoStart,
      });
      setExhibitionSettings({
        frame,
        theme,
        silhouette,
        music,
        randomMusic: res.data.roomSettings?.music === 100,
        light,
        lightColor,
        silhouettes: indices,
        colorCode,
        ceiling,
        wall,
        floor,
        background,
        objectLight,
        directionalLightIntensity,
        directionalLightColorTheme,
        directionalLightColorCode,
        spotLightIntensity,
        spotLightColorTheme,
        spotLightColorCode,
        frameColor,
        matColor,
        fogColor,
      });
    } else {
      navigate('/not-found');
    }
  };

  // 閲覧パスワードが設定されている場合はパスワード入力モーダルを表示
  useEffect(() => {
    setShowExitModal(false);
    getRoomData();
  }, []);

  const canvasRef = useRef();

  useEffect(() => {
    // ピンチズームを無効化
    const handleTouchMove = (event) => {
      if (event.touches.length > 1) {
        event.preventDefault();
      }
    };
    document.addEventListener('touchmove', handleTouchMove, { passive: false });
    // Canvasコンポーネントがアンマウントされたときにリソースを解放
    return () => {
      if (canvasRef.current) {
        const { gl } = canvasRef.current;
        if (gl) {
          gl.forceContextLoss();
          gl.dispose();
          canvasRef.current = null;
        }
      }
      document.removeEventListener('touchmove', handleTouchMove);
    };
  }, [roomId]);

  const showPrevItem = () => {
    setIsActive(true);

    if (currentItemId > 0) {
      setTimeout(() => {
        setCurrentItemId(currentItemId - 1);
      }, 200);
    }
    invalidate();
  };
  const showNextItem = () => {
    setIsActive(true);

    if (currentItemId < exhibitionItems?.length - 1) {
      setTimeout(() => {
        setCurrentItemId(currentItemId + 1);
      }, 200);
    }
    invalidate();
  };

  const handlers = useSwipeable({
    onSwiped: (event) => {
      console.log(event);
      if (event.dir == 'Left') {
        //左にスワイプしたときに発火するイベント
        showNextItem();
      }
      if (event.dir == 'Right') {
        //右にスワイプしたときに発火するイベント
        showPrevItem();
      }
    },

    trackMouse: true,
  });

  const handleUnLockPassword = async (password) => {
    try {
      await publicRequest.post(`/rooms/unlock/${room?.id}`, {
        password,
      });
      const casUnlocked = localStorage.getItem('cas-exhibition-unlocked');

      // すでにlocalStorageある場合とない場合
      if (casUnlocked) {
        // JSONをパースしてオブジェクトに変換
        const casUnlockedObj = JSON.parse(casUnlocked);
        casUnlockedObj[room?.id] = 1;
        localStorage.setItem(
          'cas-exhibition-unlocked',
          JSON.stringify(casUnlockedObj)
        );
      } else {
        localStorage.setItem(
          'cas-exhibition-unlocked',
          JSON.stringify({
            [room?.id]: 1,
          })
        );
      }
      setIsLocked(false);
    } catch (err) {
      console.error(err);
      errorToast(`${err.response.data.message}`);
    }
  };

  //webglのprogressに応じてanimationを管理する
  const { active, progress, errors, item, loaded, total } = useProgress();
  let timeoutId = null;

  useEffect(() => {
    // progressが100になった場合の処理
    if (progress === 100) {
      // 2秒後にチェックするためのタイマーを設定
      timeoutId = setTimeout(() => {
        setSkipped(true);
      }, 2000);
    } else {
      // progressが100以外になったらタイマーをクリアし、skippedをfalseに戻す
      clearTimeout(timeoutId);
      setSkipped(false);
    }

    // クリーンアップ関数でタイマーをクリア
    return () => {
      clearTimeout(timeoutId);
    };
  }, [progress]);

  useEffect(() => {
    console.log('errors=====>', errors);
  }, [errors]);

  const openMatureContentWarningModal = () => {
    if (matureContentModalRef.current) {
      matureContentModalRef.current.showModal();
    }
  };

  useEffect(() => {
    if (hasMatureContent) {
      openMatureContentWarningModal();
    }
  }, [hasMatureContent]);

  useEffect(() => {
    if (!showMatureContent) {
      //mature contentを表示しないと回答したためitemsからisMatureContent:trueのものを除外する
      const filteredItems = exhibitionItems.filter(
        (item) => !item.isMatureContent
      );
      setExhibitionItems(filteredItems);
    }
  }, [showMatureContent]);

  useEffect(() => {
    if (user?.userPlans?.length > 0) {
      const productIds = user.userPlans.map(
        (item) => item.SubscriptionPlans.productId
      );
      if (
        productIds.includes(PREMIUM_MEMBER_ID) ||
        productIds.includes(VIP_MEMBER_ID)
      ) {
        return;
      }
      setIsFreeMember(true);
    }
    setIsFreeMember(true);
  }, [user]);

  return (
    <>
      {isLocked ? (
        <main className='flex h-full flex-col'>
          <div className='flex flex-auto'>
            <ExhibitionPasswordModal
              isShow={true}
              onSubmit={handleUnLockPassword}
              returnUrl={`/lobby/${room?.user?.username}`}
            />
          </div>
        </main>
      ) : (
        <div className='relative h-full w-full bg-black' {...handlers}>
          <div
            className={`absolute inset-0 h-full w-full transition-all duration-[2s] ease-out ${
              skipped && answered ? 'z-0 opacity-0' : 'opacity-1 z-10'
            }`}
          >
            {/* mobile */}
            {room?.animationType && (
              <video
                playsInline
                loop
                autoPlay
                controls={false}
                preload='auto'
                muted
                className={`h-full w-full object-cover md:hidden`}
                src={`/assets/transition_animation/transition${room?.animationType}_mobile.mp4`}
                onLoadedData={() => setVideoLoaded(true)}
              >
                not support
              </video>
            )}
            {/* desktop */}
            {room?.animationType && (
              <video
                playsInline
                loop
                autoPlay
                controls={false}
                preload='auto'
                muted
                className={`hidden h-full w-full object-cover md:block`}
                src={`/assets/transition_animation/transition${room?.animationType}.mp4`}
                onLoadedData={() => setVideoLoaded(true)}
              >
                not support
              </video>
            )}

            <div className='absolute left-0 right-0 top-[49%] text-center font-bold text-white'>
              Loading<span className='animate-dot-1'>.</span>
              <span className='animate-dot-2'>.</span>
              <span className='animate-dot-3'>.</span>
            </div>
          </div>

          <div
            className={`fixed w-full transition-all duration-[3s] ease-in md:h-full ${
              skipped && answered ? 'opacity-1 z-10' : 'z-0 opacity-0'
            } 
            ${
              zoomItemImageUrl
                ? 'animate-fade animate-delay-[500ms] animate-reverse animate-duration-[2500ms] animate-once animate-ease-out'
                : ''
            }
            ${isFreeMember ? 'h-[calc(100%-96px)]' : 'h-full'}
            `}
          >
            <KeyboardControls
              map={[
                { name: 'leftward', keys: ['ArrowLeft'] },
                { name: 'rightward', keys: ['ArrowRight'] },
              ]}
            >
              <Canvas
                ref={canvasRef}
                shadows
                dpr={dpr}
                camera={{ fov: 70, position: [0, 2, 15] }}
                gl={{ preserveDrawingBuffer: true }}
                // performance={{
                //   current: 1, // 現在のパフォーマンス指標（通常は1）
                //   min: 0.1, // 最低DPRは0.5　フレームレートが低い時にどこまでパフォーマンスを落とすか
                //   max: 0.5, // 最高DPRは1　最大パフォーマンスの時のフレームレート
                //   debounce: 300, // 300ms毎にパフォーマンスをチェックして調整
                // }}
                frameloop='demand'
              >
                <PerformanceMonitor
                  bounds={
                    (refreshRate) =>
                      refreshRate > 90
                        ? [50, 90] // 高リフレッシュレートデバイス向け
                        : [50, 60] // 通常のデバイス向け
                  }
                  // bounds={(refreshRate) => [70, 80]} //debug
                  threshold={0.8} // 80%の計測が上下限に達すると発火
                  iterations={10} // 10回計測
                  onIncline={() => {
                    // パフォーマンスが良くなったとき
                    setMode('HIGH');
                    setDpr(window.devicePixelRatio || 1);
                  }}
                  onDecline={() => {
                    // パフォーマンスが悪くなったとき
                    setMode('LOW');
                    setDpr(
                      window.devicePixelRatio
                        ? window.devicePixelRatio * 0.6
                        : 0.6
                    ); // ピクセル比がundefinedなら0.6
                  }}
                />
                {/* シーンが重くなるとDPR（解像度）を下げ、軽くなるとDPRを戻す */}
                {/* <AdaptiveDpr pixelated minDpr={0.1} maxDpr={0.5} /> */}
                {process.env.REACT_APP_ENV === 'development' && (
                  <Perf position='top-center' />
                )}
                {(() => {
                  switch (exhibitionSettings?.theme) {
                    case 'DARK':
                      return <CustomColorRoom bgColor={'#191920'} />;

                    case 'CYBERPUNK':
                      return <CyberPunkRoom />;
                    case 'CLASSIC':
                      return <ClassicRoom />;
                    case 'CUSTOM':
                      return (
                        <CustomColorRoom
                          bgColor={exhibitionSettings?.colorCode}
                        />
                      );
                    case 'SPACE':
                      return <Space />;
                    case 'SKY':
                      return <Sky />;
                    case 'MUSEUM':
                      return <Museum />;
                    case 'MARBLE':
                      return <Marble />;
                    default:
                      return <CustomColorRoom bgColor={'#191920'} />;
                  }
                })()}
              </Canvas>
            </KeyboardControls>

            {(videoLoaded || room.animationType == 0) &&
              skipped &&
              answered && (
                <Overlay
                  maxIndex={exhibitionItems?.length - 1}
                  room={room}
                  showExitModal={showExitModal}
                  isFreeMember={isFreeMember}
                />
              )}

            {selectedItem && (
              <ItemModalInGallery
                item={selectedItem}
                setSelectedItem={setSelectedItem}
                setInquiryItem={setInquiryItem}
                roomId={roomId}
              />
            )}

            {isFreeMember && (
              <div className='h-[96px] bg-white md:hidden'>
                <Link to={'/'}>
                  <img
                    src='/assets/img/ad-home.webp'
                    alt='CAS Home'
                    className='h-full w-full object-contain'
                  />
                </Link>
              </div>
            )}
          </div>
          {zoomItemImageUrl && (
            <FullScreenImg
              onClose={() => {
                setZoomItemImageUrl(null);
              }}
              imgUrl={zoomItemImageUrl}
              delay={true}
            />
          )}

          <InquiryItemModal
            user={room?.user}
            item={inquiryItem}
            setInquiryItem={setInquiryItem}
          />

          <dialog
            ref={matureContentModalRef}
            id='mature_content_warning_modal'
            className='modal'
          >
            <div className='modal-box flex max-w-xl flex-col gap-4'>
              <div className='text-lg font-bold'>
                <Trans i18nKey={'mature.ttl'} />
              </div>
              <div className='flex flex-col gap-5'>
                <p>
                  <Trans i18nKey={'mature.desc'} />
                </p>
              </div>

              <div className='inline-flex items-center justify-start'>
                <div className='text-base font-bold leading-normal'>
                  <Trans i18nKey={'mature.select'} />
                </div>
              </div>
              <div className='flex gap-2'>
                <input
                  type='radio'
                  id='hide'
                  name='preference'
                  className='radio '
                  checked={preference === 'hide'}
                  onChange={() => setPreference('hide')}
                />
                <label htmlFor='hide' className='text-md cursor-pointer'>
                  <Trans i18nKey={'mature.hide'} />
                </label>
              </div>
              <div className='flex gap-2'>
                <input
                  type='radio'
                  id='show'
                  name='preference'
                  className='radio'
                  checked={preference === 'show'}
                  onChange={() => setPreference('show')}
                />
                <label htmlFor='show' className='text-md cursor-pointer'>
                  <Trans i18nKey={'mature.show'} />
                </label>
              </div>
              <div>
                <form method='dialog'>
                  {/* if there is a button in form, it will close the modal */}
                  <button
                    className='inline-flex h-12 w-full items-center justify-center gap-2 rounded-lg bg-[#2b3440] px-4 py-[26px] text-sm font-medium leading-tight text-[#d7dde4]'
                    onClick={() => {
                      if (preference === 'hide') {
                        setShowMatureContent(false);
                      } else if (preference === 'show') {
                        setShowMatureContent(true);
                      }
                      setAnswered(true);
                    }}
                  >
                    <Trans i18nKey={'btn.older'} />
                  </button>
                  <div className='divider'></div>
                  <button
                    className='inline-flex h-12 w-full items-center justify-center gap-2 rounded-lg bg-[#f2f2f2] px-4 py-[26px] text-sm font-medium leading-tight'
                    onClick={() => {
                      setShowMatureContent(false);
                      setAnswered(true);
                    }}
                  >
                    <Trans i18nKey={'btn.under'} />
                  </button>
                  <div className=' mb-4 mt-2 text-center text-xs font-medium leading-none text-neutral-500'>
                    <Trans i18nKey={'mature.hide-note'} />
                  </div>
                  <div className='flex items-center justify-center pt-6'>
                    <Link
                      to={`/lobby/${room?.user?.username}`}
                      className='btn btn-ghost'
                    >
                      <Trans i18nKey={'btn.back-lobby'} />
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </dialog>
        </div>
      )}
    </>
  );
};

export default Room;
