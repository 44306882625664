import { Clone, useGLTF } from '@react-three/drei';
import { useEffect, useLayoutEffect } from 'react';

const Moon = ({ ...props }) => {
  const { scene, materials } = useGLTF(
    'https://data.curatedartshow.com/models/space/moon.glb'
  );

  useEffect(() => {
    return () => {
      //リソースのクリーンアップ処理
      useGLTF.clear('https://data.curatedartshow.com/models/space/moon.glb');
    };
  }, []);

  useLayoutEffect(() => {
    // console.log('chair:', materials);
    // materials['Street_Light_Mat'].color.set('#555');
    // materials['Glass'].color.set('#fff');
    //
    // materials['Wet Coarse Concrete_material'].roughness = 0.5;
    // materials['Wet Coarse Concrete_material'].metalness = 0.5;
    // materials['Dark Brown Ceramic_material'].color.set('black');
    // materials['Dark Brown Ceramic_material'].roughness = 5;
    // materials['Dark Brown Ceramic_material'].metalness = 0.1;
    // materials['Glass'].emissive.set('white');

    scene.traverse((obj) => {
      if (obj.isMesh) obj.castShadow = obj.receiveShadow = true;
    });

    // return () => {
    //   // マテリアルの解放
    //   Object.values(materials).forEach((material) => {
    //     material.dispose();
    //   });

    //   // シーンからオブジェクトの削除
    //   scene.traverse((obj) => {
    //     if (obj.isMesh) {
    //       obj.geometry.dispose();
    //       obj.material.dispose();
    //       scene.remove(obj);
    //     }
    //   });
    // };
  }, []);

  return <Clone object={scene} {...props} castShadow />;
};

export default Moon;
