import { XMarkIcon } from '@heroicons/react/24/outline';
import React from 'react';
import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';

export const DiscardItemModal = ({ handleSave, published }) => {
  return (
    <>
      <dialog id='discard_item_modal' className='modal'>
        <div className='modal-box'>
          <h3 className='text-lg font-bold'>
            <Trans i18nKey={'artwork-modal.discard-upload-ttl'} />
          </h3>
          <p className='py-4'>
            <Trans i18nKey={'artwork-modal.discard-upload-desc'} />
          </p>
          <form method='dialog'>
            {/* if there is a button in form, it will close the modal */}
            <button className='btn btn-circle btn-ghost btn-sm absolute right-2 top-2'>
              <XMarkIcon className='h-5 w-5' />
            </button>
          </form>
          <div className='modal-action'>
            {!published && (
              <button
                className='btn btn-outline btn-primary'
                onClick={handleSave}
              >
                <Trans i18nKey={'btn.save-unpublished'} />
              </button>
            )}
            <Link to={'/dashboard'} className='btn btn-primary'>
              <Trans i18nKey={'btn.discard'} />
            </Link>
          </div>
        </div>
      </dialog>
    </>
  );
};
