import { useQuery } from '@tanstack/react-query';
import { Accordion } from './components/Accordion';

import { Fragment } from 'react';
import LoadingSpinner from '../../components/LoadingSpinner';
import { getAllFaqCategories } from '../../services/Admin';
import { Link } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';

export const Faqs = () => {
  const { i18n } = useTranslation();
  //カテゴリ分けに対応させておく
  const { data: faqCategories, isLoading: isFaqCategoriesLoading } = useQuery({
    queryFn: () => getAllFaqCategories(i18n?.language),
    queryKey: ['faq_categories', i18n?.language],
  });

  return (
    <div className='min-h-[60vh] flex-auto'>
      <div className='container m-auto flex-auto px-4'>
        <div className='mx-auto mb-10 flex h-full w-full max-w-[39.3125rem] flex-col justify-center py-10'>
          <h1 className='mb-8 text-3xl font-extrabold'>
            <Trans i18nKey={'faqs.title'} />
          </h1>
          <div className='flex flex-col gap-4'>
            {faqCategories?.map((faqCategory) => (
              <Fragment key={faqCategory.id}>
                {faqCategory.faqs?.map((faq) => (
                  <Accordion key={faq.id} faq={faq} />
                ))}
              </Fragment>
            ))}
          </div>
          <div className='divider my-10 p-0' />
          <p className='pb-5'>
            <Trans i18nKey={'faqs.contact-label'} />
          </p>
          <Link to={'/contact'} className='btn btn-neutral w-fit'>
            <Trans i18nKey={'faqs.contact'} />
          </Link>
        </div>
      </div>
      {isFaqCategoriesLoading && <LoadingSpinner />}
    </div>
  );
};
