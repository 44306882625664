import { useGLTF } from '@react-three/drei';
import { Suspense, useLayoutEffect, useState } from 'react';

const PictureFrame71 = ({ color, ...props }) => {
  const { scene, materials } = useGLTF(
    'https://data.curatedartshow.com/models/frames/koiframe3.glb'
  );

  const [geometry, setGeometry] = useState();

  if (!geometry) {
    const cloneScene = scene.clone(true);
    setGeometry(cloneScene);
  }

  useLayoutEffect(() => {
    // console.log(materials);
    // materials['Red Spotted Paint_material'].color.set('#a30000');
    materials['Red Spotted Paint_material'].metalness = 1;
    materials['Red Spotted Paint_material'].roughness = 2;
    materials['Dirty Grey Stone Layers_material'].color.set('#8e8e8e');
    materials['Dirty Grey Stone Layers_material'].metalness = 0.5;
    materials['Dirty Grey Stone Layers_material'].roughness = 2;

    materials['Wet Blue Clay_material.001'].color.set('#969696');
    materials['Wet Blue Clay_material.001'].metalness = 0;
    materials['Wet Blue Clay_material.001'].roughness = 4;

    // materials['default_material107.003'].color.set('#7c87cd');
    // materials['default_material107.003'].metalness = 1;
    // materials['default_material107.003'].roughness = 0;
    materials['paper_pattern_material'].color.set('#9a5c00');
    materials['paper_pattern_material'].metalness = 0.5;
    materials['paper_pattern_material'].roughness = 0.2;

    geometry.traverse((obj) => {
      if (obj.isMesh) obj.castShadow = obj.receiveShadow = true;
    });
  }, []);

  return (
    <Suspense fallback={null}>
      <primitive object={geometry} {...props} />
    </Suspense>
  );
};

export default PictureFrame71;
