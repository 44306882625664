import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { XMarkIcon, PlusIcon } from '@heroicons/react/24/outline';
import { TrashIcon } from '@heroicons/react/24/solid';
import dayjs from 'dayjs';
import { ReactSortable } from 'react-sortablejs';

export const ArrangeArtworks = ({ selectedArtworks, moveTo }) => {
  const { i18n } = useTranslation();
  const [arrangedArtworks, setArrangedArtworks] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);

  const location = useLocation();
  useEffect(() => {
    if (location.pathname.split('/')[1] == 'create-exhibition') {
      setIsEditMode(false);
    } else {
      setIsEditMode(true);
    }
  }, [location]);

  // 作品追加日
  const addedDate = (date) => {
    if (date) {
      const dayjsLocal = dayjs(date);
      return i18n.language === 'ja'
        ? dayjsLocal.format('YYYY年M月D日')
        : dayjsLocal.format('MMM D, YYYY');
    }
    return 'NOT FOUND';
  };

  // 選択した作品を削除
  const removeItem = (item) => {
    const newArrangedArtworks = arrangedArtworks.filter(
      (artwork) => artwork.id !== item.id
    );
    setArrangedArtworks(newArrangedArtworks);
  };

  useEffect(() => {
    if (selectedArtworks) {
      setArrangedArtworks(selectedArtworks);
    }
  }, [selectedArtworks]);

  return (
    <dialog id='arrange_artworks' className='modal'>
      <div className='modal-box h-full max-h-none w-full max-w-none rounded-none px-0 pb-0 pt-2 md:max-h-[48rem] md:max-w-[50.375rem] md:rounded-2xl md:p-6'>
        <div className='flex h-full flex-col gap-3'>
          <div className='flex items-center justify-between px-4 md:hidden'>
            <Link to={'/dashboard'} className='p-4 focus-visible:outline-none'>
              <XMarkIcon className='h-6 w-6' />
            </Link>
            <h1 className='font-semibold'>
              {isEditMode ? (
                <Trans i18nKey={'gallery.arrange-artworks.edit-exhibition'} />
              ) : (
                <Trans i18nKey={'gallery.arrange-artworks.new-exhibition'} />
              )}
            </h1>
            <span
              className={`px-4 py-2 text-sm font-semibold text-primary ${
                arrangedArtworks.length < 1 ? 'text-gray-400' : 'text-primary'
              }`}
              onClick={() => {
                if (0 < arrangedArtworks.length) {
                  moveTo('ExhibitionSettings', arrangedArtworks);
                }
              }}
            >
              <Trans i18nKey={'btn.next'} />
            </span>
          </div>
          <h2 className='mt-3 px-4 text-lg font-bold'>
            <Trans i18nKey={'gallery.arrange-artworks.ttl'} />
          </h2>
          {!arrangedArtworks ? (
            <span className='loading loading-spinner text-primary'></span>
          ) : (
            <div className='flex flex-col gap-1 px-4 text-sm'>
              <p>
                <span className='font-bold'>{arrangedArtworks.length}</span>{' '}
                <Trans i18nKey={'gallery.arrange-artworks.item-number'} />
              </p>
              <p>
                <Trans i18nKey={'gallery.arrange-artworks.drag'} />
              </p>
            </div>
          )}
          <div className='flex h-full flex-col gap-3 overflow-hidden rounded-lg bg-base-200 p-4'>
            {!arrangedArtworks ? (
              <span className='loading loading-spinner text-primary'></span>
            ) : (
              <>
                <div className='no-scrollbar h-full overflow-scroll'>
                  <ReactSortable
                    list={arrangedArtworks}
                    setList={setArrangedArtworks}
                    handle='.sort-handle'
                    className='flex h-full flex-col gap-[0.625rem] overflow-scroll'
                  >
                    {arrangedArtworks?.map((item, index) => {
                      return (
                        <div
                          className='flex items-center justify-between gap-2 rounded-xl border border-gray-200 bg-white px-4 py-3'
                          key={`arrange-artworks_${item.id}`}
                        >
                          <div className='flex w-full items-center gap-1 overflow-scroll whitespace-nowrap lg:max-w-[calc(100%-110px)] lg:gap-4'>
                            <div className='sort-handle relative cursor-grab'>
                              <svg
                                width='24'
                                height='24'
                                viewBox='0 0 24 24'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <g id='Drag Icon'>
                                  <g id='Vector'>
                                    <path
                                      fillRule='evenodd'
                                      clipRule='evenodd'
                                      d='M13.5 6C13.5 5.17157 14.1716 4.5 15 4.5C15.8284 4.5 16.5 5.17157 16.5 6C16.5 6.82843 15.8284 7.5 15 7.5C14.1716 7.5 13.5 6.82843 13.5 6ZM13.5 12C13.5 11.1716 14.1716 10.5 15 10.5C15.8284 10.5 16.5 11.1716 16.5 12C16.5 12.8284 15.8284 13.5 15 13.5C14.1716 13.5 13.5 12.8284 13.5 12ZM13.5 18C13.5 17.1716 14.1716 16.5 15 16.5C15.8284 16.5 16.5 17.1716 16.5 18C16.5 18.8284 15.8284 19.5 15 19.5C14.1716 19.5 13.5 18.8284 13.5 18Z'
                                      fill='#1F2A37'
                                    />
                                    <path
                                      fillRule='evenodd'
                                      clipRule='evenodd'
                                      d='M7 6C7 5.17157 7.67157 4.5 8.5 4.5C9.32843 4.5 10 5.17157 10 6C10 6.82843 9.32843 7.5 8.5 7.5C7.67157 7.5 7 6.82843 7 6ZM7 12C7 11.1716 7.67157 10.5 8.5 10.5C9.32843 10.5 10 11.1716 10 12C10 12.8284 9.32843 13.5 8.5 13.5C7.67157 13.5 7 12.8284 7 12ZM7 18C7 17.1716 7.67157 16.5 8.5 16.5C9.32843 16.5 10 17.1716 10 18C10 18.8284 9.32843 19.5 8.5 19.5C7.67157 19.5 7 18.8284 7 18Z'
                                      fill='#1F2A37'
                                    />
                                  </g>
                                </g>
                              </svg>
                            </div>
                            <div className='h-16 w-16 flex-shrink-0 lg:h-20 lg:w-20'>
                              <Link
                                to={`/items/edit/${item.id}`}
                                target='_blank'
                              >
                                <img
                                  src={item.itemUrl}
                                  alt={item.title}
                                  className='mx-auto h-full max-h-[5rem] w-full max-w-[5rem] object-contain'
                                />
                              </Link>
                            </div>
                            {/* SP表示 */}
                            <div className='lg:hidden'>
                              <p className='text-xs font-bold'>{item.title}</p>
                              <p className='text-xs'>
                                {item?.creator?.displayName}
                              </p>
                              <p className='text-xs'>{item?.createdYear}</p>
                              <p className='flex gap-2 text-xs'>
                                <span className='font-semibold text-[#1F2A3799]/60'>
                                  <Trans i18nKey={'gallery.added'} />
                                </span>
                                {addedDate(item.createdAt)}
                              </p>
                            </div>
                            {/* PC表示 */}
                            <p className='hidden text-sm font-bold lg:block'>
                              {item.title}
                            </p>
                            <p className='hidden text-sm lg:block'>
                              {item?.creator?.displayName}
                            </p>
                            <p className='hidden text-sm lg:block'>
                              {item?.createdYear}
                            </p>
                            <p className='hidden text-sm lg:block'>
                              {addedDate(item.createdAt)}
                            </p>
                          </div>

                          <button
                            type='button'
                            className='btn btn-circle btn-sm lg:w-auto lg:px-3'
                            onClick={() => removeItem(item)}
                          >
                            <TrashIcon className='h-5 w-5' />
                            <span className='hidden lg:block'>
                              <Trans i18nKey={'btn.remove'} />
                            </span>
                          </button>
                        </div>
                      );
                    })}
                  </ReactSortable>
                </div>
                <button
                  className='btn btn-outline btn-sm self-center'
                  onClick={() => moveTo('', arrangedArtworks)}
                >
                  <PlusIcon className='h-5 w-5' />
                  <Trans i18nKey={'btn.add-artwork'} />
                </button>
              </>
            )}
          </div>
          <div className='mt-3 hidden justify-end gap-2 md:flex'>
            <button
              className='btn'
              onClick={() =>
                document.getElementById('discard_exhibition_modal').showModal()
              }
            >
              <Trans i18nKey={'btn.cancel'} />
            </button>
            <button
              className='btn btn-primary'
              onClick={() => moveTo('ExhibitionSettings', arrangedArtworks)}
              disabled={arrangedArtworks.length < 1}
            >
              <Trans i18nKey={'btn.edit-exhib'} />
            </button>
          </div>
          {/* <div className='hidden justify-end gap-2 md:flex'>
            <Link to={'/dashboard'} className='btn'>
              Cancel
            </Link>
            <button
              className='btn btn-primary'
              onClick={() =>
                proceedNext('ArrangeArtworks', [...selectedItemIds])
              }
              disabled={selectedItemIds.size < 1}
            >
              Next
            </button>
          </div> */}
        </div>
      </div>
    </dialog>
  );
};
