import { useGLTF } from '@react-three/drei';
import { Suspense, useLayoutEffect, useState } from 'react';
import * as THREE from 'three';

const PictureFrame73 = ({ color, ...props }) => {
  const { scene, materials } = useGLTF(
    'https://data.curatedartshow.com/models/frames/koiframe5.glb'
  );

  const [geometry, setGeometry] = useState();

  if (!geometry) {
    const cloneScene = scene.clone(true);
    setGeometry(cloneScene);
  }

  useLayoutEffect(() => {
    // console.log(materials);
    // materials['fdm_plastic_infill_material.001'].color.set('#856f06');
    materials['fdm_plastic_infill_material.001'].metalness = 1;
    materials['fdm_plastic_infill_material.001'].roughness = 0.6;
    // materials['Green Marble_material'].color.set('#141414');
    materials['Green Marble_material'].metalness = 0;
    materials['Green Marble_material'].roughness = 0;

    materials['Old Polystyrene_material'].color.set('#c9c9c9');
    materials['Old Polystyrene_material'].metalness = 0.8;
    materials['Old Polystyrene_material'].roughness = 2;
    materials['Old Polystyrene_material'].normalScale = new THREE.Vector2(0, 0);

    // materials['default_material107.003'].color.set('#7c87cd');
    // materials['default_material107.003'].metalness = 1;
    // materials['default_material107.003'].roughness = 0;
    materials['anodic_powder_paint_material'].color.set('#7ccab3');
    materials['anodic_powder_paint_material'].metalness = 1;
    materials['anodic_powder_paint_material'].roughness = 1;

    geometry.traverse((obj) => {
      if (obj.isMesh) obj.castShadow = obj.receiveShadow = true;
    });
  }, []);

  return (
    <Suspense fallback={null}>
      <primitive object={geometry} {...props} />
    </Suspense>
  );
};

export default PictureFrame73;
