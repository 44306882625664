import { useGLTF } from '@react-three/drei';
import { Suspense, useLayoutEffect, useState } from 'react';
import * as THREE from 'three';

const PictureFrame68 = ({ color, ...props }) => {
  const { scene, materials } = useGLTF(
    'https://data.curatedartshow.com/models/frames/jenaframe11.glb'
  );

  const [geometry, setGeometry] = useState();

  if (!geometry) {
    const cloneScene = scene.clone(true);
    setGeometry(cloneScene);
  }

  useLayoutEffect(() => {
    // console.log(materials);
    materials['Tinted Facetted_material'].color.set('#000000');
    materials['Tinted Facetted_material'].metalness = 0;
    materials['Tinted Facetted_material'].roughness = 0.15;
    materials['printed_glass_material2'].color.set('#cfcfcf');
    materials['printed_glass_material2'].metalness = 0;
    materials['printed_glass_material2'].roughness = 0;
    materials['printed_glass_material2'].emissiveIntensity = 0;
    materials['printed_glass_material2'].normalScale = new THREE.Vector2(0, 0);
    materials['Small Scratch Glass_material'].color.set('#c1c0c0');
    materials['Small Scratch Glass_material'].metalness = 0;
    materials['Small Scratch Glass_material'].roughness = 0;

    // materials['default_material107.003'].color.set('#7c87cd');
    // materials['default_material107.003'].metalness = 1;
    // materials['default_material107.003'].roughness = 0;
    materials['Tinted Facetted_material2'].color.set('#292929');
    materials['Tinted Facetted_material2'].metalness = 0;
    materials['Tinted Facetted_material2'].roughness = 0;

    geometry.traverse((obj) => {
      if (obj.isMesh) obj.castShadow = obj.receiveShadow = true;
    });
  }, []);

  return (
    <Suspense fallback={null}>
      <primitive object={geometry} {...props} />
    </Suspense>
  );
};

export default PictureFrame68;
