import { useGLTF } from '@react-three/drei';
import { Suspense, useLayoutEffect, useState } from 'react';

const PictureFrame67 = ({ color, ...props }) => {
  const { scene, materials } = useGLTF(
    'https://data.curatedartshow.com/models/frames/jenaframe10.glb'
  );

  const [geometry, setGeometry] = useState();

  if (!geometry) {
    const cloneScene = scene.clone(true);
    setGeometry(cloneScene);
  }

  useLayoutEffect(() => {
    // console.log(materials);
    // materials['Dark Shiny Leather_material'].color.set('#686868');
    materials['Dark Shiny Leather_material'].metalness = 0.5;
    materials['Dark Shiny Leather_material'].roughness = 0.5;
    materials['Heavily Brushed Iron_material'].color.set('#212121');
    materials['Heavily Brushed Iron_material'].metalness = 0.5;
    materials['Heavily Brushed Iron_material'].roughness = 3;
    materials['Soft Yellowish Leather_material'].color.set('#d2c7b0');
    materials['Soft Yellowish Leather_material'].metalness = 0.8;
    materials['Soft Yellowish Leather_material'].roughness = 2;

    // materials['default_material107.003'].color.set('#7c87cd');
    // materials['default_material107.003'].metalness = 1;
    // materials['default_material107.003'].roughness = 0;
    materials['carbon_fiber_material'].color.set('#840909');
    materials['carbon_fiber_material'].metalness = 0.5;
    materials['carbon_fiber_material'].roughness = 1;

    geometry.traverse((obj) => {
      if (obj.isMesh) obj.castShadow = obj.receiveShadow = true;
    });
  }, []);

  return (
    <Suspense fallback={null}>
      <primitive object={geometry} {...props} />
    </Suspense>
  );
};

export default PictureFrame67;
