import { authRequest, publicRequest } from '../requestMethods';

export const fetchLobbyUser = async (username) => {
  try {
    const response = await publicRequest.get(`/users/username/${username}`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const checkInquiryEmail = async (id) => {
  try {
    const response = await publicRequest.get(`/users/${id}/inquiry-email`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
