import React from 'react';

const Light5 = ({ color, length = 5 }) => {
  let meshColor = [5, 2, 0.5];
  switch (color) {
    case 'RED':
      meshColor = [10, 1, 1];
      break;
    case 'BLUE':
      meshColor = [1, 1, 10];
      break;
    case 'WHITE':
      meshColor = [4, 4, 4];
      break;
    case 'YELLOW':
      meshColor = [4, 4, 1];
      break;
    case 'GREEN':
      meshColor = [0.6, 10, 0.6];
      break;
    case 'PINK':
      meshColor = [10, 0.5, 10];
      break;
    default:
      meshColor = [4, 4, 4];
      break;
  }
  return (
    <>
      {Array(2 * length)
        .fill()
        .map((_, index) => (
          <mesh
            scale={1}
            position={[index * 5 + 0.4 - 10, 1.8, 0]}
            // rotation-z={Math.sin(index) * 0.3}
            // rotation={[-Math.PI / 2, 0, Math.PI / 2.5]}
            key={index}
          >
            <circleGeometry args={[2, 50]} />
            {/* <meshStandardMaterial color={[10, 10, 10]} toneMapped={false} /> */}
            <meshStandardMaterial toneMapped={false} color={meshColor} />
          </mesh>
        ))}
    </>
  );
};

export default Light5;
