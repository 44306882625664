import { useSelector } from 'react-redux';
import LoadingSpinner from './LoadingSpinner';

const UserSuspendConfirmModal = ({ handleSuspend }) => {
  const { isLoading } = useSelector((state) => state.isLoading);

  return (
    <dialog id='suspend_confirm_modal' className='modal'>
      <div className='modal-box !max-w-[32rem] !p-6'>
        <h3 className='mb-6 mt-0 text-lg font-bold'>Archive confirmation</h3>
        <p className='my-0 pb-6'>
          Are you sure you want to archive this user? Archiving the user will
          have the following effects:
        </p>
        <ul className='list-disc pb-5 pl-4'>
          <li>
            All exhibitions and artworks created by this user will be set to
            private.
          </li>
          <li>
            If the user attempts to access CAS, they will be automatically
            logged out.
          </li>
        </ul>
        <p className='pb-5'>
          This action will restrict the user's access and visibility on the
          platform immediately.
        </p>
        <div className='modal-action mb-0 mt-6'>
          <form method='dialog'>
            <button className='btn mr-2'>Cancel</button>
            <button
              className='btn !bg-warning !text-error hover:!bg-warning hover:opacity-70'
              onClick={handleSuspend}
            >
              Archive
            </button>
          </form>
        </div>
      </div>
      {isLoading && <LoadingSpinner />}
    </dialog>
  );
};

export default UserSuspendConfirmModal;
