import React, { useEffect, useLayoutEffect, useRef } from 'react';
import { useTexture } from '@react-three/drei';
import * as THREE from 'three';

const Classic2Floor = () => {
  const textureProps = useTexture({
    map: `https://data.curatedartshow.com/texture/floors/TilesPortuguese3/basecolor.jpg`,
    normalMap: `https://data.curatedartshow.com/texture/floors/TilesPortuguese3/normal.jpg`,
    roughnessMap: `https://data.curatedartshow.com/texture/floors/TilesPortuguese3/roughness.jpg`,
    aoMap: `https://data.curatedartshow.com/texture/floors/TilesPortuguese3/ao.jpg`,
    displacementMap: `https://data.curatedartshow.com/texture/floors/Classic2/height.png`,

    // map: `https://data.curatedartshow.com/texture/floors/Marble006/basecolor.jpg`,
    // normalMap: `https://data.curatedartshow.com/texture/floors/Marble006/normal.jpg`,
    // roughnessMap: `https://data.curatedartshow.com/texture/floors/Marble006/roughness.jpg`,
    // displacementMap: `https://data.curatedartshow.com/texture/floors/Marble006/height.jpg`,
  });
  useLayoutEffect(() => {
    Object.values(textureProps).forEach((texture) => {
      texture.wrapS = THREE.RepeatWrapping;
      texture.wrapT = THREE.RepeatWrapping;
      texture.repeat.set(48, 48);
    });

    return () => {
      Object.values(textureProps).forEach((texture) => {
        texture.dispose();
      });
    };
  }, [textureProps]);

  const mesh = useRef();
  useEffect(() => {
    mesh.current.geometry.setAttribute(
      'uv2',
      new THREE.BufferAttribute(mesh.current.geometry.attributes.uv.array, 2)
    );
  }, []);

  return (
    <mesh
      receiveShadow
      rotation={[-Math.PI / 2, 0, 0]}
      ref={mesh}
      position={[0, -0.2, 0]}
    >
      <planeGeometry args={[200, 200]} />
      <meshStandardMaterial
        // displacementScale={0.2}
        // aoMapIntensity={1}
        // roughness={1}
        // metalness={0.5}
        // normalScale={new THREE.Vector2(0, 1)}
        displacementScale={0.2}
        aoMapIntensity={1}
        roughness={1}
        metalness={0.5}
        normalScale={new THREE.Vector2(1, 1)}
        {...textureProps}
      />
    </mesh>
  );
};

export default Classic2Floor;
