import {
  ArrowLeftOnRectangleIcon,
  Bars3Icon,
  ChevronLeftIcon,
  ChevronRightIcon,
  PencilSquareIcon,
  SpeakerWaveIcon,
  SpeakerXMarkIcon,
  Square2StackIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { PlayIcon, PauseIcon } from '@heroicons/react/24/solid';
import React, { useEffect, useMemo, useState, useRef, useContext } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import OGP from '../../components/OGP';
import { Trans, useTranslation } from 'react-i18next';
import { successToast } from '../../utils/toast';
import { ExhibitionContext } from '../../context/ExhibitionContext';
import { ExhibitionControllerContext } from '../../context/ExhibitionControllerContext';
import {
  CYBERPUNK_ATTRIBUTIONS,
  FRAME_ATTRIBUTIONS,
  LIGHTS_ATTRIBUTIONS,
  MARBLE_ATTRIBUTIONS,
  SPACE_ATTRIBUTIONS,
} from '../../data/attributions';
import AttributionModal from './components/AttributionModal';
import { invalidate } from '@react-three/fiber';

const Overlay = ({ maxIndex, room, showExitModal, isFreeMember = false }) => {
  const user = useSelector((state) => state.user.currentUser);
  const navigate = useNavigate();
  const {
    slideshowMode,
    setSlideshowMode,
    slideshowEnd,
    currentItemId,
    setCurrentItemId,
    currentItem,
    setSelectedItem,
    setIsActive,
    showInfo,
    setShowInfo,
    setInquiryItem,
  } = useContext(ExhibitionControllerContext);

  const { exhibitionItems, exhibitionInfo, exhibitionSettings } =
    useContext(ExhibitionContext);

  const [itemTitle, setItemTitle] = useState('');
  const [artistName, setArtistName] = useState('');
  const [attributions, setAttributions] = useState([]);

  const [isMuted, setIsMuted] = useState(false);
  const audioRef = useRef(null);

  const showPrevItem = () => {
    setIsActive(true);

    if (currentItemId > 0) {
      setTimeout(() => {
        setCurrentItemId(currentItemId - 1);
      }, 200);
    }
    invalidate();
  };
  const showNextItem = () => {
    setIsActive(true);

    if (currentItemId < maxIndex) {
      setTimeout(() => {
        setCurrentItemId(currentItemId + 1);
      }, 200);
    }

    invalidate();
  };

  useEffect(() => {
    setItemTitle(exhibitionItems[currentItemId]?.title);
    setArtistName(exhibitionItems[currentItemId]?.artistName ?? '');
  }, [currentItemId, exhibitionItems]);

  const audioSrc = useMemo(() => {
    if (!exhibitionSettings?.music) {
      return null;
    }
    const randomBgmIndex = Math.floor(Math.random() * 3) + 1;
    const musicIndex =
      exhibitionSettings?.music === 100
        ? randomBgmIndex
        : exhibitionSettings?.music;
    return `/assets/music/sample${musicIndex}.mp3`;
  }, [exhibitionSettings.music]);

  useEffect(() => {
    const handlePlayMusic = () => {
      if (audioRef.current) {
        audioRef.current.play().catch((error) => {
          console.error('Error playing audio:', error);
        });
      }
    };
    document.body.addEventListener('click', handlePlayMusic, { once: true });
    return () => {
      document.body.removeEventListener('click', handlePlayMusic);
    };
  }, []);

  const toggleMute = () => {
    setIsMuted((prev) => !prev);
    if (audioRef.current) {
      audioRef.current.muted = !audioRef.current.muted;
    }
  };

  const handleChangeSlideshowMode = (value) => {
    setSlideshowMode(value);
  };

  useEffect(() => {
    const tmpAttributions = [];
    //テーマに応じてattribution情報を取得
    switch (exhibitionSettings.theme) {
      case 'CYBERPUNK':
        tmpAttributions.push(...CYBERPUNK_ATTRIBUTIONS);
        break;
      case 'SPACE':
        tmpAttributions.push(...SPACE_ATTRIBUTIONS);
        break;
      case 'MARBLE':
        tmpAttributions.push(...MARBLE_ATTRIBUTIONS);
        break;
    }
    //exhibitionに設定しているframeに応じてattribution情報を取得
    if (exhibitionSettings.frame) {
      const exhibitionFrame = FRAME_ATTRIBUTIONS.find(
        (attr) => attr.frame === exhibitionSettings.frame
      );
      if (exhibitionFrame) {
        tmpAttributions.push(exhibitionFrame);
      }
    }

    //itemに設定しているframeに応じてattribution情報を取
    exhibitionItems.map((item) => {
      if (item.frame) {
        const itemFrame = FRAME_ATTRIBUTIONS.find(
          (attr) => attr.frame === item.frame
        );
        if (itemFrame) {
          tmpAttributions.push(itemFrame);
        }
      }
    });

    //使用しているobjectLightに応じてattribution情報を取得
    if (
      (exhibitionSettings.theme == 'DARK' ||
        exhibitionSettings.theme == 'CUSTOM') &&
      exhibitionSettings.objectLight
    ) {
      tmpAttributions.push(
        LIGHTS_ATTRIBUTIONS[exhibitionSettings.objectLight - 1]
      );
    }

    setAttributions(tmpAttributions);
  }, [exhibitionSettings.theme, exhibitionItems]);

  return (
    <div className={`${currentItem ? 'pointer-events-none invisible' : ''}`}>
      {/* top */}
      <div
        className={`absolute inset-0 h-full w-full bg-black transition-all duration-[2s] ease-out ${
          slideshowMode && slideshowEnd
            ? 'opacity-1 pointer-events-auto z-10'
            : 'pointer-events-none z-0 opacity-0'
        }`}
      />
      <p
        className={`bg-gallery-ui-2 absolute top-0 w-full px-[25px] py-2 text-center text-xs font-bold text-white backdrop-blur-[32px] md:text-sm`}
      >
        {room?.user?.displayName?.toUpperCase()}: {room.title}
      </p>
      <div className='absolute top-14 flex w-full items-center justify-between px-3 md:px-10'>
        <div className='flex-1'>
          {!slideshowMode && !room.isPrivate ? (
            <Link
              to={`/lobby/${room.user.username}`}
              className={`bg-gallery-ui-2 flex w-fit items-center rounded-full border border-[#868686] px-5 py-2 text-sm text-white backdrop-blur-[32px] hover:border-gray-50
          ${
            exhibitionInfo.theme === 'LIGHT'
              ? 'bg-gallery-ui-light border-gray-300'
              : 'bg-gallery-ui border-gray-400'
          }
            `}
            >
              <ArrowLeftOnRectangleIcon className='mr-1 h-5 w-5' />
              <p className='hidden md:block'>
                <Trans i18nKey={'btn.gallery-return'} />
              </p>
              <p className='md:hidden'>
                <Trans i18nKey={'btn.exit'} />
              </p>
            </Link>
          ) : (
            <div className='flex-1' />
          )}
        </div>

        <div className='flex flex-1 justify-center'>
          {slideshowMode ? (
            <button
              className={`bg-gallery-ui-2 flex items-center gap-2 rounded-full border border-[#868686] px-4 py-2 text-xs font-medium text-white backdrop-blur-[32px] hover:border-gray-50`}
              onClick={() => handleChangeSlideshowMode(false)}
            >
              <PauseIcon className='h-5 w-5 shrink-0' />
              Pause
            </button>
          ) : (
            <button
              className={`bg-gallery-ui-2 flex items-center gap-2 rounded-full border border-[#868686] px-4 py-2 text-xs font-medium text-white backdrop-blur-[32px] hover:border-gray-50`}
              onClick={() => handleChangeSlideshowMode(true)}
            >
              <PlayIcon className='h-5 w-5 shrink-0' />
              Auto-stroll
            </button>
          )}
        </div>
        {slideshowMode ? (
          <div className='flex-1' />
        ) : (
          <div className='flex flex-1 items-center justify-end space-x-2'>
            {user?.id === room?.userId && (
              <button
                onClick={() => {
                  navigate(`/edit-exhibition/${room?.roomId}`);
                }}
                className={`bg-gallery-ui-2 cursor-pointer rounded-full border border-[#868686] p-2 text-sm text-white backdrop-blur-[32px] hover:border-gray-50`}
              >
                <PencilSquareIcon className='h-5 w-5' />
              </button>
            )}
            <button
              className={`bg-gallery-ui-2 rounded-full border p-2 text-sm text-white backdrop-blur-[32px] hover:border-gray-50 ${
                !exhibitionSettings?.music && 'hidden'
              }
            ${
              exhibitionInfo.theme === 'LIGHT'
                ? 'bg-gallery-ui-light border-gray-300'
                : 'bg-gallery-ui border-gray-400'
            }
              `}
              onClick={toggleMute}
            >
              {isMuted ? (
                <SpeakerXMarkIcon className='h-5 w-5' />
              ) : (
                <SpeakerWaveIcon className='h-5 w-5' />
              )}
            </button>
            <button
              className={`bg-gallery-ui-2 rounded-full border p-2 text-sm text-white backdrop-blur-[32px] hover:border-gray-50 md:hidden ${
                exhibitionInfo.theme === 'LIGHT'
                  ? 'bg-gallery-ui-light border-gray-300'
                  : 'bg-gallery-ui border-gray-400'
              }
            `}
              onClick={() => setShowInfo(true)}
            >
              <Bars3Icon className='h-5 w-5' />
            </button>
            <button
              className={`bg-gallery-ui-2 hidden rounded-full border border-[#868686] px-5 py-2 text-sm text-white backdrop-blur-[32px] hover:border-gray-50 md:block ${
                exhibitionInfo.theme === 'LIGHT'
                  ? 'bg-gallery-ui-light border-gray-300'
                  : 'bg-gallery-ui border-gray-400'
              }`}
              onClick={() => setShowInfo(true)}
            >
              <Trans i18nKey={'btn.gallery-about'} />
            </button>
          </div>
        )}
      </div>
      {!slideshowMode && (
        <>
          <div className='absolute left-1 top-1/2 hidden -translate-y-1/2 sm:!left-3 md:block'>
            <button
              className={`bg-gallery-ui-2 flex h-[54px] w-[54px] items-center justify-center rounded-l-[2rem] rounded-r-md border-2 !border-[#9CA3AF] hover:border-gray-50 ${
                currentItemId == 0 || currentItem ? 'invisible' : 'visible'
              }`}
              onClick={showPrevItem}
              disabled={currentItemId == 0 || currentItem}
            >
              <ChevronLeftIcon className='h-7 w-7 text-white' strokeWidth={4} />
            </button>
          </div>
          <div className='absolute right-1 top-1/2 hidden -translate-y-1/2 sm:!right-3 md:block'>
            <button
              className={`bg-gallery-ui-2 flex h-[54px] w-[54px] items-center justify-center rounded-l-md rounded-r-[2rem] border-2 !border-[#9CA3AF] hover:border-gray-50 ${
                exhibitionItems.length == 0 ||
                currentItemId == maxIndex ||
                currentItem
                  ? 'invisible'
                  : 'visible'
              }`}
              onClick={showNextItem}
              disabled={
                exhibitionItems.length == 0 ||
                currentItemId == maxIndex ||
                currentItem
              }
            >
              <ChevronRightIcon
                className='h-7 w-7 text-white'
                strokeWidth={4}
              />
            </button>
          </div>
        </>
      )}

      {/* middle */}
      <div className='absolute bottom-24 left-1/2 -translate-x-1/2 transform md:hidden'>
        <div className='flex flex-1 items-start justify-center space-x-2'>
          {!slideshowMode && (
            <button
              className={`bg-gallery-ui-2 flex h-[54px] w-[54px] items-center justify-center rounded-l-[2rem] rounded-r-md border-2 !border-[#9CA3AF] hover:border-gray-50 ${
                currentItemId == 0 || currentItem ? 'invisible' : 'visible'
              }`}
              onClick={showPrevItem}
              disabled={currentItemId == 0 || currentItem}
            >
              <ChevronLeftIcon className='h-7 w-7 text-white' strokeWidth={4} />
            </button>
          )}
          {itemTitle && (
            <div>
              <div
                className={`flex h-[5.625rem] w-[9.375rem] cursor-pointer flex-col gap-2 rounded border-[1.5px] border-[#868686] bg-white/60 p-4`}
                onClick={() => {
                  setSelectedItem(exhibitionItems[currentItemId]);
                }}
              >
                <p
                  className={`line-clamp-2 text-xs font-medium text-base-content`}
                >
                  {itemTitle}
                </p>
                {artistName && (
                  <p
                    className={`line-clamp-1 text-xs font-medium text-base-content`}
                  >
                    {artistName}
                  </p>
                )}
              </div>
            </div>
          )}
          {!slideshowMode && (
            <button
              className={`bg-gallery-ui-2 flex h-[54px] w-[54px] items-center justify-center rounded-l-md rounded-r-[2rem] border-2 !border-[#9CA3AF] hover:border-gray-50 ${
                exhibitionItems.length == 0 ||
                currentItemId == maxIndex ||
                currentItem
                  ? 'invisible'
                  : 'visible'
              }`}
              onClick={showNextItem}
              disabled={
                exhibitionItems.length == 0 ||
                currentItemId == maxIndex ||
                currentItem
              }
            >
              <ChevronRightIcon
                className='h-7 w-7 text-white'
                strokeWidth={4}
              />
            </button>
          )}
        </div>
      </div>

      {/* bottom Ad Banner */}
      {isFreeMember && (
        <div className='absolute bottom-3 left-5 hidden max-w-[24.375rem] md:block'>
          <Link to={'/'}>
            <img src='/assets/img/ad-home.webp' alt='CAS Home' />
          </Link>
        </div>
      )}

      {/* bottom */}
      {room?.items?.length > 0 && (
        <>
          {user && (
            <div
              className={`absolute bottom-10 ml-2 w-11/12 md:flex-1 ${
                !currentItem?.allowComments && 'cursor-not-allowed'
              }`}
            ></div>
          )}
        </>
      )}
      <div className='absolute bottom-3 left-0 right-0 flex transform items-center justify-center md:hidden'>
        {attributions.length > 0 && (
          <button
            className='btn btn-link text-xs text-white/60'
            onClick={() =>
              document.getElementById('attribution_modal').showModal()
            }
          >
            View Attribution
          </button>
        )}
        <a
          href='/'
          target='_blank'
          rel='noopener noreferrer'
          className='rounded-[62rem] bg-black px-3 py-1 text-xs font-semibold text-white '
        >
          Powered by CAS
        </a>
      </div>
      <div className='absolute bottom-3 right-5 hidden md:block'>
        {attributions.length > 0 && (
          <button
            className='btn btn-link text-xs text-white/60'
            onClick={() =>
              document.getElementById('attribution_modal').showModal()
            }
          >
            View Attribution
          </button>
        )}

        <a
          href='/'
          target='_blank'
          rel='noopener noreferrer'
          className=' rounded-[62rem] bg-black px-3 py-1 text-xs font-semibold text-white '
        >
          Powered by CAS
        </a>
      </div>
      {/* blur */}
      {(showInfo || showExitModal) && (
        <div
          onClick={() => setShowInfo(false)}
          className={`test bg-gallery-ui absolute left-0 top-0 z-10 flex h-full w-full flex-col p-10 px-10 backdrop-blur-xl ${
            exhibitionInfo.theme === 'LIGHT'
              ? 'bg-gallery-ui-light'
              : 'bg-gallery-ui'
          }`}
        />
      )}
      {showInfo && (
        <RoomInfo setShowInfo={setShowInfo} room={room} RoomInfo={RoomInfo} />
      )}

      {!room?.items?.length > 0 && (
        <NoItemMessage roomTheme={exhibitionInfo.theme} />
      )}
      <OGP />
      {audioSrc && <audio ref={audioRef} loop src={audioSrc}></audio>}
      <AttributionModal attributions={attributions} />
    </div>
  );
};

export default Overlay;

const RoomInfo = ({ setShowInfo, room, roomTheme }) => {
  const user = useSelector((state) => state.user.currentUser);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const handleCopyLink = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const currentUrl = window.location.href; // 現在のページURLを取得
    navigator.clipboard
      .writeText(currentUrl)
      .then(() => {
        successToast(t('message.success.t24'));
      })
      .catch((err) => {
        console.error('Failed to copy: ', err);
      });
  };

  return (
    <div
      onClick={() => setShowInfo(false)}
      className='absolute top-0 z-50 flex h-full w-screen flex-col overflow-scroll bg-[#161616] p-8 text-white md:right-0 md:w-2/3 md:p-20'
    >
      <button
        className='self-end max-md:mr-0 max-md:mt-0 md:-mr-8 md:-mt-8'
        onClick={() => setShowInfo(false)}
      >
        <XMarkIcon className='h-8 w-8' />
      </button>
      <p
        className={`mb-8 text-3xl font-[800] ${
          roomTheme === 'LIGHT' ? 'text-gray' : 'text-white'
        }`}
      >
        {room?.title}
      </p>
      <div className='my-2 flex items-center space-x-3'>
        <img
          className='h-10 w-10 rounded-full object-cover'
          src={`${
            room?.user?.profileImageUrl
              ? room?.user?.profileImageUrl + '?w=300&q=60'
              : '/assets/img/avatar/dummy_avatar.svg'
          }`}
        />
        <div>
          <p className='text-sm font-bold text-white'>
            {room?.user?.displayName}
          </p>
          <p className='text-sm text-white'>
            <Trans i18nKey={'gallery-about.title-desc'} />
          </p>
        </div>
      </div>
      <div className='my-2 flex space-x-2 self-end'>
        {user?.id === room?.userId && (
          <button
            onClick={() => {
              navigate(`/edit-exhibition/${room?.roomId}`);
            }}
            className={`cursor-pointer hover:opacity-75`}
          >
            <PencilSquareIcon className='h-5 w-5' />
          </button>
        )}
      </div>
      {room.user.websiteUrl && (
        <div>
          <a
            href={room.user.websiteUrl}
            target='_blank'
            className='text-sm underline'
          >
            {i18n.language === 'ja'
              ? `${room.user.displayName}のサイトに移動`
              : `Jump to ${room.user.displayName} site`}
          </a>
        </div>
      )}
      <div className='mb-6 mt-10'>{room?.description}</div>
      <ul className='flex list-disc flex-col gap-1 pl-5'>
        {room?.items?.map((item) => (
          <li className='text-white' key={item.title}>
            {item?.title}
          </li>
        ))}
      </ul>
      <button
        className='btn btn-outline btn-sm mt-10 w-fit border-white text-white'
        onClick={handleCopyLink}
      >
        <Square2StackIcon className='h-5 w-5' />
        <Trans i18nKey={'btn.copy-exhibition-link'} />
      </button>
    </div>
  );
};

const NoItemMessage = (roomTheme) => {
  return (
    <div
      className={`hover:border-gray-50-500 absolute left-1/2 top-1/2 flex -translate-x-1/2  -translate-y-1/2  transform items-center justify-center rounded-sm border  bg-opacity-60 p-16 backdrop-filter before:absolute before:z-[-1] before:h-full before:w-full before:overflow-hidden before:rounded-xl before:backdrop-blur-3xl before:content-[""] ${
        roomTheme === 'LIGHT'
          ? 'bg-gallery-ui-light border-gray-300'
          : 'bg-gallery-ui border-gray-400'
      }`}
    >
      <p className='text-sm text-white'>
        <Trans i18nKey={'gallery.empty'} />
      </p>
    </div>
  );
};
