import {
  BakeShadows,
  Cloud,
  Environment,
  OrbitControls,
  useHelper,
} from '@react-three/drei';
import { EffectComposer } from '@react-three/postprocessing';
import React, { Suspense, useContext, useRef } from 'react';
import Loader from '../Loader';
import Items from '../Items';

import { ExhibitionControllerContext } from '../../../context/ExhibitionControllerContext';
import { ExhibitionContext } from '../../../context/ExhibitionContext';
import SilhouetteGroup from '../SilhouetteGroup';

const Sky = () => {
  const { exhibitionItems, exhibitionSettings } = useContext(ExhibitionContext);
  const { currentItemId, mode } = useContext(ExhibitionControllerContext);
  const bgColor = '#191920';

  const directLightIntensity = 1;
  const ambientLightIntensity = 1;

  //lightのdebug用
  // const dirLight = useRef(null);
  // useHelper(dirLight, THREE.DirectionalLightHelper, '#fff');

  return (
    <>
      {/* <OrbitControls /> */}
      {/* <BakeShadows /> */}
      <EffectComposer>{/* <Bloom intensity={0.1} /> */}</EffectComposer>
      <Suspense fallback={<Loader />}>
        <color attach='background' args={[`${bgColor}`]} />
        <fog attach='fog' args={[`${bgColor}`, 6, 75]} />
        <directionalLight
          // ref={dirLight}
          position={[0, 2, 20]}
          target-position={[0, 2.5, 0]}
          intensity={directLightIntensity}
          color={'#f8f5e1'}
          castShadow
        />

        <ambientLight intensity={ambientLightIntensity} color={'#ffffff'} />
        <Cloud
          seed={1}
          scale={2}
          volume={5}
          color='white'
          position={[-1, -4, 0]}
          fade={20}
        />
        <Cloud
          seed={2}
          scale={2}
          volume={5}
          color='white'
          position={[20, -4, -2]}
          fade={20}
        />
        <Cloud
          seed={3}
          scale={2}
          volume={5}
          color='white'
          position={[40, -4, 0]}
          fade={25}
        />
        <Cloud
          seed={4}
          scale={2}
          volume={5}
          color='white'
          position={[60, -4, 0]}
        />

        <group position={[0.2, -0.5, 0]}>
          <Items items={exhibitionItems} />
          <SilhouetteGroup />
        </group>
        <Environment
          background
          backgroundIntensity={1}
          backgroundBlurriness={0}
          files={
            'https://data.curatedartshow.com/hdri/industrial_sunset_02_puresky_1k.hdr'
          }
        />
      </Suspense>
    </>
  );
};

export default Sky;
