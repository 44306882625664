import { authRequest } from "../requestMethods";

export const fetchExhibitionGA4Data = async (startDate, endDate) => {
  try {
    const response = await authRequest.get(
      `/s3/ga4/exhibition?startDate=${startDate}&endDate=${endDate}`
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const fetchArtworkGA4Data = async (startDate, endDate) => {
  try {
    const response = await authRequest.get(
      `/s3/ga4/artwork?startDate=${startDate}&endDate=${endDate}`
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
