import { useState, useEffect } from 'react';
import { Trans } from 'react-i18next';
import { ChevronUpDownIcon } from '@heroicons/react/24/outline';

export const ExhibitionViewsFromAllCountries = ({
  GA4ExhibitionData,
  isLoading,
  isVIP,
}) => {
  const [rankedData, setRankedData] = useState([]);
  const [displayLimit, setDisplayLimit] = useState(25);
  const [showRowsOptions, setShowsRowOptions] = useState([]);
  const [sort, setSort] = useState({
    exhibition: 'desc',
    country: 'desc',
    cityRegion: 'desc',
    view: 'desc',
    uniqueUser: 'desc',
  });

  useEffect(() => {
    if (GA4ExhibitionData) {
      //console.log('Exhibition Countries: ', GA4ExhibitionData);
      // 表示データを設定
      const flatData = [];
      GA4ExhibitionData.forEach((item) => {
        item.exhibitions.forEach((exhibition) => {
          // 必要に応じてフィルタリング
          if (exhibition.totalViews > 0) {
            // 既に同じ国名・タイトルで追加している場合は追加済みデータに加算
            const foundIndex = flatData.findIndex(
              (data) =>
                data.country === item.country && data.title === exhibition.title
            );
            if (-1 < foundIndex) {
              flatData[foundIndex].totalViews += exhibition.totalViews;
              flatData[foundIndex].totalUsers += exhibition.totalUsers;
            } else {
              flatData.push({
                country: item.country,
                title: exhibition.title,
                totalViews: exhibition.totalViews,
                totalUsers: exhibition.totalUsers,
              });
            }
          }
        });
      });
      // ビュー数でソート（降順）
      const sortedData = flatData.sort((a, b) => b.totalViews - a.totalViews);
      // ランクを割り当て
      const ranked = sortedData.map((item, index) => ({
        rank: index + 1,
        ...item,
      }));
      setRankedData(ranked);
      makeShowRowsOptions(ranked);
    }
  }, [GA4ExhibitionData]);

  // 表示数オプションを作成
  const makeShowRowsOptions = (data) => {
    const options = [
      {
        label: '25',
        value: '25',
      },
    ];
    if (25 < data.length) {
      options.push({
        label: '50',
        value: '50',
      });
    }
    if (100 < data.length) {
      options.push({
        label: '100',
        value: '100',
      });
    }
    options.push({
      label: 'All',
      value: data.length,
    });
    setShowsRowOptions(options);
  };

  // 表示数変更時にソートをリセット
  const changeDisplayLimit = (limit) => {
    setDisplayLimit(limit);
  };

  // Exhibitionでソート
  const sortByExhibition = () => {
    const copy = [...rankedData.slice(0, displayLimit)];
    copy.sort((a, b) => {
      const exhibitionComparison =
        sort.exhibition === 'asc'
          ? a.title.localeCompare(b.title)
          : b.title.localeCompare(a.title);
      // Exhibitionが同じ場合rankで昇順ソート
      if (exhibitionComparison === 0) {
        return a.rank - b.rank;
      }
      return exhibitionComparison;
    });
    setRankedData(copy);
    setSort((prev) => ({
      ...prev,
      exhibition: prev.exhibition === 'asc' ? 'desc' : 'asc',
    }));
  };

  // Countryでソート
  const sortByCountry = () => {
    const copy = [...rankedData.slice(0, displayLimit)];
    copy.sort((a, b) => {
      const countryComparison =
        sort.country === 'asc'
          ? a.country.localeCompare(b.country)
          : b.country.localeCompare(a.country);
      // countryが同じ場合rankで昇順ソート
      if (countryComparison === 0) {
        return a.rank - b.rank;
      }
      return countryComparison;
    });
    setRankedData(copy);
    setSort((prev) => ({
      ...prev,
      country: prev.country === 'asc' ? 'desc' : 'asc',
    }));
  };

  // Viewsでソート
  const sortByViews = () => {
    const copy = [...rankedData.slice(0, displayLimit)];
    copy.sort((a, b) => {
      const viewComparison =
        sort.view === 'asc'
          ? b.totalViews - a.totalViews
          : a.totalViews - b.totalViews;
      // totalViewsが同じ場合rankで昇順ソート
      if (viewComparison === 0) {
        return a.rank - b.rank;
      }
      return viewComparison;
    });
    setRankedData(copy);
    setSort((prev) => ({
      ...prev,
      view: prev.view === 'asc' ? 'desc' : 'asc',
    }));
  };

  // Unique Usersでソート
  const sortByUniqueUsers = () => {
    const copy = [...rankedData.slice(0, displayLimit)];
    copy.sort((a, b) => {
      const uuComparison =
        sort.uniqueUser === 'asc'
          ? b.totalUsers - a.totalUsers
          : a.totalUsers - b.totalUsers;
      // totalUsersが同じ場合rankで昇順ソート
      if (uuComparison === 0) {
        return a.rank - b.rank;
      }
      return uuComparison;
    });
    setRankedData(copy);
    setSort((prev) => ({
      ...prev,
      uniqueUser: prev.uniqueUser === 'asc' ? 'desc' : 'asc',
    }));
  };

  return (
    <>
      <div className='mt-6 flex items-center gap-4'>
        <span className='label-text'>Show Rows:</span>
        {0 < showRowsOptions.length && (
          <select
            className='select select-bordered select-sm w-full max-w-32'
            onChange={(e) => changeDisplayLimit(Number(e.target.value))}
          >
            {showRowsOptions.map((item) => {
              return (
                <option
                  value={item.value}
                  key={`ExhibitionViewsFromAllCountries-${item.value}`}
                >
                  {item.label}
                </option>
              );
            })}
          </select>
        )}
      </div>

      <div className='mt-6 overflow-x-auto'>
        <table className='table'>
          {/* head */}
          <thead>
            <tr>
              <th>
                <Trans i18nKey={'analytics.rank'} />
              </th>
              <th className='min-w-[180px]'>
                <div className='flex gap-[0.625rem]'>
                  <Trans i18nKey={'analytics.exhibition'} />
                  <ChevronUpDownIcon
                    className='h-4 w-4 shrink-0 cursor-pointer'
                    onClick={sortByExhibition}
                  />
                </div>
              </th>
              <th>
                <div className='flex gap-[0.625rem]'>
                  <Trans i18nKey={'analytics.country'} />
                  <ChevronUpDownIcon
                    className='h-4 w-4 shrink-0 cursor-pointer'
                    onClick={sortByCountry}
                  />
                </div>
              </th>
              <th>
                <div className='flex gap-[0.625rem]'>
                  <Trans i18nKey={'analytics.views'} />
                  <ChevronUpDownIcon
                    className='h-4 w-4 shrink-0 cursor-pointer'
                    onClick={sortByViews}
                  />
                </div>
              </th>
              {isVIP && (
                <th>
                  <div className='flex gap-[0.625rem]'>
                    <Trans i18nKey={'analytics.uu'} />
                    <ChevronUpDownIcon
                      className='h-4 w-4 shrink-0 cursor-pointer'
                      onClick={sortByUniqueUsers}
                    />
                  </div>
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {!isLoading ? (
              rankedData.length > 0 ? (
                rankedData.slice(0, displayLimit).map((row, index) => {
                  return (
                    <tr
                      key={`ExhibitionViewsFromAllCountries-${row.country}-${row.title}_${index}`}
                    >
                      <td className='font-bold'>{row.rank}</td>
                      <td className='font-bold'>{row.title}</td>
                      <td>{row.country}</td>
                      <td>{row.totalViews.toLocaleString()}</td>
                      {isVIP && <td>{row.totalUsers.toLocaleString()}</td>}
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={3} className='text-center'>
                    <Trans i18nKey={'analytics.no-data'} />
                  </td>
                </tr>
              )
            ) : (
              <tr>
                <td className='loading loading-spinner loading-xs text-primary'></td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};
