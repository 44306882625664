import React from 'react';

const Light3 = ({ color, length = 5 }) => {
  let meshColor = [5, 2, 0.5];
  switch (color) {
    case 'RED':
      meshColor = [24, 4, 4];
      break;
    case 'BLUE':
      meshColor = [4, 4, 40];
      break;
    case 'WHITE':
      meshColor = [12, 12, 12];
      break;
    case 'YELLOW':
      meshColor = [12, 12, 4];
      break;
    case 'GREEN':
      meshColor = [4, 20, 4];
      break;
    case 'PINK':
      meshColor = [32, 4, 32];
      break;
    default:
      meshColor = [12, 12, 12];
      break;
  }

  return (
    <>
      {Array(2 * length)
        .fill()
        .map((_, index) => (
          <group position={[index * 5 - 5, 2.9, 1.5]} key={index}>
            <mesh scale={2.5} rotation={[Math.PI / 2, 0, Math.PI / 1.35]}>
              <torusGeometry args={[0.65, 0.025, 50, 50]} />
              {/* <meshStandardMaterial
                color={[3, 3, 3]}
                roughness={0.75}
                toneMapped={false}
              /> */}
              <meshStandardMaterial toneMapped={false} color={meshColor} />
            </mesh>
          </group>
        ))}
    </>
  );
};

export default Light3;
