import { Environment } from '@react-three/drei';

import { Bloom, EffectComposer } from '@react-three/postprocessing';
import React, { Suspense, useContext } from 'react';
import Loader from '../Loader';
import Items from '../Items';

import MovingSpotLight from '../lights/MovingSpotLight';
import ObjectLight1 from '../models/objectLights/ObjectLight1';
import ObjectLight2 from '../models/objectLights/ObjectLight2';
import ObjectLight3 from '../models/objectLights/ObjectLight3';
import { ExhibitionControllerContext } from '../../../context/ExhibitionControllerContext';
import { ExhibitionContext } from '../../../context/ExhibitionContext';
import MuseumWall from '../walls/MuseumWall';
import MuseumFloor from '../floors/MuseumFloor';
import SilhouetteGroup from '../SilhouetteGroup';

const Museum = () => {
  const { currentItemId, mode } = useContext(ExhibitionControllerContext);
  const { exhibitionItems, exhibitionSettings } = useContext(ExhibitionContext);

  const directLightIntensity = 3;
  const ambientLightIntensity = 0.7;

  return (
    <>
      {/* <BakeShadows /> */}
      <EffectComposer>
        <Bloom intensity={0} />
      </EffectComposer>
      <Suspense fallback={<Loader />}>
        {/* lights */}
        <color attach='background' args={[`#191920`]} />

        <directionalLight intensity={directLightIntensity} castShadow />

        <ambientLight intensity={ambientLightIntensity} />
        {/* {exhibitionSettings?.light === 1 && <CeilingLight />} */}
        {/* {exhibitionSettings?.light === 2 && <FluorescentLamp />} */}
        {/* {exhibitionSettings?.light === 3 && (
          <fog attach='fog' args={[`#fff`, 10, 30]} />
        )} */}

        {/*
      <CeilingLightTorus /> */}
        {exhibitionSettings?.objectLight == 1 &&
          Array.from({ length: 10 }, (_, index) => (
            <ObjectLight1
              key={index}
              position={[-1.5 + index * 8, 1.7, 0.6]}
              scale={1}
            />
          ))}
        {exhibitionSettings?.objectLight == 2 &&
          Array.from({ length: 10 }, (_, index) => (
            <ObjectLight2
              key={index}
              position={[-1.5 + index * 8, 4, 0.2]}
              scale={1.2}
            />
          ))}
        {exhibitionSettings?.objectLight == 3 &&
          Array.from({ length: 10 }, (_, index) => (
            <ObjectLight3
              key={index}
              position={[-1.5 + index * 8, 4.4, 0.12]}
              scale={1}
            />
          ))}

        {mode == 'HIGH' && currentItemId != null && (
          <MovingSpotLight
            currentItemId={currentItemId}
            opacity={0}
            anglePower={0}
          />
        )}

        {Array.from({ length: 10 }, (_, index) => (
          <ObjectLight2
            key={index}
            position={[-1.5 + index * 8, 4, 0.2]}
            scale={1.2}
          />
        ))}

        <group position={[0.2, -0.5, 0]}>
          <Items items={exhibitionItems} />
          <SilhouetteGroup />

          {/* wall */}
          <MuseumWall />

          {/* floor */}
          <MuseumFloor />
        </group>

        <Environment
          files={'https://data.curatedartshow.com/hdri/potsdamer_platz_1k.hdr'}
        />
      </Suspense>
    </>
  );
};

export default Museum;
