import { useState, useEffect } from 'react';
import { CalendarDaysIcon } from '@heroicons/react/24/solid';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { formatYYYYMMDD } from '../../utils/DateUtils';
import {
  fetchExhibitionGA4Data,
  fetchArtworkGA4Data,
} from '../../services/Analytics';
import { useQuery } from '@tanstack/react-query';
import { ExhibitionViewsByLocation } from './components/ExhibitionViewsByLocation';
import { ExhibitionViewsFromAllCountries } from './components/ExhibitionViewsFromAllCountries';
import { ArtworkViewsByLocation } from './components/ArtworkViewsByLocation';
import { ArtworkViewsFromAllCountries } from './components/ArtworkViewsFromAllCountries';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const Analytics = () => {
  const user = useSelector((state) => state.user.currentUser);
  const PREMIUM_MEMBER_ID =
    process.env.REACT_APP_STRIPE_PREMIUM_CAS_MEMBER_PRODUCT_ID;
  const VIP_MEMBER_ID = process.env.REACT_APP_STRIPE_VIP_CAS_MEMBER_PRODUCT_ID;
  const [selectedRange, setSelectedRange] = useState('30DAYS');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isPremium, setIsPremium] = useState(false);
  const [isVIP, setIsVIP] = useState(false);
  const { i18n } = useTranslation();
  const dateRangeOptions = [
    {
      id: '30DAYS',
      label: 'Last 30 Days',
      labelJa: '過去30日間',
    },
    {
      id: '7DAYS',
      label: 'Last 7 Days',
      labelJa: '過去7日間',
    },
  ];

  const { data: GA4ExhibitionData, isLoading: isLoadingGA4ExhibitionData } =
    useQuery({
      queryFn: () =>
        fetchExhibitionGA4Data(
          formatYYYYMMDD(startDate),
          formatYYYYMMDD(endDate)
        ),
      queryKey: ['ga4_exhibition', startDate, endDate],
      enabled: !!startDate && !!endDate,
      staleTime: 1000 * 60 * 30, // 30分でキャッシュ更新
      cacheTime: Infinity, // キャッシュは削除しない
    });

  const { data: GA4ArtworkData, isLoading: isLoadingGA4ArtworkData } = useQuery(
    {
      queryFn: () =>
        fetchArtworkGA4Data(formatYYYYMMDD(startDate), formatYYYYMMDD(endDate)),
      queryKey: ['ga4_artwork', startDate, endDate],
      enabled: !!startDate && !!endDate,
      staleTime: 1000 * 60 * 30, // 30分でキャッシュ更新
      cacheTime: Infinity, // キャッシュは削除しない
    }
  );

  useEffect(() => {
    if (user?.userPlans?.length > 0) {
      const productIds = user.userPlans.map(
        (item) => item.SubscriptionPlans.productId
      );
      if (productIds.includes(PREMIUM_MEMBER_ID)) {
        setIsPremium(true);
      }
      if (productIds.includes(VIP_MEMBER_ID)) {
        setIsVIP(true);
      }
    }
  }, [user]);

  useEffect(() => {
    const tmpDate = new Date();
    switch (selectedRange) {
      case '7DAYS':
        tmpDate.setDate(tmpDate.getDate() - 7);
        setStartDate(tmpDate);
        setEndDate(new Date());
        break;
      case '30DAYS':
        tmpDate.setDate(tmpDate.getDate() - 30);
        setStartDate(tmpDate);
        setEndDate(new Date());
        break;
      default:
        tmpDate.setDate(tmpDate.getDate() - 30);
        setStartDate(tmpDate);
        setEndDate(new Date());
        break;
    }
  }, [selectedRange]);

  return (
    <>
      <h2 className='mb-8 text-3xl font-extrabold'>
        {isPremium || isVIP ? 'Advanced Analytics' : 'Basic Analytics'}
      </h2>
      <section className='inline-flex flex-col gap-2 bg-base-200 p-6 md:w-full'>
        <span
          className='text-sm font-medium'
          style={{ color: 'rgba(31, 42, 55, 0.60)' }}
        >
          <Trans i18nKey={'analytics.date-range'} />
        </span>
        <div className='flex flex-col gap-8 md:flex-row'>
          <select
            className='select select-bordered w-full max-w-[224px]'
            onChange={(e) => setSelectedRange(e.target.value)}
            value={selectedRange}
          >
            {dateRangeOptions.map((item) => {
              return (
                <option value={item.id} key={item.id}>
                  {i18n.language === 'ja' ? item.labelJa : item.label}
                </option>
              );
            })}
          </select>

          <div className='flex flex-col items-center gap-2 sm:!flex-row'>
            <label className='input input-bordered flex items-center gap-2'>
              <CalendarDaysIcon className='h-4 w-4' />
              <DatePicker
                selected={startDate}
                maxDate={new Date()}
                onChange={(date) => setStartDate(date)}
                dateFormat={`${
                  i18n.language === 'ja' ? 'YYYY年M月d日' : 'MMM d, yyyy'
                }`}
                className='max-w-[7rem] text-sm'
                popperClassName='relative !z-20'
              />
            </label>
            <div className='flex items-center gap-2'>
              <div className='h-[0.0625rem] w-3 bg-base-content' />
              <label className='input input-bordered flex items-center gap-2'>
                <CalendarDaysIcon className='h-4 w-4' />
                <DatePicker
                  selected={endDate}
                  maxDate={new Date()}
                  minDate={startDate}
                  onChange={(date) => setEndDate(date)}
                  dateFormat={`${
                    i18n.language === 'ja' ? 'YYYY年M月d日' : 'MMM d, yyyy'
                  }`}
                  className='max-w-[7rem] text-sm'
                  popperClassName='relative !z-20'
                />
              </label>
            </div>
          </div>
        </div>
      </section>
      <section className='mt-12 '>
        <h2 className='text-2xl font-bold'>
          <Trans i18nKey={'analytics.ttl-1'} />
        </h2>
        <div className='mt-6 flex max-w-3xl flex-col gap-10'>
          <ExhibitionViewsByLocation
            GA4ExhibitionData={GA4ExhibitionData}
            isLoading={isLoadingGA4ExhibitionData}
            isVIP={isVIP}
          />
        </div>
      </section>
      <section className='mt-20'>
        <h2 className='text-xl font-semibold'>
          <Trans i18nKey={'analytics.ttl-2'} />
        </h2>
        <div className='max-w-3xl'>
          <ExhibitionViewsFromAllCountries
            GA4ExhibitionData={GA4ExhibitionData}
            isLoading={isLoadingGA4ExhibitionData}
            isVIP={isVIP}
          />
        </div>
      </section>
      {(isPremium || isVIP) && (
        <section className='mt-20'>
          <div className='mb-8 mt-4 flex items-center gap-2'>
            <h2 className='text-2xl font-bold'>
              <Trans i18nKey={'analytics.ttl-3'} />
            </h2>
            <span className='badge badge-primary badge-outline badge-md whitespace-nowrap'>
              <Trans i18nKey={'analytics.badge'} />
            </span>
          </div>
          <div className='mt-6 flex flex-col gap-10'>
            <ArtworkViewsByLocation
              GA4ArtworkData={GA4ArtworkData}
              isLoading={isLoadingGA4ArtworkData}
              isVIP={isVIP}
            />
          </div>
        </section>
      )}

      {(isPremium || isVIP) && (
        <section className='mt-20'>
          <h2 className='text-xl font-semibold'>
            <Trans i18nKey={'analytics.ttl-4'} />
          </h2>
          <ArtworkViewsFromAllCountries
            GA4ArtworkData={GA4ArtworkData}
            isLoading={isLoadingGA4ArtworkData}
            isVIP={isVIP}
          />
        </section>
      )}
    </>
  );
};

export default Analytics;
