import { useState, useEffect } from 'react';
import { TrashIcon } from '@heroicons/react/24/outline';

import DeleteModal from '../../components/DeleteModal';
import { errorToast, successToast } from '../../../../utils/toast';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { deleteWall, getWallTextures } from '../../../../services/Admin';
import { useMutation, useQuery } from '@tanstack/react-query';
import LoadingSpinner from '../../../../components/LoadingSpinner';

const AdminWallTextureList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [deleteId, setDeleteId] = useState(null);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [filteredWalls, setFilteredWalls] = useState([]);

  const {
    data: wallTextures,
    isLoading: isWallTexturesLoading,
    refetch: refetchWallTextures,
  } = useQuery({
    queryKey: ['admin-wall-textures'],
    queryFn: () => getWallTextures(),
    retry: (failureCount, error) => {
      if (error.response?.status === 401 || error.response?.status === 403) {
        return false; // 再認証が必要な場合はリトライしない
      }
    },
    meta: { errCode: 'ADMIN_GET_WALL_TEXTURES' },
  });

  const handleDelete = async () => {
    deleteWallMutation.mutate();
  };

  // delete処理
  const deleteWallMutation = useMutation({
    mutationFn: async () => {
      return await deleteWall(deleteId);
    },
    onSuccess: () => {
      successToast('Texture deleted successfully!');
      document.getElementById('delete_modal').close();
      refetchWallTextures();
    },
    onError: (err) => {
      console.error(err);

      if (err.response.data.statusCode == 403) {
        navigate('/admin/login');
      } else {
        errorToast(
          'Something went wrong. We are aware of the problem and will try to fix it as soon as possible. Sorry for the inconvenience.'
        );
      }
    },
  });

  useEffect(() => {
    if (searchKeyword) {
      const filtered = wallTextures.filter((item) =>
        item.title.toLowerCase().includes(searchKeyword)
      );
      setFilteredWalls(filtered);
    } else {
      setFilteredWalls(wallTextures);
    }
  }, [wallTextures, searchKeyword]);

  return (
    <>
      <h2 className='pb-8 text-3xl font-extrabold'>Wall Texture</h2>
      <Link to={'/admin/textures/wall/create'} className='btn btn-primary'>
        Add Wall texture
      </Link>

      <div className='mt-10 overflow-x-auto'>
        {isWallTexturesLoading ? (
          <span className='loading loading-spinner text-primary'></span>
        ) : (
          <table className='table table-auto'>
            {/* head */}
            <thead>
              <tr>
                <th>Id</th>
                <th>Thumbnail</th>
                <th>Name</th>
                <th>Availability</th>
                <th>Status</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {filteredWalls?.map((item) => (
                <tr key={`wall-${item.id}`}>
                  <th>{item.id}</th>
                  <td>
                    <img
                      className='h-24 w-24 object-cover'
                      src={item.thumbnail + '?q=60'}
                      alt=''
                    />
                  </td>
                  <td className='max-w-48'>{item.title}</td>
                  <td className='max-w-56'>
                    <div className='badge badge-outline'>
                      {item.isPro ? 'CAS-5 Only' : 'All Plans'}
                    </div>
                  </td>
                  <td>{item.published ? 'Published' : 'Unpublished'}</td>
                  <td>
                    <Link
                      to={`/admin/textures/wall/edit/${item.id}`}
                      className='btn btn-sm'
                    >
                      Edit
                    </Link>
                  </td>
                  <td className='min-w-36'>
                    <button
                      className='btn btn-outline btn-error btn-sm'
                      onClick={() => {
                        setDeleteId(item.id);
                        document.getElementById('delete_modal').showModal();
                      }}
                    >
                      <TrashIcon className='h-5 w-5' />
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>

      <DeleteModal
        itemName='texture'
        confirmDelete={handleDelete}
        loading={deleteWallMutation.isPending}
      />
    </>
  );
};

export default AdminWallTextureList;
