import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';


i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ['en', 'ja'],
    fallbackLng: 'en', // デフォルトの言語を設定
    debug: true,
    returnEmptyString: false, // 空文字での定義を許可に
    resources: {
      // 辞書情報
      // 用意した翻訳ファイルを読み込む
      en: {
        translation: require('./locales/en.json')
      },
      ja: {
        translation: require('./locales/ja.json')
      },
    },
    detection: {
      order: ['localStorage', 'navigator'], // 言語の検出順
      caches: ['localStorage']
    },
    interpolation: {
      escapeValue: false
    },
    react: {
      // 指定したHTMLノードを翻訳時にそのまま保持して表示するための設定
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'span'],
      useSuspense: false
    }
  })

export default i18n