import React from 'react';

const Light9 = ({ color, length = 5 }) => {
  let meshColor = [5, 2, 0.5];

  switch (color) {
    case 'RED':
      meshColor = [8, 1, 1];
      break;
    case 'BLUE':
      meshColor = [1, 1, 8];
      break;
    case 'WHITE':
      meshColor = [6, 6, 6];
      break;
    case 'YELLOW':
      meshColor = [6, 6, 2];
      break;
    case 'GREEN':
      meshColor = [0.6, 8, 0.6];
      break;
    case 'PINK':
      meshColor = [8, 0.6, 8];
      break;
    default:
      meshColor = [6, 6, 6];
      break;
  }
  return (
    <>
      {Array(7 * length)
        .fill()
        .map((_, index) => (
          <mesh
            scale={1}
            position={[index - 10, 0.4, 0]}
            rotation-z={Math.sin(index) * 0.3}
            key={index}
          >
            <boxGeometry args={[0.08, 1.6, 0.08]} />
            <meshStandardMaterial color={meshColor} toneMapped={false} />
          </mesh>
        ))}
    </>
  );
};

export default Light9;
