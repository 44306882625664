import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  loginFailure,
  loginStart,
  loginSuccess,
} from '../../../redux/userRedux';
import { publicRequest } from '../../../requestMethods';
import { errorToast } from '../../../utils/toast';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(loginStart());
    try {
      const res = await publicRequest.post(`/admin/login`, {
        email,
        password,
      });

      dispatch(loginSuccess(res.data));

      // stateは更新できるがlocalstorageへの保存に遅れがあるようなのでlocalstorageへの保存を待つ
      waitForTokenInLocalStorage().then(() => {
        navigate('/admin/users');
      });
    } catch (err) {
      console.log(err.response.data.message);
      if (err.response.data.statusCode === 403) {
        errorToast('Password or email is incorrect');
      } else {
        errorToast('Something went wrong. Please try again.');
      }
      dispatch(loginFailure());
    }
  };

  const waitForTokenInLocalStorage = () => {
    return new Promise((resolve) => {
      const checkLocalStorage = () => {
        const token = JSON.parse(
          JSON.parse(localStorage.getItem('persist:root')).user
        ).tokens?.access_token;
        if (token) {
          // トークンがLocalStorageに保存された場合
          resolve(token);
        } else {
          // トークンがまだLocalStorageに保存されていない場合、再試行
          setTimeout(checkLocalStorage, 100); // 100ミリ秒ごとにチェック
        }
      };

      // 最初のチェックを実行
      checkLocalStorage();
    });
  };

  return (
    <>
      <div className='relative mx-auto h-full'>
        <div className='mx-auto max-w-[30rem] px-4 py-[4.5rem] sm:px-0'>
          <h1 className='text-3xl font-extrabold'>Admin Login</h1>
          <form onSubmit={handleSubmit} className='mt-8'>
            <div className='flex flex-col gap-6'>
              <label className='form-control w-full'>
                <div className='label'>
                  <span className='label-text'>Email</span>
                </div>
                <input
                  maxLength='320'
                  type='email'
                  placeholder='Email'
                  onChange={(e) => setEmail(e.target.value)}
                  className='input input-bordered w-full'
                />
              </label>

              <label className='form-control w-full'>
                <div className='label'>
                  <span className='label-text'>Password</span>
                </div>
                <div className='relative'>
                  <input
                    className='undefined input input-bordered w-full pr-10'
                    type={`${showPassword ? 'text' : 'password'}`}
                    placeholder='Password'
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <span
                    className='absolute right-3 top-1/2 -translate-y-2/4 cursor-pointer text-sm'
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    Show
                  </span>
                </div>
              </label>
            </div>
            <button
              type='submit'
              className='btn btn-primary btn-block mb-3 mt-10'
            >
              Login
            </button>
          </form>
          <p className='mt-2 text-center'>
            If you are not admin, please go to{' '}
            <Link to='/' className='text-primary'>
              CAS website.
            </Link>
          </p>
        </div>
      </div>
    </>
  );
};

export default Login;
