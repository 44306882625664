import { useGLTF } from '@react-three/drei';
import { Suspense, useLayoutEffect, useState } from 'react';
import * as THREE from 'three';

const PictureFrame50 = ({ color, ...props }) => {
  const { scene, materials } = useGLTF(
    'https://data.curatedartshow.com/models/frames/oddframe1.glb'
  );

  const [geometry, setGeometry] = useState();

  if (!geometry) {
    const cloneScene = scene.clone(true);
    setGeometry(cloneScene);
  }

  useLayoutEffect(() => {
    // console.log(materials);
    // materials['Midnight Plastic_material2'].color.set('#686868');
    // materials['Midnight Plastic_material2'].metalness = 0;
    // materials['Midnight Plastic_material2'].roughness = 2;
    materials['Copper Metallic Leather_material'].color.set('#a77200');
    materials['Copper Metallic Leather_material'].metalness = 0.2;
    materials['Copper Metallic Leather_material'].roughness = 3;
    // materials['metal_sandblasted_material'].color.set('#c9bca0');
    materials['metal_sandblasted_material'].color.set('#565656');
    materials['metal_sandblasted_material'].metalness = 0.5;
    materials['metal_sandblasted_material'].roughness = 0.6;
    // materials['default_material191.003'].color.set('#7c87cd');
    materials['default_material191.003'].metalness = 1;
    materials['default_material191.003'].roughness = 0;
    // materials['Metallic Fabric Ripstop_material'].color.set('#7c87cd');
    materials['Metallic Fabric Ripstop_material'].metalness = 1;
    materials['Metallic Fabric Ripstop_material'].roughness = 0.4;

    geometry.traverse((obj) => {
      if (obj.isMesh) obj.castShadow = obj.receiveShadow = true;
    });
  }, []);

  return (
    <Suspense fallback={null}>
      <primitive object={geometry} {...props} />
    </Suspense>
  );
};

export default PictureFrame50;
