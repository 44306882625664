import React from 'react';

const SkeletonRoomCard = () => {
  return (
    <div className='animate-pulse'>
      <div className='mb-4 mr-10 aspect-[3/2] w-full flex-shrink-0 rounded-lg bg-slate-200' />
      <div className='h-2.5  bg-slate-200 rounded-full  w-32 mb-2' />
      <div className='h-2 w-16 bg-slate-200 rounded-full  mb-2.5' />
    </div>
  );
};

export default SkeletonRoomCard;
