import { useGLTF } from '@react-three/drei';
import { Suspense, useLayoutEffect, useState } from 'react';
import * as THREE from 'three';

const PictureFrame76 = ({ color, ...props }) => {
  const { scene, materials } = useGLTF(
    'https://data.curatedartshow.com/models/frames/koiframe8.glb'
  );

  const [geometry, setGeometry] = useState();

  if (!geometry) {
    const cloneScene = scene.clone(true);
    setGeometry(cloneScene);
  }

  useLayoutEffect(() => {
    // console.log(materials);
    materials['Sienna Glossy_material'].color.set('#856f06');
    materials['Sienna Glossy_material'].metalness = 0.5;
    materials['Sienna Glossy_material'].roughness = 2;

    // materials['Black Matte Ceramic_material'].color.set('#6a3e01');
    materials['Black Matte Ceramic_material'].metalness = 0.2;
    materials['Black Matte Ceramic_material'].roughness = 3;
    // materials['Black Matte Ceramic_material'].normalScale = new THREE.Vector2(
    //   0,
    //   0
    // );

    // materials['concrete_cast_material].color.set('#1d1d1d');
    materials['concrete_cast_material'].metalness = 0.7;
    materials['concrete_cast_material'].roughness = 3;
    materials['concrete_cast_material'].normalScale = new THREE.Vector2(0, 0);

    // materials['default_material107.003'].color.set('#7c87cd');
    // materials['default_material107.003'].metalness = 1;
    // materials['default_material107.003'].roughness = 0;
    materials['High Gloss Paper_material.001'].color.set('#868686');
    materials['High Gloss Paper_material.001'].metalness = 0.6;
    materials['High Gloss Paper_material.001'].roughness = 2;

    geometry.traverse((obj) => {
      if (obj.isMesh) obj.castShadow = obj.receiveShadow = true;
    });
  }, []);

  return (
    <Suspense fallback={null}>
      <primitive object={geometry} {...props} />
    </Suspense>
  );
};

export default PictureFrame76;
