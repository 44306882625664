import React, { useEffect, useMemo, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { publicRequest, authRequest } from '../../../../../requestMethods';
import { useDispatch, useSelector } from 'react-redux';
import { loginSuccess } from '../../../../../redux/userRedux';
import LoadingSpinner from '../../../../../components/LoadingSpinner';
import { loadEnd, loadStart } from '../../../../../redux/loadRedux';
import { Trans, useTranslation } from 'react-i18next';

export const OnBoardingSuccess = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.isLoading);
  const { i18n, t } = useTranslation();

  const [plans, setPlans] = useState(null);
  const [searchParams] = useSearchParams();
  const ids = searchParams.getAll('id');
  const [currentPlan, setCurrentPlan] = useState(['']);

  const fetchPrices = async () => {
    try {
      const res = await publicRequest.get('/stripe/prices');
      setPlans(res.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (ids.length < 1) {
      // navigate('/404', { replace: true });
    } else {
      fetchPrices();
    }
  }, []);

  useEffect(() => {
    const signIn = async () => {
      try {
        const res = await authRequest.get(`/auth/latest`);
        // ログイン状態にする
        dispatch(loginSuccess(res.data));
      } catch (err) {
        console.log(err);
      } finally {
        dispatch(loadEnd());
      }
    };
    dispatch(loadStart());
    //webhookより早い場合があるので意図的に遅らせる
    setTimeout(() => {
      signIn();
    }, 3000);
  }, [searchParams]);

  // 商品情報を取得し、クエリに一致するpriceIdのプランをセット
  useMemo(() => {
    if (plans && 0 < ids.length) {
      const currentPlans = plans.filter((plan) => ids.includes(plan.priceId));
      if (currentPlan.length < 1) {
        return navigate('/404', { replace: true });
      }
      return setCurrentPlan(currentPlans);
    }
  }, [plans]);

  const planName = useMemo(() => {
    return 0 < currentPlan.length && currentPlan[0].productName
      ? currentPlan[0].productName.replace('VIP', '').trim()
      : '';
  }, [currentPlan]);

  const price = useMemo(() => {
    if (0 < currentPlan.length && currentPlan[0].productName) {
      const plan = currentPlan.filter(
        (plan) => !plan.productName.includes('VIP')
      );
      return Number(plan[0].unitAmount) / 100;
    }
    return '';
  }, [currentPlan]);

  const VIPSupportText = useMemo(() => {
    if (0 < currentPlan.length && currentPlan[0].productName) {
      const vipPlan = currentPlan.filter((plan) =>
        plan.productName.includes('VIP')
      );
      return 0 < vipPlan.length
        ? i18n.language === 'ja'
          ? `選択済み ($${Number(vipPlan[0].unitAmount) / 100}/月)`
          : `Selected ($${Number(vipPlan[0].unitAmount) / 100}/month)`
        : i18n.language === 'ja'
        ? '選択されていません'
        : 'Not selected';
    }
    return '';
  });

  return (
    <>
      <div className='mx-auto w-full max-w-screen-sm px-4 py-10'>
        {0 < currentPlan.length ? (
          <div className='w-full flex-auto'>
            {i18n.language === 'ja' ? (
              <h1>
                <span className='text-primary'>{planName}</span>
                のサブスクリプション開始、ありがとうございます。
              </h1>
            ) : (
              <h1>
                Thank you for subscribing to{' '}
                <span className='text-primary'>{planName}</span>
              </h1>
            )}

            {i18n.language === 'ja' ? (
              <p>
                お支払いが成功しました。
                <span className='font-bold'>{planName}</span>
                のプランが有効になっています。
              </p>
            ) : (
              <p>
                Your payment was successful, and your subscription to{' '}
                <span className='font-bold'>{planName}</span> is now active!
              </p>
            )}

            <div className='mt-12'>
              <div className='mt-10 overflow-x-auto'>
                <table className='table'>
                  <thead>
                    <tr>
                      <th>
                        <Trans i18nKey={'subscription-table.th1'} />
                      </th>
                      <th>{planName}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className='font-bold'>
                        <Trans i18nKey={'subscription-table.row1-td1'} />
                      </td>
                      <td>
                        {i18n.language === 'ja'
                          ? `$${price}/月`
                          : `$${price}/month`}
                      </td>
                    </tr>
                    <tr>
                      <td className='font-bold'>
                        <Trans i18nKey={'subscription-table.row4-td1'} />
                      </td>
                      <td>
                        <ul className='list-inside list-disc'>
                          {planName == 'VAU-1' ? (
                            <>
                              <li>
                                <Trans
                                  i18nKey={'subscription-table.row4-td2-1'}
                                />
                              </li>
                              <li>
                                <Trans
                                  i18nKey={'subscription-table.row4-td2-2'}
                                />
                              </li>
                              <li>
                                <Trans
                                  i18nKey={'subscription-table.row4-td2-3'}
                                />
                              </li>
                              <li>
                                <Trans
                                  i18nKey={'subscription-table.row4-td2-4'}
                                />
                              </li>
                            </>
                          ) : (
                            <Trans i18nKey={'subscription-table.row4-td2-5'} />
                          )}
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td className='font-bold'>
                        <Trans i18nKey={'subscription-table.row2-td1'} />
                      </td>
                      <td>{planName == 'VAU-1' ? '1' : '5'}</td>
                    </tr>
                    <tr>
                      <td className='font-bold'>
                        <Trans i18nKey={'subscription-table.row3-td1'} />
                      </td>
                      <td>{planName == 'VAU-1' ? '10' : '100'}</td>
                    </tr>
                    <tr>
                      <td className='font-bold'>
                        <Trans i18nKey={'subscription-table.row5-td1'} />
                      </td>
                      <td>{VIPSupportText}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className='mt-10 flex flex-col gap-5 rounded-3xl bg-base-200 p-8 shadow-xl'>
                <p>
                  <Trans i18nKey={'subscription-table.start-text'} />
                </p>
                <div className='flex flex-col gap-4'>
                  <Link
                    to={'/create-item'}
                    className='btn btn-primary btn-wide'
                  >
                    <Trans i18nKey={'subscription-table.upload-artwork'} />
                  </Link>
                  <Link
                    to={'/dashboard'}
                    className='btn btn-outline btn-primary btn-wide'
                  >
                    <Trans i18nKey={'subscription-table.go-to-dashboard'} />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <span
            className='loading loading-dots loading-md absolute left-1/2 top-1/2 text-primary'
            style={{ transform: 'translate(50%, -200%)' }}
          ></span>
        )}
      </div>
      {isLoading && <LoadingSpinner />}
    </>
  );
};

export default OnBoardingSuccess;
