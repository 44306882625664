import { Clone, useAnimations, useGLTF } from '@react-three/drei';
import { useEffect, useLayoutEffect } from 'react';

const Car = ({ ...props }) => {
  const { scene, materials, animations } = useGLTF(
    'https://data.curatedartshow.com/models/lowPolyCar.glb'
  );

  useEffect(() => {
    return () => {
      //リソースのクリーンアップ処理
      useGLTF.clear('https://data.curatedartshow.com/models/lowPolyCar.glb');
    };
  }, []);

  useLayoutEffect(() => {
    //seat
    materials['Material.001'].color.set('black');
    materials['Material.001'].roughness = 0.2;

    // //glass
    // materials['Material.003'].color.set('black');

    // //body
    // materials['Material.006'].color.set('black');
    // materials['Material.006'].roughness = 1;
    // materials['Material.007'].roughness = 1;
    // materials['Material.007'].color.set('black');

    // materials['Material.008'].color.set('orange');
    // materials['Material.009'].color.set('white');

    //wheel
    // materials['Material.014'].color.set('silver');
    // materials['Material.016'].color.set('silver');
    // materials['Material.015'].color.set('silver');

    scene.traverse((obj) => {
      if (obj.isMesh) obj.castShadow = obj.receiveShadow = true;
    });

    return () => {
      // マテリアルの解放
      Object.values(materials).forEach((material) => {
        material.dispose();
      });

      // シーンからオブジェクトの削除
      scene.traverse((obj) => {
        if (obj.isMesh) {
          obj.geometry.dispose();
          obj.material.dispose();
          scene.remove(obj);
        }
      });
    };
  }, []);

  return <Clone object={scene} {...props} castShadow />;
};

export default Car;
