import {
  BakeShadows,
  Cloud,
  Environment,
  OrbitControls,
  Sparkles,
  useHelper,
} from '@react-three/drei';
import { EffectComposer } from '@react-three/postprocessing';
import React, { Suspense, useContext, useRef } from 'react';
import Loader from '../Loader';
import Items from '../Items';

import MovingSpotLight from '../lights/MovingSpotLight';
import * as THREE from 'three';

import { ExhibitionControllerContext } from '../../../context/ExhibitionControllerContext';
import { ExhibitionContext } from '../../../context/ExhibitionContext';
import SpaceFloor from '../floors/SpaceFloor';
import Moon from '../models/Moon';
import SFBuilding1 from '../models/SFBuilding1';
import SFBuilding2 from '../models/SFBuilding2';
import SFBuilding3 from '../models/SFBuilding3';
import Meteorite1 from '../models/Meteorite1';
import Jupiter from '../models/Jupiter';
import Saturn from '../models/Saturn';
import SaturnOrbiter from '../models/SaturnOrbiter.glb';
import LowPolyAstronaut from '../models/LowPolyAstronaut';
import SilhouetteGroup from '../SilhouetteGroup';

// import MovingSpotLight from '../lights/MovingSpotLight';

const Space = () => {
  const { exhibitionItems, exhibitionSettings } = useContext(ExhibitionContext);
  const { currentItemId, mode } = useContext(ExhibitionControllerContext);
  const bgColor = '#191920';

  const directLightIntensity = 0.8;
  const ambientLightIntensity = 0.6;

  //lightのdebug用
  // const dirLight = useRef(null);
  // useHelper(dirLight, THREE.DirectionalLightHelper, '#fff');

  return (
    <>
      {/* <OrbitControls /> */}
      {/* <BakeShadows /> */}
      <EffectComposer>{/* <Bloom intensity={0.1} /> */}</EffectComposer>
      <Suspense fallback={<Loader />}>
        <color attach='background' args={[`${bgColor}`]} />
        <fog attach='fog' args={[`${bgColor}`, 6, 75]} />
        <directionalLight
          // ref={dirLight}
          position={[0, 2, 20]}
          target-position={[0, 2.5, 0]}
          intensity={directLightIntensity}
          color={'#110073'}
          // color={'#fcf8cc'}
          // color={'#ffffff'}
          castShadow
        />

        <ambientLight intensity={ambientLightIntensity} color={'#ffffff'} />

        {mode == 'HIGH' && currentItemId != null && (
          <MovingSpotLight
            currentItemId={currentItemId}
            opacity={0.12}
            anglePower={1}
            angle={0.35}
            // color={exhibitionSettings?.spotLightColorCode}
          />
        )}

        <LowPolyAstronaut
          scale={0.3}
          rotation={[0, Math.PI, -Math.PI / 12]}
          position={[10, 1, -5]}
        />
        <LowPolyAstronaut
          scale={0.3}
          rotation={[0, Math.PI, Math.PI / 18]}
          position={[43, 1, -5]}
        />
        {/* <SFBuilding1
          scale={7}
          position={[-5, -0.7, -9]}
          rotation={[0, -Math.PI / 2, 0]}
        />
        <SFBuilding2
          scale={1}
          position={[30, -0.5, -6]}
          rotation={[0, -Math.PI / 2, 0]}
        />
        <SFBuilding3
          scale={1}
          position={[56, -0.7, -14]}
          rotation={[0, -Math.PI, 0]}
        /> */}
        <Moon position={[-35, 18, -45]} scale={5} />
        <group>
          {/* <Meteorite1 scale={0.01} position={[8, 22, -55]} />
          <Meteorite1 scale={0.015} position={[6, 20, -55]} /> */}
          <Saturn
            position={[20, 20, -65]}
            rotation={[Math.PI / 7, 0, Math.PI / 10]}
            scale={7}
          />
          <SaturnOrbiter
            rotation={[0, 0, Math.PI / 5]}
            position={[36, 26, -65]}
            scale={1.4}
          />
        </group>
        <Jupiter position={[90, 35, -55]} scale={0.8} />

        <Sparkles
          position={[0, 0, -10]}
          count={300}
          scale={40}
          size={8}
          speed={0.6}
          opacity={1}
          color={'skyblue'}
        />
        <Sparkles
          position={[40, 0, -10]}
          count={200}
          scale={40}
          size={8}
          speed={0.6}
          opacity={1}
          color={'orange'}
        />

        <group position={[0.2, -0.5, 0]}>
          <Items items={exhibitionItems} />
          <SilhouetteGroup />

          {/* floor */}
          <SpaceFloor />
        </group>
        <Environment
          background
          backgroundIntensity={0.1}
          backgroundBlurriness={0}
          files={
            'https://data.curatedartshow.com/hdri/NightSkyHDRI009_1K-HDR.exr'
          }
        />
      </Suspense>
    </>
  );
};

export default Space;
