import React from 'react';

// position={[0, 0, -0.5]}
const ClassicWall = () => {
  return (
    <>
      <group>
        <mesh position={[0, 1.8, 2]}>
          <planeGeometry args={[150, 3]} receiveShadow />
          <meshStandardMaterial color='#b34053' roughness={1} metalness={0.7} />
        </mesh>
        {/* <mesh position={[0, 0, 2.9]}>
          <planeGeometry args={[50, 5]} receiveShadow />
          <meshStandardMaterial color='#333' roughness={1} metalness={0.7} />
        </mesh> */}
      </group>
    </>
  );
};

export default ClassicWall;
