import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const useBlockNavigation = () => {
  const { t } = useTranslation();
  const handlePopstate = () => {
    document.getElementById('discard_exhibition_modal').showModal();

    // const isDiscardedOK = window.confirm(t('gallery-modal.discard-desc'));
    // if (isDiscardedOK) {
    //   // OKの場合、historyAPIで戻るを実行します。
    //   window.history.back();
    // }
    // // キャンセルの場合、 ダミー履歴を挿入して「戻る」を1回分吸収できる状態にする
    // window.history.pushState(null, '', null);
  };

  useEffect(() => {
    // ダミー履歴を挿入して「戻る」を1回分吸収できる状態にする
    window.history.pushState(null, '', null);
    window.addEventListener('popstate', handlePopstate, false);

    // 他のページに影響しないようclear
    return () => {
      window.removeEventListener('popstate', handlePopstate, false);
    };
  }, []);
};
