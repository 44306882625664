import React, { useMemo } from 'react';
import { useGLTF } from '@react-three/drei';

export default function CustomFrame1(props) {
  const { nodes, materials } = useGLTF(
    'https://data.curatedartshow.com/models/frames/custom_thick_frame.glb'
  );

  const { frameColor, matColor } = props;

  // useMemo でマテリアルをキャッシュし、frameColor, matColor が変わったときに再生成
  const frameMaterial = useMemo(() => {
    const material = materials['default_material210.001'].clone();
    material.color.set(frameColor);
    material.metalness = 1;
    material.roughness = 3;
    material.needsUpdate = true;
    return material;
  }, [frameColor, materials]);

  const mat1Material = useMemo(() => {
    const material = materials['default_material210.001'].clone();
    material.color.set(matColor);
    material.metalness = 1;
    material.roughness = 3;
    material.needsUpdate = true;
    return material;
  }, [matColor, materials]);

  const mat2Material = useMemo(() => {
    const material = materials['default_material210.001'].clone();
    material.color.set(matColor);
    material.metalness = 1;
    material.roughness = 5;
    material.needsUpdate = true;
    return material;
  }, [matColor, materials]);

  return (
    <group {...props} dispose={null}>
      <group
        position={[0, -0.135, -0.007]}
        rotation={[-0.012, 0, 0]}
        scale={0.984}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Frame.geometry}
          material={frameMaterial}
          position={[0, 0.043, -0.013]}
          rotation={[0.02, 0, 0]}
          scale={[2.5, 3.056, 0.1]}
        />
        <group
          position={[-0.008, 0.039, 0.021]}
          rotation={[0.094, -1.175, 0.005]}
          scale={1.017}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mat001.geometry}
            material={mat1Material}
            position={[-0.03, 0.304, -0.02]}
            rotation={[-0.182, 1.17, 0.166]}
            scale={[0.769, 0.813, 5]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mat2.geometry}
            material={mat2Material}
            position={[-0.03, 0.304, -0.02]}
            rotation={[-0.182, 1.17, 0.166]}
            scale={[0.809, 0.856, 0.779]}
          />
        </group>
      </group>
    </group>
  );
}

useGLTF.preload(
  'https://data.curatedartshow.com/models/frames/custom_thick_frame.glb'
);
