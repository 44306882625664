import { Html, useProgress } from '@react-three/drei';
import React from 'react';

const Loader = () => {
  const { progress } = useProgress();

  return (
    <Html center>
      <p className='absolute left-1/2 top-40 z-[10] hidden -translate-x-1/2 -translate-y-1/2 transform text-white'>
        {Math.floor(progress)}%
      </p>
    </Html>
  );
};

export default Loader;
