import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useTexture } from '@react-three/drei';
import * as THREE from 'three';

const MarbleObject = () => {
  const textureProps = useTexture({
    // map: `https://data.curatedartshow.com/texture/walls/MarbleSlabGrey/basecolor.jpg`,
    // normalMap: `https://data.curatedartshow.com/texture/walls/MarbleSlabGrey/normal.jpg`,
    // roughnessMap: `https://data.curatedartshow.com/texture/walls/MarbleSlabGrey/roughness.jpg`,

    map: `https://data.curatedartshow.com/texture/floors/Marble010/basecolor.jpg`,
    normalMap: `https://data.curatedartshow.com/texture/floors/Marble010/normal.jpg`,
    roughnessMap: `https://data.curatedartshow.com/texture/floors/Marble010/roughness.jpg`,
    displacementMap: `https://data.curatedartshow.com/texture/floors/Marble010/height.jpg`,
  });
  // const textureProps = useTexture({
  //   map: `https://data.curatedartshow.com/texture/walls/RoughPaint/basecolor.jpg`,
  //   normalMap: `https://data.curatedartshow.com/texture/walls/RoughPaint/normal.jpg`,
  //   roughnessMap: `https://data.curatedartshow.com/texture/walls/RoughPaint/roughness.jpg`,
  //   displacementMap: `https://data.curatedartshow.com/texture/walls/RoughPaint/height.jpg`,
  // });

  useLayoutEffect(() => {
    Object.values(textureProps).forEach((texture) => {
      texture.wrapS = THREE.RepeatWrapping;
      texture.wrapT = THREE.RepeatWrapping;
      texture.repeat.set(200, 1);
      // texture.needsUpdate = true; // 必要な場合は強制的に更新
    });
  }, [textureProps]);

  const mesh = useRef();
  useEffect(() => {
    if (mesh.current) {
      mesh.current.geometry.setAttribute(
        'uv2',
        new THREE.BufferAttribute(mesh.current.geometry.attributes.uv.array, 2)
      );
    }
  }, [mesh.current]);

  return (
    <mesh receiveShadow ref={mesh} position={[0, 0.2, -0.9]}>
      <boxGeometry args={[200, 0.8, 0.2]} />
      <meshStandardMaterial
        displacementScale={0}
        aoMapIntensity={2}
        roughness={0.8}
        normalScale={new THREE.Vector2(2, 1)}
        metalness={0.1}
        color={'#d8d8d8'}
        {...textureProps}
      />
    </mesh>
  );
};

export default MarbleObject;
