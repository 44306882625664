import { Trans, useTranslation } from 'react-i18next';
import { ExhibitionContext } from '../../../../context/ExhibitionContext';
import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
const SILHOUETTES = [
  {
    value: 1,
    label: 'Man 1',
    labelJA: '男性 1',
    isPro: false,
  },
  {
    value: 2,
    label: 'Man 2',
    labelJA: '男性 2',
    isPro: true,
  },
  {
    value: 3,
    label: 'Man 3',
    labelJA: '男性 3',
    isPro: true,
  },
  {
    value: 4,
    label: 'Woman 1',
    labelJA: '女性 1',
    isPro: false,
  },
  {
    value: 5,
    label: 'Woman 2',
    labelJA: '女性 2',
    isPro: true,
  },
  {
    value: 6,
    label: 'Woman 3',
    labelJA: '女性 3',
    isPro: true,
  },
  {
    value: 7,
    label: 'Child 1',
    labelJA: '子ども 1',
    isPro: false,
  },
  {
    value: 8,
    label: 'Child 2',
    labelJA: '子ども 2',
    isPro: true,
  },
  {
    value: 9,
    label: 'Child 3',
    labelJA: '子ども 3',
    isPro: true,
  },
];

export const Silhouette = () => {
  const { i18n } = useTranslation();
  const user = useSelector((state) => state.user.currentUser);

  const { exhibitionSettings, setExhibitionSettings } =
    useContext(ExhibitionContext);

  const {
    //product ID
    REACT_APP_STRIPE_PREMIUM_CAS_MEMBER_PRODUCT_ID: PREMIUM_ID,
    REACT_APP_STRIPE_VIP_CAS_MEMBER_PRODUCT_ID: VIP_ID,
  } = process.env;

  const [plan, setPlan] = useState(null);

  const [filteredSilhouettes, setFilteredSilhouettes] = useState([]);
  const [availableSilhouettes, setAvailableSilhouettes] = useState([]);

  useEffect(() => {
    if (user?.userPlans?.length > 0) {
      // 初期値を設定するための変数
      let foundPlan = 'FREE';

      // userPlans配列の各要素をチェック
      user.userPlans.forEach((el) => {
        if (el.SubscriptionPlans.productId === PREMIUM_ID) {
          foundPlan = 'PREMIUM';
        } else if (el.SubscriptionPlans.productId === VIP_ID) {
          foundPlan = 'VIP';
        }
      });

      setPlan(foundPlan);
    } else {
      setPlan('FREE');
    }
  }, [user]);

  useEffect(() => {
    //FREE: 1,4,7
    //PREMIUM: 1,2,4,5,7,8
    //VIP: all
    const filterSilhouettesByPlan = (plan, silhouettes) => {
      switch (plan) {
        case 'FREE':
          return silhouettes.filter(({ value }) => [1, 4, 7].includes(value));
        case 'PREMIUM':
          return silhouettes.filter(({ value }) =>
            [1, 2, 4, 5, 7, 8].includes(value)
          );
        case 'VIP':
          return silhouettes; //全て
        default:
          return [];
      }
    };

    const filteredSilhouettesTmp = filterSilhouettesByPlan(plan, SILHOUETTES);
    setFilteredSilhouettes(filteredSilhouettesTmp);
    setAvailableSilhouettes(filteredSilhouettesTmp.map((item) => item.value));
  }, [SILHOUETTES, plan]);

  const handleChangeSilhouette = (value) => {
    //必要であればvalitationを追加する

    setExhibitionSettings((prevState) => ({
      ...prevState,
      silhouette: value,
    }));
  };

  const handleChangeSilhouettes = (value) => {
    //必要であればvalitationを追加する

    setExhibitionSettings((prevState) => ({
      ...prevState,
      silhouettes: value,
    }));
  };

  const handleSilhouettesSelect = (index) => {
    const updatedCheckedItems = exhibitionSettings.silhouettes?.includes(index)
      ? exhibitionSettings.silhouettes.filter((item) => item !== index)
      : [...exhibitionSettings.silhouettes, index];

    if (updatedCheckedItems.length === 0) {
      handleChangeSilhouette(false);
    }

    handleChangeSilhouettes(updatedCheckedItems);
  };

  return (
    <div className=''>
      <p className='pb-8 text-xl font-semibold'>
        <Trans i18nKey={'gallery.silhouettes.ttl'} />
      </p>

      <p className='mb-5'>
        <Trans i18nKey={'gallery.silhouettes.desc'} />
      </p>
      <div className='label justify-start gap-2'>
        <span className='label-text text-base'>
          <Trans i18nKey={'gallery.silhouettes.show'} />
        </span>
        <input
          checked={exhibitionSettings.silhouette}
          type='checkbox'
          className='toggle'
          onChange={() => {
            if (exhibitionSettings.silhouette) {
              handleChangeSilhouettes([]);
            } else {
              handleChangeSilhouettes(availableSilhouettes);
            }
            handleChangeSilhouette(!exhibitionSettings.silhouette);
          }}
        />
      </div>

      <div
        className={`${
          exhibitionSettings.silhouette
            ? 'pointer-events-auto cursor-auto opacity-100'
            : 'pointer-events-none cursor-not-allowed opacity-30'
        }`}
      >
        <p className='my-4 font-bold'>
          <Trans i18nKey={'gallery.silhouettes.manage'} />
        </p>
        <div className='flex flex-col'>
          {filteredSilhouettes.map((el) => (
            <div className='form-control' key={`silhouette-${el.value}`}>
              <label className='label cursor-pointer justify-start gap-3'>
                <input
                  type='checkbox'
                  className='checkbox'
                  checked={exhibitionSettings.silhouettes.includes(el.value)}
                  onChange={() => handleSilhouettesSelect(el.value)}
                />
                <span className='label-text'>
                  {i18n.language === 'ja' ? el.labelJA : el.label}
                </span>
              </label>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
