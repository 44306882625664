import React from 'react';
import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';

const NotFound = () => {
  return (
    <>
      <div className='mx-auto h-full w-full max-w-[25rem] pb-40 pt-10'>
        <div className='flex flex-col items-center justify-center gap-8'>
          <h1 className='text-3xl font-extrabold'>
            <Trans i18nKey={'404.not-found'} />
          </h1>
          <Link to='/' className='btn btn-primary'>
            <Trans i18nKey={'btn.back-home'} />
          </Link>
        </div>
        <img src='/assets/img/404.webp' className='w-full' alt='' />
      </div>
    </>
  );
};

export default NotFound;
