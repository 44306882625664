import {
  ChevronLeftIcon,
  CubeIcon,
  EyeSlashIcon,
  FilmIcon,
  LightBulbIcon,
  MusicalNoteIcon,
  UserIcon,
  ViewfinderCircleIcon,
} from '@heroicons/react/24/outline';
import React, { useContext } from 'react';
import { ExhibitionContext } from '../../../../context/ExhibitionContext';
import { Trans } from 'react-i18next';

const MobileMenu = ({ selectMenu, moveTo }) => {
  const { exhibitionSettings, setExhibitionSettings } =
    useContext(ExhibitionContext);

  return (
    <div className='flex h-full flex-col justify-center px-4 py-6'>
      <div className='flex flex-col items-center gap-8'>
        <p className='text-center text-xs text-gray-500'>
          <Trans i18nKey={'gallery.mobile-menu.tip'} />
        </p>
        {/* navigation icon section */}
        <div className='flex flex-shrink-0 flex-wrap content-center items-center justify-center gap-x-[0.75rem] gap-y-[0.5rem] self-stretch text-base-content'>
          <button
            className='flex h-[4.5rem] w-[4.5rem] flex-col items-center gap-1'
            onClick={() => selectMenu('Theme')}
          >
            <CubeIcon className='h-8 w-8 shrink-0' />
            <p className='text-xs text-base-content'>
              <Trans i18nKey={'gallery.mobile-menu.theme'} />
            </p>
          </button>
          <button
            className='flex h-[4.5rem] w-[4.5rem] flex-col items-center gap-1'
            onClick={() => selectMenu('Picture Frames')}
          >
            <ViewfinderCircleIcon className='h-8 w-8 shrink-0' />
            <p className='text-xs text-base-content'>
              <Trans i18nKey={'gallery.mobile-menu.picture-frames'} />
            </p>
          </button>
          <button
            className={`flex h-[4.5rem] w-[4.5rem] flex-col items-center gap-1 ${
              exhibitionSettings.theme != 'CUSTOM' && 'opacity-20'
            }`}
            onClick={() => selectMenu('Functional Light')}
            disabled={exhibitionSettings.theme != 'CUSTOM'}
          >
            <LightBulbIcon className='h-8 w-8 shrink-0' />
            <p className='text-xs text-base-content'>
              <Trans i18nKey={'gallery.mobile-menu.functional-light'} />
            </p>
          </button>
          <button
            className={`flex h-[4.5rem] w-[4.5rem] flex-col items-center gap-1 ${
              exhibitionSettings.theme != 'CUSTOM' && 'opacity-20'
            }`}
            onClick={() => selectMenu('Invisible Light')}
            disabled={exhibitionSettings.theme != 'CUSTOM'}
          >
            <EyeSlashIcon className='h-8 w-8 shrink-0' />
            <p className='text-xs text-base-content'>
              <Trans i18nKey={'gallery.mobile-menu.invisible-light'} />
            </p>
          </button>
          <button
            className='flex h-[4.5rem] w-[4.5rem] flex-col items-center gap-1'
            onClick={() => selectMenu('Silhouettes')}
          >
            <UserIcon className='h-8 w-8 shrink-0' />
            <p className='text-xs text-base-content'>
              <Trans i18nKey={'gallery.mobile-menu.silhouettes'} />
            </p>
          </button>
          <button
            className='flex h-[4.5rem] w-[4.5rem] flex-col items-center gap-1'
            onClick={() => selectMenu('Music')}
          >
            <MusicalNoteIcon className='h-8 w-8 shrink-0' />
            <p className='text-xs text-base-content'>
              <Trans i18nKey={'gallery.mobile-menu.music'} />
            </p>
          </button>
          <button
            className='flex h-[4.5rem] w-[4.5rem] flex-col items-center gap-1'
            onClick={() => selectMenu('Entrance Video')}
          >
            <FilmIcon className='h-8 w-8 shrink-0' />
            <p className='text-xs text-base-content'>
              <Trans i18nKey={'gallery.mobile-menu.entrance-video'} />
            </p>
          </button>
        </div>
        {/* back button */}
        <button className='btn btn-sm' onClick={() => moveTo('')}>
          <ChevronLeftIcon className='h-5 w-5 shrink-0' />
          <Trans i18nKey={'gallery.mobile-menu.arrange-artworks'} />
        </button>
      </div>
    </div>
  );
};

export default MobileMenu;
