import React, { useMemo } from 'react';
import { useGLTF } from '@react-three/drei';

export default function CustomFrame3(props) {
  const { nodes, materials } = useGLTF(
    'https://data.curatedartshow.com/models/frames/custom_slim_frame.glb'
  );

  const { frameColor, matColor } = props;

  // useMemo でマテリアルをキャッシュし、frameColor, matColor が変わったときに再生成
  const frameMaterial = useMemo(() => {
    const material = materials['default_material'].clone();
    material.color.set(frameColor);
    material.metalness = 1;
    material.roughness = 3;
    material.needsUpdate = true;
    return material;
  }, [frameColor, materials]);

  const matMaterial = useMemo(() => {
    const material = materials['default_material'].clone();
    material.color.set(matColor);
    material.metalness = 1;
    material.roughness = 3;
    material.needsUpdate = true;
    return material;
  }, [matColor, materials]);

  return (
    <group {...props} dispose={null}>
      <group
        position={[-0.001, -0.107, -0.003]}
        rotation={[-0.017, 0, 0]}
        scale={0.984}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Frame.geometry}
          material={frameMaterial}
          position={[0, 0.043, -0.013]}
          rotation={[0.02, 0, 0]}
          scale={[2.5, 3.056, 0.1]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mat.geometry}
          material={matMaterial}
          position={[-0.001, 0.365, 0.009]}
          rotation={[0.019, 0, 0]}
          scale={[0.851, 0.89, 1.296]}
        />
      </group>
    </group>
  );
}

useGLTF.preload(
  'https://data.curatedartshow.com/models/frames/custom_slim_frame.glb'
);
