import React, { Suspense, useEffect, useLayoutEffect, useMemo } from 'react';
import { useLoader, useThree } from '@react-three/fiber';
import {
  Environment,
  useGLTF,
  useAnimations,
  Image,
  BakeShadows,
} from '@react-three/drei';
import { TextureLoader } from 'three/src/loaders/TextureLoader';
import * as THREE from 'three';

import CyberPunkFloor from '../floors/CyberPunkFloor.jsx';

import StreetLight from '../models/StreetLight.js';

import BackgroundLightPreview from '../lights/BackgroundLightPreview.js';
import LowPolyBuilding1 from '../models/LowPolyBuilding1.jsx';
import LowPolyBuilding6 from '../models/LowPolyBuilding6.jsx';
import LowPolyBuilding7 from '../models/LowPolyBuilding7.jsx';
import MetalFences from '../models/MetalFence.jsx';
import Cable from '../models/Cable.jsx';
import TrashCan from '../models/TrashCan.jsx';
import ElectricPole from '../models/ElectricPole.js';
import { Bloom, EffectComposer } from '@react-three/postprocessing';
import Man1 from '../models/Man1.jsx';
import Car from '../models/Car.js';

import ItemOnPreview from './ItemOnPreview.jsx';

const CyberPunkRoomPreview = ({
  theme,
  frame,
  showSilhouette,
  setSnapshot,
  items,
  frameColor,
  matColor,
}) => {
  const GOLDENRATIO = 1.61803398875;
  const { camera, gl } = useThree();

  const imageMap = useLoader(
    TextureLoader,
    items.length > 0 ? items[0]?.itemUrl : '/assets/img/sample_item_image.png'
  );

  const aspectRatio =
    imageMap.source?.data?.width / imageMap.source?.data?.height;
  const scaleValues = useMemo(() => {
    const scaleY = GOLDENRATIO;
    const scaleX = scaleY * aspectRatio;
    return { scaleX, scaleY };
  }, [aspectRatio]);
  const { scaleX, scaleY } = scaleValues;

  const bgColor = '#00aff2';

  const directLightIntensity = 0.5;
  const ambientLightIntensity = 1;

  useEffect(() => {
    return () => {
      gl.dispose();
      // imageMap.dispose();
      useLoader.clear(
        TextureLoader,
        items.length > 0
          ? items[0]?.itemUrl
          : '/assets/img/sample_item_image.png'
      );
    };
  }, [gl]);

  useEffect(() => {
    camera.lookAt(0, 0.8, 1.1);
  }, [camera]);

  return (
    <>
      <BakeShadows />
      <EffectComposer>
        <Bloom intensity={0.2} />
      </EffectComposer>
      <color attach='background' args={[`${bgColor}`]} />
      <fog attach='fog' args={['#00aff2', 1, 24]} />
      {/* lights */}
      <directionalLight
        intensity={directLightIntensity}
        castShadow
        color={'#d600ff'}
      />
      <directionalLight
        intensity={0.5}
        castShadow
        color={'#ff6666'}
        position={[-10, 20, -5]}
      />

      <directionalLight
        position={[0, 2, 20]}
        target-position={[0, 2.5, 0]}
        intensity={0.5}
        color={'#ffffff'}
      />
      <BackgroundLightPreview />
      <ambientLight intensity={ambientLightIntensity} color={'#001eff'} />
      {/* light */}
      <mesh scale={1} position={[6.5, 3.3, -5]} rotation-z={Math.PI * 0.5}>
        <boxGeometry args={[0.3, 7, 0.08]} />
        <meshStandardMaterial color={[9, 3, 3]} toneMapped={false} />
      </mesh>
      <mesh scale={1} position={[-6, 4.8, -4.5]}>
        <boxGeometry args={[1, 3, 0.08]} />
        <meshStandardMaterial color={[7, 2.6, 2.6]} toneMapped={false} />
      </mesh>
      <mesh scale={1} position={[1.5, 5.8, -6]}>
        <boxGeometry args={[2, 3, 0.08]} />
        <meshStandardMaterial color={[2.6, 2.6, 9]} toneMapped={false} />
      </mesh>
      <group>
        <Cable
          startPoint={new THREE.Vector3(-23, 4.7, -1.3)}
          endPoint={new THREE.Vector3(2, 4.9, -1.6)}
          radius={0.01}
          segments={10}
        />
        <Cable
          startPoint={new THREE.Vector3(-23, 3.9, -1.3)}
          endPoint={new THREE.Vector3(2, 4.1, -1.6)}
          radius={0.01}
          segments={10}
        />
      </group>
      <group>
        <Cable
          startPoint={new THREE.Vector3(2, 4.9, -1.6)}
          endPoint={new THREE.Vector3(23, 4.7, -1.3)}
          radius={0.01}
          segments={10}
        />
        <Cable
          startPoint={new THREE.Vector3(2, 4.1, -1.6)}
          endPoint={new THREE.Vector3(23, 3.9, -1.3)}
          radius={0.01}
          segments={10}
        />
      </group>
      <StreetLight
        scale={0.65}
        position={[4, -1, -0.5]}
        rotation-y={Math.PI * 0.5}
      />
      <ElectricPole
        position={[2, -1, -1.5]}
        scale={0.9}
        rotation-y={Math.PI * 0.5}
      />
      <TrashCan scale={0.15} position={[5, 0, -2]} />
      <Car scale={1.8} position={[-3.5, -0.5, -8]} rotation-y={Math.PI} />
      <MetalFences />
      <LowPolyBuilding1 scale={1.9} position={[-12, 0, -10]} />
      <LowPolyBuilding6 scale={0.0018} position={[-10.5, -0.5, -16.5]} />
      <LowPolyBuilding7 scale={0.26} position={[11, -1.6, -5.8]} />
      <group position={[0, -0.5, 0]}>
        {items.length == 1 && (
          <ItemOnPreview
            position={[-0.2, 0.85, 3]}
            item={items[0]}
            frame={frame}
            frameColor={frameColor}
            matColor={matColor}
          />
        )}
        {items.length == 2 && (
          <>
            <ItemOnPreview
              position={[-1.3, 0.85, 3]}
              item={items[0]}
              frame={frame}
              frameColor={frameColor}
              matColor={matColor}
            />

            <ItemOnPreview
              position={[1.3, 0.85, 3]}
              item={items[1]}
              frame={frame}
              frameColor={frameColor}
              matColor={matColor}
            />
          </>
        )}
        {items.length >= 3 && (
          <>
            <ItemOnPreview
              position={[-3.6, 0.85, 3]}
              item={items[0]}
              frame={frame}
              frameColor={frameColor}
              matColor={matColor}
            />

            <ItemOnPreview
              position={[-0.2, 0.85, 3]}
              item={items[1]}
              frame={frame}
              frameColor={frameColor}
              matColor={matColor}
            />

            <ItemOnPreview
              position={[3.6, 0.85, 3]}
              item={items[2]}
              frame={frame}
              frameColor={frameColor}
              matColor={matColor}
            />
          </>
        )}
        {showSilhouette && (
          <Man1 position={[-0.5, 0, 3.8]} scale={0.45} rotation-y={Math.PI} />
        )}

        {/* floor */}
        <CyberPunkFloor />
      </group>
      <Environment
        files={'https://data.curatedartshow.com/hdri/potsdamer_platz_1k.hdr'}
      />
    </>
  );
};

export default CyberPunkRoomPreview;
