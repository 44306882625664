import { useHelper } from '@react-three/drei';
import React, { useLayoutEffect, useRef } from 'react';
import * as THREE from 'three';
const BackgroundLightPreview = () => {
  const ref = useRef();

  // useHelper(ref, THREE.DirectionalLightHelper, 1);

  useLayoutEffect(() => {
    ref.current.position.set(35, 10, -10);
    ref.current.target.position.x = 35;
    ref.current.target.updateMatrixWorld();
    // ref.current.castShadow = true;
  }, []);
  return (
    <directionalLight
      ref={ref}
      intensity={25}
      color={'#00b8ff'}
      position={[35, 10, -10]}
    />
  );
};

export default BackgroundLightPreview;
