import React from 'react';
import { Link } from 'react-router-dom';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { Trans, useTranslation } from 'react-i18next';

const SuspendAlert = ({ type }) => {
  const { i18n } = useTranslation();
  return (
    <div role='alert' className='alert alert-warning mb-8'>
      <ExclamationTriangleIcon className='h-6 w-6' />
      <div>
        <p className='w-full text-base font-bold'>
          <Trans i18nKey={'signin.restricted-ttl'} />
        </p>
        {i18n.language === 'ja' && type == 'PAYMENT' ? (
          <div className='w-full text-base font-normal'>
            お支払いが完了していないため、サインインできません。
            <Link to={'/contact'} className='underline'>
              サポート
            </Link>
            にお問い合わせください。
          </div>
        ) : (
          type == 'PAYMENT' && (
            <div className='w-full text-base font-normal'>
              You cannot sign in because your payment is not complete. Please{' '}
              <Link to={'/contact'} className='underline'>
                contact support
              </Link>{' '}
              for assistance.
            </div>
          )
        )}
        {i18n.language === 'ja' && type == 'ADMIN' ? (
          <div className='w-full text-base font-normal'>
            サインインできません。
            <Link to={'/contact'} className='underline'>
              サポート
            </Link>
            にお問い合わせください。
          </div>
        ) : (
          type == 'ADMIN' && (
            <div className='w-full text-base font-normal'>
              You cannot sign in. Please{' '}
              <Link to={'/contact'} className='underline'>
                contact support
              </Link>{' '}
              for assistance.
            </div>
          )
        )}
      </div>
      <Link to={'/contact'} className='btn !btn-neutral btn-sm'>
        <Trans i18nKey={'signin.restricted-btn'} />
      </Link>
    </div>
  );
};

export default SuspendAlert;
