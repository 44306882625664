import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import { authRequest } from '../../requestMethods';
import { updateProfile } from '../../redux/userRedux';
import { successToast } from '../../utils/toast';
import ChangeEmailModal from './components/ChangeEmailModal';
import ChangePasswordModal from './components/ChangePasswordModal';

const AccountSettings = () => {
  const { i18n, t } = useTranslation();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user.currentUser);

  const changeLanguage = async (lng) => {
    try {
      if (user) {
        const res = await authRequest.patch('/users/update-language', {
          language: lng,
        });

        dispatch(updateProfile(res.data));
        successToast(t('message.success.t21'));
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <div className='mx-auto !max-w-[25.8rem] px-4 pb-44 pt-10 sm:px-0'>
        <p className='mb-8 text-3xl font-[800]'>
          <Trans i18nKey={'account-settings.ttl'} />
        </p>
        <div className='divider' />
        <div className='flex items-center justify-between'>
          <div className='flex flex-col gap-1'>
            <p className='font-medium'>
              <Trans i18nKey={'account-settings.email'} />
            </p>
            <p className='text-xs'>{user.email}</p>
          </div>
          <button
            className='btn btn-sm'
            onClick={() => {
              document.getElementById('change_email_modal').showModal();
            }}
          >
            <Trans i18nKey={'account-settings.change'} />
          </button>
        </div>
        <div className='divider' />
        <div className='flex items-center justify-between'>
          <p className='font-medium'>
            <Trans i18nKey={'account-settings.password'} />
          </p>
          <button
            className='btn btn-sm'
            onClick={() => {
              document.getElementById('change_password_modal').showModal();
            }}
          >
            <Trans i18nKey={'account-settings.change'} />
          </button>
        </div>
        <div className='divider' />
        <div className='flex items-center justify-between'>
          <p className='font-medium'>
            <Trans i18nKey={'account-settings.language'} />
          </p>
          <select
            className='select select-bordered select-sm'
            value={user.language}
            onChange={(e) => changeLanguage(e.target.value)}
          >
            <option value={'en'}>
              {i18n.language == 'ja' ? '英語' : 'English'}
            </option>
            <option value={'ja'}>
              {i18n.language == 'ja' ? '日本語' : 'Japanese'}
            </option>
          </select>
        </div>
        <div className='divider' />
      </div>
      <ChangeEmailModal />
      <ChangePasswordModal />
    </>
  );
};

export default AccountSettings;
