import { authRequest, publicRequest } from '../requestMethods';

export const fetchActiveUsers = async (plan) => {
  try {
    const queryString = plan ? `?plan=${plan}` : '';
    const response = await authRequest.get(`/admin/users${queryString}`);
    return response.data;
  } catch (error) {
    console.error(`Failed on fetchActiveUsers request:`, error);
    throw error;
  }
};

export const fetchPublishedArtworks = async () => {
  try {
    //全ての公開済み作品を取得
    const response = await publicRequest.get('/items');
    return response.data;
  } catch (error) {
    console.error(`Failed on fetchPublishedArtworks request:`, error);
    throw error;
  }
};

export const fetchDeletedArtworks = async () => {
  try {
    //全てのinactive状態の作品を取得
    const response = await authRequest.get('/admin/inactive-items');
    return response.data;
  } catch (error) {
    console.error(`Failed on fetchDeletedArtworks request:`, error);
    throw error;
  }
};

export const fetchPublishedExhibitions = async () => {
  try {
    //全ての公開済み展示会を取得
    const response = await publicRequest.get('/rooms');
    return response.data;
  } catch (error) {
    console.error(`Failed on fetchPublishedExhibitions request:`, error);
    throw error;
  }
};

export const getFaqById = async (id) => {
  try {
    const { data } = await publicRequest.get(`/faq/${id}`);
    return data;
  } catch (error) {
    console.error(`Failed on getFaqById request:`, error);
    throw error;
  }
};

export const deleteFaqById = async (id) => {
  try {
    const { data } = await authRequest.delete(`/faq/${id}`);
    return data;
  } catch (error) {
    console.error(`Failed on deleteFaqById request:`, error);
    throw error;
  }
};

export const getAllPublishedFaqs = async () => {
  try {
    const { data } = await publicRequest.get(`/faq/published`);
    return data;
  } catch (error) {
    console.error(`Failed on getAllPublishedFaqs request:`, error);
    throw error;
  }
};

export const getAllFaqCategories = async (language) => {
  try {
    const { data } = await publicRequest.get(
      `/faq/category-with-faq?language=${language}`
    );
    return data;
  } catch (error) {
    console.error(`Failed on getAllFaqCategories request:`, error);
    throw error;
  }
};

export const createFaq = async (input) => {
  try {
    const { data } = await authRequest.post(`/faq`, input);
    return data;
  } catch (error) {
    console.error(`Failed on createFaq  request:`, error);
    throw error;
  }
};

export const updateFaq = async (id, input) => {
  try {
    const { data } = await authRequest.patch(`/faq/${id}`, input);
    return data;
  } catch (error) {
    console.error(`Failed on updateFaq  request:`, error);
    throw error;
  }
};

//指定のkeyでs3にファイルをアップロード　admin用
export const uploadToS3 = async (file, key) => {
  const blobToFile = (blob, originalFileName) => {
    // 元ファイルの拡張子を取得
    const fileExtension = originalFileName.split('.').pop(); // 例: 'png', 'jpg', etc.
    const newFileName = `image.${fileExtension}`; // 新しいファイル名に拡張子を適用

    // BlobをFileに変換して返す
    return new File([blob], newFileName, { type: blob.type });
  };

  // 使用例
  const modifiedFile = blobToFile(file, file.name);

  const response = await authRequest.post(
    '/s3/upload',
    { file: modifiedFile, key },
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  );

  return response.data;
};

export const getCeilingTextures = async () => {
  try {
    //ceiling textureを全て取得
    const response = await authRequest.get('/admin/textures/ceilings');
    return response.data;
  } catch (error) {
    console.error(`Failed on getCeilingTextures request:`, error);
    throw error;
  }
};

export const createCeiling = async (data) => {
  try {
    const response = await authRequest.post(`/admin/textures/ceilings`, data);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const updateCeiling = async (id, data) => {
  try {
    const response = await authRequest.patch(
      `/admin/textures/ceilings/${id}`,
      data
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const deleteCeiling = async (id) => {
  try {
    const response = await authRequest.delete(`/admin/textures/ceilings/${id}`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getWallTextures = async () => {
  try {
    //wall textureを全て取得
    const response = await authRequest.get('/admin/textures/walls');
    return response.data;
  } catch (error) {
    console.error(`Failed on getWallTextures request:`, error);
    throw error;
  }
};

export const createWall = async (data) => {
  try {
    const response = await authRequest.post(`/admin/textures/walls`, data);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const updateWall = async (id, data) => {
  try {
    const response = await authRequest.patch(
      `/admin/textures/walls/${id}`,
      data
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const deleteWall = async (id) => {
  try {
    const response = await authRequest.delete(`/admin/textures/walls/${id}`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getFloorTextures = async () => {
  try {
    //floor textureを全て取得
    const response = await authRequest.get('/admin/textures/floors');
    return response.data;
  } catch (error) {
    console.error(`Failed on getFloorTextures request:`, error);
    throw error;
  }
};

export const createFloor = async (data) => {
  try {
    const response = await authRequest.post(`/admin/textures/floors`, data);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const updateFloor = async (id, data) => {
  try {
    const response = await authRequest.patch(
      `/admin/textures/floors/${id}`,
      data
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const deleteFloor = async (id) => {
  try {
    const response = await authRequest.delete(`/admin/textures/floors/${id}`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const updateCuratorsSelection = async (id, info) => {
  try {
    const res = await authRequest.patch(`/admin/rooms/${id}/curators-selection`, info);
    return res.data;
  } catch (error) {
    console.error(`Failed on updateCuratorsSelection request:`, error);
    throw error;
  }
};