import { useState, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ChevronUpDownIcon } from '@heroicons/react/24/outline';

export const ArtworkViewsByLocation = ({
  GA4ArtworkData,
  isLoading,
  isVIP,
}) => {
  const { t } = useTranslation();
  const [countryOptions, setCountryOptions] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [rankedData, setRankedData] = useState([]);
  const [filteredRankedData, setFilteredRankedData] = useState([]);
  const [displayLimit, setDisplayLimit] = useState(25);
  const [showRowsOptions, setShowsRowOptions] = useState([]);
  const [sort, setSort] = useState({
    artwork: 'desc',
    country: 'desc',
    cityRegion: 'desc',
    view: 'desc',
    artworkDetails: 'desc',
    fullscreen: 'desc',
    uniqueUser: 'desc',
  });

  useEffect(() => {
    if (GA4ArtworkData) {
      //console.log('Artwork Locations: ', GA4ArtworkData);
      // 国選択を設定
      const countries = GA4ArtworkData.map((item) => item.country);
      setCountryOptions([t('analytics.countries'), ...countries]);
      // 表示データを設定
      const flatData = [];
      GA4ArtworkData.forEach((item) => {
        item.artworks.forEach((artwork) => {
          // ビュー数がない場合はスキップ
          if (artwork.totalViews < 1) {
            return;
          }
          // 都市データがない場合はRegionのみを表示
          if (artwork.cities.length < 1) {
            const fullScreenEvent = artwork.events.find(
              (item) => item.name.toLowerCase() === 'enter_fullscreen'
            );
            const openModalEvent = artwork.events.find(
              (item) => item.name.toLowerCase() === 'open_modal'
            );
            flatData.push({
              country: item.country,
              artworkTitle: artwork.title,
              cityName: '',
              regionName: artwork.region,
              totalViews: artwork.totalViews,
              fullScreenEventCount: fullScreenEvent ? fullScreenEvent.views : 0,
              openModalEventCount: openModalEvent ? openModalEvent.views : 0,
              totalUsers: artwork.totalUsers,
            });
          } else {
            artwork.cities.forEach((city) => {
              const fullScreenEvent = city.events.find(
                (item) => item.name.toLowerCase() === 'enter_fullscreen'
              );
              const openModalEvent = city.events.find(
                (item) => item.name.toLowerCase() === 'open_modal'
              );
              flatData.push({
                country: item.country,
                artworkTitle: artwork.title,
                cityName: city.city,
                regionName: artwork.region,
                totalViews: city.totalViews,
                fullScreenEventCount: fullScreenEvent
                  ? fullScreenEvent.views
                  : 0,
                openModalEventCount: openModalEvent ? openModalEvent.views : 0,
                totalUsers: city.totalUsers,
              });
            });
          }
        });
      });
      // ビュー数でソート（降順）
      const sortedData = flatData.sort((a, b) => b.totalViews - a.totalViews);
      // ランクを割り当て
      const ranked = sortedData.map((item, index) => ({
        rank: index + 1,
        ...item,
      }));
      setRankedData(ranked);
      setFilteredRankedData(ranked);
      makeShowRowsOptions(ranked);
    }
  }, [GA4ArtworkData]);

  // 表示数オプションを作成
  const makeShowRowsOptions = (data) => {
    const options = [
      {
        label: '25',
        value: '25',
      },
    ];
    if (25 < data.length) {
      options.push({
        label: '50',
        value: '50',
      });
    }
    if (100 < data.length) {
      options.push({
        label: '100',
        value: '100',
      });
    }
    options.push({
      label: 'All',
      value: data.length,
    });
    setShowsRowOptions(options);
  };

  const filterByCountry = () => {
    const newData =
      selectedCountry === ''
        ? rankedData
        : rankedData.filter((item) => item.country === selectedCountry);
    setFilteredRankedData(newData);
  };

  // 絞り込み
  useEffect(() => {
    filterByCountry();
  }, [selectedCountry, rankedData, t]);

  // 表示数変更時にソートをリセット
  const changeDisplayLimit = (limit) => {
    setDisplayLimit(limit);
  };

  // Artworkでソート
  const sortByArtwork = () => {
    const copy = [...filteredRankedData.slice(0, displayLimit)];
    copy.sort((a, b) => {
      const artworkComparison =
        sort.artwork === 'asc'
          ? a.artworkTitle.localeCompare(b.artworkTitle)
          : b.artworkTitle.localeCompare(a.artworkTitle);

      // countryが同じ場合、totalViewsで昇順・降順ソート
      if (artworkComparison === 0) {
        const viewComparison =
          sort.view === 'asc'
            ? b.totalViews - a.totalViews
            : a.totalViews - b.totalViews;
        // totalViewsが同じ場合、rankで昇順・降順ソート
        if (viewComparison === 0) {
          return b.rank - a.rank; // rankで昇順・降順ソート
        }
        return viewComparison;
      }
      return artworkComparison;
    });
    setFilteredRankedData(copy);
    setSort((prev) => ({
      ...prev,
      artwork: prev.artwork === 'asc' ? 'desc' : 'asc',
    }));
  };

  // Countryでソート
  const sortByCountry = () => {
    const copy = [...filteredRankedData.slice(0, displayLimit)];
    copy.sort((a, b) => {
      const countryComparison =
        sort.country === 'asc'
          ? a.country.localeCompare(b.country)
          : b.country.localeCompare(a.country);

      // countryが同じ場合、totalViewsで昇順・降順ソート
      if (countryComparison === 0) {
        const viewComparison =
          sort.view === 'asc'
            ? b.totalViews - a.totalViews
            : a.totalViews - b.totalViews;
        // totalViewsが同じ場合、rankで昇順・降順ソート
        if (viewComparison === 0) {
          return b.rank - a.rank; // rankで昇順・降順ソート
        }
        return viewComparison;
      }
      return countryComparison;
    });
    setFilteredRankedData(copy);
    setSort((prev) => ({
      ...prev,
      country: prev.country === 'asc' ? 'desc' : 'asc',
    }));
  };

  // City/Regionでソート
  const sortByCityRegion = () => {
    const copy = [...filteredRankedData.slice(0, displayLimit)];
    copy.sort((a, b) => {
      const cityComparison =
        sort.cityRegion === 'asc'
          ? a.cityName.localeCompare(b.cityName)
          : b.cityName.localeCompare(a.cityName);

      // cityNameが同じ場合にregionNameで昇順・降順ソート
      if (cityComparison === 0) {
        return sort.cityRegion === 'asc'
          ? a.regionName.localeCompare(b.regionName)
          : b.regionName.localeCompare(a.regionName);
      }
      return cityComparison;
    });
    setFilteredRankedData(copy);
    setSort((prev) => ({
      ...prev,
      cityRegion: prev.cityRegion === 'asc' ? 'desc' : 'asc',
    }));
  };

  // Viewsでソート
  const sortByViews = () => {
    const copy = [...filteredRankedData.slice(0, displayLimit)];
    copy.sort((a, b) => {
      const viewComparison =
        sort.view === 'asc'
          ? b.totalViews - a.totalViews
          : a.totalViews - b.totalViews;
      // totalViewsが同じ場合、rankで昇順ソート
      if (viewComparison === 0) {
        return a.rank - b.rank;
      }
      return viewComparison;
    });
    setFilteredRankedData(copy);
    setSort((prev) => ({
      ...prev,
      view: prev.view === 'asc' ? 'desc' : 'asc',
    }));
  };

  // Artwork Detailsでソート
  const sortByArtworkDetails = () => {
    const copy = [...filteredRankedData.slice(0, displayLimit)];
    copy.sort((a, b) => {
      const detailsComparison =
        sort.artworkDetails === 'asc'
          ? b.openModalEventCount - a.openModalEventCount
          : a.openModalEventCount - b.openModalEventCount;
      // artworkDetailsが同じ場合、rankで昇順ソート
      if (detailsComparison === 0) {
        return a.rank - b.rank;
      }
      return detailsComparison;
    });
    setFilteredRankedData(copy);
    setSort((prev) => ({
      ...prev,
      artworkDetails: prev.artworkDetails === 'asc' ? 'desc' : 'asc',
    }));
  };

  // Fullscreenでソート
  const sortByFullscreen = () => {
    const copy = [...filteredRankedData.slice(0, displayLimit)];
    copy.sort((a, b) => {
      const fullscreenComparison =
        sort.fullscreen === 'asc'
          ? b.fullScreenEventCount - a.fullScreenEventCount
          : a.fullScreenEventCount - b.fullScreenEventCount;
      // fullscreenが同じ場合、rankで昇順ソート
      if (fullscreenComparison === 0) {
        return a.rank - b.rank;
      }
      return fullscreenComparison;
    });
    setFilteredRankedData(copy);
    setSort((prev) => ({
      ...prev,
      fullscreen: prev.fullscreen === 'asc' ? 'desc' : 'asc',
    }));
  };

  // Unique Usersでソート
  const sortByUniqueUsers = () => {
    const copy = [...filteredRankedData.slice(0, displayLimit)];
    copy.sort((a, b) => {
      const uuComparison =
        sort.uniqueUser === 'asc'
          ? b.totalUsers - a.totalUsers
          : a.totalUsers - b.totalUsers;
      // uniqueUserが同じ場合、rankで昇順ソート
      if (uuComparison === 0) {
        return a.rank - b.rank;
      }
      return uuComparison;
    });
    setFilteredRankedData(copy);
    setSort((prev) => ({
      ...prev,
      uniqueUser: prev.uniqueUser === 'asc' ? 'desc' : 'asc',
    }));
  };

  return (
    <div>
      <div className='flex items-center gap-2'>
        <select
          className='select select-bordered w-full max-w-xs'
          onChange={(e) => setSelectedCountry(e.target.value)}
        >
          {countryOptions.map((item) => {
            return (
              <option
                value={item === t('analytics.countries') ? '' : item}
                key={item}
              >
                {item}
              </option>
            );
          })}
        </select>
      </div>
      <div className='mt-6 flex items-center gap-4'>
        <span className='label-text'>Show Rows:</span>
        {0 < showRowsOptions.length && (
          <select
            className='select select-bordered select-sm w-full max-w-32'
            onChange={(e) => changeDisplayLimit(Number(e.target.value))}
          >
            {showRowsOptions.map((item) => {
              return (
                <option
                  value={item.value}
                  key={`ArtworkViewsByLocation-${item.value}`}
                >
                  {item.label}
                </option>
              );
            })}
          </select>
        )}
      </div>
      <div className='mt-6 overflow-x-auto'>
        <table className='table'>
          {/* head */}
          <thead>
            <tr>
              <th>
                <Trans i18nKey={'analytics.rank'} />
              </th>
              <th className='min-w-[180px]'>
                <div className='flex gap-[0.625rem]'>
                  <Trans i18nKey={'analytics.artwork'} />
                  <ChevronUpDownIcon
                    className='h-4 w-4 shrink-0 cursor-pointer'
                    onClick={sortByArtwork}
                  />
                </div>
              </th>
              <th>
                <div className='flex gap-[0.625rem]'>
                  <Trans i18nKey={'analytics.country'} />
                  <ChevronUpDownIcon
                    className='h-4 w-4 shrink-0 cursor-pointer'
                    onClick={sortByCountry}
                  />
                </div>
              </th>
              <th>
                <div className='flex gap-[0.625rem]'>
                  <Trans i18nKey={'analytics.city-region'} />
                  <ChevronUpDownIcon
                    className='h-4 w-4 shrink-0 cursor-pointer'
                    onClick={sortByCityRegion}
                  />
                </div>
              </th>
              <th>
                <div className='flex gap-[0.625rem]'>
                  <Trans i18nKey={'analytics.views'} />
                  <ChevronUpDownIcon
                    className='h-4 w-4 shrink-0 cursor-pointer'
                    onClick={sortByViews}
                  />
                </div>
              </th>
              <th>
                <div className='flex gap-[0.625rem]'>
                  <Trans i18nKey={'analytics.artwork-details'} />
                  <ChevronUpDownIcon
                    className='h-4 w-4 shrink-0 cursor-pointer'
                    onClick={sortByArtworkDetails}
                  />
                </div>
              </th>
              <th>
                <div className='flex gap-[0.625rem]'>
                  <Trans i18nKey={'analytics.fullscreen'} />
                  <ChevronUpDownIcon
                    className='h-4 w-4 shrink-0 cursor-pointer'
                    onClick={sortByFullscreen}
                  />
                </div>
              </th>
              {isVIP && (
                <th>
                  <div className='flex gap-[0.625rem]'>
                    <Trans i18nKey={'analytics.uu'} />
                    <ChevronUpDownIcon
                      className='h-4 w-4 shrink-0 cursor-pointer'
                      onClick={sortByUniqueUsers}
                    />
                  </div>
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {!isLoading ? (
              filteredRankedData.length > 0 ? (
                filteredRankedData.slice(0, displayLimit).map((row, index) => {
                  return (
                    <tr
                      key={`ArtworkViewsByLocation-${row.artworkTitle}-${row.cityName}_${index}`}
                    >
                      <td className='font-bold'>{row.rank}</td>
                      <td className='font-bold'>{row.artworkTitle}</td>
                      <td>{row.country}</td>
                      <td>
                        {row.cityName === ''
                          ? `${row.regionName}`
                          : `${row.cityName}, ${row.regionName}`}
                      </td>
                      <td>{row.totalViews}</td>
                      <td>{row.openModalEventCount.toLocaleString()}</td>
                      <td>{row.fullScreenEventCount.toLocaleString()}</td>
                      {isVIP && <td>{row.totalUsers.toLocaleString()}</td>}
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={3} className='text-center'>
                    <Trans i18nKey={'analytics.no-data'} />
                  </td>
                </tr>
              )
            ) : (
              <tr>
                <td className='loading loading-spinner loading-xs text-primary'></td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};
