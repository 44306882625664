import {
  ArrowPathIcon,
  EyeIcon,
  EyeSlashIcon,
  Square2StackIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import React, { useMemo, useState, useContext, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { errorToast, successToast } from '../../../utils/toast';
import { ChevronLeftIcon } from '@heroicons/react/24/outline';
import { FooterSimple } from '../../../components/FooterSimple';
import CustomHeaderDesktop from '../components/CustomHeaderDesktop';
import CustomHeaderMobile from '../components/CustomHeaderMobile';
import { ExhibitionContext } from '../../../context/ExhibitionContext';
import {
  validateGalleryName,
  validateGalleryDescription,
  validateRoomId,
  validateExhibitionPassword,
} from '../../../utils/validation';
import Preview from '../../../components/r3f/Preview';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import {
  getExhibitionByExhibitionId,
  publishExhibition,
  saveDraftExhibition,
  unpublishExhibition,
} from '../../../services/Exhibition';
import InactiveRoomModal from '../../../components/room/InactiveRoomModal';

export const BasicInfo = ({
  pcHeaderTitle,
  currentMenu,
  moveTo,
  editMode,
  handleSubmit,
  plan,
  hasReachedMaxPublish,
  hasReachedMaxCreation,
}) => {
  const {
    REACT_APP_MAX_PUBLISHED_EXHIBITION_MEMBER: MAX_PUBLISHED_EXHIBITION_FREE,
    REACT_APP_MAX_PUBLISHED_EXHIBITION_PREMIUM:
      MAX_PUBLISHED_EXHIBITION_PREMIUM,
    REACT_APP_MAX_PUBLISHED_EXHIBITION_VIP: MAX_PUBLISHED_EXHIBITION_VIP,
    REACT_APP_MAX_EXHIBITION_MEMBER: MAX_EXHIBITION_FREE,
    REACT_APP_MAX_EXHIBITION_PREMIUM: MAX_EXHIBITION_PREMIUM,
    REACT_APP_MAX_EXHIBITION_VIP: MAX_EXHIBITION_VIP,
  } = process.env;

  const {
    exhibitionInfo,
    setExhibitionInfo,
    exhibitionSettings,
    exhibitionItems,
    exhibitionSnapshot,
    setExhibitionSnapshot,
    isAgreedTerms,
    setIsAgreedTerms,
  } = useContext(ExhibitionContext);
  const {
    title,
    description,
    password,
    newPassword,
    roomId,
    isPrivate,
    showOnHome,
  } = exhibitionInfo;
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.currentUser);

  const [isEditMode, setIsEditMode] = useState(false);
  const [exhibitionId, setExhibitionId] = useState(null);
  const location = useLocation();
  const [showNewPasswordInput, setShowNewPasswordInput] = useState(false);
  const [previousExhibitionData, setPreviousExhibitionData] = useState(null);
  // エラー
  const [titleError, setTitleError] = useState('');
  const [descriptionError, setDescriptionError] = useState('');
  const [roomIdError, setRoomIdError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [newPasswordError, setNewPasswordError] = useState('');

  const getExhibition = async (roomId) => {
    const room = await getExhibitionByExhibitionId(roomId);
    setPreviousExhibitionData(room);
    setExhibitionId(room.id);
  };

  // save&draft処理
  const saveDraftMutation = useMutation({
    mutationFn: async () => {
      const items = exhibitionItems.map((item) => item.id);

      return await saveDraftExhibition(
        Number(exhibitionId),
        user.id,
        exhibitionSnapshot,
        items,
        exhibitionInfo,
        exhibitionSettings
      );
    },
    onSuccess: () => {
      navigate('/dashboard?tab=drafts');
      successToast(t('message.success.t16'));
    },
    onError: (err) => {
      if (err.response.data.statusCode == 400) {
        errorToast(err?.response?.data.message);
      } else {
        errorToast(
          'Something went wrong. We are aware of the problem and will try to fix it as soon as possible. Sorry for the inconvenience.'
        );
      }
    },
  });

  const saveExhibitionInfo = (key, value) => {
    setExhibitionInfo((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const validateSaveTitle = (title) => {
    const result = validateGalleryName(title);
    result.isValid ? setTitleError('') : setTitleError(result.message);
    saveExhibitionInfo('title', title);
  };

  const validateSaveDescription = (desc) => {
    const result = validateGalleryDescription(desc);
    result.isValid
      ? setDescriptionError('')
      : setDescriptionError(result.message);
    saveExhibitionInfo('description', desc);
  };

  const validateSaveRoomId = (id) => {
    const result = validateRoomId(id);
    result.isValid ? setRoomIdError('') : setRoomIdError(result.message);
    saveExhibitionInfo('roomId', id);
  };

  const validateSavePassword = (password) => {
    const result = validateExhibitionPassword(password);
    result.isValid ? setPasswordError('') : setPasswordError(result.message);
    saveExhibitionInfo('password', password);
  };

  const validateNewSavePassword = (newPassword) => {
    const result = validateExhibitionPassword(newPassword);
    result.isValid
      ? setNewPasswordError('')
      : setNewPasswordError(result.message);
    saveExhibitionInfo('newPassword', newPassword);
  };

  const handleChangeSnapshot = (value) => {
    setExhibitionSnapshot(value);
  };

  const toggleSlideshowAutoStart = () => {
    saveExhibitionInfo(
      'slideshowAutoStart',
      !exhibitionInfo.slideshowAutoStart
    );
  };

  const handleSaveDraft = () => {
    if (saveDraftMutation.isPending) {
      return;
    }
    saveDraftMutation.mutate();
  };

  const exhibitionLink = useMemo(() => {
    return roomId ? `https://curatedartshow.com/galleries/${roomId}` : '';
  }, [roomId]);

  const hasError = useMemo(() => {
    // requiredを満たしているか
    const isFilled =
      !!title &&
      !!description &&
      !!roomId &&
      ((isPrivate && !!password) || !isPrivate);
    const hasErrorMessage =
      !!titleError ||
      !!descriptionError ||
      !!roomIdError ||
      (isPrivate && !!passwordError);
    return hasErrorMessage || !isFilled ? true : false;
  }, [title, description, roomId, password, isPrivate]);

  useEffect(() => {
    if (location.pathname.split('/')[1] == 'create-exhibition') {
      setIsEditMode(false);
    } else {
      setIsEditMode(true);
      const roomId = location.pathname.split('/')[2];
      getExhibition(roomId);
    }
  }, [location]);

  // 編集の場合は同意済みで固定
  useEffect(() => {
    if (editMode) {
      setIsAgreedTerms(true);
    }
  }, [editMode]);

  return (
    <div className='flex h-full w-full flex-col'>
      <CustomHeaderDesktop
        displayText={pcHeaderTitle}
        moveTo={() => null}
        hasReachedMaxPublish={hasReachedMaxPublish}
        hasReachedMaxCreation={hasReachedMaxCreation}
        isAgreedTerms={isAgreedTerms}
        hideNext
      />
      <CustomHeaderMobile
        selectMenu={() => null}
        currentMenu={currentMenu}
        moveTo={moveTo}
        hideNext
      />
      <div className='flex flex-col items-center justify-center'>
        <div className='h-full max-h-none w-full max-w-none rounded-none px-0 pb-0 pt-2 md:max-w-[50.375rem] md:rounded-2xl md:p-6'>
          <div className='mb-10 flex flex-col gap-3 px-4'>
            <div className='justify-left hidden lg:flex'>
              <button
                className='btn btn-ghost'
                onClick={() => {
                  moveTo('ExhibitionSettings');
                }}
              >
                <ChevronLeftIcon className='inline h-6 w-6' />
                <Trans i18nKey={'btn.back'} />
              </button>
            </div>
            <div className='hidden h-[68px] w-[726px] items-center justify-start gap-2.5 pb-8 md:inline-flex'>
              <div className="font-['Inter'] text-3xl font-extrabold leading-9 text-[#1f2a37]">
                <Trans i18nKey={'gallery.basic-info.ttl'} />
              </div>
            </div>
            <div className='flex flex-col gap-6'>
              {/* Thumbnail Image */}
              <span className='label label-text'>
                <Trans i18nKey={'gallery.basic-info.thumbnail'} />
              </span>
              <div className='flex w-[343px] justify-center'>
                {exhibitionSnapshot ? (
                  <img
                    src={exhibitionSnapshot}
                    className='h-[169.5px] w-full max-w-[226px] object-cover'
                  />
                ) : (
                  <div className='flex h-[169.5px] w-full max-w-[226px] items-center justify-center bg-slate-300'>
                    <p className='text-sm'>Loading</p>
                  </div>
                )}
              </div>

              {/* Exhibition Name */}
              <label className='form-control w-full'>
                <div className='label'>
                  <span className='label-text'>
                    <Trans i18nKey={'label.basic-exhib-name'} />
                  </span>
                  <span className='label-text-alt'>
                    <Trans i18nKey={'label.required'} />
                  </span>
                </div>
                <input
                  className={`input input-bordered w-full text-sm placeholder:text-sm ${
                    titleError && 'input-error'
                  }`}
                  maxLength='100'
                  type='text'
                  placeholder={t('placeholder.type-here')}
                  value={title}
                  onChange={(e) => validateSaveTitle(e.target.value)}
                />
                <div className='label'>
                  <span className='label-text-alt text-error'>
                    {titleError}
                  </span>
                  <span className='label-text-alt'>
                    {title?.length || 0}/100
                  </span>
                </div>
              </label>

              {/* Exhibition Overview */}
              <label className='form-control'>
                <div className='label'>
                  <span className='label-text'>
                    <Trans i18nKey={'label.basic-exhib-overview'} />
                  </span>
                  <span className='label-text-alt'>
                    <Trans i18nKey={'label.required'} />
                  </span>
                </div>
                <textarea
                  className={`textarea textarea-bordered text-sm placeholder:text-sm ${
                    descriptionError && 'textarea-error'
                  }`}
                  placeholder={t('placeholder.type-here')}
                  maxLength='1000'
                  rows='7'
                  value={description}
                  onChange={(e) => validateSaveDescription(e.target.value)}
                ></textarea>
                <div className='label'>
                  <span className='label-text-alt text-error'>
                    {descriptionError}
                  </span>
                  <span className='label-text-alt'>
                    {description?.length || 0}/1000
                  </span>
                </div>
              </label>

              {/* Exhibition ID */}
              <label className='form-control w-full'>
                <div className='label'>
                  <span className='label-text'>
                    <Trans i18nKey={'label.basic-exhib-id'} />
                  </span>
                  <span className='label-text-alt'>
                    <Trans i18nKey={'label.required'} />
                  </span>
                </div>
                <p className='px-1 pb-3 text-xs'>
                  <Trans i18nKey={'label.basic-exhib-desc'} />
                </p>
                <input
                  className={`input input-bordered w-full text-sm placeholder:text-sm ${
                    roomIdError && 'input-error'
                  }`}
                  maxLength='30'
                  type='text'
                  placeholder={t('placeholder.type-here')}
                  value={roomId}
                  onChange={(e) => validateSaveRoomId(e.target.value)}
                />
                <div className='label'>
                  <span className='label-text-alt text-error'>
                    {roomIdError}
                  </span>
                  <span className='label-text-alt'>
                    {roomId?.length || 0}/30
                  </span>
                </div>
              </label>
              <div className='inline-flex h-[90px] w-full max-w-[34.375rem] flex-col items-end justify-center'>
                <div className='mb-3 w-full rounded-lg bg-base-200 px-4 py-3'>
                  <p className='break-words text-sm font-medium text-gray-600'>
                    https://curatedartshow.com/galleries/{roomId}
                  </p>
                </div>
                <div className='flex justify-end'>
                  <button
                    className='btn btn-outline btn-sm inline-flex w-fit'
                    onClick={() => {
                      navigator.clipboard.writeText(exhibitionLink);
                      successToast(t('message.success.t24'));
                    }}
                    disabled={!roomId}
                  >
                    <Square2StackIcon className='h-5 w-5' />
                    <Trans i18nKey={'btn.copy-exhibition-link'} />
                  </button>
                </div>
              </div>
            </div>
            {/* Private Mode */}
            {(plan == 'PREMIUM' || plan == 'VIP') && (
              <label className='form-control mt-2 w-full'>
                <div className='label'>
                  <span className='label-text'>
                    <Trans i18nKey={'label.basic-exhib-private'} />
                  </span>
                </div>
                <p className='px-1 text-xs'>
                  <Trans i18nKey={'label.basic-exhib-private-description'} />
                </p>

                <div className='label items-center justify-start gap-2'>
                  <span className='label-text w-[7.125rem] text-sm !font-medium'>
                    <Trans i18nKey={'gallery.basic-info.set-private'} />
                  </span>
                  <input
                    checked={isPrivate}
                    type='checkbox'
                    className='toggle'
                    onChange={() => saveExhibitionInfo('isPrivate', !isPrivate)}
                  />
                </div>
              </label>
            )}
            {/* Password */}
            {!editMode && isPrivate && (
              <div>
                <label className='form-control w-full'>
                  <div className='label'>
                    <span className='label-text'>
                      <Trans i18nKey={'gallery.basic-info.set-password'} />
                    </span>
                    <span className='label-text-alt'>
                      <Trans i18nKey={'label.required'} />
                    </span>
                  </div>
                  <input
                    className={`input input-bordered w-full text-sm placeholder:text-sm ${
                      passwordError && 'input-error'
                    }`}
                    maxLength='20'
                    type='password'
                    placeholder={t('placeholder.type-here')}
                    value={password}
                    onChange={(e) => validateSavePassword(e.target.value)}
                    required={isPrivate}
                  />
                  <div className='label'>
                    <span className='label-text-alt text-error'>
                      {passwordError}
                    </span>
                    <span className='label-text-alt'>
                      {password?.length ?? 0}/20
                    </span>
                  </div>
                </label>
              </div>
            )}
            {/* New Password */}
            {editMode && isPrivate && (
              <div className='w-full'>
                {0 < previousExhibitionData.password.length ? (
                  <>
                    {showNewPasswordInput ? (
                      <div className='mt-4 flex flex-col gap-4'>
                        <label className='form-control w-full'>
                          <div className='label'>
                            <span className='label-text text-sm font-medium'>
                              <Trans
                                i18nKey={'gallery.basic-info.new-password'}
                              />
                            </span>
                            <span className='label-text-alt'>
                              <Trans i18nKey={'label.required'} />
                            </span>
                          </div>
                          <input
                            className={`input input-bordered w-full text-sm placeholder:text-sm ${
                              newPasswordError && 'input-error'
                            }`}
                            maxLength='20'
                            type='password'
                            placeholder={t('placeholder.type-here')}
                            value={newPassword}
                            onChange={(e) =>
                              validateNewSavePassword(e.target.value)
                            }
                            required={isPrivate}
                          />
                          <div className='label'>
                            <span className='label-text-alt text-error'>
                              {newPasswordError}
                            </span>
                            <span className='label-text-alt'>
                              {newPassword?.length ?? 0}/20
                            </span>
                          </div>
                        </label>
                        <button
                          className='btn btn-ghost self-end text-sm font-[600]'
                          onClick={() => {
                            setShowNewPasswordInput(false);
                            saveExhibitionInfo('newPassword', '');
                            setNewPasswordError('');
                          }}
                        >
                          <Trans
                            i18nKey={
                              'gallery.basic-info.cancel-change-password'
                            }
                          />
                        </button>
                      </div>
                    ) : (
                      <div className='mt-4 flex items-center gap-6'>
                        <p className='text-sm font-medium'>
                          <Trans
                            i18nKey={'gallery.basic-info.password-already-set'}
                          />
                        </p>
                        <button
                          className='btn btn-outline !btn-neutral btn-sm w-fit border text-sm font-[600] !text-neutral-content'
                          onClick={() => {
                            setShowNewPasswordInput(true);
                          }}
                        >
                          <ArrowPathIcon className='h-5 w-5' />
                          <Trans
                            i18nKey={'gallery.basic-info.change-password'}
                          />
                        </button>
                      </div>
                    )}
                  </>
                ) : (
                  <label className='form-control w-full'>
                    <div className='label'>
                      <span className='label-text text-sm font-medium'>
                        <Trans i18nKey={'gallery.basic-info.set-password'} />
                      </span>
                      <span className='label-text-alt'>
                        <Trans i18nKey={'label.required'} />
                      </span>
                    </div>
                    <input
                      className={`input input-bordered w-full text-sm placeholder:text-sm ${
                        newPasswordError && 'input-error'
                      }`}
                      maxLength='20'
                      type='password'
                      placeholder={t('placeholder.type-here')}
                      value={newPassword}
                      onChange={(e) => {
                        validateNewSavePassword(e.target.value);
                      }}
                      required={isPrivate}
                    />
                    <div className='label'>
                      <span className='label-text-alt text-error'>
                        {newPasswordError}
                      </span>
                      <span className='label-text-alt'>
                        {newPassword?.length ?? 0}/20
                      </span>
                    </div>
                  </label>
                )}
              </div>
            )}
            {((isEditMode && !exhibitionInfo.published) || !isEditMode) &&
              hasReachedMaxPublish && (
                <div role='alert' className='alert mt-6'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                    className='h-6 w-6 shrink-0 stroke-info'
                  >
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      strokeWidth='2'
                      d='M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z'
                    ></path>
                  </svg>
                  {i18n.language == 'ja' ? (
                    <span>
                      {plan == 'VIP'
                        ? `現在のプラン（VIP CAS Member）で同時に公開できる展示会の上限に達しました。さらに展示会を公開したい場合、公開する展示会を１つ選んで他の展示会を非公開にすることを検討してください。VIP CAS Memberでは${MAX_PUBLISHED_EXHIBITION_VIP}個の展示会の同時公開をサポートしていますが、さらに多くの展示会を公開したい場合は、お問い合わせフォームよりお問い合わせください。`
                        : plan == 'PREMIUM'
                        ? `現在のプラン（PREMIUM CAS Member）での同時公開展示の上限に達しました。さらに展示会を公開したい場合、公開する展示会を１つ選んで他の展示会を非公開にするか、${MAX_PUBLISHED_EXHIBITION_VIP}個の展示会を同時に公開できるVIP CAS Memberにアップグレードしましょう。`
                        : `現在のプラン（CAS Member）での同時公開展示の上限に達しました。さらに展示会を公開したい場合、公開する展示会を１つ選んで他の展示会を非公開にするか、${MAX_PUBLISHED_EXHIBITION_PREMIUM}個の展示会を同時に公開できるVIP CAS Memberにアップグレードしましょう。`}
                    </span>
                  ) : (
                    <span>
                      {plan == 'VIP'
                        ? `You have reached the limit for the number of concurrent published exhibitions under your plan: VIP CAS Member.You may wish to unpublish this exhibition and publish one of your unpublished exhibitions. VIP CAS Member supports ${MAX_PUBLISHED_EXHIBITION_VIP} concurrent published exhibitions, but if you need even more, please contact us.`
                        : plan == 'PREMIUM'
                        ? `You have reached the limit for the number of concurrent published exhibitions under your plan: Premium CAS Member.
You may wish to unpublish this exhibition and publish one of your unpublished exhibitions, or upgrade to VIP CAS Member, which supports ${MAX_PUBLISHED_EXHIBITION_VIP} concurrent published exhibitions.`
                        : `You have reached the limit for the number of concurrent published exhibitions under your plan: CAS Member.
You may wish to unpublish this exhibition and publish one of your unpublished exhibitions, or upgrade to VIP CAS Member, which supports ${MAX_PUBLISHED_EXHIBITION_PREMIUM} concurrent published exhibitions.`}
                    </span>
                  )}
                </div>
              )}
            <div className='divider my-5' />

            <div className='flex items-center gap-6'>
              <p className='text-sm font-medium text-base-content'>
                <Trans i18nKey={'gallery.basic-info.auto-stroll'} />
              </p>
              <input
                type='checkbox'
                className='toggle'
                checked={exhibitionInfo.slideshowAutoStart}
                onChange={toggleSlideshowAutoStart}
              />
            </div>

            <div className='divider my-5' />
            <div className='mb-4'>
              <div className='form-control'>
                <label className='label flex cursor-pointer items-start justify-start gap-3'>
                  <input
                    checked={showOnHome}
                    type='checkbox'
                    onChange={() =>
                      saveExhibitionInfo('showOnHome', !showOnHome)
                    }
                    className='checkbox-primary checkbox !h-[1.375rem] !w-[1.375rem] !border-base-content/20'
                  />
                  <p className='text-xs font-normal'>
                    {plan == 'FREE' && (
                      <Trans i18nKey={'exhibition-agreement-free'} />
                    )}

                    {(plan == 'PREMIUM' || plan == 'VIP') && (
                      <Trans
                        i18nKey={'exhibition-agreement-premium'}
                        components={{
                          link1: (
                            <a
                              href='/explore?tab=premium'
                              target='_blank'
                              className='underline'
                            ></a>
                          ),
                        }}
                      />
                    )}
                  </p>
                </label>
              </div>
              <div className='form-control'>
                <label
                  className={`label flex items-start gap-3 ${
                    editMode ? 'cursor-not-allowed' : 'cursor-pointer'
                  }`}
                >
                  <input
                    checked={isAgreedTerms}
                    type='checkbox'
                    onChange={() => setIsAgreedTerms(!isAgreedTerms)}
                    className={`checkbox-primary checkbox !h-[1.375rem] !w-[1.375rem] !border-base-content/20`}
                    disabled={isEditMode}
                  />

                  <p className='text-xs font-normal'>
                    <Trans
                      i18nKey={'exhibition-term'}
                      components={{
                        link1: (
                          <a
                            href='/terms-privacy'
                            target='_blank'
                            className='underline'
                          ></a>
                        ),
                      }}
                    />
                  </p>
                </label>
              </div>
            </div>
            {isEditMode ? (
              <div className='flex flex-col items-center'>
                <button
                  className='btn btn-primary  mb-2 w-[256px]'
                  onClick={handleSubmit}
                  disabled={hasError || !isAgreedTerms || !exhibitionSnapshot}
                >
                  <Trans i18nKey={'btn.save-publish'} />
                </button>

                <button
                  className='btn btn-outline btn-primary  w-[256px]'
                  onClick={handleSaveDraft}
                  disabled={hasError || !isAgreedTerms || !exhibitionSnapshot}
                >
                  {saveDraftMutation.isPending && (
                    <span className='loading loading-spinner'></span>
                  )}
                  <EyeSlashIcon className='h-6 w-6 shrink-0' />
                  <Trans i18nKey={'btn.save-unpublish'} />
                </button>

                <div className='divider my-5' />

                <label
                  htmlFor='delete-modal'
                  className='btn btn-outline btn-error  w-[256px]'
                >
                  <TrashIcon className='h-6 w-6 shrink-0' />
                  <Trans i18nKey={'btn.delete'} />
                </label>
              </div>
            ) : (
              <div className='flex justify-start'>
                <button
                  className='btn btn-primary  w-[256px]'
                  onClick={handleSubmit}
                  disabled={
                    hasError ||
                    !isAgreedTerms ||
                    !exhibitionSnapshot ||
                    hasReachedMaxPublish
                  }
                >
                  <Trans i18nKey={'btn.save-publish'} />
                </button>
              </div>
            )}
          </div>
        </div>
        <FooterSimple />
      </div>

      <div className='pointer-events-none absolute top-0 z-[-1] opacity-0'>
        <Preview
          theme={exhibitionSettings.theme}
          frame={exhibitionSettings.frame}
          showSilhouette={exhibitionSettings.silhouette}
          setSnapshot={handleChangeSnapshot}
          light={exhibitionSettings.light}
          items={exhibitionItems}
          lightColor={exhibitionSettings.lightColor}
          ceiling={exhibitionSettings.ceiling}
          wall={exhibitionSettings.wall}
          floor={exhibitionSettings.floor}
          background={exhibitionSettings.background}
          objectLight={exhibitionSettings.objectLight}
          colorCode={exhibitionSettings.colorCode}
          directionalLightIntensity={
            exhibitionSettings.directionalLightIntensity
          }
          directionalLightColorCode={
            exhibitionSettings.directionalLightColorCode
          }
          spotLightIntensity={exhibitionSettings.spotLightIntensity}
          spotLightColorCode={exhibitionSettings.spotLightColorCode}
          frameColor={exhibitionSettings.frameColor}
          matColor={exhibitionSettings.matColor}
          fogColor={exhibitionSettings.fogColor}
        />
      </div>
      <InactiveRoomModal roomId={exhibitionId} />
    </div>
  );
};

export default BasicInfo;
