import { useEffect } from 'react';

export const usePreventLeave = () => {
  useEffect(() => {
    // リロード・タブを閉じる際に警告を表示
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = ''; // 標準の警告メッセージを表示するために必要
    };

    // beforeunloadイベントをリッスン
    window.addEventListener('beforeunload', handleBeforeUnload);

    // クリーンアップ処理
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);
};
