import React from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

const PromotionCardSmall = () => {
  return (
    <Link to={'/signup'}>
      <div className='flex w-full flex-col gap-2 rounded-2xl bg-white p-5 text-[#00100E]'>
        <button className='btn btn-accent text-sm '>
          <Trans i18nKey={'btn.free-to-use'} />
        </button>
        <p className='text-xs text-base-content'>
          <Trans i18nKey={'price-card.note-for-member'} />
        </p>
      </div>
    </Link>
  );
};

export default PromotionCardSmall;
