import {
  ArrowsPointingOutIcon,
  EllipsisHorizontalIcon,
  BuildingStorefrontIcon,
  PencilSquareIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { publicRequest } from '../../requestMethods';
import ReportItemModal from './modal/ReportItemModal';
import FullScreenImg from './FullScreenImg';
import { useOnClickOutside } from '../../utils/useOnClickOutside';
import parse from 'html-react-parser';
import { useLocation } from 'react-router-dom';
import { Trans } from 'react-i18next';

const ItemModal = ({ item, setShowModal, isBackgroundLight }) => {
  const [currentItem, setCurrentItem] = useState(null);

  const [galleries, setGalleries] = useState([]);

  const [showReport, setShowReport] = useState(false);
  const [showReportModal, setShowReportModal] = useState(false);
  const [showRentingUsers, setShowRentingUsers] = useState(false);
  const [showFullScreenImg, setShowFullScreenImg] = useState(null);

  // ステータスのENUMを定義
  const STATUS = {
    AVAILABLE_FOR_SALE: 'Available for Sale',
    NOT_FOR_SALE: 'Not for Sale',
    PENDING: 'Pending',
    SOLD: 'Sold',
  };

  const user = useSelector((state) => state.user.currentUser);
  const location = useLocation();

  const itemModalRef = useRef();
  const shareRef = useRef();
  const shareButtonRef = useRef();
  const rentingUsersRef = useRef();
  const rentingUsersButtonRef = useRef();
  const reportRef = useRef();
  const reportButtonRef = useRef();
  const reportModalRef = useRef();
  const closeModal = () => {
    document.body.classList.remove('is-scrollLocked');
    setShowModal(false);
  };

  const handleClickOutsideReport = (event) => {
    if (showReport && !reportButtonRef.current.contains(event.target)) {
      setShowReport(false);
    }
  };
  const handleClickOutsideRenting = (event) => {
    if (
      showRentingUsers &&
      !rentingUsersButtonRef.current.contains(event.target)
    ) {
      setShowRentingUsers(false);
    }
  };
  useOnClickOutside(rentingUsersRef, (e) => handleClickOutsideRenting(e));
  useOnClickOutside(reportRef, (e) => handleClickOutsideReport(e));
  useOnClickOutside(reportModalRef, () => setShowReportModal(false));

  const navigate = useNavigate();

  const getLatestItemData = async () => {
    const res = await publicRequest.get(`/items/${item?.id}`);
    setCurrentItem(res.data);
  };

  useEffect(() => {
    getLatestItemData();
  }, [item.id]);

  useEffect(() => {
    const getGalleries = async () => {
      const galleryData = await publicRequest.get(
        `/rooms/galleries-has-item/${item?.id}`
      );
      setGalleries(galleryData.data);
    };
    getGalleries();
  }, []);

  return (
    <>
      <div
        className='fixed left-0 top-0 z-[1000] h-full w-full bg-transparent'
        onClick={(e) => {
          e.stopPropagation();
          closeModal();
        }}
      ></div>

      <div ref={itemModalRef}>
        <div className='fixed bottom-0 left-1/2 z-[1001] flex h-[95%] w-full -translate-x-1/2 transform flex-col items-center rounded-xl border-2 border-gray-300 bg-white/70 bg-opacity-60 p-1 text-black before:absolute before:top-0 before:z-[-1] before:h-full before:w-full before:overflow-hidden before:rounded-xl before:backdrop-blur-2xl before:content-[""] md:top-1/2 md:!h-[90vh] md:!w-[90vw] md:-translate-y-1/2 md:p-5'>
          {/* header */}
          <div className='flex w-full justify-end'>
            <button onClick={closeModal}>
              <XMarkIcon className='m-2 h-8 w-8 cursor-pointer' />
            </button>
          </div>

          <div className='flex w-full flex-col overflow-hidden md:flex-row'>
            {/* left */}
            <div className='cas-scrollbar hidden w-full flex-col items-center overflow-y-scroll px-4 py-2 md:flex md:w-1/3'>
              <div
                className='relative mb-5 max-h-[90%]'
                onClick={(event) => {
                  event.stopPropagation();
                  item?.itemUrl
                    ? setShowFullScreenImg(item.itemUrl)
                    : setShowFullScreenImg('/assets/img/artwork_dummy.png');
                }}
              >
                <img
                  className='m-full w-full max-w-full cursor-pointer object-contain lg:max-h-full'
                  src={
                    item?.itemUrl
                      ? item.itemUrl + '?q=60'
                      : '/assets/img/artwork_dummy.png'
                  }
                  alt=''
                />
                {item?.itemUrl && (
                  <ArrowsPointingOutIcon
                    className='absolute bottom-0 right-0 h-7 w-7 cursor-pointer bg-primary p-1 text-white'
                    onClick={() =>
                      item?.itemUrl
                        ? setShowFullScreenImg(item.itemUrl)
                        : setShowFullScreenImg('/assets/img/artwork_dummy.png')
                    }
                  />
                )}
              </div>
              <div className='grid w-full grid-cols-3 gap-4'>
                {item.otherImage1Url && (
                  <div className='flex flex-col gap-2'>
                    <div
                      className='aspect-square cursor-pointer overflow-hidden'
                      onClick={(event) => {
                        event.stopPropagation();
                        setShowFullScreenImg(item.otherImage1Url);
                      }}
                    >
                      <img
                        src={item.otherImage1Url + '?q=60'}
                        className='h-full w-full object-cover'
                      />
                    </div>
                    <p className='line-clamp-1 text-xs'>
                      {item.otherImage1Title}
                    </p>
                  </div>
                )}
                {item.otherImage2Url && (
                  <div className='flex flex-col gap-2'>
                    <div
                      className='aspect-square cursor-pointer overflow-hidden'
                      onClick={(event) => {
                        event.stopPropagation();
                        setShowFullScreenImg(item.otherImage2Url);
                      }}
                    >
                      <img
                        src={item.otherImage2Url + '?q=60'}
                        className='h-full w-full object-cover'
                      />
                    </div>
                    <p className='line-clamp-1 text-xs'>
                      {item.otherImage2Title}
                    </p>
                  </div>
                )}
                {item.otherImage3Url && (
                  <div className='flex flex-col gap-2'>
                    <div
                      className='aspect-square cursor-pointer overflow-hidden'
                      onClick={(event) => {
                        event.stopPropagation();
                        setShowFullScreenImg(item.otherImage3Url);
                      }}
                    >
                      <img
                        src={item.otherImage3Url + '?q=60'}
                        className='h-full w-full object-cover'
                      />
                    </div>
                    <p className='line-clamp-1 text-xs'>
                      {item.otherImage3Title}
                    </p>
                  </div>
                )}
              </div>

              {item?.ownerId !== user?.id && item?.published && (
                <button
                  onClick={() =>
                    navigate(`/users/${item?.owner.username}?tab=forSale`, {
                      state: { item: item },
                    })
                  }
                  className={`primary-filled regular-btn flex w-full items-center justify-center space-x-2 text-sm ${
                    !item?.isActive && 'non-active-filled'
                  }`}
                  disabled={!item?.isActive}
                  style={{ display: 'none' }}
                >
                  <BuildingStorefrontIcon
                    className={`h-5 w-5
                    stroke-white`}
                  />
                  <span>Get this artwork</span>
                </button>
              )}
            </div>

            {/* right */}
            <div className='col-span-2 flex h-full w-full flex-col px-4 py-2 md:w-2/3'>
              <div className='flex flex-col justify-between md:flex-row'>
                <div className='p-2 text-3xl font-bold text-black'>
                  {item?.title ? item.title : 'Untitled Artwork'}
                </div>
                <div className='mb-3 flex flex-col items-center md:flex-row md:space-x-1 md:self-end'>
                  <div className='mr-5 flex w-full items-center justify-end space-x-[24px] md:space-x-3'>
                    {item?.ownerId !== user?.id &&
                      item?.creatorId !== user?.id &&
                      user && (
                        <div
                          className='hover:bg-modal-bg-blur relative rounded-md p-1'
                          ref={reportButtonRef}
                        >
                          <EllipsisHorizontalIcon
                            className='h-5 w-5 cursor-pointer'
                            onClick={() => setShowReport(!showReport)}
                          />
                          {showReport && (
                            <div
                              ref={reportRef}
                              onClick={() => {
                                setShowReportModal(!showReportModal);
                                setShowReport(false);
                              }}
                              className='absolute right-0 z-10 mt-2 flex w-56 flex-col space-y-3 rounded-lg border border-[#ACACAC] bg-white p-2 shadow-lg'
                            >
                              <span className='cursor-pointer rounded-lg p-3 hover:bg-gray-200'>
                                Report
                              </span>
                            </div>
                          )}
                        </div>
                      )}

                    {item?.ownerId === user?.id &&
                      item?.creatorId === user?.id && (
                        <>
                          <Link
                            to={`/items/edit/${item?.id}`}
                            className='hover:bg-modal-bg-blur rounded-md p-1'
                            state={{
                              from: `${location.pathname}${location.search}`,
                            }}
                          >
                            <PencilSquareIcon className='text-accent-600 h-5 w-5' />
                          </Link>
                        </>
                      )}
                  </div>
                </div>
              </div>
              <div className='cas-scrollbar overflow-y-auto overflow-x-hidden overscroll-contain p-3'>
                {/* mobile only start */}
                <div className='my-5 flex justify-center md:hidden'>
                  <div
                    className='relative'
                    onClick={(event) => {
                      event.stopPropagation();
                      item?.itemUrl
                        ? setShowFullScreenImg(item.itemUrl)
                        : setShowFullScreenImg('/assets/img/artwork_dummy.png');
                    }}
                  >
                    <img
                      className='pointer-events-none w-full object-contain'
                      src={
                        item?.itemUrl
                          ? item.itemUrl + '?q=60'
                          : '/assets/img/artwork_dummy.png'
                      }
                      alt=''
                    />
                    {item?.itemUrl && (
                      <ArrowsPointingOutIcon
                        className='absolute bottom-0 right-0 h-7 w-7 cursor-pointer bg-primary p-1 text-white'
                        onClick={(event) => {
                          event.stopPropagation();
                          item?.itemUrl
                            ? setShowFullScreenImg(item.itemUrl)
                            : setShowFullScreenImg(
                                '/assets/img/artwork_dummy.png'
                              );
                        }}
                      />
                    )}
                  </div>
                </div>
                <div className='grid w-full grid-cols-3 gap-4 md:hidden'>
                  {item.otherImage1Url && (
                    <div className='flex flex-col gap-2'>
                      <div
                        className='aspect-square cursor-pointer overflow-hidden'
                        onClick={(event) => {
                          event.stopPropagation();
                          console.log(item.otherImage3Url);
                          setShowFullScreenImg(item.otherImage1Url);
                        }}
                      >
                        <img
                          src={item.otherImage1Url + '?q=60'}
                          className='h-full w-full object-cover'
                        />
                      </div>
                      <p className='line-clamp-1 text-xs'>
                        {item.otherImage1Title}
                      </p>
                    </div>
                  )}
                  {item.otherImage2Url && (
                    <div className='flex flex-col gap-2'>
                      <div
                        className='aspect-square cursor-pointer overflow-hidden'
                        onClick={(event) => {
                          event.stopPropagation();
                          setShowFullScreenImg(item.otherImage2Url);
                        }}
                      >
                        <img
                          src={item.otherImage2Url + '?q=60'}
                          className='h-full w-full object-cover'
                        />
                      </div>
                      <p className='line-clamp-1 text-xs'>
                        {item.otherImage2Title}
                      </p>
                    </div>
                  )}
                  {item.otherImage3Url && (
                    <div className='flex flex-col gap-2'>
                      <div
                        className='aspect-square cursor-pointer overflow-hidden'
                        onClick={(event) => {
                          event.stopPropagation();
                          setShowFullScreenImg(item.otherImage3Url);
                        }}
                      >
                        <img
                          src={item.otherImage3Url + '?q=60'}
                          className='h-full w-full object-cover'
                        />
                      </div>
                      <p className='line-clamp-1 text-xs'>
                        {item.otherImage3Title}
                      </p>
                    </div>
                  )}
                </div>

                {/* mobile only end */}
                {item.artworkUrl && (
                  <a
                    target='_blank'
                    href={item.artworkUrl}
                    className='btn btn-ghost '
                  >
                    {item.artworkUrlLabel}
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 24 24'
                      strokeWidth='1.5'
                      stroke='currentColor'
                      className='h-6 w-6'
                    >
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        d='M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25'
                      />
                    </svg>
                  </a>
                )}

                <p className='py-4 text-sm text-black'>
                  {parse(item?.description.replaceAll('\n', '<br />'))}
                </p>
                {/* Artist Name */}
                {item.artistName && (
                  <div className='mb-6'>
                    <p className='font-bold'>
                      <Trans i18nKey={'item-modal.artist'} />
                    </p>
                    <p>{item.artistName}</p>
                  </div>
                )}
                {currentItem?.tags?.map((tag, index) => (
                  <div className='mb-6 flex space-x-3 text-sm'>
                    <span className='link' key={index}>
                      #{tag}
                    </span>
                  </div>
                ))}

                {item.createdYear && (
                  <div className='mb-6'>
                    <p className='font-bold text-black'>
                      <Trans i18nKey={'artwork-modal.created'} />
                    </p>
                    <p className='text-black'>{item.createdYear}</p>
                  </div>
                )}

                <div className='mb-6'>
                  <p className='font-bold text-black'>
                    <Trans i18nKey={'artwork-modal.location'} />
                  </p>
                  <p className='text-black'>{item?.owner?.displayName}</p>
                </div>

                {item?.medium && (
                  <div className='mb-6'>
                    <p className='font-bold text-black'>
                      <Trans i18nKey={'artwork-modal.medium'} />
                    </p>
                    <p className='text-black'>{item?.medium}</p>
                  </div>
                )}

                {item?.dimensions && (
                  <div className='mb-6'>
                    <p className='font-bold text-black'>
                      <Trans i18nKey={'artwork-modal.dimensions'} />
                    </p>
                    <p className='text-black'>{item?.dimensions}</p>
                  </div>
                )}

                {item?.availability && (
                  <div className='mb-6'>
                    <p className='font-bold text-black'>
                      <Trans i18nKey={'artwork-modal.availability'} />
                    </p>
                    <p className='text-black'>{STATUS[item?.availability]}</p>
                  </div>
                )}

                {item?.price != null && (
                  <div className='mb-6'>
                    <p className='font-bold text-black'>
                      <Trans i18nKey={'artwork-modal.price-currency'} />
                    </p>
                    <p className='text-black'>
                      {item?.price} {item?.currency}
                    </p>
                  </div>
                )}

                {galleries?.length > 0 && (
                  <div className='mb-6'>
                    <p className='font-bold text-black'>
                      <Trans i18nKey={'item-modal.exhibitions'} />
                    </p>

                    <div className='cas-scrollbar horizontal-scroll mt-3 flex space-x-3 overflow-x-scroll pb-2'>
                      {galleries?.map((gallery) => (
                        <Link
                          key={gallery?.id}
                          to={`/galleries/${gallery?.roomId}`}
                          className='w-30 aspect-video h-20 rounded-lg'
                          target='_blank'
                        >
                          <img
                            src={gallery?.previewImg + '?q=60'}
                            className='aspect-video rounded-lg  object-cover'
                          />
                        </Link>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {showReportModal && (
          <ReportItemModal
            itemId={item?.id}
            setShowReportModal={setShowReportModal}
            reportModalRef={reportModalRef}
            isBackgroundLight={isBackgroundLight}
          />
        )}
        {showFullScreenImg && (
          <FullScreenImg
            onClose={() => setShowFullScreenImg(null)}
            imgUrl={showFullScreenImg}
          />
        )}
      </div>
    </>
  );
};

export default ItemModal;
