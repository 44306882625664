import React, { useMemo } from 'react';
import { useGLTF } from '@react-three/drei';

export default function CustomFrame2(props) {
  const { nodes, materials } = useGLTF(
    'https://data.curatedartshow.com/models/frames/custom_wide_frame.glb'
  );

  const { frameColor, matColor } = props;

  // useMemo でマテリアルをキャッシュし、frameColor, matColor が変わったときに再生成
  const frameMaterial = useMemo(() => {
    const material = materials['default_material'].clone();
    material.color.set(frameColor);
    material.metalness = 1;
    material.roughness = 3;
    material.needsUpdate = true;
    return material;
  }, [frameColor, materials]);

  const matMaterial = useMemo(() => {
    const material = materials['default_material'].clone();
    material.color.set(matColor);
    material.metalness = 1;
    material.roughness = 3;
    material.needsUpdate = true;
    return material;
  }, [matColor, materials]);

  return (
    <group {...props} dispose={null}>
      <group
        position={[-0.015, -0.085, -0.011]}
        rotation={[0.086, -1.186, 0.026]}
      >
        <group
          position={[0.032, -0.012, -0.015]}
          rotation={[-0.238, 1.179, 0.217]}
          scale={1.193}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Frame_n3d.geometry}
            material={frameMaterial}
            position={[0, 0.043, -0.013]}
            rotation={[0.02, 0, 0]}
            scale={[2.5, 3.056, 0.1]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mat_n3d.geometry}
            material={matMaterial}
            position={[0, 0.35, 0.011]}
            rotation={[-3.109, 0.016, -3.14]}
            scale={[0.787, 0.842, 0.775]}
          />
        </group>
      </group>
    </group>
  );
}

useGLTF.preload(
  'https://data.curatedartshow.com/models/frames/custom_wide_frame.glb'
);
