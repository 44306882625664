import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { publicRequest } from '../../../../../requestMethods';
import LoadingSpinner from '../../../../../components/LoadingSpinner';
import { useDispatch, useSelector } from 'react-redux';
import { loadEnd, loadStart } from '../../../../../redux/loadRedux';
import { errorToast } from '../../../../../utils/toast';
import { useTranslation } from 'react-i18next';

export const OnBoardingExpired = () => {
  const [success, setSuccess] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get('id');
  const accountId = searchParams.get('account_id');
  const { t } = useTranslation();

  const { isLoading } = useSelector((state) => state.isLoading);
  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(loadStart());
    try {
      await publicRequest.post(`/auth/resend-email`, {
        userId: Number(id),
        accountId,
      });
      dispatch(loadEnd());
      setSuccess(true);
    } catch (err) {
      dispatch(loadEnd());
      console.log(err);
      errorToast(t('message.error.t1'));
    }
  };

  return (
    <>
      <div className='mx-auto flex max-w-[21.25rem] flex-col items-center gap-10 pt-10'>
        {!success ? (
          <>
            <h2 className='text-center text-3xl font-extrabold'>
              Your verification link has expired
            </h2>
            <div className='text-center text-sm'>
              The verification link has expired.
            </div>

            <button
              onClick={handleSubmit}
              className={`btn btn-primary btn-wide`}
            >
              Resend verification link
            </button>
          </>
        ) : (
          <>
            <h2 className='text-3xl font-extrabold'>Email has been sent!</h2>
            <div className='flex justify-center'>
              <img
                src='/assets/img/message-sent.png'
                alt=''
                className='h-60 w-60'
              />
            </div>
            <p className='text-center'>
              We have sent a password recovery instruction to your email
            </p>
          </>
        )}
      </div>
      {isLoading && (
        <>
          <LoadingSpinner />
        </>
      )}
    </>
  );
};
