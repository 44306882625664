import { authRequest, publicRequest } from '../requestMethods';

export const fetchGalleriesByUsername = async (username) => {
  try {
    const response = await publicRequest.get(
      `/rooms/users/username/${username}`
    );
    return response.data;
  } catch (error) {
    console.error(`Failed on fetchGalleriesByUsername request:`, error);
    throw error;
  }
};

export const fetchAvailableItems = async (userId) => {
  try {
    const response = await publicRequest.get(`/items/owner/${userId}`);
    // 重複を除去
    return [...response.data].reduce((result, item) => {
      // アイテムのIDが既に存在する追加しない
      if (!result.find((existingItem) => existingItem.id === item.id)) {
        result.push(item);
      }
      return result;
    }, []);
  } catch (error) {
    console.error(`Failed on fetchAvailableItems request:`, error);
    throw error;
  }
};

export const createPreset = async (data) => {
  try {
    const s3PreviewImgRes = await publicRequest.post(
      `/s3/base64`,
      {
        file: data.snapshot,
        key: `${data.userId}/previewImg/`,
      },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );

    const { snapshot, randomMusic, ...restData } = data; // snapshotを削除
    const requestData = {
      ...restData,
      imageUrl: s3PreviewImgRes.data.replace(
        'curatedartshow-data.s3.amazonaws.com',
        'data.curatedartshow.com'
      ),
    };
    const response = await authRequest.post(`/presets`, requestData);
    return response;
  } catch (error) {
    console.error(`Failed on createPreset request:`, error);
    throw error;
  }
};

/**
 * 展示会情報を保存する
 * @param {Object} exhibitionInfo - 展示会情報のオブジェクト
 * @param {string} exhibitionInfo.title - タイトル
 * @param {string} exhibitionInfo.description - 説明
 * @param {string} exhibitionInfo.previewImg - プレビュー画像URL
 * @param {number} exhibitionInfo.animationType - 遷移アニメーションの種類
 * @param {string} exhibitionInfo.roomId - 展示会ID
 * @param {boolean} exhibitionInfo.isPrivate - プライベートかどうか
 * @param {string} exhibitionInfo.password - パスワード
 * @param {boolean} exhibitionInfo.showOnHome - トップページに表示するか
 * @param {boolean} exhibitionInfo.published - 公開するか
 * @returns {Object} exhibitionInfo - 展示会情報のオブジェクト
 */
export const createExhibition = async (exhibitionInfo) => {
  try {
    const res = await authRequest.post('/rooms', exhibitionInfo);
    console.log(res);
    return res.data;
  } catch (error) {
    console.error(`Failed on createExhibition request:`, error);
    throw error;
  }
};

export const updateExhibition = async (id, exhibitionInfo) => {
  try {
    const res = await authRequest.patch(`/rooms/${id}`, exhibitionInfo);
    return res.data;
  } catch (error) {
    console.error(`Failed on updateExhibition request:`, error);
    throw error;
  }
};

export const updateArtworksInExhibition = async (exhibitionId, artworkIds) => {
  try {
    await authRequest.patch(`/rooms/edit-items/${exhibitionId}`, {
      items: artworkIds,
    });
  } catch (error) {
    console.error(`Failed on updateArtworksInExhibition request:`, error);
    throw error;
  }
};

/**
 * 展示会設定を更新する
 * @param {Object} exhibitionSettings - 展示会設定のオブジェクト
 * @param {string} exhibitionSettings.frame - 額縁
 * @param {string} exhibitionSettings.theme - テーマ
 * @param {boolean} exhibitionSettings.silhouette - シルエット表示
 * @param {string} exhibitionSettings.music - BGM
 * @param {boolean} exhibitionSettings.randomMusic - BGMのランダム再生
 * @param {number} exhibitionSettings.light - 照明
 * @param {string} exhibitionSettings.lightColor - 照明の色
 * @param {string[]} exhibitionSettings.silhouettes - 表示するシルエット
 * @param {string} exhibitionSettings.colorCode - 展示会全体の色コード
 * @param {number} exhibitionSettings.ceiling - 天井
 * @param {number} exhibitionSettings.wall - 壁
 * @param {number} exhibitionSettings.floor - 床
 * @param {number} exhibitionSettings.objectLight - オブジェクトライト
 * @param {number} exhibitionSettings.directionalLightIntensity - 指向性ライトの強度
 * @param {string} exhibitionSettings.directionalLightColorTheme - 指向性ライトの色テーマ
 * @param {string} exhibitionSettings.directionalLightColorCode - 指向性ライトの色コード
 * @param {string} exhibitionSettings.spotLightIntensity - スポットライトの強度
 * @param {string} exhibitionSettings.spotLightColorTheme - スポットライトの色テーマ
 * @param {string} exhibitionSettings.spotLightColorCode - スポットライトの色コード
 * @param {string} exhibitionSettings.frameColor - 額縁の色
 * @param {string} exhibitionSettings.matColor - 額縁のマットカラー
 * @returns {void}
 */
export const updateExhibitionSettings = async (
  exhibitionId,
  exhibitionSettings
) => {
  try {
    await authRequest.patch(
      `/rooms/settings/${exhibitionId}`,
      exhibitionSettings
    );
  } catch (error) {
    console.error(`Failed on updateExhibitionSettings request:`, error);
    throw error;
  }
};

export const saveExhibitionPreviewInS3 = async (snapshot, userId) => {
  try {
    const res = await publicRequest.post(
      `/s3/base64`,
      {
        file: snapshot,
        key: `${userId}/previewImg/`,
      },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    return res.data;
  } catch (error) {
    console.error(`Failed on saveExhibitionPreviewInS3 request:`, error);
    throw error;
  }
};

export const createPublishExhibition = async (
  userId,
  snapshot,
  items,
  exhibitionInfo,
  exhibitionSettings
) => {
  try {
    const s3PreviewImgRes = await publicRequest.post(
      `/s3/base64`,
      {
        file: snapshot,
        key: `${userId}/previewImg/`,
      },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    const requestInfoData = {
      ...exhibitionInfo,
      published: true,
      previewImg: s3PreviewImgRes.data.replace(
        'curatedartshow-data.s3.amazonaws.com',
        'data.curatedartshow.com'
      ),
    };
    delete requestInfoData.newPassword;

    // 展示会設定を取得しランダムBGM削除
    const requestSettingsData = {
      ...exhibitionSettings,
      music: exhibitionSettings.randomMusic
        ? 100
        : exhibitionSettings.music
        ? exhibitionSettings.music
        : null,
    };
    delete requestSettingsData.randomMusic;

    //展示会作成
    const res = await authRequest.post('/rooms', requestInfoData);

    //作品保存
    await authRequest.patch(`/rooms/edit-items/${res.data.id}`, {
      items,
    });

    //展示会設定保存
    await authRequest.patch(
      `/rooms/settings/${res.data.id}`,
      requestSettingsData
    );

    return res;
  } catch (error) {
    console.error(`Failed on createPublishExhibition request:`, error);
    throw error;
  }
};

export const savePublishExhibition = async (
  id,
  userId,
  snapshot,
  items,
  exhibitionInfo,
  exhibitionSettings
) => {
  try {
    const s3PreviewImgRes = await publicRequest.post(
      `/s3/base64`,
      {
        file: snapshot,
        key: `${userId}/previewImg/`,
      },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    const requestInfoData = {
      ...exhibitionInfo,
      published: true,
      previewImg: s3PreviewImgRes.data.replace(
        'curatedartshow-data.s3.amazonaws.com',
        'data.curatedartshow.com'
      ),
      ...(exhibitionInfo.newPassword && {
        password: exhibitionInfo.newPassword,
      }),
    };
    delete requestInfoData.newPassword;

    const isRandomMusic = exhibitionSettings.randomMusic;
    const { randomMusic, ...restData } = exhibitionSettings; // snapshotを削除
    const requestSettingsData = {
      ...restData,
      music: isRandomMusic
        ? 100
        : exhibitionSettings.music
        ? exhibitionSettings.music
        : null,
    };

    //展示会更新
    const res = await authRequest.patch(`/rooms/${id}`, requestInfoData);

    //登録作品更新
    await authRequest.patch(`/rooms/edit-items/${res.data.id}`, {
      items,
    });

    //展示会設定更新
    await authRequest.patch(
      `/rooms/settings/${res.data.id}`,
      requestSettingsData
    );

    return res;
  } catch (error) {
    console.error(`Failed on savePublishExhibition request:`, error);
    throw error;
  }
};

export const createDraftExhibition = async (
  userId,
  snapshot,
  items,
  exhibitionInfo,
  exhibitionSettings
) => {
  try {
    const s3PreviewImgRes = await publicRequest.post(
      `/s3/base64`,
      {
        file: snapshot,
        key: `${userId}/previewImg/`,
      },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    const requestInfoData = {
      ...exhibitionInfo,
      published: false,
      previewImg: s3PreviewImgRes.data.replace(
        'curatedartshow-data.s3.amazonaws.com',
        'data.curatedartshow.com'
      ),
    };
    delete requestInfoData.newPassword;

    const isRandomMusic = exhibitionSettings.randomMusic;
    const { randomMusic, ...restData } = exhibitionSettings; // snapshotを削除
    const requestSettingsData = {
      ...restData,
      music: isRandomMusic
        ? 100
        : exhibitionSettings.music
        ? exhibitionSettings.music
        : null,
    };

    //展示会作成
    const res = await authRequest.post('/rooms', requestInfoData);

    //作品保存
    await authRequest.patch(`/rooms/edit-items/${res.data.id}`, {
      items,
    });

    //展示会設定保存
    await authRequest.patch(
      `/rooms/settings/${res.data.id}`,
      requestSettingsData
    );

    return res;
  } catch (error) {
    console.error(`Failed on createDraftExhibition request:`, error);
    throw error;
  }
};

export const saveDraftExhibition = async (
  id,
  userId,
  snapshot,
  items,
  exhibitionInfo,
  exhibitionSettings
) => {
  try {
    const s3PreviewImgRes = await publicRequest.post(
      `/s3/base64`,
      {
        file: snapshot,
        key: `${userId}/previewImg/`,
      },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    const requestInfoData = {
      ...exhibitionInfo,
      published: false,
      previewImg: s3PreviewImgRes.data.replace(
        'curatedartshow-data.s3.amazonaws.com',
        'data.curatedartshow.com'
      ),
      ...(exhibitionInfo.newPassword && {
        password: exhibitionInfo.newPassword,
      }),
    };
    delete requestInfoData.newPassword;

    const isRandomMusic = exhibitionSettings.randomMusic;
    const { randomMusic, ...restData } = exhibitionSettings; // snapshotを削除
    const requestSettingsData = {
      ...restData,
      music: isRandomMusic
        ? 100
        : exhibitionSettings.music
        ? exhibitionSettings.music
        : null,
    };

    //展示会更新
    const res = await authRequest.patch(`/rooms/${id}`, requestInfoData);

    //登録作品更新
    await authRequest.patch(`/rooms/edit-items/${res.data.id}`, {
      items,
    });

    //展示会設定更新
    await authRequest.patch(
      `/rooms/settings/${res.data.id}`,
      requestSettingsData
    );

    return res;
  } catch (error) {
    console.error(`Failed on saveDraftExhibition request:`, error);
    throw error;
  }
};

export const publishExhibition = async (roomId) => {
  try {
    return await authRequest.post(`/rooms/publish/${roomId}`);
  } catch (error) {
    console.error(`Failed on unpublishExhibition request:`, error);
    throw error;
  }
};

export const unpublishExhibition = async (roomId) => {
  try {
    return await authRequest.post(`/rooms/unpublish/${roomId}`);
  } catch (error) {
    console.error(`Failed on unpublishExhibition request:`, error);
    throw error;
  }
};

export const getExhibitionByRoomId = async (roomId) => {
  try {
    const res = await publicRequest.get(`/rooms/roomId/${roomId}`);
    return res.data;
  } catch (error) {
    console.error(`Failed on getExhibitionByRoomId request:`, error);
    throw error;
  }
};

export const getExhibitionByExhibitionId = async (roomId) => {
  try {
    const res = await authRequest.get(`/rooms/roomId/${roomId}/password`);
    return res.data;
  } catch (error) {
    console.error(`Failed on getExhibitionByExhibitionId request:`, error);
    throw error;
  }
};

export const getPremiumCASExhibitions = async () => {
  try {
    const res = await publicRequest.get('/rooms/random-rooms/premium');
    return res.data;
  } catch (error) {
    console.error(`Failed on getPremiumCASExhibitions request:`, error);
    throw error;
  }
};

export const getPremiumVipCASExhibitions = async () => {
  try {
    const res = await publicRequest.get(
      '/rooms/random-rooms/premium-vip?minItemLength=8'
    );
    return res.data;
  } catch (error) {
    console.error(`Failed on getPremiumVipCASExhibitions request:`, error);
    throw error;
  }
};

export const getCuratorsSelectionExhibitions = async () => {
  try {
    const res = await publicRequest.get('/rooms/curated?minItemLength=8');
    return res.data;
  } catch (error) {
    console.error(`Failed on getCuratorsSelectionExhibitions request:`, error);
    throw error;
  }
};
