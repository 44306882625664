import { useGLTF } from '@react-three/drei';
import { Suspense, useLayoutEffect, useState } from 'react';

const PictureFrame41 = ({ color, ...props }) => {
  const { scene, materials } = useGLTF(
    'https://data.curatedartshow.com/models/frames/perforated.glb'
  );

  const [geometry, setGeometry] = useState();

  if (!geometry) {
    const cloneScene = scene.clone(true);
    setGeometry(cloneScene);
  }

  useLayoutEffect(() => {
    // console.log(materials);
    // materials['Black Paint Rolled_material'].color.set('#ebeaea');
    materials['Black Paint Rolled_material'].metalness = 0.6;
    materials['Black Paint Rolled_material'].roughness = 0.2;
    // materials['Soft Purple Satin Ceramic_material2.001'].color.set('#191919');
    materials['Soft Purple Satin Ceramic_material2.001'].metalness = 0.5;
    materials['Soft Purple Satin Ceramic_material2.001'].roughness = 1;
    materials['metal_perforated_dots_material'].color.set('#0f0f0f');
    materials['metal_perforated_dots_material'].metalness = 1;
    materials['metal_perforated_dots_material'].roughness = 3;

    geometry.traverse((obj) => {
      if (obj.isMesh) obj.castShadow = obj.receiveShadow = true;
    });
  }, []);

  return (
    <Suspense fallback={null}>
      <primitive object={geometry} {...props} />
    </Suspense>
  );
};

export default PictureFrame41;
